import React,{useState,useEffect} from 'react'
import api from '../config.json';
import axios from '../httpServices/httpService';
import _ from 'lodash';
import Loading from '../utill/LoadingComponent';

const EquipmentSelection=({style,equipGroups,equip,selectedEquipGroupOption,selectedEquipOption,setSelection})=>{
    const [errors,setErrors]=useState({})
    const [querySuccess,setQuerySuccess]=useState(false)

    useEffect(() => {
        handleOnChangeSelect({currentTarget:{checked:!getCheckeStatus("All",'EquipmentGroup'),id:"All",name:"EquipmentGroup"}}, "All",'EquipmentGroup')
        // handleOnChangeSelect({currentTarget:{checked:!getCheckeStatus("All",'Equipment'),id:"All",name:"Equipment"}}, "All",'Equipment')

    }, [])
    useEffect(() => {
        if( selectedEquipGroupOption.length > 0){

            handleOnChangeSelect({currentTarget:{checked:true,id:"All",name:"Equipment"}},"All",'Equipment')
        }

    }, [selectedEquipGroupOption])
  
    const handleOnChangeSelect = async  (e, value,key) => {
        let option ;
        
        if(key=='EquipmentGroup'){
            option = [...selectedEquipGroupOption];
        }
        if(key=='Equipment'){
            option = [...selectedEquipOption];
        }
        if (value == 'All') {
            if (e.currentTarget.checked) {
                let obj = {}
                obj.label = value;
                obj.value = e.currentTarget.id;
                option.push(obj);
                if(key=='Equipment'){
                    equip.forEach(one => {
                        let obj = {}
                        obj.label = one['EquipmentID'];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }else{
                    equipGroups.forEach(one => {
                        let obj = {}
                        obj.label = one[e.currentTarget.name];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }
               
            } else {
                option.splice(0, option.length);
            }
        } else {
            if (e.currentTarget.checked) {
                let obj = {}
                obj.label = value;
                obj.value = e.currentTarget.id;
                option.push(obj);

            } else {
               option= option.filter(one => one.value !== e.currentTarget.id);
               option=option.filter(one=>one.value!=='All')

            }
        }
        let query=[]

        option.forEach(one=>{
            let obj={}
            if(one.value!=='All'){
                obj.EquipmentGroup=one.value;
                obj['EquipmentGroupname']=one.label;

                query.push(obj)
            }
        })

        option= _.uniqWith(
            option,
            (a, b) =>
                a.value === b.value
        );
        if(key=='EquipmentGroup'){
            let res=[];
            let equips;
            let filteredArray;
            if(query.length>0){
            const storedEquipGroupsData = localStorage.getItem('equipment');

            if (storedEquipGroupsData) {
              // Data exists in local storage, use it
            
              const storedEquipGroups = JSON.parse(storedEquipGroupsData);
              equips = await  storedEquipGroups
            //   // consol(equips,"from localstorage data")
              filteredArray = equips.filter(obj1 => {
                // Check if there is an object in array2 with the same id
                return query.some(obj2 => obj2.EquipmentGroupname === obj1.EquipmentGroup);
              });
              // consol(filteredArray,"current filtered data")
            } 
                // setQuerySuccess(true)
                // res = await axios.put(api.api1 + 'equipment', { $or:query })
                // setQuerySuccess(false)
               // setEquip(res.data)
            }
           setSelection(key,option,filteredArray)
            //setSelectedEquipGroupOption(option);
        }
        if(key=='Equipment'){
            setSelection(key,option)
            //setSelectedEquipOption(option)
        }
       
    }
    const getCount=(data)=>{
        let count=0
        if(data.filter(one=>one.value=='All').length>0){
            count=data.length-1;
        }else{
            count=data.length;
        }
       
        return count
    }
    const getCheckeStatus = (value,key) => {
        let option ;
        if(key=='EquipmentGroup'){
            option = [...selectedEquipGroupOption];
        }
        if(key=='Equipment'){
            option = [...selectedEquipOption];
        }
        let filterData=option.filter(one=>value==one.value)
        if(filterData.length>0){
            return true
        }else{
            return false
        }
    }
    return (
        <>
        {querySuccess?<Loading/>:''}
         <div className="col-sm-2">
                    <div className='form-group text-center'>
                        <label className='small'>Equipment Group</label>
                        <div id="list1" className="dropdown p-0 m-0  form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0,height:'100%',width:'100%' }} className="btn p-0 m-0  btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                               {getCount(selectedEquipGroupOption)>0? getCount(selectedEquipGroupOption)+ ' Equipment Group':'Select Group'}
                        </button>
                            <ul className="dropdown-menu" style={{minWidth:'100%'}} aria-labelledby="dropdownMenuButton">
                                <li onClick={() => handleOnChangeSelect({currentTarget:{checked:!getCheckeStatus("All",'EquipmentGroup'),id:"All",name:"EquipmentGroup"}}, "All",'EquipmentGroup')}  className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All','EquipmentGroup')}  onChange={(e) => handleOnChangeSelect(e, 'All','EquipmentGroup')} id='All'  name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;All </li>
                                {equipGroups.map((one) => {
                                    return <li onClick={() => handleOnChangeSelect({currentTarget:{checked:!getCheckeStatus(one._id,'EquipmentGroup'),id:one._id,name:"EquipmentGroup"}}, one.EquipmentGroup,'EquipmentGroup')}  className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id,'EquipmentGroup')}  id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentGroup,'EquipmentGroup')} name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;{one.EquipmentGroup} </li>
                                })}
                            </ul>
                        </div>
                        {errors.EquipmentGroup && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.EquipmentGroup}</p>}
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className='form-group text-center'>
                        <label className='small'>Equipment</label>
                        <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0,height:'100%',width:'100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {getCount(selectedEquipOption)>0? getCount(selectedEquipOption)+ ' Equipment':'Select Equipment'}
                        </button>
                            <ul  className="dropdown-menu" style={style?style:{minWidth:'100%'}} aria-labelledby="dropdownMenuButton">
                            {selectedEquipGroupOption.length>0?<li onClick={() => handleOnChangeSelect({currentTarget:{checked:!getCheckeStatus("All",'Equipment'),id:"All",name:"Equipment"}}, "All",'Equipment')} className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All','Equipment')}  onChange={(e) => handleOnChangeSelect(e, 'All','Equipment')} id='All'  name='Equipment' />&nbsp;&nbsp;&nbsp;All </li>:''}
                                {equip.map((one) => {
                                    return <li onClick={() => handleOnChangeSelect({currentTarget:{checked:!getCheckeStatus(one._id,'Equipment'),id:one._id,name:"Equipment"}}, one.EquipmentID,'Equipment')} className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id,'Equipment')}  id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentID,'Equipment')} name='Equipment' />&nbsp;&nbsp;&nbsp;{one.EquipmentID} </li>
                                })}
                            </ul>
                        </div>
                        {errors.Equipment && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Equipment}</p>}
                        
                    </div>
                </div>
        </>
    )
}

export default EquipmentSelection;