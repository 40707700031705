import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import {generateExcelFileFull,generateExcelFile} from '../utill/download'
import HourlyTableAvailability from '../Common/hourlyAvailability';
import { ToastContainer, toast } from 'react-toastify';



const AvailabilityReportHourly = ({user,fromDateLimit}) => {
  const [productionData, setProductionData] = useState([])
  const [inputData, setInputData] = useState({})
  const [errors, setErrors] = useState({})
  const [querySuccess, setQuerySuccess] = useState(false)
  const [shiftSetting, setShiftSetting] = useState([])

  const schema = {
    DateTime: joi.date().required().label('Datetime'),
    UserId: joi.string(),
    Shift: joi.string().required().label('ShiftName')
  }

  const sortColumnComponent = { path: 'Component', order: 'asc' }
  const sortColumnMachine = { path: 'Machine', order: 'asc' }
  const sortColumnOperator = { path: 'Operator', order: 'asc' }

  const sortColumnDate = { path: 'Date', order: 'asc' }
  const columnsComponent = [
    { path: 'Component', label: 'Component' },
    { path: 'OperationID', label: 'OperationId' },
    { path: 'Operator', label: 'Operator' },
    { path: 'Equipment', label: 'Machine' },
    {
      path: 'Date', label: 'Date', content: obj => <React.Fragment>
        <label>
         {date.getDate(obj.From)}
        </label>
      </React.Fragment>
    },
    { path: 'Shift', label: 'Shift' },
    { path: 'ActualProduction', label: 'Total Qty.' },
    { path: 'Rejected', label: 'Rejected' },
    { path: 'Accepted', label: 'Accepted' }
  ]
  const columnsOperator = [
    { path: 'Operator', label: 'Operator' },
    { path: 'Equipment', label: 'Machine' },
    { path: 'Component', label: 'Component' },
    { path: 'OperationID', label: 'OperationId' },
    { path: 'Date', label: 'Date' },
    { path: 'Shift', label: 'Shift' },
    { path: 'ActualProduction', label: 'Total Qty.' },
    { path: 'Rejected', label: 'Rejected' },
    { path: 'Accepted', label: 'Accepted' }
  ]
  const columnsMachine = [
    { path: 'Equipment', label: 'Machine' },
    { path: 'Component', label: 'Component' },
    { path: 'OperationID', label: 'OperationId' },
    { path: 'Operator', label: 'Operator' },
    { path: 'Date', label: 'Date' },
    { path: 'Shift', label: 'Shift' },
    { path: 'ActualProduction', label: 'Total Qty.' },
    { path: 'Rejected', label: 'Rejected' },
    { path: 'Accepted', label: 'Accepted' }
  ]

  const getProductionReportSchema=()=>{
    let schema=[]
    productionData.forEach((one)=>{
      let obj={}
      obj.path='Date'
      obj.label='Date'
      obj.content=(o)=>{return <label>{date.getDateFormat(o.From)}</label>}
      
      let obj1={}
      obj1.path='Equipment'
      obj1.label='Equipment'
      let obj2={}
      obj2.path='EquipmentGroup'
      obj2.label='EquipmentGroup'
      schema.push(obj)
      schema.push(obj1)
      schema.push(obj2)
      one.Hours.forEach(hour=>{
       let obj4={}
       obj4.key='hour'
       obj4.label=`${date.getTime(hour.From)}-${date.getTime(hour.Till)}`
       obj4.content=(o)=>{return <label>{o.Cycles}</label>}
       schema.push(obj4);
      })
    })

    return schema;
  }

  const ProductionReportSchema = [
    { path: 'Date', label: 'Date' },
    { path: 'Shift', label: 'Shift' },
    { path: 'Equipment', label: 'Equipment' },
    { path: 'EquipmentGroup', label: 'EquipmentGroup' },
    {path:'Hour',label:'Hour1'},
    {path:'Hour',label:'Hour2'},
    {path:'Hour',label:'Hour3'}
  ]
  const handleOnChange = async(e) => {
    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
    }
    else {
      inputData[e.currentTarget.name] = e.currentTarget.value;
    }
   let res= await axios.put(api.api+'GetShiftSettingForDateShift',{DateTime:inputData.DateTime})
   setShiftSetting(res.data);
    setInputData(inputData);
    setErrors({})
  }
  const handleOnSubmit = (inputData) => {
  
    // consol(inputData)
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    inputData.UserId=user._id
    axios.post(api.api + 'GetEquipmentAvailabilityHourly',inputData).then((result) => {
      // consol(result)
      if (result.data !== null && result.data !== undefined) {
        if (result.data.length > 0) {
          setProductionData(result.data)
          setQuerySuccess(false)
        }
        else {
          swal('Data Not Available');
          setQuerySuccess(false)
        }
      }
      else {
        setQuerySuccess(false)
      }

    })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err)
        setQuerySuccess(false)
      })

  }

 
 
  const handleSort = (sortColumn) => {
    if (productionData.length > 0) {
      const sorted = _.orderBy(
        productionData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setProductionData(sorted)
    }
  }

  const handleDownload=(e)=>{
    generateExcelFile(e)
  }
  return (
    <React.Fragment>    <ToastContainer/>
      {querySuccess ? <Loading /> : ''}
      <div className='text-center' >
        <p >Availability Hourly</p>
      </div>
      <div className="row">
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Date</label>
            <input className={'form-control form-control-sm'} placeholder='dd/mm/yyyy' min={fromDateLimit} type='date' onChange={handleOnChange} name='DateTime' />
            {errors.DateTime && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.DateTime}</p>}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              {shiftSetting.map(one=>{
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div>
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>
        <div className="col-sm-2">
          <button disabled={productionData.length>0?false:true} className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('HourlyAvailabilityReport')}>Download Excel</button>
        </div>
      </div>
      <div>
      {productionData.length>0?<HourlyTableAvailability inputData={inputData} data={productionData}/>:''}

      
      </div>
    </React.Fragment>
  );
}
export default AvailabilityReportHourly;