import React, { useState, useEffect } from "react";
import {CheckboxDropdown} from "../Common/CheckBoxFunctionality"

function ReusableCheckboxDropdown({ initialItems, label, selectedItemIds, onSelectedItemsChange, heading, searchbar, name, enableLocalStorage,multiSelect,selectAll }) {
  const [checkboxItems, setCheckboxItems] = useState(initialItems);

  const handleCheckboxChange = (updatedItems,heading) => {
    setCheckboxItems(updatedItems);

    const newSelectedItems = updatedItems.filter(item => item.checked).map(item => item.label);
    onSelectedItemsChange(newSelectedItems,heading);
    // consol(updatedItems,newSelectedItems,"multiselect")
    if (enableLocalStorage) {
      localStorage.setItem(`selectedItems_${label}`, JSON.stringify(newSelectedItems));
    }
  };

  useEffect(() => {
    if (enableLocalStorage) {
      const storedSelectedItems = JSON.parse(localStorage.getItem(`selectedItems_${label}`)) || [];
      onSelectedItemsChange(storedSelectedItems);
    }
  }, [label, enableLocalStorage]);

  return (
    <div>
      {/* Other content */}
      <CheckboxDropdown items={checkboxItems} onCheckboxChange={handleCheckboxChange} heading={heading} name={name} searchbar={searchbar} multiSelect={multiSelect} selectAll={selectAll || false}/>
      {/* Other content */}
    </div>
  );
}

export default ReusableCheckboxDropdown;























// import React, { useState, useEffect } from "react";
// import  { CheckboxDropdown } from './CheckBoxFunctionality';
// function ReusableCheckboxDropdown({ initialItems, label, selectedItemIds, onSelectedItemsChange, heading,searchbar ,name}) {
//   const [checkboxItems, setCheckboxItems] = useState(initialItems);
 

//   const handleCheckboxChange = (updatedItems, heading) => {
//     setCheckboxItems(updatedItems);
  
//     const newSelectedItems = updatedItems.filter(item => item.checked).map(item => item.label);
//     onSelectedItemsChange(newSelectedItems, heading); // Pass the heading here
  
//     localStorage.setItem(`selectedItems_${label}`, JSON.stringify(newSelectedItems));
//   };
  
//   useEffect(() => {
//     const storedSelectedItems = JSON.parse(localStorage.getItem(`selectedItems_${label}`)) || [];
//     onSelectedItemsChange(storedSelectedItems);
//   }, [label]); // Only run this effect when the label changes

//   return (
//     <div>
//       {/* Other content */}
//       <CheckboxDropdown items={checkboxItems} onCheckboxChange={handleCheckboxChange} heading={heading} name={name} searchbar={searchbar} />
//       {/* Other content */}
//       {/* <div>
//         <h3>Selected Items:</h3>
//         <ul>
//           {selectedItemIds.map((label, index) => (
//             <li key={index}>{label}</li>
//           ))}
//         </ul>
//       </div> */}
//     </div>
//   );
// }

// export default ReusableCheckboxDropdown;
