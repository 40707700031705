import React, { useState } from 'react';
import Table from '../Common/table';
import ColumnChart from '../Charts/ColumnChart';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import { ToastContainer, toast } from 'react-toastify';

import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import EquipmentSelection from '../Common/equipmentSelection';
import DateSelection from '../Common/DateSelection';
const Availability = ({ user, equips,equipGroups,fromDateLimit }) => {
  const [availabilityData, setAvailabilityData] = useState([])
  const [inputData, setInputData] = useState({})
  const [errors, setErrors] = useState({})
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [querySuccess, setQuerySuccess] = useState(false)
  const [index, setIndex] = useState(0);
  const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })

  const setSelection=(key,option,equips)=>{
    if(key=='EquipmentGroup'){
        SetEquips(equips?equips:[]);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if(key=='Equipment'){
       
        setSelectedEquipOption(option)
    }
}

const getEquipsForReport=()=>{
    let equipArr=[]
    equips.forEach((one)=>{
        selectedEquipOption.forEach((option)=>{
            if(one._id==option.value){
                equipArr.push(one);
            }
        })
    })
    return equipArr;
}
  const schema = {
    From: joi.date().required().label('From'),
    Till: joi.date().required().label('Till'),
    UserId: joi.string(),
    Equips: joi.array(),
  }
  const columns = [
    { path: 'Date', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.DateTime)}</label> },
    { path: 'Shift', label: 'Shift' },
    { path: 'Equipment', label: 'Equipment' },
    { path: 'TotalTime', label: 'TotalTime (mins)' },
    { path: 'RunTime', label: 'RunTime (mins)' },
    { path: 'Break', label: 'Breaks (mins)' },
    { path: 'PlannedDowntime', label: 'Planned Downtime (mins)' },
    { path: 'UnplannedDowntime', label: 'Unplanned Downtime (mins)' },
    { path: 'Availability', label: 'Availability(%)', content: (obj) => <label>{obj.Availability.toFixed(1)}</label> },
  ]


  const handleOnChange = (e) => {

    if (e.currentTarget.type === 'date') {
      // consol(new Date(e.currentTarget.value))
      // consol(e.currentTarget.value)
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
      setInputData(inputData);
    }
    else if (e.currentTarget.type === 'time') {
      let Time = e.currentTarget.value.split(':');
      // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
      // consol(e.currentTarget.value)
      inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
      setInputData(inputData);
    }
    else {
      inputData[e.currentTarget.name] = e.currentTarget.value
    }
    setErrors({})

  }
  const handleOnSubmit = async (inputData) => {
    setIndex(0)
    availabilityData.splice(0,availabilityData.length);
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    inputData.UserId = user._id

  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }

    let equipForReort=getEquipsForReport()
    for (let i = 0; i < equipForReort.length; i++) {
      inputData.Equips = [equipForReort[i]]
      try {
        let result = await axios.post(api.api + 'GetAvailability', inputData)
        if (result.data.length > 0) {
          let idx=i+1
          result.data.forEach((one)=>{
            availabilityData.push(one)
          })
          setAvailabilityData(availabilityData);
          setIndex(idx)
         
        }
      }
      catch (err) {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        setQuerySuccess(false)
      }

    }
    setQuerySuccess(false)

  }
  const handleDonwLoad = (e, date) => {
    generateExcelFile(e, date)
  }

  const handleSort = (sortColumn) => {
    if (availabilityData.length > 0) {
      const sorted = _.orderBy(
        availabilityData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setAvailabilityData(sorted)
      setSortColumn(sortColumn)
    }
  }


  const SelectedDates =(dates)=>{
    setInputData(dates)
  }

  return (
    <React.Fragment>
      {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`} /> : ''}
      <div className='text-center' >
        <p >Availability</p>
      </div>
      <ToastContainer/>
      <div className="row">
      {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}
      <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
       
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
         
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
          
        </div> */}
        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>
        {availabilityData.length > 0 ? <div className="col-sm-2">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDonwLoad('Availability', inputData.DateTime)}>Download Excel</button>
        </div> : ''}
      </div>
      <div>
        {availabilityData.length > 0 ?
          <>
            <p className='small ml-2'>* Availability Calculated Formula ((RunTime/(TotalTime - Breaks - PlannedDowntime))*100)%</p>
            <Table id='Availability' onSort={handleSort} columns={columns} sortColumn={sortColumn} data={availabilityData} />
          </> : ''}
      </div>
    </React.Fragment>


  );
}

export default Availability;