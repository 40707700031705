import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import auth from '../httpServices/authService';
import api from '../config'
import swal from 'sweetalert';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from '../utill/LoadingComponent';
import date from '../utill/dateTime';
import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MyChart from '../utill/Barchart';
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}



const OverallMachineOEE = ({ user, equips, equipGroups, fromDateLimit }) => {
    const [value, setValue] = React.useState(0);

    const [OEEData, setOEEData] = useState([])
    const [JobWeightedAverageData, setJobWeightedAverageData] = useState([])
    const [ShiftWeightedAverageData, setShiftWeightedAverageData] = useState([])

    const [view, setView] = useState([])
    const [inputData, setInputData] = useState({})
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [equip, SetEquips] = useState([])
    const [errors, setErrors] = useState({})
    const [querySuccess, setQuerySuccess] = useState(false);
    const [index, setIndex] = useState(0);
    const [shiftSetting, setShiftSettings] = useState([])
    const [sortColumn, setSortColumn] = useState({ path: 'From', order: 'asc' })

    const schema = {
        From: joi.date().required().label('From Datetime'),
        Till: joi.date().required().label('Till Datetime'),
        Equips: joi.array(),
        UserId: joi.string(),

    }

    const handleOnSubmit = async (inputData) => {
        setJobWeightedAverageData([])
        setOEEData([]);
        setShiftWeightedAverageData([]);
        // console.log(inputData,"Data send to the backend");
        // consol(inputData)
        setIndex(0);
        OEEData.splice(0, OEEData.length)
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return
        setQuerySuccess(true)
        inputData.UserId = user._id
        if (new Date(inputData.From) <= new Date(inputData.Till)) {
        } else {
            setQuerySuccess(false);
            return toast.success('From date should be Less Than Till date', {
                position: 'bottom-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    background: '#babbbb', // Set your desired background color
                    color: 'white',     // Set your desired text color
                },
            });
        }
        let equipForReort = getEquipsForReport()
        try {
            inputData.Equips = equipForReort
            let result = await axios.put(api.api + 'EquipmentWiseOEE', inputData)
            if (result.data.length > 0) {
                // let idx = i + 1
                // const arr=[]
                // result.data.forEach((one) => {
                //     arr.push(one)
                // });
                setOEEData(result.data);
               // setIndex(idx);
            }
        }
        catch (err) {
            toast.success(`${err}`, {
                position: 'bottom-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    background: 'red', // Set your desired background color
                    color: 'white',     // Set your desired text color
                },
            });
            // consol(err)
            setQuerySuccess(false)
        }
        // for (let i = 0; i < equipForReort.length; i++) {
        //     inputData.Equips = [equipForReort[i]]
        //     try {
        //         let result = await axios.put(api.api + 'EquipmentWiseOEE', inputData)
        //         if (result.data.length > 0) {
        //             let idx = i + 1
        //             const arr=[]
        //             result.data.forEach((one) => {
        //                 arr.push(one)
        //             });
        //             setOEEData((prevState)=>[...prevState,...arr]);
        //             setIndex(idx);
        //         }
        //     }
        //     catch (err) {
        //         toast.success(`${err}`, {
        //             position: 'bottom-right',
        //             autoClose: 2000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true,
        //             progress: undefined,
        //             style: {
        //                 background: 'red', // Set your desired background color
        //                 color: 'white',     // Set your desired text color
        //             },
        //         });
        //         // consol(err)
        //         setQuerySuccess(false)
        //     }

        // }
        setQuerySuccess(false)

    }

 

    const setSelection = (key, option, equips) => {
        if (key == 'EquipmentGroup') {
            SetEquips(equips ? equips : []);
            setSelectedEquipGroupOption(option)
            setSelectedEquipOption([])
        }
        if (key == 'Equipment') {

            setSelectedEquipOption(option)
        }
    }

    const getEquipsForReport = () => {
        let equipArr = []
        equips.forEach((one) => {
            selectedEquipOption.forEach((option) => {
                if (one._id == option.value) {
                    equipArr.push(one);
                }
            })
        })
        return equipArr;
    }

  
 
   
    const SelectedDates = (dates) => {
        setInputData(dates)
    }

    
    const getData = (data) => {
        const obj = { Head: [], Tail: [] };
        let  quipment=[...equips];
        quipment=quipment.filter((one)=>selectedEquipOption.some(x=>x.label==one.EquipmentID))
        obj.Head=quipment.map(one=>one.EquipmentID)
        obj.Tail=quipment.map(one=>{
            if(data.find(x=>x.Equipment==one.EquipmentID)){
                return data[data.findIndex(x=>x.Equipment==one.EquipmentID)].OEE
            }else{
                return 0.0;
            }
        })
        return obj;
    }

    return (
        <React.Fragment>
            {querySuccess ? <Loading labelShow={true} label={`${getEquipsForReport().length}/${getEquipsForReport().length}`} /> : ''}
            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Overall Machine OEE
                </h4>
            </div>


            <ToastContainer />

            <div className="row">
                {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}
                <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
                {errors['Equips'] && <p className='text-danger ' style={{ fontSize: 11 }}>{errors['Equips']}</p>}
                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => { handleOnSubmit(inputData) }}>Apply</button>
                </div>
                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{ borderBottom: 1, borderColor: "divider", margin: "auto" }}
                    >
                        <MyChart
                            Barcolor={"rgba(255, 132, 72, 1)"}
                            data={getData(OEEData)}
                            height={"300px"}
                            title={`Overall Machine Wise OEE % `}
                            valuetype={"Percentage %"}
                        />

                    </Box>

                </Box>
                {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>From</label>
                 <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From'/>
                 {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                </div>
              </div>
            <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>Till</label>
                  <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                  {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                </div>
              </div> */}
                {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>View By</label>
                  <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewBy'>
                  <option>--select view--</option>
                      <option>Day</option>
                      <option>Shift</option>
                  </select>
                  {errors.ViewBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewBy}</p>}
       
                </div>
              </div> */}
                {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>Shift</label>
                  <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
                  <option>--select shift--</option>
                  {shiftSetting.map(one=>{
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
                  </select>
                  {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
       
                </div>
              </div> */}






            </div>

        </React.Fragment>


    );
}

export default OverallMachineOEE;