import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import { toPng } from "html-to-image";

const DynamicVerticalChart = ({ data, width = "100%", height = "1200px",yaxisTitle ,Color}) => {

  // Check if data is valid and has at least one data row
  if (!data || data.length < 2) {
    return <div className="bolder" style={{ textAlign: "center" }}>No Data.</div>;
  }

  const options = {
    // chartArea: { width: "80%" },
    isStacked: true,
   
    vAxis: {
      title: 'Jobs',
      minValue: 0,
      textStyle: { fontSize: 14 }, // Small font size for axis labels
      titleTextStyle: { fontSize: 15 }, // Small font size for axis title
    },
    hAxis: {
      title: yaxisTitle,
      slantedText: true, // Enable slanted text
      slantedTextAngle: 90, // Specify the angle (e.g., 45 degrees)
      showTextEvery:1,
      textStyle: { fontSize: 14 }, // Small font size for axis labels
      titleTextStyle: { fontSize: 15 }, // Small font size for axis title
    },
     legend: {position: 'top', textStyle: {color: 'blue', fontSize: 16}},
    chartArea: { left:150,right:50,top: 20,bottom:80,},
    colors:Color
  };
  return (
    <Chart
      chartType="BarChart" // Vertical chart type
      width={width}
      height={height}
      data={data}
      options={options}
    />
  );
};

// Example usage:
export const data = [
  ["City", "2010 Population", "2000 Population"],
  ["New York City, NY", 8175000, 8008000],
  ["Los Angeles, CA", 3792000, 3694000],
  ["Chicago, IL", 2695000, 2896000],
  ["Houston, TX", 2099000, 1953000],
  ["Philadelphia, PA", 1526000, 1517000],
];


export function Stackedbarchart({yaxisTitle, data ,Color}) {
  const chartRef = useRef(null);

  const handleDownload = async () => {
    if (chartRef.current) {
      try {
        // Convert the chart container to a PNG image
        const imageDataURL = await toPng(chartRef.current, {
          backgroundColor: "#ffffff", // Set background color
        });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = imageDataURL;
        downloadLink.download = "chart.png"; // File name
        document.body.appendChild(downloadLink);

        // Trigger the download
        downloadLink.click();

        // Remove the download link from the DOM
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Failed to download chart:", error);
      }
    }
  };
  return (<>
   <button className="btn btn-primary mx-2" onClick={handleDownload}>
        Download Chart
      </button>
    <div ref={chartRef}>
      <DynamicVerticalChart data={data} yaxisTitle={yaxisTitle}  Color={Color} />
    </div>
  </>
  );
}
