import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Chart from 'react-google-charts';
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import { generateExcelFileFull, generateExcelFile } from '../utill/download'
import BarChart from 'react-google-charts';
import dateTime from '../utill/dateTime';
import { ToastContainer, toast } from 'react-toastify';



const BenchMarkMachineDetail = ({ obj }) => {
  const [equipProdData, setEquipProdData] = useState([["Time(In Minutes)", "Production Count", { role: "style" }, { role: 'annotation' }]]);
  const [allData, setAllData] = useState({})
  const [querySuccess, setQuerySuccess] = useState(false);
  const [viewChart, setViewChart] = useState({ SOP_EOP: false, EOP_SOP: false, SOP_SOP: false })
  useEffect(() => {
    handleOnSubmit(obj)
  }, [])

  //   const schema = {
  //     From: joi.date().required().label('From'),
  //     Till: joi.date().required().label('Till'),
  //     Shift: joi.string().required().label('Shift'),
  //     Equipment: joi.string().required().label('Equipment'),
  //     Type: joi.string(),
  //     EquipType: joi.string(),
  //   }


  const show_Chart_Diff = (diffType) => {
    if (obj.EquipType == "ProductionCounterLogger" || obj.EquipType == "ProductionLogger") {
      const lineChart = [["Time(In Minutes)", "Time Diffrence", { role: "style" }]]
      const histogramChart = [["Time(In Seconds)", "No Of Incident", { role: "style" }, { role: "tooltip", type: "string" }]]
      allData[diffType] && allData[diffType].Line.forEach((x, idx) => {
        const arr = [];
        arr.push(new Date(x.DateTime));
        arr.push(x.Value)
        arr.push("orange");
        lineChart.push(arr);
      })
      allData[diffType] && allData[diffType].Histogram.forEach((x, idx) => {
        const arr = [];
        arr.push(x.Value);
        arr.push(x.Count);
        arr.push("orange");
        arr.push(`Time (In Sec): ${x.Value} \n No Of Incident: ${x.Count}`)
        histogramChart.push(arr);
      })
      return <>
        <BarChart
          chartType="LineChart"
          width="100%"
          height="400px"
          data={lineChart}
          options={{
            vAxes: {
              0: { title: "Time Difference (sec)", format: "#", minValue: 0 },
            },
            hAxis: {
              slantedText: true,
              slantedTextAngle: 45, // More readable angle
              textStyle: { fontSize: 10 }, // Slightly increased font size
              title: "Time", // Title for the horizontal axis
            },
            tooltip: {
              isHtml: true,
              textStyle: { fontSize: 12 },
            },
            animation: { startup: true, duration: 400, easing: "out" },
            explorer: {
              maxZoomIn: 10,
              maxZoomOut: 8,
              zoomDelta: 2,
              actions: ["dragToZoom", "rightClickToReset"],
              axis: "horizontal",
              keepInBounds: true, // Prevents going beyond the data range
            },
            series: {
              0: { targetAxisIndex: 0, type: "line" },
            },
            colors: ["orange", "#FF00FF", "#0000FF"],
            annotations: {
              textStyle: { fontSize: 10, color: "black" },
              datum: {
                stem: { color: "transparent" },
                textPosition: "out",
              },
              highContrast: true,
            },
            legend: { position: "none" },
          }}
        />
        <BarChart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={histogramChart}
          options={{
            title: obj.EquipType === 'ProductionCounter'
              ? "SPM / Cycles Per Minute Histogram"
              : "Time (In Sec) / No Of Incident  Histogram",
            vAxes: {
              0: {
                title: obj.EquipType === 'ProductionCounter'
                  ? "Number Of Minutes"
                  : "No of Incident",
                format: "#",
                minValue: 0
              },
            },
            hAxis: {
              slantedText: true,
              slantedTextAngle: 45, // More readable angle
              textStyle: { fontSize: 10 }, // Slightly increased font size
              title: obj.EquipType === 'ProductionCounter'
                ? "Cycles Per Minute / SPM"
                : "Time (In Sec)", // Title for the horizontal axis
            },
            tooltip: {
              isHtml: true, // Use custom HTML tooltips
            },
            animation: {
              startup: true,
              duration: 400,
              easing: "out"
            },
            explorer: {
              maxZoomIn: 10,
              maxZoomOut: 8,
              zoomDelta: 2,
              actions: ["dragToZoom", "rightClickToReset"],
              axis: "horizontal",
              keepInBounds: true, // Prevents going beyond the data range
            },
            annotations: {
              textStyle: { fontSize: 10, color: "black" },
              datum: {
                stem: { color: "transparent" },
                textPosition: "out",
              },
              highContrast: true,
            },
            legend: { position: "none" },
          }}
        />


      </>
    } else {
      const histogramChart = [["Cycle Per Minute", "No Of Minutes", { role: "style" }, { role: "tooltip", type: "string" }]]
      allData[diffType] && allData[diffType].Histogram.forEach((x, idx) => {
        const arr = [];
        arr.push(x.Value);
        arr.push(x.Count);
        arr.push("orange");
        arr.push(`Cycle Per Minute: ${x.Value} \n No Of Minutes: ${x.Count}`)
        histogramChart.push(arr);
      })
      return <BarChart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={histogramChart}
        options={{
          title: obj.EquipType === 'ProductionCounter'
            ? "SPM / Cycles Per Minute Histogram"
            : "Time (In Sec) / No Of Incident  Histogram",
          vAxes: {
            0: {
              title: obj.EquipType === 'ProductionCounter'
                ? "Number Of Minutes"
                : "No of Incident",
              format: "#",
              minValue: 0
            },
          },
          hAxis: {
            slantedText: true,
            slantedTextAngle: 45, // More readable angle
            textStyle: { fontSize: 10 }, // Slightly increased font size
            title: obj.EquipType === 'ProductionCounter'
              ? "Cycles Per Minute / SPM"
              : "Time (In Sec)", // Title for the horizontal axis
          },
          tooltip: {
            isHtml: true, // Use custom HTML tooltips
          },
          animation: {
            startup: true,
            duration: 400,
            easing: "out"
          },
          explorer: {
            maxZoomIn: 10,
            maxZoomOut: 8,
            zoomDelta: 2,
            actions: ["dragToZoom", "rightClickToReset"],
            axis: "horizontal",
            keepInBounds: true, // Prevents going beyond the data range
          },
          annotations: {
            textStyle: { fontSize: 10, color: "black" },
            datum: {
              stem: { color: "transparent" },
              textPosition: "out",
            },
            highContrast: true,
          },
          legend: { position: "none" },
        }}
      />
    }
  }




  const handleOnSubmit = (data) => {
    let inputData = { From: data.From, Till: data.Till, Shift: data.Shift, Equipment: data.Equipment, Type: 'History', EquipType: data.EquipType }

    setQuerySuccess(true)
    axios.put(api.api + 'GetCardViewDashboardForLiveViewForEquipment', inputData).then((result) => {
      // consol(result)
      if (result.data !== null && result.data !== undefined) {
        const singleMacData = [["Time(In Minutes)", "Production Count", { role: "style" }, { role: 'annotation' }]];
        if (result.data && result.data.result && result.data.result.length > 0) {

          result.data.result.forEach(element => {
            let arr = []
            arr.push(new Date(element.DateTime))
            if (inputData.EquipType == 'ProductionCounter') {
              arr.push(element.Count);
              arr.push('#008000');
              arr.push('')
            }
            if (inputData.EquipType == 'ProductionEvent') {
              arr.push(1);
              arr.push('#008000');
              arr.push('')
            }
            if (inputData.EquipType == 'ProductionLogger' || inputData.EquipType == 'ProductionCounterLogger') {
              arr.push(1);
              if (element.EventType == 'EOP') {
                arr.push('yellow');
                arr.push(element.EventType)

              }
              else if (element.EventType == 'SOP') {
                arr.push('green');
                arr.push(element.EventType)

              } else {
                arr.push('red');
                arr.push(element.Event)

              }

            }



            singleMacData.push(arr)
          });
          setAllData(result.data);
          setEquipProdData(singleMacData)
          setQuerySuccess(false)
        }
        else {
          setEquipProdData(singleMacData)
          swal('Production Data Not Available');
          setQuerySuccess(false)
        }
      }
      else {
        setQuerySuccess(false)
      }

    })
      .catch((err) => {
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // // consol(err)
        setQuerySuccess(false)
      })

  }



  const chartRender = (data) => {
    return <BarChart
      //style={{ marginTop: 200 }}
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={data}
      loader={<div>Loading Chart</div>}
      options={{
        vAxis: { title: "Cycles Count" },
        tooltip: { isHtml: true },
        animation: { startup: true, duration: 400, easing: "out" },

        explorer: {
          maxZoomIn: 10,
          maxZoomOut: 8,
          zoomDelta: 2,
          actions: ["dragToZoom", "rightClickToReset"],
          axis: "horizontal",
          // axis: "vertical",
          // keepInBounds: true,
          // maxZoomIn: 4.0
        },

        hAxis: {
          slantedText: true,
          slantedTextAngle: 90,
          textStyle: { fontSize: 9 },
          title: "Time"
        },
        annotations: { textStyle: { fontSize: 10, color: "" } },
        legend: { position: 'none' }

      }}
    />
  }
  return (
    <React.Fragment>   <ToastContainer />
      {querySuccess ? <Loading /> : ''}
      <div>

        {obj && ((obj.EquipType === "ProductionCounterLogger") || (obj.EquipType === "ProductionLogger")) && <div className="d-flex flex-column ml-4">
          <div className="form-check">
            <input checked={viewChart && viewChart.SOP_EOP} onChange={(e) =>
              setViewChart((prev) => ({
                ...prev, // Spread operator to copy all other properties
                SOP_EOP: !viewChart.SOP_EOP, // Update EOP_SOP property
              }))
            } type="checkbox" className="form-check-input" id="sop-eop" />
            <label className="form-check-label" htmlFor="sop-eop">
              SOP - EOP ( Process Time )
            </label>
          </div>
          <div className="form-check">
            <input checked={viewChart && viewChart.EOP_SOP} onChange={(e) =>
              setViewChart((prev) => ({
                ...prev, // Spread operator to copy all other properties
                EOP_SOP: !viewChart.EOP_SOP, // Update EOP_SOP property
              }))
            } type="checkbox" className="form-check-input" id="eop-sop" />
            <label className="form-check-label" htmlFor="eop-sop">
              EOP - SOP ( Prepration Time )
            </label>
          </div>
          <div className="form-check">
            <input checked={viewChart && viewChart.SOP_SOP} onChange={(e) =>
              setViewChart((prev) => ({
                ...prev, // Spread operator to copy all other properties
                SOP_SOP: !viewChart.SOP_SOP, // Update EOP_SOP property
              }))
            } type="checkbox" className="form-check-input" id="sop-sop" />
            <label className="form-check-label" htmlFor="sop-sop">
              SOP - SOP ( Cycle Time )
            </label>
          </div>
        </div>}
        {equipProdData.length > 1 ? chartRender(equipProdData) : querySuccess ? <p>Loading....</p> : ''}
        {obj && ((obj.EquipType === "ProductionCounter") || (obj.EquipType === "ProductionEvent")) && show_Chart_Diff("SPM")}
        {viewChart.SOP_EOP ? <div className='text-center'><strong>SOP - EOP ( Process Time )</strong>{show_Chart_Diff("SOP_EOP_Diffrence")}</div> : <></>}
        {viewChart.EOP_SOP ? <div className='text-center'><strong>EOP - SOP ( Prepration Time )</strong>{show_Chart_Diff("EOP_SOP_Diffrence")}</div> : <></>}
        {viewChart.SOP_SOP ? <div className='text-center'><strong>SOP - SOP ( Cycle Time )</strong>{show_Chart_Diff("SOP_SOP_Diffrence")}</div> : <></>}
      </div>
    </React.Fragment>
  );
}
export default BenchMarkMachineDetail;