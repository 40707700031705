
import React, { useEffect, useState } from 'react';
import date from '../utill/dateTime';
import Chart from 'react-google-charts';
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import Table from '../Common/table';
import axios from 'axios';
import api from '../config.json';
const HourlyTableProdction = ({ data }) => {
    const [modelState, setModelState] = useState(false);
    const [modelData, setModelData] = useState({});
    const [jobTableData, setJobTableData] = useState([]);
   
    const sortColumn = { path: 'From', order: 'asc' }
    const [componentList, setComponentList] = useState([])
    useEffect(() => {
        axios.get(api.api1 + 'component')
            .then((result) => {
                setComponentList(result.data);
            })
            .catch((err) => {
                // console.log(err);
            })
    }, [])



    const handleClickModelState = (one) => {
        setModelState(true);
        setModelData(one);
        let jobsArr = []
        one.Hours.forEach((hour) => {
            jobsArr = [...jobsArr, ...hour.Jobs]
        })
        setJobTableData(jobsArr);
    }

    const getTableData = (data2) => {
        let jobsArr = []
        if (Object.keys(data2).length > 0) {
            data2.Hours.forEach((hour) => {
                let arr = []
                hour.Jobs.forEach(job => {
                    let obj = { ...job };
                    // obj.Cycles=hour.Cycles;
                    // obj.IdealCycles=hour.IdealCycles;
                    arr.push(obj)
                })
                jobsArr = [...jobsArr, ...arr]
            })
        }

        return jobsArr;
    }




    const getChartData = (data1) => {
        let arr = []
        if (Object.keys(data1).length > 0) {
            let schema = ['Hours', 'Ideal', 'Actual']
            arr.push(schema);
            data1.Hours.forEach(element => {
                let arr1 = []
                arr1.push(date.getTime(element.From) + '-' + date.getTime(element.Till));
                arr1.push(parseInt(element.IdealCycles));
                arr1.push(element.Cycles);
                arr.push(arr1);
            });
        }


        return arr;
    }

    const columns = [
        { path: 'From', label: 'Date', content: (obj) => <label>{date.getDate(obj.From)}</label> },
        { path: 'From', label: 'From', content: (obj) => <label>{date.getTime(obj.From)}</label> },
        { path: 'Till', label: 'Till', content: (obj) => <label>{date.getTime(obj.Till)}</label> },
        {
            key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
                let component = [...componentList];
                component = component.filter((one) => one.ComponentId == obj.Component)
                if (component.length > 0) {
                    return <label>{component[0].Alias}</label>
                } else {
                    return <label>N/A</label>
                }
            }
        },
        { path: 'Component', label: 'Component' },
        { path: 'OperationID', label: 'Operation' },
        { path: 'ToolID', label: 'Tool' },
        { path: 'IdealCycles', label: 'Ideal Count' },
        { path: 'Cycles', label: 'Actual Count' },
    ]

    const getTotalIdeal = (hours, key) => {
        let cycles = 0
        if (hours) {
            hours.forEach((hour) => {
                cycles = parseFloat(hour[key]) + cycles
            })
        }
        return cycles

    }

    const getTotalRowCycles = (data) => {
        let arr = []
        if (data) {
            data.forEach((one, idx) => {
                if (one.Hours) {
                    one.Hours.forEach((hors, hrsIdx) => {
                        if (arr[hrsIdx]) {
                            let obj = arr[hrsIdx]
                            obj.IdealCycles = (parseFloat(obj.IdealCycles) || 0) + (parseFloat(one.Hours[hrsIdx].IdealCycles || 0));
                            obj.Cycles = obj.Cycles + parseFloat(one.Hours[hrsIdx].Cycles);
                            arr[hrsIdx] = obj;
                        } else {
                            arr[hrsIdx] = { IdealCycles: (one.Hours[hrsIdx].IdealCycles ||0), Cycles: (one.Hours[hrsIdx].Cycles || 0) }
                        }
                    })
                }
            })
        }
        let idealCycles = 0;
        let cycles = 0;
        arr.forEach((one) => {
            idealCycles = idealCycles + (parseFloat(one.IdealCycles) || 0);
            cycles = cycles + parseFloat(one.Cycles);
        })
        arr.push({ IdealCycles: (idealCycles || 0), Cycles: cycles });
        return arr;
    }


    return (
        <div style={{overflow:'auto'}}>
            <table id='HourlyProductionReport' class="table table-sm table-hover small  table-striped ">
                <thead className='small text-center border'>
                    <tr className='table-primary '>
                        <th rowSpan='2' >Date</th>
                        <th rowSpan='2' >Shift</th>
                        <th rowSpan='2' >Equipment</th>
                        <th rowSpan='2' >EquipmentGroup</th>
                        {data.length > 0 ? data[0].Hours.map(hour => {
                            return <th  colSpan='2' style={{ verticalAlign: 'middle' }} ><div className='mt-2'><span>{date.getTime(new Date(hour.From))}-{date.getTime(new Date(hour.Till))}</span></div></th>
                        }) : ''}
                        <th  colSpan='2' style={{ verticalAlign: 'middle' }} ><div className='mt-2'><span>Total</span></div></th>
                    </tr>

                    <tr className='table-primary '>
                        {data.length > 0 ? data[0].Hours.map(hour => {
                            return <><th>Ideal</th><th>Actual</th>
                            </>
                        }) : ''}
                        <th>Ideal</th><th>Actual</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        data.map((one) => {
                            return <tr className='text-center'>
                                <td>{date.getDateFormat(one.Hours[0].From)}</td>
                                <td>{one.Hours[0].Shift}</td>
                                <td><a href='#' onClick={() => handleClickModelState(one)} style={{ color: 'blue' }}>{one.Equipment}</a></td>
                                <td>{one.EquipmentGroup}</td>
                                {one.Hours.map(hour => {
                                    return <><td>{hour.IdealCycles || 0}</td><td>{hour.Cycles || 0}</td></>

                                })}
                                <td>{one.Hours.reduce((accumulator, currentValue) => accumulator + (Number(currentValue.IdealCycles) || 0), 0) === 0?'0':one.Hours.reduce((accumulator, currentValue) => accumulator + (Number(currentValue.IdealCycles) || 0), 0)}</td><td>{one.Hours.reduce((accumulator, currentValue) => accumulator + (Number(currentValue.Cycles) || 0), 0) ===0 ?'0':one.Hours.reduce((accumulator, currentValue) => accumulator + (Number(currentValue.Cycles) || 0), 0)}</td>


                            </tr>
                        })


                    }

                    <tr style={{ backgroundColor: 'lightgray' }} className='text-center'>
                        <td colSpan={4}>Total</td>
                        {getTotalRowCycles(data).map(hour => {
                            return <><td>{hour.IdealCycles}</td><td>{hour.Cycles}</td></>

                        })}
                    </tr>


                </tbody>



            </table>
            <Modal
                show={modelState}
                size='xl'
                onHide={() => setModelState(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <p>Hourly Production</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {<Row>

                        {/* <Col sm={12}>
          <Table columns={columns} sortColumn={sortColumn} data={machineDetail}/>
        </Col>
        <Col sm={4}>
        <Table columns={columns1} sortColumn={sortColumn1} data={operatorDetail}/>

        </Col> */}
                        <Col sm={12}>

                            <Row>
                                <Col sm={12}>
                                    {data.length > 0 ? <Chart
                                        width={'100%'}
                                        height={'100%'}
                                        chartType="Bar"
                                        loader={<div>Loading Chart</div>}
                                        data={getChartData(modelData)}
                                        options={{
                                            // Material design options
                                            // chart: {
                                            //     title: ' Hourly Production',
                                            //     subtitle: 'Actual and Ideal Production Hourly',
                                            // },
                                        }}
                                        // For tests
                                        rootProps={{ 'data-testid': '2' }}
                                    /> : ''}
                                </Col>

                            </Row>
                            <Row>
                                <Table columns={columns} data={getTableData(modelData)} sortColumn={sortColumn} />
                            </Row>
                        </Col>
                    </Row>}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default HourlyTableProdction;