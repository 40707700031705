import React, { Component } from 'react';
import  swal  from 'sweetalert';
import endPoint from '../../../config';
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';



class EquipmentType extends Component {
    state = { 
        equipmentType:{},
        oldObj:{},
       errors:{},
       querySuccess:false,
     }
     schema={
       EquipmentType:joi.string().required().label('Equipment Type')
     }

     componentDidMount()
     {
     
       if(this.props.actionStatus==='add')
       {
         this.setState({equipmentGroup:{}})
       }
       if(this.props.actionStatus==='update')
       {
         const equipmentType={...this.props.equipmentTypeData}
         this.setState({equipmentType:this.props.equipmentTypeData,oldObj:equipmentType});
       }
     }

    handleOnChange=(e)=>{
        const {equipmentType,errors} ={...this.state};

        const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

        equipmentType[e.currentTarget.name]=e.currentTarget.value;
        this.setState({equipmentType,errors:errors||{}});
        

    }
    handleSubmit=(data)=>{
      
      // consol(this.props.equipmentTypeList)
     
      if(this.props.actionStatus==='add')
      {
        const errors = valid.validate(data,this.schema);
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.setState({querySuccess:true});
        axios.post(endPoint.api+'equipmenttype',data)
        .then((result)=>{
         if(result.status===200)
         {
          this.setState({querySuccess:false});
           this.props.history.push('/setting/equipmenttype/list')
           swal('Save Data Successfully');
         }
         else
         {
          this.setState({querySuccess:false});
          swal(`Data Not Saved`);
         }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
          swal(`${err.response.data.message}`);
        })
  
      }
      if(this.props.actionStatus==='update')
      {
        this.setState({querySuccess:true});
        axios.patch(endPoint.api+'equipmenttype/'+data._id,{old:this.state.oldObj,new:data})
        .then((result)=>{
          const {n,nModified,ok}=result.data;
         if(ok==1&&n==1&&nModified==1)
         {
          this.setState({querySuccess:false});
           this.props.history.push('/setting/equipmenttype/list')
           swal('Updated Data Successfully');
         }
         else
         {
          this.setState({querySuccess:false});
          swal(`${result.data}`);
         }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
          swal(`${err.response.data.message}`);
        })
  
      }
   
     


    }


    render() { 
        return ( 
            <React.Fragment>
       {this.state.querySuccess?<Loading/>:''}
      

            <div className=" border">
              <div className="row justify-content-center">
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Equipment Type</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="equipment Type"
                      name="EquipmentType"
                      onChange={this.handleOnChange}
                    defaultValue={this.state.equipmentType.EquipmentType}
                    />
                    
                    
                    {this.state.errors.EquipmentType && <div className="alert small alert-danger">{this.state.errors['EquipmentType']}</div>}
                   
                  </div>
    
                
                </div>
    
               
               
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.equipmentType);
                  }}
                >
                  Submit
                </button>
              </div>

              <div className="text-center" style={{marginTop:10}}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push('/setting/equipmenttype/list')
                  }}
                >
                 Cancel
                </button>
              </div>
            </div>
          </React.Fragment>
         );
    }
}
 
export default EquipmentType;