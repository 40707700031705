import React from 'react';
import Chart from 'react-google-charts';

const GuageChart = ({data}) => {
//  const chartEvents=[
//     {
//       eventName: "ready",
//       callback: ({ chartWrapper, google }) => {
//         google.visualization.NumberFormat(
//           {suffix: '%',pattern:'#'}
//       );
         
//       }
//     }
//   ]

//   const chartPackage=(pack)=>{
//  // consol(pack)
//   }

    return (   <Chart
       className='small'
       formatters={[
    {
      type: 'NumberFormat',
      column: 1,
      options: {
        suffix: '%',
        // negativeColor: 'red',
        // negativeParens: true,
      },
    },
  ]}
        width={'100%'}
        height={250}
        style={{fontSize:10}}
        chartType="Gauge"
        loader={<div>Loading Chart</div>}
        data={data}
        // chartPackages={chartPackage}
        // chartEvents={chartEvents}
        options={{
          redFrom: 0,
          redTo: 50,
          yellowFrom: 50,
          yellowTo: 75,
          minorTicks: 5,
          greenFrom:75,
          greenTo:100
        }}
        rootProps={{ 'data-testid': '1' }}
      /> );
}
 
export default GuageChart;