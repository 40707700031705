import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import { toPng } from "html-to-image";

function GroupedBarChart({ data, color, vaxisTitle, chartType, height, haxisTitle }) {
  const chartRef = useRef(null);

  // Check if data is valid and has at least one data row
  if (!data || data.length < 2) {
    return (
      <div className="bolder" style={{ textAlign: "center" }}>
        No Data.
      </div>
    );
  }

  const handleDownload = async () => {
    if (chartRef.current) {
      try {
        // Convert the chart container to a PNG image
        const imageDataURL = await toPng(chartRef.current, {
          backgroundColor: "#ffffff", // Set background color
        });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = imageDataURL;
        downloadLink.download = "chart.png"; // File name
        document.body.appendChild(downloadLink);

        // Trigger the download
        downloadLink.click();

        // Remove the download link from the DOM
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Failed to download chart:", error);
      }
    }
  };

  const options = {
    vAxis: {
      title: vaxisTitle,
      minValue: 0,
      textStyle: { fontSize: 14 }, // Small font size for axis labels
      titleTextStyle: { fontSize: 15 }, // Small font size for axis title
    },
    hAxis: {
      title: haxisTitle,
      slantedText: true, // Enable slanted text
      slantedTextAngle: 90, // Specify the angle
      showTextEvery: 1,
      textStyle: { fontSize: 14 }, // Small font size for axis labels
      titleTextStyle: { fontSize: 15 }, // Small font size for axis title
    },
    chartArea: { left: 150, right: 50, top: 20, bottom: 80 },
    legend: { 
      position: "top", 
      textStyle: { color: "blue", fontSize: 16 }, // Customize legend text
    },
    colors: color,
  };
  

  return (
    <>
      <button className="btn btn-primary mx-2" onClick={handleDownload}>
        Download Chart
      </button>
      <div ref={chartRef}>
        <Chart
          chartType={chartType}
          data={data}
          height={height}
          width={"100%"}
          options={options}
        />
      </div>
    </>
  );
}

export default GroupedBarChart;
