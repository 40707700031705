
import React, { useState } from 'react';
import date from '../utill/dateTime';
import Chart from 'react-google-charts';
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import Table from '../Common/table';
const HourlyTableAvailability = ({ data, inputData }) => {
    const [modelState, setModelState] = useState(false);
    const [modelData, setModelData] = useState({});
    const [jobTableData, setJobTableData] = useState([]);

    const sortColumn = { path: 'From', order: 'asc' }

    const handleClickModelState = (one) => {
        setModelState(true);
        setModelData(one);

    }

    const getTableData = (data2) => {
        let jobsArr = []
        if (Object.keys(data2).length > 0) {
            data2.Hours.forEach((hour) => {
                jobsArr = [...jobsArr, ...hour.Jobs]
            })
        }

        return jobsArr;
    }


    const getChartData = (data1) => {
        let arr = []
        if (Object.keys(data1).length > 0) {
            let schema = ['Hours', 'Ideal', 'Actual']
            arr.push(schema);
            data1.Hours.forEach(element => {
                let arr1 = []
                arr1.push(date.getTime(element.From) + '-' + date.getTime(element.Till));
                arr1.push(parseFloat(element.IdealTime));
                arr1.push(parseFloat(element.AvailableTime));
                arr.push(arr1);
            });
        }


        return arr;
    }

    const columns = [
        { path: 'From', label: 'Date', content: (obj) => <label>{date.getDate(obj.From)}</label> },
        { path: 'From', label: 'From', content: (obj) => <label>{date.getTime(obj.From)}</label> },
        { path: 'Till', label: 'Till', content: (obj) => <label>{date.getTime(obj.Till)}</label> },
        { path: 'Equipment', label: 'Equipment' },
        { path: 'EquipmentGroup', label: 'EquipmentGroup' },
        { path: 'Shift', label: 'Shift' },
        { path: 'AvailableTime', label: 'AvailableTime' },

    ]


    return (
        <>
            <table id='HourlyAvailabilityReport' className="table table-sm table-hover small  table-striped table-responsive">
                <thead className='small text-center border'>
                    <tr className='table-primary '>
                        <th rowSpan='2' >Date</th>
                        <th rowSpan='2' >Shift</th>
                        <th rowSpan='2' >Equipment</th>
                        <th rowSpan='2' >EquipmentGroup</th>
                        {data.length > 0 ? data[0].Hours.map(hour => {
                            return <th className='rotate mt-3' colSpan='2' style={{ verticalAlign: 'middle' }} ><div className='mt-2'><span>{date.getTime(new Date(hour.From))}-{date.getTime(new Date(hour.Till))}</span></div></th>
                        }) : ''}
                    </tr>
                    <tr className='table-primary '>
                        {data.length > 0 ? data[0].Hours.map(hour => {
                            return <><th>Ideal</th><th>Actual</th></>
                        }) : ''}
                    </tr>

                </thead>
                <tbody>
                    {
                        data.map((one) => {
                            return <tr>
                                <td>{date.getDateFormat(one.Hours[0].From)}</td>
                                <td>{one.Hours[0].Shift}</td>
                                <td><a href='#' onClick={() => handleClickModelState(one)} style={{ color: 'blue' }}>{one.Equipment}</a></td>
                                <td>{one.EquipmentGroup}</td>
                                {one.Hours.map(hour => {
                                    return <><td>{hour.IdealTime}</td><td>{hour.AvailableTime}</td></>

                                })}
                            </tr>
                        })
                    }


                </tbody>



            </table>
            <Modal
                show={modelState}
                size='xl'
                onHide={() => setModelState(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <p>Hourly Availabilty - {modelData.Equipment}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {<Row>

                        {/* <Col sm={12}>
          <Table columns={columns} sortColumn={sortColumn} data={machineDetail}/>
        </Col>
        <Col sm={4}>
        <Table columns={columns1} sortColumn={sortColumn1} data={operatorDetail}/>

        </Col> */}
                        <Col sm={12}>

                            <Row>
                                <Col style={{height:'100%'}} sm={12}>
                                    {data.length > 0 ?
                                        <>
                                        <Row><div className='ml-5'><p >Date :{date.getDateFormat(inputData.DateTime)}</p></div></Row>
                                        <Row><div className='ml-5'><p >Shift : {inputData.Shift}</p></div></Row>
                                        <Row>
                                            <Chart
                                                width={'100%'}
                                                height={500}
                                                chartType="ColumnChart"
                                                loader={<div>Loading Chart</div>}
                                                data={getChartData(modelData)}
                                                options={{
                                                    // Material design options
                                                    chart: {
                                                        title: ' Hourly Availability For ' + inputData.Shift + "  " + date.getDateFormat(inputData.DateTime),
                                                        subtitle: 'Actual and Ideal Availability Hourly',
                                                    },
                                                    hAxis: {
                                                        slantedText: true,
                                                        slantedTextAngle: 90,
                                                        textStyle: { fontSize: 13 },
                                                       
                                                    },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '2' }}
                                            />
                                        </Row></>
                                        : ''}
                                </Col>

                            </Row>
                        </Col>
                    </Row>}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default HourlyTableAvailability;