import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";
import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import { ToastContainer, toast } from 'react-toastify';




const Refrence = [
  { label: "Equipment", id: "1" },
  { label: "Component", id: "2" },
  { label: "Operator", id: "3" },
  { label: "Tool", id: "4" },
  { label: "Operation", id: "5" },
  { label: "Time", id: "6" },
  { label: "Stage Reasons", id: "7" },
  { label: "Stage", id: "8" },
];

function TalbrosProductionSummary({ fromDateLimit, storeDataInLocalStorage }) {
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [Totaldata, setTotaldata] = useState([]);
  const [data, setchartdata] = useState([]);


  const [selectedDates, setSelectedDates] = useState({
    From: "", Till: ""
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState([]);
  const [selectedItemLengths, setSelectedItemLengths] = useState({});




  // Your existing chart code goes here

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    {
      heading: "Equipment",
      label: "Equipment",
      initialItems: equpdata,
      selectedItemIds: selectedItemIds,
      name: "Equipment",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    },


  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////

  const column2 = [
    { path: "Time", label: "Time" },
    // { path: "Equipment", label: "Equipment" },
    // { path: "EquipmentGroup", label: "EquipmentGroup" },
    { path: "PartName", label: "PartName" },
    { path: "ProcessName", label: "ProcessName" },
    { path: "TargetParts", label: "TargetParts" },
    { path: "QuantityProduced", label: "QuantityProduced" },
    { path: "Rejected", label: "Rejected" },
    { path: "TotalDownTime", label: "TotalDownTime" },

    // { path: "Shift", label: "Shift" },

  ];

  const schema = {
    Equips: joi.array().required().label("Equipment"),
  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
        setQuerySuccess(true);

        // const equipmentdata = await axios.get(api.api1 + "equipment");

        // const downtimedata = await axios.get(api.api + "downtimereason");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

        let equipmentdata = await storeDataInLocalStorage('equipment')
        const downtimelist = downtimedata
          .map((reason, index) => ({
            id: `${index}`,
            label: reason.ReasonGroup,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        downtimelist.push({ id: '40', label: "Down" })


        const uniqueObjects = [];

        // Filter out objects with duplicate combinations of label and id
        const filteredData = downtimelist.filter(obj => {
          // Check if the combination of label and id is already in the uniqueObjects array
          const isDuplicate = uniqueObjects.some(uniqueObj =>
            uniqueObj.label === obj.label
          );

          if (!isDuplicate) {
            // If not, add it to the array and keep the object
            uniqueObjects.push({ id: obj.id, label: obj.label });
            return true;
          }

          // If the combination of label and id is already in the array, filter it out
          return false;
        });

        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        setDynamicData(uniqueObjects);
        setequpdata(equiplist);
        setEquipmentDatalist(equipmentdata)
        // consol("fetching data", downtimelist);

      } catch (error) {
        // console.error("Error fetching data:", error);
        setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts

  const getQualityReason = (ReasonArr) => {
    let returnArr = []
    if (dynamicData.length == 0) {
      returnArr.push({ DowntimeGroup: 'default', Duration: 0 });
    } else {
      dynamicData.forEach((one1) => {
        let filterReason = ReasonArr.filter(obj => obj?.DowntimeGroup == one1.label)
        if (filterReason.length > 0) {
          returnArr.push({ ...filterReason[0] })
        } else {
          let obj = { DowntimeGroup: one1.label, Duration: 0 }
          returnArr.push(obj)
        }
      })
    }

    return returnArr;
  }


  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
      "Equipment",
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Equipment") {

      let noobdata = [];
      newSelectedItems.forEach((item) => {
        let noob = EquipmentDatalist.filter(equipment => equipment.EquipmentID === item);
        noobdata.push(noob[0])
        inputData['Equips'] = noobdata
      })
    } else {
      inputData[name] = newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

    // consol(selectedDates,"current date")
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };

    const errors = validate.validate(inputData, schema);

    setErrors(errors || {});
    if (errors) return;

    setQuerySuccess(true);


    const dateTime = { ...selectedDates };
    if (new Date(dateTime.From) <= new Date(dateTime.Till)) {
      inputData.From = dateTime.From;
      inputData.Till = dateTime.Till;
    } else {
      setQuerySuccess(false);

      return toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    inputData.Report = 'getSummarySheet'
    const results = axios.post(api.api + '/DailyDetailedProductionData', inputData).then(result => {
      // consol(result.data,"data received",inputData);
      if (result.data?.length > 0) {
        // Process the data as needed
        // Assuming result.data is an array of objects

        const totals = result.data.reduce((accumulator, item) => {
          accumulator.PlannedHrs += parseInt(item.PlannedHrs) || 0;
          accumulator.TargetQty += parseInt(item.TargetQty) || 0;
          accumulator.QuantityProduced += parseInt(item.QuantityProduced) || 0;
          accumulator.Reject_Qty += parseInt(item.Reject_Qty) || 0;
          accumulator.Actual_OK_Qty += parseInt(item.Actual_OK_Qty) || 0;
          accumulator.ActualHrs += parseInt(item.ActualHrs) || 0;
          accumulator.TotalLostHrs += parseInt(item.TotalLostHrs) || 0;
          accumulator.UPTIME += parseInt(item.UPTIME) || 0;
          accumulator.TotalOEE += parseInt(item.TotalOEE) || 0;

          return accumulator;
        }, {
          PlannedHrs: 0,
          TargetQty: 0,
          QuantityProduced:0,
          Reject_Qty: 0,
          Actual_OK_Qty: 0,
          ActualHrs: 0,
          TotalLostHrs: 0,
          UPTIME: 0,
          TotalOEE: 0
        });

        // consol(totals,"all the addition");

        setTotaldata(totals)
        setcolumns2(result.data);
        let chartdata = result.data.map(data => { return [data.Equipment, Number(data.UPTIME)] })
        setchartdata([["Equipment", "UPTIME"], ...chartdata]);
      } else {
        swal("Data Not Available");
      }

      setQuerySuccess(false);
    }).catch((err) => {
      toast.success(`${err}`, {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: 'red', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
      // consol(err);
      setQuerySuccess(false);
    });
  };





  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };

  ///////////////////////////// Column chart data ///////////////////

  return (
    <div className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Summary Report
        </h4>
      </div>
      <ToastContainer />
      <div className="row mx-3">
        {equpdata.length > 0 ? <>
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">From</label>
              <input
                style={{ borderColor: "#eaeaea" }}
                className={
                  "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
                }
                min={fromDateLimit}
                placeholder="dd/mm/yyyy"
                type="date"
                onChange={handleOnChange}
                name="From"
              />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">Till</label>
              <input
                style={{ borderColor: "#eaeaea" }}
                className={
                  "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
                }
                placeholder="dd/mm/yyyy"
                type="date"
                onChange={handleOnChange}
                name="Till"
              />
            </div>
          </div>


{console.log(DropDownData,"vosnvv")}
          {DropDownData.map((data, index) => {
            return (
              <>
                <div className="col-sm-2">
                  <div className="form-group text-center">
                    <label className="small">{data.heading}</label>
                    {/* {componentdata.length > 0 && ( */}
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length ? "All" : selectedItemLengths[data.name] || 0}  ${data.heading}`}

                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar}
                    />
                    {/* )} */}
                    {errors[data.heading] && (
                      <p className="text-danger" style={{ fontSize: 11 }}>
                        {errors[data.heading]}
                      </p>
                    )}
                  </div>
                </div>
              </>
            );
          })}

          <div className="col-sm-1">
            <button
              className="btn btn-primary btn-sm mt-4"
              onClick={() => handleOnSubmit()}
            >
              Apply
            </button>
          </div>

        </> : ""}
      </div>
      {columns2.length > 0 ?
        <div className="rounded shadow my-4 " style={{ width: '100%', overflowX: 'scroll' }}>
          <div className="d-flex justify-content-start align-items-center  ">
            <div className="uiverse mx-2" onClick={() =>
              handleDownloadExcel("summary_report")}>
              <span className="tooltip">Dowload Excel File</span>
              <span>
                Download
              </span>
            </div>

          </div>

          <table id={'summary_report'} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0 }}>
            <thead className='small text-center border'>

              <tr className='text-center align-self-center table-primary'>



                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Machine</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Planned Prod Time (mins)</th>

                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Target Qty</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} > Produced Qty</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Reject Qty</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Actual Qty</th>

                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Actual time for Actual Prod (mins)</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Total lost time (mins)</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Up Time(%)</th>

                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Total OEE</th>





                <th style={{ verticalAlign: 'middle' }} colSpan={dynamicData.length + 1}>Loss time Analysis (mins)</th>



              </tr>

              <tr className='table-primary' >


                {dynamicData.length > 0 ? dynamicData.map(one => {
                  return <th className="rotate p-0 m-0 text-start" style={{ height: (100 + dynamicData.length) * 2.8 }} ><div style={{ marginTop: '1rem' }}><span className="text-start">{one.label}</span></div></th>
                }) : ''}

              </tr>

            </thead>
            <tbody>

              {columns2.map(one => <tr >

                <th className="text-center" style={{ fontWeight: '400' }}>{one.Equipment}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.PlannedHrs}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.TargetQty}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.QuantityProduced}</th>

                <th className="text-center" style={{ fontWeight: '400' }}>{one.Reject_Qty}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.Actual_OK_Qty
                }</th>

                <th style={{ fontWeight: '400' }} className="text-center">{one.ActualHrs}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.TotalLostHrs}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.UPTIME}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.TotalOEE}</th>




                {getQualityReason(one.DownTimeSummary).length > 0 ? getQualityReason(one.DownTimeSummary).map(r => {
                  return <td >{r.Duration == 0 ? '0' : (r.Duration)?.toFixed(1)}</td>
                }) : <td >0</td>}

              </tr>
              )}

              <tr>
                <th>Total</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{Totaldata.PlannedHrs}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{Totaldata.TargetQty}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{Totaldata.QuantityProduced}</th>

                
                <th className="text-center" style={{ fontWeight: '400' }}>{Totaldata.Reject_Qty}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{Totaldata.Actual_OK_Qty}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{Totaldata.ActualHrs}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{Totaldata.TotalLostHrs}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{(Totaldata.UPTIME / columns2.length).toFixed(1)}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{(Totaldata.TotalOEE / columns2.length).toFixed(1)}</th>

                {dynamicData.map((one, index) => {
                  // const duration=columns2[index].DownTimeSummary[index]
                  const totalDuration = [...columns2].reduce((total, v) => {
                    const rr=getQualityReason(v.DownTimeSummary)
                    if(rr[index]){
                      return total + parseFloat(rr[index].Duration);
                    }else{
                      return total;
                    }
                  }, 0);
                  console.log(totalDuration,'Api Docs')
                  return (
                    <th key={index} className="text-center" style={{ fontWeight: '400' }}>
                      {/* Total */}
                      {totalDuration.toFixed(1)}
                    </th>
                  );
                })}
              </tr>
            </tbody>
          </table>

          <div>
            <h3>Press Shop UpTime</h3>
            <Chart chartType="ColumnChart" width="100%" height="400px" data={data}
              options={{

                annotations: {
                  style: "line",
                  alwaysOutside: true,
                },
              }}
            />

          </div>

        </div> : ''}




    </div>
  );
}

export default TalbrosProductionSummary;
