import React, { Component } from "react";
import Logo from '../assets/leafnet-logo.jpg';
import '../root.css'
import axios from 'axios';
import api from '../config.json';
import swal from "sweetalert";
import valid from '../utill/validation';
import joi from 'joi';
import Loading from "../utill/LoadingComponent";
import { ToastContainer, toast } from 'react-toastify';


class ForgotForm extends Component {
  state = {
      user:{},
      errors:{},
      querySuccess:false,
  };
  handleOnChange=(e)=>{
   const {user,errors}={...this.state};
   const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];


   user[e.currentTarget.name]=e.currentTarget.value;
   this.setState({user,errors:errors||{}});
  
 }
 schema={
   email:joi.string().email().required(),
   company:joi.string().required()
 }

 handleForgotClick=(user)=>{
  const errors = valid.validate(this.state.user,this.schema);
  this.setState({ errors: errors || {} });
  if (errors) return;


  this.setState({querySuccess:true})
   axios.post(api.api+'forgotpassword',this.state.user,{headers:{Company:user.company}})
   .then((result)=>{
      // // consol(result)
      if(result.status===200)
      {
      
        this.setState({querySuccess:false})
        swal({
          title: "Suceess",
          text:'Password is send to your email id ',
          icon: "success",
          buttons: true,
          
        })
        .then((willDelete) => {
          if (willDelete) {
            this.props.history.push('/');
          } 
        });
      }
   })
   .catch((err)=>{
    this.setState({querySuccess:false})
      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
   })

 }
  render() {
    return (
      <div className="wrapper1 fadeInDown1">    <ToastContainer/>
      {this.state.querySuccess?<Loading/>:''}
        <div id="formContent1">
          <div className="fadeIn1 first1">
            <img
             width={250}
              src={Logo}
              id="icon"
              alt="User Icon"
            />
          </div>

         
            <input
              type="text"
              id="login1"
              className="fadeIn1 second1"
              name="email"
              placeholder="Enter Email"   
              onChange={this.handleOnChange}
            />
            {this.state.errors.email && <div className="alert small alert-danger">{this.state.errors['email']}</div>}
            <input
             required
              type="text"
              id="companyName"
              className="fadeIn1 fourth1"
              name="company"
              placeholder="Company Name"
              onChange={this.handleOnChange}
            />
             {this.state.errors.company && <div className="alert small alert-danger">{this.state.errors['company']}</div>}
           
            <input type="submit" className="fadeIn1 fourth1" value="Forgot" onClick={()=>this.handleForgotClick(this.state.user)} />
        

          <div id="formFooter1">
            <a  className="underlineHover1" style={{cursor:'pointer'}} onClick={()=>this.props.history.push('/')}>
              Back
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotForm;
