import React, { useState,useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from '../utill/LoadingComponent';
import date from '../utill/dateTime';
import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const PerformanceDetailed = ({ user,equips,equipGroups,fromDateLimit,storeDataInLocalStorage }) => {
  const [machineData, setMachineData] = useState([])
  const [view, setView] = useState([])
  const [inputData, setInputData] = useState({})
  const [errors, setErrors] = useState({})
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [index, setIndex] = useState(0)
  const [querySuccess, setQuerySuccess] = useState(false)
  const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })
  const[compList,setCompList]=useState([])

  const [cycle,setcycle]=useState([
    "Both",
    "SPM",
    "Cycle time",
  ])
  
  const [cycleoption,setcycleoption] =useState('')


  // useEffect(()=>{
  //   axios.get(api.api1 + "component")
  //   .then((result)=>{
  //     setCompList(result.data)
  //   })
  //   .catch((err)=>{
  //     // consol(err);
  //   })

  // },[])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const result = await axios.get(api.api1 + 'component');
 let result = await storeDataInLocalStorage('component')

        setCompList(result);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  const schema = {
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
    UserId: joi.string(),
    Equips:joi.array()
  }

  const handleSort = (sortColumn) => {
    if (machineData.length > 0) {
      const sorted = _.orderBy(
        machineData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setMachineData(sorted)
      setSortColumn(sortColumn)
    }
  }


  const columns = [
    (cycleoption ==="Cycle time"?[

    { path: 'Date', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
    { path: 'Shift', label: 'Shift' },
    { path: 'Equipment', label: 'Equipment' },
    { path: 'Component', label: 'Component' },
    {
      key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
        let component = [...compList];
        component = component.filter((one) => one.ComponentId == obj.Component)
        if (component.length > 0) {
          return <label>{component[0].Alias}</label>
        } else {
          return <label>N/A</label>
        }
      }
    },
    { path: 'OperationID', label: 'Operation' },
    { path: 'ToolID', label: 'Tool', content: (obj) => <label>{obj.ToolID == 'null' || obj.ToolID == null ? '-' : obj.ToolID}</label> },
    { path: 'AvailableTime', label: 'Run Time (In Mins)', content: (obj) => <label>{(obj.AvailableTime / 60).toFixed(1)}</label> },
    { path: 'IdealCycleTime', label: 'Ideal Cycle Time (In Sec)', content: (obj) => <label>{obj.IdealCycleTime ? (obj.IdealCycleTime).toFixed(1) : '-'}</label> },
    { path: 'IdealCycles', label: 'Ideal Cycles', content: (obj) => <label>{obj.IdealCycles ? obj.IdealCycles.toFixed(1) : '-'}</label> },
    { path: 'AverageCycleTime', label: 'Average Cycle Time(In Sec)', content: (obj) => <label>{obj.AverageCycleTime ? obj.AverageCycleTime.toFixed(1) : '-'}</label> },
   
    { path: 'Performance', label: 'Performance (%)', content: (obj) => <label>{obj.Performance?obj.Performance.toFixed(1):''}</label> },
 
  ] :([])),
   
  (cycleoption ==="SPM"?[

   
    { path: 'Date', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
    { path: 'Shift', label: 'Shift' },
    { path: 'Equipment', label: 'Equipment' },
    { path: 'Component', label: 'Component' },
    {
      key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
        let component = [...compList];
        component = component.filter((one) => one.ComponentId == obj.Component)
        if (component.length > 0) {
          return <label>{component[0].Alias}</label>
        } else {
          return <label>N/A</label>
        }
      }
    },
    { path: 'OperationID', label: 'Operation' },
    { path: 'ToolID', label: 'Tool', content: (obj) => <label>{obj.ToolID == 'null' || obj.ToolID == null ? '-' : obj.ToolID}</label> },
    { path: 'AvailableTime', label: 'Run Time (In Mins)', content: (obj) => <label>{(obj.AvailableTime / 60).toFixed(1)}</label> },
    { path: 'AverageCycleTime', label: 'Average Cycle Time(In Sec)', content: (obj) => <label>{obj.AverageCycleTime ? obj.AverageCycleTime.toFixed(1) : '-'}</label> },
    
    { path: 'IdealSPM', label: 'Ideal SPM', content: (obj) => <label>{obj.IdealSPM ? obj.IdealSPM.toFixed(1) : '-'}</label> },
    { path: 'AverageSPM', label: 'Average SPM', content: (obj) => <label>{obj.AverageSPM ? obj.AverageSPM.toFixed(1) : '-'}</label> },
    { path: 'ActualCycles', label: 'Actua lCycles' },
    { path: 'Performance', label: 'Performance (%)', content: (obj) => <label>{obj.Performance?obj.Performance.toFixed(1):''}</label> },
  ] :([])),
  (!cycleoption ==="Cycle time" || "SPM"? [

    { path: 'Date', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
    { path: 'Shift', label: 'Shift' },
    { path: 'Equipment', label: 'Equipment' },
    { path: 'Component', label: 'Component' },
    {
      key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
        let component = [...compList];
        component = component.filter((one) => one.ComponentId == obj.Component)
        if (component.length > 0) {
          return <label>{component[0].Alias}</label>
        } else {
          return <label>N/A</label>
        }
      }
    },
    { path: 'OperationID', label: 'Operation' },
    { path: 'ToolID', label: 'Tool', content: (obj) => <label>{obj.ToolID == 'null' || obj.ToolID == null ? '-' : obj.ToolID}</label> },
    { path: 'AvailableTime', label: 'Run Time (In Mins)', content: (obj) => <label>{(obj.AvailableTime / 60).toFixed(1)}</label> },
    { path: 'IdealCycleTime', label: 'Ideal Cycle Time (In Sec)', content: (obj) => <label>{obj.IdealCycleTime ? (obj.IdealCycleTime).toFixed(1) : '-'}</label> },
    { path: 'IdealCycles', label: 'IdealCycles', content: (obj) => <label>{obj.IdealCycles ? obj.IdealCycles.toFixed(1) : '-'}</label> },
    { path: 'IdealSPM', label: 'Ideal SPM', content: (obj) => <label>{obj.IdealSPM ? obj.IdealSPM.toFixed(1) : '-'}</label> },
    { path: 'AverageSPM', label: 'Average SPM', content: (obj) => <label>{obj.AverageSPM ? obj.AverageSPM.toFixed(1) : '-'}</label> },
    
    { path: 'ActualCycles', label: 'Actual Cycles' },
    { path: 'Performance', label: 'Performance (%)', content: (obj) => <label>{obj.Performance?obj.Performance.toFixed(1):''}</label> },

] :([])),
  
  ]
  useEffect(() => {
    const storedValue = localStorage.getItem('selectedCycle');
    if (storedValue) {
      setcycleoption(storedValue);
    }
  }, []);

const handleOncycleChange =(e)=>{
// // consol(e.currentTarget.value,"options ")
e.preventDefault();
setcycleoption(e.currentTarget.value)
localStorage.setItem('selectedCycle', e.currentTarget.value); // Store the selected value in localStorage

}

  const handleOnChange = (e) => {

    if (e.currentTarget.type === 'date') {
      // consol(new Date(e.currentTarget.value))
      // consol(e.currentTarget.value)
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
      setInputData(inputData);
    }
    else if (e.currentTarget.type === 'time') {
      let Time = e.currentTarget.value.split(':');
      // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
      // consol(e.currentTarget.value)
      inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
      setInputData(inputData);
    }
    else {
      inputData[e.currentTarget.name] = e.currentTarget.value
    }
    setErrors({})

  }
  const handleOnSubmit = async (inputData) => {
    setIndex(0);
    machineData.splice(0,machineData.length);
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    inputData.UserId = user._id
    
  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    let equipFroReport=getEquipsForReport()
    for (let i = 0; i < equipFroReport.length; i++) {
      try {
        inputData.Equips = [equipFroReport[i]]
        let result = await axios.post(api.api + 'GetMachinePerformance', inputData)
        if (result.data.length > 0) {
          let idx = i + 1;
          result.data.forEach((one)=>{
            one.Detailed.forEach((oneJob)=>{
              machineData.push(oneJob)
            })
          })
          setMachineData(machineData)
          setIndex(idx)
        }
      }
      catch (err) {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        setQuerySuccess(false)
      }
    }

    setQuerySuccess(false)
  }

  const handleDownload = (e, dateTime) => {
    generateExcelFile(e, dateTime);
  }

  const setSelection=(key,option,equips)=>{
    if(key=='EquipmentGroup'){
        SetEquips(equips?equips:[]);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if(key=='Equipment'){
       
        setSelectedEquipOption(option)
    }
}

const getEquipsForReport=()=>{
    let equipArr=[]
    equips.forEach((one)=>{
        selectedEquipOption.forEach((option)=>{
            if(one._id==option.value){
                equipArr.push(one);
            }
        })
    })
    return equipArr;
}
const SelectedDates =(dates)=>{
  setInputData(dates)
}
  return (
    <React.Fragment>
      {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`} /> : ''}
      <div className='text-center' >
        <p >Machine Performance Detailed</p>
      </div>
      <ToastContainer/>
      <div className="row">
      {equipGroups && equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}
      <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input className='form-control form-control-sm' type='date' min={fromDateLimit} onChange={handleOnChange} name='From' />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}

          </div>
          </div> */}
          {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Periodicity</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewBy'>
              <option>--select  report--</option>
              <option>Detailed</option>
              <option>Overview</option>
            </select>
            {errors.ViewBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewBy}</p>}
         
          </div>
        </div> */}
          {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}

          </div>
          </div> */}
          {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='Till' />
          </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Periodicity</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewBy'>
              <option>--select periodicity--</option>
              <option>Hourly</option>
              <option>Daily</option>
              <option>Shift</option>
            </select>
            {errors.ViewBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewBy}</p>}
         
          </div>
        </div> */}

        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>

        <div className="col-sm-2">
      
              <div className="form-group text-center">
                <label>  SPM / Cycle time </label>
                <select
                  className="custom-select form-control"
                  name="cycletime"
                 value={cycleoption}
                  onChange={handleOncycleChange}
                  placeholder='selectCycleTime'
                >
                  {cycle.map(one => {
                    return <option>{one}</option>
                  })}
                </select>
              
              </div>

           
           
        </div>
        {machineData.length > 0 ? <div className="col-sm-2">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('Machine_Performance_Detailed', inputData.DateTime)}>Download Excel</button>
        </div> : ''}



      </div>
      <div>
        {machineData.length > 0 ? <Table columns={columns[0].length> 0?columns[0]:columns[1].length>0?columns[1]:columns[2]} id='Machine_Performance_Detailed' onSort={handleSort} sortColumn={sortColumn} data={machineData} /> : ''}
      </div>
    </React.Fragment>


  );
}

export default PerformanceDetailed;