import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";
import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import { ToastContainer, toast } from 'react-toastify';




const Refrence = [
  { label: "Equipment", id: "1" },
  { label: "Component", id: "2" },
  { label: "Operator", id: "3" },
  { label: "Tool", id: "4" },
  { label: "Operation", id: "5" },
  { label: "Time", id: "6" },
  { label: "Stage Reasons", id: "7" },
  { label: "Stage", id: "8" },
];

function AvailabilityRejectionOEEReport({fromDateLimit,storeDataInLocalStorage}) {
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [Totaldata, setTotaldata] = useState([]);
  const [data, setchartdata] = useState([]);

  
  const [selectedDates, setSelectedDates] = useState({
    From: "",Till:""
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState([]);
  const [selectedItemLengths, setSelectedItemLengths] = useState({});
  const [Cumulative, setCumulative] = useState([]);
  const [rawdata, setrawdata] = useState([]);

  
  


 

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////

  const column2 = [
    { path: "Time", label: "Time" },
    // { path: "Equipment", label: "Equipment" },
    // { path: "EquipmentGroup", label: "EquipmentGroup" },
    { path: "PartName", label: "PartName" },
    { path: "ProcessName", label: "ProcessName" },
    { path: "TargetParts", label: "TargetParts" },
    { path: "QuantityProduced", label: "QuantityProduced" },
    { path: "Rejected", label: "Rejected" },
    { path: "TotalDownTime", label: "TotalDownTime" },

    // { path: "Shift", label: "Shift" },

  ];

  const schema = {
    From: joi.string().required().label("Equipment"),
    Till: joi.string().required().label("Equipment"),

  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
      setQuerySuccess(true);

        // const equipmentdata = await axios.get(api.api1 + "equipment");
    
        // const downtimedata = await axios.get(api.api + "downtimereason");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

        let equipmentdata = await storeDataInLocalStorage('equipment')
        const downtimelist = downtimedata
        .map((reason, index) => ({
          id: `${index}`,
          label: reason.ReasonGroup,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));

        downtimelist.push({id:'40',label:"Down"})


        const uniqueObjects = [];

        // Filter out objects with duplicate combinations of label and id
        const filteredData = downtimelist.filter(obj => {
          // Check if the combination of label and id is already in the uniqueObjects array
          const isDuplicate = uniqueObjects.some(uniqueObj =>
            uniqueObj.label === obj.label
          );
        
          if (!isDuplicate) {
            // If not, add it to the array and keep the object
            uniqueObjects.push({ id: obj.id, label: obj.label });
            return true;
          }
        
          // If the combination of label and id is already in the array, filter it out
          return false;
        });
        
        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        setDynamicData(uniqueObjects);
        setequpdata(equiplist);
        setEquipmentDatalist(equipmentdata)
        // consol("fetching data", downtimelist);
        
      } catch (error) {
        // console.error("Error fetching data:", error);
      setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts

  const getQualityReason = (ReasonArr) => {
    let returnArr = []
    if (dynamicData.length == 0) {
        returnArr.push({ DowntimeGroup: 'default', Duration: 0 });
    } else {
      dynamicData.forEach((one1) => {
            let filterReason = ReasonArr.filter(obj => obj?.DowntimeGroup == one1.label)
            if (filterReason.length > 0) {
                returnArr.push({ ...filterReason[0] })
            } else {
                let obj = { DowntimeGroup: one1.label, Duration: 0 }
                returnArr.push(obj)
            }
        })
    }

    return returnArr;
}


  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
      "Equipment",
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Equipment") {

     let noobdata  = [];
      newSelectedItems.forEach((item) => {
      let noob = EquipmentDatalist.filter(equipment => equipment.EquipmentID === item);
      noobdata.push(noob[0])
      inputData['Equips'] = noobdata
    })
    } else {
      inputData[name] = newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

    // consol(selectedDates,"current date")
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
  

    setQuerySuccess(true);
  
   
      const dateTime = { ...selectedDates };
      if(new Date(dateTime.From) <= new Date(dateTime.Till)){
        inputData.From = dateTime.From;
        inputData.Till = dateTime.Till;
      }else{
        setQuerySuccess(false);
       
        return  toast.success('From date should be Less Than Till date', {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#babbbb', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      }
      const errors = validate.validate(inputData, schema);
  
      setErrors(errors || {});
      if (errors) return;
    
      inputData.Report = 'getSummarySheet'
      const results =  axios.put(api.api + 'AvailabilityRejectionOEEReport', inputData).then(result =>{
          // console.log(result.data,"data received");
      if(result.data?.length > 0) {
        
      let Tabledata =[];
      let  Cumulative= [
        ["Shift", "Availability",{ role: "style" },{ role: 'annotation' },"OEE",{ role: "style" },{ role: 'annotation' },"Rejection",{ role: "style" },{ role: 'annotation' }]];
      result.data.map((items) =>{ 
      
     
        //  let AcceptedParts = items.AcceptedParts;
        //  let Availability = items.Availability;
        //  let OEE = items.OEE;
        //  let Rejection = items.Rejection;
         let Shift = items.Shift;
        //  let TotalRejection =items.TotalRejection;
        Cumulative.push([items.Shift,Number(items.Availability),'fill-color:#529165;opacity: 0.9',Number(items.Availability),Number(items.OEE),'fill-color:#40689e;opacity: 0.9',Number(items.OEE),Number(items.Rejection),'fill-color:red;opacity: 0.9',Number(items.Rejection)])
        let  Availability= [
            ["Equipment", "Availability", { role: "style" },{ role: 'annotation' }],
            
          ];
          let  OEE= [
            ["Equipment", "OEE", { role: "style" },{ role: 'annotation' }],
           
          ];
          let  Rejection= [
            ["Equipment", "OEE", { role: "style" },{ role: 'annotation' }],
           
          ];
          items.Data.sort((a,b)=>a.Equipment -b.Equipment)
          // console.log(items.Data,"sorted data")
      items.Data.map(one=>{
        Availability.push( [one.Equipment, Number(one.Availability), 'fill-color:#529165;opacity: 0.9',Number(one.Availability)])
        OEE.push( [one.Equipment, Number(one.OEE), 'fill-color:#40689e;opacity: 0.9',Number(one.OEE)])
        Rejection.push( [one.Equipment, Number(one.TotalRejection), 'fill-color:red;opacity: 0.9',Number(one.TotalRejection)])


        //  let Accepted= one.Accepted;
        //  let Availability= one.Availability;
        //  let NumberOfJobs= one.NumberOfJobs;
        //  let OEE= one.OEE;
        //  let Rejection= one.Rejection;
        //  let Shift= one.Shift;
        //  let TotalRejection= one.TotalRejection;
        //  let Equipment= one.Equipment;
        //  Tabledata.push({AcceptedParts,Availability,OEE,Rejection,Shift,TotalRejection,Equipment,NumberOfJobs,OEE,Rejection,Shift,Accepted})
      })
      Tabledata.push({Shift,Cumulative,Availability,OEE,Rejection})

     })
    //  console.log(Tabledata,"fisdvbisuf",Cumulative)
     setCumulative(Cumulative)
         setcolumns2(Tabledata);
         setrawdata(result.data)
    //  return
      } else {
        swal("Data Not Available");
      }
  
      setQuerySuccess(false);
    }).catch((err) => {
          swal(`${err}`);
          // consol(err);
          setQuerySuccess(false);
        });
  };
  





  

  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };

  ///////////////////////////// Column chart data ///////////////////

  return (
    <div  className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
        Summary Report (3)
        </h4>
      </div>
      <ToastContainer/>
      <div className="row mx-3">
       {equpdata.length > 0 ?  <>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
              />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              />
          </div>
        </div>

            
<div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div>

 </>:""}           
    </div>
    {rawdata.length >0 &&<div>
<h4 className="text-center">Availability , OEE and Rejection</h4>
<Chart
  chartType="ColumnChart"
  options={{
    hAxis: {
      textStyle: {
        color: '#333', // Text color
        fontName: 'Arial', // Font family
        fontSize: 12, // Font size
        bold: true, // Bold text
        italic: false // Not italicized
      },
      slantedText: true, // Rotate text
      slantedTextAngle: 90 // Angle of rotation
    },
    chartArea: {left:80,top:50,right:50,bottom:60},
    annotations: {
      style: 'line', // or 'point', or 'text', or 'arrow'
      textStyle: {
        fontSize: 12,
        fontName: 'Arial',
      },
      stemColor: 'none', // No stem
      position: 'vertical',
      stem: {
        color: 'transparent',
        length: '50%', // Set the stem length to 50% to position it in the middle of the bars
      },
      // Align annotations vertically
    },
    series: {
      0: { color: '#529165' },
      1: { color: '#40689e' }, // Change the color of the first series
      2: { color: 'red' }, // Change the color of the first series
       // Change the color of the first series
      // Add more as needed for each series
    },
    width: '100%',
    height: '400px'
  }}
  data={Cumulative}
/>


</div>}
{columns2.map(item =><>    

<div>
<h4 className="text-center"> Availability (%) {item.Shift}</h4>
<Chart chartType="ColumnChart"  options={{
    chartArea: {left:80,top:50,right:50,bottom:60},  hAxis: {
      textStyle: {
        color: '#333', // Text color
        fontName: 'Arial', // Font family
        fontSize: 12, // Font size
        bold: true, // Bold text
        italic: false // Not italicized
      },
      slantedText: true, // Rotate text
      slantedTextAngle: 90 // Angle of rotation
    },
    annotations: {
      style: 'line', // or 'point', or 'text', or 'arrow'
      textStyle: {
        fontSize: 12,
        fontName: 'Arial',
      },
      stemColor: 'none', // No stem
      position: 'vertical',
      stem: {
        color: 'transparent',
        length: '50%', // Set the stem length to 50% to position it in the middle of the bars
      },
      // Align annotations vertically
    },
    series: {
      0: { color: '#529165' },
       // Change the color of the first series
      // Add more as needed for each series
    },
    width: '100%',
    height: '400px'
  }} width="100%" height="400px" data={item.Availability} />


    </div> 
<div>
<h4 className="text-center">OEE (%) {item.Shift}</h4>
<Chart chartType="ColumnChart"  options={{  hAxis: {
      textStyle: {
        color: '#333', // Text color
        fontName: 'Arial', // Font family
        fontSize: 12, // Font size
        bold: true, // Bold text
        italic: false // Not italicized
      },
      slantedText: true, // Rotate text
      slantedTextAngle: 90 // Angle of rotation
    },
    chartArea: {left:80,top:50,right:50,bottom:60},
    annotations: {
      style: 'line', // or 'point', or 'text', or 'arrow'
      textStyle: {
        fontSize: 12,
        fontName: 'Arial',
      },
      stemColor: 'none', // No stem
      position: 'vertical',
      stem: {
        color: 'transparent',
        length: '50%', // Set the stem length to 50% to position it in the middle of the bars
      },
      // Align annotations vertically
    },
    series: {
    
      0: { color: '#40689e' }, // Change the color of the first series
       // Change the color of the first series
      // Add more as needed for each series
    },
    width: '100%',
    height: '400px'
  }} width="100%" height="400px" data={item.OEE} /> 


    </div> 
    <div>
<h4 className="text-center">Rejection (%) {item.Shift}</h4>
<Chart chartType="ColumnChart"  options={{
    chartArea: {left:80,top:50,right:50,bottom:60},
    hAxis: {
      textStyle: {
        color: '#333', // Text color
        fontName: 'Arial', // Font family
        fontSize: 12, // Font size
        bold: true, // Bold text
        italic: false // Not italicized
      },
      slantedText: true, // Rotate text
      slantedTextAngle: 90 // Angle of rotation
    },
    annotations: {
      style: 'line', // or 'point', or 'text', or 'arrow'
      textStyle: {
        fontSize: 10,
        fontName: 'Arial',
      },
      stemColor: 'none', // No stem
      position: 'vertical',
      stem: {
        color: 'transparent',
        length: '50%', // Set the stem length to 50% to position it in the middle of the bars
      },
      // Align annotations vertically
    },
    series: {
  
      0: { color: 'red' }, // Change the color of the first series
       // Change the color of the first series
      // Add more as needed for each series
    },
    width: '100%',
    height: '400px'
  }} width="100%" height="400px" data={item.Rejection} />
    </div>

 </>)  


}




    </div>
  );
}

export default AvailabilityRejectionOEEReport;
