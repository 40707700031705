import React, { Component } from 'react';
import axios from '../../../httpServices/httpService';
import api from '../../../config';
import swal from 'sweetalert';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';
import dateTime from '../../../utill/dateTime';
import { ToastContainer, toast } from 'react-toastify';


class Operator extends Component {

  state = {
    equipmentGroupList: [],
    equipmentList: [],

    operatorList: [],
    FromDate:'',
    FromTime:"",
    TillDate:"",
    TillTime:"",

    operatorData: { RunningStatus: 'NotRunning' },
    errors: {},
    querySuccess: false,
    RunningStatus: 'NotRunning',
    old: {}

  }
  componentDidMount() {
    if (this.props.actionStatus === "update") {

      const old = { ...this.props.dataEntryOperatorData }
      this.setState({
        FromDate:this.getDate(this.props.dataEntryOperatorData.From),
        FromTime:this.getTime(this.props.dataEntryOperatorData.From),
        TillDate:this.getDate(this.props.dataEntryOperatorData.Till),
        TillTime:this.getTime(this.props.dataEntryOperatorData.Till),
        old: old,
        operatorData: this.props.dataEntryOperatorData,
        RunningStatus: this.props.dataEntryOperatorData.Till
          ? "NotRunning"
          : "Running",
      });
    }
    if (this.props.actionStatus === "add") {
      this.setState({ operatorData: { RunningStatus: "Running" } });
    }

    this.setState({ querySuccess: true })
    let eqpgrpList = axios.get(api.api1 + 'equipmentgroup');
    let compList = axios.get(api.api1 + 'equipment');
    let opList = axios.get(api.api1 + 'operator');

    Promise.all([eqpgrpList, compList, opList]).then((result) => {
      // consol(result)
      this.setState({ equipmentGroupList: result[0].data, equipmentList: result[1].data, operatorList: result[2].data, querySuccess: false });
    })
      .catch((err) => {
        this.setState({ querySuccess: false })
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })

  }
  schema = {
    Equipment: joi.string().required(),
    EquipmentGroup: joi.string().required(),
    OperatorID: joi.string().required().label('Operator ID'),
    Operator: joi.string().required(),
    RunningStatus: joi.string(),//.required(),
    // FromTime: joi.string(),//.required(),
    // FromDate: joi.string(),//.required(),
    // TillTime: joi.string(),//.when('RunningStatus', { is: 'NotRunning', then: joi.required() }),
    // TillDate: joi.string(),//.when('RunningStatus', { is: 'NotRunning', then: joi.required() }),


  }
  schema1 = {
    Equipment: joi.string().required(),
    EquipmentGroup: joi.string().required(),
    OperatorID: joi.string().required().label('Operator ID'),
    Operator: joi.string().required(),
    RunningStatus: joi.string(),//.required(),
    From: joi.date(),//.required(),//.label('From Datetime'),
    Till: joi.date().label('Till Datetime')//.when('RunningStatus', { is: 'NotRunning', then: joi.required() }),

  }
  getDate = (datetime) => {
    if (datetime) {
      let date = new Date(datetime);
      return (
        date.getFullYear() +
        "-" +
        ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
        "-" +
        ((date.getDate() < 10 ? "0" : "") + date.getDate())
      );
    }
  };
  getTime = (datetime) => {
    if (datetime) {
      let Time = new Date(datetime);
      return (
        (Time.getHours() < 10 ? "0" : "") +
        Time.getHours() +
        ":" +
        (Time.getMinutes() < 10 ? "0" : "") +
        Time.getMinutes()
      );
    }
  };

  handleOnChange = (e) => {
    const { operatorData, errors } = { ...this.state };

    if(e.currentTarget.name!=='FromTime' && e.currentTarget.name!=='FromDate' &&  e.currentTarget.name!=='TillTime' &&  e.currentTarget.name!=='TillDate'){
      const errorMessage = valid.validateProperty(e.currentTarget, this.schema);
      if (errorMessage) errors[e.currentTarget.name] = errorMessage;
      else delete errors[e.currentTarget.name];
    }


    if (e.currentTarget.name === 'FromDate' || e.currentTarget.name === 'FromTime') {
      if (e.currentTarget.name === 'FromDate') {
        // let date=new Date(e.currentTarget.value);
        // date.setHours(0,0);
        // operatorData['From'] = date;
        this.setState({FromDate:e.currentTarget.value})
      }
      if (e.currentTarget.name === 'FromTime') {
      //   let Time = e.currentTarget.value.split(':');
      //   operatorData['From'] = new Date(new Date(operatorData.From).setHours(Time[0], Time[1]))
      this.setState({FromTime:e.currentTarget.value})
       }
     
    }
    else if (e.currentTarget.name === 'TillDate' || e.currentTarget.name === 'TillTime') {
      if (e.currentTarget.name === 'TillDate') {
        // let date=new Date(e.currentTarget.value);
        // date.setHours(0,0)
        // operatorData['Till'] = date;
        this.setState({TillDate:e.currentTarget.value})
      }
      if (e.currentTarget.name === 'TillTime') {
        // let Time = e.currentTarget.value.split(':');
        // operatorData['Till'] = new Date(new Date(operatorData.From).setHours(Time[0], Time[1]))
        this.setState({TillTime:e.currentTarget.value})
      }
    }
    else if (e.currentTarget.name === 'EquipmentGroup') {
      operatorData[e.currentTarget.name] = e.currentTarget.value;
      let eqpGroup = this.state.equipmentGroupList.filter(one => one.EquipmentGroup == e.currentTarget.value);
      axios.put(api.api1 + "equipment", { EquipmentGroup: eqpGroup[0]._id })
        .then((result) => {
          this.setState({ equipmentList: result.data })
        })
        .catch((err) => {
          swal(`${err}`)
        });
    }
    else {
      if (e.currentTarget.name === "Operator") {
        let optionElement = e.target.childNodes[e.target.selectedIndex]
        let option = optionElement.getAttribute('className');
        operatorData[e.currentTarget.name + 'ID'] = option;
      }
      operatorData[e.currentTarget.name] = e.currentTarget.value;
    }
    this.setState({ operatorData, errors: errors || {} });
    // consol(operatorData);
  }

  handleRadioChange = (e) => {
    const { operatorData, errors } = { ...this.state };
    operatorData[e.currentTarget.name] = e.currentTarget.value === 'Running' ? 'NotRunning' : 'Running';
    this.setState({ [e.currentTarget.name]: e.currentTarget.value === 'Running' ? 'NotRunning' : 'Running', operatorData })
    // consol(operatorData);
  }

  setDateTimeToData=(data)=>{
    let from=new Date(this.state.FromDate)
    let fromTime=this.state.FromTime.split(':')
    from.setHours(fromTime[0],fromTime[1])
    data['From']=from
    let till=new Date(this.state.TillDate)
    let tillTime=this.state.TillTime.split(':')
    till.setHours(tillTime[0],tillTime[1])
    data['Till']=till
    return data
  }


  handleSubmit = (data1) => {
    let data = { ...data1 }
     const {FromTime,TillTime,FromDate,TillDate}={...this.state}
     if(FromDate==""||FromTime==""){swal('From Date Time Not Allowed To Be Emphty');return }
     if(TillDate==""||TillTime==""){swal('Till Date Time Not Allowed To Be Emphty');return }
     data=this.setDateTimeToData(data)
    data=this.setDateTimeToData(data)
    data.From.setMilliseconds(1)
    if(dateTime.getDifferenceBetweenTimes(data.From,data.Till).minutes<1){
     swal('From Time and Till Time Diff Should Be Greater Than One Minutes',{dangerMode:true})
     return
    }
    if(data.From>data.Till){
     swal('from time is should be less than till time',{dangerMode:true})
     return
    }
  
    if (this.props.actionStatus === 'update') {
      // if (data.RunningStatus === 'NotRunning') {
      //   //delete data.RunningStatus
      //   data.RunningStatus = false
      // }
      // if (data.RunningStatus === 'Running') {
      //   data.RunningStatus = true
      //   //delete data.RunningStatus
      //   delete data.Till
      // }
      this.setState({ querySuccess: true });
      axios
        .patch(api.api + "operatorselected/" + data._id, {
          old: this.state.old,
          new: data,
        })
        .then((result) => {
          const { n, nModified, ok } = result.data;
          if (ok === 1 && n === 1 && nModified === 1) {
            this.setState({ querySuccess: false });
            this.props.handleCloseAddOperator()
            swal("Update Data Successfully");
          } else {
            this.setState({ querySuccess: false });
            swal('Not Updated Some Internal Error');
          }
        })
        .catch((err) => {
          if(err.response.status==409){
            this.setState({querySuccess:false});
            swal(`${err.response.data.message}`);
          }else
          {
            swal({
              title: "Allready Deployed You Want Shared With Another Equipment Press Ok Otherwise cancel",
              text: `${err.response.data.message}`,
              icon: "warning",
              buttons: true,
              dangerMode: true
            }).then(willDelete => {
              if (willDelete) {
                this.setState({querySuccess:true});
                axios
                  .post(api.api + "updateOperatorOnSharedEquipment/"+data._id, {
                    old: this.state.old,
                    new: data,
                  })
                  .then(result => {
                    const { n, nModified, ok } = result.data;
                    if (ok === 1 && n === 1 && nModified === 1) {
                      this.setState({ querySuccess: false });
                      this.props.handleCloseAddOperator()
                      swal("Update Data Successfully");
                    } else {
                      this.setState({ querySuccess: false });
                      swal('Not Updated Some Internal Error');
                    }
                  })
                  .catch(err => {
                    this.setState({querySuccess:false});
                      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                  });
              } else {
                this.setState({querySuccess:false});
                // swal("Your imaginary file is safe!");
              }
            });
          }
       
        });
    }
    else {
      const errors = valid.validate(data, this.schema1);
      this.setState({ errors: errors || {} });
      if (errors) return;
      // if (data.RunningStatus === 'NotRunning') {
      //   //delete data.RunningStatus
      //   data.RunningStatus = false;
      // }
      // if (data.RunningStatus === 'Running') {
      //   data.RunningStatus = true;
      //   // delete data.RunningStatus
      //   delete data.Till
      // }
      this.setState({ querySuccess: true })
      axios.post(api.api + 'operatorselected', data)
        .then((result) => {
          if (result.status == 200) {
            this.setState({ querySuccess: false })
            swal(`Data Submitted Successfully`);
            this.props.handleCloseAddOperator()
            this.props.handleOnSubmit(this.props.inputData)
          }
          else {
            this.setState({ querySuccess: false })
            swal(`${result.data.message}`)
          }
        })
        .catch((err) => {
          if(err.response.status==409||err.response.status==410,err.response.status==500){
            this.setState({querySuccess:false});
            swal(`${err.response.data.message}`);
          }else{
            swal({
              title: "Allready Deployed You Want Shared With Another Equipment Press Ok Otherwise cancel",
              text: `${err.response.data.message}`,
              icon: "warning",
              buttons: true,
              dangerMode: true
            }).then(willDelete => {
              if (willDelete) {
                this.setState({querySuccess:true});
                axios
                  .post(api.api + "deployOperatorOnSharedEquipment",data)
                  .then(result => {
                    if (result.status == 200) {
                      this.setState({ querySuccess: false })
                      swal(`Data Submitted Successfully`);
                      this.props.handleCloseAddOperator()
                      this.props.handleOnSubmit(this.props.inputData)
                    }
                    else {
                      this.setState({ querySuccess: false })
                      swal(`${result.data.message}`)
                    }
                  })
                  .catch(err => {
                    this.setState({querySuccess:false});
                      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                  });
              } else {
                this.setState({querySuccess:false});
                // swal("Your imaginary file is safe!");
              }
            });
          }
          
        })
    }
  }

  render() {
    return (
      <div className='border ' >
      <ToastContainer/>

        {this.state.querySuccess ? <Loading /> : ''}
        <div className="row  justify-content-center">
          <div className="col-sm-6 text-center">
            <p>Set Operator</p>
            <div className="form-group text-center">
              <label className='small'>Equipment Group</label>
              <select
                className="form-control form-control-sm custom-select"
                onChange={this.handleOnChange}
                name='EquipmentGroup'
                disabled={this.props.actionStatus=='update'}
                value={this.state.operatorData.EquipmentGroup}
              >
                <option>--select equipment group--</option>
                {this.state.equipmentGroupList.map(one => {
                  return <option>{one.EquipmentGroup}</option>
                })}
              </select>
              {this.state.errors.EquipmentGroup && <div className="alert small alert-danger">{this.state.errors['EquipmentGroup']}</div>}
            </div>
            <div className="form-group text-center">
              <label className='small'>Equipment</label>
              <select
                className="form-control form-control-sm custom-select"
                onChange={this.handleOnChange}
                name='Equipment'
                value={this.state.operatorData.Equipment}
                disabled={this.props.actionStatus=='update'}
              >
                <option>--select equipment  --</option>
                {this.state.equipmentList.map(one => {
                  return <option>{one.EquipmentID}</option>
                })}
              </select>
              {this.state.errors.Equipment && <div className="alert small alert-danger">{this.state.errors['Equipment']}</div>}
            </div>
            <div className="form-group text-center">
              <label className='small'>Operator</label>
              <select
                className="form-control form-control-sm custom-select"
                onChange={this.handleOnChange}
                name='Operator'
                disabled={this.props.actionStatus=='update'}
                value={this.state.operatorData.Operator}
              >
                <option>--select operator --</option>
                {this.state.operatorList.map(one => {
                  return <option className={one.OperatorID}>{one.Name}</option>
                })}
              </select>
              {this.state.errors.Operator && <div className="alert small alert-danger">{this.state.errors['Operator']}</div>}
            </div>
            <div className='row'>
              <div className="form-group text-center col-sm-6">
                <label className='small'>From Date</label>
                <input className='form-control form-control-sm' defaultValue={this.state.FromDate} onChange={this.handleOnChange} type='date' name='FromDate' />
                {this.state.errors.FromDate && <div className="alert small alert-danger">{this.state.errors['FromDate']}</div>}
              </div>
              <div className="form-group text-center col-sm-6">
                <label className='small'>From Time</label>
                <input className='form-control form-control-sm' defaultValue={this.state.FromTime} onChange={this.handleOnChange} type='time' name='FromTime' />
                {this.state.errors.FromTime && <div className="alert small alert-danger">{this.state.errors['FromTime']}</div>}
              </div>
              {this.state.errors.From && <div className="alert small alert-danger">{this.state.errors['From']}</div>}
            </div>
            {/* <div className='form-group'>
              <input type='checkbox' name='RunningStatus' value={this.state.RunningStatus} onChange={this.handleRadioChange} checked={this.state.RunningStatus === 'Running'} />
          &nbsp;&nbsp;&nbsp;<label className='small'>Currently Working</label>
            </div> */}
            {this.state.RunningStatus === 'NotRunning' ? <div className='row'>
              <div className="form-group text-center col-sm-6">
                <label className='small'>Till Date</label>
                <input className='form-control form-control-sm' onChange={this.handleOnChange} defaultValue={this.state.TillDate} type='date' name='TillDate' />
                {this.state.errors.TillDate && <div className="alert small alert-danger">{this.state.errors['TillDate']}</div>}
              </div>
              <div className="form-group text-center col-sm-6">
                <label className='small'>Till Time</label>
                <input className='form-control form-control-sm' defaultValue={this.state.TillTime} onChange={this.handleOnChange} type='time' name='TillTime' />
                {this.state.errors.FromTime && <div className="alert small alert-danger">{this.state.errors['TillTime']}</div>}

              </div>
              {this.state.errors.Till && <div className="alert small alert-danger">{this.state.errors['Till']}</div>}

            </div> : ''}
          </div>
        </div>
        <div className="text-center">
          <button
            className="btn btn-primary"
            onClick={() => {
              this.handleSubmit(this.state.operatorData);
            }}
          >
            Save
                </button>
        </div>
      </div>
    );
  }
}

export default Operator;