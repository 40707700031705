import React, { Component } from 'react';
import axios from '../../../httpServices/httpService';
import endPoint from '../../../config';
import swal from 'sweetalert';
import valid from '../../../utill/validation';
import joi from 'joi';
import LoadingOverlay from 'react-loading-overlay'
import Loading from '../../../utill/LoadingComponent';



class OperationMaster extends Component {
    state = { 
        OperationMaster:{},
        oldObj:{},
        errors:{},
        querySuccess:false,

     }
     schema={
       OperationName:joi.string().required()
     }
     componentDidMount()
     {    
       if(this.props.actionStatus==='add')
       {
         this.setState({OperationMaster:{}})
       }
       if(this.props.actionStatus==='update')
       {
         const UserGroup={...this.props.operationMasterData}
         this.setState({OperationMaster:this.props.operationMasterData,oldObj:UserGroup});
       }
     }

    handleOnChange=(e)=>{
      const {OperationMaster,errors} ={...this.state};
      const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    
    OperationMaster[e.currentTarget.name]=e.currentTarget.value;
        this.setState({OperationMaster,errors:errors||{}});
        
        

    }
    handleSubmit=(data)=>{
      
     
      if(this.props.actionStatus==='add')
      {
        const errors = valid.validate(data,this.schema);
        // consol(data)
        // consol(errors)
      this.setState({ errors: errors || {} });
      if (errors) return;
  
      this.setState({querySuccess:true});
        axios.post(endPoint.api+'operation',data)
        .then((result)=>{
         if(result.status===200)
         {
          this.setState({querySuccess:false});
           this.props.history.push('/setting/operation/list')
           swal('Save Data Successfully');
         }
         else
         {
          this.setState({querySuccess:false});
          swal(`Data Not Saved`);
         }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
          swal(`${err.response.data.message}`);
        })
  
      }
      if(this.props.actionStatus==='update')
      {
        this.setState({querySuccess:true});
      
        axios.patch(endPoint.api+'operation/'+data._id,{old:this.state.oldObj,new:data})
        .then((result)=>{
          const {n,nModified,ok}=result.data;
         if(ok==1&&n==1&&nModified==1)
         {
          this.setState({querySuccess:false});
           this.props.history.push('/setting/operation/list')
           swal('Updated Data Successfully');
         }
         else
         {
          this.setState({querySuccess:false});
          swal(`${result.data}`);
         }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
          swal(`${err.response.data.message}`);
        })
  
      }
   


    }


    render() { 
        return ( 
            <React.Fragment>
       {this.state.querySuccess?<Loading/>:''}
      

            <div className=" border">
              <div className="row justify-content-center">
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Operation Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="operation name"
                      name="OperationName"
                      onChange={this.handleOnChange}
                    defaultValue={this.state.OperationMaster.OperationName}
                    />
                    {this.state.errors.OperationName && <div className="alert small alert-danger">{this.state.errors['OperationName']}</div>}
                  </div>
    
                
                </div>
    
               
               
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.OperationMaster);
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="text-center" style={{marginTop:10}}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push('/setting/operation/list')
                  }}
                >
                 Cancel
                </button>
              </div>
            </div>
          </React.Fragment>
         );
    }
}
 
export default OperationMaster;