import React, { Component } from 'react';
import Chart from 'chart.js';
//import 'chartjs-plugin-labels'
class PieChart extends Component {

    constructor(props)
    {
        super(props);
        this.chartRef=React.createRef();

    }

componentDidMount()
{
    Chart.pluginService.register({
        beforeDraw: function (chart) {
            var width = chart.chart.width,
                height = chart.chart.height,
                ctx = chart.chart.ctx;
            ctx.restore();
            var fontSize = (height / 114).toFixed(2);
            ctx.font = 1.15 + "em serif";
            ctx.textBaseline = "middle";
            var text = chart.config.options.elements.center.text,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    });
    let ctx=this.chartRef.current.getContext('2d');
    //ctx.fillText(data[0].value + "%", 500/2 - 20, 500/2, 200);
    new Chart(ctx, this.config);
}


    randomScalingFactor = function() {
        return Math.round(Math.random() * 100);
    };
    randomColorFactor = function() {
        return Math.round(Math.random() * 255);
    };
    
    config = {
        type: 'doughnut',
       
        data: {
            datasets: [{
                data: [
                    30,
                    100-30,
                    //this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                ],
                    backgroundColor: [
                    "#F7464A",
                    'lightgray'
                    
                ],
            }, {
                data: [
                    40,
                    100-60

                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                ],
                    backgroundColor: [
        
                    "#46BFBD",
                     "lightgray",
                    
                ],
            }, {
                data: [
                    60,
                    40
                    //this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                ],
                    backgroundColor: [
                    
                    "#949FB1",
                    'lightgray'
                    
                ],
             },{ data: [
                    60,
                    40
                    //this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                    // this.randomScalingFactor(),
                ],
                    backgroundColor: [
                    
                   "#FDB45C",
                   
                    'lightgray'
                    
                ],anotation:''},
            ],
            labels: [
                "Effective",
                "Loss",
                "",
                "Grey",
                "Dark Grey"
            ]
        },
        options: {
            legend:{display:false},
            //title:{display:true,text:['20','33','50','40']},
            responsive: true,
            elements: {
                center: {
                  text: this.props.oee
                }
            },
           
           
        }
    };
    
    

    render() { 
        return ( 

            <div id="canvas-holder" style={{width:'90%'}}>
    <canvas id="chart-area" ref={this.chartRef} style={{height:500,width:500}}  />
</div>
         );
    }
}
 
export default PieChart;