import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import EquipmentSelection from '../Common/equipmentSelection';
import { Modal, Container, Col, Button } from 'react-bootstrap';
import BenchMarkMachineDetail from '../utill/benchMarkMachineDetaile';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const BenchMark = ({ handleDownloadTableToExcel, equips, equipGroups, handleUpdateEquips,fromDateLimit ,storeDataInLocalStorage}) => {
  const [productionData, setProductionData] = useState([])
  const [inputData, setInputData] = useState({})
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [errors, setErrors] = useState({});
  const [index, setIndex] = useState(0);
  const [querySuccess, setQuerySuccess] = useState(false);
  const [modelState, setModelState] = useState(false);
  const [sortColumn, setSortColumn] = useState({ path: 'From', order: 'asc' });
  const [updateObj, setUpdateObj] = useState({})
  const [newUpdateobj, setNewUpdateObj] = useState({})
  const [updateRowIdx, setUpdateRowIdx] = useState(0)
  const [viewObj, setViewObj] = useState({})
  const [viewModelState, setViewModelState] = useState(false);
const [cycle,setcycle]=useState([
  "Both",
  "SPM",
  "Cycle time",

])

const [cycleoption,setcycleoption] =useState('')
  const[compList,setCompList]=useState([])


  // useEffect(()=>{
  //   axios.get(api.api1 + "component")
  //   .then((result)=>{
  //     setCompList(result.data)
  //   })
  //   .catch((err)=>{
  //     // consol(err);
  //   })

  // },[])


  useEffect(() => {
    const fetchData = async () => {
      try {
        // const result = await axios.get(api.api1 + 'component');
 let result = await storeDataInLocalStorage('component')

        setCompList(result);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);
  
  const schema = {
    From: joi.date().required().label('From Date'),
    UserId: joi.string(),
    Till: joi.date().required().label('Till Date'),
    Equips: joi.array()
  }

  const GetDailyFrames = (FromDate, TillDate) => {
    let frame = []
    let from = new Date(new Date(FromDate).setHours(0, 0, 0, 0));
    let till = new Date(new Date(TillDate).setHours(0, 0, 0, 0));
    // from.setHours(from.getHours()-5);
    // from.setMinutes(from.getMinutes()-30);

    // till.setHours(till.getHours()-5);
    // till.setMinutes(till.getMinutes()-30);
    while (from <= till) {
      let obj = { From: new Date(from) };
      from.setHours(23, 59, 59, 999)
      obj.Till = new Date(from);
      frame.push(obj);
      from.setDate(from.getDate() + 1)
      from.setHours(0, 0, 0, 0);


    }
    return frame
  }

  const handleClickOnEquipment = (obj) => {
    setViewModelState(true);
    setViewObj(obj)
  }

  useEffect(() => {
    const storedValue = localStorage.getItem('selectedCycle');
    if (storedValue) {
      setcycleoption(storedValue);
    }
  }, []);

const handleOncycleChange =(e)=>{
// // consol(e.currentTarget.value,"options ")
e.preventDefault();
setcycleoption(e.currentTarget.value)
localStorage.setItem('selectedCycle', e.currentTarget.value); // Store the selected value in localStorage

}




  const columns = [

  

    (cycleoption ==="Cycle time"?[
      { path: 'From', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
      { path: 'Shift', label: 'Shift' },
      { path: 'Equipment', label: 'Equipment', content: (obj) => <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleClickOnEquipment(obj)}>{obj.Equipment}</a> },
      { path: 'EquipmentGroup', label: 'EquipmentGroup' },
      { path: 'Component', label: 'Component' },
      {
        key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
          let component = [...compList];
          component = component.filter((one) => one.ComponentId == obj.Component)
          if (component.length > 0) {
            return <label>{component[0].Alias}</label>
          } else {
            return <label>N/A</label>
          }
        }
      },
     
      { path: 'Operation', label: 'Operation' },
      { path: 'Tool', label: 'Tool' },
      { path: 'Operator', label: 'Operator' },
      { path: 'Performance', label: 'Performance(%)' },
      { path: 'From', label: 'Job From Time', content: (obj) => <label>{date.getTime(obj.From)}</label> },
      { path: 'Till', label: 'Job Till Time', content: (obj) => <label>{date.getTime(obj.Till)}</label> },

    { path: 'EquipmentIdealCycleTime', label: 'Equipment Ideal Cycle Time (sec)', content: (obj) => <label>{obj.EquipmentIdealCycleTime ? obj.EquipmentIdealCycleTime : '0'}</label> },
    { path: 'ToolIdealCycleTime', label: 'Tool Ideal Cycle Time(sec)', content: (obj) => <label>{obj.ToolIdealCycleTime ? obj.ToolIdealCycleTime : '0'}</label> },
    { path: 'OperationIdealCycleTime', label: 'Operation Ideal Cycle Time (sec)', content: (obj) => <label>{obj.OperationIdealCycleTime ? obj.OperationIdealCycleTime : '0'}</label> },
    { path: 'ConsideredIdealCycleTime', label: 'Considered Ideal Cycle Time (sec)', content: (obj) => <label>{obj.ConsideredIdealCycleTime ? obj.ConsideredIdealCycleTime : '0'}</label> },
    { path: 'AverageCycleTime', label: 'Average Cycle Time (sec)', content: (obj) => <label>{obj.AverageCycleTime ? obj.AverageCycleTime : '0'}</label> },
    { path: 'SuggestedCycleTime', label: 'Suggested Cycle Time (sec)', content: (obj, index) => <label>{obj.SuggestedCycleTime ? obj.SuggestedCycleTime : '0'}</label> },
    { key: 'Update', content: (obj, index) => <React.Fragment><button onClick={() => handleOnUpDateClickBenchMark(obj, index)} className='btn btn-sm btn-primary'>Update</button></React.Fragment> },
   
  ] :([])),
   (cycleoption ==="SPM"?[
    { path: 'From', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
    { path: 'Shift', label: 'Shift' },
    { path: 'Equipment', label: 'Equipment', content: (obj) => <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleClickOnEquipment(obj)}>{obj.Equipment}</a> },
    { path: 'EquipmentGroup', label: 'EquipmentGroup' },
    { path: 'Component', label: 'Component' },
    {
      key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
        let component = [...compList];
        component = component.filter((one) => one.ComponentId == obj.Component)
        if (component.length > 0) {
          return <label>{component[0].Alias}</label>
        } else {
          return <label>N/A</label>
        }
      }
    },
   
    { path: 'Operation', label: 'Operation' },
    { path: 'Tool', label: 'Tool' },
    { path: 'Operator', label: 'Operator' },
    { path: 'Performance', label: 'Performance(%)' },
    { path: 'From', label: 'Job From Time', content: (obj) => <label>{date.getTime(obj.From)}</label> },
    { path: 'Till', label: 'Job Till Time', content: (obj) => <label>{date.getTime(obj.Till)}</label> },

    { path: 'EquipmentIdealSPM', label: 'Equipment Ideal SPM', content: (obj) => <label>{obj.EquipmentIdealSPM ? obj.EquipmentIdealSPM : '0'}</label> },
    { path: 'ToolIdealCycleSPM', label: 'Tool Ideal Cycle SPM', content: (obj) => <label>{obj.ToolIdealCycleSPM ? obj.ToolIdealCycleSPM : '0'}</label> },
    { path: 'OperationIdealCycleSPM', label: 'Operation Ideal SPM', content: (obj) => <label>{obj.OperationIdealCycleSPM ? obj.OperationIdealCycleSPM : '0'}</label> },
    { path: 'ConsideredIdealSPM', label: 'Considered Ideal SPM', content: (obj) => <label>{obj.ConsideredIdealSPM ? obj.ConsideredIdealSPM : '0'}</label> },
    { path: 'AverageSPM', label: 'Average SPM', content: (obj) => <label>{obj.AverageSPM ? obj.AverageSPM : '0'}</label> },
    
    { path: 'SuggestedSPM', label: 'Suggested SPM', content: (obj) => <label>{obj.SuggestedSPM ? obj.SuggestedSPM : '0'}</label> },
    { key: 'Update', content: (obj, index) => <React.Fragment><button onClick={() => handleOnUpDateClickBenchMark(obj, index)} className='btn btn-sm btn-primary'>Update</button></React.Fragment> },
 
  ] :([])),
   
  (!cycleoption ==="Cycle time" || "SPM"? [

  { path: 'From', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
  { path: 'Shift', label: 'Shift' },
  { path: 'Equipment', label: 'Equipment', content: (obj) => <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleClickOnEquipment(obj)}>{obj.Equipment}</a> },
  { path: 'EquipmentGroup', label: 'EquipmentGroup' },
  { path: 'Component', label: 'Component' },
  {
    key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
      let component = [...compList];
      component = component.filter((one) => one.ComponentId == obj.Component)
      if (component.length > 0) {
        return <label>{component[0].Alias}</label>
      } else {
        return <label>N/A</label>
      }
    }
  },
 
  { path: 'Operation', label: 'Operation' },
  { path: 'Tool', label: 'Tool' },
  { path: 'Operator', label: 'Operator' },
  { path: 'Performance', label: 'Performance(%)' },
  { path: 'From', label: 'Job From Time', content: (obj) => <label>{date.getTime(obj.From)}</label> },
  { path: 'Till', label: 'Job Till Time', content: (obj) => <label>{date.getTime(obj.Till)}</label> },
  { path: 'EquipmentIdealCycleTime', label: 'Equipment Ideal Cycle Time (sec)', content: (obj) => <label>{obj.EquipmentIdealCycleTime ? obj.EquipmentIdealCycleTime : '0'}</label> },
  { path: 'ToolIdealCycleTime', label: 'Tool Ideal Cycle Time(sec)', content: (obj) => <label>{obj.ToolIdealCycleTime ? obj.ToolIdealCycleTime : '0'}</label> },
  { path: 'OperationIdealCycleTime', label: 'Operation Ideal Cycle Time (sec)', content: (obj) => <label>{obj.OperationIdealCycleTime ? obj.OperationIdealCycleTime : '0'}</label> },
  { path: 'ConsideredIdealCycleTime', label: 'Considered Ideal Cycle Time (sec)', content: (obj) => <label>{obj.ConsideredIdealCycleTime ? obj.ConsideredIdealCycleTime : '0'}</label> },
  { path: 'AverageCycleTime', label: 'Average Cycle Time (sec)', content: (obj) => <label>{obj.AverageCycleTime ? obj.AverageCycleTime : '0'}</label> },
  { path: 'SuggestedCycleTime', label: 'Suggested Cycle Time (sec)', content: (obj, index) => <label>{obj.SuggestedCycleTime ? obj.SuggestedCycleTime : '0'}</label> },

  { path: 'EquipmentIdealSPM', label: 'Equipment Ideal SPM', content: (obj) => <label>{obj.EquipmentIdealSPM ? obj.EquipmentIdealSPM : '0'}</label> },
  { path: 'ToolIdealCycleSPM', label: 'Tool Ideal Cycle SPM', content: (obj) => <label>{obj.ToolIdealCycleSPM ? obj.ToolIdealCycleSPM : '0'}</label> },
  { path: 'OperationIdealCycleSPM', label: 'Operation Ideal SPM', content: (obj) => <label>{obj.OperationIdealCycleSPM ? obj.OperationIdealCycleSPM : '0'}</label> },
  { path: 'ConsideredIdealSPM', label: 'Considered Ideal SPM', content: (obj) => <label>{obj.ConsideredIdealSPM ? obj.ConsideredIdealSPM : '0'}</label> },
  { path: 'AverageSPM', label: 'Average SPM', content: (obj) => <label>{obj.AverageSPM ? obj.AverageSPM : '0'}</label> },
  
  { path: 'SuggestedSPM', label: 'Suggested SPM', content: (obj) => <label>{obj.SuggestedSPM ? obj.SuggestedSPM : '0'}</label> },
  { key: 'Update', content: (obj, index) => <React.Fragment><button onClick={() => handleOnUpDateClickBenchMark(obj, index)} className='btn btn-sm btn-primary'>Update</button></React.Fragment> },
] :([])),

  ]

  const handleOnUpDateClickBenchMark = (obj, index) => {
    setModelState(true);
    setNewUpdateObj(obj)
    setUpdateObj(obj);
    setUpdateRowIdx(index)
  }
  const handleOnChange = (e) => {
    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
    }
    else {
      inputData[e.currentTarget.name] = e.currentTarget.value;
    }
    setInputData(inputData);
    setErrors({})
  }
  const getEquipsForReport = () => {
    let equipArr = []
    equips.forEach((one) => {
      selectedEquipOption.forEach((option) => {
        if (one._id == option.value) {
          equipArr.push(one);
        }
      })
    })
    return equipArr;
  }
  const handleOnSubmit = async (inputData) => {
    productionData.splice(0, productionData.length);
    setIndex(0)
    // consol(inputData)
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    const user = auth.getCurrentUser();
    inputData.UserId = user._id
  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    let equipForReport = getEquipsForReport()
    for (let i = 0; i < equipForReport.length; i++) {
      try {
        let list = GetDailyFrames(new Date(inputData.From), new Date(inputData.Till));
        for (let frame = 0; frame < list.length; frame++) {
          let obj = { From: list[frame].From, Till: list[frame].Till, Equips: [equipForReport[i]] }
          let result = await axios.post(api.api + 'BenchMark', obj)
          if (result.data !== null && result.data !== undefined) {
            if (result.data.length > 0) {

              result.data.forEach((one) => {
                productionData.push(one);
              })

            }
          }
          else {
            setQuerySuccess(false)
          }
        }

        let idx = i + 1
        setProductionData(productionData)
        setIndex(idx)



      }
      catch (err) {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err)
        setQuerySuccess(false)
        break;
      }
      // }

    }
    setQuerySuccess(false)


  }


  const handleSort = (sortColumn) => {
    if (productionData.length > 0) {
      const sorted = _.orderBy(
        productionData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setProductionData(sorted)
      setSortColumn(sortColumn)
    }
  }

  const setSelection = (key, option, equips) => {
    if (key == 'EquipmentGroup') {
      SetEquips(equips ? equips : []);
      setSelectedEquipGroupOption(option)
      setSelectedEquipOption([])
    }
    if (key == 'Equipment') {

      setSelectedEquipOption(option)
    }
  }
  const handleClose = () => {
    setModelState(false)
  }

  const handleSaveChanges = () => {
    setQuerySuccess(true)
    axios.patch(api.api + 'BenchMarkUpdate', newUpdateobj)
      .then((result) => {
        if (result.data == 'Update') {
          // let prodUpdateData = [...productionData];
          // prodUpdateData[updateRowIdx] = newUpdateobj;
          // setProductionData(prodUpdateData);
          // handleUpdateEquips();
          handleOnSubmit(inputData);
          swal('Update Success');
          setModelState(false);
          //setQuerySuccess(false)
        } else {
          swal('Not Update Some Internal Error', { dangerMode: true });
          setQuerySuccess(false)
        }
      })
      .catch((err) => {
        setQuerySuccess(false)
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
  }

  const getNumberDefaultZero = (number) => {
    if (isNaN(number)) {
      return '0'
    }
    else if (number == undefined || number == '') {
      return '0'
    } else if (!isFinite(number)) {
      return '0'
    }
    else {
      return `${number}`
    }
  }
  const handleOnChangeUpdate = (e) => {
    const obj = { ...newUpdateobj };
    if (e.currentTarget.name == 'EquipmentIdealCycleTime') {
      obj[e.currentTarget.name] = getNumberDefaultZero(e.currentTarget.value);
      obj['EquipmentIdealSPM'] = getNumberDefaultZero((60 / parseFloat(e.currentTarget.value)));
    }
    if (e.currentTarget.name == 'EquipmentIdealSPM') {
      obj[e.currentTarget.name] = getNumberDefaultZero(e.currentTarget.value);
      obj['EquipmentIdealCycleTime'] = getNumberDefaultZero((60 / parseFloat(e.currentTarget.value)));
    }
    if (e.currentTarget.name == 'ToolIdealCycleTime') {
      obj[e.currentTarget.name] = getNumberDefaultZero(e.currentTarget.value);
      obj['ToolIdealCycleSPM'] = getNumberDefaultZero((60 / parseFloat(e.currentTarget.value)));
    }
    if (e.currentTarget.name == 'ToolIdealCycleSPM') {
      obj[e.currentTarget.name] = getNumberDefaultZero(e.currentTarget.value);
      obj['ToolIdealCycleTime'] = getNumberDefaultZero((60 / parseFloat(e.currentTarget.value)));
    }
    if (e.currentTarget.name == 'OperationIdealCycleTime') {
      obj[e.currentTarget.name] = getNumberDefaultZero(e.currentTarget.value);
      obj['OperationIdealCycleSPM'] = getNumberDefaultZero((60 / parseFloat(e.currentTarget.value)));
    }
    if (e.currentTarget.name == 'OperationIdealCycleSPM') {
      obj[e.currentTarget.name] = getNumberDefaultZero(e.currentTarget.value);
      obj['OperationIdealCycleTime'] = getNumberDefaultZero((60 / parseFloat(e.currentTarget.value)));
    }

    setNewUpdateObj(obj);
  }

  const getRowForTableModel = (obj, key) => {
    if (key == 'SuggestedSPM' || key == 'SuggestedCycleTime') {
      return <tr>
        <td>{key}</td>
        <td><input type='text' disabled={true} className='form-control form-control-sm' value={obj[key]} name={key} /></td>
      </tr>
    }
    if (key == 'EquipmentIdealCycleTime' || key == 'EquipmentIdealSPM') {
      return <tr>
        <td>{key}</td>
        <td><input type='text' onChange={handleOnChangeUpdate} className='form-control form-control-sm' value={obj[key]} name={key} /></td>
      </tr>
    }
    if (key == 'ToolIdealCycleTime' || key == 'ToolIdealCycleSPM') {
      return obj[key] ? <tr>
        <td>{key}</td>
        <td><input type='text' onChange={handleOnChangeUpdate} className='form-control form-control-sm' value={obj[key]} name={key} /></td>
      </tr> : ''
    }
    if (key == 'OperationIdealCycleTime' || key == 'OperationIdealCycleSPM') {
      return obj[key] ? <tr>
        <td>{key}</td>
        <td><input type='text' onChange={handleOnChangeUpdate} className='form-control form-control-sm' value={obj[key]} name={key} /></td>
      </tr> : ''
    }
    if (key == 'Break') {
      if (obj['ToolIdealCycleTime']) {
        return <hr style={{ width: 275 }}></hr>
      }
      else if (obj['OperationIdealCycleTime']) {
        return <hr style={{ width: 275 }}></hr>
      }
      else if (obj['EquipmentIdealCycleTime']) {
        return <hr style={{ width: 275 }}></hr>
      }
      else if (obj['SuggestedCycleTime']) {
        return <hr style={{ width: 275 }}></hr>
      }
      else {
        return ''
      }

    }
  }
  const handleCloseViewModel = () => {
    setViewModelState(false);
  }
  const SelectedDates =(dates)=>{
    setInputData(dates)
  }
  const keys = ['SuggestedCycleTime', 'SuggestedSPM', 'Break', 'EquipmentIdealCycleTime', 'EquipmentIdealSPM', 'Break', 'ToolIdealCycleTime', 'ToolIdealCycleSPM', 'OperationIdealCycleTime', 'OperationIdealCycleSPM']
  return (
    <React.Fragment>
      {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`} /> : ''}
      <Modal  size='xl'
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title" show={viewModelState} onHide={handleCloseViewModel}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 17 }}>
            Machine Activity
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BenchMarkMachineDetail obj={viewObj} />
        </Modal.Body>

      </Modal>
      <Modal size='lg' show={modelState} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: 17 }}>

            Update Bench Mark<br></br>
            PS: The Largest Cycle Time or The Smallest SPM amongst Equipment / Tool / Operation shall be considered while calculating Performance<br></br>
            <table className='table table-sm table-striped'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Shift</th>
                  <th>Equipment</th>
                  <th>Component</th>
                  <th>Operation</th>
                  <th>JobFrom</th>
                  <th>JobTill</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{date.getDateFormat(updateObj.From)}</td>
                  <td>{updateObj.Shift}</td>
                  <td>{updateObj.Equipment}</td>
                  <td>{updateObj.Component}</td>
                  <td>{updateObj.Operation}</td>
                  <td>{date.getTime(updateObj.From)}</td>
                  <td>{date.getTime(updateObj.Till)}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table  table-sm table-hover borderless">
            {/* <thead>
                <tr>
                  <th scope="col">Reason Group</th>
                  <th scope="col">Reason</th>
                  <th scope="col">Production</th>

                </tr>
              </thead> */}
            <tbody>
              {keys.map(key => {
                return getRowForTableModel(newUpdateobj, key)
              })}

            </tbody>
            <tfoot>
              <tr>

              </tr>

            </tfoot>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges} >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Bench Mark
        </h4>
      </div>
      <ToastContainer/>

      <div className="row">
      {equipGroups.length > 0 && <EquipmentSelection  equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}

        {/* <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} /> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input className={'form-control form-control-sm'} min={fromDateLimit} placeholder='dd/mm/yyyy' type='date' onChange={handleOnChange} name='From' />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' placeholder='dd/mm/yyyy' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
        </div> */}
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              <option>All</option>
              <option>Shift1</option>
              <option>Shift2</option>
              <option>Shift3</option>
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>
        <div className="col-sm-2">
      
              <div className="form-group text-center">
                <label> SPM / Cycle Time </label>
                <select
                  className="custom-select form-control"
                  name="cycletime"
                 value={cycleoption}
                  onChange={handleOncycleChange}
                  placeholder='selectCycleTime'
                >
                  {cycle.map(one => {
                    return <option>{one}</option>
                  })}
                </select>
              
              </div>

           
           
        </div>
        <div className={productionData.length > 0 ? 'col-sm-1' : 'd-none col-sm-1'}>
          <a className='btn btn-sm btn-primary mt-4' onClick={() => handleDownloadTableToExcel('BenchMark')} style={{ float: 'right', color: 'white' }}>Download</a>
        </div>
      </div>
      <div>

        {productionData.length > 0 ? <Table id='BenchMark' columns={columns[0].length > 0?columns[0]:columns[1].length > 0?columns[1] :columns[2]} data={productionData} onSort={handleSort} sortColumn={sortColumn} /> : <p></p>}
      </div>
    </React.Fragment>
  );
}
export default BenchMark;