import React, { Component } from 'react';
import Table from '../../../Common/table';
//import '../QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faEye, faPlus, faFileImport, faDownload } from '@fortawesome/free-solid-svg-icons';
import dateTime from '../../../utill/dateTime';
import swal from 'sweetalert';
import api from '../../../config';
import axios from '../../../httpServices/httpService';
import _ from 'lodash'
import { BoxLoading, BlockLoading } from 'react-loadingg';
import ReactDOM from 'react-dom';
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx';
import valid from '../../../utill/validation';
import joi from 'joi';
import {Modal} from 'react-bootstrap';


class OperationTable extends Component {

  state = {

    operationList: [],
    sortColumn: { path: 'OperationName', order: 'asc' },
    excelData:[],
    arrayProcessIndex:0,
    errorModal:false,
    querySuccess:false,
    errorMessage:'',
    errorFlag:false,
    rowNumber:0,
    errors:{},
    objKey:'',
  }

  schema={
    OperationName:joi.string().required()
  }

  handleSort = sortColumn => {
    // consol(sortColumn);
    if (this.state.operationList.length > 0) {
      const sorted = _.orderBy(this.state.operationList, [sortColumn.path], [sortColumn.order]);
      this.setState({ sortColumn, operationList: sorted });
    }
  }

  componentDidMount() {
    this.setState({ querySuccess: true });
    axios.get(api.api + 'operation')
      .then((result) => {
        if (result.data.length > 0) {
          this.setState({ operationList: result.data, querySuccess: false });
        }
        else {
          this.setState({ querySuccess: false })
        }
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });

      })


  }
  onDelete = (data) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        axios
          .delete(api.api + "operation/" + data._id, data)
          .then(result => {
            // consol(result.data)
            if (result.data.ok === 1 && result.data.deletedCount === 1) {
              let filterData = this.state.operationList.filter(
                obj => obj._id !== data._id
              );
              this.setState({ operationList: filterData, querySuccess: false });
            } else {
              this.setState({ querySuccess: false });
              swal('Not Delete Due To Some Internal Error ', { dangerMode: true });
            }
          })
          .catch(err => {
            this.setState({ querySuccess: false });
            swal(`${err.response.data.message}`);

          });
      } else {
        this.setState({ querySuccess: false });
        // swal("Your imaginary file is safe!");
      }
    });




  }


  columns = [
    { path: 'OperationName', label: 'Operation Name' },

    { key: 'createdAt', label: 'Created Date', content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.createdAt)}</label><br /></React.Fragment> },
    { key: 'updatedAt', label: 'Last Updated Date', content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.updatedAt)}</label><br /></React.Fragment> },
    { key: 'update', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights', 'OperationMaster', 'update')} onClick={() => this.props.onUpdate(user)} className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faEdit} /></button> },
    // { key: 'view', content: user => <button onClick={() => this.props.onView(user)} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faEye} /></button> },
    { key: 'delete', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights', 'OperationMaster', 'write')} onClick={() => this.onDelete(user)} className="btn btn-sm btn-danger"><FontAwesomeIcon icon={faTrash} /></button> }

  ]


  handleUploadGuagesDoc = () => {
    let link = document.createElement('input');
    link.setAttribute('type', 'file');
    link.setAttribute('name', 'OperationList');
    // link.setAttribute('onChange', handleOnChangeFile);
    let self = this;
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      e.preventDefault();
   
      let fr = new FileReader();

      // if (e.currentTarget.files[0].size < 51200 && e.currentTarget.files[0].size > 2000) {
      fr.readAsBinaryString(e.currentTarget.files[0]);
      fr.onloadend = async function (e) {
        const wb = XLSX.read(e.target.result, { type: 'binary', cellDates: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let dataArray = []
        let keyArr = data[0]
        let arr = data.slice(1, data.length)
        arr.forEach((oneArr) => {
          let obj = {}
          oneArr.forEach((value, i) => {
            if(keyArr[i]=='DeviceID'){
              obj[[keyArr[i]]] = value;
            }else{
              obj[[keyArr[i]]] = value.toString();
            }
          })
          dataArray.push(obj);
        })
        self.setState({excelData:dataArray});
        await self.sendDataToServer(dataArray,dataArray,self.state.arrayProcessIndex)
        // consol("Data>>>" + dataArray);
      };
      // }
      // else {
      //   swal('File Size Should Be Between 2KB to 50KB');
      // }
    }
     document.body.removeChild(link);

  }

sendDataToServer = async (EquipsArr,originalDataArray,idx) => {
  this.setState({querySuccess:true})
  
    for (let index = idx; index < EquipsArr.length; index += 1) {
      try {
        let error=valid.validate(EquipsArr[index],this.schema);
        if(error!==null){
          let row=index+1
          this.setState({errors:error,querySuccess:false,errorModal:true,rowNumber:row});
         break;

        }else{
          let res=await axios.post(api.api + 'UploadExcelOperationData', { row: index + 1, data: EquipsArr[index] })
          await    (async () => {
            const result = await axios.get(
              api.api + 'operation'
            );
           // setQuerySuccess(false)
           this.setState({operationList:result.data});
          })();
          if(res.data=='Insert'){
            toast.success('🦄 Save  Operation  - '+EquipsArr[index].OperationName, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }
        
        }
      }
      catch (err) {
        if(err.response.data.code==11000){
          this.AbortAndResumeProcess(EquipsArr,originalDataArray,index);
        }else{
          swal('Process Cancel',{dangerMode:true})
        }
        break;
      
      }

    }
    this.setState({querySuccess:false})
  }


  AbortAndResumeProcess=(dataArray,originalArr,index)=>{
    let row=index+1;
    swal({
      title: "Are you sure?",
      text: ' This operation name already exist skip this operation name!  ' +dataArray[index].OperationName  +"  at row number "+row,
      icon: "warning",
      buttons: true,
      dangerMode: true
  }).then(willDelete => {
      if (willDelete) {
        index=index+1;
        this.sendDataToServer(dataArray,originalArr,index)
      } else {
        this.setState({querySuccess:false,arrayProcessIndex:index})
        swal('Process  Abort',{dangerMode:true});
      
          // swal("Your imaginary file is safe!");
      }
  });
  }

  

 


   handleDownloadFormat = () => {
    axios.get(api.api + 'OperationTemplate')
      .then((result) => {
        if (result.status == 200) {
          // const url = window.URL.createObjectURL(new Blob([result.data]));
          let link = document.createElement('a');
          link.setAttribute('href', result.data);
          link.setAttribute('download', 'OperationFormat.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        else {
          swal('Error In Downloading File')
        }
      })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
  }

  handleHideModal=()=>{
   this.setState({errorModal:false})
  }



  render() {


    return (
      <React.Fragment>
        <div className='mt-1'>
        <div className='text-center'>
                   <p>Operation Master</p>
                 </div>
           <ToastContainer />
         <Modal
            show={this.state.errorModal}
           onHide={this.handleHideModal}
            backdrop="static"
            keyboard={false}
            size='sm'
        >
            <Modal.Header closeButton>
                <Modal.Title>Operation Validate Error List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(this.state.errors).map(key=>{
                return <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors[key] +"  " +"Row Number  "+ this.state.rowNumber}</p>
              })}
            </Modal.Body>
            {/* <Modal.Footer>
                <Button className='bg-primary' variant="secondary" >
                    Save
        </Button>
                <Button variant="secondary" onClick={handleHideModal}>
                    Close
        </Button>

            </Modal.Footer> */}
        </Modal>

        {this.state.querySuccess ? <Loading /> : ''}


          {/* {this.props.getRightsForAccess('StaticDataRights', 'OperationMaster', 'write') ? <a style={{ cursor: 'pointer' }} className='float-right common-anchor' > <FontAwesomeIcon size='1x' icon={faPlus} /> Add</a> : ''} */}
          {this.props.getRightsForAccess('StaticDataRights', 'OperationMaster', 'write') ? <a
            style={{ cursor: "pointer", color: 'white' }}
            className="float-right btn btn-sm btn-primary "
            onClick={this.handleUploadGuagesDoc}
          >
            {" "}

            <FontAwesomeIcon size="1x" icon={faFileImport} /> Import Excel
          </a> : ''}

          {this.props.getRightsForAccess('StaticDataRights', 'OperationMaster', 'write') ? <a
            style={{ cursor: "pointer", color: 'white' }}
            className="common-anchor btn btn-sm btn-primary ml-2"
            onClick={() => { this.props.history.push('/setting/operation/add'); this.props.handleOnAdd('/setting/operation/add', 'add') }}
          >
            {" "}

            <FontAwesomeIcon size="1x" icon={faPlus} /> Add
          </a> : ''}
          {this.state.operationList.length < 0 ? "Data Not Available" : <Table columns={this.columns} onSort={this.handleSort} sortColumn={this.state.sortColumn} data={this.state.operationList} />}
        </div>
      </React.Fragment>

    );
  }
}

export default OperationTable;
