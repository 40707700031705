import React, { useState } from 'react';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';

const YourDateRangePickerComponent = ({ onDateChange }) => {
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState([
    {
      startDate: addDays(new Date(), -1),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleDateChange = (item) => {
    const formattedStartDate = moment(item.selection.startDate).format('YYYY MM DD');
    const formattedEndDate = moment(item.selection.endDate).format('YYYY MM DD');

    setSelectedDateRange([item.selection]);
    onDateChange([{ ...item.selection, startDate: formattedStartDate, endDate: formattedEndDate }]);
  };

  const handleButtonClick = () => {
    setDatePickerVisibility(!isDatePickerVisible);
  };

  return (
    <div style={{ position: 'relative' }} className='p-0 m-0'>
  <p className="p-0 m-0 text-center bolder text-black" style={{color:"black",fontSize:'15px', fontWeight:'400px'}}>Date</p>

      <button onClick={handleButtonClick} style={{ padding: '4px 5px ', background: 'white' ,width:"170px"} } className='mb-2 d-flex shadow-sm rounded border border-2 '><p className='p-0 m-0 text-black ' style={{fontSize:'14px',fontWeight:'400',color:'black'}}>{moment(selectedDateRange[0].startDate).format('DD MM YYYY')} - {moment(selectedDateRange[0].endDate).format('DD MM YYYY')}</p>
        
      </button>

      {isDatePickerVisible && (
        <div className='shadow' style={{ position: 'absolute', top: '10', left: '0',zIndex: '1000'}}>
          <DateRangePicker
            onChange={handleDateChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={selectedDateRange}
            direction="horizontal"
          />
        </div>
      )}
    </div>
  );
};

export default YourDateRangePickerComponent;
