import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";

import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import object from "joi/lib/types/object";




function UtilizationReport({fromDateLimit,storeDataInLocalStorage}) {
  const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);
  const [periodOption, setperiodOption] = useState([
    { label: "Shift", id: "1" },
    { label: "Daily", id: "2" },
    { label: "Weekly", id: "3" },
    { label: "Monthly", id: "4" },
    { label: "Cummulative", id: "5" },
  ]);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [datacount, setdatacount] = useState('');
  const [datetime, setdatetime] = useState(null);

  
  const [selectedDates, setSelectedDates] = useState({
    From: "",
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState([]);
  const [selectedItemLengths, setSelectedItemLengths] = useState({});

  
  let DowntimegroupTotal =[];

  columns2.forEach(column=>{
    const totalDuration = {};
    column.forEach(one =>
        one?.DowntimeGroups !== undefined ? one?.DowntimeGroups.map((obj) => {
      const { DowntimeGroups, Duration } = obj;
      totalDuration[DowntimeGroups] = (totalDuration[DowntimeGroups] || 0) + Duration;
    }) :'',"testing the function")

    
    const downtimeGroupObjects = Object.keys(totalDuration).map((group) => ({
      DowntimeGroups: group,
      Duration: totalDuration[group],
    }));
    DowntimegroupTotal.push(downtimeGroupObjects)
  
    // consol("isuvbiubvvisdfvbjisdufbv ",DowntimegroupTotal.map(one => one.map(item=>item.Duration)))
  })

  // Iterate through the array and accumulate the duration for each downtime group
  // Log the separate objects for each downtime group
  // Your existing chart code goes here

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    {
      heading: "Equipment",
      label: "Equipment",
      initialItems: equpdata,
      selectedItemIds: selectedItemIds,
      name: "Equipment",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    },
    {
      heading: "Shift",
      label: "Shift",
      initialItems: shiftOption,
      selectedItemIds: selectedItemIds,
      name: "Shift",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: false,
    },
    
 
  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////

  const column2 = [
    { path: "Time", label: "Time" },
    // { path: "Equipment", label: "Equipment" },
    // { path: "EquipmentGroup", label: "EquipmentGroup" },
    { path: "PartName", label: "PartName" },
    { path: "ProcessName", label: "ProcessName" },
    { path: "TargetParts", label: "TargetParts" },
    { path: "QuantityProduced", label: "QuantityProduced" },
    { path: "Rejected", label: "Rejected" },
    { path: "TotalDownTime", label: "TotalDownTime" },

    // { path: "Shift", label: "Shift" },

  ];

  const schema = {
    Equips: joi.array().required().label("Equipment"),
    Shift: joi.array().required().label("please select at least one shift"),
   
  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
      setQuerySuccess(true);

        // const equipmentdata = await axios.get(api.api1 + "equipment");
    
        // const downtimedata = await axios.get(api.api + "downtimereason");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

        let equipmentdata = await storeDataInLocalStorage('equipment')
        const downtimelist = downtimedata
        .map((reason, index) => ({
          id: `${index}`,
          label: reason.ReasonGroup,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));

        downtimelist.push({id:'40',label:"Down"})


        const uniqueObjects = [];

        // Filter out objects with duplicate combinations of label and id
        const filteredData = downtimelist.filter(obj => {
          // Check if the combination of label and id is already in the uniqueObjects array
          const isDuplicate = uniqueObjects.some(uniqueObj =>
            uniqueObj.label === obj.label
          );
        
          if (!isDuplicate) {
            // If not, add it to the array and keep the object
            uniqueObjects.push({ id: obj.id, label: obj.label });
            return true;
          }
        
          // If the combination of label and id is already in the array, filter it out
          return false;
        });
        

        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        setDynamicData(uniqueObjects);
        setequpdata(equiplist);
        setEquipmentDatalist(equipmentdata)
        // consol("fetching data",uniqueObjects);
        
      } catch (error) {
        // console.error("Error fetching data:", error);
      setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts

 const getDowntimeReason = (ReasonArr) => {
    let returnArr = [];

    // Check if ReasonArr is undefined
    if (!ReasonArr) {
      ReasonArr = [{ DowntimeGroups: 'default', Duration: 0 }];
    }

    if (dynamicData.length === 0) {
        returnArr.push({ DowntimeGroups: 'default', Duration: 0 });
    } else {
        dynamicData.forEach((one1) => {
            let filterReason = ReasonArr.filter(obj => obj?.DowntimeGroups === one1.label);
            if (filterReason.length > 0) {
                returnArr.push({ ...filterReason[0] });
            } else {
                let obj = { DowntimeGroups: one1.label, Duration: 0 };
                returnArr.push(obj);
            }
        });
    }

    return returnArr;
};



  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
      "Equipment",
      "Shift",
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }

    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Equipment") {

     let noobdata  = [];
      newSelectedItems.forEach((item) => {
      let noob = EquipmentDatalist.filter(equipment => equipment.EquipmentID === item);
      noobdata.push(noob[0])
      inputData['Equips'] = noobdata
    })
    } else {
      inputData[name] = newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

    // consol(selectedDates,"current date")
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
  
    const errors = validate.validate(inputData, schema);
  
    setErrors(errors || {});
    if (errors) return;
  
    setQuerySuccess(true);
  
    try {
      const dateTime = { ...selectedDates };
      inputData.DateTime = dateTime.From;
  
      // Initialize an array to store the response data
      const multipleTbledata = [];
      
      // Define an async function to make requests sequentially
      async function makeRequest(index) {
        if (index >= inputData.Equips.length) {
          // All requests are done, process the data as needed
          if (multipleTbledata.length > 0) {
            setcolumns2(multipleTbledata);
           
          } else {
            swal("Data Not Available");
          }
          setQuerySuccess(false);
          return;
        }
        
        const equip = inputData.Equips[index];
        const equipData = { ...inputData, Equips: [equip ]};
        
        try {
          const result = await axios.post(api.api + '/GetHourlyData', equipData);
          const equipResponse = result.data;
          // consol(equipResponse,"after pushing")
          setdatetime([inputData.DateTime,inputData.Shift])
          // consol(equipData, "current data being sent for a single element",inputData.DateTime,inputData.Shift);
          // Check if equipResponse has a length greater than zero before processing
          if (Array.isArray(equipResponse) && equipResponse.length > 0) {
            multipleTbledata.push(equipResponse);
          }
  // consol()
          // Make the next request
          setdatacount(index +1)
          makeRequest(index + 1);
        } catch (error) {
          swal(`${error}`);
          // consol(error);
          setQuerySuccess(false);
        }
      }
  
      // Start making requests from the first element
      makeRequest(0);
      setdatacount(0)

    } catch (error) {
      swal(`${error}`);
      // consol(error);
      setQuerySuccess(false);
    }
  };
  
  


  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };


 // consol(datacount,"index number of data")
  return (
    <div  className="justify-content-center">
      {querySuccess ? <Loading labelShow={true} label={inputData.Equips?.length < 0 ? ' ':`${datacount || 0 }  /  ${inputData.Equips?.length || 0}`}/> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Utilization Report
        </h4>
      </div>
      <div className="row mx-3">
       {equpdata.length > 0 ?  <>
       <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Date</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
              />
          </div>
        </div>
            

        {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  {/* {componentdata.length > 0 && ( */}
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}

                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar}
                    />
                  {/* )} */}
                  {errors[data.heading] && (
                    <p className="text-danger" style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}

<div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div>

 </>:""}           
    </div>
   {columns2.length >0 ?

columns2.map((column,index) => <div className="rounded shadow my-4 " style={{width:'100%'}}>
<div className="d-flex justify-content-start align-items-center  ">
<div className="uiverse mx-2" onClick={() =>
   handleDownloadExcel(`${senddata['Equips'][index].EquipmentID}` +"_utilizationreport_"+ `${moment(datetime[0]).format('DD/MM/YY')}`+'_'+`${datetime[1]}`)}>
 <span className="tooltip">Dowload Excel File</span>
 <span>
 Download
 </span>
</div>
<div className="mx-2"><span>Equipment : </span> <span>{senddata['Equips'][index].EquipmentID}</span></div>
<div className="mx-2"><span>Date : </span> <span>{moment(datetime[0]).format('DD/MM/YY')}</span></div>
<div className="mx-2"><span>Shift : </span> <span>{datetime[1]}</span></div>
</div>
                 <div  style={{ overflowX: 'auto' }}>
                   <table id={`${senddata['Equips'][index].EquipmentID}` +'_utilizationreport_'+ `${moment(datetime[0]).format('DD/MM/YY')}`+'_'+`${datetime[1]}`} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0,width: "100%" }}>
            <thead className='small text-center border'>
         
                <tr className='text-center align-self-center table-primary'>
                  
                
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Time</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Operator</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Part Name</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Process Name</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Target</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Produced Quantity </th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Rejected Quantity</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Downtime in (min)</th>


    
                <th style={{ verticalAlign: 'middle' }} colSpan={dynamicData.length}>Downtime Reasons (min)
                </th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Remarks</th>

                
                   
                </tr>

                <tr className='table-primary' >


                    {dynamicData.length > 0 ? dynamicData.map(one => {
                        return <th className="rotate p-0 m-0 text-start" style={{  height: (100 + dynamicData.length) * 2.8 }} ><div  style={{marginTop:'1rem'}}><span className="text-start">{one.label}</span></div></th>
                    }) : ''}

                </tr>
              
            </thead>
            <tbody>
               
           { column.map(one =><><tr >
                    <th className="text-center" style={{fontWeight:'400'}}>{` ${moment(one.From).utcOffset('+05:30').format('HH:mm:ss') + ' - '+ moment(one.Till).utcOffset('+05:30').format('HH:mm:ss')}`}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Operator}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.PartName}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.ProcessName}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.TargetParts}</th>
                    <th style={{fontWeight:'400'}} className="text-center">{one.QuantityProduced}</th>                      
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Rejected}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.TotalDownTime}</th>
                   
                    {getDowntimeReason(one?.DowntimeGroups).length > 0 ? getDowntimeReason(one?.DowntimeGroups).map(r => {
                            return <td >{r.Duration == 0 ? '0': r.Duration}</td>
                        }) : <td >0</td>}
                    <th className="text-center" style={{fontWeight:'400'}}></th>

                    </tr>
                   
                    </>               
                   )}
                    <tr>
                    <th className="text-center" style={{fontWeight:'400'}}>Total</th>
                    <th className="text-center" style={{fontWeight:'400'}}></th>
                    <th className="text-center" style={{fontWeight:'400'}}></th>
                    <th className="text-center" style={{fontWeight:'400'}}></th>

                    <th className="text-center" style={{fontWeight:'400'}}>
  {column.reduce((accumulator, currentObject) => {
    const valueToAdd = currentObject?.TargetParts !== '0' ? Number(currentObject.TargetParts) : 0;
   
    return accumulator + valueToAdd;
  }, 0)}
</th>

<th className="text-center" style={{ fontWeight: '400' }}>
  {column.reduce((accumulator, currentObject) => {
    return accumulator + (currentObject?.QuantityProduced !== '0' ? Number(currentObject.QuantityProduced) : 0);
  }, 0)}
</th>

<th className="text-center" style={{ fontWeight: '400' }}>
  {column.reduce((accumulator, currentObject) => {
    return accumulator + (currentObject?.Rejected !== '0' ? Number(currentObject.Rejected) : 0);
  }, 0)}
</th>

<th className="text-center" style={{ fontWeight: '400' }}>
  {column.reduce((accumulator, currentObject) => {
    return accumulator + (currentObject?.TotalDownTime !== '0' ? Number(currentObject.TotalDownTime) : 0);
  }, 0)}
</th>

{DowntimegroupTotal && ( getDowntimeReason(DowntimegroupTotal[index]).map(r =>  <td >{r.Duration == 0 ? '0': r.Duration}</td>))}

                    </tr>
                    <tr>
                    <th className="text-center" style={{fontWeight:'400'}}>Signatures</th>
                    <th className="text-center" colSpan={7 + dynamicData.length} style={{fontWeight:'400'}}></th>
                    <th className="text-center" style={{fontWeight:'400'}}></th>



                    </tr>
            </tbody>
        </table></div>
        </div> ):''} 

    </div>
  );
}

export default UtilizationReport;
