import React, { useEffect, useRef,useState } from "react";
import Chart from "chart.js";
import { toPng } from "html-to-image";

export default function PieChart({data}) {
  const chartRef = useRef(null);

  const handleDownload = async () => {
    if (chartRef.current) {
      try {
        // Convert the chart container to a PNG image
        const imageDataURL = await toPng(chartRef.current, {
          backgroundColor: "#ffffff", // Set background color
        });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = imageDataURL;
        downloadLink.download = "chart.png"; // File name
        document.body.appendChild(downloadLink);

        // Trigger the download
        downloadLink.click();

        // Remove the download link from the DOM
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Failed to download chart:", error);
      }
    }
  };

 const organisePieChartData = (clone) => {
    let data = [...clone]
    let arr = { datasets: [{ data: [], backgroundColor: [], Event: [] }], labels: [], }
    // if (this.props.reasonSearchText.length > 0) {
    //   data = data.filter(reason => { return reason.State.replace(/_/gi, '').toLowerCase().startsWith(this.props.reasonSearchText.toLowerCase()) })
    //   for (let i = 0; i < data.length; i++) {
    //     arr.datasets[0].data.push(data[i].TotalDuration);
    //     arr.datasets[0].backgroundColor.push(data[i].Color)
    //     arr.datasets[0].Event.push(data[i].Downtime)
    //     arr.labels.push(data[i].State)

    //   }
    // }
    // else {

    for (let i = 0; i < data.length; i++) {
      arr.datasets[0].data.push(Math.ceil(data[i].Duration / 60));
      arr.datasets[0].backgroundColor.push(data[i].Color)
      arr.datasets[0].Event.push(data[i].Event)
      arr.labels.push(data[i].State)

    }
    //  }


    return arr;
  }
  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const pieChart = new Chart(ctx, {
      type: "pie",
      data: organisePieChartData(data),

      options: {
      cutoutPercentage: 50,

        plugins: {
            datalabels:{
                color: "black",
            },
          legend: {
            position: "top", // Position of the legend
          },
          tooltip: {
            enabled: true, // Enable tooltips
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    });

    // Cleanup the chart instance on component unmount
    return () => {
      pieChart.destroy();
    };
  }, []);

  return (<>
       <button className="btn btn-primary mx-2" style={{height:'40px'}} onClick={handleDownload}>
        Download Chart
      </button>
    <div style={{ width: "700px", height: "700px" }}>
      <canvas ref={chartRef}></canvas>
    </div>
  </>
  );
}
