import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import ColumnChart from '../Charts/ColumnChart';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import 'bootstrap/dist/js/bootstrap'
import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import { Chart } from 'react-google-charts';
import common from '../utill/common';
import TableForDowntimeAnalysis from './../utill/tableForMachineDowntimeAnalysis';
import DateSelection from '../Common/DateSelection';
import { ToastContainer, toast } from 'react-toastify';

const JobOEE = ({ user, equipGroups, componentList,reasons,fromDateLimit ,storeDataInLocalStorage}) => {
    const [availabilityData, setAvailabilityData] = useState([]);
    const [data, setData] = useState([])
    const [inputData, setInputData] = useState({})
    const [equip, setEquip] = useState([]);
    const [operationList, setOperationList] = useState([]);
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [selectedReason, setSelectedReason] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState([]);
    const [selectedOperation, setSelectedOperation] = useState([])
    const [errors, setErrors] = useState({})
    const [querySuccess, setQuerySuccess] = useState(false)
    const [tableData, setTableData] = useState([]);
    const [operationMaster, setOperationMaster] = useState([]);

    const[compList,setCompList]=useState([])

    // useEffect(()=>{
    //   axios.get(api.api1 + "component")
    //   .then((result)=>{
    //     setCompList(result.data)
    //   })
    //   .catch((err)=>{
    //     // consol(err);
    //   })
  
    // },[])


    // useEffect(()=>{
    //     (async()=>{
    //         let op=await axios.get(api.api+'operation');
    //         setOperationMaster(op.data);

    //     })()
    // },[])



    useEffect(() => {
        const fetchData = async () => {
          try {
            let compRes = await storeDataInLocalStorage('component')

            // const compRes = await axios.get(api.api1 + 'component');
            setCompList(compRes);
          } catch (error) {
            // console.error("Error fetching component data:", error);
          }
      
          try {
            let operationRes = await storeDataInLocalStorage('operation')

            // const operationRes = await axios.get(api.api1 + 'operation');
            setOperationMaster(operationRes);
          } catch (error) {
            // console.error("Error fetching operation data:", error);
          }
        };
      
        fetchData();
      }, []);

    const sortColumn={ path: 'Date', order: 'asc' };
    const schema = {
        From: joi.date().required().label('From'),
        Till: joi.date().required().label('Till'),
        Component: joi.array().min(1).required(),
        Operation: joi.array().min(1).required(),
        UserId: joi.string(),
    }
    const handleOnChange = async (e) => {

        if (e.currentTarget.type === 'date') {
            // consol(new Date(e.currentTarget.value))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
            setInputData(inputData);
        }
        else if (e.currentTarget.type === 'time') {
            let Time = e.currentTarget.value.split(':');
            // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
            setInputData(inputData);
        }
        else if (e.currentTarget.name === 'EquipmentGroup') {
            inputData[e.currentTarget.name] = e.currentTarget.value;
            let filterObj = equipGroups.filter(one => one.EquipmentGroup == e.currentTarget.value)[0]
            let res = await axios.put(api.api1 + 'equipment', { EquipmentGroup: filterObj._id })
            setEquip(res.data)

            setInputData(inputData);
        }
        else if (e.currentTarget.name === 'Component') {
            inputData[e.currentTarget.name] = e.currentTarget.value;
            let filterObj = componentList.filter(one => one.ComponentId == e.currentTarget.value)
            let res = filterObj(filterObj[0])
            setOperationList(res.Operation)

            setInputData(inputData);
        }
        else {
            inputData[e.currentTarget.name] = e.currentTarget.value
        }
        setErrors({})

    }

    const organiseDowntimeData = (bufferArr) => {
        // [
        //     ['City', '2010 Population', '2000 Population'],
        //     ['New York City, NY', 8175000, 8008000],
        //     ['Los Angeles, CA', 3792000, 3694000],
        //     ['Chicago, IL', 2695000, 2896000],
        //     ['Houston, TX', 2099000, 1953000],
        //     ['Philadelphia, PA', 1526000, 1517000],
        // ]
        let schema = ['Date']
        let arr = [];
        let colorWithReason = []
        let seriesObj = {}
        bufferArr.forEach(element => {
            element.Downtime.forEach((one, i) => {
                let IsAlready;
                let obj = {}

                if (one.DowntimeReason) {

                    IsAlready = schema.includes(one.DowntimeReason);
                } else {

                    IsAlready = schema.includes(one.State);
                }
                if (!IsAlready) {
                    if (one.DowntimeReason) {
                        obj.Reason = one.DowntimeReason;
                        obj.Color = one.Color;
                        colorWithReason.push(obj)
                        schema.push(one.DowntimeReason)
                        schema.push({ role: 'style' })
                    } else {
                        obj.Reason = one.State;
                        obj.Color = 'red';
                        colorWithReason.push(obj)
                        schema.push(one.State)
                        schema.push({ role: 'style' })
                    }
                }
            })
        });

        bufferArr.forEach(element => {
            let oneArr = []
            if (element.Downtime.length > 0) {
                if(inputData.Periodicity=='Weekly'||inputData.Periodicity=='Monthly'){
                    oneArr = [date.getDateFormat(new Date(element.DateTime)) +'-'+date.getDateFormat(new Date(element.DateTime1))+ '/' + element.Shift]
                }else{
                    oneArr = [date.getDateFormat(new Date(element.DateTime)) + '-' + element.Shift]
                }
            }
            let down = element.Downtime;
            down = aggreagteReasonDowntime(down)
            for (let i = 1; i < schema.length; i = i + 2) {
                let filterDown = down.filter((one) => {
                    if (one.DowntimeReason) {
                        if (one.DowntimeReason == schema[i]) {
                            return one
                        }
                    } else {
                        if (one.State == schema[i]) {
                            return one
                        }
                    }
                }
                )
                if (filterDown.length > 0) {
                    oneArr.push(Math.ceil(filterDown[0].Duration / 60));
                    oneArr.push(filterDown[0].Color)

                } else {
                    let filterColor = colorWithReason.filter(color => color.Reason == schema[i])
                    oneArr.push(0);
                    oneArr.push(filterColor[0].Color)
                }
            }
            arr.push(oneArr)
        })
        arr.unshift(schema)
        colorWithReason.forEach((one, i) => {
            seriesObj[i] = { color: one.Color }
        })

        let returnObj = { arr: arr, color: seriesObj }
        return returnObj
    }

    function aggreagteReasonDowntime(bufferArry) {

        for (let i = 0; i < bufferArry.length; i++) {
            for (let j = i + 1; j < bufferArry.length; j++) {
                if (bufferArry[i].DowntimeReason === bufferArry[j].DowntimeReason) {
                    bufferArry[i].Duration += bufferArry[j].Duration
                    bufferArry.splice(j, 1);

                    // i--;
                    j--;
                    // i=j-1
                }
            }
        }
        return bufferArry
    }
    const handleDownload = (e, date) => {
        generateExcelFile(e, date)
    }

    const column=[
        {key:'Date',label:'Date',content:(obj)=><label>{date.getDateFormat(obj.From)}</label>},
        {path:'From',label:'From',content:(obj)=><label>{date.getTime(obj.From)}</label>},
        {path:'Till',label:'Till',content:(obj)=><label>{date.getTime(obj.Till)}</label>},
        {path:'Shift',label:'Shift'},
        {path:'Equipment',label:'Equipment'},
        {path:'Component',label:'Component'},
        {
            key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
              let component = [...compList];
              component = component.filter((one) => one.ComponentId == obj.Component)
              if (component.length > 0) {
                return <label>{component[0].Alias}</label>
              } else {
                return <label>N/A</label>
              }
            }
          },
        
        {path:'Operation',label:'Operation'},
        {path:'Operator',label:'Operator'},
        {path:'TargetQty',label:'Job Target'},
        {path:'ProducedQty',label:'Produced Qty'},
        {path:'PlannedProdTime',label:'Planned Prod Time(In Mins)'},
        {path:'Availability',label:'Availability(%)'},
        {path:'Performance',label:'Performance(%)'},
        {path:'QualityFactor',label:'QualityFactor(%)'},
        {path:'OEE',label:'OEE(%)'},
    ]

    const handleOnSubmit = (inputData) => {
        let operation=[...selectedOperation];
        operationMaster.forEach((one)=>{
           operation.forEach((op)=>{
               if(one.OperationName==op.label){
                   op.value=one._id
               }
           })
        })
        inputData.Component = selectedComponent;
        inputData.Operation = operation;
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setQuerySuccess(true)

        inputData.UserId = user._id
        axios.post(api.api + 'GetJobOEE', inputData).then(async (result) => {
            // consol(result.data)
            if (result.data.length > 0) {
                setTableData(result.data)
                setData(result.arr)
                setQuerySuccess(false)
            }
            else {
                swal('Data Not Available');
                setQuerySuccess(false)
            }
        })
            .catch((err) => {
                  toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                setQuerySuccess(false)
                // consol(err)
            })
    }
    const handleDonwLoad = (e, date) => {
        generateExcelFile(e, date)
    }

    // const handleSort = (sortColumn) => {
    //     if (availabilityData.length > 0) {
    //         const sorted = _.orderBy(
    //             availabilityData,
    //             [sortColumn.path],
    //             [sortColumn.order]
    //         );
    //         setSortColumn(sortColumn)
    //     }
    // }

    const handleOnChangeSelect = async (e, value, key) => {
        let option;
        if (key == 'EquipmentGroup') {
            option = [...selectedEquipGroupOption];
        }
        if (key == 'Equipment') {
            option = [...selectedEquipOption];
        }
        if (key == 'Reason') {
            option = [...selectedReason];
        }
        if (key == 'Component') {
            option = [...selectedComponent];
        }
        if (key == 'Operation') {
            option = [...selectedOperation];
        }
        if (value == 'All') {
            if (e.currentTarget.checked) {
                let obj = {}
                obj.label = value;
                obj.value = e.currentTarget.id;
                option.push(obj);
                if (key == 'Equipment') {
                    equip.forEach(one => {
                        let obj = {}
                        obj.label = one['EquipmentID'];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }
                else if (key == 'Component') {
                    componentList.forEach(one => {
                        let obj = {}
                        obj.label = one['ComponentId'];
                        obj.value = one._id;
                        option.push(obj);
                    })
                } 
                else if (key == 'Operation') {
                    operationList.forEach(one => {
                        let obj = {}
                        obj.label = one['OperationName'];
                        if(operationMaster.filter(op=>op.OperationName==one['OperationName']).length>0){
                            obj.value = operationMaster.filter(op=>op.OperationName==one['OperationName'])[0]._id;
                        }else{
                            obj.value = one._id;
                        }
                        option.push(obj);
                    })
                } else {
                    equipGroups.forEach(one => {
                        let obj = {}
                        obj.label = one[e.currentTarget.name];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }

            } else {
                option.splice(0, option.length);
            }
        } else {
            if (e.currentTarget.checked) {
                if(key=='Operation'){
                    let obj = {}
                    obj.label = value;
                    obj.value = e.currentTarget.id;
                    option.push(obj);
                }else{
                    let obj = {}
                    obj.label = value;
                    obj.value = e.currentTarget.id;
                    option.push(obj);
                }
            } else {
                option = option.filter(one => one.value !== e.currentTarget.id);

            }
        }
        let query = []
        option.forEach(one => {
            let obj = {}
            if (one.value !== 'All') {
                obj.EquipmentGroup = one.value;
                query.push(obj)
            }
        })

        option = _.uniqWith(
            option,
            (a, b) =>
                a.value === b.value
        );
        if (key == 'EquipmentGroup') {
            if (query.length > 0) {
                let res = await axios.put(api.api1 + 'equipment', { $or: query })
                setEquip(res.data)
            }

            setSelectedEquipGroupOption(option);
        }
        if (key == 'Equipment') {
            setSelectedEquipOption(option)
        }
        if (key == 'Reason') {
            setSelectedReason(option)
        }
        if (key == 'Component') {
            setSelectedComponent(option)
            let arr=[]
            option.forEach((one)=>{
              componentList.forEach((oneDoc)=>{
                  if(oneDoc.ComponentId==one.label){
                      arr=[...arr,...oneDoc.Operation]
                  }
              })
            })
            let arr1=[]
            operationMaster.forEach(one => {
                if(arr.filter(op=>op.OperationID==one.OperationName).length>0){
                   arr1.push(one);
                }
            })
            setOperationList(arr1);
        }
        if (key == 'Operation') {
            setSelectedOperation(option)
        }

    }

    const getCheckeStatus = (value, key) => {
        let option;
        if (key == 'EquipmentGroup') {
            option = [...selectedEquipGroupOption];
        }
        if (key == 'Equipment') {
            option = [...selectedEquipOption];
        }
        if (key == 'Reason') {
            option = [...selectedReason];
        }
        if (key == 'Component') {
            option = [...selectedComponent];
        }
        if (key == 'Operation') {
            option = [...selectedOperation];
        }
        let filterData = option.filter(one => value == one.value)
        if (filterData.length > 0) {
            return true
        } else {
            return false
        }
    }
    


    const getCount = (data) => {
        let count = 0
        if (data.filter(one => one.value == 'All').length > 0) {
            count = data.length - 1;
        } else {
            count = data.length;
        }

        return count
    }

    const SelectedDates =(dates)=>{
        setInputData(dates)
      }
    return (
        <React.Fragment>              <ToastContainer/>

            {querySuccess ? <Loading /> : ''}
            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Jobs OEE
                </h4>
            </div>
            <div className="row ml-2">
                {/* <div className="col-sm-3">
                    <div className='form-group text-center'>
                        <label className='small'>Equipment Group</label>
                        <div id="list1" className="dropdown p-0 m-0  form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0  btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {getCount(selectedEquipGroupOption) > 0 ? getCount(selectedEquipGroupOption) + ' Equipment Group' : 'Select Equipment Group'}
                            </button>
                            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'EquipmentGroup')} onChange={(e) => handleOnChangeSelect(e, 'All', 'EquipmentGroup')} id='All' name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;All </li>
                                {equipGroups.map((one) => {
                                    return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'EquipmentGroup')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentGroup, 'EquipmentGroup')} name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;{one.EquipmentGroup} </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-sm-3">
                    <div className='form-group text-center'>
                        <label className='small'>Equipment</label>
                        <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {getCount(selectedEquipOption) > 0 ? getCount(selectedEquipOption) + ' Equipment' : 'Select Equipment'}
                            </button>
                            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'Equipment')} onChange={(e) => handleOnChangeSelect(e, 'All', 'Equipment')} id='All' name='Equipment' />&nbsp;&nbsp;&nbsp;All </li>
                                {equip.map((one) => {
                                    return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'Equipment')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentID, 'Equipment')} name='Equipment' />&nbsp;&nbsp;&nbsp;{one.EquipmentID} </li>
                                })}
                            </ul>
                        </div>

                    </div>
                </div> */}

                <div className="col-sm-3">
                    <div className='form-group text-center'>
                        <label className='small'>Component</label>
                        <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {getCount(selectedComponent) > 0 ? getCount(selectedComponent) + ' Component' : 'Select Component'}
                            </button>
                            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'Component')} onChange={(e) => handleOnChangeSelect(e, 'All', 'Component')} id='All' name='Component' />&nbsp;&nbsp;&nbsp;All </li>
                                {componentList.map((one) => {
                                    return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'Component')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.ComponentId, 'Component')} name='Component' />&nbsp;&nbsp;&nbsp;{one.ComponentId} </li>
                                })}
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="col-sm-3">
                    <div className='form-group text-center'>
                        <label className='small'>Operation</label>
                        <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {getCount(selectedOperation) > 0 ? getCount(selectedOperation) + ' Operation' : 'Select Operation'}
                            </button>
                            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'Operation')} onChange={(e) => handleOnChangeSelect(e, 'All', 'Operation')} id='All' name='Operation' />&nbsp;&nbsp;&nbsp;All </li>
                                {operationList.map((one) => {
                                    return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'Operation')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.OperationName, 'Operation')} name='Operation' />&nbsp;&nbsp;&nbsp;{one.OperationName} </li>
                                })}
                            </ul>
                        </div>

                    </div>
                </div>
                <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

                {/* <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>From</label>
                        <input className='form-control form-control-sm' type='date' onChange={handleOnChange} min={fromDateLimit} name='From' />
                        {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                    </div>
                    </div> */}
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
              
                {/* <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Till</label>
                        <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                        {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                    </div>
                    </div> */}
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
                {/* <div className="col-sm-2">
                    <div className='form-group text-center'>
                        <label className='small'>Periodicity</label>
                        <select className='form-control form-control-sm' onChange={handleOnChange} name='Periodicity'>
                        <option>--Select Periodicity--</option>
                            <option>Shift</option>
                            <option>Daily</option>
                            <option>Weekly</option>
                            <option>Monthly</option>
                        </select>
                        {errors.Periodicity && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Periodicity}</p>}
                    </div>
                </div> */}
                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>
              
            </div>
            <div className='row ml-2'>
           
              
                {/* {availabilityData.length > 0 ? <div className="col-sm-2">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDonwLoad('Availability', inputData.DateTime)}>Download Excel</button>
                </div> : ''} */}
            </div>
            { tableData.length > 0 ? <div className="col-sm-2">
                <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('JobOEE')}>Download Excel</button>
            </div> : ''}
            <div>
                {tableData.length > 0 ? <Table id='JobOEE' columns={column} sortColumn={sortColumn} data={tableData} /> : ''}
            </div>
        </React.Fragment>


    );
}

export default JobOEE;