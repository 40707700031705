import React, { Component } from 'react';
import valid from '../../../utill/validation';
import joi from 'joi';
import { Modal, Container, Col } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import EquipmentType from './../EquipmentType/EquipmentType';
import swal from 'sweetalert';
import Select from 'react-select';
import axios from '../../../httpServices/httpService';
import api from '../../../config.json';
import MultiSelect from '../../../Common/multiselect';

const ComponentOperation = ({modelState,handleHideModel,Operation,handleOnChange,operationList,toolList,equipmentTypeList,errors,handleChangeEquipmentType,toolRequireRadioState,id,handleRadioChange,getToolListForOptions,getToolListForValue,handleCloseModel,handleSubmitModelClick}) => {
  return ( 
    <Modal show={modelState} onHide={handleHideModel}>
    <Modal.Header closeButton>
      <Modal.Title className='text-center'>Operation</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Container>
        {/* <div className="collapse" data-parent="#parent" id={`Operation${id}`}> */}
        <Col sm={12}>

          <div className="form-group text-center">
            <label>Operational ID</label>
            {/* <select
              className="form-control form-control-sm"
              required
              onChange={handleOnChange}
              name="OperationID"
              placeholder="Enter Operation Id"

              value={Operation.OperationID}>
              <option>--Select Operation--</option>
              {operationList.map(one => {
                return <option>{one.OperationName}</option>
              })}
            </select> */}
            <MultiSelect name="OperationID" handleOnChange={handleOnChange} masterName="OperationName" selected={Operation['OperationID']} all={operationList} objLevel={1}/>


            {errors.OperationID && <div className="alert small alert-danger">{errors['OperationID']}</div>}

          </div>

          <div className="form-group text-center">
            <label>Equipment Type</label>
            {/* <select
              type="text"
              required
              className="form-control form-control-sm"
              name="EquipmentType"
              onChange={handleChangeEquipmentType}
              value={Operation.EquipmentType}
            >
              <option>--select equipment type--</option>
              {equipmentTypeList.map((one, i) => {
                return <option key={i}>{one.EquipmentType}</option>;
              })}
            </select> */}
            <MultiSelect name="EquipmentType" handleOnChange={handleChangeEquipmentType} masterName="EquipmentType" selected={Operation['EquipmentType']} all={equipmentTypeList} objLevel={1}/>

            {errors.EquipmentType && <div className="alert small alert-danger">{errors['EquipmentType']}</div>}

          </div>

          {/* <div className="form-group mt-2">
    <label htmlFor="componentweight">Component Weight</label>
    <input
      className="form-control form-control-sm"
      type="number"
      min="0"
      required
      onChange={handleOnChange}
      name="NetWeightPerComponent"
      placeholder="Enter Component Weight..."
   defaultValue={Operation.NetWeightPerComponent}
    />
    {errors.NetWeightPerComponent && <div className="alert small alert-danger">{errors['NetWeightPerComponent']}</div>}

  </div> */}
            {/* <div className="form-group d-flex">
              <label className='mt-2' htmlFor="componentweigth">Final Operation</label>
              <input className='ml-4'
                type="radio"
                checked={Operation.IsOpFinal}
                name={`IsOpFinal`}
                onChange={(e) => handleOnChange({ currentTarget: { name: "IsOpFinal", value: true } })}
              />
              &nbsp;<label className='mt-2' htmlFor="yes">Yes</label>
              <input
                type="radio"
                className='ml-4'
                name={`IsOpFinal`}
                checked={!Operation.IsOpFinal}
                onChange={(e) => handleOnChange({ currentTarget: { name: "IsOpFinal", value: false } })}
              />
              &nbsp;<label className='mt-2' htmlFor="no">No</label>
            </div> */}
            <div className="form-group d-flex">
              <label className='mt-2' htmlFor="componentweigth">Tool Required To Produce</label>
              <input className='ml-4'
                type="radio"
                value={true}
                checked={toolRequireRadioState === 1}
                name={`ToolRequiredToProduce|${id}`}
                onChange={handleRadioChange}
              />
              &nbsp;<label className='mt-2' htmlFor="yes">Yes</label>
              <input
                type="radio"
                className='ml-4'
                value={false}
                name={`ToolRequiredToProduce|${id}`}
                checked={toolRequireRadioState === 0}
                onChange={handleRadioChange}
              />
              &nbsp;<label className='mt-2' htmlFor="no">No</label>
            </div>
            {toolRequireRadioState === 0 ? (
              <React.Fragment>

                <div className="form-group">
                  <label htmlFor="idealcycletime">Ideal Cycle Time(In Sec)</label>
                  <input
                    className="form-control form-control-sm"
                    onChange={handleOnChange}
                    type="number"

                    min="0"
                    name="IdealCycleTime"
                    defaultValue={Operation.IdealCycleTime}
                  />
                  {errors.IdealCycleTime && <div className="alert small alert-danger">{errors['IdealCycleTime']}</div>}
                </div>

                <div className="form-group">
                  <label htmlFor="PartsPerCycle">Parts Per Cycle</label>
                  <input
                    required
                    onChange={handleOnChange}
                    //disabled={cycleTimeRadioState === 1 ? false : true}
                    className="form-control"
                    type="number"
                    min="0"
                    name="PartsPerCycle"
                    defaultValue={Operation.PartsPerCycle}
                  />
                  {errors.PartsPerCycle && <div className="alert small alert-danger">{errors['PartsPerCycle']}</div>}

                </div>

              </React.Fragment>
            ) : (
              <React.Fragment>
                <Select
                  options={getToolListForOptions(toolList)}
                  value={getToolListForValue(Operation)}
                  onChange={(one) => handleOnChange(one, 'Tool')}
                  isMulti={true}
                />
                 {errors.Tool && <div className="alert small alert-danger">{errors['Tool']}</div>}
                 <div className="form-group">
                <label htmlFor="operationRejectionCost">Operation Rejection Cost (Optional)</label>
                <input
                  required
                  onChange={handleOnChange}
                  //disabled={cycleTimeRadioState === 1 ? false : true}
                  className="form-control"
                  type="number"
                  min="0"
                  name="operationRejectionCost"
                  defaultValue={Operation.operationRejectionCost}
                />
                {errors.operationRejectionCost && <div className="alert small alert-danger">{errors['operationRejectionCost']}</div>}

              </div>
              </React.Fragment>
              /* <div className="form-group">
                <label htmlFor="Tool">Tool</label>
                <select
                  required
                  className="form-control form-control-sm"
                  onChange={handleOnChange}
                  name="Tool"
                 value={ Operation.Tool}
                >
                  <option>----Select Tool-----</option>
                  {toolList.map((one,i) => {
                    return <option key={i}>{one.ToolId}</option>;
                  })}
      
                </select>
                {errors.Tool && <div className="alert small alert-danger">{errors['Tool']}</div>}
              </div> */
            )}

          {/* <div className='text-center'>
  {// consol(isEmpty(this.state.errors))}
    <button
   // ref={this.OkClickRef}
      className=" btn btn-primary"
     // data-toggle={isEmpty(this.state.errors)?"":'collapse'}
     // data-target={`#Operation${id}`}
      onClick={()=>this.handleClickOkValidate(this.state.Operation)}
    >
      OK
    </button>
    <button
     ref={this.OkClickRef}
      className=" btn btn-primary"
      data-toggle='collapse'
      data-target={`#Operation${id}`}
      style={{visibility:'hidden'}}
   />

    
  </div> */}

        </Col>

        {/* </div> */}
      </Container>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseModel} >
        Close
  </Button>
      <Button variant="primary" onClick={() => handleSubmitModelClick(Operation)} >
        Submit
  </Button>
    </Modal.Footer>
  </Modal>
   );
}
 
export default ComponentOperation ;
