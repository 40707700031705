import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";

import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import object from "joi/lib/types/object";
const Refrence = [
  { label: "Equipment", id: "1" },
  { label: "Component", id: "2" },
  { label: "Operator", id: "3" },
  { label: "Tool", id: "4" },
  { label: "Operation", id: "5" },
  { label: "Time", id: "6" },
  { label: "Stage Reasons", id: "7" },
  { label: "Stage", id: "8" },
];

function DaySummaryOneReport({fromDateLimit,storeDataInLocalStorage}) {
  const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);
  const [periodOption, setperiodOption] = useState([
    { label: "Shift", id: "1" },
    { label: "Daily", id: "2" },
    { label: "Weekly", id: "3" },
    { label: "Monthly", id: "4" },
    { label: "Cummulative", id: "5" },
  ]);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [PeriodFortable, setPeriodFortable] = useState('');

  const [selectedDates, setSelectedDates] = useState({
    From: "",
    Till: "",
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState([]);

  const [selectedItemLengths, setSelectedItemLengths] = useState({});



  // Your existing chart code goes here

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    // {
    //   heading: "Equipment",
    //   label: "Equipment",
    //   initialItems: equpdata,
    //   selectedItemIds: selectedItemIds,
    //   name: "Equipment",
    //   enableLocalStorage: false,
    //   multiSelect: true,
    //   searchbar: true,
    // },
    {
        heading: "Periodicity",
        label: "tablecolumns",
        initialItems: periodOption,
        selectedItemIds: selectedItemIds,
        name: "Periodicity",
        enableLocalStorage: false,
        multiSelect: false,
        searchbar: false,
      },
    
 
  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////

  const column2 = [
    { path: "Time", label: "Time" },
    // { path: "Equipment", label: "Equipment" },
    // { path: "EquipmentGroup", label: "EquipmentGroup" },
    { path: "PartName", label: "PartName" },
    { path: "ProcessName", label: "ProcessName" },
    { path: "TargetParts", label: "TargetParts" },
    { path: "QuantityProduced", label: "QuantityProduced" },
    { path: "Rejected", label: "Rejected" },
    { path: "TotalDownTime", label: "TotalDownTime" },

    // { path: "Shift", label: "Shift" },

  ];

  const schema = {
    From:joi.string().required().label("From"),
     Till: joi.string().required().label("Till"),
    Periodicity: joi.string().required().label("Periodicity"),
   
  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
      setQuerySuccess(true);

        // const equipmentdata = await axios.get(api.api1 + "equipment");
    
        // const downtimedata = await axios.get(api.api + "downtimereason");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

         let equipmentdata = await storeDataInLocalStorage('equipment')
        const downtimelist = downtimedata
        .map((reason, index) => ({
          id: `${index}`,
          label: reason.Reason,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));
        // downtimelist.push({id:'40',label:"Down"})

        const uniqueObjects = [];

        // Filter out objects with duplicate combinations of label and id
        const filteredData = downtimelist.filter(obj => {
          // Check if the combination of label and id is already in the uniqueObjects array
          const isDuplicate = uniqueObjects.some(uniqueObj =>
            uniqueObj.label === obj.label
          );
        
          if (!isDuplicate) {
            // If not, add it to the array and keep the object
            uniqueObjects.push({ id: obj.id, label: obj.label });
            return true;
          }
        
          // If the combination of label and id is already in the array, filter it out
          return false;
        });
        
        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

         
          
        setDynamicData(uniqueObjects);
        setequpdata(equiplist);
        setEquipmentDatalist(equipmentdata)
        // consol("fetching data", uniqueObjects);
        
      } catch (error) {
        // console.error("Error fetching data:", error);
      setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts

  const getQualityReason = (ReasonArr) => {
    let returnArr = []
    if (dynamicData.length == 0) {
        returnArr.push({ DowntimeReason: 'default', Duration: 0 });
    } else {
      dynamicData.forEach((one1) => {
            let filterReason = ReasonArr.filter(obj => obj?.DowntimeReason == one1.label)
            if (filterReason.length > 0) {
                returnArr.push({ ...filterReason[0] })
            } else {
                let obj = { DowntimeReason: one1.label, Duration: 0 }
                returnArr.push(obj)
            }
        })
    }

    return returnArr;
}


// let DowntimegroupTotal =[];

// columns2.forEach(column=>{
//   const totalDuration = {};
//   column.forEach(one =>
//       one?.DowntimeReasonWise !== undefined ? one?.DowntimeReasonWise.map((obj) => {
//     const { DowntimeGroup, Duration } = obj;
//     totalDuration[DowntimeGroup] = (totalDuration[DowntimeGroup] || 0) + Duration;
//   }) :'',"testing the function")

  
//   const downtimeGroupObjects = Object.keys(totalDuration).map((group) => ({
//     DowntimeGroup: group,
//     Duration: totalDuration[group],
//   }));
//   DowntimegroupTotal.push(downtimeGroupObjects)

//   // consol("isuvbiubvvisdfvbjisdufbv ",DowntimegroupTotal.map(one => one.map(item=>item.Duration)))
// })

  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
    //   "Equipment",
      "Periodicity",
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections
    // if (name == "Equipment") {

    //  let selected  = [];
    //   newSelectedItems.forEach((item) => {
    //   let selection = EquipmentDatalist.filter(equipment => equipment.EquipmentID === item);
    //   selected.push(selection[0])
    //   inputData['Equips'] = selected
    // })
    // } else {
      inputData[name] = newSelectedItems;
    // }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

    // consol(selectedDates,"current date")
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
    const dateTime = { ...selectedDates };
   let Data ={};
      Data.Periodicity = inputData.Periodicity[0];
      setPeriodFortable(inputData.Periodicity[0])
      Data.From = dateTime.From;
      Data.Till = dateTime.Till;
  
    const errors = validate.validate(Data, schema);
  
    setErrors(errors || {});
    if (errors) return;
  
    setQuerySuccess(true);
  
   

      const results =  axios.put(api.api + '/daySummary1', Data).then(result =>{
      if(result.data?.length > 0) {
      // Process the data as needed
  
     let Tabledata =[];
     result.data.map((items) =>{ 
        let From = items.From;
        let Till =items.Till;
        let Periodicity = items.Periodicity;
     items.Data.map(one=>{
        let Equipment= one.Equipment
        let PartsProduced=one.PartsProduced
        let TotalDownTime=one.TotalDownTime
        let AvailableTime =one.AvailableTime;
        let DowntimeReasonWise =one.DowntimeReasonWise;
        Tabledata.push({From,Till,Periodicity,Equipment,PartsProduced,TotalDownTime,AvailableTime,DowntimeReasonWise})
     })
    })
        setcolumns2(Tabledata);
        // consol(result.data,"data received ");
      } else {
        swal("Data Not Available");
      }
  
      setQuerySuccess(false);
    }).catch((err) => {
          swal(`${err}`);
          // consol(err);
          setQuerySuccess(false);
        });
  };
  


  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };


 
  return (
    <div  className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Day Summary Report
        </h4>
      </div>
      <div className="row mx-3">
       {equpdata.length > 0 ?  <>
         
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
      min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={"form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              // max={TillDateLimit}
            />
          </div>
        </div>

            

        {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  {/* {componentdata.length > 0 && ( */}
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}

                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar}
                    />
                  {/* )} */}
                  {errors[data.heading] && (
                    <p className="text-danger" style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}

<div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div>

 </>:""}           
    </div>
    {columns2.length >0 ?

<div className="rounded shadow my-4 " style={{width:'100%'}}>
<div className="d-flex justify-content-start align-items-center  ">
<div className="uiverse mx-2" onClick={() =>
   handleDownloadExcel("day_summay_one"+ `${moment(dateTime[0]).format('DD/MM/YY')}`+'_'+`${dateTime[1]}`)}>
 <span className="tooltip">Dowload Excel File</span>
 <span>
 Download
 </span>
</div>
</div>
                 <div  style={{ overflowX: 'auto' }}>
                   <table id={'day_summay_one'+ `${moment(dateTime[0]).format('DD/MM/YY')}`+'_'+`${dateTime[1]}`} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0,width: "100%" }}>
            <thead className='small text-center border'>
         
                <tr className='text-center align-self-center table-primary'>
                  

                {PeriodFortable === 'Shift' && <th style={{ verticalAlign: 'middle' }} rowSpan={2} >{'Date' }</th>}
                {PeriodFortable === 'Daily'  && <th style={{ verticalAlign: 'middle' }} rowSpan={2} >{'Date' }</th>}
                {PeriodFortable === 'Weekly' && <th style={{ verticalAlign: 'middle' }} rowSpan={2} >{'Weekly' }</th>}
               
                {PeriodFortable === 'Shift' && <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Shift</th>}
                {PeriodFortable === 'Monthly' && <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Month</th>}
               
                {PeriodFortable === 'Cummulative' && <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Cummulative</th>}
               
                

                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Equipment</th>
             
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Parts Produced</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >AvailableTime</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >TotalDownTime in (min)</th>


    
                <th style={{ verticalAlign: 'middle' }} colSpan={dynamicData.length}>Downtime Reasons (min)
                </th>

                
                   
                </tr>

                <tr className='table-primary' >


                    {dynamicData.length > 0 ? dynamicData.map(one => {
                        return <th className="rotate px-1 m-0 "  style={{  height: (100 + dynamicData.length) * 2.8 }}><div  style={{marginTop:'1rem'}}><span className="text-center">{one.label}</span></div></th>
                    }) : ''}

                </tr>
              
            </thead>
            <tbody>
            {/* "Shift", id: 
 "Daily", id: 
 "Weekly", id:
 "Monthly", id
 "Cummulative" */}
           {columns2.map(one =><><tr >
                    {/* <th className="text-center" style={{fontWeight:'400',width:'200px'}}>{`${moment(one.From).format('DD MMM YY') + ' - '+ moment(one.Till).format('DD MMM YY')}`}</th> */}
                    {PeriodFortable === 'Shift'  && <th className="text-center" style={{fontWeight:'400',width:'200px'}}>{`${moment(one.From).format('DD MMM YY') }`}</th>}
                    {PeriodFortable === 'Shift' && <th className="text-center" style={{fontWeight:'400',width:'200px'}}>{one.Periodicity}</th>}
                
                    {PeriodFortable === 'Daily' && <th className="text-center" style={{fontWeight:'400',width:'200px'}}>{`${moment(one.From).format('DD MMM YY') }`}</th>}

                    {PeriodFortable === 'Weekly' && <th className="text-center" style={{fontWeight:'400',width:'200px'}}>{`${moment(one.From).format('DD MMM YY') + ' - '+ moment(one.Till).format('DD MMM YY')}`}</th>}
                    {PeriodFortable === 'Monthly' && <th className="text-center" style={{fontWeight:'400',width:'200px'}}>{`${moment(one.From).format('DD MMM YY') + ' - '+ moment(one.Till).format('DD MMM YY')}`}</th>}

                    {PeriodFortable  === 'Cummulative' && <th className="text-center" style={{fontWeight:'400',width:'200px'}}>{`${moment(one.From).format('DD MMM YY') + ' - '+ moment(one.Till).format('DD MMM YY')}`}</th>}

                    <th className="text-center" style={{fontWeight:'400'}}>{one.Equipment}</th>
                   
                    <th style={{fontWeight:'400'}} className="text-center">{one.PartsProduced}</th>                      
                    <th className="text-center" style={{fontWeight:'400'}}>{one.AvailableTime}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.TotalDownTime}</th>
                   
                    {getQualityReason(one?.DowntimeReasonWise).length > 0 ? getQualityReason(one?.DowntimeReasonWise).map(r => {
                            return <td >{r.Duration == 0 ? '-': r.Duration}</td>
                        }) : <td >0</td>}

                    </tr>
                   
                    </>               
                   )}
                    {/* <tr> */}
                   

                    {/* <th className="text-center" style={{fontWeight:'400'}}> */}
  {/* {column.reduce((accumulator, currentObject) => {
    const valueToAdd = currentObject?.TargetParts !== '-' ? Number(currentObject.TargetParts) : 0;
   
    return accumulator + valueToAdd;
  }, 0)}
</th>

<th className="text-center" style={{ fontWeight: '400' }}>
  {column.reduce((accumulator, currentObject) => {
    return accumulator + (currentObject?.QuantityProduced !== '-' ? Number(currentObject.QuantityProduced) : 0);
  }, 0)}
</th>

<th className="text-center" style={{ fontWeight: '400' }}>
  {column.reduce((accumulator, currentObject) => {
    return accumulator + (currentObject?.Rejected !== '-' ? Number(currentObject.Rejected) : 0);
  }, 0)}
</th>

<th className="text-center" style={{ fontWeight: '400' }}>
  {column.reduce((accumulator, currentObject) => {
    return accumulator + (currentObject?.TotalDownTime !== '-' ? Number(currentObject.TotalDownTime) : 0);
  }, 0)}
</th> */}

{/* {DowntimegroupTotal && ( getQualityReason(DowntimegroupTotal[index]).map(r =>  <td >{r.Duration == 0 ? '-': r.Duration}</td>))} */}

                    {/* </tr> */}
                    {/* <tr>
                    <th className="text-center" style={{fontWeight:'400'}}>Signatures</th>
                    <th className="text-center" colSpan={7 + dynamicData.length} style={{fontWeight:'400'}}></th>
                    <th className="text-center" style={{fontWeight:'400'}}></th>



                    </tr> */}
            </tbody>
        </table></div>
        </div> :''} 

    </div>
  );
}

export default DaySummaryOneReport;
