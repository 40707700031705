import React from 'react';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import './MultiSelectCheckBox.css';
const MultiSelectCheckBox = ({ name, options, onChange, selectedValues }) => {
    return <div className='form-group text-center'>
        <div id="list1" className="dropdown p-0 m-0  form-control form-control-sm" style={{ display: 'inherit' }} >
            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0  btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {options?options.filter(one=>one.checked).length +" "+name:''} Select
            </button>
            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                <li className="dropdown-item"><input type="checkbox" onChange={(e) => {onChange({currentTarget:{name:name,value:'All',checked:e.currentTarget.checked}})}} id='All' checked={options&&selectedValues?options.length==selectedValues.filter(one=>one.checked).length?true:false:false} name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;All </li>

                {options.map((one) => {
                return <li  className="dropdown-item"> <input type="checkbox" checked={one.checked}  id={one.value} onChange={(e) => {onChange({currentTarget:{name:name,value:one.value,checked:e.currentTarget.checked}})}} name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;{one.label} </li>
            })}
            </ul>
        </div>
        {/* {errors.EquipmentGroup && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.EquipmentGroup}</p>} */}
    </div>


}

export default MultiSelectCheckBox;