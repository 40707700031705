
import React from 'react';
import Select from 'react-select';
import 'bootstrap/dist/js/bootstrap';
import { isArray } from 'lodash';
const MultiSelect = ({ handleOnChange, name, all, selected, masterName, objLevel, master,isDisable,isMulti }) => {

  const getToolListForValue = (selected) => {
    let arr = []
    if (selected) {
      if(!isArray(selected) ){
        all.forEach(one => {
          if (objLevel == 0) {
            if (selected == one) {
              let obj = {}
              obj.label = one
              obj.value = one
              arr.push(obj)
            }
          }
          if (objLevel == 1) {
            if (name == 'OperatorID') {
              if (selected == one[name]) {
                let obj = {}
                obj.label = one[masterName]
                obj.value = one[name]
                arr.push(obj)
              }
            } else {
              if (selected == one[masterName]) {
                let obj = {}
                obj.label = one[masterName]
                obj.value = one._id
                arr.push(obj)
              }
            }
  
          }
  
        })
      }else{
        selected.forEach((one)=>{
          let obj = {}
          obj.label = one
          obj.value = one
          arr.push(obj)
        })
      }
     
    }
    //   if (arrOfObjects.length > 0) {
    //     arrOfObjects.forEach(one => {
    //       let obj = {}
    //       obj.label = one[name]
    //       obj.value = one._id
    //       arr.push(obj)
    //     })
    //     return arr
    //   }
    //   else {
    //     return arr
    //   }
    return arr
  }
  const getToolListForOptions = (list, name) => {
    let arr = []
    list.forEach(one => {
      let obj = {}
      if (objLevel == 0) {
        obj.label = one
        obj.value = one
        arr.push(obj)
      }
      if (objLevel == 1) {
        if (name == 'OperatorID') {
          obj.label = one[masterName]
          obj.value = one[name]
          arr.push(obj)
        }
        // if(name=='ReasonGroup'||name=='Reason'){
        //   obj.label = one[masterName]
        //   obj.value = JSON.stringify(one)
        //   arr.push(obj)
        // }
        else {
          obj.label = one[masterName]
          obj.value = one._id
          arr.push(obj)
        }

      }

    })
    return arr
  }
  const handleOnChnageThis = (one, name) => {
    if (name == 'OperatorID') {
      let e = { currentTarget: { name: name, value: one.value } }
      handleOnChange(e)
    }
    else if (name == 'ReasonGroup' || name == 'Reason') {
      if (master) {
        let e = { currentTarget: { name: name, value: one.label } }
        handleOnChange(e)
      } else {
        let e = { currentTarget: { name: name, value: JSON.stringify(all.filter((obj) => obj._id === one.value)[0]) } }
        handleOnChange(e)
      }

    }
    else if (name == 'Tool') {
      let e = { currentTarget: { name: name, value: one } }
        handleOnChange(e)

    }
    else {
      let e = { currentTarget: { name: name, value: one.label } }
      handleOnChange(e)
    }


  }

  const getDisablility=()=>{
       if(isDisable==undefined){
         return false;
       }
       return isDisable
  }
  return (

    <Select
    // onInputChange={(e)=>handleSearchInput?handleSearchInput(e):""}
    isDisabled={getDisablility()}
      options={getToolListForOptions(all, name)}
      value={getToolListForValue(selected)}
      onChange={(one) => handleOnChnageThis(one, name)}
     isMulti={isMulti}
    />
  );
}

export default MultiSelect;