import React, { Component } from 'react';
import 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min.js';
import Chart from 'react-google-charts';
import { generateExcelFile } from '../utill/download';
import api from '../config.json';
import swal from 'sweetalert';
import axios from '../httpServices/httpService';
import Loading from '../utill/LoadingComponent';
import validate from '../utill/validation';
import joi from 'joi'
import Table from './../Common/table';
import _ from 'lodash';
import DateSelection from '../Common/DateSelection';
import { ToastContainer, toast } from 'react-toastify';


class QualityPareto extends Component {
    state = {
        myNewChart: '',
        filterBy: '',
        barColorStateWise: 'red',
        qualityParetoOrganiseData: [],
        qualityParetoData: [],
        inputData: {},
        errors: {},
        querySuccess: false,
        selectedComponent: [],
        selectedEquipGroupOption: [],
        selectedReason: [],
        selectedOperation: [],
        operationList: [],
        equip: [],
        componentList: [],
        equipGroups: [],
        operationMaster:[]
    }


    componentWillReceiveProps() {
        this.setState({ componentList: this.props.componentList })
    }

    componentDidMount(){
       
        //     let op=await axios.get(api.api+'operation');
        //    this.setState({operationMaster:op.data});

       
           (async()=>{
        try {
            let operationRes = await this.props.storeDataInLocalStorage('operation')

            this.setState({operationMaster:operationRes});
          } catch (error) {
            // console.error("Error fetching operation data:", error);
          } })()
    }

    columns = [
        { path: 'ReasonGroup', label: 'Reason Group' },
        { path: 'Reason', label: 'Reason' },
        { path: 'Count', label: 'Rejection Count' },
        { path: 'OriginalPercentage', label: 'Individual Percentage', content: (obj) => <label>{obj.OriginalPercentage?(parseFloat(obj.OriginalPercentage)).toFixed(1):0}</label> },
        { path: 'Percentage', label: 'Cumulative Percentage', content: (obj) => <label>{obj.Percentage?(parseFloat(obj.Percentage)).toFixed(1):0}</label> },
    ]


    schema = {
        From: joi.date().required().label('From Datetime'),
        UserId: joi.string(),
        Component: joi.array().min(1).label('Component'),
        Operation: joi.array().min(1).label('Operation'),
        Till: joi.date().required().label('Till Datetime'),
        //Shift: joi.string().required().label('Shift')
    }

    handleDownloadExcel = (e) => {
        generateExcelFile(e)
    }

    handleOnChange = async (e) => {
        const { inputData, errors } = { ...this.state }
        if (e.currentTarget.type === 'date') {
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
        }
        else {
            inputData[e.currentTarget.name] = e.currentTarget.value;
        }
        let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime })
        this.setState({ inputData: inputData, errors: errors || {}, shiftSetting: res.data })
    }


    handleOnSubmit = (inputData) => {
        let operation=[...this.state.selectedOperation];
        this.state.operationMaster.forEach((one)=>{
           operation.forEach((op)=>{
               if(one.OperationName==op.label){
                   op.value=one._id
               }
           })
        })
        inputData.Component = this.state.selectedComponent;
        inputData.Operation = operation;
        const errors = validate.validate(inputData, this.schema);
        this.setState({ errors: errors || {} })
        if (errors) return;
        this.setState({ querySuccess: true })
        inputData.UserId = this.props.user._id
        axios.post(api.api + 'GetQualityPareto', inputData).then((result) => {
            // consol(result)
            if (result.data !== null && result.data !== undefined) {
                if (result.data.length > 0) {
                    let data = this.organiseParetoData(result.data)
                    this.setState({ querySuccess: false, qualityParetoOrganiseData: data, qualityParetoData: result.data });

                }
                else {
                    swal('Data Not Available');
                    this.setState({ querySuccess: false })
                }
            }
            else {
                this.setState({ querySuccess: false })
            }

        })
            .catch((err) => {
                  toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                // consol(err)
                this.setState({ querySuccess: false })
            })

    }

    organiseParetoData = (clone) => {
        let data = [...clone];
        let downTimeParetoArray = [];
        let schema = ['Reason', 'Rejection Count',{ role: 'annotation' }, 'Percentage(%)',{ role: 'annotation' }];
        downTimeParetoArray.push(schema);
        //    if (this.props.reasonSearchText.length > 0) {
        //      data = data.filter(reason => { return reason.Reason.replace(/_/gi, '').toLowerCase().startsWith(this.props.reasonSearchText.toLowerCase()) })
        //      for (let i = 0; i < data.length; i++) {
        //        let StateArray = [];
        //        if (data[i].Reason !== "OK") {
        //          StateArray.push(data[i].Reason);
        //          StateArray.push(data[i].Duration);
        //          StateArray.push(data[i].Color.toLowerCase());
        //          StateArray.push(data[i].Percentage);
        //          // accumPer= accumPer+(this.props.downTimeParetoData[i].Duration/(60*1000))/sum*100;
        //          // StateArray.push(accumPer);
        //          downTimeParetoArray.push(StateArray);
        //        }
        //      }
        //    }
        //    else
        //    {
        for (let i = 0; i < data.length; i++) {
            let StateArray = [];
            if (data[i].Reason !== "OK") {
                StateArray.push(data[i].Reason);
                StateArray.push(data[i].Count);
                StateArray.push(data[i].Count);

                StateArray.push(data[i].Percentage?data[i].Percentage:0);
                StateArray.push(data[i].Percentage?data[i].Percentage:0);

                // accumPer= accumPer+(this.props.downTimeParetoData[i].Duration/(60*1000))/sum*100;
                // StateArray.push(accumPer);
                downTimeParetoArray.push(StateArray);
            }
        }
        // }
        // consol(data);
        let accumPer = 0;
        return downTimeParetoArray;
    }


    renderParetoChart = (data) => {
        return <Chart
            width={'1000px'}
            height={'500px'}
            chartType="ComboChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
                backgroundColor: { strokeWidth: 2 },
                // title: this.props.downTimeParetoText,
                annotations: {
                    style: "line",
                    role: "annotation",
                    alwaysOutside: false,
                    stem: {
                      color: "transparent",
                      length: 0, // Adjust the length of the annotation line
                    },
                    textStyle: {
                      fontSize: 12,
                    },
                  },
                vAxes: [
                    { title: 'Count', minValue: 0 },
                    { title: " Cumulative Percentage", minValue: 0, maxValue: 100 }
                ],
                hAxis: { title: 'Reason', slantedTextAngle: 90, slantedText: true },
                seriesType: 'bars',
                series: { 1: { type: "line", targetAxisIndex: 1 } },
            }}
            rootProps={{ 'data-testid': '1' }}
        />
    }


    handleOnChangeSelect = async (e, value, key) => {
        const { selectedComponent, selectedEquipGroupOption, selectedReason, selectedOperation, equip, operationList, componentList, equipGroups } = this.state
        let option;
        if (key == 'EquipmentGroup') {
            option = [...selectedEquipGroupOption];
        }
        // if (key == 'Equipment') {
        //     option = [...selectedEquipOption];
        // }
        if (key == 'Reason') {
            option = [...selectedReason];
        }
        if (key == 'Component') {
            option = [...selectedComponent];
        }
        if (key == 'Operation') {
            option = [...selectedOperation];
        }
        if (value == 'All') {
            if (e.currentTarget.checked) {
                let obj = {}
                obj.label = value;
                obj.value = e.currentTarget.id;
                option.push(obj);
                if (key == 'Equipment') {
                    equip.forEach(one => {
                        let obj = {}
                        obj.label = one['EquipmentID'];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }
                else if (key == 'Component') {
                    componentList.forEach(one => {
                        let obj = {}
                        obj.label = one['ComponentId'];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }
                else if (key == 'Operation') {
                    
                    operationList.forEach(one => {
                        let obj = {}
                        obj.label = one['OperationID'];
                        obj.value = one._id;
                        option.push(obj);
                    })
                } else {
                    equipGroups.forEach(one => {
                        let obj = {}
                        obj.label = one[e.currentTarget.name];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }

            } else {
                option.splice(0, option.length);
            }
        } else {
            if (e.currentTarget.checked) {
                let obj = {}
                obj.label = value;
                obj.value = e.currentTarget.id;
                option.push(obj);

            } else {
                option = option.filter(one => one.value !== e.currentTarget.id);

            }
        }
        let query = []
        option.forEach(one => {
            let obj = {}
            if (one.value !== 'All') {
                obj.EquipmentGroup = one.value;
                query.push(obj)
            }
        })

        option = _.uniqWith(
            option,
            (a, b) =>
                a.value === b.value
        );
        if (key == 'EquipmentGroup') {
            if (query.length > 0) {
                // let res = await axios.put(api.api1 + 'equipment', { $or: query })
                // setEquip(res.data)
            }
            this.setState({ selectedEquipGroupOption: option })
        }
        // if (key == 'Equipment') {
        //     setSelectedEquipOption(option)
        // }
        // if (key == 'Reason') {
        //     setSelectedReason(option)
        // }
        if (key == 'Component') {
            this.setState({ selectedComponent: option })
            let arr = []
            option.forEach((one) => {
                componentList.forEach((oneDoc) => {
                    if (oneDoc.ComponentId == one.label) {
                        arr = [...arr, ...oneDoc.Operation]
                    }
                })
            })
            // OperationID
            for(let i = 0; i < arr.length; i++) {
                for(let j =i+1 ; j < arr.length ; j++) {
                    if(arr[i].OperationID === arr[j].OperationID){
                        arr.splice(j, 1);
                        j--;
                    }
                }
            }

            this.setState({ operationList: arr })
            //setOperationList(arr);
        }
        if (key == 'Operation') {
            this.setState({ selectedOperation: option });
            // setSelectedOperation(option)
        }

    }

    getCheckeStatus = (value, key) => {
        const { selectedEquipGroupOption, selectedReason, selectedComponent, selectedOperation } = this.state;
        let option;
        if (key == 'EquipmentGroup') {
            option = [...selectedEquipGroupOption];
        }

        if (key == 'Reason') {
            option = [...selectedReason];
        }
        if (key == 'Component') {
            option = [...selectedComponent];
        }
        if (key == 'Operation') {
            option = [...selectedOperation];
        }
        let filterData = option.filter(one => value == one.value)
        if (filterData.length > 0) {
            return true
        } else {
            return false
        }
    }



    getCount = (data) => {
        let count = 0
        if (data.filter(one => one.value == 'All').length > 0) {
            count = data.length - 1;
        } else {
            count = data.length;
        }

        return count
    }


    SelectedDates =(dates)=>{
       this.setState({inputData:dates});
      }

    render() {
        const { getCount, getCheckeStatus, handleOnChangeSelect, handleOnChange, handleOnSubmit, handleDownloadExcel, renderParetoChart } = this;
        const { querySuccess, selectedOperation, selectedReason, selectedEquipGroupOption, selectedComponent, componentList, operationList, inputData, errors } = this.state;
        return (
            <React.Fragment>  <ToastContainer />
                {querySuccess ? <Loading /> : ''}
                <div className="text-center heading-bg mb-4">
                    <h4 className="text-white p-0 m-0" >
                        Quality Pareto
                    </h4>
                </div>
                <div className="row ml-2">
                    {/* <div className="col-sm-3">
                <div className='form-group text-center'>
                    <label className='small'>Equipment Group</label>
                    <div id="list1" className="dropdown p-0 m-0  form-control form-control-sm" style={{ display: 'inherit' }} >
                        <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0  btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {getCount(selectedEquipGroupOption) > 0 ? getCount(selectedEquipGroupOption) + ' Equipment Group' : 'Select Equipment Group'}
                        </button>
                        <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                            <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'EquipmentGroup')} onChange={(e) => handleOnChangeSelect(e, 'All', 'EquipmentGroup')} id='All' name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;All </li>
                            {equipGroups.map((one) => {
                                return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'EquipmentGroup')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentGroup, 'EquipmentGroup')} name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;{one.EquipmentGroup} </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div> */}
                    {/* <div className="col-sm-3">
                <div className='form-group text-center'>
                    <label className='small'>Equipment</label>
                    <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                        <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {getCount(selectedEquipOption) > 0 ? getCount(selectedEquipOption) + ' Equipment' : 'Select Equipment'}
                        </button>
                        <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                            <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'Equipment')} onChange={(e) => handleOnChangeSelect(e, 'All', 'Equipment')} id='All' name='Equipment' />&nbsp;&nbsp;&nbsp;All </li>
                            {equip.map((one) => {
                                return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'Equipment')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentID, 'Equipment')} name='Equipment' />&nbsp;&nbsp;&nbsp;{one.EquipmentID} </li>
                            })}
                        </ul>
                    </div>

                </div>
            </div> */}

                    <div className="col-sm-3">
                        <div className='form-group text-center'>
                            <label className='small'>Component</label>
                            <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                                <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {getCount(selectedComponent) > 0 ? getCount(selectedComponent) + ' Component' : 'Select Component'}
                                </button>
                                <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                    <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'Component')} onChange={(e) => handleOnChangeSelect(e, 'All', 'Component')} id='All' name='Component' />&nbsp;&nbsp;&nbsp;All </li>
                                    {componentList.map((one) => {
                                        return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'Component')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.ComponentId, 'Component')} name='Component' />&nbsp;&nbsp;&nbsp;{one.ComponentId} </li>
                                    })}
                                </ul>
                            </div>
                            {errors.Component && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Component}</p>}


                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className='form-group text-center'>
                            <label className='small'>Operation</label>
                            <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                                <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {getCount(selectedOperation) > 0 ? getCount(selectedOperation) + ' Operation' : 'Select Operation'}
                                </button>
                                <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                    <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'Operation')} onChange={(e) => handleOnChangeSelect(e, 'All', 'Operation')} id='All' name='Operation' />&nbsp;&nbsp;&nbsp;All </li>
                                    {operationList.map((one) => {
                                        return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'Operation')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.OperationID, 'Operation')} name='Operation' />&nbsp;&nbsp;&nbsp;{one.OperationID} </li>
                                    })}
                                </ul>
                            </div>
                            {errors.Operation && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Operation}</p>}

                        </div>
                    </div>
      <DateSelection SelectedDates={this.SelectedDates} fromDateLimit={this.props.fromDateLimit} />

                    {/* <div className="col-sm-2">
                        <div className="form-group text-center">
                            <label className='small'>From</label>
                            <input className='form-control form-control-sm' min={this.props.fromDateLimit} type='date' onChange={handleOnChange} name='From' />
                            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                        </div>
                        </div> */}
                        {/* <div className="form-group ">
        <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
      </div> */}

                    {/* <div className="col-sm-2">
                        <div className="form-group text-center">
                            <label className='small'>Till</label>
                            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                        </div>
                        </div> */}
                        {/* <div className="form-group ">
        <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
      </div> */}
                    {/* <div className="col-sm-2">
                <div className='form-group text-center'>
                    <label className='small'>Periodicity</label>
                    <select className='form-control form-control-sm' onChange={handleOnChange} name='Periodicity'>
                    <option>--Select Periodicity--</option>
                        <option>Shift</option>
                        <option>Daily</option>
                        <option>Weekly</option>
                        <option>Monthly</option>
                    </select>
                    {errors.Periodicity && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Periodicity}</p>}
                </div>
            </div> */}
                    <div className="col-sm-1">
                        <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                    </div>

                </div>
                {this.state.qualityParetoOrganiseData.length > 0 ? <div className='row ml-2'>
                    {renderParetoChart(this.state.qualityParetoOrganiseData)}

                </div> : ''}

                {this.state.qualityParetoData.length > 0 ? <div className="col-sm-7 table-responsive-sm table-striped" >
                    <p className='btn btn-primary btn-sm' onClick={() => handleDownloadExcel('QualityPareto')}>Download Excel</p>
                    <Table columns={this.columns} id='QualityPareto' sortColumn={{ path: 'Reason', order: 'asc' }} data={this.state.qualityParetoData} />
                </div > : ''}
            </React.Fragment>

        );
    }
}

// function getip(json) {
//     alert( json.ip);
//     }


export default QualityPareto;