import React, { Component,useState } from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

const Table = (props) => {
    const [query,setQuery]=useState('');
    const { columns, data, viewSelected, sortColumn, onSort, id,height } = props;
    const [isInputFocused, setIsInputFocused] = useState(false);


    const handleInputFocus = () => {
      setIsInputFocused(true);
    };
  
    const handleInputBlur = () => {
      setIsInputFocused(false);
    };
  
    const outerDivStyle = {
      boxShadow: isInputFocused ? '0px 0px 5px 3px #bcc6e3' : '0px 0px 5px 2px rgba(0, 0, 0, 0.1)',

    };
    // consol(columns);
    // consol(data)
    const handleOnChange=(e)=>{
      setQuery(e.currentTarget.value);
    }

    const getData=(data)=>{
        let cloneData=[...data];
        if(query==""){
           
        }else{
            cloneData=searchArrayOfObjects(cloneData,query)
            }
        return cloneData;
    }

    const searchArrayOfObjects=(arr,search)=>{
     let arr1=[]
     for(let i=0;i<arr.length;i++){
        let keys=Object.keys(arr[i])
        for(let k=0;k<keys.length;k++){
            if(_.includes(arr[i][keys[k]],search)){
                arr1.push(arr[i]);
                break;
            }
        }
     }
     return arr1;
    }
    return (
        <div className='py-2 table-screen' >
          <div className='desktop-only'>
           <div className="  input-group mb-2 mx-2 w-auto mx-2 px-2 d-flex align-items-center bg-white rounded-pill " style={outerDivStyle}>
      <span
        className="d-flex align-items-center p-1 my-1 mr-1"
        style={{
          height: '32px',
          width: '32px',
          border: '3px solid #bcc6e3',
          borderRadius: '50%',
          boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.3)',
        }}
      >
        <FontAwesomeIcon color="#bcc6e3" icon={faSearch} />
      </span>

      <input
        type="text"
        onChange={(e)=>handleOnChange(e)}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        className="d-flex align-items-center form-control-sm mx-2 "
        style={{ background: 'white' ,borderLeft:'1px solid #e8e8e8',borderTop:'0px',borderBottom:'0px',borderRight:'0px'}}
        placeholder="Search..."
        aria-label="Search"
        aria-describedby="basic-addon1"
      />
    </div>
    </div>
    <div className="mobile-only">
    <div className="  input-group mb-2 mx-2 w-auto mx-2 px-2 d-flex align-items-center bg-white rounded-pill " style={outerDivStyle}>
      <span
        className="d-flex align-items-center p-1 my-1 mr-1"
        style={{
          height: '16px',
          width: '16px',
          // border: '3px solid #bcc6e3',
          borderRadius: '50%',
          // boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.3)',
        }}
      >
        <FontAwesomeIcon color="#bcc6e3" icon={faSearch} />
      </span>

      <input
        type="text"
        onChange={(e)=>handleOnChange(e)}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        className="d-flex align-items-center form-control-sm mx-2 "
        style={{ background: 'white' ,borderLeft:'1px solid #e8e8e8',borderTop:'0px',borderBottom:'0px',borderRight:'0px'}}
        placeholder="Search..."
        aria-label="Search"
        aria-describedby="basic-addon1"
      />
    </div>
    </div>
    <div
  className="table-responsive"
  style={{ minHeight: height === 'auto' ? '' : '600px' }}
>



            <table style={{ fontSize: 14, padding: 0 }} id={id} className={props.grid?"table  table-bordered table-striped small table-sm table-hover common":"table table-striped small table-sm table-hover common"}>
                <TableHeader id={id} columns={columns} sortColumn={sortColumn} onSort={onSort} />
                <TableBody data={getData(data)} columns={columns} />

            </table> 
    </div>
            </div>);
}

export default Table;