import React, { useState,useEffect } from 'react';
import Table from '../Common/table';
import validation from '../utill/validation';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import {generateExcelFile} from '../utill/download';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const OperatorPerformance = ({user,fromDateLimit,storeDataInLocalStorage}) => {
  const [operatorData, setOperatorData] = useState([])
  const [view, setView] = useState([])
  const [inputData, setInputData] = useState({})
  const [errors, setErrors] = useState({})
  const [querySuccess, setQuerySuccess] = useState(false)
  const [shiftSetting,setShiftSetting]=useState([])
  const [sortColumn,setSortColumn]=useState({ path: 'Date', order: 'asc' })

  const schema = {
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
   // Shift: joi.string().required().label('Shift'),
    UserId: joi.string()
  }
  const[compList,setCompList]=useState([])


  // useEffect(()=>{
  //   axios.get(api.api1 + "component")
  //   .then((result)=>{
  //     setCompList(result.data)
  //   })
  //   .catch((err)=>{
  //     // consol(err);
  //   })

  // },[])

    useEffect(() => {
    const fetchData = async () => {
      try {
        // const result = await axios.get(api.api1 + 'component');
 let result = await storeDataInLocalStorage('component')

        setCompList(result);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

  
  const columns=[
    {path:'Date',label:'Date',content:(obj)=><label>{date.getDateFormat(obj.Date)}</label>},
    {path:'Shift',label:'Shift'},
    {path:'Operator',label:'Operator'},
    {path:'Equipment',label:'Equipment'},
    {path:'OperatorID',label:'OperatorID'},
    {path:'Component',label:'Component'},
    {
      key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
        let component = [...compList];
        component = component.filter((one) => one.ComponentId == obj.Component)
        if (component.length > 0) {
          return <label>{component[0].Alias}</label>
        } else {
          return <label>N/A</label>
        }
      }
    },
    {path:'OperationID',label:'OperationID'},
    {path:'ToolID',label:'Tool',content:(obj)=><label>{obj.ToolID=='null'||obj.ToolID==undefined||obj.ToolID==null?'-':obj.ToolID}</label>},
    {path:'DeploymentDuration',label:'Deployment Duration'},
    { path: 'AvailableTime', label: 'RunTime (In Mins)' ,content:(obj)=><label>{(obj.AvailableTime/60).toFixed(1)}</label>},
    {path:'IdealCycles',label:'Ideal Cycles'},
    {path:'ActualCycles',label:'Actual Cycles'},
    {path:'Performance',label:'Performance(%)'},
  
    
]

 

  const handleOnChange = async (e) => {

    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
      // let res= await axios.put(api.api+'GetShiftSettingForDateShift',{DateTime:inputData.DateTime})
      // setShiftSetting(res.data)
      setInputData(inputData);
    }
    else if (e.currentTarget.type === 'time') {
      let Time = e.currentTarget.value.split(':');
      inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
      setInputData(inputData);
    }
    else {
      inputData[e.currentTarget.name] = e.currentTarget.value
    }
    setErrors({})
    // consol(inputData);

  }
  const handleOnSubmit = (inputData) => {
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    inputData.UserId=user._id
  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }

    axios.post(api.api + 'GetOperatorPerformanceDetailed', inputData).then((result) => {
      if (result.data.length > 0) {
        setOperatorData(result.data)
        setQuerySuccess(false)
      }
      else {
        swal('Data Not Available');
        setQuerySuccess(false)
      }
    })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        setQuerySuccess(false)
        // consol(err)
      })

  }

  const handleDownload=(e,date)=>{
    generateExcelFile(e,date)
  }

  const handleSort = (sortColumn) => {
    if (operatorData.length > 0) {
      const sorted = _.orderBy(
        operatorData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setSortColumn(sortColumn)
      setOperatorData(sorted)
    }
  }

    
  const SelectedDates =(dates)=>{
    setInputData(dates)
  }
  return (
    <React.Fragment>
      {querySuccess ? <Loading /> : ''}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Operator Performance Detailed
        </h4>
      </div>
      <ToastContainer/>
      <div className="row">
      <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
          </div> */}
          {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
          </div> */}
          {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='Till' />
          </div> */}

        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              {shiftSetting.map(one=>{
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Periodicity</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewBy'>
              <option>--select periodicity--</option>
              <option>Hourly</option>
              <option>Daily</option>
              <option>Shift</option>
            </select>
            {errors.ViewBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewBy}</p>}
          </div>
        </div> */}

        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>

       {operatorData.length>0? <div className="col-sm-2">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('OperatorPerfReport',inputData.DateTime)}>Download Excel</button>
        </div>:''}



      </div>
      <div>
       {operatorData.length>0? <Table columns={columns} id='OperatorPerfReport' onSort={handleSort}  sortColumn={sortColumn} data={operatorData} />:'' }
      </div>
    </React.Fragment>


  );
}

export default OperatorPerformance;