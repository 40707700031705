import React, { Component } from "react";
import Table from "../../../Common/table";
import api from '../../../config';
import axios from '../../../httpServices/httpService';
import  swal  from 'sweetalert';
import _ from 'lodash';
import { Button, Modal } from 'react-bootstrap';
import dateTime from '../../../utill/dateTime';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPaperPlane,faTrash } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../utill/LoadingComponent";
import Operator from './Operator';
import joi from 'joi';
import validation from '../../../utill/validation';
class OperatorView extends Component {
  state = {
    operatorList:[],
    action:'',
    inputData:{From:new Date(Date.now()),Till:new Date(Date.now())},
    operatorData:{},
    oldObj:{},
    newObj:{},
    update:false,
    querySuccess:false,
    sortColumn:{path:'EquipmentGroup',order:'asc'},
    addOperatorModal:false,
    updateObj:{},
    actionStatus:'',
    errors:{}

  };

  handleSort = sortColumn => {
    // consol(sortColumn);
    if(this.state.operatorList.length>0)
    {
        const sorted = _.orderBy(this.state.operatorList, [sortColumn.path], [sortColumn.order]);
        this.setState({ sortColumn,operatorList:sorted });
    }
  
}
schema = {
  From: joi.date().required().label('From Datetime'),
  Till: joi.date().required().label('Till Datetime'),
 
}

componentDidMount(){
  this.handleOnSubmit(this.state.inputData)
}

  

  handleOnChange=(e)=>{
    const {inputData}={...this.state};
    inputData[e.currentTarget.name]=new Date(e.currentTarget.value);
    this.setState({inputData});
    
   }
   handleOnSubmit=(inputData)=>{
    const errors = validation.validate(inputData, this.schema);
    // consol(errors)
    this.setState({errors:errors||{} });
    if (errors) return;
    this.setState({querySuccess:true})
      axios.put(api.api+'operatorselected',inputData)
      .then((result)=>{
        // consol(result.data);
       this.setState({operatorList:result.data,querySuccess:false});
      })
      .catch((err)=>{
        this.setState({querySuccess:false})
   
        swal(`${err.response.data.message}`)
      })
   }
   handleOnChangeUpdate=(e)=>{
    const{oldObj}={...this.state};
    oldObj[e.currentTarget.name]=e.currentTarget.value;
    this.setState({newObj:oldObj});
    // consol(oldObj)
    
    }
    handleOnUpdate=(obj)=>{
      this.setState({update:true,oldObj:obj})
  
    }
    handleOnRowSubmit=(obj)=>{
      this.setState({querySuccess:true})
  
        axios.patch(api.api+'operatorselected/'+this.state.oldObj._id,{old:this.state.oldObj,new:this.state.newObj},{headers:{rights:this.props.dataRightsKey}})
    .then((result)=>{
      this.setState({operatorList:result.data,update:false,querySuccess:false});
    })
    .catch((err)=>{
      swal(`${err.response.data.message}`);
      this.setState({update:false,querySuccess:false})
    })
    }
  
  




  columns = [
  //   { path: 'EquipmentGroup', label: 'EquipmentGroup' ,content:(obj)=><React.Fragment>
  //  {this.state.update? <div className='form-group ' >
  //     <input type='text'  name='EquipmentGroup' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.EquipmentGroup} className='form-control form-control-sm'/>
  //   </div>:<label>{obj.EquipmentGroup}</label>}
  // </React.Fragment>  },
  //   { path: 'EquipmentType', label: 'EquipmentType',content:(obj)=><React.Fragment>
  //  {this.state.update? <div className='form-group ' >
  //     <input type='text'  name='EquipmentType' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.EquipmentType} className='form-control form-control-sm'/>
  //   </div>:<label>{obj.EquipmentType}</label>}
  // </React.Fragment>   },
    { path: 'Equipment', label: 'Equipment' ,content:(obj)=><React.Fragment>
    {this.state.update?<div className='form-group ' >
      <input type='text'  name='Equipment' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.Equipment} className='form-control form-control-sm'/>
    </div>:<label>{obj.Equipment}</label>}
  </React.Fragment>  },
    { path: 'Operator', label: 'Operator',content:(obj)=><React.Fragment>
   {this.state.update? <div className='form-group ' >
      <input type='text'  name='Operator' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.Operator} className='form-control form-control-sm'/>
    </div>:<label>{obj.Operator}</label>}
  </React.Fragment>  },
   
    { path: 'From', label: 'From' ,content:(obj)=><React.Fragment>
   {this.state.update? <div className='form-group ' >
      <input type='text'  name='From' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.From} className='form-control form-control-sm'/>
    </div>:<label>{dateTime.getStringOfDateTime(obj.From)}</label>}
  </React.Fragment>  },
    { path: 'Till', label: 'Till',content:(obj)=><React.Fragment>
   {this.state.update? <div className='form-group ' >
      <input type='text'  name='Till' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.Till} className='form-control form-control-sm'/>
    </div>:<label>{obj.Till?dateTime.getStringOfDateTime(obj.Till):'-'}</label>}
  </React.Fragment>  },
    { key: 'Update',content: (obj) => <React.Fragment>
     {!this.state.update?
      <button className='btn btn-sm btn-primary '  onClick={()=>this.setState({updateObj:obj,addOperatorModal:true,actionStatus:'update'})} ><FontAwesomeIcon icon={faEdit} color={'yellow'}/></button>:
      <button className='btn btn-sm btn-primary '  onClick={()=>{this.handleOnRowSubmit(obj)}} ><FontAwesomeIcon icon={faPaperPlane} color={'yellow'}/></button>
    }
    
    </React.Fragment> },
      {
        key: 'Delete', content: (obj) => <React.Fragment><button disabled={!this.props.getRightsForAccess('DynamicDataRights', 'OperatorSelection', 'update')} className='btn btn-sm btn-primary ' onClick={() => { this.onDelete(obj)}} ><FontAwesomeIcon icon={faTrash} color='red'   /></button></React.Fragment>
         
      },
//     { path: 'Reason', label: 'Reason' ,content: () => <React.Fragment>


// <div className='form-group'>
//   <select className='form-control form-control-sm custom-select' name='Reason' onChange={this.handleOnChange}>
//   <option>--select reason--</option>
//   {this.state.downTimeList.map(one=>{
//    return  one.Reason.map(reason=>{
//       return  <option>{reason}</option>
//     })
//   })}
//   </select>
// </div>
//     </React.Fragment> },
  
  
    
]

onDelete=(data)=>{
       
  swal({
    title: "Are you sure?",
    text: "Once deleted, Not Recover Operator Selected On This Equipment ",
    icon: "warning",
    buttons: true,
    dangerMode: true
  }).then(willDelete => {
    if (willDelete) {
      this.setState({querySuccess:true});
      axios
        .delete(api.api + "operatorselected/"+data._id, { data: data})
        .then(result => {
          // consol(result.data)
          if (result.data.ok === 1 && result.data.deletedCount === 1) {
              let filterData = this.state.operatorList.filter(
                  obj => obj._id !== data._id
                );
                this.setState({ operatorList: filterData,querySuccess:false });
          }
        })
        .catch(err => {
          this.setState({querySuccess:false});
          swal(`${err.response.data.message}`);
        });
    } else {
      this.setState({querySuccess:false});
      // swal("Your imaginary file is safe!");
    }
  });
}

handleAddJobClick = () => {
  this.setState({ addOperatorModal: true,actionStatus:'add' });
}

handleCloseAddOperator=()=>{
  this.setState({ addOperatorModal: false,actionStatus:'' });
}
 
  render() {
    const{errors}=this.state;
    const{getRightsForAccess}=this.props;
    return (
      <React.Fragment>
      <div className='text-center'>
      <Modal
          show={this.state.addOperatorModal}
          onHide={this.handleCloseAddOperator}
          backdrop="static"
          keyboard={false}
          size='lg'
         
        >
          <Modal.Header closeButton>
            <Modal.Title>New Operator Selection Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Operator dataEntryOperatorData={this.state.updateObj} handleOnSubmit={this.handleOnSubmit} inputData={this.state.inputData} actionStatus={this.state.actionStatus} handleCloseAddOperator={this.handleCloseAddOperator}/>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseAddOperator}>
              Close
          </Button>
          
          </Modal.Footer>
        </Modal>
      <p>Operator View</p>
      </div>
      {this.state.querySuccess?<Loading/>:''}
      <div className="row">
      <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
           <input className='form-control form-control-sm' type='date' defaultValue={dateTime.getDateFormatForInputTypeHtml(new Date(Date.now()))}  onChange={this.handleOnChange} name='From'/>
           {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
        </div>
      <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' defaultValue={dateTime.getDateFormatForInputTypeHtml(new Date(Date.now()))}  onChange={this.handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
        </div>
        <div className="col-sm-1">
         <button className='btn btn-primary btn-sm mt-4' disabled={!getRightsForAccess('DynamicDataRights','OperatorSelection','read')} onClick={()=>this.handleOnSubmit(this.state.inputData)}>OK</button>
        </div>

        <div className="col-sm-6">
         <button className='btn btn-primary btn-sm mt-4 float-right' disabled={!getRightsForAccess('DynamicDataRights','OperatorSelection','write')} onClick={this.handleAddJobClick} >Add Operator</button>
        </div>
      
       
        
      </div>
      <div>
      {this.state.operatorList.length < 0 ? "Data Not Available" : <Table columns={this.columns} sortColumn={this.state.sortColumn} onSort={this.handleSort} data={this.state.operatorList} />}
      </div>
      </React.Fragment>
    );
  }
}

export default OperatorView;
