import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const CycleRunAndRuntime = ({ handleDownloadTableToExcel, equips, equipGroups, fromDateLimit }) => {
    const [productionData, setProductionData] = useState([])
    const [inputData, setInputData] = useState({})
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [equip, SetEquips] = useState([])
    const [errors, setErrors] = useState({});
    const [index, setIndex] = useState(0);
    const [querySuccess, setQuerySuccess] = useState(false);
    const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })

    const schema = {
        From: joi.date().required().label('From Date'),
        UserId: joi.string(),
        Till: joi.date().required().label('Till Date'),
        Equips: joi.array()
    }








    const ProductionReportSchema = [
        { path: 'Date', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
        { path: 'Shift', label: 'Shift' },
        { path: 'EquipmentGroup', label: 'EquipmentGroup' },
        { path: 'Equipment', label: 'Equipment' },
        { path: 'Cycles', label: 'Cycles', content: (obj) => <label>{obj.Cycles ? obj.Cycles : '-'}</label> },
        { path: 'AvailableTime', label: 'Runtime', content: (obj) => <label>{obj.AvailableTime ? obj.AvailableTime : '-'}</label> },

    ]
    const handleOnChange = (e) => {
        if (e.currentTarget.type === 'date') {
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
        }
        else {
            inputData[e.currentTarget.name] = e.currentTarget.value;
        }
        setInputData(inputData);
        setErrors({})
    }
    const getEquipsForReport = () => {
        let equipArr = []
        equips.forEach((one) => {
            selectedEquipOption.forEach((option) => {
                if (one._id == option.value) {
                    equipArr.push(one);
                }
            })
        })
        return equipArr;
    }
    const handleOnSubmit = async (inputData) => {
        productionData.splice(0, productionData.length);
        setIndex(0)
        // consol(inputData)
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setQuerySuccess(true)
        const user = auth.getCurrentUser();
        inputData.UserId = user._id

        if (new Date(inputData.From) <= new Date(inputData.Till)) {
        } else {
            setQuerySuccess(false);

            return toast.success('From date should be Less Than Till date', {
                position: 'bottom-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                style: {
                    background: '#babbbb', // Set your desired background color
                    color: 'white',     // Set your desired text color
                },
            });
        }
        let equipForReport = getEquipsForReport()
      //  equipForReport = equipForReport.filter((one) => one.EquipmentID == "hp-01")
        for (let i = 0; i < equipForReport.length; i++) {
            inputData.Equips = [equipForReport[i]]
            //let data=[...productionData];
            try {
                let result = await axios.post(api.api + 'CycleRunAndRuntime', inputData)

                if (result.data !== null && result.data !== undefined) {
                    if (result.data.length > 0) {
                        let idx = i + 1
                        result.data.forEach((one) => {
                            productionData.push(one);
                        })
                        setProductionData(productionData)
                        setIndex(idx)
                    }
                    // else {
                    //   setProductionData([])
                    //   swal('Data Not Available');
                    //   setQuerySuccess(false)
                    // }
                }
                else {
                    setQuerySuccess(false)
                }

            }
            catch (err) {
                toast.success(`${err}`, {
                    position: 'bottom-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    style: {
                        background: 'red', // Set your desired background color
                        color: 'white',     // Set your desired text color
                    },
                });
                // consol(err)
                setQuerySuccess(false)
            }
        }
        setQuerySuccess(false)


    }


    const handleSort = (sortColumn) => {
        if (productionData.length > 0) {
            const sorted = _.orderBy(
                productionData,
                [sortColumn.path],
                [sortColumn.order]
            );
            setProductionData(sorted)
            setSortColumn(sortColumn)
        }
    }

    const setSelection = (key, option, equips) => {
        if (key == 'EquipmentGroup') {
            SetEquips(equips ? equips : []);
            setSelectedEquipGroupOption(option)
        }
        if (key == 'Equipment') {

            setSelectedEquipOption(option)
        }
    }
    const SelectedDates = (dates) => {
        setInputData(dates)
    }
    return (
        <React.Fragment>
            {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`} /> : ''}
            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Cycles/Runtime
                </h4>
            </div>
            <ToastContainer />

            <div className="row">

                {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}
                {/* <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>From</label>
                        <input className={'form-control form-control-sm'} min={fromDateLimit} placeholder='dd/mm/yyyy' type='date' onChange={handleOnChange} name='From' />
                        {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Till</label>
                        <input className='form-control form-control-sm' type='date' placeholder='dd/mm/yyyy' onChange={handleOnChange} name='Till' />
                        {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                    </div>
                </div> */}

                <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />


                {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              <option>All</option>
              <option>Shift1</option>
              <option>Shift2</option>
              <option>Shift3</option>
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div> */}
                {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>
                <div className={productionData.length > 0 ? 'col-sm-1' : 'col-sm-1 d-none'}>
                    <a className='btn btn-sm btn-primary mt-4' onClick={() => handleDownloadTableToExcel('CycleRun')} style={{ float: 'right', color: 'white' }}>Download</a>
                </div>
            </div>
            <div>

                {productionData.length > 0 ? <Table columns={ProductionReportSchema} onSort={handleSort} id='CycleRun' sortColumn={sortColumn} data={productionData} /> : <p></p>}
            </div>
        </React.Fragment>
    );
}
export default CycleRunAndRuntime;