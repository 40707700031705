import React, { Component } from 'react';
import Table from '../../../Common/table';
//import '../QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faEye, faPlus, faFileImport, faDownload } from '@fortawesome/free-solid-svg-icons';
import dateTime from '../../../utill/dateTime';
import swal from 'sweetalert';
import api from '../../../config';
import axios from '../../../httpServices/httpService';
import _ from 'lodash'
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx';
import valid from '../../../utill/validation';
import joi from 'joi';
import { Modal } from 'react-bootstrap';
import boolean from 'joi/lib/types/boolean';


class ToolMasterTable extends Component {

  state = {
    toolMasterList: [],
    sortColumn: { path: 'ToolId', order: 'asc' },
    querySuccess: false,

    excelData: [],
    arrayProcessIndex: 0,
    errorModal: false,
    errorMessage: '',
    errorFlag: false,
    rowNumber: 0,
    errors: {},
    objKey: '',
  }

  handleSort = sortColumn => {
    // consol(sortColumn);
    if (this.state.toolMasterList.length > 0) {
      const sorted = _.orderBy(this.state.toolMasterList, [sortColumn.path], [sortColumn.order]);
      this.setState({ sortColumn, toolMasterList: sorted });
    }
  }

  componentDidMount() {
    this.setState({ querySuccess: true });
    axios.get(api.api + 'tool')
      .then((result) => {
        // // consol(result.data)
        if (result.data.length > 0) {
          this.setState({ toolMasterList: result.data, querySuccess: false });
        }
        else {
          this.setState({ querySuccess: false })
        }
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });

      })


  }

  onDelete = (data) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        axios
          .delete(api.api + "tool/" + data._id, { data: data })
          .then(result => {
            // // consol(result.data)
            if (result.data.ok === 1 && result.data.deletedCount === 1) {
              let filterData = this.state.toolMasterList.filter(
                obj => obj._id !== data._id
              );
              this.setState({ toolMasterList: filterData, querySuccess: false });
            }
          })
          .catch(err => {
            this.setState({ querySuccess: false });
            swal(`${err.response.data.message}`);
          });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });




  }


  columns = [
    { path: 'ToolId', label: 'Tool ID' },
    { path: 'EquipmentType', label: 'Equipment Type' },
    //{path:'ScrapWeightCycle',label:'ScrapWeight / Cycle'},
    { path: 'IdealCycleTime', label: 'Ideal Time / Cycle' },
    { path: 'PartsPerCycle', label: 'Parts / Cycle' },
    { path: 'PartOfFaimly', label: 'Part of Family', content: (obj) => <React.Fragment><label>{obj.PartOfFaimly ? 'Yes' : 'No'}</label><br /></React.Fragment> },
    // {path:'CyclePerParts',label:'Cycle / Part'},
    // {path:'PartsPreventiveMaintenance',label:'Parts / Preventive Maintenance'}, 
    // {path:'MaxDaysPreventiveMaintenance',label:'MaxDays / Preventive Maintenance'},
    // {path:'PartsSinceLastPreventiveMaintenance',label:'Parts Since Last Preventive Maintenance'},



    // {key:'LastPreventiveMaintenanceDate',label:'Last Preventive Maintenance Date',content:(obj)=><label>{new Date(obj.LastPreventiveMaintenanceDate).getDate()+'-'+(new Date(obj.LastPreventiveMaintenanceDate).getMonth()+1)+'-'+new Date(obj.LastPreventiveMaintenanceDate).getFullYear()}</label> },



    { key: 'createdAt', label: 'Created Date', content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.createdAt)}</label><br /></React.Fragment> },
    { key: 'updatedAt', label: 'Last Updated Date', content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.updatedAt)}</label><br /></React.Fragment> },
    { key: 'update', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights', 'ToolMaster', 'update')} onClick={() => this.props.onUpdate(user)} className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faEdit} /></button> },
    // { key: 'view', content: user => <button onClick={() => this.props.onView(user)} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faEye} /></button> },
    { key: 'delete', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights', 'ToolMaster', 'write')} onClick={() => this.onDelete(user)} className="btn btn-sm btn-danger"><FontAwesomeIcon icon={faTrash} /></button> }

  ]



  handleUploadGuagesDoc = () => {
    let link = document.createElement('input');
    link.setAttribute('type', 'file');
    link.setAttribute('name', 'ToolList');
    // link.setAttribute('onChange', handleOnChangeFile);
    let self = this;
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      e.preventDefault();

      let fr = new FileReader();

      // if (e.currentTarget.files[0].size < 51200 && e.currentTarget.files[0].size > 2000) {
      fr.readAsBinaryString(e.currentTarget.files[0]);
      fr.onloadend = async function (e) {
        const wb = XLSX.read(e.target.result, { type: 'binary', cellDates: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let dataArray = []
        let keyArr = data[0]
        let arr = data.slice(1, data.length)
        arr.forEach((oneArr) => {
          let obj = {}
          oneArr.forEach((value, i) => {
            if (keyArr[i] == 'DeviceID') {
              obj[[keyArr[i]]] = value;
            } if (keyArr[i] == 'PartOfFamily') {
              if (value && value.toLowerCase() == "yes") {
                obj["PartOfFaimly"] = true;
              } else {
                obj["PartOfFaimly"] = false;
              }
            }
            else {
              obj[[keyArr[i]]] = value.toString();
            }
          })
          dataArray.push(obj);
        })
        self.setState({ excelData: dataArray });
        await self.sendDataToServer(dataArray, dataArray, self.state.arrayProcessIndex)
        // // consol("Data>>>" + dataArray);
      };
      // }
      // else {
      //   swal('File Size Should Be Between 2KB to 50KB');
      // }
    }
    document.body.removeChild(link);

  }

  schema = {
    ToolId: joi.string().required().label('Tool Id'),
    EquipmentType: joi.string().required().label('Equipment Type'),
    //ScrapWeightCycle:joi.number().required().min(0).label('Scrap Weigth Cycle'),
    IdealCycleTime: joi.number().required().min(0),
    PartsPerCycle: joi.number().required().min(0),
    PartOfFaimly: joi.boolean().default(false)
    // CyclePerParts:joi.number().min(0),
    // PartsPreventiveMaintenance:joi.number().min(0).required().label('PartsPreventiveMaintenance'),
    // PartsSinceLastPreventiveMaintenance:joi.number().min(0).required().label('PartsSinceLastPreventiveMaintenance'),
    // LastPreventiveMaintenanceDate:joi.date().required().label('LastPreventiveMaintenanceDate'),
    // MaxDaysPreventiveMaintenance:joi.number().min(0).required().label('MaxDaysPreventiveMaintenance'),

  }


  sendDataToServer = async (EquipsArr, originalDataArray, idx) => {
    this.setState({ querySuccess: true })

    for (let index = idx; index < EquipsArr.length; index += 1) {
      try {
        let error = valid.validate(EquipsArr[index], this.schema);
        if (error !== null) {
          let row = index + 1
          this.setState({ errors: error, querySuccess: false, errorModal: true, rowNumber: row });
          break;

        } else {
          let res = await axios.post(api.api + 'UploadExcelToolData', { row: index + 1, data: EquipsArr[index] })
          await (async () => {
            const result = await axios.get(
              api.api + 'tool'
            );
            // setQuerySuccess(false)
            this.setState({ toolMasterList: result.data });
          })();
          if (res.data == 'Insert') {
            toast.success('🦄 Save Tool  - ' + EquipsArr[index].ToolId, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

        }
      }
      catch (err) {

        if (err.response.data.code == 11000) {
          this.AbortAndResumeProcess(EquipsArr, originalDataArray, index)
        } else {
          let message = JSON.parse(err.response.data.message);
          this.setState({ querySuccess: true, objKey: message.key, errorMessage: message.message, errorFlag: true })
          this.insertIfNotExistDataTypeAndResumeProcess(message.message, message.key.toLowerCase(), message.key, EquipsArr[index][message.key], originalDataArray, index);
        }
        break;
      }

    }
    this.setState({ querySuccess: false })
  }

  AbortAndResumeProcess = (dataArray, originalArr, index) => {
    let row = index + 1;
    swal({
      title: "Are you sure?",
      text: ' This tool id already exist skip this tool!  ' + dataArray[index].ToolId + "  at row number " + row,
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        index = index + 1;
        this.sendDataToServer(dataArray, originalArr, index)
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index })
        swal('Process  Abort', { dangerMode: true });

        // swal("Your imaginary file is safe!");
      }
    });
  }



  insertIfNotExistDataTypeAndResumeProcess = (errorMessage, url, key, value, dataArray, index) => {
    swal({
      title: "Are you sure?",
      text: `${errorMessage}` + '  , Do you want to insert it ',
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.setState({ querySuccess: true })
        axios
          .post(api.api + url, { [key]: value })
          .then(async (result) => {
            if (result.status == 200) {
              this.setState({ errorFlag: false })
              this.sendDataToServer(dataArray, dataArray, index)
              // setQuerySuccess(false);
            }
          })
          .catch(err => {
            this.setState({ querySuccess: false })
            toast.success(`${err}`, {
              position: 'bottom-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: 'red', // Set your desired background color
                color: 'white',     // Set your desired text color
              },
            });
          });
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index })
        swal('Process  Abort')

        // swal("Your imaginary file is safe!");
      }
    });
  }


  handleDownloadFormat = () => {
    axios.get(api.api + 'ToolMasterTemplate')
      .then((result) => {
        if (result.status == 200) {
          // const url = window.URL.createObjectURL(new Blob([result.data]));
          let link = document.createElement('a');
          link.setAttribute('href', result.data);
          link.setAttribute('download', 'ToolMasterFormat.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        else {
          swal('Error In Downloading File')
        }
      })
      .catch((err) => {
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
  }

  handleHideModal = () => {
    this.setState({ errorModal: false })
  }


  render() {


    return (
      <React.Fragment>
        <div className='mt-1'>
          <div className='text-center'>
            <p>Tool Master</p>
          </div>
          {this.state.querySuccess ? <Loading /> : ''}
          <ToastContainer />
          <Modal
            show={this.state.errorModal}
            onHide={this.handleHideModal}
            backdrop="static"
            keyboard={false}
            size='sm'
          >
            <Modal.Header closeButton>
              <Modal.Title>Equipment Validate Error List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(this.state.errors).map(key => {
                return <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors[key] + "  " + "Row Number  " + this.state.rowNumber}</p>
              })}
            </Modal.Body>
            {/* <Modal.Footer>
                <Button className='bg-primary' variant="secondary" >
                    Save
        </Button>
                <Button variant="secondary" onClick={handleHideModal}>
                    Close
        </Button>

            </Modal.Footer> */}
          </Modal>

          {this.props.getRightsForAccess('StaticDataRights', 'ToolMaster', 'write') ? <a style={{ cursor: 'pointer' }} className='btn-sm btn btn-primary ml-2 common-anchor'

            onClick={() => {
              this.props.history.push('/setting/toolmaster/add');
              this.props.handleOnAdd('/setting/toolmaster/add', 'add', 'toolMasterList', this.state.toolMasterList)
            }}>
            <FontAwesomeIcon size='1x' icon={faPlus} /> Add</a> : ''}

          {this.props.getRightsForAccess('StaticDataRights', 'ToolMaster', 'write') ? <a
            style={{ cursor: "pointer", color: 'white' }}
            className="float-right btn btn-sm btn-primary "
            onClick={this.handleUploadGuagesDoc}
          >
            {" "}

            <FontAwesomeIcon size="1x" icon={faFileImport} /> Import Excel
          </a> : ''}

          {/* {this.props.getRightsForAccess('StaticDataRights', 'ToolMaster', 'write') ? <a
            style={{ cursor: "pointer", color: 'white' }}
            className="float-left btn btn-sm btn-primary "
            onClick={this.handleDownloadFormat}
          >
            {" "}

            <FontAwesomeIcon size="1x" icon={faDownload} /> Download Template
          </a> : ''} */}


          {this.state.toolMasterList.length < 0 ? "Data Not Available" : <Table columns={this.columns} onSort={this.handleSort} sortColumn={this.state.sortColumn} data={this.state.toolMasterList} />}
        </div>
      </React.Fragment>

    );
  }
}

export default ToolMasterTable;
