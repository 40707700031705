import React, { Component } from 'react';
import Chart from "chart.js";
import 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min.js';
import PieChart from 'react-google-charts';
import { generateExcelFile } from '../utill/download';
import api from '../config.json';
import swal from 'sweetalert';
import axios from '../httpServices/httpService';
import Loading from '../utill/LoadingComponent';
import validate from '../utill/validation';
import joi from 'joi'
import date from '../utill/dateTime';
import ScatterChart from '../utill/scatterChart';
import EquipmentSelection from '../Common/equipmentSelection';
import MultiSelectCheckBox from '../Common/MultiSelectCheckBox';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';


class DowntimeAnalysisCumulative extends Component {
  state = {
    shiftOption:[
   { label:'Shift1',value:'Shift1',checked:false},
   { label:'Shift2',value:'Shift2',checked:false},
   { label:'Shift3',value:'Shift3',checked:false},
   { label:'Shift4',value:'Shift4',checked:false}],
   downtime:[
    { label:'Planned',value:'Planned',checked:false},
    { label:'Unplanned',value:'Unplanned',checked:false},],
    myNewChart: '',
    myNewChart1: '',

    viewBarChartOnClick: false,
    filterBy: '',
    barColorStateWise: 'red',
    downTimePieChartData: [],
    downtimeGroupTableData: [],
    downtimeReasons: [],
    selectedEquipGroupOption: [],
    selectedEquipOption: [],
    equip: [],
    shiftSetting: [],
    pieChartData: [],
    pieChartCostData:[],
    Bardata: [
      ["Machine", "Time(In Mins)", { role: "style" }],

    ],

    inputData: {},
    errors: {},
    downtimeDataCumulative: [],
    querySuccess: false,
    viewBarChartOnClick: false,
  }
  Bardata = [
    ["Machine", "Time(In Mins)", { role: "style" }],

  ];

  schema = {
    From: joi.date().required().label('From Datetime'),
    UserId: joi.string(),
    Till: joi.date().required().label('Till Datetime'),
    Shift: joi.array().min(1).required().label("select at least one shift"),
    Equips: joi.array(),
    Downtime:joi.array().min(1).required()
  }


  // getListOfMasterDowntimeReason=()=>{
  //    if(this.props.reasonType.ReasonType=='UnPlan'){
  //     let arr=[]
  //     for(let i=0;i<this.props.downTimeReasons.length;i++){
  //         for(let j=0;j<this.props.PlannedReason.length;j++){
  //             if(this.props.downTimeReasons[i].Reason!==this.props.PlannedReason[j]){
  //                 arr.push(this.props.downTimeReasons[i])
  //             }
  //         }
  //     }
  //     return arr
  //    }
  //    else if(this.props.reasonType.ReasonType=='Plan'){
  //     return this.props.PlannedReason
  //    }else
  //    {
  //     return this.props.downTimeReasons
  //    }
  // }


  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.chartRef1 = React.createRef();


  }


  organisePieChartData = (clone) => {
    let data = [...clone]
    let arr = { datasets: [{ data: [], backgroundColor: [], Event: [] }], labels: [], }
    // if (this.props.reasonSearchText.length > 0) {
    //   data = data.filter(reason => { return reason.State.replace(/_/gi, '').toLowerCase().startsWith(this.props.reasonSearchText.toLowerCase()) })
    //   for (let i = 0; i < data.length; i++) {
    //     arr.datasets[0].data.push(data[i].TotalDuration);
    //     arr.datasets[0].backgroundColor.push(data[i].Color)
    //     arr.datasets[0].Event.push(data[i].Downtime)
    //     arr.labels.push(data[i].State)

    //   }
    // }
    // else {

    for (let i = 0; i < data.length; i++) {
      arr.datasets[0].data.push(Math.ceil(data[i].Duration / 60));
      arr.datasets[0].backgroundColor.push(data[i].Color)
      arr.datasets[0].Event.push(data[i].Event)
      arr.labels.push(data[i].State)

    }
    //  }


    return arr;
  }
  organisePieChartCostData = (clone) => {
    let data = [...clone]
    let arr = { datasets: [{ data: [], backgroundColor: [], Event: [] }], labels: [], }
    // if (this.props.reasonSearchText.length > 0) {
    //   data = data.filter(reason => { return reason.State.replace(/_/gi, '').toLowerCase().startsWith(this.props.reasonSearchText.toLowerCase()) })
    //   for (let i = 0; i < data.length; i++) {
    //     arr.datasets[0].data.push(data[i].TotalDuration);
    //     arr.datasets[0].backgroundColor.push(data[i].Color)
    //     arr.datasets[0].Event.push(data[i].Downtime)
    //     arr.labels.push(data[i].State)

    //   }
    // }
    // else {

    for (let i = 0; i < data.length; i++) {
      arr.datasets[0].data.push(data[i].Cost > 0 ? Number((data[i].Cost).toFixed(1)):0);
      arr.datasets[0].backgroundColor.push(data[i].Color)
      arr.datasets[0].Event.push(data[i].Event)
      arr.labels.push(data[i].State)

    }
    //  }


    return arr;
  }
  async componentDidMount() {

    //let res = await axios.post(api.api + '', {})
    const myChartRef = this.chartRef.current.getContext("2d");
    document.addEventListener('mousedown', this.handleClickOutside);
    this.state.myNewChart = new Chart(myChartRef, {
      type: "pie",
      data: [],//this.organisePieChartData(this.props.downTimePieChartData),
      options: {
        cutoutPercentage: 50,
        responsive: false,
        legend: { position: 'right', align: 'center', display: true },
        title: {
          display: true,
          text: 'Duration in (mins) by Reasons',
          fontSize: 16 // optional font size
      },
        layout: {
            padding: {
                left: 20,
                right: 20,
                top: 20,
                bottom: 20
            }
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {

              let sum = 0;
              let dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map(data => {
                sum += data;
              });
              let percentage = ((value * 100) / sum).toFixed(1) + "%";
              return percentage;


            },

            color: 'black',

          }
        }
      },

    });

    const myChartRef1 = this.chartRef1.current.getContext("2d");
    document.addEventListener('mousedown', this.handleClickOutside);
    this.state.myNewChart1 = new Chart(myChartRef1, {
      type: "pie",
      data: [],//this.organisePieChartData(this.props.downTimePieChartData),
      options: {
        cutoutPercentage: 50,
        responsive: false,
        legend: { position: 'right', align: 'center', display: true },
        title: {
          display: true,
          text: 'Cost in (₹) by Reasons',
          fontSize: 16 // optional font size
        },
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20
          }
        },
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              return value;
          },
          

            color: 'black',

          }
        }
      },

    });
    // console.log('componentdid run successfully');
    // this.handleOnChange({currentTarget:{name:'Shift',value:'All',checked:true}});
  
    // Set today's date as the default value
let preSelectedDate = {
 From:this.getTodayDate(),
   Till:this.getTodayDate()
}
this.setState({inputData:preSelectedDate});

    // this.chartRef.onclick=(function(evt){
    //     var activePoints = myNewChart.getElementsAtEvent(evt);
    //     if (activePoints[0]) {
    //       var chartData = activePoints[0]['_chart'].config.data;
    //       var idx = activePoints[0]['_index'];

    //       var label = chartData.labels[idx];
    //       var value = chartData.datasets[0].data[idx];

    //       var url = "http://example.com/?label=" + label + "&value=" + value;
    //       // consol(url);
    //       alert(url);
    //     }
    // })



  }  
 
  getTodayDate = () => {
    const today = new Date();
    let month = String(today.getMonth() + 1).padStart(2, '0');
    let day = String(today.getDate()).padStart(2, '0');
    const year = today.getFullYear();

    return `${year}-${month}-${day}`;
  };
  // componentDidMount() {
 
  //  }

  componentDidUpdate() {
    // if(JSON.stringify(this.state.myNewChart.chart.config.data.datasets)===JSON.stringify(this.props.downTimePieChartData))
    // {
    //     // consol("true")
    // }
    // else
    // {
    // this.state.myNewChart.chart.config.data = this.state.downtimeDataCumulative;
    // this.state.myNewChart.chart.update();
    // // consol(this.state.myNewChart.chart.config.data)
    //}
    // this.state.myNewChart.chart.config.data=this.organisePieChartData(this.props.downTimePieChartData);
    // this.state.myNewChart.chart.update();
    // // consol(this.state.myNewChart.chart.config.data)
  }

  // chartRef(node) {
  //     this.mychartRef = node;
  //   }
  // handleClickOutside=(event)=>{
  //     if (this.mychartRef && !this.mychartRef.contains(event.target)) {
  //         alert('You clicked outside of me!');
  //       }
  // }
  handleOnClickCanvas = (evt) => {
    var addRadiusMargin = 10;
    var activePoints = this.state.myNewChart.getElementsAtEvent(evt);
    if (activePoints[0]) {
      // activePoints[0]['_model'].innerRadius = activePoints[0]['_model'].innerRadius +
      //     addRadiusMargin;
      // activePoints[0]['_model'].outerRadius = activePoints[0]['_model'].outerRadius +
      //     addRadiusMargin;
      var chartData = activePoints[0]['_chart'].config.data;
      var idx = activePoints[0]['_index'];

      var label = chartData.labels[idx];

      var value = chartData.datasets[0].data[idx];
      var backgroundColor = chartData.datasets[0].backgroundColor[idx];
      var Event = chartData.datasets[0].Event[idx];
      // consol(Event)

      var url = "http://example.com/?label=" + label + "&value=" + value + '&color=' + backgroundColor;
      // consol(url)
      let schema = ["Machine", "Time(In Mins)", { role: "style" }];
      let barArrayData = [schema];
      for (let i = 0; i < Event.length; i++) {
        let arrBar = [];
        arrBar.push(Event[i].Equipment);

        arrBar.push(Math.ceil(Event[i].Duration / 60));
        arrBar.push(backgroundColor);
        barArrayData.push(arrBar);
      }
      let sortData = barArrayData.sort(function (a, b) { return b[1] - a[1] });
      // consol(sortData);
      this.setState({ viewBarChartOnClick: true, Bardata: sortData });
      ///// consol(url);
      //alert(url);
    }
  }
  handleOnClickCanvas1 = (evt) => {
    var addRadiusMargin = 10;
    var activePoints = this.state.myNewChart1.getElementsAtEvent(evt);
    if (activePoints[0]) {
      // activePoints[0]['_model'].innerRadius = activePoints[0]['_model'].innerRadius +
      //     addRadiusMargin;
      // activePoints[0]['_model'].outerRadius = activePoints[0]['_model'].outerRadius +
      //     addRadiusMargin;
      var chartData = activePoints[0]['_chart'].config.data;
      var idx = activePoints[0]['_index'];

      var label = chartData.labels[idx];

      var value = chartData.datasets[0].data[idx];
      var backgroundColor = chartData.datasets[0].backgroundColor[idx];
      var Event = chartData.datasets[0].Event[idx];
      // consol(Event)

      var url = "http://example.com/?label=" + label + "&value=" + value + '&color=' + backgroundColor;
      // consol(url)
      let schema = ["Machine", "Time(In Mins)", { role: "style" }];
      let barArrayData = [schema];
      for (let i = 0; i < Event.length; i++) {
        let arrBar = [];
        arrBar.push(Event[i].Equipment);

        arrBar.push(Math.ceil(Event[i].Duration / 60));
        arrBar.push(backgroundColor);
        barArrayData.push(arrBar);
      }
      let sortData = barArrayData.sort(function (a, b) { return b[1] - a[1] });
      // consol(sortData);
      this.setState({ viewBarChartOnClick: true, Bardata: sortData });
      ///// consol(url);
      //alert(url);
    }
  }
  handleChangeFilter = (e) => {
    this.setState({ filterBy: e.target.value });
  }
  getPercentage = (clone) => {
    let data = [...clone]
    let arr = [];
    let sum = 0;
    // if (this.props.reasonSearchText.length > 0) {
    //   data = data.filter(reason => { return reason.State.replace(/_/gi, '').toLowerCase().startsWith(this.props.reasonSearchText.toLowerCase()) })
    //   data.map(data1 => {
    //     sum += data1.Duration;
    //   });

    //   data.forEach((one, i) => {
    //     let obj = {}
    //     obj.Reason = one.State
    //     obj.Duration = one.Duration;
    //     obj.Percentage = (one.Duration * 100 / sum).toFixed(2);
    //     arr.push(obj)
    //   })
    //   arr.sort((a, b) => {
    //     return b.Duration - a.Duration;
    //     // if(a.Duration>b.Duration) return -1
    //     //if(a.Duration<b.Duration) return -1
    //   })
    // }
    // else {
    data.map(data1 => {
      sum += data1.Duration;
    });
    sum = sum / 60;
    data.forEach((one, i) => {
      let obj = {}
      obj.Reason = one.State;
      obj.Duration = Math.ceil(one.Duration / 60);
      obj.Cost =(one.Cost);
      obj.Percentage = ((obj.Duration * 100) / sum).toFixed(1);
      obj.NumOfMachines = one.Event.length;
      obj.Color = one.Color;
      obj.DowntimeGroup = one.Event[0].DowntimeGroup ? one.Event[0].DowntimeGroup : 'Default';
      arr.push(obj)
    })
    arr.sort(function (a, b) {
      if (a.Reason < b.Reason) {
        return -1;
      }
      else if (a.Reason > b.Reason) {
        return 1;
      }
      return 0;
    });
    // }
    return arr
  }

  getPercentage1 = (clone) => {
    let data = [...clone]
    let arr = [];
    let sum = 0;
    // if (this.props.reasonSearchText.length > 0) {
    //   data = data.filter(reason => { return reason.State.replace(/_/gi, '').toLowerCase().startsWith(this.props.reasonSearchText.toLowerCase()) })
    //   data.map(data1 => {
    //     sum += data1.Duration;
    //   });

    //   data.forEach((one, i) => {
    //     let obj = {}
    //     obj.Reason = one.State
    //     obj.Duration = one.Duration;
    //     obj.Percentage = (one.Duration * 100 / sum).toFixed(2);
    //     arr.push(obj)
    //   })
    //   arr.sort((a, b) => {
    //     return b.Duration - a.Duration;
    //     // if(a.Duration>b.Duration) return -1
    //     //if(a.Duration<b.Duration) return -1
    //   })
    // }
    // else {
    data.map(data1 => {
      sum += Number(data1.Cost);
    });
    sum = sum ;
    data.forEach((one, i) => {
      let obj = {}
      obj.Reason = one.State;
      obj.Cost = ((one.Cost));
      obj.Percentage = ((obj.Cost * 100) / sum);
      obj.NumOfMachines = one.Event.length;
      obj.Color = one.Color;
      obj.DowntimeGroup = one.Event[0].DowntimeGroup ? one.Event[0].DowntimeGroup : 'Default';
      arr.push(obj)
    })
    arr.sort(function (a, b) {
      if (a.Reason < b.Reason) {
        return -1;
      }
      else if (a.Reason > b.Reason) {
        return 1;
      }
      return 0;
    });
    // }
    return arr
  }

  handleDownloadExcel = (e) => {
    generateExcelFile(e)
  }
   convertDateFormat = (dateString) => {
    const [month, day, year] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };
  handleOnChange = async (e) => {
    const { inputData, errors } = { ...this.state }
    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] = this.convertDateFormat(e.currentTarget.value);
    }
    else {
      if(e.currentTarget.name=='Shift'){
        const options=[...this.state.shiftOption];
        if(e.currentTarget.value&&(e.currentTarget.value=='All')){
          options.forEach(one=>one.checked=e.currentTarget.checked);
        }else{
          options.forEach(one=>{
            if(one.value==e.currentTarget.value){
              one.checked=e.currentTarget.checked
            }
          });
        }
        inputData[e.currentTarget.name] = options.filter(one=>one.checked === true);
        this.setState({shiftOption:options});
      }
    else if(e.currentTarget.name=='Downtime'){
        const options=[...this.state.downtime];
        if(e.currentTarget.value&&(e.currentTarget.value=='All')){
          options.forEach(one=>one.checked=e.currentTarget.checked);
        }else{
          options.forEach(one=>{
            if(one.value==e.currentTarget.value){
              one.checked=e.currentTarget.checked
            }
          });
        }
        inputData[e.currentTarget.name] = options.filter(one=>one.checked === true);
        this.setState({downtime:options});
      }else{
        inputData[e.currentTarget.name] = e.currentTarget.value;
      }
     
    }
    let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime })
    this.setState({ inputData: inputData, errors: errors || {}, shiftSetting: res.data })
  }

  GetPieChartData = (data) => {
   

    data = [...this.getPercentage(data)];
    for (let i = 0; i < data.length; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i].DowntimeGroup == data[j].DowntimeGroup) {
          data[i].Duration = data[i].Duration + data[j].Duration;
          data.splice(j, 1);
          j--;
        }
      }
    }
    let arr = [['Downtime Group', 'Duration']]
    data.forEach((one) => {
      let arr1 = []
      arr1.push(one.DowntimeGroup);
      arr1.push(one.Duration)
      arr.push(arr1);
    })
    return arr;
  }

  GetPieChartCostData = (data) => {
    data = [...this.getPercentage1(data)];
    for (let i = 0; i < data.length; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i].DowntimeGroup == data[j].DowntimeGroup) {
          data[i].Cost = Number(data[i].Cost) + Number(data[j].Cost);
          data.splice(j, 1);
          j--;
        }
      }
    }
    let arr = [['Downtime Group', 'Cost']]
    data.forEach((one) => {
      let arr1 = []
      arr1.push(one.DowntimeGroup);
      arr1.push(one.Cost >0 ? parseInt((one?.Cost).toFixed(1)):0)
      arr.push(arr1);
    })
    return arr;
  }

  handleOnSubmit = (inputData) => {
    inputData={...inputData}

    const errors = validate.validate(inputData, this.schema);

    this.setState({ errors: errors || {} })
    if (errors) return;
    this.setState({ querySuccess: true })

    inputData.UserId = this.props.user._id
    
    if(new Date(inputData.From) <= new Date(inputData.Till)){
      }
else{
  this.setState({ querySuccess: false })
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }

    inputData.Equips = this.getEquipsForReport()
    inputData.Shift=inputData.Shift.map(one=>one.value);
    axios.post(api.api + 'GetDowntimeAnalysisCumulative', inputData).then((result) => {
      if (result.data !== null && result.data !== undefined) {
        if (result.data.length > 0) {
          let pieChartData = this.GetPieChartData(result.data)
          let pieChartCostData = this.GetPieChartCostData(result.data)

          let data = this.organisePieChartData(result.data)
          let data1 = this.organisePieChartCostData(result.data)

          this.state.myNewChart.chart.config.data = data;
          this.state.myNewChart.chart.update();
          this.state.myNewChart1.chart.config.data = data1;
          this.state.myNewChart1.chart.update();
          this.setState({ querySuccess: false, downtimeDataCumulative: data, downTimePieChartData: result.data, pieChartData: pieChartData,pieChartCostData: pieChartCostData });

        }
        else {
          swal('Data Not Available');
          this.setState({ querySuccess: false })
        }
      }
      else {
        this.setState({ querySuccess: false })
      }

    })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err)
        this.setState({ querySuccess: false })
      })

  }
  renderScatterForCostChart = () => {
    let data = this.getPercentage1(this.state.downTimePieChartData);
    let arr = []
    let seriesObj = {}
    data.forEach((one, i) => {
      seriesObj[i] = { color: one.Color }
    })
    let schema = ['NumOfMachines'];
    data.forEach((one) => {
      one.Reason = one.Reason + '(Machines , Cost)'
      schema.push(one.Reason);
      schema.push({ 'role': 'style', 'type': 'string' });
    })
    arr.push(schema);


    data.forEach(one => {
      let arr1 = []
      arr1.push(one.NumOfMachines);

      for (let i = 1; i < schema.length; i = i + 2) {
        if (schema[i] == one.Reason) {
          arr1.push((one.Cost));//'point {size: 14; fill-color: green'
          arr1.push('point {size: 7; fill-color: ' + one.Color);
        } else {
          arr1.push(null);
          arr1.push(null)
        }
      }
      arr.push(arr1);
    })

    return arr.length > 1 ? <ScatterChart data={arr} colors={seriesObj} titlev={'Downtime By Cost in (₹)'}/> : ''
  }

  renderScatterChart = () => {
    let data = this.getPercentage(this.state.downTimePieChartData);
    let arr = []
    let seriesObj = {}
    data.forEach((one, i) => {
      seriesObj[i] = { color: one.Color }
    })
    let schema = ['NumOfMachines'];
    data.forEach((one) => {
      one.Reason = one.Reason + '(Machines , Percentage)'
      schema.push(one.Reason);
      schema.push({ 'role': 'style', 'type': 'string' });
    })
    arr.push(schema);


    data.forEach(one => {
      let arr1 = []
      arr1.push(one.NumOfMachines);

      for (let i = 1; i < schema.length; i = i + 2) {
        if (schema[i] == one.Reason) {
          arr1.push(parseFloat(one.Percentage));//'point {size: 14; fill-color: green'
          arr1.push('point {size: 7; fill-color: ' + one.Color);
        } else {
          arr1.push(null);
          arr1.push(null)
        }
      }
      arr.push(arr1);
    })

    return arr.length > 1 ? <ScatterChart data={arr} colors={seriesObj}  titlev={'Downtime Percentage'}/> : ''
  }

  renderPieChart = () => {
    if (this.state.pieChartData.length == 0) {
      return <></>;
    }
    return     <PieChart
    style={{ marginTop: 20 }}
    chartType="PieChart"
    width="100%"
    height="800px"
    data={this.state.pieChartData}
    options={{
      chartArea:{left:50,top:40,right:20,bottom:20},


      title: "DownTime Group",
      pieHole: 0.4,
      is3D: false,
      tooltip: {
        showColorCode: true,
        text: 'percentage',
      },
    }}
  />
  
  }
  renderPieChart1 = () => {
    if (this.state.pieChartCostData.length == 0) {
      return <></>;
    }
    return     <PieChart
    style={{ marginTop: 20 }}
    chartType="PieChart"
    width="100%"
    height="800px"
    data={this.state.pieChartCostData}
    options={{
      chartArea:{left:50,top:40,right:20,bottom:20},
      // legend: 'none',
      title: "DownTime Group by Cost in (₹)",
      pieHole: 0.4,
      is3D: false,
      pieSliceText:'value',
      tooltip: {
        showColorCode: true,
        text: 'Cost in (₹)',
      },
    }}
  />
  
  }
  setSelection = (key, option, equips) => {
    if (key == 'EquipmentGroup') {
      this.setState({ equip: equips ? equips : [], selectedEquipGroupOption: option ,selectedEquipOption: []})
    }
    if (key == 'Equipment') {

      this.setState({ selectedEquipOption: option })
    }
  }

  getEquipsForReport = () => {
    let equipArr = []
    this.props.equips.forEach((one) => {
      this.state.selectedEquipOption.forEach((option) => {
        if (one._id == option.value) {
          equipArr.push(one);
        }
      })
    })
    return equipArr;
  }

  getDowntimeGroupTableData=(data)=>{
    data=[...this.getPercentage(data)];
    for (let i = 0; i < data.length; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i].DowntimeGroup == data[j].DowntimeGroup) {
          data[i].Duration = data[i].Duration + data[j].Duration;
          data[i].Cost = data[i].Cost + data[j].Cost;
          data.splice(j, 1);
          j--;
        }
      }
    }
    return data;
  }

  SelectedDates = (dates) => {
    this.setState({ inputData: dates })
  }


  render() {
    const { equip, selectedEquipGroupOption, selectedEquipOption } = this.state;
    const { getEquipsForReport, setSelection } = this
    return (
      <div style={{ marginTop: 30 }} className='justify-content-center'>
        <div className="text-center heading-bg mb-4">
          <h4 className="text-white p-0 m-0" >
            Downtime Analysis Cumulative
          </h4>
        </div>
        {this.state.querySuccess ? <Loading /> : ''}
        <ToastContainer />
        <div className='row'>
          {this.props.equipGroups.length > 0 && <EquipmentSelection equipGroups={this.props.equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}
          {/* <div className="col-sm-2">
            <div className="form-group text-center">
              <label className='small'>From</label>
              <input className={'form-control form-control-sm'} min={this.props.fromDateLimit} placeholder='dd/mm/yyyy' type='date' onChange={this.handleOnChange} name='From' />
              {this.state.errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.From}</p>}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className='small'>Till</label>
              <input className={'form-control form-control-sm'} placeholder='dd/mm/yyyy' type='date' onChange={this.handleOnChange} name='Till' />
              {this.state.errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.Till}</p>}
            </div>
          </div> */}
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className='small'>Downtime Type</label>
              <MultiSelectCheckBox name='Downtime' onChange={this.handleOnChange} selectedValues={this.state.inputData['Downtime']} options={this.state.downtime} />
              {this.state.errors.Downtime && <p className='text-danger ' style={{ fontSize: 11 }}>please select Downtime type</p>}
            </div>
          </div>
          <DateSelection SelectedDates={this.SelectedDates} fromDateLimit={this.props.fromDateLimit} />

          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className='small'>Shift</label>
              <MultiSelectCheckBox name='Shift' onChange={this.handleOnChange} selectedValues={this.state.inputData['Shift']} options={this.state.shiftOption} />
              {this.state.errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>please select at least on shift</p>}
            </div>
          </div>
          
          {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={this.handleOnChange} name='Shift'>
              <option>--select shift--</option>
              <option>All</option>
              {this.state.shiftSetting.map(one=>{
                
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
            </select>
            {this.state.errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.Shift}</p>}
          </div>
        </div> */}

          <div className="col-sm-1">
            <button className='btn btn-primary btn-sm mt-4' onClick={() => this.handleOnSubmit(this.state.inputData)}>Apply</button>
          </div>
        </div>
        <div className='w-100 d-flex'>
          <div className="w-100">

            <canvas
              height={500}
              width={window.innerWidth * 0.7} // 50% of the window's inner width
              onClick={this.handleOnClickCanvas}
              id="myChart"
              ref={this.chartRef}
            />

          </div>

        </div>
        <div className='w-100 d-flex'>
          <div className="w-100">

            <canvas
              height={500}

              width={window.innerWidth * 0.7} // 50% of the window's inner width

              onClick={this.handleOnClickCanvas1}
              id="myChart1"
              ref={this.chartRef1}
            />

          </div>
        </div>
        <div className='text-center' >
          {this.renderScatterChart()}
          {this.state.downTimePieChartData[0]?.Event[0].Cost > 0 ? this.renderScatterForCostChart() : "no data"}

          <div className='w-100 d-flex'>

            <div className='w-50'>
              {this.renderPieChart()}
            </div>

            <div className='w-50'>
              {this.renderPieChart1()}
            </div>
          </div>

        </div>
        <div className='row'>
          {this.state.downTimePieChartData.length > 0 ? <div className="col-sm-7 table-responsive-sm table-striped" >
            <p className='btn btn-primary btn-sm' onClick={() => this.handleDownloadExcel('DownTimeAnalysis')}>Download Excel</p>
            <table id="DownTimeAnalysis" className="table table-sm">
              <thead>
                <tr className="table-primary">
                  <th scope="col">Downtime Group</th>
                  <th scope="col">Downtime Reason</th>
                  <th scope="col">Downtime(In Mins)</th>
                  <th scope="col">Percentage(%)</th>
                  <th scope="col">Cost in (₹)</th>

                </tr>
              </thead>
              <tbody>
                {this.getPercentage(this.state.downTimePieChartData).map((one) => {
                  return <tr>
                    <td>{one.DowntimeGroup}</td>
                    <td>{one.Reason}</td>
                    <td>{one.Duration}</td>
                    <td>{one.Percentage.toString()}</td>
                    <td>{one.Cost > 0 ? Number(one.Cost.toFixed(1)) : 0}</td>


                  </tr>
                })
                }
              </tbody>

            </table>
          </div > : ''}
          {this.state.downTimePieChartData.length > 0 ? <div className="col-sm-5 table-responsive-sm table-striped" >
            <p className='btn btn-primary btn-sm' onClick={() => this.handleDownloadExcel('DowntimeGroupTable')}>Download Excel</p>
            <table id="DowntimeGroupTable" className="table table-sm">
              <thead>
                <tr className="table-primary">
                  <th scope="col">Downtime Group</th>
                  <th scope="col">Downtime(In Mins)</th>
                  <th scope="col">Cost in (₹)</th>
                </tr>
              </thead>
              <tbody>
                {this.getDowntimeGroupTableData(this.state.downTimePieChartData).map((one) => {
                  return <tr>
                    <td>{one.DowntimeGroup}</td>
                    <td>{one.Duration}</td>
                    <td>{one.Cost > 0 ? Number(one.Cost.toFixed(1)) : 0}</td>
                  </tr>
                })
                }
              </tbody>

            </table>
          </div > : ''}

        </div>
        {this.state.viewBarChartOnClick ? <PieChart
          style={{ marginTop: 20 }}
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={this.state.Bardata}
          options={{
            legend: { position: 'none' },
            vAxis: { title: "Time(In Minutes)" },
            tooltip: { isHtml: true },
            animation: { startup: true, duration: 400, easing: "out" },
            //   explorer: {
            //    // actions: ["dragToZoom", "rightClickToReset"],
            //     axis: "horizontal",
            //     axis: "vertical",
            //     keepInBounds: true,
            //    // maxZoomIn: 4.0
            //   },

            hAxis: {
              slantedText: true,
              slantedTextAngle: 90,
              textStyle: { fontSize: 9 },
              title: "Machines"
            },
            annotations: { textStyle: { fontSize: 10, color: "" } },

          }}
        /> : ''}


      </div>

    );
  }
}

// function getip(json) {
//     alert( json.ip);
//     }


export default DowntimeAnalysisCumulative;