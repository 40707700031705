
import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import EquipmentSelection from '../Common/equipmentSelection';
import MultiSelectCheckBox from '../Common/MultiSelectCheckBox';
import Chart from 'react-google-charts';
import colors from '../utill/colors.json';
import DetailedProdAnalysisCompTbl from '../utill/DetailedProdAnalysisCompTbl';
import DetailedProdAnalysisPlantTbl from '../utill/DetailedProdAnalysisPlantTbl';
import dateTime from '../utill/dateTime';
import '../App.css'
import _, { cloneDeep } from 'lodash';

const AnalysisDetailedProduction = ({
    onApply,
    handleDownloadTableToExcel,
    reasons,
    qualityReason,
    handleOnChnage,
    data, state, onHide,
    setSelectedEquipGroupOption, selectedEquipGroupOption,
    inputData, setInputData,
    selectedEquipOption, setSelectedEquipOption,
    setEquips, equip,
    shiftOption, setShiftOption,
    equips, equipGroups,fromDateLimit }) => {

    const [showChartType, setShowChartType] = useState({})
    const [showChartState, setShowChartState] = useState(false);

    const onChange = (e) => {
        const obj = {}
        obj.name = e.currentTarget.value;
        obj.label = e.currentTarget.options[e.currentTarget.selectedIndex].innerHTML;
        setShowChartType(obj);
    }

    const setSelection = (key, option, equips) => {
        if (key == 'EquipmentGroup') {
            setEquips(equips ? equips : []);
            setSelectedEquipGroupOption(option)
            setSelectedEquipOption([])
        }
        if (key == 'Equipment') {

            setSelectedEquipOption(option)
        }
    }




    const organiseDowntimeData = (bufferArr) => {
         bufferArr = _.cloneDeep(bufferArr);
        bufferArr = [...getComponentUniqe(bufferArr)]
        let schema = ['Component/Operation']
        let arr = [];
        let colorWithReason = []
        let seriesObj = {}
        bufferArr.forEach(element => {
            element.Downtime.forEach((one, i) => {
                let IsAlready;
                let obj = {}

                if (one.DowntimeReason) {

                    IsAlready = schema.includes(one.DowntimeReason);
                } else {

                    IsAlready = schema.includes(one.State);
                }
                if (!IsAlready) {
                    if (one.DowntimeReason) {
                        obj.Reason = one.DowntimeReason;
                        obj.Color = one.Color;
                        colorWithReason.push(obj)
                        schema.push(one.DowntimeReason)
                        schema.push({ role: 'style' })
                    } else {
                        obj.Reason = one.State;
                        obj.Color = 'red';
                        colorWithReason.push(obj)
                        schema.push(one.State)
                        schema.push({ role: 'style' })
                    }
                }
            })
        });

        bufferArr.forEach(element => {
            let oneArr = []
            if (element.Downtime.length > 0) {
                oneArr = [element.Component + "/" + element.Operation]
            }
            let down = element.Downtime;
            if (down.length > 0) {
                down = aggreagteReasonDowntime(down)
                for (let i = 1; i < schema.length; i = i + 2) {
                    let filterDown = down.filter((one) => {
                        if (one.DowntimeReason) {
                            if (one.DowntimeReason == schema[i]) {
                                return one
                            }
                        } else {
                            if (one.State == schema[i]) {
                                return one
                            }
                        }
                    }
                    )
                    if (filterDown.length > 0) {
                        oneArr.push(Math.ceil(filterDown[0].Duration));
                        oneArr.push(filterDown[0].Color)
                    } else {
                        let filterColor = colorWithReason.filter(color => color.Reason == schema[i])
                        oneArr.push(0);
                        oneArr.push(filterColor[0].Color)
                    }
                }
                arr.push(oneArr)
            }

        })
        arr.unshift(schema)
        colorWithReason.forEach((one, i) => {
            seriesObj[i] = { color: one.Color }
        })
        let returnObj = { arr: arr, color: seriesObj }
        return returnObj
    }

    const organiseEquipmentDowntimeData = (bufferArr) => {
        bufferArr = _.cloneDeep(bufferArr);
        bufferArr = aggreagteEquipmentDowntime(bufferArr)
        let schema = ['Equipment']
        let arr = [];
        let colorWithReason = []
        let seriesObj = {}
        bufferArr.forEach(element => {
            element.Downtime.forEach((one, i) => {
                let IsAlready;
                let obj = {}

                if (one.DowntimeReason) {

                    IsAlready = schema.includes(one.DowntimeReason);
                } else {

                    IsAlready = schema.includes(one.State);
                }
                if (!IsAlready) {
                    if (one.DowntimeReason) {
                        obj.Reason = one.DowntimeReason;
                        obj.Color = one.Color;
                        colorWithReason.push(obj)
                        schema.push(one.DowntimeReason)
                        schema.push({ role: 'style' })
                    } else {
                        obj.Reason = one.State;
                        obj.Color = 'red';
                        colorWithReason.push(obj)
                        schema.push(one.State)
                        schema.push({ role: 'style' })
                    }
                }
            })
        });

        bufferArr.forEach(element => {
            let oneArr = []
            if (element.Downtime.length > 0) {
                oneArr = [element.Equipment]
            }
            let down = element.Downtime;
            if (down.length > 0) {
                down = aggreagteReasonDowntime(down)
                for (let i = 1; i < schema.length; i = i + 2) {
                    let filterDown = down.filter((one) => {
                        if (one.DowntimeReason) {
                            if (one.DowntimeReason == schema[i]) {
                                return one
                            }
                        } else {
                            if (one.State == schema[i]) {
                                return one
                            }
                        }
                    }
                    )
                    if (filterDown.length > 0) {
                        oneArr.push(Math.ceil(filterDown[0].Duration));
                        oneArr.push(filterDown[0].Color)
                    } else {
                        let filterColor = colorWithReason.filter(color => color.Reason == schema[i])
                        oneArr.push(0);
                        oneArr.push(filterColor[0].Color)
                    }
                }
                arr.push(oneArr)
            }

        })
        arr.unshift(schema)
        colorWithReason.forEach((one, i) => {
            seriesObj[i] = { color: one.Color }
        })
        let returnObj = { arr: arr, color: seriesObj }
        return returnObj
    }


    const qualityColorAssign = (bufferArr) => {
        let startIdx = 0
        bufferArr.forEach((obj) => {
            obj.RejectionReason.forEach((reason) => {
                if (colors[startIdx]) {
                    reason.Color = colors[startIdx].hex;
                    startIdx = startIdx + 1;
                } else {
                    reason.Color = colors[0].hex;
                    startIdx = 1;
                }

            })
        })
        return bufferArr;
    }

    const organiseRejectionData = (bufferArr) => {
        bufferArr = [...getComponentUniqe(bufferArr)]
        bufferArr = qualityColorAssign(bufferArr);
        let schema = ['Component/Operation']
        let arr = [];
        let colorWithReason = []
        let seriesObj = {}
        bufferArr.forEach(element => {
            element.RejectionReason.forEach((one, i) => {
                let IsAlready;
                let obj = {}
                if (one.Reason) {
                    IsAlready = schema.includes(one.Reason);
                } else {
                    IsAlready = schema.includes(one.Reason);
                }
                if (!IsAlready) {
                    if (one.Reason) {
                        obj.Reason = one.Reason;
                        obj.Color = one.Color;
                        colorWithReason.push(obj)
                        schema.push(one.Reason)
                        schema.push({ role: 'style' })
                    } else {
                        obj.Reason = one.Reason;
                        obj.Color = 'red';
                        colorWithReason.push(obj)
                        schema.push(one.Reason)
                        schema.push({ role: 'style' })
                    }
                }
            })
        });

        bufferArr.forEach(element => {
            let oneArr = []
            if (element.RejectionReason.length > 0) {
                oneArr = [element.Component + "/" + element.Operation]
            }
            let down = element.RejectionReason;
            if (down.length > 0) {
                // down = aggreagteReasonDowntime(down)
                for (let i = 1; i < schema.length; i = i + 2) {
                    let filterDown = down.filter((one) => {
                        if (one.Reason) {
                            if (one.Reason == schema[i]) {
                                return one
                            }
                        } else {
                            if (one.Reason == schema[i]) {
                                return one
                            }
                        }
                    }
                    )
                    if (filterDown.length > 0) {
                        oneArr.push(Math.ceil(filterDown[0].Count));
                        oneArr.push(filterDown[0].Color)
                    } else {
                        let filterColor = colorWithReason.filter(color => color.Reason == schema[i])
                        oneArr.push(0);
                        oneArr.push(filterColor[0].Color)
                    }
                }
                arr.push(oneArr)
            }

        })
        arr.unshift(schema)
        colorWithReason.forEach((one, i) => {
            seriesObj[i] = { color: one.Color }
        })
        let returnObj = { arr: arr, color: seriesObj }
        return returnObj
    }

    function aggreagteReasonDowntime(bufferArry) {
        bufferArry = [...bufferArry]
        for (let i = 0; i < bufferArry.length; i++) {
            for (let j = i + 1; j < bufferArry.length; j++) {
                if (bufferArry[i].DowntimeReason === bufferArry[j].DowntimeReason) {
                    bufferArry[i].Duration += bufferArry[j].Duration
                    bufferArry.splice(j, 1);

                    // i--;
                    j--;
                    // i=j-1
                }
            }
        }
        return bufferArry
    }

    function aggreagteRejectionCount(bufferArry) {

        for (let i = 0; i < bufferArry.length; i++) {
            for (let j = i + 1; j < bufferArry.length; j++) {
                if (bufferArry[i].Reason === bufferArry[j].Reason) {
                    bufferArry[i].Count += bufferArry[j].Count
                    bufferArry.splice(j, 1);

                    // i--;
                    j--;
                    // i=j-1
                }
            }
        }
        return bufferArry
    }

    function aggreagteEquipmentDowntime(bufferArry) {
        bufferArry = [...bufferArry]
        for (let i = 0; i < bufferArry.length; i++) {
            for (let j = i + 1; j < bufferArry.length; j++) {
                if (bufferArry[i].Equipment === bufferArry[j].Equipment) {
                    bufferArry[i].Downtime = [...bufferArry[j].Downtime, ...bufferArry[i].Downtime]
                    bufferArry.splice(j, 1);
                    j--;
                }
            }
        }
        bufferArry.forEach((one) => {
            one.Downtime = aggreagteReasonDowntime(one.Downtime);
        })
        return bufferArry
    }

    const addPeriodicity = (bufferArry) => {
        bufferArry = [...bufferArry];

        if (inputData.Periodicity == 'Weekly') {
            let frames = dateTime.GetWeeklyFrame(new Date(inputData.From), new Date(inputData.Till))
            frames.forEach((one) => {
                one.From.setHours(0, 0, 0, 0);
                one.Till.setHours(23, 59, 59, 999);
            })
            frames.forEach((one, index) => {
                bufferArry.forEach((obj) => {
                    if ((one.From <= new Date(obj.From)) && (one.Till >= new Date(obj.Till))) {
                        obj.Periodicity = one.Periodicity
                        obj.FromPeriodicity = one.From
                        obj.TillPeriodicity = one.Till
                    }
                })
            })
            return bufferArry;
        }
        else if (inputData.Periodicity == 'Daily') {
            let frames = dateTime.GetDailyFrames(new Date(inputData.From), new Date(inputData.Till))
            frames.forEach((one, index) => {
                one.From.setHours(0, 0, 0, 0);
                one.Till.setHours(23, 59, 59, 999);
            })
            frames.forEach((one, index) => {
                bufferArry.forEach((obj) => {
                    if ((one.From <= new Date(obj.From)) && (one.Till >= new Date(obj.Till))) {
                        obj.Periodicity = one.Periodicity
                        obj.FromPeriodicity = one.From
                        obj.TillPeriodicity = one.Till
                    }
                })
            })
            return bufferArry;
        } else {
            return bufferArry
        }
    }

    const getComponentUniqe = (bufferArry) => {
        bufferArry = _.cloneDeep(bufferArry);
        bufferArry.forEach((one) => {
            one.IdealSPM_x_EquipmentRunTime = (parseFloat(one.IdealSPM)*parseFloat(one.EquipmentRunTime))
        })
        if (inputData.Periodicity == 'Cummulative') {
            bufferArry.forEach((one) => {
                one.IdealSPM_x_EquipmentRunTime = (parseFloat(one.IdealSPM)*parseFloat(one.EquipmentRunTime))
                one.Periodicity = one.Shift
                one.FromPeriodicity = one.From
                one.TillPeriodicity = one.Till
                one.Performance_x_PlannedProdTime = (parseFloat(one.Performance) * parseFloat(one.PlannedProdTime))
            })
            for (let i = 0; i < bufferArry.length; i++) {
                for (let j = i + 1; j < bufferArry.length; j++) {
                    if ((bufferArry[i].Component === bufferArry[j].Component) && (bufferArry[i].Operation === bufferArry[j].Operation)) {
                        bufferArry[i].Duration += bufferArry[j].Duration;
                        bufferArry[i].IdealSPM_x_EquipmentRunTime = parseFloat(bufferArry[i].IdealSPM_x_EquipmentRunTime) + parseFloat(bufferArry[j].IdealSPM_x_EquipmentRunTime);
                        bufferArry[i].TotalRejection = parseFloat(bufferArry[i].TotalRejection) + parseFloat(bufferArry[j].TotalRejection);
                        bufferArry[i].CyclesRun = parseFloat(bufferArry[i].CyclesRun) + parseFloat(bufferArry[j].CyclesRun);
                        bufferArry[i].TotalDowntimeDuration = parseFloat(bufferArry[i].TotalDowntimeDuration) + parseFloat(bufferArry[j].TotalDowntimeDuration);
                        bufferArry[i].UnplannedDowntimeDuration = parseFloat(bufferArry[i].UnplannedDowntimeDuration) + parseFloat(bufferArry[j].UnplannedDowntimeDuration);
                        bufferArry[i].TargetParts = parseFloat(bufferArry[i].TargetParts) + parseFloat(bufferArry[j].TargetParts);
                        bufferArry[i].BreakDuration = parseFloat(bufferArry[i].BreakDuration) + parseFloat(bufferArry[j].BreakDuration);
                        bufferArry[i].JobDuration = parseFloat(bufferArry[i].JobDuration) + parseFloat(bufferArry[j].JobDuration);
                        bufferArry[i].EquipmentRunTime = parseFloat(bufferArry[i].EquipmentRunTime) + parseFloat(bufferArry[j].EquipmentRunTime);
                        bufferArry[i].PlannedProdTime = parseFloat(bufferArry[i].PlannedProdTime) + parseFloat(bufferArry[j].PlannedProdTime);
                        bufferArry[i].PlannedDowntimeDuration = parseFloat(bufferArry[i].PlannedDowntimeDuration) + parseFloat(bufferArry[j].PlannedDowntimeDuration);
                        bufferArry[i].AcceptedParts = parseInt(bufferArry[i].AcceptedParts) + parseInt(bufferArry[j].AcceptedParts);
                        bufferArry[i].PartsProduced = parseInt(bufferArry[i].PartsProduced) + parseInt(bufferArry[j].PartsProduced);
                        bufferArry[i].Performance_x_PlannedProdTime = parseFloat(bufferArry[i].Performance_x_PlannedProdTime) + parseFloat(bufferArry[j].Performance_x_PlannedProdTime);
                        bufferArry[i].RejectionReason = [...bufferArry[i].RejectionReason, ...bufferArry[j].RejectionReason];
                        bufferArry[i].Downtime = [...bufferArry[i].Downtime, ...bufferArry[j].Downtime];
                        bufferArry.splice(j, 1);
                        j--;
                    }
                }
            }
            bufferArry.forEach((one) => {
                one.Downtime = aggreagteReasonDowntime(one.Downtime);
            })
            bufferArry.forEach((one) => {
                one.RejectionReason = aggreagteRejectionCount(one.RejectionReason);
            })
            bufferArry = addPeriodicity(bufferArry);
            return bufferArry;
        }
        else if (inputData.Periodicity == 'Daily') {
            bufferArry.forEach((one) => {
                one.Performance_x_PlannedProdTime = (parseFloat(one.Performance) * parseFloat(one.PlannedProdTime))
            })
            for (let i = 0; i < bufferArry.length; i++) {
                for (let j = i + 1; j < bufferArry.length; j++) {
                    if ((bufferArry[i].ShiftOf === bufferArry[j].ShiftOf) && (bufferArry[i].Component === bufferArry[j].Component) && (bufferArry[i].Operation === bufferArry[j].Operation)) {
                        bufferArry[i].Duration += bufferArry[j].Duration;
                        bufferArry[i].IdealSPM_x_EquipmentRunTime = parseFloat(bufferArry[i].IdealSPM_x_EquipmentRunTime) + parseFloat(bufferArry[j].IdealSPM_x_EquipmentRunTime);
                        bufferArry[i].TotalRejection = parseFloat(bufferArry[i].TotalRejection) + parseFloat(bufferArry[j].TotalRejection);
                        bufferArry[i].CyclesRun = parseFloat(bufferArry[i].CyclesRun) + parseFloat(bufferArry[j].CyclesRun);
                        bufferArry[i].TotalDowntimeDuration = parseFloat(bufferArry[i].TotalDowntimeDuration) + parseFloat(bufferArry[j].TotalDowntimeDuration);
                        bufferArry[i].UnplannedDowntimeDuration = parseFloat(bufferArry[i].UnplannedDowntimeDuration) + parseFloat(bufferArry[j].UnplannedDowntimeDuration);
                        bufferArry[i].TargetParts = parseFloat(bufferArry[i].TargetParts) + parseFloat(bufferArry[j].TargetParts);
                        bufferArry[i].BreakDuration = parseFloat(bufferArry[i].BreakDuration) + parseFloat(bufferArry[j].BreakDuration);
                        bufferArry[i].JobDuration = parseFloat(bufferArry[i].JobDuration) + parseFloat(bufferArry[j].JobDuration);
                        bufferArry[i].EquipmentRunTime = parseFloat(bufferArry[i].EquipmentRunTime) + parseFloat(bufferArry[j].EquipmentRunTime);
                        bufferArry[i].PlannedProdTime = parseFloat(bufferArry[i].PlannedProdTime) + parseFloat(bufferArry[j].PlannedProdTime);
                        bufferArry[i].PlannedDowntimeDuration = parseFloat(bufferArry[i].PlannedDowntimeDuration) + parseFloat(bufferArry[j].PlannedDowntimeDuration);
                        bufferArry[i].AcceptedParts = parseInt(bufferArry[i].AcceptedParts) + parseInt(bufferArry[j].AcceptedParts);
                        bufferArry[i].PartsProduced = parseInt(bufferArry[i].PartsProduced) + parseInt(bufferArry[j].PartsProduced);
                        bufferArry[i].Performance_x_PlannedProdTime = parseFloat(bufferArry[i].Performance_x_PlannedProdTime) + parseFloat(bufferArry[j].Performance_x_PlannedProdTime);
                        bufferArry[i].RejectionReason = [...bufferArry[i].RejectionReason, ...bufferArry[j].RejectionReason];
                        bufferArry[i].Downtime = [...bufferArry[i].Downtime, ...bufferArry[j].Downtime];
                        bufferArry.splice(j, 1);
                        j--;
                    }

                }
            }
            bufferArry.forEach((one) => {
                one.Downtime = aggreagteReasonDowntime(one.Downtime);
            })
            bufferArry.forEach((one) => {
                one.RejectionReason = aggreagteRejectionCount(one.RejectionReason);
            })
            bufferArry = addPeriodicity(bufferArry);
            return bufferArry;
        }
        else if (inputData.Periodicity == 'Weekly') {
            bufferArry.forEach((one) => {
                one.Performance_x_PlannedProdTime = (parseFloat(one.Performance) * parseFloat(one.PlannedProdTime))
            })
            for (let i = 0; i < bufferArry.length; i++) {
                let start
                for (let j = i + 1; j < bufferArry.length; j++) {
                    if (dateTime.IsInSameWeek(new Date(bufferArry[i].ShiftOf), new Date(bufferArry[j].ShiftOf)) && (bufferArry[i].Component === bufferArry[j].Component) && (bufferArry[i].Operation === bufferArry[j].Operation)) {
                        bufferArry[i].Duration += bufferArry[j].Duration;
                        bufferArry[i].IdealSPM_x_EquipmentRunTime = parseFloat(bufferArry[i].IdealSPM_x_EquipmentRunTime) + parseFloat(bufferArry[j].IdealSPM_x_EquipmentRunTime);
                        bufferArry[i].TotalRejection = parseFloat(bufferArry[i].TotalRejection) + parseFloat(bufferArry[j].TotalRejection);
                        bufferArry[i].CyclesRun = parseFloat(bufferArry[i].CyclesRun) + parseFloat(bufferArry[j].CyclesRun);
                        bufferArry[i].TotalDowntimeDuration = parseFloat(bufferArry[i].TotalDowntimeDuration) + parseFloat(bufferArry[j].TotalDowntimeDuration);
                        bufferArry[i].UnplannedDowntimeDuration = parseFloat(bufferArry[i].UnplannedDowntimeDuration) + parseFloat(bufferArry[j].UnplannedDowntimeDuration);
                        bufferArry[i].TargetParts = parseFloat(bufferArry[i].TargetParts) + parseFloat(bufferArry[j].TargetParts);
                        bufferArry[i].BreakDuration = parseFloat(bufferArry[i].BreakDuration) + parseFloat(bufferArry[j].BreakDuration);
                        bufferArry[i].JobDuration = parseFloat(bufferArry[i].JobDuration) + parseFloat(bufferArry[j].JobDuration);
                        bufferArry[i].EquipmentRunTime = parseFloat(bufferArry[i].EquipmentRunTime) + parseFloat(bufferArry[j].EquipmentRunTime);
                        bufferArry[i].PlannedProdTime = parseFloat(bufferArry[i].PlannedProdTime) + parseFloat(bufferArry[j].PlannedProdTime);
                        bufferArry[i].PlannedDowntimeDuration = parseFloat(bufferArry[i].PlannedDowntimeDuration) + parseFloat(bufferArry[j].PlannedDowntimeDuration);
                        bufferArry[i].AcceptedParts = parseInt(bufferArry[i].AcceptedParts) + parseInt(bufferArry[j].AcceptedParts);
                        bufferArry[i].PartsProduced = parseInt(bufferArry[i].PartsProduced) + parseInt(bufferArry[j].PartsProduced);
                        bufferArry[i].Performance_x_PlannedProdTime = parseFloat(bufferArry[i].Performance_x_PlannedProdTime) + parseFloat(bufferArry[j].Performance_x_PlannedProdTime);
                        bufferArry[i].RejectionReason = [...bufferArry[i].RejectionReason, ...bufferArry[j].RejectionReason];
                        bufferArry[i].Downtime = [...bufferArry[i].Downtime, ...bufferArry[j].Downtime];
                        bufferArry.splice(j, 1);
                        j--;
                    }

                }
            }
            bufferArry.forEach((one) => {
                one.PlannedProdTime = parseFloat(one.PlannedProdTime).toFixed(2);
                one.Downtime = aggreagteReasonDowntime(one.Downtime);
            })
            bufferArry.forEach((one) => {
                one.RejectionReason = aggreagteRejectionCount(one.RejectionReason);
            })
            bufferArry = addPeriodicity(bufferArry);
            return bufferArry;
        }
        else if (inputData.Periodicity == 'Shift') {
            bufferArry.forEach((one) => {
                one.FromPeriodicity = one.From
                one.TillPeriodicity = one.Till
                one.Periodicity = one.Shift
                one.Performance_x_PlannedProdTime = (parseFloat(one.Performance) * parseFloat(one.PlannedProdTime))
            })
            bufferArry.forEach((one) => {
                one.Downtime = aggreagteReasonDowntime(one.Downtime);
            })
            bufferArry.forEach((one) => {
                one.RejectionReason = aggreagteRejectionCount(one.RejectionReason);
            })
            bufferArry = addPeriodicity(bufferArry);
            return bufferArry;
        } else {
            return []
        }

    }

    const getAPQEChart = (bufferArr) => {
        bufferArr = _.cloneDeep(bufferArr);
        const schema = [
            ["Component", "Availability", "Performance", "Quality", "OEE"],
        ];
        bufferArr = [...getComponentUniqe(bufferArr)];
        bufferArr.forEach((one) => {
            let data = []
            let avila = parseFloat(((one.EquipmentRunTime / (parseFloat(one.PlannedProdTime) - parseFloat(one.PlannedDowntimeDuration))) * 100).toPrecision(4));
            let perf = parseFloat((one.Performance_x_PlannedProdTime / parseFloat(one.PlannedProdTime)).toPrecision(4));
            let qf = parseFloat(((one.AcceptedParts / one.PartsProduced) * 100).toPrecision(4))
            let oee = parseFloat(((avila * perf * qf) / 10000).toPrecision(4))
            data.push(one.Component);
            data.push(avila);
            data.push(perf);
            data.push(qf);
            data.push(oee);
            schema.push(data);

        })

        const options = {
            chart: {
                title: "Component Wise OEE",
            },
            hAxis: {
                title: 'Percentage',
                
            },
            
            // bars: "horizontal",
        };
        return <Chart
            chartType="Bar"
            // width="100%"
            data={schema}
             height="600px"
            options={options}
        />
    }


    const getRejectionAnalysis = (bufferArr) => {
        bufferArr = _.cloneDeep(bufferArr);
        return <Chart
            // width={'100%'}
            height={800}
            style={{ padding: 0, height: 600}}
            // style={{ padding: 0, height: (bufferArr.length + 1) * 30 }}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={organiseRejectionData(bufferArr).arr}
            options={{
                title: 'Component Wise Rejection Analysis',
                // chartArea: { width: '50%' },
                isStacked: true,
                series: organiseRejectionData(bufferArr).color,
                vAxis: {
                    title: 'Rejection Count',
                    minValue: 0,
                },
                hAxis: {
                    title: 'Component',
                   

                },

            }}
            // For tests
            rootProps={{ 'data-testid': '3' }}
        />
    }

    const getComponentWiseDowntime = (bufferArr) => {
        bufferArr = _.cloneDeep(bufferArr);
        return <Chart
            // width={'100%'}
             height={800}
             style={{ padding: 0, height: 600 }}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={organiseDowntimeData(bufferArr).arr}
            options={{
                title: 'Component Wise Downtime Analysis',
                // chartArea: { width: '50%' },
                isStacked: true,
                series: organiseDowntimeData(bufferArr).color,
                vAxis: {
                    title: 'Downtime Duration',
                    minValue: 0,
                },
                hAxis: {
                    title: 'Component',
                   

                },
               

            }}
            // For tests
            rootProps={{ 'data-testid': '3' }}
        />
    }

    const getEquipmentWiseDowntime = (bufferArr) => {
        bufferArr = _.cloneDeep(bufferArr);
        return <Chart
            // width={'100%'}
            height={800}
            style={{ padding: 0, height: 600}}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={organiseEquipmentDowntimeData(bufferArr).arr}
            options={{
                title: 'Equipment Wise Downtime Analysis',
                // chartArea: { width: '50%' },
                isStacked: true,
                series: organiseEquipmentDowntimeData(bufferArr).color,
                vAxis: {
                    title: 'Downtime Duration',
                    minValue: 0,
                },
                hAxis: {
                    title: 'Equipment',
                   

                },

            }}
            // For tests
            rootProps={{ 'data-testid': '3' }}
        />
    }

    const getPlantWiseAccumlate = (bufferArry) => {
        bufferArry = _.cloneDeep(bufferArry);
        bufferArry.forEach((one) => {
            one.IdealSPM_x_EquipmentRunTime = (parseFloat(one.IdealSPM)*parseFloat(one.EquipmentRunTime))
        })
        if (inputData.Periodicity == 'Cummulative') {
            bufferArry.forEach((one) => {
                one.Periodicity = one.Shift
                one.FromPeriodicity = inputData.From
                one.TillPeriodicity = inputData.Till
                one.Performance_x_PlannedProdTime = (parseFloat(one.Performance) * parseFloat(one.PlannedProdTime))
            })
            for (let i = 0; i < bufferArry.length; i++) {
                for (let j = i + 1; j < bufferArry.length; j++) {
                    bufferArry[i].Duration += bufferArry[j].Duration;
                    bufferArry[i].IdealSPM_x_EquipmentRunTime = parseFloat(bufferArry[i].IdealSPM_x_EquipmentRunTime) + parseFloat(bufferArry[j].IdealSPM_x_EquipmentRunTime);
                    bufferArry[i].TotalRejection = parseFloat(bufferArry[i].TotalRejection) + parseFloat(bufferArry[j].TotalRejection);
                    bufferArry[i].CyclesRun = parseFloat(bufferArry[i].CyclesRun) + parseFloat(bufferArry[j].CyclesRun);
                    bufferArry[i].TotalDowntimeDuration = parseFloat(bufferArry[i].TotalDowntimeDuration) + parseFloat(bufferArry[j].TotalDowntimeDuration);
                    bufferArry[i].UnplannedDowntimeDuration = parseFloat(bufferArry[i].UnplannedDowntimeDuration) + parseFloat(bufferArry[j].UnplannedDowntimeDuration);
                    bufferArry[i].TargetParts = parseFloat(bufferArry[i].TargetParts) + parseFloat(bufferArry[j].TargetParts);
                    bufferArry[i].BreakDuration = parseFloat(bufferArry[i].BreakDuration) + parseFloat(bufferArry[j].BreakDuration);
                    bufferArry[i].JobDuration = parseFloat(bufferArry[i].JobDuration) + parseFloat(bufferArry[j].JobDuration);
                    bufferArry[i].EquipmentRunTime = parseFloat(bufferArry[i].EquipmentRunTime) + parseFloat(bufferArry[j].EquipmentRunTime);
                    bufferArry[i].PlannedProdTime = parseFloat(bufferArry[i].PlannedProdTime) + parseFloat(bufferArry[j].PlannedProdTime);
                    bufferArry[i].PlannedDowntimeDuration = parseFloat(bufferArry[i].PlannedDowntimeDuration) + parseFloat(bufferArry[j].PlannedDowntimeDuration);
                    bufferArry[i].AcceptedParts = parseInt(bufferArry[i].AcceptedParts) + parseInt(bufferArry[j].AcceptedParts);
                    bufferArry[i].PartsProduced = parseInt(bufferArry[i].PartsProduced) + parseInt(bufferArry[j].PartsProduced);
                    bufferArry[i].Performance_x_PlannedProdTime = parseFloat(bufferArry[i].Performance_x_PlannedProdTime) + parseFloat(bufferArry[j].Performance_x_PlannedProdTime);
                    bufferArry[i].RejectionReason = [...bufferArry[i].RejectionReason, ...bufferArry[j].RejectionReason];
                    bufferArry[i].Downtime = [...bufferArry[i].Downtime, ...bufferArry[j].Downtime];
                    bufferArry.splice(j, 1);
                    j--;
                }
            }
            bufferArry.forEach((one) => {
                one.Downtime = aggreagteReasonDowntime(one.Downtime);
            })
            bufferArry.forEach((one) => {
                one.RejectionReason = aggreagteRejectionCount(one.RejectionReason);
            })
            bufferArry = addPeriodicity(bufferArry);
            return bufferArry;
        }
        else if (inputData.Periodicity == 'Daily') {
            bufferArry.forEach((one) => {
                one.Performance_x_PlannedProdTime = (parseFloat(one.Performance) * parseFloat(one.PlannedProdTime))
            })
            for (let i = 0; i < bufferArry.length; i++) {
                for (let j = i + 1; j < bufferArry.length; j++) {
                    if ((bufferArry[i].ShiftOf === bufferArry[j].ShiftOf)) {
                        bufferArry[i].Duration += bufferArry[j].Duration;
                        bufferArry[i].IdealSPM_x_EquipmentRunTime = parseFloat(bufferArry[i].IdealSPM_x_EquipmentRunTime) + parseFloat(bufferArry[j].IdealSPM_x_EquipmentRunTime);
                        bufferArry[i].TotalRejection = parseFloat(bufferArry[i].TotalRejection) + parseFloat(bufferArry[j].TotalRejection);
                        bufferArry[i].CyclesRun = parseFloat(bufferArry[i].CyclesRun) + parseFloat(bufferArry[j].CyclesRun);
                        bufferArry[i].TotalDowntimeDuration = parseFloat(bufferArry[i].TotalDowntimeDuration) + parseFloat(bufferArry[j].TotalDowntimeDuration);
                        bufferArry[i].UnplannedDowntimeDuration = parseFloat(bufferArry[i].UnplannedDowntimeDuration) + parseFloat(bufferArry[j].UnplannedDowntimeDuration);
                        bufferArry[i].TargetParts = parseFloat(bufferArry[i].TargetParts) + parseFloat(bufferArry[j].TargetParts);
                        bufferArry[i].BreakDuration = parseFloat(bufferArry[i].BreakDuration) + parseFloat(bufferArry[j].BreakDuration);
                        bufferArry[i].JobDuration = parseFloat(bufferArry[i].JobDuration) + parseFloat(bufferArry[j].JobDuration);
                        bufferArry[i].EquipmentRunTime = parseFloat(bufferArry[i].EquipmentRunTime) + parseFloat(bufferArry[j].EquipmentRunTime);
                        bufferArry[i].PlannedProdTime = parseFloat(bufferArry[i].PlannedProdTime) + parseFloat(bufferArry[j].PlannedProdTime);
                        bufferArry[i].PlannedDowntimeDuration = parseFloat(bufferArry[i].PlannedDowntimeDuration) + parseFloat(bufferArry[j].PlannedDowntimeDuration);
                        bufferArry[i].AcceptedParts = parseInt(bufferArry[i].AcceptedParts) + parseInt(bufferArry[j].AcceptedParts);
                        bufferArry[i].PartsProduced = parseInt(bufferArry[i].PartsProduced) + parseInt(bufferArry[j].PartsProduced);
                        bufferArry[i].Performance_x_PlannedProdTime = parseFloat(bufferArry[i].Performance_x_PlannedProdTime) + parseFloat(bufferArry[j].Performance_x_PlannedProdTime);
                        bufferArry[i].RejectionReason = [...bufferArry[i].RejectionReason, ...bufferArry[j].RejectionReason];
                        bufferArry[i].Downtime = [...bufferArry[i].Downtime, ...bufferArry[j].Downtime];

                        bufferArry.splice(j, 1);
                        j--;
                    }

                }
            }
            bufferArry.forEach((one) => {
                one.Downtime = aggreagteReasonDowntime(one.Downtime);
            })
            bufferArry.forEach((one) => {
                one.RejectionReason = aggreagteRejectionCount(one.RejectionReason);
            })
            bufferArry = addPeriodicity(bufferArry);
            return bufferArry;
        }
        else if (inputData.Periodicity == 'Weekly') {
            bufferArry.forEach((one) => {
                one.Performance_x_PlannedProdTime = (parseFloat(one.Performance) * parseFloat(one.PlannedProdTime))
            })
            for (let i = 0; i < bufferArry.length; i++) {
                for (let j = i + 1; j < bufferArry.length; j++) {
                    if (dateTime.IsInSameWeek(new Date(bufferArry[i].ShiftOf), new Date(bufferArry[j].ShiftOf))) {
                        bufferArry[i].Duration += bufferArry[j].Duration;
                        bufferArry[i].IdealSPM_x_EquipmentRunTime = parseFloat(bufferArry[i].IdealSPM_x_EquipmentRunTime) + parseFloat(bufferArry[j].IdealSPM_x_EquipmentRunTime);
                        bufferArry[i].TotalRejection = parseFloat(bufferArry[i].TotalRejection) + parseFloat(bufferArry[j].TotalRejection);
                        bufferArry[i].CyclesRun = parseFloat(bufferArry[i].CyclesRun) + parseFloat(bufferArry[j].CyclesRun);
                        bufferArry[i].TotalDowntimeDuration = parseFloat(bufferArry[i].TotalDowntimeDuration) + parseFloat(bufferArry[j].TotalDowntimeDuration);
                        bufferArry[i].UnplannedDowntimeDuration = parseFloat(bufferArry[i].UnplannedDowntimeDuration) + parseFloat(bufferArry[j].UnplannedDowntimeDuration);
                        bufferArry[i].TargetParts = parseFloat(bufferArry[i].TargetParts) + parseFloat(bufferArry[j].TargetParts);
                        bufferArry[i].BreakDuration = parseFloat(bufferArry[i].BreakDuration) + parseFloat(bufferArry[j].BreakDuration);
                        bufferArry[i].JobDuration = parseFloat(bufferArry[i].JobDuration) + parseFloat(bufferArry[j].JobDuration);
                        bufferArry[i].EquipmentRunTime = parseFloat(bufferArry[i].EquipmentRunTime) + parseFloat(bufferArry[j].EquipmentRunTime);
                        bufferArry[i].PlannedProdTime = parseFloat(bufferArry[i].PlannedProdTime) + parseFloat(bufferArry[j].PlannedProdTime);
                        bufferArry[i].PlannedDowntimeDuration = parseFloat(bufferArry[i].PlannedDowntimeDuration) + parseFloat(bufferArry[j].PlannedDowntimeDuration);
                        bufferArry[i].AcceptedParts = parseInt(bufferArry[i].AcceptedParts) + parseInt(bufferArry[j].AcceptedParts);
                        bufferArry[i].PartsProduced = parseInt(bufferArry[i].PartsProduced) + parseInt(bufferArry[j].PartsProduced);
                        bufferArry[i].Performance_x_PlannedProdTime = parseFloat(bufferArry[i].Performance_x_PlannedProdTime) + parseFloat(bufferArry[j].Performance_x_PlannedProdTime);
                        bufferArry[i].RejectionReason = [...bufferArry[i].RejectionReason, ...bufferArry[j].RejectionReason];
                        bufferArry[i].Downtime = [...bufferArry[i].Downtime, ...bufferArry[j].Downtime];
                        bufferArry.splice(j, 1);
                        j--;
                    }

                }
            }
            bufferArry.forEach((one) => {
                one.Downtime = aggreagteReasonDowntime(one.Downtime);
            })
            bufferArry.forEach((one) => {
                one.RejectionReason = aggreagteRejectionCount(one.RejectionReason);
            })
            bufferArry = addPeriodicity(bufferArry);
            return bufferArry;
        }
        else if (inputData.Periodicity == 'Shift') {
            bufferArry.forEach((one) => {
                one.FromPeriodicity = one.From
                one.TillPeriodicity = one.Till
                one.Periodicity = one.Shift
                one.Performance_x_PlannedProdTime = (parseFloat(one.Performance) * parseFloat(one.PlannedProdTime))
            })
            for (let i = 0; i < bufferArry.length; i++) {
                for (let j = i + 1; j < bufferArry.length; j++) {
                    if ((bufferArry[i].ShiftOf === bufferArry[j].ShiftOf) && (bufferArry[i].Shift === bufferArry[j].Shift)) {
                        bufferArry[i].Duration += bufferArry[j].Duration;
                        bufferArry[i].IdealSPM_x_EquipmentRunTime = parseFloat(bufferArry[i].IdealSPM_x_EquipmentRunTime) + parseFloat(bufferArry[j].IdealSPM_x_EquipmentRunTime);
                        bufferArry[i].TotalRejection = parseFloat(bufferArry[i].TotalRejection) + parseFloat(bufferArry[j].TotalRejection);
                        bufferArry[i].CyclesRun = parseFloat(bufferArry[i].CyclesRun) + parseFloat(bufferArry[j].CyclesRun);
                        bufferArry[i].TotalDowntimeDuration = parseFloat(bufferArry[i].TotalDowntimeDuration) + parseFloat(bufferArry[j].TotalDowntimeDuration);
                        bufferArry[i].UnplannedDowntimeDuration = parseFloat(bufferArry[i].UnplannedDowntimeDuration) + parseFloat(bufferArry[j].UnplannedDowntimeDuration);
                        bufferArry[i].TargetParts = parseFloat(bufferArry[i].TargetParts) + parseFloat(bufferArry[j].TargetParts);
                        bufferArry[i].BreakDuration = parseFloat(bufferArry[i].BreakDuration) + parseFloat(bufferArry[j].BreakDuration);
                        bufferArry[i].JobDuration = parseFloat(bufferArry[i].JobDuration) + parseFloat(bufferArry[j].JobDuration);
                        bufferArry[i].EquipmentRunTime = parseFloat(bufferArry[i].EquipmentRunTime) + parseFloat(bufferArry[j].EquipmentRunTime);
                        bufferArry[i].PlannedProdTime = parseFloat(bufferArry[i].PlannedProdTime) + parseFloat(bufferArry[j].PlannedProdTime);
                        bufferArry[i].PlannedDowntimeDuration = parseFloat(bufferArry[i].PlannedDowntimeDuration) + parseFloat(bufferArry[j].PlannedDowntimeDuration);
                        bufferArry[i].AcceptedParts = parseInt(bufferArry[i].AcceptedParts) + parseInt(bufferArry[j].AcceptedParts);
                        bufferArry[i].PartsProduced = parseInt(bufferArry[i].PartsProduced) + parseInt(bufferArry[j].PartsProduced);
                        bufferArry[i].Performance_x_PlannedProdTime = parseFloat(bufferArry[i].Performance_x_PlannedProdTime) + parseFloat(bufferArry[j].Performance_x_PlannedProdTime);
                        bufferArry[i].RejectionReason = [...bufferArry[i].RejectionReason, ...bufferArry[j].RejectionReason];
                        bufferArry[i].Downtime = [...bufferArry[i].Downtime, ...bufferArry[j].Downtime];
                        bufferArry.splice(j, 1);
                        j--;
                    }

                }
            }
            bufferArry.forEach((one) => {
                one.Downtime = aggreagteReasonDowntime(one.Downtime);
            })
            bufferArry.forEach((one) => {
                one.RejectionReason = aggreagteRejectionCount(one.RejectionReason);
            })
            bufferArry = addPeriodicity(bufferArry);
            return bufferArry;
        } else {
            return []
        }

    }

    const getPlantWiseOee = (bufferArr) => {
        const schema = [
            ["Date", "Availability","Performance","QualityFactor","OEE"],
        ];
        bufferArr = getPlantWiseAccumlate(bufferArr);
        bufferArr.forEach((one) => {
            let data = []
            let avila = parseFloat(((one.EquipmentRunTime / (parseFloat(one.PlannedProdTime) - parseFloat(one.PlannedDowntimeDuration))) * 100).toPrecision(4));
            let perf = parseFloat((one.Performance_x_PlannedProdTime / parseFloat(one.PlannedProdTime)).toPrecision(4));
            let qf = parseFloat(((one.AcceptedParts / one.PartsProduced) * 100).toPrecision(4))
            let oee = parseFloat(((avila * perf * qf) / 10000).toPrecision(4))
            if (inputData.Periodicity == "Weekly" || inputData.Periodicity == "Daily") {
                data.push(dateTime.getDateFormat(one.TillPeriodicity) + "-" + dateTime.getDateFormat(one.FromPeriodicity));
            }
            if (inputData.Periodicity == "Cummulative") {
                data.push(dateTime.getDateFormat(inputData.From) + "-" + dateTime.getDateFormat(inputData.Till));
            } else {
                data.push(one.Shift + "-" + dateTime.getDateFormat(one.ShiftOf));
            }

            data.push(avila);
            data.push(perf);
            data.push(qf);
            data.push(oee);
            schema.push(data);

        })

        const options = {
            chart: {
                title: "Plant Wise OEE",
            },
            hAxis: {
                title: 'Percentage',
            },

        };
        return <Chart
            chartType="Bar"
            width="100%"
            height="800px"
            data={schema}
            options={options}
        />
    }


    const charts = {
        ComponentWiseDowntimeAnalysis: getComponentWiseDowntime([...data]),
        ComponentWiseAPQE: getAPQEChart([...data]),
        ComponentWiseRejectionAnalysis: getRejectionAnalysis([...data]),
        EquipmentWiseDowntimeAnalysis: getEquipmentWiseDowntime([...data])
    }



    const showChart = (chartType) => {

        return <Modal
            className='detail-modal'
            backdrop={'static'}
            show={showChartState}
            size='xl'
            onHide={() => setShowChartState(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <p> {showChartType.label}</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {charts[chartType]}
            </Modal.Body>
        </Modal>
    }



    return (
        <>
            {/* {showChart(showChartType.name)} */}
            <Modal
                className='detail-modal'
                backdrop={'static'}
                show={state}
                size='xl'
                onHide={onHide}
                // dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className='m-0 p-0' closeButton>
                    <Row style={{ width: '100%' }}>
                        <div className='col-sm-4'>
                            <p>Detailed Production Analysis</p>
                        </div>
                        <div className='col-sm-2 mt-1'>
                            <select value={inputData.ReportType?inputData.ReportType:''} name='ReportType' onChange={handleOnChnage} className='form-control form-control-sm'>
                                <option>--select option--</option>
                                <option value={"ComponentWise"}>Component Wise</option>
                                <option value={"PlantWise"}>Plant Wise</option>
                            </select>
                        </div>

                        <div className='col-sm-2 mt-1'>
                            <span onClick={() => handleDownloadTableToExcel(inputData.ReportType == 'PlantWise' ? "DetailedProductionAnalysisPlant" : "DetailedProductionAnalysisComp")} className='btn btn-sm btn-primary'>Download Table</span>
                        </div>

                        {/* <div className='col-sm-2 mt-1'>
                            <select value={showChartType.name} name='ChartType' onChange={onChange} className='form-control form-control-sm'>
                                <option>--select Chart--</option>
                                <option value={"ComponentWiseDowntimeAnalysis"}>Component Wise Downtime Analysis</option>
                                <option value={"ComponentWiseAPQE"}>Component Wise APQE</option>
                                <option value={"ComponentWiseRejectionAnalysis"}>Component Wise Rejection Analysis</option>
                                <option value={"EquipmentWiseDowntimeAnalysis"}>Equipment Wise Downtime Analysis</option>
                            </select>
                        </div>
                        <div className='col-sm-2 mt-1'>
                            <span onClick={() => setShowChartState(true)} className='btn btn-sm btn-primary'>Generate Chart</span>
                        </div> */}




                    </Row>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <EquipmentSelection  style={{height:'90vh',overflowY:'auto'}} equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />
                        <div className="col-sm-2">
                            <div className="form-group text-center">
                                <label className='small'>From</label>
                                <input value={inputData.From?inputData.From:''} min={fromDateLimit} className={'form-control form-control-sm'} onChange={handleOnChnage} placeholder='dd/mm/yyyy' type='date' name='From' />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="form-group text-center">
                                <label className='small'>Till</label>
                                <input className='form-control form-control-sm' value={inputData.Till?inputData.Till:''} onChange={handleOnChnage} type='date' placeholder='dd/mm/yyyy' name='Till' />
                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="form-group text-center">
                                <label className='small'>Shift</label>
                                <MultiSelectCheckBox name='Shift' onChange={handleOnChnage} selectedValues={inputData['Shift']} options={shiftOption} />
                                {/* {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>} */}
                            </div>
                        </div>
                        <div className="col-sm-1">
                            <div className="form-group text-center">
                                <label value={inputData.Periodicity?inputData.Periodicity:''} className='small'>Periodicity</label>
                                <select onChange={handleOnChnage} name='Periodicity' className='form-control form-control-sm'>
                                    <option>--select report type--</option>
                                    <option value={"Shift"}>Shift Wise</option>
                                    <option value={"Daily"}>Daily</option>
                                    <option value={"Weekly"}>Weekly</option>
                                    <option value={"Cummulative"}>Cummulative</option>
                                </select>
                            </div>
                        </div>
                        <div style={{ marginTop: 28 }} className="col-sm-1">
                            <span onClick={onApply} className='btn-sm btn btn-primary'>Apply</span>
                        </div>
                    </Row>
                    {inputData.ReportType == "ComponentWise" ? <>
                        <Row >
                            <div style={{ overflow: 'scroll' }} className='col-sm-12'>
                                {data.length > 0 ? <DetailedProdAnalysisCompTbl id='DetailedProductionAnalysisComp' inputData={inputData} qualityReason={qualityReason} reasons={reasons} data={getComponentUniqe([...data])} /> : <p></p>}
                            </div>

                        </Row>
                        {data.length > 0 ?
                            <>
                                {/* <Row>
                            <div className='col-sm-6'>
                                {getComponentWiseDowntime([...data])}
                            </div>
                            <div className='col-sm-6'>
                                {getAPQEChart([...data])}
                            </div>
                        </Row>
                            <Row>
                                <div className='col-sm-6'>
                                    {getRejectionAnalysis([...data])}
                                </div>
                                <div className='col-sm-6'>
                                    {getEquipmentWiseDowntime([...data])}
                                </div>
                            </Row> */}

                            </>

                            : <p>Data Not Available </p>}</>

                        :
                        inputData.ReportType == "PlantWise" ?
                            <>
                                <Row>
                                    <div style={{ overflow: 'scroll' }} className='col-sm-12'>
                                        {data.length > 0 ? <DetailedProdAnalysisPlantTbl inputData={inputData} id='DetailedProductionAnalysisPlant' qualityReason={qualityReason} reasons={reasons} data={getPlantWiseAccumlate(data)} /> : <p></p>}
                                    </div>
                                </Row>
                                {/* <Row>
                                    <div style={{ overflow: 'scroll' }} className='col-sm-12'>
                                        {data.length > 0 ? getPlantWiseOee(data) : <p></p>}
                                    </div>
                                </Row> */}

                            </>

                            :
                            <p>Please Select Report</p>}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AnalysisDetailedProduction;