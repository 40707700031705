import React from 'react';
import { useState } from 'react';
import date from '../utill/dateTime';
import api from '../config.json'
import axios from 'axios';
const DetailedProductionTable = ({ id,data,reasons,qualityReason }) => {
    const [compList,setCompList]=useState([]);
    useState(()=>{
        axios.get(api.api1 + "component")
        .then((result)=>{
            setCompList(result.data);
        })
        .catch((err)=>{
        //   // consol(err);
        })
    },[])

    const getRowSpan=(data1)=>{
       // return '3'
       let ReasonArr=[...data1.Downtime]
       let arr=[]
       let returnArr=[]
       for(let i=0;i<ReasonArr.length;i++){
           let arr1=[]
           arr1.push(ReasonArr[i])
           for(let j=i+1;j<ReasonArr.length;j++){
               if(ReasonArr[i].DowntimeReason==ReasonArr[j].DowntimeReason){
                   arr1.push(ReasonArr[j]);
                   ReasonArr.splice(j,1)
                   j--;
               }
           }
           arr.push(arr1);
       }
       arr.forEach(one=>{
           returnArr.push(one.length);
       })
       returnArr.sort(function(a, b) {
        return b-a;
      });
      if(returnArr.length>0){
          return returnArr[0]
      }else{
          return 1;
      }
       
      
    }

    const getDowntimeReason=(downsReason,limit)=>{
        let ReasonArr=[...downsReason]
        let arr=[]
        let returnArr=[]
        for(let i=0;i<ReasonArr.length;i++){
            let arr1=[]
            arr1.push(ReasonArr[i])
            for(let j=i+1;j<ReasonArr.length;j++){
                if(ReasonArr[i].DowntimeReason==ReasonArr[j].DowntimeReason){
                    arr1.push(ReasonArr[j]);
                    ReasonArr.splice(j,1)
                    j--;
                }
            }
            arr.push(arr1);
        }

        for(let j=0;j<limit;j++){
            if(j<limit){
                let arr2=[]
                for(let i=0;i<arr.length;i++){
                    if(arr[i][j]){
                        arr2.push(arr[i][j])
                    }
                }
                returnArr.push(getDowntimeReason1(arr2))
            }
        }
        
        // arr.forEach((one,idx)=>{
         
           
        // })

        return returnArr;
    }
    const getDowntimeReason1 = (ReasonArr) => {
        let returnArr = []
        if (reasons.length == 0) {
            let obj={}
            obj.Duration = null;
            obj.From=null
            obj.Till=null
            obj.Remarks=null
            returnArr.push(obj)
        } else {
            reasons.forEach((one1) => {
                let filterReason = ReasonArr.filter(obj => obj.DowntimeReason == one1.Reason)
                if (filterReason.length > 0) {
                    returnArr.push({ ...filterReason[0] })
                } else {
                    let obj = { }
                    obj.Duration = null;
                    obj.From=null
                    obj.Till=null
                    obj.Remarks=null
                    returnArr.push(obj)
                }
            })
        }

        return returnArr;
    }

    const getQualityReason = (ReasonArr) => {
        let returnArr = []
      
        if (qualityReason.length == 0) {
            returnArr.push({ Reason: 'default', Count: 0 });
        } else {
            qualityReason.forEach((one1) => {
                let filterReason = ReasonArr.filter(obj => obj.Reason == one1.QualityReason)
                if (filterReason.length > 0) {
                    returnArr.push({ ...filterReason[0] })
                } else {
                    let obj = { Reason: one1.QualityReason, Count: 0 }
                    returnArr.push(obj)
                }
            })
        }

        return returnArr;
    }

    const getAliaName=(Component)=>{
        let component=[...compList];
        component=component.filter((one)=>one.ComponentId==Component)
        if(component.length>0){
          return component[0].Alias
        }else{
          return 'N/A'
        }
    }

   
    return (
        <table id={id} className="table table-sm table-bordered  small table-striped " style={{ padding: 0 }}>
            <thead className='small text-center border'>
                <tr className='text-center align-self-center table-primary'>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3' >Date</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Shift</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Equipment Group</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Equipment</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Component</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Component Alias</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Operation</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Tool</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Parts Per Cycle</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Operator</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Customer</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Job From Date</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Job From Time</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Job Till Date</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Job Till Time</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Remarks</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Job Duration (mins)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Break Duration(mins)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Planned Prod Time (mins)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Equipment StartDate</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Equipment StartTime</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Equipment StopDate</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Equipment StopTime</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Cycles Run</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Parts Produced</th>





                    <th style={{ verticalAlign: 'middle' }} colSpan={reasons.length*6}>Downtime Reasons </th>
                  

                    <th style={{ verticalAlign: 'middle' }} rowSpan='3' >Unplanned Downtime Duration</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3' >Planned Downtime Duration</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Total Downtime Duration</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Equipment RunTime</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Ideal SPM</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Average SPM</th>

                    <th style={{ verticalAlign: 'middle' }} colSpan={qualityReason.length}>Quality Reasons</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Total Rejection (no. of units)</th>

                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Availability (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Performance (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>QualityFactor (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>OEE (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Target Parts</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Accepted Parts</th>
                </tr>

                <tr className='table-primary' >

                    {reasons.length > 0 ? reasons.map(one => {
                        return <th  colSpan='6' style={{ verticalAlign: 'middle' }} ><div ><span>{one.Reason}</span></div></th>
                    }) : ''}

                    {qualityReason.length > 0 ? qualityReason.map(one => {
                        return <th   style={{ verticalAlign: 'middle' }} ><div ><span>{one.QualityReason}</span></div></th>
                    }) : ''}

                </tr>
                <tr className='table-primary' >
                    {reasons.length>0?reasons.map(one=>{
                         return <> <th  style={{ verticalAlign: 'middle'}}  >From Date</th>
                          <th  style={{ verticalAlign: 'middle'}} >From Time</th>
                          <th  style={{ verticalAlign: 'middle'}}  >Till Date</th>
                          <th  style={{ verticalAlign: 'middle'}} >Till Time</th>
                          <th  style={{ verticalAlign: 'middle'}}  >Duration (mins)</th>
                          <th  style={{ verticalAlign: 'middle'}}  >Remarks</th></>
                    }):''}
                  
                </tr>
            </thead>
            <tbody>
            {data.map(one => {
                    return <>
                    
                    <tr>
                        <td rowSpan={getRowSpan(one)}>{date.getDateFormat(one.From)}</td>
                        <td rowSpan={getRowSpan(one)}>{one.Shift}</td>
                        <td rowSpan={getRowSpan(one)}>{one.EquipmentGroup}</td>
                        <td rowSpan={getRowSpan(one)}>{one.Equipment}</td>
                        <td rowSpan={getRowSpan(one)}>{one.Component}</td>
                        <td rowSpan={getRowSpan(one)}>{getAliaName(one.Component)}</td>
                        <td rowSpan={getRowSpan(one)}>{one.Operation}</td>
                        <td rowSpan={getRowSpan(one)}>{(one.Tool == 'null' || one.Tool == undefined) ? '-' : one.Tool}</td>
                        <td rowSpan={getRowSpan(one)}>{one.PartsPerCycle}</td>
                        <td rowSpan={getRowSpan(one)}>{one.Operator}</td>
                        <td rowSpan={getRowSpan(one)}>{(one.CustomerName == 'null' || one.CustomerName == null|| one.CustomerName == undefined) ? '-' : one.CustomerName}</td>
                        <td rowSpan={getRowSpan(one)}>{date.getDateFormat(one.JobFrom)}</td>
                        <td rowSpan={getRowSpan(one)}>{date.getTime(one.JobFrom)}</td>
                        <td rowSpan={getRowSpan(one)}>{date.getDateFormat(one.JobTill)}</td>
                        <td rowSpan={getRowSpan(one)}>{date.getTime(one.JobTill)}</td>
                        <td rowSpan={getRowSpan(one)}>{one.JobRemarks}</td>
                        <td rowSpan={getRowSpan(one)}>{one.JobDuration}</td>
                        <td rowSpan={getRowSpan(one)}>{one.BreakDuration}</td>
                        <td rowSpan={getRowSpan(one)}>{one.PlannedProdTime}</td>
                        <td rowSpan={getRowSpan(one)}>{date.getDateFormat(one.EquipmentStartDateTime)}</td>
                        <td rowSpan={getRowSpan(one)}>{date.getTime(one.EquipmentStartDateTime)}</td>
                        <td rowSpan={getRowSpan(one)}>{date.getDateFormat(one.EquipmentStopDateTime)}</td>
                        <td rowSpan={getRowSpan(one)}>{date.getTime(one.EquipmentStopDateTime)}</td>
                        <td rowSpan={getRowSpan(one)}>{one.CyclesRun}</td>
                        <td rowSpan={getRowSpan(one)}>{one.PartsProduced ? one.PartsProduced : '-'}</td>
                        {getDowntimeReason(one.Downtime,getRowSpan(one)).length > 0 ? getDowntimeReason(one.Downtime,getRowSpan(one)).map((o,i) => {
                              if(i==0){
                             return    o.map((r)=>{
                                    return <>
                                    <td>{r.From?date.getDateFormat(r.From):'-'}</td>
                                <td>{r.From?date.getTime(r.From):'-'}</td>
                                <td>{r.Till?date.getDateFormat(r.Till):'-'}</td>
                                <td>{r.Till?date.getTime(r.Till):'-'}</td>
                                <td>{r.Duration?Math.ceil(r.Duration/60):'-'}</td>
                                <td>{r.Remarks?r.Remarks:'-'}</td>
                                </>
                                })
                               
                              }
                           
                        }) : ''}
                        <td rowSpan={getRowSpan(one)}>{one.UnplannedDowntimeDuration ? Math.ceil(one.UnplannedDowntimeDuration/60) : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.PlannedDowntimeDuration ? Math.ceil(one.PlannedDowntimeDuration/60) : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.TotalDowntimeDuration ? Math.ceil(one.TotalDowntimeDuration/60) : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.EquipmentRunTime ? one.EquipmentRunTime : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.IdealSPM ? one.IdealSPM : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.AverageSPM ? one.AverageSPM : '-'}</td>
                        {getQualityReason(one.RejectionReason).length > 0 ? getQualityReason(one.RejectionReason).map(r => {
                            return <td rowSpan={getRowSpan(one)}>{r.Count ? r.Count:'0' }</td>
                        }) : <td rowSpan={getRowSpan(one)}>0</td>}
                        <td rowSpan={getRowSpan(one)}>{one.TotalRejection ? one.TotalRejection : '0'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.Availability ? one.Availability : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.Performance ? one.Performance : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.QualityFactor ? one.QualityFactor : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.OEE ? one.OEE : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.TargetParts ? one.TargetParts : '-'}</td>
                        <td rowSpan={getRowSpan(one)}>{one.AcceptedParts ? one.AcceptedParts : '-'}</td>

                    </tr>
                    
                          {getDowntimeReason(one.Downtime,getRowSpan(one)).length > 0 ? getDowntimeReason(one.Downtime,getRowSpan(one)).map((o,idx) => {
                              if(idx>0){
                                  return <tr>
                                      {o.map((r)=>{
                                    return <>
                                    <td>{r.From?date.getDateFormat(r.From):'-'}</td>
                                <td>{r.From?date.getTime(r.From):'-'}</td>
                                <td>{r.Till?date.getDateFormat(r.Till):'-'}</td>
                                <td>{r.Till?date.getTime(r.Till):'-'}</td>
                                <td>{r.Duration?Math.ceil(r.Duration/60):'-'}</td>
                                <td>{r.Remarks?r.Remarks:'-'}</td>
                                </>
                                  })}

                                  </tr>
                                  
                                
                              }
                           
                        }) : ''}
                    </>
                })}
            </tbody>
        </table>
    );
}

export default DetailedProductionTable;