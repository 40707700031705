import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

const GaugeChart = ({color1,color2,title,data}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // Initialize ECharts instance
    const myChart = echarts.init(chartRef.current);

    // Define the initial chart options
    const initialOption = {
     
        series: [
          
            {
              type: 'gauge',
              center: ['50%', '60%'],
              startAngle: 200,
              endAngle: -20,
              min: 0,
              max: 100,
              splitNumber: 10,
              itemStyle: {
                color: `${color1}`
              },
              progress: {
                show: true,
                width: 30
              },
              pointer: {
                show: false
              },
              axisLine: {
                lineStyle: {
                  width: 30
                }
              },
              axisTick: {
                distance: -40,
                splitNumber: 5,
                lineStyle: {
                  width: 2,
                  color: '#999'
                }
              },
              splitLine: {
                distance: -50,
                length: 14,
                lineStyle: {
                  width: 3,
                  color: '#999'
                }
              },
              axisLabel: {
                distance: -10,
                color: '#999',
                fontSize: 20
              },
              anchor: {
                show: false
              },
              title: {
                text:`${title}`,
                show: true
              },
              detail: {
                valueAnimation: true,
                width: '60%',
                lineHeight: 40,
                borderRadius: 8,
                offsetCenter: [0, '-15%'],
                fontSize: '1.7rem',
                fontWeight: 'bolder',
                formatter:`${title} \n`+ '{value} % OEE',
                color: 'inherit'
              },
              data: [
                {
                  value: data || 0
                }
              ]
            },
            {
              type: 'gauge',
              center: ['50%', '60%'],
              startAngle: 200,
              endAngle: -20,
              min: 0,
              max: 100,
              itemStyle: {
                color: `${color2}`
              },
              progress: {
                show: true,
                width: 8
              },
              pointer: {
                show: false
              },
              axisLine: {
                show: false
              },
              axisTick: {
                show: false
              },
              splitLine: {
                show: false
              },
              axisLabel: {
                show: false
              },
              detail: {
                show: false
              },
              data: [
                {
                  value: data || 0
                }
              ]
            }]
    };
    
    // Set the initial options
    myChart.setOption(initialOption);

    // Set up the interval to update the chart
    const updateChartInterval = setInterval(() => {
      const random = +(Math.random() * 100).toFixed(0);
      myChart.setOption({
        series: [
          {
            data: [
              {
                value: data || 0
              }
            ]
          },
          {
            data: [
              {
                value: data || 0
              }
            ]
          }
        ]
      });
    }, 2000);

    // Cleanup on component unmount
    return () => {
      clearInterval(updateChartInterval);
      myChart.dispose();
    };
  }, [data]); // The empty dependency array ensures the useEffect runs once when the component mounts

  return <> 
  {/* <div ref={chartRef} style={{ width: '70%', height: '350px' }} /> */}
   <div  ref={chartRef} style={{ width: '100%', height: '330px' }} />
 
 
  
  </>;
};

export default GaugeChart;
