import React, { Component } from 'react';
import _ from 'lodash';

class TableBody extends Component {
   renderCell = (item,column,idx) =>{
   if(column.content) return column.content(item,idx);

    return _.get(item,column.path);
   }
  createKey = (item,column)=>{
   
return item._id + (column.path || column._id);
  }
    render() { 
        const {data,columns}=this.props;
        return (<tbody style={{padding:0,backgroundColor:'white'}}>
            {data.map((item,i) => 
            <tr className='text-center'   key={item._id}>  
              {columns.map(column=><td  style={{padding:1,textJustify:'center'}} key={this.createKey(item,column)}>{this.renderCell(item,column,i)}</td>)}  
            </tr>
            )}
        </tbody>  );
    }
}

export default TableBody;
 
