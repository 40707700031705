import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";
import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import pako from "pako"
import EquipmentSelection from "../Common/equipmentSelection";
import { ToastContainer, toast } from 'react-toastify';



function DailyProductionSummary({equipGroups,reasons,fromDateLimit,storeDataInLocalStorage}) {
const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);
  const [periodOption, setperiodOption] = useState([
    { label: "Shift", id: "1" },
    { label: "Daily", id: "2" },
    { label: "Weekly", id: "3" },
    { label: "Monthly", id: "4" },
    
  ]);

  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  const [componentdata, setcomponentdata] = useState([]);
  const [downtimereasongroup, setdowntimereasongroup] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [selecetddowntimegroup, setselecetddowntimegroup] = useState([]);

  const [selectedItemLengths, setSelectedItemLengths] = useState({});
  
  const [operatordata, setoperatordata] = useState([]);
  const [tooldata, settooldata] = useState([]);
  const [operationdata, setoperationdata] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [AvailableHrs, setAvailableHrs] = useState([]);
  const [selectedequipgroups, setselectedequipgroups] = useState([]);

  const [selectedDates, setSelectedDates] = useState({
    From: "",
    Till: "",
  });

  const [pieDateTime, setpieDateTime] = useState([]);

 
  const [columns, setcolumn] = useState([]);

  const [sortColumn, setSortColumn] = useState({ path: "Date", order: "asc" });
  const [Reasonlength, setReasonlength] = useState([]);
  const [columns1, setcolumns1] = useState([]);
  const [columns2, setcolumns2] = useState([]);

  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [column1, setcolumn1] = useState([])

  
  const [indexofpie, setindexofpie] = useState(null);
  const DowntimeReasonGroup ={
    heading: "ReasonGroup",
    label: "tablecolumns",
    initialItems: downtimereasongroup,
    selectedItemIds: selectedItemIds,
    name: "ReasonGroup",
    enableLocalStorage: false,
    multiSelect: true,
    searchbar: true,
  }


 const downtime ={
    heading: "DowntimeReason",
    label: "tablecolumns",
    initialItems: dynamicData,
    selectedItemIds: selectedItemIds,
    name: "DowntimeReason",
    enableLocalStorage: false,
    multiSelect: true,
    searchbar: true,
  }








  const setSelection = (key, option, equips) => {
    if (key == 'EquipmentGroup') {
        SetEquips(equips ? equips : []);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if (key == 'Equipment') {

        setSelectedEquipOption(option)
    }
}


  const schema = {
    Equipment: joi.array().required(),
    Component: joi.array().required(),
    Operator: joi.array().required(),
    Shift: joi.array().required(),
    Tool: joi.array().required(),
    Periodicity: joi.array().required(),
    DowntimeReason: joi.array().required(),
    Operation: joi.array().required(),
    Refrence: joi.array().required(),
    ReasonGroup:joi.array().required(),
  };


useEffect(() => {
 let filterdata = selecetddowntimegroup.map(item=> reasons.filter(reason=>reason.ReasonGroup === item))
  filterdata = _.flattenDeep(filterdata)
  if(filterdata?.length > 0) {
    const downtimelist = filterdata
    .map((reason, index) => ({
      id: `${index}`,
      label: reason.Reason,
    }))
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label));


    setDynamicData(downtimelist);
  }else{
      setDynamicData([])
     }


}, [selecetddowntimegroup.length])

  

const handleSort1 = (sortColumn) => {
  // if (columns1.length > 0) {
    const sorted = _.orderBy(columns1, [sortColumn.path], [sortColumn.order]);
    setcolumns1(sorted);
    setSortColumn(sortColumn);
  // }
};
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      setQuerySuccess(true);

      try {
        // const downtimedata = await axios.get(api.api + "downtimereason");
        // const componentdata = await axios.get(api.api1 + "component");
        // const operatordata = await axios.get(api.api1 + "operator");
        // const tooldata = await axios.get(api.api + "tool");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

 let componentdata = await storeDataInLocalStorage('component')

 let operatordata = await storeDataInLocalStorage('operator')

 let tooldata = await storeDataInLocalStorage('tool')

 let equipmentdata = await storeDataInLocalStorage('equipment')

        // let equipmentdata = await axios.get(api.api1 + "equipment");
        // const Equipgrouplist =  equipGroups.map((reason, index) => ({
        //   id: `${index}`,
        //   label: reason.EquipmentGroup,
        // }))
        // .slice()
        // .sort((a, b) => a.label.localeCompare(b.label));
        const downtimelist = downtimedata
          .map((reason, index) => ({
            id: `${index}`,
            label: reason.Reason,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        const complist = componentdata
          .map((componentList, index) => ({
            id: `${index}`,
            label: componentList.ComponentId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        // Iterate through the data and extract operation IDs
        const operationIds = [];
        componentdata.forEach((item) => {
          item.Operation.forEach((operation) => {
            operationIds.push(operation.OperationID);
          });
        });
        // Sort and remove duplicates from operationIds
        const uniqueSortedOperations = [...new Set(operationIds)]
          .sort((a, b) => a - b)
          .map((operation, index) => ({ id: index, label: operation }));
        const operatorlist = operatordata
          .map((operator, index) => ({
            id: `${index}`,
            label: operator.Name,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        const toollist = tooldata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.ToolId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        
          // let uydgschbsch
          // if(selectedequipgroups?.length > 0) {
          //   uydgschbsch  =   selectedequipgroups.map(item =>{
          //  return equipmentdata.filter(one=>one.EquipmentGroup === item) 

          // })}
 
          // const flatArray = _.flatten(uydgschbsch);
        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        
          setequpdata(equiplist);
     
         
          const DowntimeColors =downtimedata.map((index)=>([index.Reason,index.Color]));

          const uniqueReasonGroups = {};

// Filter the array to remove duplicates based on ReasonGroup
const uniqueData = downtimedata.filter((obj) => {
  const reasonGroup = obj.ReasonGroup;

  if (!uniqueReasonGroups[reasonGroup]) {
    // If this ReasonGroup is not in the uniqueReasonGroups object, mark it as seen and return true to keep it in the result
    uniqueReasonGroups[reasonGroup] = true;
    return true;
  }

  // If the ReasonGroup is already in the uniqueReasonGroups object, return false to filter it out
  return false;
});

          const DowntimeReasonGroup = uniqueData
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.ReasonGroup,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        //   setcolor(DowntimeColors)
        setoperationdata(uniqueSortedOperations);
        settooldata(toollist);
        setoperatordata(operatorlist.sort((a, b) => a[1] - b[1]));
       
        setcomponentdata(complist);
        setdowntimereasongroup(DowntimeReasonGroup)
        // console.log("fetching data",uniqueData,downtimelist);

      } catch (error) {
        // console.error("Error fetching data:", error);
      }
      setQuerySuccess(false);

    };

    fetchData();
  }, [equipGroups,selectedequipgroups]); // Runs once when the component mounts

  const handleSelectedItemsChange = (newSelectedItems, name,totallength) => {
    setSelectedItemIds(newSelectedItems);
if(name==='ReasonGroup'){
  setselecetddowntimegroup(newSelectedItems)

}
    const validHeadings = [
      "Equipment",
      // "Component",
      // "Operation",
      // "Operator",
      "Shift",
      // "Tool",
      "Periodicity",
      "DowntimeReason",
      "EquipmentGroup",
      "ReasonGroup"
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    if(name == 'DowntimeReason'){
      inputData[name] = newSelectedItems
    }else{
      inputData[name] = newSelectedItems.length == totallength?["all"]:newSelectedItems
    }
    if(name == 'EquipmentGroup'){
      setselectedequipgroups(newSelectedItems)
    }


    // Now you have access to both newSelectedItems and heading
  //  console.log(newSelectedItems.length == totallength?"All":"Noob","totallength")
    // console.log("Heading:", name, "inputData :", inputData);
    setsenddata(inputData);
  };






 

  const handleSort = (sortColumn) => {
    if (columns.length > 0) {
      const sorted = _.orderBy(columns, [sortColumn.path], [sortColumn.order]);
      setcolumn(sorted);
      setSortColumn(sortColumn);
    }
  };


  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };
  // console.log(selectedDates, "date to be send");

  
  function compareDates(dateRangeA, dateRangeB) {
    const startDateA = new Date(dateRangeA[0].split(' - ')[0]);
    const startDateB = new Date(dateRangeB[0].split(' - ')[0]);
    return startDateA - startDateB;
  }
  let handleOnSubmit =async () => {
    let inputData = { ...senddata };
    inputData['From'] = selectedDates.From
    inputData['Till'] = selectedDates.Till
    setQuerySuccess(true);

    const results =  axios.put(api.api + 'dailyProductionSummaryReport', inputData).then(result =>{
        if(result.data?.length > 0) {




         let columndata =[{ path: "From", label: "Weekly",content:(obj)=><label>{ moment(obj.From).format('DD MMMM YYYY')+' - '+ moment(obj.Till).format('DD MMMM YYYY')}</label> },
                   
         { path: "DowntimeReason", label: "DowntimeReason" },
         { path: "Duration", label: "DownTime Duration (min)",content:(obj)=><label>{Number(obj.Duration)}</label> },
         { path: "Incidents", label: "Incidents (count)" },
         { path: "AverageDuration", label: "Average Duration (min)" ,content:(obj)=><label>{Number(obj.AverageDuration)}</label>},]
         result.data[0].DowntimeSummary.map(item=>columndata.push({path:item.DowntimeReason,label:item.DowntimeReason}))
          setcolumn1(columndata)



setReasonlength(result.data[0].DowntimeSummary)
            setInputData(result.data)
         
            let formattedArray = [];

            // Iterate over each object in the input array
            result.data.forEach(data => {
                // Initialize an object to hold the formatted data for the current item
                let formattedItem = {
                    Achieved: data.Achieved,
                    Availability: data.Availability,
                    AvailableHrs: data.AvailableHrs,
                    ShiftOf: data.ShiftOf,
                    NoOfToolsChange:data.NoOfToolsChange,
NumberOfJobs: data.NumberOfJobs,
OEE: data.OEE,
PartsProduced: data.PartsProduced,
Performance: data.Performance,
QualityFactor: data.QualityFactor,
TotalDowntime:data.TotalDowntime,
UsedHrs: data.UsedHrs,
UsedHrsTarget:data.UsedHrsTarget,
                };
        
                // Iterate over each item in the DowntimeSummary array of the current object
                data.DowntimeSummary.forEach(item => {
                    // Add the downtime reason and duration to the formatted item object
                    formattedItem[item.DowntimeReason] = item.Duration;
                });
        
                // Push the formatted item object to the formatted array
                formattedArray.push(formattedItem);
            });


         let AvailableHrs=   formattedArray.reduce((total, one) => {
                const availability = one?.AvailableHrs ? parseFloat(one.AvailableHrs) : 0;
                return total + availability;
                }, 0)
                setAvailableHrs(AvailableHrs)
            setcolumns1(formattedArray)
            // Return the formatted array
        // const newArray = result.data.map((item) => {
        //   const { Date, From,ShiftName, ShiftStart,Periodicity, Till, Data } = item;
        //  Data.map((element) => {
        //     // Modify the element or perform any operations as needed
        //     // For example, adding Date, From, Periodicity, Till to each element
        //     structuredArray.push({
        //       Date,
        //       ShiftName,
        //       From,
        //       Periodicity,
        //       ShiftStart,
        //       Till,
        //       ...element,
        //     });
        //   });
        
         
        // });
 
       

// console.log(structuredArray,"sovxkmvx",inputData.Periodicity)

  } else {
    swal("Data Not Available");
  }

  setQuerySuccess(false);
}).catch((err) => {
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      // console.log(err);
      setQuerySuccess(false);
    });
                  
                
  };
 
  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };


  return (
    <div className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
         Daily Production Summary
        </h4>
      </div>
       <ToastContainer />
      <div className="row mx-2">
      {componentdata.length > 0  &&  (<>

     
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
      // min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={"form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              // max={TillDateLimit}
            />
          </div>
        </div>
        <div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div> </>
        )}
        
       
         {columns1?.length > 0 && <div className="rounded shadow my-4 " style={{width:'100%'}}>
<div className="d-flex justify-content-start align-items-center  ">
<div className="uiverse mx-2" onClick={() =>
   handleDownloadExcel("daily-production-summary")}>
 <span className="tooltip">Dowload Excel File</span>
 <span>
 Download
 </span>
</div>

{/* 
<span className="border  rounded-4 py-2 px-3 gradient-background mx-2 text-white"  ><h4>Date : {moment(item.Date).format('DD/MM/YY')}</h4></span>
            <span className="border  rounded-4 py-2 px-3 gradient-background mx-2 text-white"  ><h4>Periodicity : {item.Periodicity}</h4></span> */}
</div>
                       
 <div  style={{ overflowX: 'auto' }}>
 <div className="rounded shadow my-4 " style={{width:'100%',overflowX:'scroll'}}>

<table id={'daily-production-summary'} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0 }}>
<thead className='small text-center border'>

<tr className='text-center align-self-center table-primary'>
<th style={{ verticalAlign: 'middle' }} >{'Summary'}</th>
{ columns1.map(item=><th style={{ verticalAlign: 'middle' }} >{(item.ShiftOf).split('T')[0]}</th>)}

<th style={{ verticalAlign: 'middle' }} >{'Till date'}</th>
<th style={{ verticalAlign: 'middle' }} >{'loss (%)'}</th>


</tr>

</thead>
<tbody>

<tr  className='text-center' >
<td style={{ verticalAlign: 'middle' }}>{'Achieved'}</td>

{ columns1.map((one,index)=><td style={{ verticalAlign: 'middle' }}>{one.Achieved}</td>)}
<td>{
(columns1.reduce((total, one) => {
const availability = one?.Achieved ? parseFloat(one.Achieved) : 0;
return total + availability;
}, 0)).toFixed(1)
}</td>

</tr>
<tr  className='text-center' >
<td style={{ verticalAlign: 'middle' }}>{'AvailableHrs'}</td>

{ columns1.map((one,index)=><td style={{ verticalAlign: 'middle' }}>{one.AvailableHrs}</td>)}
<td>{
(columns1.reduce((total, one) => {
const availability = one?.AvailableHrs ? parseFloat(one.AvailableHrs) : 0;
return total + availability;
}, 0)).toFixed(1)
}</td>

</tr>
<tr  className='text-center' >
<td style={{ verticalAlign: 'middle' }}>{'Availability'}</td>

{ columns1.map((one,index)=><td style={{ verticalAlign: 'middle' }}>{one.Availability}</td>)}
<td>{
(columns1.reduce((total, one) => {
const availability = one?.Availability ? parseFloat(one.Availability) : 0;
return total + availability;
}, 0)).toFixed(1)
}</td>
</tr>
<tr  className='text-center' >
<td style={{ verticalAlign: 'middle' }}>{'UsedHrs'}</td>

{ columns1.map((one,index)=><td style={{ verticalAlign: 'middle' }}>{one.UsedHrs}</td>)}
<td>{
(columns1.reduce((total, one) => {
const availability = one?.UsedHrs ? parseFloat(one.UsedHrs) : 0;
return total + availability;
}, 0)).toFixed(1)
}</td>
</tr>
<tr  className='text-center' >
<td style={{ verticalAlign: 'middle' }}>{'UsedHrsTarget'}</td>

{ columns1.map((one,index)=><td style={{ verticalAlign: 'middle' }}>{one.UsedHrsTarget}</td>)}
<td>{
(columns1.reduce((total, one) => {
const availability = one?.UsedHrsTarget ? parseFloat(one.UsedHrsTarget) : 0;
return total + availability;
}, 0)).toFixed(1)
}</td> 
</tr>
<tr  className='text-center' >
<td style={{ verticalAlign: 'middle' }}>{'Performance'}</td>

{ columns1.map((one,index)=><td style={{ verticalAlign: 'middle' }}>{one.Performance}</td>)}
<td>{
(columns1.reduce((total, one) => {
const availability = one?.Performance ? parseFloat(one.Performance) : 0;
return (total + availability)/columns1.length;
}, 0)).toFixed(1)
}</td> 
</tr>
<tr  className='text-center' >
<td style={{ verticalAlign: 'middle' }}>{'OEE'}</td>

{ columns1.map((one,index)=><td style={{ verticalAlign: 'middle' }}>{one.OEE}</td>)}
<td>{
(columns1.reduce((total, one) => {
const availability = one?.OEE ? parseFloat(one.OEE) : 0;
return (total + availability)/columns1.length;
}, 0)).toFixed(1)
}</td> 
</tr>
<tr  className='text-center' >
<td style={{ verticalAlign: 'middle' }}>{'TotalDowntime'}</td>

{ columns1.map((one,index)=><td style={{ verticalAlign: 'middle' }}>{one.TotalDowntime}</td>)}
<td>{
(columns1.reduce((total, one) => {
const availability = one?.TotalDowntime ? parseFloat(one.TotalDowntime) : 0;
return total + availability;
}, 0)).toFixed(1)
}</td> 
</tr>
<tr  className='text-center' >
<td style={{ verticalAlign: 'middle' }}>{'NumberOfJobs'}</td>

{ columns1.map((one,index)=><td style={{ verticalAlign: 'middle' }}>{one.NumberOfJobs}</td>)}
<td>{
(columns1.reduce((total, one) => {
const availability = one?.NumberOfJobs ? parseFloat(one.NumberOfJobs) : 0;
return total + availability;
}, 0)).toFixed(1)
}</td>  
</tr>
{Reasonlength.map((item, index) => (
<tr key={index} className='text-center'>
<td style={{ verticalAlign: 'middle' }}>{item.DowntimeReason}</td>

{columns1.map((one, index) => (
<td key={index} style={{ verticalAlign: 'middle' }}>
{one[item.DowntimeReason]||'-'}
</td>
))}

<td>
{(columns1.reduce((total, one1) => {
const availability = one1[item.DowntimeReason] ? parseFloat(one1[item.DowntimeReason]) : 0;
return Number(total + availability);
}, 0)).toFixed(1)}

</td>
{/* <td>
  { (columns1.reduce((total, one1) => {
      const availability = one1[item.DowntimeReason] ? parseFloat(one1[item.DowntimeReason]) : 0;
      return total + availability;
    }, 0) * 100).toFixed(2) }
</td> */}
<td>
  { ((columns1.reduce((total, one1) => {
      const availability = one1[item.DowntimeReason] ? parseFloat(one1[item.DowntimeReason]) : 0;
      return total + availability;
    }, 0) /Number(AvailableHrs)) * 100).toFixed(2) }
</td>

{/* //  (Math.round(total + availability)/Number(AvailableHrs))*100; */}
</tr>
))}

</tbody>
</table>
</div> 
     </div>   
  
         

           </div>} 
          
  
           </div>     

    </div>
  );
}

export default DailyProductionSummary;



