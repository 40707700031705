import React, { Component } from "react";
import Table from "../../../Common/table";
import api from '../../../config';
import axios from '../../../httpServices/httpService';
import  swal  from 'sweetalert';
import _ from 'lodash';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../utill/LoadingComponent";
import dateTime from "../../../utill/dateTime";
import { ToastContainer, toast } from 'react-toastify';

class ConsumableView extends Component {
  state = {
    consumableList:[],
    action:'',
    inputData:{},
    update:false,
    querySuccess:false,
    oldObj:{},
    newObj:{},
    sortColumn:{path:'EquipmentGroup',order:'asc'}
  };


  handleSort = sortColumn => {
    // consol(sortColumn);
    if(this.state.consumableList.length>0)
    {
        const sorted = _.orderBy(this.state.consumableList, [sortColumn.path], [sortColumn.order]);
        this.setState({ sortColumn,consumableList:sorted });
    }
}
  handleOnChange=(e)=>{
    const {inputData}={...this.state};
    inputData[e.currentTarget.name]=new Date(e.currentTarget.value);
    this.setState({inputData});
   }
   handleOnSubmit=(inputData)=>{
     this.setState({querySuccess:true})
      axios.put(api.api+'GetAllConsumableSelected',inputData)
      .then((result)=>{
        // consol(result.data)
       this.setState({consumableList:result.data,querySuccess:false});
      })
      .catch((err)=>{
        this.setState({querySuccess:false})
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
   }
   handleOnChangeUpdate=(e)=>{
    const{oldObj}={...this.state};
    oldObj[e.currentTarget.name]=e.currentTarget.value;
    this.setState({newObj:oldObj});
    // consol(oldObj)
    
    }
 
  handleOnUpdate=(obj)=>{
    this.setState({update:true,oldObj:obj})

  }
  handleOnRowSubmit=(obj)=>{
   
  }



  columns = [
    { path: 'EquipmentGroup', label: 'EquipmentGroup',content:(obj)=><React.Fragment>
   {this.state.update? <div className='form-group ' >
      <input type='text'  name='Operator' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.Operator} className='form-control form-control-sm'/>
    </div>:<label>{obj.EquipmentGroup}</label>}
  </React.Fragment>   },
    { path: 'Equipment', label: 'Equipment',content:(obj)=><React.Fragment>
    {this.state.update?<div className='form-group ' >
      <input type='text'  name='EquipmentType' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.EquipmentType} className='form-control form-control-sm'/>
    </div>:<label>{obj.Equipment}</label>}
  </React.Fragment>   },
    { path: 'LoadedAt', label: 'LoadedAt' ,content:(obj)=><React.Fragment>
    {this.state.update?<div className='form-group ' >
      <input type='text'  name='Date' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.Date} className='form-control form-control-sm'/>
    </div>:<label>{dateTime.getStringOfDateTime(obj.LoadedAt)}</label>}
  </React.Fragment>  },
   
    { path: 'ConsumableGroup', label: 'ConsumableGroup' ,content:(obj)=><React.Fragment>
    {this.state.update?<div className='form-group ' >
      <input type='text'  name='ConsumableGroup' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.ConsumableGroup} className='form-control form-control-sm'/>
    </div>:<label>{obj.ConsumableGroup}</label>}
  </React.Fragment> 

//     content: () => <React.Fragment>


// <div className='form-group'>
//   <select className='form-control form-control-sm custom-select' name='Reason' onChange={this.handleOnChange}>
//   <option>--select reason--</option>
//   {this.state.downTimeList.map(one=>{
//    return  one.Reason.map(reason=>{ 
//       return  <option>{reason}</option>
//     })
//   })}
//   </select>
// </div>
//     </React.Fragment>
    
},
    { path: 'Consumable', label: 'Consumable' ,content:(obj)=><React.Fragment>
    {this.state.update?<div className='form-group ' >
      <input type='text'  name='Consumable' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.Consumable} className='form-control form-control-sm'/>
    </div>:<label>{obj.Consumable}</label>}
  </React.Fragment>  },
    { path: 'LoadedQty', label: 'Qty.' ,content:(obj)=><React.Fragment>
    {this.state.update?<div className='form-group ' >
      <input type='text'  name='Quantity' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.Quantity} className='form-control form-control-sm'/>
    </div>:<label>{obj.LoadedQty}</label>}
  </React.Fragment>  },
    { key: 'Update',content: (obj) => <React.Fragment>
     {!this.state.update?
      <button className='btn btn-sm btn-primary '  onClick={()=>{this.props.handleOnUpdate('dataEntryConsumableData',obj,'/dataentry/consumable','update')}} ><FontAwesomeIcon icon={faEdit} color={'yellow'}/></button>:
      <button className='btn btn-sm btn-primary '  onClick={()=>{this.handleOnRowSubmit(obj)}} ><FontAwesomeIcon icon={faPaperPlane} color={'yellow'}/></button>
    }
    </React.Fragment> },    
]

  
  render() {
    return (
      <React.Fragment>     <ToastContainer/>
      <div className='text-center'>
      <p>Consumable View</p>
      </div>
      {this.state.querySuccess?<Loading/>:''}
      <div className="row">
      <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
           <input className='form-control form-control-sm' onChange={this.handleOnChange} type='date' name='From'/>
          </div>
        </div>
      <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' onChange={this.handleOnChange} type='date' name='Till' />
          </div>
        </div>
        <div className="col-sm-1">
         <button className='btn btn-primary btn-sm mt-4' onClick={this.handleOnSubmit}>OK</button>
        </div>
      
       
        
      </div>
      <div>
      {this.state.consumableList.length < 0 ? "Data Not Available" : <Table columns={this.columns} sortColumn={this.state.sortColumn} onSort={this.handleSort} data={this.state.consumableList} />}
      </div>
      </React.Fragment>
    );
  }
}

export default ConsumableView;
