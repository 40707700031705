import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";

import Table from "../Common/table";
import _, { isNumber, parseInt } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import object from "joi/lib/types/object";
import { th } from "date-fns/locale";
import { ToastContainer, toast } from 'react-toastify';

const Refrence = [
  { label: "Equipment", id: "1" },
  { label: "Component", id: "2" },
  { label: "Operator", id: "3" },
  { label: "Tool", id: "4" },
  { label: "Operation", id: "5" },
  { label: "Time", id: "6" },
  { label: "Stage Reasons", id: "7" },
  { label: "Stage", id: "8" },
];

function McWiseData({fromDateLimit,storeDataInLocalStorage}) {
  const [periodOption, setperiodOption] = useState([
    { label: "Shift", id: "1" },
    { label: "Daily", id: "2" },
    { label: "Weekly", id: "3" },
    { label: "Monthly", id: "4" },
    { label: "Cummulative", id: "5" },
  ]);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [datacount, setdatacount] = useState('');
  const [coumnvalues, setcoumnvalues] = useState([]);

  const [selectedItemLengths, setSelectedItemLengths] = useState({});
  
  const [selectedDates, setSelectedDates] = useState({
    From: "",Till:"",
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState([]);

  const [dfdate, sfdate] = useState([]);



  // Your existing chart code goes here

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    {
      heading: "Equipment",
      label: "Equipment",
      initialItems: equpdata,
      selectedItemIds: selectedItemIds,
      name: "Equipment",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    },
  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////

  const column2 = [
    { path: "Time", label: "Time" },
    // { path: "Equipment", label: "Equipment" },
    // { path: "EquipmentGroup", label: "EquipmentGroup" },
    { path: "PartName", label: "PartName" },
    { path: "ProcessName", label: "ProcessName" },
    { path: "TargetParts", label: "TargetParts" },
    { path: "QuantityProduced", label: "QuantityProduced" },
    { path: "Rejected", label: "Rejected" },
    { path: "TotalDownTime", label: "TotalDownTime" },

    // { path: "Shift", label: "Shift" },

  ];

  const schema = {
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
    Equips: joi.array().required().label("Equipment"),
    Report:joi.required().label("Report"),
   
  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
      setQuerySuccess(true);

        // const equipmentdata = await axios.get(api.api1 + "equipment");
    
        // const downtimedata = await axios.get(api.api + "downtimereason");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

        let equipmentdata = await storeDataInLocalStorage('equipment')
        const downtimelist = downtimedata
        .map((reason, index) => ({
          id: `${index}`,
          label: reason.Reason,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));
        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

          
        setDynamicData(downtimelist);
        setequpdata(equiplist);
        setEquipmentDatalist(equipmentdata)
        // consol("fetching data", equipmentdata);
        
      } catch (error) {
        // console.error("Error fetching data:", error);
      setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts

  const getQualityReason = (ReasonArr) => {
    let returnArr = []
    if (dynamicData.length == 0) {
        returnArr.push({ DowntimeReasons: 'default', Duration: 0 });
    } else {
      dynamicData.forEach((one1) => {
            let filterReason = ReasonArr.filter(obj => obj?.DowntimeReasons == one1.label)
            if (filterReason.length > 0) {
                returnArr.push({ ...filterReason[0] })
            } else {
                let obj = { DowntimeReasons: one1.label, Duration: 0 }
                returnArr.push(obj)
            }
        })
    }

    return returnArr;
}


  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
      "Equipment",
   
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Equipment") {

     let noobdata  = [];
      newSelectedItems.forEach((item) => {
      let noob = EquipmentDatalist.filter(equipment => equipment.EquipmentID === item);
      noobdata.push(noob[0])
      inputData['Equips'] = noobdata
    })
    } else {
      inputData[name] = newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

    // consol(selectedDates,"current date")
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
    const dateTime = { ...selectedDates };
       if(new Date(dateTime.From) <= new Date(dateTime.Till)){
      inputData.From = dateTime.From;
      inputData.Till = dateTime.Till;
    }else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    inputData.Report= "McWiseData"

    const errors = validate.validate(inputData, schema);
  
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true);
   try {
          const result = await axios.post(api.api + '/DailyDetailedProductionData', inputData);
          const equipResponse = result.data;
          const groupedData = {};

          for (const item of equipResponse) {
              const equipment = item.Equipment;
          
              if (!groupedData[equipment]) {
                  groupedData[equipment] = {};
              }
          
              const key = item.shiftOf;
              if (!groupedData[equipment][key]) {
                  groupedData[equipment][key] = [];
              }
          
              groupedData[equipment][key].push(item);
          }
          let uniqueShiftOfSet = new Set();

          // Filter the array based on unique shiftOf values
          let filteredArray = equipResponse.filter((obj) => {
            if (!uniqueShiftOfSet.has(obj.shiftOf.split('T')[0])) {
              uniqueShiftOfSet.add(obj.shiftOf.split('T')[0]);
              return true;
            }
            return false;
          });
          
        setcoumnvalues(filteredArray)
  // consol(equipResponse,"received data",groupedData)
  setcolumns2(groupedData)
  setQuerySuccess(false);
        } catch (error) {
          swal(`${error}`);
          // consol(error);
          setQuerySuccess(false);
        }
      

   
  };

  
  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };


 // consol(datacount,"index number of data")
  return (
    <div  className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
 Machine Wise Report
        </h4>
      </div>
      <ToastContainer/>
      <div className="row mx-3">
       {equpdata.length > 0 ?  <>
       <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
              />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              />
          </div>
        </div>

        {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  {/* {componentdata.length > 0 && ( */}
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}

                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar}
                    />
                  {/* )} */}
                  {errors[data.heading] && (
                    <p className="text-danger" style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}

<div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div>

 </>:""}           
    </div>
   {Object.values(coumnvalues).length > 0?

<div className="rounded shadow my-4 " style={{width:'100%',overflowX:'scroll'}} >
<div className="d-flex justify-content-start align-items-center  ">
<div className="uiverse mx-2" onClick={() =>
   handleDownloadExcel("machine_wise_report")}>
 <span className="tooltip">Dowload Excel File</span>
 <span>
 Download
 </span>
</div>

</div>

<table id='machine_wise_report' className="table table-sm table-hover small  table-striped ">
        <thead  className='small text-center border'  >
        <tr  className='table-primary  '>
        <th className="border" colSpan={1} ></th>
        {coumnvalues.map((column, index) => {return(
  <th key={index} className="border" colSpan={8}>{` ${moment(column.shiftOf).format('DD-MM-YYYY ') }`}</th>
)})}

          </tr>
          <tr  className='table-primary  '>
            <th className="border" rowSpan={2} >Equipment</th>
            {coumnvalues.map((column, index) => {return( <>
  <th key={index} className="border" colSpan={4}>{'shift A'}</th> <th key={index} className="border" colSpan={4}>{'shift B'}</th></>
)})}
              
          </tr>
         
          <tr  className='table-primary '> {Object.values(coumnvalues).map((column)  => (<>
              <th>{'Production'}</th>   <th>{'AvailableTime (mins)'}</th>  <th>{'BreakdownTime (mins)'} </th> <th>{'SPM'}</th> <th>{'Production'}</th>   <th>{'AvailableTime (mins)'}</th>  <th>{'BreakdownTime (mins)'} </th> <th>{'SPM'}</th></>
            ))}</tr>
        </thead>
        <tbody>
        {Object.entries(columns2).map(([equipment, dates]) => ( <>
       
  <tr key={equipment} className='text-center'>
    <td>{equipment}</td>
    {Object.entries(dates).map(([date, values], dateIndex) => (
  <React.Fragment key={`${equipment}-${dateIndex}`}>
   
    <td>{values.find(val => val.Shift === 'Shift1')?.Production || '-'}</td>
    <td>{values.find(val => val.Shift === 'Shift1')?.AvailableTime || '-'}</td>
    <td>{values.find(val => val.Shift === 'Shift1')?.BreakdownTime || '-'}</td>
    <td>{values.find(val => val.Shift === 'Shift1')?.SPH || '-'}</td>
    {/* Render for shift2 */}
    <td>{values.find(val => val.Shift === 'Shift2')?.Production || '-'}</td>
    <td>{values.find(val => val.Shift === 'Shift2')?.AvailableTime || '-'}</td>
    <td>{values.find(val => val.Shift === 'Shift2')?.BreakdownTime || '-'}</td>
    <td>{values.find(val => val.Shift === 'Shift2')?.SPH || '-'}</td>
  </React.Fragment>
))}
  </tr>



  </>))}

</tbody>

      </table>
 
        </div> :''} 

    </div>
  );
}

export default McWiseData;
