import React, { Component } from "react";
import swal from "sweetalert";
import endPoint from "../../../config";
import axios from "../../../httpServices/httpService";
import Loading from "../../../utill/LoadingComponent";
import Select from 'react-select';
import joi from 'joi';
import dateTime from '../../../utill/dateTime';
import valid from '../../../utill/validation';

class ShiftSetting extends Component {
  state = {
    shiftSetting: {
      Shift1Enable: false,
      Shift2Enable: false,
      Shift3Enable: false,
      Shift4Enable: false,
      Shift1Breaks: [],
      Shift2Breaks: [],
      Shift3Breaks: [],
      Shift4Breaks: [],
      MidNightShift: 0,
      WeeklyHolidays:[]

    },
    Shift1Enable: 0,
    Shift2Enable: 0,
    Shift3Enable: 0,
    Shift4Enable: 0,

    MidNightShift: 0,

    oldObj: {},
    from: "",
    till: "",
    querySuccess: false,
    errors: {}
  };

  options = [
    { value: 0, label: 'Sunday' },
    { value: 1, label: 'Monday' },
    { value: 2, label: 'Tuesday' },
    { value: 3, label: 'Whenesday' },
    { value: 4, label: 'Thursday' },
    { value: 5, label: 'Friday' },
    { value: 6, label: 'Saturday' },
  ];

  componentDidMount() {
    // // consol(this.props.shiftSettingData)
    // // consol(this.props.actionStatus)
    if (this.props.actionStatus === "add") {
      this.setState({
        shiftSetting: {
          Shift1Enable: false,
          Shift2Enable: false,
          Shift3Enable: false,
          Shift4Enable: false,
          Shift1Breaks: [],
          Shift2Breaks: [],
          Shift3Breaks: [],
          Shift4Breaks: [],
          MidNightShift: 0,
        }
      });
    }
    if (this.props.actionStatus === "update") {
      swal("only update shift from or till time if you want to update shift start,end and break time you create new shift!");
      const shiftSetting = { ...this.props.shiftSettingData };
      let arr = []
      shiftSetting.WeeklyHolidays.forEach((one) => {
        let obj = {}
        obj.label = one;
        obj.value = one;
        arr.push(obj)
      })
      shiftSetting.WeeklyHolidays = arr;
      if (shiftSetting.Shift1Enable) {
        this.setState({ Shift1Enable: 1 });
      }
      if (shiftSetting.Shift3Enable) {
        this.setState({ Shift3Enable: 1 });
      }
      if (shiftSetting.Shift2Enable) {
        this.setState({ Shift2Enable: 1 });
      }
      if (shiftSetting.Shift4Enable) {
        this.setState({ Shift4Enable: 1 });
      }
      // // consol(this.props.shiftSettingData)

      this.setState({
        MidNightShift: shiftSetting.MidNightShift.toString(),
        shiftSetting: this.props.shiftSettingData,
        oldObj: shiftSetting,
        from: this.getDate(shiftSetting.From),
        till: this.getDate(shiftSetting.Till)
      });
    }
  }

  schema={
    From:joi.date().required(),
    Till:joi.date().required(),
    Shift1Enable:joi.bool().required().label('Min Shift1 Is Required'),
    Shift2Enable:joi.bool(),
    Shift3Enable:joi.bool(),
    Shift4Enable:joi.bool(),
    Shift1Breaks:joi.array(),
    Shift2Breaks:joi.array(),
    Shift3Breaks:joi.array(),
    Shift4Breaks:joi.array(),
    MidNightShift:joi.number()

  }

  

  

  handleValidateFieldsOfShif=(shift)=>{
    if(shift.From!==undefined  || shift.Till!==undefined){
      if(shift.Shift1Enable){
        if(shift.Shift1StartTime==undefined || shift.Shift1EndTime==undefined){
          swal('shift1start and shift1end time entry are mandatory',{dangerMode:true});
          return true
        }else 
        {
          if(shift.Shift2Enable){
            if(shift.Shift2Enable=='false'){return false}
            if(shift.Shift2StartTime==undefined || shift.Shift2EndTime==undefined){
              swal('shift2start and shift2end time entry are mandatory',{dangerMode:true});
              return true
            }else 
            {
              return false
            }
          }
          else if(shift.Shift3Enable){
            if(shift.Shift2Enable=='false'){return false}
            if(shift.Shift3StartTime==undefined || shift.Shift3EndTime==undefined){
              swal('shift3start and shift3end time entry are mandatory',{dangerMode:true});
              return true
            }else 
            {
              return false
            }
          }
          else if(shift.Shift4Enable){
            if(shift.Shift2Enable=='false'){return false}
            if(shift.Shift4StartTime==undefined || shift.Shift4EndTime==undefined){
              swal('shift4start and shift4end time entry are mandatory',{dangerMode:true});
              return true
            }else 
            {
              return false
            }
          }else
          {
            return false
          }
        }
      }
      else{
        swal('shift 1 entry are mandatory',{dangerMode:true});
        return true
      }
    }else
    {
      swal('from time and till time are mandatory',{dangerMode:true});
      return true
    }
  }


  handleRemoveBreaks=(shiftData)=>{
    let shift={...shiftData};
    if(shift.Shift1Enable=='true'||shift.Shift1Enable){
      shift.Shift1Breaks.forEach((Break,i)=>{
          if(dateTime.getDifferenceBetweenTimes(new Date(Break.BreakStart),new Date(Break.BreakEnd)).minutes<=1){
            shift.Shift1Breaks.splice(i,1)
          } 
      })
    }
    if(shift.Shift1Enable=='true'||shift.Shift1Enable){
      shift.Shift2Breaks.forEach((Break,i)=>{
        if(dateTime.getDifferenceBetweenTimes(new Date(Break.BreakStart),new Date(Break.BreakEnd)).minutes<=1){
          shift.Shift2Breaks.splice(i,1)
        } 
    })
    }
    if(shift.Shift1Enable=='true'||shift.Shift1Enable){
      shift.Shift3Breaks.forEach((Break,i)=>{
        if(dateTime.getDifferenceBetweenTimes(new Date(Break.BreakStart),new Date(Break.BreakEnd)).minutes<=1){
          shift.Shift3Breaks.splice(i,1)
        } 
    })
    }
    if(shift.Shift1Enable=='true'||shift.Shift1Enable){
      shift.Shift4Breaks.forEach((Break,i)=>{
        if(dateTime.getDifferenceBetweenTimes(new Date(Break.BreakStart),new Date(Break.BreakEnd)).minutes<=1){
          shift.Shift4Breaks.splice(i,1)
        } 
    })
    }
    return shiftData
  }

  handleSubmit = data => {
    // // consol(data);
    let errorFlag=this.handleValidateFieldsOfShif(data)
    if(errorFlag){return }
    data=this.validateAndUpdateShiftSettings(data)
    data=this.handleRemoveBreaks(data)
    data.EffectiveFrom.setMilliseconds(1);
      
    // if (this.handleCheckValidate(data))
    //   return

      if (this.props.actionStatus === "add") {
        this.setState({ querySuccess: true });
        axios
          .post(endPoint.api + "shift", data)
          .then(result => {
            if (result.status === 200) {
              this.setState({ querySuccess: false });
              this.props.history.push("/setting/shiftsetting/list");
              swal("Save Data Successfully");
            } else {
              this.setState({ querySuccess: false });
              swal(`Data Not Saved`);
            }
          })
          .catch(err => {
            this.setState({ querySuccess: false });
            swal(`${err.response.data.message}`);
          });
      }
    if (this.props.actionStatus === "update") {
      this.setState({ querySuccess: true });
      axios
        .patch(endPoint.api + "shift/" + data._id, {
          old: this.state.oldObj,
          new: data

        })
        .then(result => {
          const { n, nModified, ok } = result.data;
          if (ok == 1 && n == 1 && nModified == 1) {
            this.setState({ querySuccess: false });
            this.props.history.push("/setting/shiftsetting/list");
            swal("Updated Data Successfully");
          } else {
            this.setState({ querySuccess: false });
            this.props.history.push("/setting/shiftsetting/list");
            swal(`Updated`);
            swal(`${result.data}`);
          }
        })
        .catch(err => {
          this.setState({ querySuccess: false });
          swal(`${err.response.data.message}`);
        });
    }
  };

  validateAndUpdateShiftSettings=(shiftSetting)=>{
       if((shiftSetting['Shift1Enable']==true || shiftSetting['Shift1Enable']=='true')
       && (shiftSetting['Shift2Enable']==false || shiftSetting['Shift2Enable']=='false')
       &&( shiftSetting['Shift3Enable']==false ||shiftSetting['Shift3Enable']=='false') 
       &&( shiftSetting['Shift4Enable']==false ||shiftSetting['Shift4Enable']=='false'))
       {
        shiftSetting['EffectiveFrom'].setHours(shiftSetting['Shift1StartTime'].getHours());
        shiftSetting['EffectiveFrom'].setMinutes(shiftSetting['Shift1StartTime'].getMinutes());

        shiftSetting['EffectiveTill'].setHours(shiftSetting['Shift1EndTime'].getHours());
        shiftSetting['EffectiveTill'].setMinutes(shiftSetting['Shift1EndTime'].getMinutes());
       }

       if((shiftSetting['Shift1Enable']==true || shiftSetting['Shift1Enable']=='true')
       && (shiftSetting['Shift2Enable']==true || shiftSetting['Shift2Enable']=='true')
       &&( shiftSetting['Shift3Enable']==false ||shiftSetting['Shift3Enable']=='false')
       &&( shiftSetting['Shift4Enable']==false ||shiftSetting['Shift4Enable']=='false') )
       {
        shiftSetting['EffectiveFrom'].setHours(shiftSetting['Shift1StartTime'].getHours());
        shiftSetting['EffectiveFrom'].setMinutes(shiftSetting['Shift1StartTime'].getMinutes());

        shiftSetting['EffectiveTill'].setHours(shiftSetting['Shift2EndTime'].getHours());
        shiftSetting['EffectiveTill'].setMinutes(shiftSetting['Shift2EndTime'].getMinutes());
       }


       if((shiftSetting['Shift1Enable']==true || shiftSetting['Shift1Enable']=='true')
       && (shiftSetting['Shift2Enable']==true || shiftSetting['Shift2Enable']=='true')
       &&( shiftSetting['Shift3Enable']==true ||shiftSetting['Shift3Enable']=='true') 
       &&( shiftSetting['Shift4Enable']==true ||shiftSetting['Shift4Enable']=='true'))
       {
        shiftSetting['EffectiveFrom'].setHours(shiftSetting['Shift1StartTime'].getHours());
        shiftSetting['EffectiveFrom'].setMinutes(shiftSetting['Shift1StartTime'].getMinutes());

        shiftSetting['EffectiveTill'].setHours(shiftSetting['Shift3EndTime'].getHours());
        shiftSetting['EffectiveTill'].setMinutes(shiftSetting['Shift3EndTime'].getMinutes());
       }

       if((shiftSetting['Shift1Enable']==true || shiftSetting['Shift1Enable']=='true')
       && (shiftSetting['Shift2Enable']==false || shiftSetting['Shift2Enable']=='false')
       &&( shiftSetting['Shift3Enable']==true ||shiftSetting['Shift3Enable']=='true')
       &&( shiftSetting['Shift4Enable']==false ||shiftSetting['Shift4Enable']=='false') )
       {
        shiftSetting['EffectiveFrom'].setHours(shiftSetting['Shift1StartTime'].getHours());
        shiftSetting['EffectiveFrom'].setMinutes(shiftSetting['Shift1StartTime'].getMinutes());

        shiftSetting['EffectiveTill'].setHours(shiftSetting['Shift3EndTime'].getHours());
        shiftSetting['EffectiveTill'].setMinutes(shiftSetting['Shift3EndTime'].getMinutes());
       }

       if((shiftSetting['Shift1Enable']==false || shiftSetting['Shift1Enable']=='false')
       && (shiftSetting['Shift2Enable']==true || shiftSetting['Shift2Enable']=='true')
       &&( shiftSetting['Shift3Enable']==true ||shiftSetting['Shift3Enable']=='true')
       &&( shiftSetting['Shift4Enable']==true ||shiftSetting['Shift4Enable']=='true') )
       {
        shiftSetting['EffectiveFrom'].setHours(shiftSetting['Shift2StartTime'].getHours());
        shiftSetting['EffectiveFrom'].setMinutes(shiftSetting['Shift2StartTime'].getMinutes());

        shiftSetting['EffectiveTill'].setHours(shiftSetting['Shift4EndTime'].getHours());
        shiftSetting['EffectiveTill'].setMinutes(shiftSetting['Shift4EndTime'].getMinutes());
       }
       if((shiftSetting['Shift1Enable']==false || shiftSetting['Shift1Enable']=='false')
       && (shiftSetting['Shift2Enable']==false || shiftSetting['Shift2Enable']=='false')
       &&( shiftSetting['Shift3Enable']==true ||shiftSetting['Shift3Enable']=='true')
       &&( shiftSetting['Shift4Enable']==true ||shiftSetting['Shift4Enable']=='true') )
       {
        shiftSetting['EffectiveFrom'].setHours(shiftSetting['Shift3StartTime'].getHours());
        shiftSetting['EffectiveFrom'].setMinutes(shiftSetting['Shift3StartTime'].getMinutes());

        shiftSetting['EffectiveTill'].setHours(shiftSetting['Shift4EndTime'].getHours());
        shiftSetting['EffectiveTill'].setMinutes(shiftSetting['Shift4EndTime'].getMinutes());
       }
       if((shiftSetting['Shift1Enable']==false || shiftSetting['Shift1Enable']=='false')
       && (shiftSetting['Shift2Enable']==false || shiftSetting['Shift2Enable']=='false')
       &&( shiftSetting['Shift3Enable']==false ||shiftSetting['Shift3Enable']=='false')
       &&( shiftSetting['Shift4Enable']==true ||shiftSetting['Shift4Enable']=='true') )
       {
        shiftSetting['EffectiveFrom'].setHours(shiftSetting['Shift4StartTime'].getHours());
        shiftSetting['EffectiveFrom'].setMinutes(shiftSetting['Shift4StartTime'].getMinutes());

        shiftSetting['EffectiveTill'].setHours(shiftSetting['Shift4EndTime'].getHours());
        shiftSetting['EffectiveTill'].setMinutes(shiftSetting['Shift4EndTime'].getMinutes());
       }

       if((shiftSetting['Shift1Enable']==true || shiftSetting['Shift1Enable']=='true')
       && (shiftSetting['Shift2Enable']==false || shiftSetting['Shift2Enable']=='false')
       &&( shiftSetting['Shift3Enable']==false ||shiftSetting['Shift3Enable']=='false')
       &&( shiftSetting['Shift4Enable']==true ||shiftSetting['Shift4Enable']=='true') )
       {
        shiftSetting['EffectiveFrom'].setHours(shiftSetting['Shift1StartTime'].getHours());
        shiftSetting['EffectiveFrom'].setMinutes(shiftSetting['Shift1StartTime'].getMinutes());

        shiftSetting['EffectiveTill'].setHours(shiftSetting['Shift4EndTime'].getHours());
        shiftSetting['EffectiveTill'].setMinutes(shiftSetting['Shift4EndTime'].getMinutes());
       }



       if(shiftSetting['MidNightShift']=='1'){
        if(shiftSetting['Shift1StartTime']>shiftSetting['Shift1EndTime']){
          shiftSetting['Shift1EndTime'].setDate(shiftSetting['Shift1EndTime'].getDate()+1);
          shiftSetting['EffectiveTill'].setDate(shiftSetting['EffectiveTill'].getDate()+1);
          // shiftSetting['Shift1Breaks'].forEach((one)=>{
          //   if(one.BreakStart>one.BreakEnd){
          //     shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
          //   }
          //     else
          //     {
          //       shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
          //       shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
          //     }
            
          // })
          shiftSetting['Shift1Breaks'].forEach((one)=>{
            let startBreak=new Date(one.BreakStart);
            let endBreak=new Date(one.BreakEnd);
            if(startBreak>endBreak){
              endBreak.setDate(endBreak.getDate()+1);
            }
            one.BreakEnd=endBreak;
            let diff= dateTime.getDifferenceBetweenTimes(shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart,shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd).minutes
            let endTime=new Date(shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart);
            endTime.setMinutes(endTime.getMinutes()+diff)
            shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd=endTime;
            
             if(one.BreakStart<shiftSetting['Shift1StartTime']){
                  shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
                  shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift1Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
                 }
               //shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
           })
        }
       }
       if(shiftSetting['MidNightShift']=='2'){
        if(shiftSetting['Shift2StartTime']>shiftSetting['Shift2EndTime']){
          shiftSetting['Shift2EndTime'].setDate(shiftSetting['Shift2EndTime'].getDate()+1);
          shiftSetting['EffectiveTill'].setDate(shiftSetting['EffectiveTill'].getDate()+1);

          shiftSetting['Shift2Breaks'].forEach((one)=>{
            let startBreak=new Date(one.BreakStart);
            let endBreak=new Date(one.BreakEnd);
            if(startBreak>endBreak){
              endBreak.setDate(endBreak.getDate()+1);
            }
            one.BreakEnd=endBreak;
           let diff= dateTime.getDifferenceBetweenTimes(shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart,shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd).minutes
           let endTime=new Date(shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart);
           endTime.setMinutes(endTime.getMinutes()+diff)
           shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd=endTime;
           
           
           if(one.BreakStart<shiftSetting['Shift2StartTime']){
                 shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
                 shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
                }
              //shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
          })

          // shiftSetting['Shift2Breaks'].forEach((one)=>{
          //   if(one.BreakStart>one.BreakEnd){
          //     shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
          //   }
          // })
        }
       }
       if(shiftSetting['MidNightShift']=='3'){
        if(shiftSetting['Shift3StartTime']>shiftSetting['Shift3EndTime']){
          shiftSetting['Shift3EndTime'].setDate(shiftSetting['Shift3EndTime'].getDate()+1);
          shiftSetting['EffectiveTill'].setDate(shiftSetting['EffectiveTill'].getDate()+1);
          // shiftSetting['Shift3Breaks'].forEach((one)=>{
          //   if(one.BreakStart>one.BreakEnd){
          //     shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
          //   }
          //   else
          //   {
          //     shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
          //     shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
          //   }
          // })
          shiftSetting['Shift3Breaks'].forEach((one)=>{
            let startBreak=new Date(one.BreakStart);
            let endBreak=new Date(one.BreakEnd);
            if(startBreak>endBreak){
              endBreak.setDate(endBreak.getDate()+1);
            }
            one.BreakEnd=endBreak;
            let diff= dateTime.getDifferenceBetweenTimes(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart,shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd).minutes
            let endTime=new Date(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart);
            endTime.setMinutes(endTime.getMinutes()+diff)
            shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd=endTime;
            
             if(one.BreakStart<shiftSetting['Shift3StartTime']){
                  shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
                  shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
                 }
               //shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
           })
        }
       }

       if(shiftSetting['MidNightShift']=='4'){
        if(shiftSetting['Shift4StartTime']>shiftSetting['Shift4EndTime']){
          shiftSetting['Shift4EndTime'].setDate(shiftSetting['Shift4EndTime'].getDate()+1);
          shiftSetting['EffectiveTill'].setDate(shiftSetting['EffectiveTill'].getDate()+1);
          // shiftSetting['Shift3Breaks'].forEach((one)=>{
          //   if(one.BreakStart>one.BreakEnd){
          //     shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
          //   }
          //   else
          //   {
          //     shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
          //     shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift3Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
          //   }
          // })
          shiftSetting['Shift4Breaks'].forEach((one)=>{
            let startBreak=new Date(one.BreakStart);
            let endBreak=new Date(one.BreakEnd);
            if(startBreak>endBreak){
              endBreak.setDate(endBreak.getDate()+1);
            }
            one.BreakEnd=endBreak;
            let diff= dateTime.getDifferenceBetweenTimes(shiftSetting['Shift4Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart,shiftSetting['Shift4Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd).minutes
            let endTime=new Date(shiftSetting['Shift4Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart);
            endTime.setMinutes(endTime.getMinutes()+diff)
            shiftSetting['Shift4Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd=endTime;
            
             if(one.BreakStart<shiftSetting['Shift4StartTime']){
                  shiftSetting['Shift4Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.setDate(shiftSetting['Shift4Breaks'].filter(obj=>obj.key===one.key)[0].BreakEnd.getDate()+1)
                  shiftSetting['Shift4Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift4Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
                 }
               //shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.setDate(shiftSetting['Shift2Breaks'].filter(obj=>obj.key===one.key)[0].BreakStart.getDate()+1)
           })
        }
       }

       return shiftSetting
  }

  handleOnChange = (e, shiftBreakKey) => {
    const { shiftSetting } = { ...this.state };
    if (e.currentTarget.name === "WeeklyHolidays") {
      shiftSetting[e.currentTarget.name] = e.currentTarget.value;
      this.setState({ shiftSetting });
    } else {
      if (e.currentTarget.name === "From" || e.currentTarget.name === "Till") {
        if (e.currentTarget.name === "From") {
          let dateTime = new Date(e.currentTarget.value).setHours(0, 0, 0, 0)
          shiftSetting[e.currentTarget.name] = new Date(dateTime);
          shiftSetting['EffectiveFrom'] = new Date(dateTime)
        } else {
          let dateTime = new Date(e.currentTarget.value).setHours(0, 0, 0, 0)
          shiftSetting[e.currentTarget.name] = new Date(dateTime)
          //dateTime = new Date(dateTime).setDate(new Date(dateTime).getDate() + 1)
          shiftSetting['EffectiveTill'] = new Date(dateTime)
        }
      }
      else if (e.currentTarget.id === "Shift1Breaks" || e.currentTarget.id === "Shift3Breaks" || e.currentTarget.id === "Shift4Breaks"|| e.currentTarget.id === "Shift2Breaks") {
        let filterObj = shiftSetting[e.currentTarget.id].filter((obj1) => obj1.key === shiftBreakKey);
        if (filterObj.length == 0) {
          let obj = {}
          if (e.currentTarget.name == 'BreakStart') {
            let date = new Date(shiftSetting.From);
            obj[e.currentTarget.name] = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              e.currentTarget.value.split(":")[0],
              e.currentTarget.value.split(":")[1],
              0,
              0
            );
          }
          if (e.currentTarget.name == 'BreakEnd') {
            let date = new Date(shiftSetting.From);
            obj[e.currentTarget.name] = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              e.currentTarget.value.split(":")[0],
              e.currentTarget.value.split(":")[1],
              0,
              0
            );
          }
          obj['key'] = shiftBreakKey;
          shiftSetting[e.currentTarget.id].push(obj)
        }
        else {
          if (e.currentTarget.name == 'BreakStart') {
              let date = new Date(shiftSetting.From);
              shiftSetting[e.currentTarget.id].filter((obj1) => obj1.key === shiftBreakKey)[0][e.currentTarget.name] = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                e.currentTarget.value.split(":")[0],
                e.currentTarget.value.split(":")[1],
                0,
                0
              );
          }
          if (e.currentTarget.name == 'BreakEnd') {
            let date = new Date(shiftSetting.From);
            shiftSetting[e.currentTarget.id].filter((obj1) => obj1.key === shiftBreakKey)[0][e.currentTarget.name] = new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate() ,
              e.currentTarget.value.split(":")[0],
              e.currentTarget.value.split(":")[1],
              0,
              0
            );
          }
        }
      }
      else if (e.currentTarget.name == 'Shift1StartTime' || e.currentTarget.name == 'Shift2StartTime' || e.currentTarget.name == 'Shift3StartTime'|| e.currentTarget.name == 'Shift4StartTime') {
        let date = new Date(shiftSetting.From);
        
        shiftSetting[e.currentTarget.name] = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          e.currentTarget.value.split(":")[0],
          e.currentTarget.value.split(":")[1],
          0,
          0
        );
      }
      else if (e.currentTarget.name == 'Shift4EndTime' ||e.currentTarget.name == 'Shift1EndTime' || e.currentTarget.name == 'Shift2EndTime' || e.currentTarget.name == 'Shift3EndTime') {
        let date = new Date(shiftSetting.From);
        shiftSetting[e.currentTarget.name] = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          e.currentTarget.value.split(":")[0],
          e.currentTarget.value.split(":")[1],
          0,
          0
        );
      }

      else {
        let date = new Date(Date.now());

        // // consol(e.currentTarget.value);
        shiftSetting[e.currentTarget.name] = new Date(
          date.getFullYear(),
          date.getMonth() ,
          date.getDate(),
          e.currentTarget.value.split(":")[0],
          e.currentTarget.value.split(":")[1],
          0,
          0
        );

      }

      this.setState({ shiftSetting });
    }

    // // consol(shiftSetting);
  };
  handleRadioChange = e => {
    const { shiftSetting } = { ...this.state };
    shiftSetting[e.currentTarget.name] = e.currentTarget.value;
    // // consol(e.currentTarget.value);
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value === "true" ? 1 : 0,
      shiftSetting
    });
    // // consol(shiftSetting);
  };

  handleMidnight = (e) => {

    const { shiftSetting } = { ...this.state };
    shiftSetting[e.currentTarget.name] = e.currentTarget.value;

    this.setState({
      [e.currentTarget.name]:e.currentTarget.value === '4'|| e.currentTarget.value === '1' || e.currentTarget.value === '2' || e.currentTarget.value === '3' ? e.currentTarget.value : 0,
      shiftSetting
    });
    // // consol(this.state.MidNightShift)
    // // consol(shiftSetting);


  }

  getDate = datetime => {
    if (datetime) {
      let date = new Date(datetime);
      return (
        date.getFullYear() +
        "-" +
        ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
        "-" +
        ((date.getDate() < 10 ? "0" : "") + date.getDate())
      );
    }
  };
  getTime = datetime => {
    if (datetime) {
      let Time = new Date(datetime);
      return (
        (Time.getHours() < 10 ? "0" : "") +
        Time.getHours() +
        ":" +
        (Time.getMinutes() < 10 ? "0" : "") +
        Time.getMinutes()
      );
    }
  };

  getTimeForShiftBreaks = (shiftOfBreaks, breakKey, breakType) => {
    if(this.state.shiftSetting[shiftOfBreaks]==undefined){
      return ''
    }else
    {
      return this.state.shiftSetting[shiftOfBreaks].filter(obj => obj.key == breakKey).length > 0 ?
      this.state.shiftSetting[shiftOfBreaks].filter(obj => obj.key == breakKey)[0][breakType] : ''
    }
  }

  handleChange = (value) => {
    const { shiftSetting } = { ...this.state };
    let arr = []
    if(value){
      value.forEach((one) => {
        arr.push(one.value)
      })
    }
    shiftSetting['WeeklyHolidays'] = arr;
    this.setState({ shiftSetting });
  }

  getValue = (WeeklyHolidays) => {
    let arr = []
    if (WeeklyHolidays !== undefined && WeeklyHolidays !== undefined && WeeklyHolidays.length > 0) {
      this.options.forEach(one => {
        WeeklyHolidays.forEach(value => {
          if (value == one.value) {
            arr.push(one)
          }
        })
      })
      return arr;
    }
    else {
      return arr;
    }
  }

  render() {
    // // consol(this.props.shiftSettingData)
    // // consol(this.props.actionStatus)
    return (
      <React.Fragment>
        {this.state.querySuccess ? <Loading /> : ''}

        <div style={{marginLeft:15}} className=" main ">
          <div className="row ">
            <div className="col-sm-12 ">
              <div className="row">
                <div className="col-sm-3">
                  <div className="form-group text-center">
                    <label className="small">From</label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      placeholder="From Date"
                      name="From"
                      defaultValue={this.state.from}
                      //value='2013-01-08'
                      onChange={this.handleOnChange}
                    />
                     {this.state.errors.From && <div className="alert small alert-danger">{this.state.errors['From']}</div>}
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group text-center">
                    <label className="small">Till</label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      placeholder="Till Date"
                      defaultValue={this.state.till}
                      name="Till"
                      onChange={this.handleOnChange}
                    />
                     {this.state.errors.Till && <div className="alert small alert-danger">{this.state.errors['Till']}</div>}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group text-center ">
                    <label className="small">Weekly Holiday</label>
                    <Select
                    
                      value={this.getValue(this.state.shiftSetting.WeeklyHolidays)}
                      onChange={this.handleChange}
                      options={this.options}
                      isMulti={true}
                    />
                    {/* <select
                      type="text"
                      className="form-control form-control-sm"
                      name="WeeklyHolidays"
                      onChange={this.handleOnChange}
                      value={this.state.shiftSetting.WeeklyHolidays}
                      //onClick={this.showCheckboxes}
                    >
                      <option>--select Weekly Holiday--</option>
                      <option><input type='checkbox'/><text>Sunday</text></option>
                      <option><input type='checkbox'/>Monday</option>
                      <option><input type='checkbox'/>Tuesday</option>
                      <option><input type='checkbox'/>Whenesday</option>
                      <option><input type='checkbox'/>Thursday</option>
                      <option><input type='checkbox'/>Friday</option>
                      <option><input type='checkbox'/>Saturday</option>
                      <option><input type='checkbox'/>None</option>
                    </select> */}
                  </div>
                </div>

                {/* <div className="col-sm-3">
                  <div className="form-group text-center">
                    <label className='small'>Midnigth Shift</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Midnight Shift"
                      name="MidNightShift"
                      onChange={this.handleOnChange}
                      defaultValue={this.state.shiftSetting.MidNightShift}
                    />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-sm-12 ">
              <div className="row">
                <div className="col-sm-3 border p-0 m-0">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="small" htmlFor="componentweigth">
                        Shift1
                      </label>
                      <br />
                      <input
                        type="checkbox"
                        value={this.state.Shift1Enable === 0 ? true : false}
                        checked={this.state.Shift1Enable === 1}
                        name="Shift1Enable"
                        disabled={this.props.actionStatus=='update'}
                        onChange={this.handleRadioChange}
                        defaultValue={this.state.shiftSetting.Shift1Enable}
                      />
                       {this.state.errors.Shift1Enable && <div className="alert small alert-danger">{this.state.errors['Shift1Enable']}</div>}
                      &nbsp;
                      <label className="small" htmlFor="yes">
                        Shift1 Enable
                      </label>
                      {/* {// consol(this.state.MidNightShift)} */}
                      <input
                        className="ml-3"
                        type="radio"
                        value={1}
                        disabled={this.state.Shift1Enable === 0  ||this.props.actionStatus=='update'}
                        checked={this.state.MidNightShift === '1'}
                        name="MidNightShift"
                        onChange={this.handleMidnight}
                      //defaultValue={this.state.shiftSetting.MidNightShift}
                      />
                      &nbsp;
                      <label className="small" htmlFor="yes">
                        MidNightShift
                      </label>
                      <br />
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Start Time</label>
                          <input
                          
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="Shift1StartTime"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            onChange={this.handleOnChange}
                            defaultValue={this.getTime(
                              this.state.shiftSetting.Shift1StartTime
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">End Time</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            name="Shift1EndTime"
                            onChange={this.handleOnChange}
                            defaultValue={this.getTime(
                              this.state.shiftSetting.Shift1EndTime
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break1 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            id="Shift1Breaks"
                            name="BreakStart"
                            onChange={(e) => this.handleOnChange(e, "Break1")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break1', 'BreakStart'))}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break1 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift1Breaks"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            name="BreakEnd"
                            onChange={(e) => this.handleOnChange(e, "Break1")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break1', 'BreakEnd'))}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break2 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            name="BreakStart"
                            id="Shift1Breaks"
                            onChange={(e) => this.handleOnChange(e, "Break2")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break2', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break2 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakEnd"
                            id="Shift1Breaks"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break2")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break2', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break3 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakStart"
                            id="Shift1Breaks"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break3")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break3', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break3 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakEnd"
                            id="Shift1Breaks"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break3")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break3', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break4 Start</label>
                          <input
                            type="time"
                            id="Shift1Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakStart"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break4")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break4', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break4 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift1Breaks"
                            name="BreakEnd"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break4")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break4', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break5 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift1Breaks"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            name="BreakStart"
                            onChange={(e) => this.handleOnChange(e, "Break5")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break5', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break5 End</label>
                          <input
                            type="time"
                            id="Shift1Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift1Enable === 0||this.props.actionStatus=='update'}
                            name="BreakEnd"
                            onChange={(e) => this.handleOnChange(e, "Break5")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift1Breaks', 'Break5', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 border p-0 m-0">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="small" htmlFor="componentweigth">
                        Shift2
                      </label>
                      <br />
                      <input
                      disabled={this.props.actionStatus=='update'}
                        type="checkbox"
                        value={this.state.Shift2Enable === 0 ? true : false}
                        checked={this.state.Shift2Enable === 1}
                        name="Shift2Enable"
                        onChange={this.handleRadioChange}
                        defaultValue={this.state.shiftSetting.Shift2Enable}
                      />
                      &nbsp;
                      <label className="small" htmlFor="yes">
                        Shift2 Enable
                      </label>
                      <input
                        className='ml-3'
                        type="radio"
                        value={2}
                        disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                        checked={this.state.MidNightShift === '2'}
                        name="MidNightShift"
                        onChange={this.handleMidnight}
                      //defaultValue={this.state.shiftSetting.MidNightShift}
                      />
                      &nbsp;
                      <label className="small" htmlFor="yes">
                        MidNightShift
                      </label>
                      <br />
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Start Time</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="Shift2StartTime"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            onChange={this.handleOnChange}
                            defaultValue={this.getTime(
                              this.state.shiftSetting.Shift2StartTime
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">End Time</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="Shift2EndTime"
                            onChange={this.handleOnChange}
                            defaultValue={this.getTime(
                              this.state.shiftSetting.Shift2EndTime
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break1 Start</label>
                          <input
                            type="time"
                            id="Shift2Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="BreakStart"
                            onChange={(e) => this.handleOnChange(e, "Break1")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break1', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break1 End</label>
                          <input
                            type="time"
                            id="Shift2Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="BreakEnd"
                            onChange={(e) => this.handleOnChange(e, "Break1")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break1', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break2 Start</label>
                          <input
                            type="time"
                            id="Shift2Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="BreakStart"
                            onChange={(e) => this.handleOnChange(e, "Break2")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break2', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break2 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift2Breaks"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="BreakEnd"
                            onChange={(e) => this.handleOnChange(e, "Break2")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break2', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break3 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="BreakStart"
                            id="Shift2Breaks"
                            onChange={(e) => this.handleOnChange(e, "Break3")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break3', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break3 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="BreakEnd"
                            id="Shift2Breaks"
                            onChange={(e) => this.handleOnChange(e, "Break3")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break3', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break4 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="BreakStart"
                            id="Shift2Breaks"
                            onChange={(e) => this.handleOnChange(e, "Break4")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break4', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break4 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="BreakEnd"
                            id="Shift2Breaks"
                            onChange={(e) => this.handleOnChange(e, "Break4")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break4', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break5 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            name="BreakStart"
                            id="Shift2Breaks"
                            onChange={(e) => this.handleOnChange(e, "Break5")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break5', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break5 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakEnd"
                            id="Shift2Breaks"
                            disabled={this.state.Shift2Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break5")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift2Breaks', 'Break5', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 border p-0 m-0">

                  <div className="col-sm-12 ">
                    <div className="form-group">
                      <label className="small" htmlFor="componentweigth">
                        Shift3
                      </label>
                      <br />
                      <input
                      disabled={this.props.actionStatus=='update'}
                        type="checkbox"
                        value={this.state.Shift3Enable == 0 ? true : false}
                        checked={this.state.Shift3Enable === 1}
                        name="Shift3Enable"
                        onChange={this.handleRadioChange}
                      />
                      &nbsp;
                      <label className="small" htmlFor="yes">
                        Shift3 Enable
                      </label>


                      <input
                        className='ml-3'
                        type="radio"
                        value={3}
                        checked={this.state.MidNightShift === '3'}
                        name="MidNightShift"
                        disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                        onChange={this.handleMidnight}
                      />
                      &nbsp;
                      <label className="small" htmlFor="yes">
                        MidNightShift
                      </label>
                      <br />
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Start Time</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="Shift3StartTime"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={this.handleOnChange}
                            defaultValue={this.getTime(
                              this.state.shiftSetting.Shift3StartTime
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">End Time</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="Shift3EndTime"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={this.handleOnChange}
                            defaultValue={this.getTime(
                              this.state.shiftSetting.Shift3EndTime
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break1 Start</label>
                          <input
                            type="time"
                            id="Shift3Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakStart"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break1")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break1', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break1 End</label>
                          <input
                            type="time"
                            id="Shift3Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakEnd"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break1")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break1', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break2 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift3Breaks"
                            name="BreakStart"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break2")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break2', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break2 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift3Breaks"
                            name="BreakEnd"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break2")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break2', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break3 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift3Breaks"
                            name="BreakStart"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break3")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break3', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break3 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift3Breaks"
                            name="BreakEnd"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break3")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break3', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break4 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakStart"
                            id="Shift3Breaks"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break4")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break4', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break4 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift3Breaks"
                            name="BreakEnd"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break4")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break4', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break5 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift3Breaks"
                            name="BreakStart"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break5")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break5', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break5 End</label>
                          <input
                            type="time"
                            id="Shift3Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift3Enable === 0||this.props.actionStatus=='update'}
                            name="BreakEnd"
                            onChange={(e) => this.handleOnChange(e, "Break5")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift3Breaks', 'Break5', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3  border p-0 m-0">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="small" htmlFor="componentweigth">
                        Shift4
                      </label>
                      <br />
                      <input
                      disabled={this.props.actionStatus=='update'}
                        type="checkbox"
                        value={this.state.Shift4Enable == 0 ? true : false}
                        checked={this.state.Shift4Enable === 1}
                        name="Shift4Enable"
                        onChange={this.handleRadioChange}
                      />
                      &nbsp;
                      <label className="small" htmlFor="yes">
                        Shift4 Enable
                      </label>


                      <input
                        className='ml-3'
                        type="radio"
                        value={4}
                        checked={this.state.MidNightShift === '4'}
                        name="MidNightShift"
                        disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                        onChange={this.handleMidnight}
                      />
                      &nbsp;
                      <label className="small" htmlFor="yes">
                        MidNightShift
                      </label>
                      <br />
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Start Time</label>
                          <input
                            type="time"
                            
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="Shift4StartTime"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={this.handleOnChange}
                            defaultValue={this.getTime(
                              this.state.shiftSetting.Shift4StartTime
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">End Time</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="Shift4EndTime"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={this.handleOnChange}
                            defaultValue={this.getTime(
                              this.state.shiftSetting.Shift4EndTime
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break1 Start</label>
                          <input
                            type="time"
                            id="Shift4Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakStart"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break1")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break1', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break1 End</label>
                          <input
                            type="time"
                            id="Shift4Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakEnd"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break1")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break1', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break2 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift4Breaks"
                            name="BreakStart"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break2")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break2', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break2 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift4Breaks"
                            name="BreakEnd"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break2")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break2', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break3 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shif43Breaks"
                            name="BreakStart"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break3")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break3', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break3 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift4Breaks"
                            name="BreakEnd"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break3")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break3', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break4 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            name="BreakStart"
                            id="Shift4Breaks"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break4")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break4', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break4 End</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift4Breaks"
                            name="BreakEnd"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break4")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break4', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break5 Start</label>
                          <input
                            type="time"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            id="Shift4Breaks"
                            name="BreakStart"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            onChange={(e) => this.handleOnChange(e, "Break5")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break5', 'BreakStart'))}

                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group text-center">
                          <label className="small">Break5 End</label>
                          <input
                            type="time"
                            id="Shift4Breaks"
                            className="form-control form-control-sm"
                            placeholder="Date"
                            disabled={this.state.Shift4Enable === 0 ||this.props.actionStatus=='update'}
                            name="BreakEnd"
                            onChange={(e) => this.handleOnChange(e, "Break5")}
                            defaultValue={this.getTime(this.getTimeForShiftBreaks('Shift4Breaks', 'Break5', 'BreakEnd'))}

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.handleSubmit(this.state.shiftSetting);
              }}
            >
              Submit
            </button>
          </div>
          <div className="text-center" style={{ marginTop: 10 }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.history.push("/setting/shiftsetting/list");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ShiftSetting;
