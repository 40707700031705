import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";

import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import object from "joi/lib/types/object";


function EquipmentWiseProductionData({ fromDateLimit, storeDataInLocalStorage }) {
  const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);
  const [periodOption, setperiodOption] = useState([
    { label: "Shift", id: "1" },
    { label: "Daily", id: "2" },
    { label: "Weekly", id: "3" },
    { label: "Monthly", id: "4" },
    { label: "Cummulative", id: "5" },
  ]);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [datacount, setdatacount] = useState('');
  const [selectedItemLengths, setSelectedItemLengths] = useState({});

  const [selectedDates, setSelectedDates] = useState({
    From: "", Till: "",
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState([]);

  const [dfdate, sfdate] = useState([]);



  // Your existing chart code goes here

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    {
      heading: "Equipment",
      label: "Equipment",
      initialItems: equpdata,
      selectedItemIds: selectedItemIds,
      name: "Equipment",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    },
    {
      heading: "Shift",
      label: "tablecolumns",
      initialItems: shiftOption,
      selectedItemIds: selectedItemIds,
      name: "Shift",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: false,
    },


  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////


  const schema = {
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
    Equips: joi.array().required().label("Equipment"),
    Shift: joi.array().required().label("please select at least one shift"),
    Report: joi.required().label("Report"),

  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
        setQuerySuccess(true);

        // const equipmentdata = await axios.get(api.api1 + "equipment");

        // const downtimedata = await axios.get(api.api + "downtimereason");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

        let equipmentdata = await storeDataInLocalStorage('equipment')
        const downtimelist = downtimedata
          .map((reason, index) => ({
            id: `${index}`,
            label: reason.ReasonGroup,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));




        const uniqueObjects = [];

        // Filter out objects with duplicate combinations of label and id
        const filteredData = downtimelist.filter(obj => {
          // Check if the combination of label and id is already in the uniqueObjects array
          const isDuplicate = uniqueObjects.some(uniqueObj =>
            uniqueObj.label === obj.label
          );

          if (!isDuplicate) {
            // If not, add it to the array and keep the object
            uniqueObjects.push({ id: obj.id, label: obj.label });
            return true;
          }

          // If the combination of label and id is already in the array, filter it out
          return false;
        });

        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));


        setDynamicData(uniqueObjects);
        setequpdata(equiplist);
        setEquipmentDatalist(equipmentdata)
        // consol("fetching data", equipmentdata);

      } catch (error) {
        // console.error("Error fetching data:", error);
        setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts

  const getQualityReason = (ReasonArr) => {
    let returnArr = []
    if (dynamicData.length == 0) {
      returnArr.push({ DowntimeGroup: 'default', Duration: 0 });
    } else {
      dynamicData.forEach((one1) => {
        let filterReason = ReasonArr.filter(obj => obj?.DowntimeGroup == one1.label)
        if (filterReason.length > 0) {
          returnArr.push({ ...filterReason[0] })
        } else {
          let obj = { DowntimeGroup: one1.label, Duration: 0 }
          returnArr.push(obj)
        }
      })
    }

    return returnArr;
  }


  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
      "Equipment",
      "Shift",
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }

    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Equipment") {

      let selected = [];
      newSelectedItems.forEach((item) => {
        let selection = EquipmentDatalist.filter(equipment => equipment.EquipmentID === item);
        selected.push(selection[0])
        inputData['Equips'] = selected
      })
    } else {
      inputData[name] = newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

    // consol(selectedDates,"current date")
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
    const dateTime = { ...selectedDates };
    inputData.From = dateTime.From;
    inputData.Till = dateTime.Till;
    inputData.Report = "EquipmentWiseProductionData"

    const errors = validate.validate(inputData, schema);

    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true);
    try {
      const result = await axios.post(api.api + '/DailyDetailedProductionData', inputData);
      const equipResponse = result.data;

      // consol(equipResponse,"received data",inputData)
      setcolumns2(equipResponse)

      setQuerySuccess(false);
    } catch (error) {
      swal(`${error}`);
      // consol(error);
      setQuerySuccess(false);
    }



  };




  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };


  // consol(datacount,"index number of data")
  return (
    <div className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Equipment Wise Report
        </h4>
      </div>
      <div className="row mx-3">
        {equpdata.length > 0 ? <>
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">From</label>
              <input
                style={{ borderColor: "#eaeaea" }}
                className={
                  "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
                }
                min={fromDateLimit}
                placeholder="dd/mm/yyyy"
                type="date"
                onChange={handleOnChange}
                name="From"
              />
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">Till</label>
              <input
                style={{ borderColor: "#eaeaea" }}
                className={
                  "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
                }
                placeholder="dd/mm/yyyy"
                type="date"
                onChange={handleOnChange}
                name="Till"
              />
            </div>
          </div>

          {DropDownData.map((data, index) => {
            return (
              <>
                <div className="col-sm-2">
                  <div className="form-group text-center">
                    <label className="small">{data.heading}</label>
                    {/* {componentdata.length > 0 && ( */}
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length ? "All" : selectedItemLengths[data.name] || 0}  ${data.heading}`}

                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar}
                    />
                    {/* )} */}
                    {errors[data.heading] && (
                      <p className="text-danger" style={{ fontSize: 11 }}>
                        {errors[data.heading]}
                      </p>
                    )}
                  </div>
                </div>
              </>
            );
          })}

          <div className="col-sm-1">
            <button
              className="btn btn-primary btn-sm mt-4"
              onClick={() => handleOnSubmit()}
            >
              Apply
            </button>
          </div>

        </> : ""}
      </div>
      {columns2.length > 0 ?
        <div className="rounded shadow my-4 " style={{ width: '100%', overflowX: 'scroll' }}>
          <div className="d-flex justify-content-start align-items-center  ">
            <div className="uiverse mx-2" onClick={() =>
              handleDownloadExcel("equipment_wise_report")}>
              <span className="tooltip">Dowload Excel File</span>
              <span>
                Download
              </span>
            </div>

          </div>

          <table id={'equipment_wise_report'} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0 }}>
            <thead className='small text-center border'>

              <tr className='text-center align-self-center table-primary'>

                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Date</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Shift</th>


                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Machine</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Operator</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Total Operators</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Part Name</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Process Name</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Planned Prod Time (mins) </th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Target Qty</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Quantity Produced</th>

                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Reject Qty</th>

                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Actual OK Qty</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Actual Time (mins)</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Total Lost (mins)</th>

                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Planned Downtime (mins)</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Unplanned Downtime (mins)</th>

                <th style={{ verticalAlign: 'middle' }} colSpan={dynamicData.length} >Downtime Reason Group (mins)</th>

                <th style={{ verticalAlign: 'middle' }} colSpan={7} ></th>




              </tr>

              <tr className='table-primary' >


                {dynamicData.length > 0 ? dynamicData.map(one => {
                  return <th className="rotate p-0 m-0 text-start" style={{ height: (100 + dynamicData.length) * 2.8 }} ><div style={{ marginTop: '1rem' }}><span className="text-start">{one.label}</span></div></th>
                }) : ''}



                <th style={{ verticalAlign: 'middle' }}  >Available Time  (mins)</th>
                <th style={{ verticalAlign: 'middle' }}  > Available Time Production Target </th>

                <th style={{ verticalAlign: 'middle' }} >AR (%)</th>
                <th style={{ verticalAlign: 'middle' }}  >PR (%)</th>
                <th style={{ verticalAlign: 'middle' }}  >QR (%)</th>
                <th style={{ verticalAlign: 'middle' }} >OEE (%)</th>
              </tr>

            </thead>
            <tbody>

              {columns2.map(one => <tr >
                <th className="text-center" style={{ fontWeight: '400' }}>{` ${moment(one.ShiftOf).utcOffset('+05:30').format('DD-MM-YYYY ')}`}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.Shift}</th>

                <th className="text-center" style={{ fontWeight: '400' }}>{one.Equipment}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.Operator}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.TotalOperators}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.PartName}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.ProcessName}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.PlannedHrs}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.TargetQty}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.QuantityProduced}</th>
                <th className="text-center" style={{ fontWeight: '400' }}>{one.Reject_Qty}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.Actual_OK_Qty}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.ActualHrs}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.TotalLostHrs}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.PlannedDowntime}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.UnplannedDowntime}</th>

                {getQualityReason(one.DowntimeReason).length > 0 ? getQualityReason(one.DowntimeReason).map(r => {
                  return <td >{r.Duration == 0 ? '-' : r.Duration}</td>
                }) : <td >0</td>}

                <th style={{ fontWeight: '400' }} className="text-center">{one.AvailableTime}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.AvailableTimeProdTarget}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.AR}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.PR}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.QR}</th>
                <th style={{ fontWeight: '400' }} className="text-center">{one.OEE}</th>


              </tr>
              )}


            </tbody>
          </table>
        </div> : ''}

    </div>
  );
}

export default EquipmentWiseProductionData;
