import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';

import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import DateSelection from "../Common/DateSelection";
const Refrence = [
  { label: "Equipment", id: "1" },
  { label: "Component", id: "2" },
  { label: "Operator", id: "3" },
  { label: "Tool", id: "4" },
  { label: "Operation", id: "5" },
  { label: "Time", id: "6" },
  { label: "Stage Reasons", id: "7" },
  { label: "Stage", id: "8" },
];

function QualityRejectionStageReport({fromDateLimit,storeDataInLocalStorage}) {
  const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);
  const [periodOption, setperiodOption] = useState([
    { label: "Shift", id: "1" },
    { label: "Daily", id: "2" },
    { label: "Weekly", id: "3" },
    { label: "Monthly", id: "4" },
    { label: "Cummulative", id: "5" },
  ]);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  const [componentdata, setcomponentdata] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [operatordata, setoperatordata] = useState([]);
  const [tooldata, settooldata] = useState([]);
  const [operationdata, setoperationdata] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [rejstage, setrejstage] = useState([]);

  const [selectedDates, setSelectedDates] = useState({
    From: "",
    Till: "",
  });
  const [Compareref, setCompareref] = useState([]);
  const [root, setroot] = useState([]);
  const [test, settest] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "Date", order: "asc" });
  const [columns2, setcolumns2] = useState();
  const [selectedItemLengths, setSelectedItemLengths] = useState({});



  // Your existing chart code goes here

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    {
      heading: "Equipment",
      label: "Equipment",
      initialItems: equpdata,
      selectedItemIds: selectedItemIds,
      name: "Equipment",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,

    },
    {
      heading: "Operation",
      label: "Operation",
      initialItems: operationdata,
      selectedItemIds: selectedItemIds,
      name: "Operation",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,

    },
    {
      heading: "Component",
      label: "Operator",
      initialItems: componentdata,
      selectedItemIds: selectedItemIds,
      name: "Component",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,

    },
    {
      heading: "Operator",
      label: "Operator",
      initialItems: operatordata,
      selectedItemIds: selectedItemIds,
      name: "Operator",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,

    },
    {
      heading: "Stage",
      label: "Stage",
      initialItems: rejstage,
      selectedItemIds: selectedItemIds,
      name: "RejectionStages",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: false,
      selectAll:true,

    },
    {
      heading: "Shift",
      label: "tablecolumns",
      initialItems: shiftOption,
      selectedItemIds: selectedItemIds,
      name: "Shift",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,

    },
    {
      heading: "Reasons",
      label: "tablecolumns",
      initialItems: dynamicData,
      selectedItemIds: selectedItemIds,
      name: "Reasons",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,

    },
    {
      heading: "Tool",
      label: "tablecolumns",
      initialItems: tooldata,
      selectedItemIds: selectedItemIds,
      name: "Tool",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,

    },
 
  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////

  const column2 = [
    { path: "ShiftOf", label: "Date" },
    { path: "Shift", label: "Shift" },
    { path: "Equipment", label: "Equipment" },
    { path: "Tool", label: "Tool" },
    { path: "Operation", label: "Operation" },
    { path: "Component", label: "Component" },
    { path: "Stage", label: "Stage" },
    { path: "Count", label: "Count" },
    { path: "Reason", label: "Reason" },
    { path: "CyclesRun", label: "Cycles Run" },
    { path: "PartsProduced", label: "Parts Produced" },
    { path: "AcceptedParts", label: "Accepted Parts" },
    { path: "TotalRejection", label: "Total Rejection" },

  ];

  const schema = {
    Equipment: joi.array().required().label("Equipment"),
    Component: joi.array().required().label("Component"),
    Operator: joi.array().required().label("Operator"),
    Shift: joi.array().required().label("please select at least one shift"),
    Tool: joi.array().required().label("Tool"),
    // Periodicity: joi.array().required().label("Periodicity"),
    Reasons: joi.array().required().label("Reasons"),
    Operation: joi.array().required().label("Operation"),
    // Refrence: joi.array().required().label("Refrence"),
    RejectionStages: joi.array().required().label("Stage"),
  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
      setQuerySuccess(true);

        // const qualitydata = await axios.get(api.api + "qualityreason");
        // const componentdata = await axios.get(api.api1 + "component");
        // const operatordata = await axios.get(api.api1 + "operator");
        // const tooldata = await axios.get(api.api1 + "tool");
        // const equipmentdata = await axios.get(api.api1 + "equipment");
        // const rejectionstages = await axios.get(api.api + "rejectionstage");


        let qualitydata = await storeDataInLocalStorage('qualityreason')
        let componentdata = await storeDataInLocalStorage('component')
        let operatordata = await storeDataInLocalStorage('operator')
        let tooldata = await storeDataInLocalStorage('tool')
        let equipmentdata = await storeDataInLocalStorage('equipment')
        let rejectionstages = await storeDataInLocalStorage('rejectionstage')


        const qualitylist = qualitydata
          .map((reason, index) => ({
            id: `${index}`,
            label: reason.QualityReason,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        const complist = componentdata
          .map((componentList, index) => ({
            id: `${index}`,
            label: componentList.ComponentId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        // Iterate through the data and extract operation IDs
        const operationIds = [];
        componentdata.forEach((item) => {
          item.Operation.forEach((operation) => {
            operationIds.push(operation.OperationID);
          });
        });
        // Sort and remove duplicates from operationIds
        const uniqueSortedOperations = [...new Set(operationIds)]
          .sort((a, b) => a - b)
          .map((operation, index) => ({ id: index, label: operation }));
        const operatorlist = operatordata
          .map((operator, index) => ({
            id: `${index}`,
            label: operator.Name,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        const toollist = tooldata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.ToolId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        const stagelist = rejectionstages
          .map((stage, index) => ({
            id: `${index}`,
            label: stage.Stage,
          }))
          .slice()
          .sort((a, b) => a.id.localeCompare(b.id));
        setrejstage(stagelist);
        setequpdata(equiplist);
        setoperationdata(uniqueSortedOperations);
        settooldata(toollist);
        setoperatordata(operatorlist.sort((a, b) => a[1] - b[1]));
        setDynamicData(qualitylist);
        setcomponentdata(complist);
        // consol("fetching data", qualitylist);
        
      } catch (error) {
        // console.error("Error fetching data:", error);
      setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts





  const getQualityReason = (ReasonArr) => {
    let returnArr = []
    if (dynamicData.length == 0) {
        returnArr.push({ Reason: 'default', Count: 0 });
    } else {
      dynamicData.forEach((one1) => {
            let filterReason = ReasonArr.filter(obj => obj.Reason == one1.label)
            if (filterReason.length > 0) {
                returnArr.push({ ...filterReason[0] })
            } else {
                let obj = { Reason: one1.label, Count: 0 }
                returnArr.push(obj)
            }
        })
    }

    return returnArr;
}








  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
      "Equipment",
      "Component",
      "Operation",
      "Operator",
      "Shift",
      "Tool",
      "Reasons",
      "RejectionStages",
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));

    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Reasons") {
      inputData[name] = newSelectedItems;
    } else {
      inputData[name] =
        newSelectedItems.length == totallength ? ["all"] : newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData);
    setsenddata(inputData);
    setCompareref(inputData.Refrence);
  };




  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  let handleOnSubmit = () => {
    let inputData = { ...senddata };

    const errors = validate.validate(inputData, schema);

    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true);
    let dateTime = { ...selectedDates };
    if(new Date(dateTime.From) <= new Date(dateTime.Till)){
      inputData.From = dateTime.From;
      inputData.Till = dateTime.Till;
    }else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    const compressedData = pako.gzip(JSON.stringify(inputData));

    axios
      .post(api.api + "GetQualityReport", compressedData, {
        headers: {
          "Content-Encoding": "gzip", // Set the content encoding to gzip
          "Content-Type": "application/json", // Set the content type to JSON
        },
      })
      // const result = await axios.post(api.api + 'GetQualityAnalysis', inputData)
      .then((result) => {
        const decompressedData = pako.ungzip(result.data, { to: "string" });

        // Assuming the decompressed data is in JSON format, parse it
        const jsonData = JSON.parse(decompressedData);
        // consol(jsonData, "Rejection result received");

        if (jsonData !== null && jsonData !== undefined) {
          if (jsonData.length > 0) {
// First, get all unique stages
const uniqueStagesArray = [];

jsonData.forEach((one) => {
    one.StageSummary.forEach((one2) => {
        const stage = one2.stage;
        if (!uniqueStagesArray.includes(stage)) {
            uniqueStagesArray.push(stage);
        }
    });
});

// Then, update each StageSummary to have all unique stages
jsonData.forEach((one) => {
    const updatedStageSummary = uniqueStagesArray.map((stage) => {
        const stageObj = one.StageSummary.find((s) => s.stage === stage);
        if (stageObj) {
            return stageObj;
        } else {
            return { stage: stage, Rejection: [] }; // Add a blank entry if stage not present
        }
    });
    one.StageSummary = updatedStageSummary;
});

            const uniqueStagesSet = new Set(uniqueStagesArray);
            const uniqueStagesArray1 = Array.from(uniqueStagesSet);
            // consol("hellley",uniqueStagesArray1)
 let datesort = [...jsonData]    
         setcolumns2(uniqueStagesArray1)
         settest(datesort.sort((a, b) => {
          const dateA = new Date(a.ShiftOf);
          const dateB = new Date(b.ShiftOf);
          return dateA - dateB;
        }))
            setQuerySuccess(false);
          } else {
            swal("Data Not Available");
            setQuerySuccess(false);
          }
        } else {
          setQuerySuccess(false);
        }
      })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err);
        setQuerySuccess(false);
      });
  };

 

  // consol(root, "current root");
  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };

  const SelectedDates =(dates)=>{
    setSelectedDates(dates)
  }
 
  return (
    <div  className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Quality Report
        </h4>
      </div>
      <ToastContainer/>
      <div className="row mx-3">
       {componentdata.length > 0 ?  <>
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

       {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
              />
          </div>
        </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              />
          </div>
        </div> */}
            

        {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  {/* {componentdata.length > 0 && ( */}
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${
                        selectedItemLengths[data.name] ==
                        data.initialItems.length
                          ? "All"
                          : selectedItemLengths[data.name] || 0
                      }  ${data.heading}`}
                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar}
                    selectAll={data.selectAll}

                    />
                  {/* )} */}
                  {errors[data.heading] && (
                    <p className="text-danger" style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}

<div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div>

 </>:""}
     
             
    </div>



   {test.length >0 ?<div style={{width:'100%'}}>

   <div className="uiverse" onClick={() =>
      handleDownloadExcel("Qualitystagerejection")}>
    <span className="tooltip">Dowload Excel File</span>
    <span>
    Download
    </span>
</div>

   <table id={'Qualitystagerejection'} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0 }}>
            <thead className='small text-center border'>
                <tr className='text-center align-self-center table-primary'>
                  
                
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Date</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Shift</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Equipment</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Component</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Tool</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Operation</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Cycles Run</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Parts Produced</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Accepted Parts</th>
              
              {columns2.map(item=> <th style={{ verticalAlign: 'middle' }} colSpan={dynamicData.length}>{item +" " + "Stage"}</th>)}
              <th  colSpan={1} ></th>
                </tr>

                <tr className='table-primary' >
                {columns2.map(item=> {return dynamicData.map(one => {
                        return <th className="rotate p-0 m-0 text-start" style={{  height: (100 + dynamicData.length) * 2.8 }} ><div  style={{marginTop:'1rem'}}><span className="text-start">{one.label}</span></div></th>
                    }) } )}
                  
                {/* {dynamicData.length > 0 ? dynamicData.map(one => {
                        return <th className="rotate p-0 m-0 text-start" style={{  height: (100 + dynamicData.length) * 2.8 }} ><div  style={{marginTop:'1rem'}}><span className="text-start">{one.label}</span></div></th>
                    }) : ''}
                      {dynamicData.length > 0 ? dynamicData.map(one => {
                        return <th className="rotate p-0 m-0 text-start" style={{  height: (100 + dynamicData.length) * 2.8 }} ><div  style={{marginTop:'1rem'}}><span className="text-start">{one.label}</span></div></th>
                    }) : ''} */}
  <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Total Rejection</th>
                </tr>
               
            </thead>
            <tbody>
                {test.map(one => {
                  
                    return <>
                   
                   
                    <tr >
                    <th className="text-center" style={{fontWeight:'400'}}>{moment(one.ShiftOf).format('DD/MM/YY')}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Shift}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Equipment}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Component}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Tool}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Operation}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.CyclesRun}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.PartsProduced}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.AcceptedParts}</th>
                 


                   
                    {/* {getQualityReason(one.RejectionReason).length > 0 ? getQualityReason(one.RejectionReason).map(r => {
                            return <td >{r.Count}</td>
                        }): <td >0</td>}
      {getQualityReason(one.RejectionReason).length > 0 ? getQualityReason(one.RejectionReason).map(r => {
                            return <td >{r.Count}</td>
                        }): <td >0</td>} */}

{one.StageSummary.map(one2 => (
  <>
    {columns2.map(item => {
      let matchFound = false;
      if (item === one2.stage && getQualityReason(one2.Rejection).length > 0) {
        matchFound = true;
        return getQualityReason(one2.Rejection).map(r => (
          <td>{r.Count === 0 ? '0' : r.Count}</td>
        ));
      } 
    })}
  </>
))}

{columns2.slice(0,columns2.length -1).map(item=>{ return one.StageSummary.length == 1 ? one.StageSummary.map(one2 => (
    <>
    {getQualityReason(one2.Rejection).map(r => {
                            return <td >-</td>
                        })}
    
    </>
 
)):''})}

                    <th style={{fontWeight:'400'}} className="text-center">{one.TotalRejection}</th>                      
                    </tr>               
                    </>

                })}

                
            </tbody>
        </table></div>:''}








 

    </div>
  );
}

export default QualityRejectionStageReport;
