import React, { useState, useEffect ,useRef} from 'react';
import Table from '../Common/table';
import ColumnChart from '../Charts/ColumnChart';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _, { isNumber } from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import 'bootstrap/dist/js/bootstrap'
import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import { Chart } from 'react-google-charts';
import EquipmentSelection from '../Common/equipmentSelection';
import DateSelection from '../Common/DateSelection';
import { ToastContainer, toast } from 'react-toastify';
import DownloadButton from '../utill/ChartImageDownload';

const AvailabilityTrend = ({ user, equipGroups,fromDateLimit }) => {
    const [availabilityData, setAvailabilityData] = useState([])
    const [inputData, setInputData] = useState({})
    const [equip, SetEquips] = useState([])
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([])
    const [errors, setErrors] = useState({})
    const [querySuccess, setQuerySuccess] = useState(false)
    const [chartData, setChartData] = useState([]);
    const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })
    const chartRef = useRef(null);
    const schema = {
        From: joi.date().required().label('From'),
        Till: joi.date().required().label('Till'),
        EquipmentGroup: joi.array().min(1).required(),
        Equipment: joi.array().min(1).required(),
        Periodicity: joi.string().required(),
        UserId: joi.string(),
    }
    const columnsForShift = [
        { path: 'DateTime', label: 'From', content: (obj) => <label>{date.getDateFormat(obj.DateTime)}</label> },
        { path: 'DateTime1', label: 'Till', content: (obj) => <label>{date.getDateFormat(obj.DateTime1)}</label> },
        { path: 'Shift', label: 'Shift' },
        // { path: 'Equipment', label: 'Equipment' },
        { path: 'TotalTime', label: 'TotalTime (mins)' },
        { path: 'RunTime', label: 'RunTime (mins)' },
        { path: 'Break', label: 'Breaks (mins)' },
        { path: 'PlannedDowntime', label: 'Planned Downtime (mins)' },
        { path: 'UnplannedDowntime', label: 'Unplanned Downtime (mins)' },
        { path: 'Availability', label: 'Availability', content: (obj) => <label>{isNumber(obj.Availability) ? obj.Availability.toFixed(1) : obj.Availability}</label> },
    ]

    const columnForDaily = [
        { path: 'DateTime', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.DateTime)}</label> },
        { path: 'Shift', label: 'Shift' },
        // { path: 'Equipment', label: 'Equipment' },
        { path: 'TotalTime', label: 'TotalTime (mins)' },
        { path: 'RunTime', label: 'RunTime (mins)' },
        { path: 'Break', label: 'Breaks (mins)' },
        { path: 'PlannedDowntime', label: 'Planned Downtime (mins)' },
        { path: 'UnplannedDowntime', label: 'Unplanned Downtime (mins)' },
        { path: 'Availability', label: 'Availability', content: (obj) => <label>{isNumber(obj.Availability) ? obj.Availability.toFixed(1) : obj.Availability}</label> },
    ]

    const columnForWeekly = [
        { path: 'DateTime', label: 'From', content: (obj) => <label>{date.getDateFormat(obj.DateTime)}</label> },
        { path: 'DateTime1', label: 'Till', content: (obj) => <label>{date.getDateFormat(obj.DateTime1)}</label> },
        { path: 'Shift', label: 'Week' },
        // { path: 'Equipment', label: 'Equipment' },
        { path: 'TotalTime', label: 'TotalTime (mins)' },
        { path: 'RunTime', label: 'RunTime (mins)' },
        { path: 'Break', label: 'Breaks (mins)' },
        { path: 'PlannedDowntime', label: 'Planned Downtime (mins)' },
        { path: 'UnplannedDowntime', label: 'Unplanned Downtime (mins)' },
        { path: 'Availability', label: 'Availability', content: (obj) => <label>{isNumber(obj.Availability) ? obj.Availability.toFixed(1) : obj.Availability}</label> },
    ]
    const columnForMonthly = [
        { path: 'DateTime', label: 'From', content: (obj) => <label>{date.getDateFormat(obj.DateTime)}</label> },
        { path: 'DateTime1', label: 'Till', content: (obj) => <label>{date.getDateFormat(obj.DateTime1)}</label> },
        { path: 'Shift', label: 'Monthly' },
        // { path: 'Equipment', label: 'Equipment' },
        { path: 'TotalTime', label: 'TotalTime (mins)' },
        { path: 'RunTime', label: 'RunTime (mins)' },
        { path: 'Break', label: 'Breaks (mins)' },
        { path: 'PlannedDowntime', label: 'Planned Downtime (mins)' },
        { path: 'UnplannedDowntime', label: 'Unplanned Downtime (mins)' },
        { path: 'Availability', label: 'Availability', content: (obj) => <label>{isNumber(obj.Availability) ? obj.Availability.toFixed(1) : obj.Availability}</label> },
    ]

    const getColumns = (period) => {
        switch (period) {
            case 'Shift':
                return columnsForShift;
            case 'Weekly':
                return columnForWeekly;
            case 'Daily':
                return columnForDaily;
            case 'Monthly':
                return columnForMonthly;
            default:
                return []
        }
    }


    const handleOnChange = async (e) => {

        if (e.currentTarget.type === 'date') {
            // consol(new Date(e.currentTarget.value))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
            setInputData(inputData);
        }
        else if (e.currentTarget.type === 'time') {
            let Time = e.currentTarget.value.split(':');
            // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
            setInputData(inputData);
        }
        else if (e.currentTarget.name === 'EquipmentGroup') {
            inputData[e.currentTarget.name] = e.currentTarget.value;
            let filterObj = equipGroups.filter(one => one.EquipmentGroup == e.currentTarget.value)[0]
            let res = await axios.put(api.api1 + 'equipment', { EquipmentGroup: filterObj._id })
            SetEquips(res.data)

            setInputData(inputData);
        }
        else {
            inputData[e.currentTarget.name] = e.currentTarget.value;
            setInputData(inputData);
        }
        setErrors({})

    }
    const handleOnSubmit = (inputData) => {
        inputData.Equipment = selectedEquipOption;
        inputData.EquipmentGroup = selectedEquipGroupOption;
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setQuerySuccess(true)

        inputData.UserId = user._id
        axios.post(api.api + 'GetAvailabilityTrend', inputData).then((result) => {
            // consol(result.data)
            if (result.data.length > 0) {
                let arr = [['test', 'Availabilty (%)',{ role: 'annotation' }]]
                result.data.forEach(one => {
                    let arr1 = []
                    if (inputData.Periodicity == 'Weekly' || inputData.Periodicity == 'Monthly') {
                        arr1.push(date.getDateFormat(one.DateTime) + '-' + date.getDateFormat(one.DateTime1) + '/' + one.Shift);
                    } else {
                        arr1.push(date.getDateFormat(one.DateTime) + '-' + one.Shift);
                    }

                    arr1.push(one.Availability)
                    arr.push(arr1)
                    arr1.push((one.Availability).toFixed(1) +'%')

                })
                setChartData(arr)
                setAvailabilityData(result.data)
                setQuerySuccess(false)
            }
            else {
                swal('Data Not Available');
                setQuerySuccess(false)
            }
        })
            .catch((err) => {
                  toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                setQuerySuccess(false)
                // consol(err)
            })
    }
    const handleDonwLoad = (e, date) => {
        generateExcelFile(e, date)
    }

    const handleSort = (sortColumn) => {
        if (availabilityData.length > 0) {
            const sorted = _.orderBy(
                availabilityData,
                [sortColumn.path],
                [sortColumn.order]
            );
            setAvailabilityData(sorted)
            setSortColumn(sortColumn)
        }
    }

    const handleOnChangeSelect = async (e, value, key) => {
        let option;
        if (key == 'EquipmentGroup') {
            option = [...selectedEquipGroupOption];
        }
        if (key == 'Equipment') {
            option = [...selectedEquipOption];
        }

        if (value == 'All') {
            if (e.currentTarget.checked) {
                let obj = {}
                obj.label = value;
                obj.value = e.currentTarget.id;
                option.push(obj);
                if (key == 'Equipment') {
                    equip.forEach(one => {
                        let obj = {}
                        obj.label = one['EquipmentID'];
                        obj.value = one._id;
                        option.push(obj);
                    })
                } else {
                    equipGroups.forEach(one => {
                        let obj = {}
                        obj.label = one[e.currentTarget.name];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }

            } else {
                option.splice(0, option.length);
            }
        } else {
            if (e.currentTarget.checked) {
                let obj = {}
                obj.label = value;
                obj.value = e.currentTarget.id;
                option.push(obj);

            } else {
                option = option.filter(one => one.value !== 'All');
                option = option.filter(one => one.value !== e.currentTarget.id);

            }
        }
        let query = []
        option.forEach(one => {
            let obj = {}
            if (one.value !== 'All') {
                obj.EquipmentGroup = one.value;
                query.push(obj)
            }
        })

        option = _.uniqWith(
            option,
            (a, b) =>
                a.value === b.value
        );
        if (key == 'EquipmentGroup') {
            if (query.length > 0) {
                let res = await axios.put(api.api1 + 'equipment', { $or: query })
                SetEquips(res.data)
            }

            setSelectedEquipGroupOption(option);
        }
        if (key == 'Equipment') {
            setSelectedEquipOption(option)
        }

    }

    const getCheckeStatus = (value, key) => {
        let option;
        if (key == 'EquipmentGroup') {
            option = [...selectedEquipGroupOption];
        }
        if (key == 'Equipment') {
            option = [...selectedEquipOption];
        }
        let filterData = option.filter(one => value == one.value)
        if (filterData.length > 0) {
            return true
        } else {
            return false
        }
    }


    const chartRender = (data) => {
        let mac = '';
        selectedEquipOption.forEach(one => {
            if (one.value !== 'All') {
                mac = mac + ' , ' + one.label;
            }
        })


        return   <div>
        <div ref={chartRef}>
          <Chart
            width={'100%'}
            height={'500px'}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
              chart: {
                title: 'Availability Trend',
                subtitle: 'Trend Report for ' + mac,
              },
              vAxis: {
                title: 'Availability ( In Percentage )'
              },
              hAxis: {
                title: 'Date-Shift',
                slantedText: true,
                slantedTextAngle: 90,
                textStyle: { fontSize: 13 },
              },
            }}
            rootProps={{ 'data-testid': '2' }}
          />
        </div>
        {/* <button onClick={downloadHTML}>Download Chart</button> */}
      </div>
    }


    const getCount = (data) => {
        let count = 0
        if (data.filter(one => one.value == 'All').length > 0) {
            count = data.length - 1;
        } else {
            count = data.length;
        }

        return count
    }
    const setSelection=(key,option,equips)=>{
        if(key=='EquipmentGroup'){
            SetEquips(equips?equips:[]);
            setSelectedEquipGroupOption(option)
            setSelectedEquipOption([])
        }
        if(key=='Equipment'){
           
            setSelectedEquipOption(option)
        }
    }
    const SelectedDates =(dates)=>{
        setInputData(dates)
      }

      const downloadHTML = () => {
        const chartContainer = chartRef.current;
        const htmlContent = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Interactive Chart</title>
            <script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
            <script type="text/javascript">
              google.charts.load('current', {'packages':['corechart', 'bar']});
              google.charts.setOnLoadCallback(drawChart);
              function drawChart() {
                var data = google.visualization.arrayToDataTable(${JSON.stringify(chartData)});
                var options = {
                  chart: {
                    title: 'Availability Trend',
                    subtitle: 'Trend Report for ',
                  },
                  vAxis: {
                    title: 'Availability ( In Percentage )'
                  },
                  hAxis: {
                    title: 'Date-Shift',
                    slantedText: true,
                    slantedTextAngle: 90,
                    textStyle: { fontSize: 13 },
                  },
                };
                var chart = new google.visualization.ColumnChart(document.getElementById('chart_div'));
                chart.draw(data, options);
              }
            </script>
          </head>
          <body>
            <div id="chart_div" style="width: 100%; height: 500px;"></div>
          </body>
          </html>
        `;
    
        const blob = new Blob([htmlContent], { type: 'text/html' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'chart.html';
        link.click();
      };
    
    return (
        <React.Fragment>              <ToastContainer/>
            {querySuccess ? <Loading /> : ''}
            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                Availability Trend
                </h4>
            </div>
            <div className="row ml-2">
            { equipGroups && equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}
                {/* <div className="col-sm-3">
                    <div className='form-group text-center'>
                        <label className='small'>Equipment Group</label>
                        <div id="list1" className="dropdown p-0 m-0  form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0  btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {getCount(selectedEquipGroupOption) > 0 ? getCount(selectedEquipGroupOption) + ' Equipment Group' : 'Select Equipment Group'}
                            </button>
                            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'EquipmentGroup')} onChange={(e) => handleOnChangeSelect(e, 'All', 'EquipmentGroup')} id='All' name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;All </li>
                                {equipGroups.map((one) => {
                                    return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'EquipmentGroup')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentGroup, 'EquipmentGroup')} name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;{one.EquipmentGroup} </li>
                                })}
                            </ul>
                        </div>
                        {errors.EquipmentGroup && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.EquipmentGroup}</p>}

                    </div>
                </div>
                <div className="col-sm-3">
                    <div className='form-group text-center'>
                        <label className='small'>Equipment</label>
                        <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {getCount(selectedEquipOption) > 0 ? getCount(selectedEquipOption) + ' Equipment' : 'Select Equipment'}
                            </button>
                            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'Equipment')} onChange={(e) => handleOnChangeSelect(e, 'All', 'Equipment')} id='All' name='Equipment' />&nbsp;&nbsp;&nbsp;All </li>
                                {equip.map((one) => {
                                    return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'Equipment')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentID, 'Equipment')} name='Equipment' />&nbsp;&nbsp;&nbsp;{one.EquipmentID} </li>
                                })}
                            </ul>
                        </div>
                        {errors.Equipment && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Equipment}</p>}


                    </div>
                </div> */}

<DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
{/* 
                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>From</label>
                        <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
                        {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                    </div>
                    </div> */}
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
                {/* <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Till</label>
                        <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                        {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                    </div>
                    </div> */}
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
                <div className="col-sm-1">
                    <div className='form-group text-center'>
                        <label className='small'>Periodicity</label>
                        <select className='form-control form-control-sm' onChange={handleOnChange} name='Periodicity'>
                            <option>--Select Periodicity--</option>
                            <option>Shift</option>
                            <option>Daily</option>
                            <option>Weekly</option>
                            <option>Monthly</option>
                        </select>
                        {errors.Periodicity && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Periodicity}</p>}
                    </div>
                </div>
                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>

            </div>
            <div>
            <DownloadButton targetRef={chartRef} filename="chart.png" />
                <div  id="chart-container"   ref={chartRef}>
                {availabilityData.length > 0 ? chartRender(chartData) : ''}
                </div>
                <div>
                    {availabilityData.length > 0 ? <div className="col-sm-2">
                        <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDonwLoad('AvailabilityTrend')}>Download Excel</button>
                    </div> : ''}
                    {availabilityData.length > 0 ? <Table id='AvailabilityTrend' onSort={handleSort} columns={getColumns(inputData.Periodicity)} sortColumn={sortColumn} data={availabilityData} /> : ''}
                </div>
            </div>
        </React.Fragment>


    );
}

export default AvailabilityTrend;