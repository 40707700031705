import React, { Component } from 'react';
import swal from 'sweetalert';
import endPoint from '../../../config';
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';




class PartsPerCycleReason extends Component {
    state = {
        data: {},
        oldObj: {},
        errors: {},
        querySuccess: false

    }
    schema = {

        Reason: joi.string().required().label('Reason'),
        Default: joi.boolean().default(false)
    }

    componentDidMount() {
        // consol('QualityGroup')
        if (this.props.actionStatus === 'add') {
            this.setState({ RejectionStage: {} })
        }
        if (this.props.actionStatus === 'update') {
            const QualityReasonGroup = { ...this.props.data }
            this.setState({ data: this.props.data, oldObj: QualityReasonGroup });
        }
    }

    handleOnChange = (e) => {
        const { data, errors } = { ...this.state };
        const errorMessage = valid.validateProperty(e.currentTarget, this.schema);
        if (errorMessage) errors[e.currentTarget.name] = errorMessage;
        else delete errors[e.currentTarget.name];

        data[e.currentTarget.name] = e.currentTarget.value;
        this.setState({ data, errors: errors || {} });


    }
    handleSubmit = (data) => {


        if (this.props.actionStatus === 'add') {
            const errors = valid.validate(data, this.schema);
            this.setState({ errors: errors || {} });
            if (errors) return;

            this.setState({ querySuccess: true });
            axios.post(endPoint.api + 'partspercyclereason', data)
                .then((result) => {
                    if (result.status === 200) {

                        this.setState({ querySuccess: false });
                        this.props.history.push('/setting/partspercyclereason/list')
                        swal('Save Data Successfully');
                    }
                    else {
                        this.setState({ querySuccess: false });
                        swal(`Data Not Saved`);
                    }
                })
                .catch((err) => {
                    this.setState({ querySuccess: false });
                    swal(`${err.response.data.message}`);
                })

        }
        if (this.props.actionStatus === 'update') {
            this.setState({ querySuccess: true });
            axios.patch(endPoint.api + 'partspercyclereason/' + data._id, { old: this.state.oldObj, new: data })
                .then((result) => {
                    const { n, nModified, ok } = result.data;
                    if (ok == 1 && n == 1 && nModified == 1) {
                        this.setState({ querySuccess: false });
                        this.props.history.push('/setting/partspercyclereason/list')
                        swal('Updated Data Successfully');
                    }
                    else {
                        this.setState({ querySuccess: false });
                        swal(`${result.data}`);
                    }
                })
                .catch((err) => {
                    this.setState({ querySuccess: false });
                      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                })

        }




    }




    render() {
        return (
            <React.Fragment>  <ToastContainer/>
                {this.state.querySuccess ? <Loading /> : ''}


                <div className=" border">
                    <div className="row justify-content-center">
                        <div className="col-sm-4 ">
                            <div className="form-group text-center">
                                <label>Reason</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder="Enter Reason"
                                    name="Reason"
                                    onChange={this.handleOnChange}
                                    defaultValue={this.state.data.Reason}
                                />
                                {this.state.errors.Reason && <div className="alert small alert-danger">{this.state.errors['Reason']}</div>}
                            </div>


                        </div>

                        <div className="col-sm-4 mt-4">
                            <div className="form-group text-center">
                                <label>Default&nbsp;&nbsp;&nbsp;</label>
                                <input
                                    type="checkbox"
                                    checked={this.state.data.Default ? true : false}
                                   
                                    placeholder="Enter Reason"
                                    onChange={(e)=>this.handleOnChange({currentTarget:{name:e.target.name,value:e.target.checked}})}
                                    name='Default'
                                />
                                {/* {this.state.errors.Stage && <div className="alert small alert-danger">{this.state.errors['Stage']}</div>} */}
                            </div>


                        </div>



                    </div>
                    <div className="text-center">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.handleSubmit(this.state.data);
                            }}
                        >
                            Submit
                        </button>
                    </div>
                    <div className="text-center" style={{ marginTop: 10 }}>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                this.props.history.push('/setting/partspercyclereason/list')
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default PartsPerCycleReason;