import React, { Component } from 'react';
import swal from 'sweetalert';
import endPoint from '../../../config';
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';

class OperatorMaster extends Component {
  state = { 
    operatorMaster:{},
    oldObj:{},
    errors:{}

 }

 schema={
   Name:joi.string().required().label('Operator Name'),
   OperatorID:joi.string().required().label('Operator ID')
 }

componentDidMount()
 {
   if(this.props.actionStatus==='add')
   {
     this.setState({operatorMaster:{}})
   }
   if(this.props.actionStatus==='update')
   {
     const operatorMaster={...this.props.operatorMasterData}
     this.setState({operatorMaster:this.props.operatorMasterData,oldObj:operatorMaster});
   }
 }

handleOnChange=(e)=>{
    const {operatorMaster,errors} ={...this.state};

    const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];



    operatorMaster[e.currentTarget.name]=e.currentTarget.value;
    this.setState({operatorMaster,errors:errors||{}});
    

}
handleSubmit=(data)=>{
 
 
  if(this.props.actionStatus==='add')
  {
    const errors = valid.validate(data,this.schema);
    this.setState({ errors: errors || {} });
    if (errors) return;
  
    this.setState({querySuccess:true});
    axios.post(endPoint.api1+'operator',data)
    .then((result)=>{
     if(result.status===200)
     {
      this.setState({querySuccess:false});
       this.props.history.push('/setting/operatormaster/list')
       swal('Save Data Successfully');
     }
     else
     {
      this.setState({querySuccess:false});
      swal(`Data Not Saved`);
     }
    })
    .catch((err)=>{
      this.setState({querySuccess:false});
      swal(`${err.response.data.message}`);
    })

  }
  if(this.props.actionStatus==='update')
  {
    this.setState({querySuccess:true});
    axios.patch(endPoint.api+'operator/'+data._id,{old:this.state.oldObj,new:data})
    .then((result)=>{
      const {n,nModified,ok}=result.data;
     if(ok==1&&n==1&&nModified==1)
     {
      this.setState({querySuccess:false});
       this.props.history.push('/setting/operatormaster/list')
       swal('Updated Data Successfully');
     }
     else
     {
      this.setState({querySuccess:false});
      swal(`${result.data}`);
     }
    })
    .catch((err)=>{
      this.setState({querySuccess:false});
      swal(`${err.response.data.message}`);
    })

  }
}



    render() { 
        return ( 
            <React.Fragment>
       
       {this.state.querySuccess?<Loading/>:''}
      
            <div className=" border">
              <div className="row justify-content-center">
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Operator Name"
                      name="Name"
                      onChange={this.handleOnChange}
                    defaultValue={this.state.operatorMaster.Name}
                    />
                    {this.state.errors.Name && <div className="alert small alert-danger">{this.state.errors['Name']}</div>}
                  </div>
    
                
                </div>

                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Employee ID</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Operator Id"
                      name="OperatorID"
                      onChange={this.handleOnChange}
                      defaultValue={this.state.operatorMaster.OperatorID}
                    />
                    {this.state.errors.OperatorID && <div className="alert small alert-danger">{this.state.errors['OperatorID']}</div>}
                  </div>
    
                
                </div>
    
               
               
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.operatorMaster);
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="text-center" style={{marginTop:10}}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push('/setting/operatormaster/list')
                  }}
                >
                 Cancel
                </button>
              </div>
            </div>
          </React.Fragment>
         );
    }
}
 
export default OperatorMaster;