import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import './index.css';
//import './User/Setting/QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './navbar.css';
import './utill/common';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie9';
// import 'babel-polyfill';
// import 'core-js/stable';
// import "regenerator-runtime/runtime";
// import 'unorm';

import App from './App';

import * as serviceWorker from './serviceWorker';



ReactDOM.render(
<BrowserRouter>
<App  />
</BrowserRouter>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA


serviceWorker.register();
