import React,{useEffect,useState} from 'react'

const DateSelection = ({SelectedDates,fromDateLimit}) => {

    const [inputData, setInputData] = useState({  From: '', // Initialize From date state
    Till: ''})
    const convertDateFormat = (dateString) => {
        const [month, day, year] = dateString.split('-');
        return `${month}-${day}-${year}`;
      };
    useEffect(() => {
        // Function to get today's date in 'YYYY-MM-DD' format
        const getTodayDate = () => {
          const today = new Date();
          let month = String(today.getMonth() + 1).padStart(2, '0');
          let day = String(today.getDate()).padStart(2, '0');
          const year = today.getFullYear();
    
          return `${year}-${month}-${day}`;
        };
        // Set today's date as the default value
  let preSelectedDate = {
     From:getTodayDate(),
       Till:getTodayDate()
  }
//   handleOnChange({currentTarget:{name:'Shift',value:'All',checked:true}});
  
        setInputData(preSelectedDate);
        SelectedDates(preSelectedDate)
      }, []);

      const handleOnChange =(e)=>{
        // if (e.currentTarget.type === 'date') {
            const { name, value } = e.target;
              // inputData[e.currentTarget.name]= convertDateFormat(e.currentTarget.value);
             
              setInputData(prevState => ({
                ...prevState,
                [name]: convertDateFormat(value) // Convert date format before setting
              }));
              SelectedDates({...inputData,[name]: convertDateFormat(value)})
        //   }
      }
  return (
    <>   <div className="col-sm-2">
    <div className="form-group text-center">
      <label className='small'>From</label>
      {/* <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' /> */}
      <input
className='form-control form-control-sm'
min={fromDateLimit}
type='date'
onChange={handleOnChange}
name='From'
value={inputData.From} // Set the default date value here
/>
      {/* {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>} */}
    </div>
  </div>
  <div className="col-sm-2">
    <div className="form-group text-center">
      <label className='small'>Till</label>
      <input  className='form-control form-control-sm' type='date'
       onChange={handleOnChange} 
        value={inputData.Till} // Set the default date value here
        name='Till' />
      {/* {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>} */}
    </div>
  </div></>
  )
}

export default DateSelection