import React, { Component } from "react";
import Table from "../../../Common/table";
//import "../QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faPlus,
  faFileImport,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import dateTime from "../../../utill/dateTime";
import swal from "sweetalert";
import api from "../../../config";
import axios from "../../../httpServices/httpService";
import _ from "lodash";
import Loading from "../../../utill/LoadingComponent";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx';
import valid from '../../../utill/validation';
import joi from 'joi';
import {Modal} from 'react-bootstrap';

class EquipmentHourlyRateTable extends Component {
  state = {
    equipmentList: [],
    excelData:[],
    arrayProcessIndex:0,
    errorModal:false,
    querySuccess:false,
    errorMessage:'',
    errorFlag:false,
    rowNumber:0,
    errors:{},
    objKey:'',
    sortColumn: { path: "EquipmentType", order: "asc" },
  };

  handleSort = (sortColumn) => {
    if (this.state.equipmentList.length > 0) {
      const sorted = _.orderBy(
        this.state.equipmentList,
        [sortColumn.path],
        [sortColumn.order]
      );
      this.setState({ sortColumn, equipmentList: sorted });
    }
  };

  schema = {
    // PartsPreventiveMaintenance: joi.number().min(0).required().label('PartsPreventiveMaintenance'),
    // PartsSinceLastPreventiveMaintenance: joi.number().min(0).required().label('PartsSinceLastPreventiveMaintenance'),
    // LastPreventiveMaintenanceDate: joi.date().required().label('LastPreventiveMaintenanceDate'),
    // MaxDaysPreventiveMaintenance: joi.number().min(0).required().label('MaxDaysPreventiveMaintenance'),
    // DeviceConfig:joi.string().required().label('Device Config'),
    // DownTimeThresold: joi.number().required().min(0).label('DownTime Thresold'),
    // DeviceChannel: joi.string().required().label('Device Channel'),
    // IdealCycleTime: joi.number().required().min(0).label('Ideal Cycle Time'),
    // DeviceID: joi.string().required().label('Device ID'),
    EquipmentID: joi.string().required().label('Equipment Id'),

    EquipmentGroup: joi.string().required().label('Equipment Group'),
    // EquipmentType: joi.string().required().label('Equipment Type'),
  }

  componentDidMount() {
    this.setState({ querySuccess: true });
    axios
      .get(api.api1 + "equipment")
      .then((result) => {
        if (result.data.length > 0) {
          this.setState({ equipmentList: result.data, querySuccess: false });
        }
        else{
          this.setState({querySuccess:false})
        }
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
  }

  onDelete = (data) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        axios
          .delete(api.api + "equipment/"+data._id, { data: data})
          .then((result) => {
            if (result.data.ok === 1 && result.data.deletedCount === 1) {
              let filterData = this.state.equipmentList.filter(
                (obj) => obj._id !== data._id
              );
              this.setState({ equipmentList: filterData, querySuccess: false });
            }
          })
          .catch((err) => {
            this.setState({ querySuccess: false });
            swal(`${err.response.data.message}`);
          });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  columns = [
    // { path: "EquipmentType", label: "Equipment Type" },
    // { path: "EquipmentGroup", label: "Equipment Group" },
    { path: "EquipmentID", label: "Equipment Id" },
    
    // { path: "IdealCycleTime", label: "Ideal Cycle Time" },
    // { path: "DeviceID", label: "Device ID" },
    // { path: "DeviceChannel", label: "Device Channel" },
    // { path: "DownTimeThresold", label: "Downtime Thresold" },
    { path: "HourlyRate", label: "Hourly rate" },

    // {
    //   path: "PartsPreventiveMaintenance",
    //   label: "Parts / Preventive Maintenence",
    // },
    // {
    //   path: "PartsSinceLastPreventiveMaintenance",
    //   label: "Parts Since Last Preventive Maintenence",
    // },
    // {
    //   path: "MaxDaysPreventiveMaintenance",
    //   label: "MaxDays / Preventive Maintenence",
    // },
    // {
    //   path: "LastPreventiveMaintenanceDate",
    //   label: "Last Preventive Maintenencedate",

    //   content: (obj) => (
    //     <label>
    //       {new Date(obj.LastPreventiveMaintenanceDate).getDate() +
    //         "-" +
    //         (new Date(obj.LastPreventiveMaintenanceDate).getMonth() + 1) +
    //         "-" +
    //         new Date(obj.LastPreventiveMaintenanceDate).getFullYear()}
    //     </label>
    //   ),
    // },

    {
      key: "createdAt",
      label: "Created Date",
      content: (obj) => (
        <React.Fragment>
          <label>{dateTime.getStringOfDateTime(obj.createdAt)}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      key: "updatedAt",
      label: "Last Updated Date",
      content: (obj) => (
        <React.Fragment>
          <label>{dateTime.getStringOfDateTime(obj.updatedAt)}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      key: "update",
      content: (user) => (
        <button
          onClick={() => this.props.onUpdate(user)}
          className="btn btn-sm btn-primary"
          disabled={!this.props.getRightsForAccess('StaticDataRights','EquipmentMaster','update')}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      ),
    },
    // { key: 'view', content: user => <button onClick={() => this.props.onView(user)} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faEye} /></button> },
    
  ];



  handleUploadGuagesDoc = () => {
    let link = document.createElement('input');
    link.setAttribute('type', 'file');
    link.setAttribute('name', 'EquipmentList');
    // link.setAttribute('onChange', handleOnChangeFile);
    let self = this;
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      e.preventDefault();
   
      let fr = new FileReader();

      // if (e.currentTarget.files[0].size < 51200 && e.currentTarget.files[0].size > 2000) {
      fr.readAsBinaryString(e.currentTarget.files[0]);
      fr.onloadend = async function (e) {
        const wb = XLSX.read(e.target.result, { type: 'binary', cellDates: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let dataArray = []
        let keyArr = data[0]
        let arr = data.slice(1, data.length)
        arr.forEach((oneArr) => {
          let obj = {}
          oneArr.forEach((value, i) => {
            if(keyArr[i]=='DeviceID'){
              obj[[keyArr[i]]] = value;
            }else{
              obj[[keyArr[i]]] = value.toString();
            }
          })
          dataArray.push(obj);
        })
        self.setState({excelData:dataArray});
        await self.sendDataToServer(dataArray,dataArray,self.state.arrayProcessIndex)
        // console.log("Data>>>" + dataArray);
      };
      // }
      // else {
      //   swal('File Size Should Be Between 2KB to 50KB');
      // }
    }
     document.body.removeChild(link);

  }

sendDataToServer = async (EquipsArr,originalDataArray,idx) => {
  this.setState({querySuccess:true})
  
    for (let index = idx; index < EquipsArr.length; index += 1) {
      try {
        let error=valid.validate(EquipsArr[index],this.schema);
        if(error!==null){
          let row=index+1
          this.setState({errors:error,querySuccess:false,errorModal:true,rowNumber:row});
         break;

        }else{
          let res=await axios.post(api.api + 'UploadExcelEquipmentData', { row: index + 1, data: EquipsArr[index] })
          await    (async () => {
            const result = await axios.get(
              api.api1 + 'equipment'
            );
           // setQuerySuccess(false)
           this.setState({equipmentList:result.data});
          })();
          if(res.data=='Insert'){
            toast.success('🦄 Save Equipment  - '+EquipsArr[index].EquipmentID, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
          }
        
        }
      }
      catch (err) {

        if(err.response.data.code==11000){
          this.AbortAndResumeProcess(EquipsArr,originalDataArray,index)
         }else
         {  
          let  message=JSON.parse(err.response.data.message);
          this.setState({querySuccess:true,objKey:message.key,errorMessage:message.message,errorFlag:true})
          if(message.key=='DeviceID'){
            swal(`${message.message}  , \n  Please contact leafnet admin customer support !`,{dangerMode:true});
          }else
          {  
            this.insertIfNotExistDataTypeAndResumeProcess(message.message,message.key.toLowerCase(),message.key,EquipsArr[index][message.key],originalDataArray,index);
          }
         }
     
        break;
      
      }

    }
    this.setState({querySuccess:false})
  }

  AbortAndResumeProcess=(dataArray,originalArr,index)=>{
    let row=index+1;
    swal({
      title: "Are you sure?",
      text: ' This tool id already exist skip this tool!  ' +dataArray[index].ToolId  +"  at row number "+row,
      icon: "warning",
      buttons: true,
      dangerMode: true
  }).then(willDelete => {
      if (willDelete) {
        index=index+1;
        this.sendDataToServer(dataArray,originalArr,index)
      } else {
        this.setState({querySuccess:false,arrayProcessIndex:index})
        swal('Process  Abort',{dangerMode:true});
      
          // swal("Your imaginary file is safe!");
      }
  });
  }

  

  insertIfNotExistDataTypeAndResumeProcess=(errorMessage,url,key,value,dataArray,index)=>{
    swal({
      title: "Are you sure?",
      text: `${errorMessage}`+'  , Do you want to insert it ',
      icon: "warning",
      buttons: true,
      dangerMode: true
  }).then(willDelete => {
      if (willDelete) {
         if(url=='manufacturer'){
           key='Manufacture';
         }
        this.setState({querySuccess:true})
          axios
              .post(api.api + url , { [key]: value })
              .then(async (result) => {
                  if (result.status==200) {
                    this.setState({errorFlag:false})
                    this.sendDataToServer(dataArray,dataArray,index)
                   // setQuerySuccess(false);
                  }
              })
              .catch(err => {
                this.setState({querySuccess:false})
                    toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
              });
      } else {
        this.setState({querySuccess:false,arrayProcessIndex:index})
        swal('Process  Abort')
      
          // swal("Your imaginary file is safe!");
      }
  });
  }


   handleDownloadFormat = () => {
    axios.get(api.api + 'EquipmentTemplate')
      .then((result) => {
        if (result.status == 200) {
          // const url = window.URL.createObjectURL(new Blob([result.data]));
          let link = document.createElement('a');
          link.setAttribute('href', result.data);
          link.setAttribute('download', 'EquipmentFormat.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        else {
          swal('Error In Downloading File')
        }
      })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
  }

  handleHideModal=()=>{
   this.setState({errorModal:false})
  }



  render() {
    return (
      <React.Fragment >
        <div className='mt-1'>
        <div className='text-center'>
                   <p>Equipment Hourly Rate Master</p>
                 </div>
         <ToastContainer />
         <Modal
            show={this.state.errorModal}
           onHide={this.handleHideModal}
            backdrop="static"
            keyboard={false}
            size='sm'
        >
            <Modal.Header closeButton>
                <Modal.Title>Equipment Validate Error List</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {Object.keys(this.state.errors).map(key=>{
                return <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors[key] +"  " +"Row Number  "+ this.state.rowNumber}</p>
              })}
            </Modal.Body>
            {/* <Modal.Footer>
                <Button className='bg-primary' variant="secondary" >
                    Save
        </Button>
                <Button variant="secondary" onClick={handleHideModal}>
                    Close
        </Button>

            </Modal.Footer> */}
        </Modal>
        {this.state.querySuccess ? <Loading /> : ""}

       {/* {this.props.getRightsForAccess('StaticDataRights','EquipmentMaster','write')? <a
          style={{ cursor: "pointer",color:'white' }}
          className="float-right btn btn-sm btn-primary ml-2 common-anchor"
          onClick={() => {
            this.props.history.push("/setting/equipment/add");
            this.props.handleOnAdd(
              "/setting/equipment/add",
              "add",
              "equipmentList",
              this.state.equipmentList
            );
          }}
        >
          {" "}
          
          <FontAwesomeIcon size="1x" icon={faPlus} /> Add
        </a>:''} */}


        {this.props.getRightsForAccess('StaticDataRights','EquipmentMaster','write')? <a
          style={{ cursor: "pointer",color:'white' }}
          className="btn btn-sm btn-primary ml-2"
          onClick={this.handleUploadGuagesDoc} 
        >
          {" "}
          
          <FontAwesomeIcon size="1x" icon={faFileImport} /> Import Excel
        </a>:''}

        {/* {this.props.getRightsForAccess('StaticDataRights','EquipmentMaster','write')? <a
          style={{ cursor: "pointer",color:'white' }}
          className="float-left btn btn-sm btn-primary "
          onClick={this.handleDownloadFormat} 
        >
          {" "}
          
          <FontAwesomeIcon size="1x" icon={faDownload} /> Download Template
        </a>:''} */}
        {this.state.equipmentList.length < 0 ? (
          "Data Not Available"
        ) : (
          <Table
            columns={this.columns}
            onSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            data={this.state.equipmentList}
          />
        )}
        </div>
      </React.Fragment>
    );
  }
}

export default EquipmentHourlyRateTable;
