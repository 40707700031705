function getStringOfDateTime(dateTime) {
  let currentDate = new Date(dateTime);
  let CurrentDateTime =
    ((currentDate.getDate() < 10 ? "0" : "") + currentDate.getDate()) +
    "/" +
    ((currentDate.getMonth() + 1 < 10 ? "0" : "") + (currentDate.getMonth() + 1)) +
    "/" +
    currentDate.getFullYear() +
    " @ " +
    (currentDate.getHours() < 10 ? "0" : "") +
    currentDate.getHours() +
    ":" +
    (currentDate.getMinutes() < 10 ? "0" : "") +
    currentDate.getMinutes()
  // ":" +
  // currentDate.getSeconds();
  return CurrentDateTime;
}

function getTime(date) {

  let Time = new Date(date);
  return (
    (Time.getHours() < 10 ? "0" : "") +
    Time.getHours() +
    ":" +
    (Time.getMinutes() < 10 ? "0" : "") +
    Time.getMinutes()
  );

};

function getDateFormat(dateTime) {
  dateTime = new Date(dateTime)
  let date = dateTime.toDateString().split(" ")
  date.splice(0, 1)
  let joindate = date[1] + " " + date[0] + " " + date[2]
  return joindate;
}

function getDate(date) {

  let date1 = new Date(date);
  return (
    (date1.getDate() < 10 ? "0" : "") +
    date1.getDate() +
    "-" +
    ((date1.getMonth() + 1) < 10 ? "0" : "") +
    (date1.getMonth() + 1) +
    "-" +
    date1.getFullYear()
  );

};
function getDateFormatForInputTypeHtml(date) {

  let date1 = new Date(date);
  return (



    date1.getFullYear() + "-" +
    ((date1.getMonth() + 1) < 10 ? "0" : "") +
    (date1.getMonth() + 1)
    +
    "-" +
    (date1.getDate() < 10 ? "0" : "") +
    date1.getDate()
  );

};

function getDifferenceBetweenTimes(time1, time2) {
  let timeDiff = new Object();
  timeDiff.milliseconds = Math.abs((time1 - time2));
  timeDiff.seconds = Math.floor(timeDiff.milliseconds / 1000);
  timeDiff.minutes = Math.floor(timeDiff.seconds / 60);
  timeDiff.hours = Math.floor(timeDiff.minutes / 60);
  timeDiff.days = Math.floor(timeDiff.hours / 24);
  timeDiff.weeks = Math.floor(timeDiff.days / 7);

  return timeDiff;
}

function getDateForHtml(datetime) {
  if (datetime) {
    let date = new Date(datetime);
    return (
      date.getFullYear() +
      "-" +
      ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
      "-" +
      ((date.getDate() < 10 ? "0" : "") + date.getDate())
    );
  }
};
function getTimeForHtml(datetime) {
  if (datetime) {
    let Time = new Date(datetime);
    return (
      (Time.getHours() < 10 ? "0" : "") +
      Time.getHours() +
      ":" +
      (Time.getMinutes() < 10 ? "0" : "") +
      Time.getMinutes()
    );
  }
};


function GetDailyFrames(d1, d2) {
  let frames = [];
  d1 = new Date(d1);
  d2 = new Date(d2);
  let i=1;
  while (d1 <= d2) {
    let obj = { From: new Date(d1) }
    obj.From.setHours(0,0,0,0)
    obj.Till = new Date(d1)
    obj.Till.setHours(23,59,59,999)
    d1.setDate(d1.getDate() + 1)
    obj.Periodicity = "Day"+i
    i=i+1;
    frames.push(obj);
  }
  return frames;
}


function GetWeeklyFrame(d1, d2) {
  let result=GetDailyFrames(d1,d2);
  let res = [];
  let week=1;
  for (let i = 0; i < result.length; i++) {
    let obj = { From: new Date(result[i].From), Periodicity: 'Week' + week, Till: new Date(result[i].Till) }
    for (let j = 0; j < result.length; j++) {
      if (IsInSameWeek(new Date(obj.From), new Date(result[j].From))) {
      
        obj.Till = result[j].Till;
        result.splice(j, 1);
        j--;
        // i--;
      } else {
        week=week+1;
       i--
        // result.splice(0, 1);
        break;
      }

    }
    res.push(obj);
  }
  res.forEach((one, i) => {
    if (one.From > one.Till) {
      res.splice(i, 1)
    }
  })
  return res;
}

function GetMonthlyFrame(d1, d2) {
  let result=GetDailyFrames(d1,d2);
  let res = [];
  for (let i = 0; i < result.length; i++) {
    let obj = { From: new Date(result[i].From), Periodicity: 'Month' + (i + 1) }
    for (let j = 0; j < result.length; j++) {
      if (IsInSameMonth(new Date(obj.From), new Date(result[j].From))) {
        j = 0;
        obj.Till = result[j].Till;
        result.splice(0, 1);
      } else {
        // result.splice(0, 1);
        break;
      }

    }
    res.push(obj);
  }
  res.forEach((one, i) => {
    if (one.From > one.Till) {
      res.splice(i, 1)
    }
  })
  return res;
}



function IsInSameWeek(d1, d2) {
  let x = new Date(d1)
  let daysToSunday = 7 - x.getDay();
  x.setDate(x.getDate() + daysToSunday);
  if (x >= d2) {
    return true
  }
  return false
}

function IsInSameMonth(d1, d2) {
  let x = new Date(d1)
  let daysToMonthEnd = lastDateOFMonth(d1.getFullYear(), d1.getMonth()) - x.getDate();
  x.setDate(x.getDate() + daysToMonthEnd);
  if (x >= d2) {
    return true
  }
  return false
}

function lastDateOFMonth(year,month){
  return  new Date(year, month +1, 0).getDate();
  }



export default {
  IsInSameWeek,
  GetDailyFrames,
  GetWeeklyFrame,
  GetMonthlyFrame,
  getDateFormat,
  getTime,
  getDate,
  getTimeForHtml,
  getDateForHtml,
  getStringOfDateTime,
  getDateFormatForInputTypeHtml,
  getDifferenceBetweenTimes
}