import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import { toPng } from "html-to-image";

const PieChart = ({title,data}) => {
  const chartRef = useRef(null);

  const handleDownload = async () => {
    if (chartRef.current) {
      try {
        // Convert the chart container to a PNG image
        const imageDataURL = await toPng(chartRef.current, {
          backgroundColor: "#ffffff", // Set background color
        });

        // Create a download link
        const downloadLink = document.createElement("a");
        downloadLink.href = imageDataURL;
        downloadLink.download = "chart.png"; // File name
        document.body.appendChild(downloadLink);

        // Trigger the download
        downloadLink.click();

        // Remove the download link from the DOM
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Failed to download chart:", error);
      }
    }
  };

    return ( <>
    <button className="btn btn-primary mx-2" style={{height:'40px'}} onClick={handleDownload}>
        Download Chart
      </button>
        <div ref={chartRef}>
            <Chart
  width={'800px'}
  height={'800px'}
  chartType="PieChart"
  loader={<div>Loading Chart</div>}
  data={data}
  options={{
    title: `${''}`,
    pieSliceText: "value",
    chartArea: { left:0,right:40,top: 0,bottom:0,},
  }}
  rootProps={{ 'data-testid': '1' }}
/>
        </div>
  </>
     );
}
 
export default PieChart;