import React, { useEffect, useRef ,useState} from 'react';
import * as echarts from 'echarts';

const MyChartComponent = ({ data, Periodicity,title }) => {
  const chartRef = useRef(null);
const [viewvalue,setviewvalue]= useState('label')
const [isChecked, setIsChecked] = useState(false);

// Function to handle the onChange event of the checkbox
const handleChangeView = () => {
  // Toggle the checked state when the checkbox is clicked
  setIsChecked(!isChecked);

  // Add your code here based on the checked or unchecked state
  if (!isChecked) {
    // // consol('Checkbox is checked');
    // Add your code for the checked state
    setviewvalue('count')
  } else {
    // // consol('Checkbox is not checked');
    setviewvalue('label')

    // Add your code for the unchecked state
  }
};
  useEffect(() => {
    // Create chart instance
    const myChart = echarts.init(chartRef.current);

    // Larger custom color palette
    const colorPalette = [
      '#FF5733', '#FFC300', '#36A2EB', '#4CAF50', '#FF6384',
      '#9966CC', '#FFD700', '#00CED1', '#FF6347', '#7CFC00',
      '#8A2BE2', '#00FF00', '#32CD32', '#FF4500', '#9370DB',
      '#FF8C00', '#9400D3', '#ADFF2F', '#00FFFF', '#FF1493',
      '#1E90FF', '#8B0000', '#FFB6C1', '#00FA9A', '#CD5C5C',
      '#3CB371', '#40E0D0', '#8B008B', '#00FF7F', '#FF00FF',
    ];

    // Chart options
    const option = {
      title: {
        text: `${title}`,
        textStyle: {
          fontWeight: 'bold',
          fontSize: 16,
        },
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      yAxis: {
        type: 'value',
      },
      xAxis: {
        type: 'category',
        data: Periodicity,
        axisLabel: {
          rotate: 90,
          formatter: function (value) {
            return  value;
          },
        },
      },
      series: data.map(({ name, values }, index) => ({
        name,
        type: 'bar',
        stack: 'total',
        label: {
          show: true,
          formatter: function () {
            // Use the viewvalue state to determine the behavior
            if (viewvalue === 'count') {
              // Return the count of values
              values.map(item=>{ return item})
            } else {
              // Return the name for each value
              return  name
            }
          },
        },
        emphasis: {
          focus: 'series',
        },
        data: values,
        itemStyle: {
          color: colorPalette[index % colorPalette.length],
        },
      })),
    };

    // Set chart options
    myChart.setOption(option);

    // Clean up chart on component unmount
    return () => {
      myChart.dispose();
    };
  }, [data, viewvalue]);
  const downloadChartImage = () => {
    const myChart = echarts.init(chartRef.current);
    const dataURL = myChart.getDataURL({ pixelRatio: 2, backgroundColor: '#fff' });

    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'chart.png';
    link.click();
  };

  return (<>
    {data && Periodicity && Periodicity.length > 0 && data.length > 0 ?<div>
     <div className="uiverse mx-2" onClick={()=>{downloadChartImage()}}
>
 <span className="tooltip">Dowload Chart</span>
 <span>
 Download
 </span>
</div>
      {/* <button  className='rounded btn btn-primary p-1 m-0 mx-1' onClick={() => chnageview('label')}>Label</button> */}
      <div className='d-flex justify-content-between' style={{ width: '170px' }}>
      <div className='mx-2'>Label</div>
      <div className='form-check form-switch mx-2'>
        <input
          className='form-check-input'
          type='checkbox'
          role='switch'
          id='flexSwitchCheckDefault'
          checked={isChecked}
          onChange={handleChangeView}
        />
      </div>
      <div className='mx-2'>Count</div>
    </div>
      {/* <button className='rounded btn btn-primary p-1 m-0' onClick={() => chnageview('count')}>Count</button> */}
     
      <div ref={chartRef} style={{ width: '100%', height: '700px' }}></div>
    </div>: <div style={{ width: '100%', height: '300px' }}  className='animated-gradient d-flex align-items-center justify-content-center'><p className='text-center bolder'>No Data ...</p></div>}
    </> );
};

export default MyChartComponent;