import React, { useState,useEffect } from 'react';
import Joi from 'joi';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { Modal,Button } from 'react-bootstrap';
import axios from '../httpServices/httpService';
import api from '../config.json';

const ChangePassword=({modelState,handleClose,user})=>{
const[show,setShow]=useState(modelState);
const[error,setError]=useState({});
const[data,setData]=useState({});
useEffect(()=>{
    setShow(modelState)
},[show,data,error])


const handleOnChnage=(e)=>{
data[e.currentTarget.name]=e.currentTarget.value;
setData(data);
// // consol(data)
}

const handleSaveChange=()=>{
    // // consol(data.new===data.confirmNew)
  if(data.new===data.confirmNew)
  {
      let user1={...user}
      user1.new=data.new;
      user1.password=data.exist;
    axios.patch(api.api+'changepassword',user1).then((result)=>{
       if(result.status==200)
       {
           swal(`${result.data.message}`)
           handleClose()
       }
       else
       {
        swal(`${result.statusText}`)
       }
    })
    .catch((Err)=>{
        swal(`${Err}`);
    })
  }
  else
  {
   
    swal("Confirm Password and New Password Does Not Match",{dangerMode:true})
    //   error['error']="Confirm Password and New Password Does Not Match"
    //   setError(error);
  }
}

return( <Modal show={modelState} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
           
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faKey}/></span>
                        </div>
                        <input type="text" className="form-control" onChange={handleOnChnage}  name='exist' placeholder="Existing" aria-label="Existing" aria-describedby="basic-addon1" />
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faKey}/></span>
                        </div>
                        <input type="password" className="form-control" onChange={handleOnChnage} name='new' placeholder="New Password" aria-label="New Password" aria-describedby="basic-addon1" />
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1"><FontAwesomeIcon icon={faKey}/></span>
                        </div>
                        <input type="text" className="form-control" onChange={handleOnChnage} name='confirmNew' placeholder="Confirm New Password" aria-label="Username" aria-describedby="basic-addon1" />
                    </div>
                   {/* {// consol(error)} */}
                    {/* <div className="input-group mb-3">
                       
                       {error['error']?<p className='label-danger'>{error['error']}</p>:''}
                    </div> */}
              
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSaveChange}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
            
    )
}

export default ChangePassword;