import React, { Component } from "react";
import Table from "../../../Common/table";
//import "../QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faPlus,
  faDownload,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import dateTime from "../../../utill/dateTime";
import swal from "sweetalert";
import api from "../../../config";
import axios from "../../../httpServices/httpService";

import _ from "lodash";
import Loading from "../../../utill/LoadingComponent";
import color from './color.json';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx';
import valid from '../../../utill/validation';
import joi from 'joi';
import { Modal } from 'react-bootstrap';


class DownTimeTable extends Component {
  state = {
    downTimeReasonList: [],
    sortColumn: { path: "ReasonGroup", order: "asc" },

    excelData: [],
    arrayProcessIndex: 0,
    errorModal: false,
    errorMessage: '',
    errorFlag: false,
    rowNumber: 0,
    errors: {},
    objKey: '',
  };

  handleSort = (sortColumn) => {

    if (this.state.downTimeReasonList.length > 0) {
      const sorted = _.orderBy(
        this.state.downTimeReasonList,
        [sortColumn.path],
        [sortColumn.order]
      );
      this.setState({ sortColumn, downTimeReasonList: sorted });
    }
  };
  componentDidMount() {
    this.setState({ querySuccess: true });
    axios
      .get(api.api + "downtimereason")
      .then((result) => {
        // consol(result.data);
        if (result.data.length > 0) {
          this.setState({
            downTimeReasonList: result.data,
            querySuccess: false,
          });
        }
        else {
          this.setState({ querySuccess: false })
        }
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
  }

  onDelete = (data) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        axios
          .delete(api.api + "downtimereason/" + data._id, { data: data })
          .then((result) => {
            // consol(result.data);
            if (result.data.ok === 1 && result.data.deletedCount === 1) {
              let filterData = this.state.downTimeReasonList.filter(
                (obj) => obj._id !== data._id
              );
              this.setState({
                downTimeReasonList: filterData,
                querySuccess: false,
              });
            }
          })
          .catch((err) => {
            this.setState({ querySuccess: false });
            swal(`${err.response.data.message}`);
          });
      } else {
        // swal("Your imaginary file is safe!");
      }
    });
  };

  columns = [
    { path: "ReasonGroup", label: "Reason Group" },
    { path: "ReasonType", label: "Reason Type" },
    { path: "Reason", label: "Reason" },
    {
      path: "OperatorResponsible", label: 'Operator responsible', content: (obj) => (
        <React.Fragment>
          <label>{`${obj.OperatorResponsible}`}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      key: "createdAt",
      label: "Created Date",
      content: (obj) => (
        <React.Fragment>
          <label>{dateTime.getStringOfDateTime(obj.createdAt)}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      key: "updatedAt",
      label: "Last Updated Date",
      content: (obj) => (
        <React.Fragment>
          <label>{dateTime.getStringOfDateTime(obj.updatedAt)}</label>
          <br />
        </React.Fragment>
      ),
    },
    {
      key: "update",
      content: (user) => (
        <button
          onClick={() => this.props.onUpdate(user)}
          className="btn btn-sm btn-primary"
          disabled={!this.props.getRightsForAccess('StaticDataRights', 'DowntimeMaster', 'update')}
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
      ),
    },
    // { key: 'view', content: user => <button onClick={() => this.props.onView(user)} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faEye} /></button> },
    {
      key: "delete",
      content: (user) => (
        <button
          onClick={() => this.onDelete(user)}
          className="btn btn-sm btn-danger"
          disabled={!this.props.getRightsForAccess('StaticDataRights', 'DowntimeMaster', 'write') ||user.Reason ==='Down'?true:false}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      ),
    },
  ];




  handleUploadGuagesDoc = () => {
    let link = document.createElement('input');
    link.setAttribute('type', 'file');
    link.setAttribute('name', 'DowntimeList');
    // link.setAttribute('onChange', handleOnChangeFile);
    let self = this;
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      e.preventDefault();

      let fr = new FileReader();

      // if (e.currentTarget.files[0].size < 51200 && e.currentTarget.files[0].size > 2000) {
      fr.readAsBinaryString(e.currentTarget.files[0]);
      fr.onloadend = async function (e) {
        const wb = XLSX.read(e.target.result, { type: 'binary', cellDates: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let dataArray = []
        let keyArr = data[0]
        let arr = data.slice(1, data.length)
        arr.forEach((oneArr) => {
          if (oneArr.length > 0) {
            let obj = {}

            oneArr.forEach((value, i) => {
              if(keyArr[i]=='Reason'){
                // if(value.length > 0){
                //   obj[[keyArr[i]]] = value;

                // }else{

                  obj[[keyArr[i]]] = value.toString();
                // }
              }
              
              if(keyArr[i]=='Color'){
                // if(value.length > 0){
                //   obj[[keyArr[i]]] = value;

                // }else{

                  obj[[keyArr[i]]] = (Object.values(color)[i]);
                // }
              }
              else{
                obj[[keyArr[i]]] = value.toString();
              }
            })
            dataArray.push(obj);
          }
        })
        self.setState({ excelData: dataArray });
        await self.sendDataToServer(dataArray, dataArray, self.state.arrayProcessIndex)
        // consol("Data>>>" + dataArray);
      };
      // }
      // else {
      //   swal('File Size Should Be Between 2KB to 50KB');
      // }
    }
    document.body.removeChild(link);

  }

  schema = {
    ReasonType: joi.string().required().label('Reason Type'),
    Reason: joi.string().required().label('Reason'),
    ReasonGroup: joi.string().required().label('Reason Group'),
    Color: joi.string().label('Color For Reason'),
    OperatorResponsible: joi.boolean().label('OperatorResponsible'),
  }


  sendDataToServer = async (EquipsArr, originalDataArray, idx) => {
    this.setState({ querySuccess: true })

    for (let index = idx; index < EquipsArr.length; index += 1) {
      try {
        let error = valid.validate(EquipsArr[index], this.schema);
        if (error !== null) {
          let row = index + 1
          this.setState({ errors: error, querySuccess: false, errorModal: true, rowNumber: row });
          break;

        } else {
          let res = await axios.post(api.api + 'UploadExcelDowntimeMasterData', { row: index + 1, data: EquipsArr[index] })
          await (async () => {
            const result = await axios.get(
              api.api + 'downtimereason'
            );
            // setQuerySuccess(false)
            this.setState({ downTimeReasonList: result.data });
          })();
          if (res.data == 'Insert') {
            toast.success('🦄 Save Dwontime Reason For - ' + EquipsArr[index].Reason, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

        }
      }
      catch (err) {
        if(err.response.data.code){
          if (err.response.data.code == 11000) {
        
            this.AbortAndResumeProcess(EquipsArr, originalDataArray, index)
          } else {
            let message = JSON.parse(err.response.data.message);
            this.setState({ querySuccess: true, objKey: message.key, errorMessage: message.message, errorFlag: true })
            this.insertIfNotExistDataTypeAndResumeProcess1(message.message, message.key.toLowerCase(), message.key, EquipsArr[index][message.key], originalDataArray, index);
          }
        }else{
          let message = JSON.parse(err.response.data.message);
          this.setState({ querySuccess: true, objKey: message.key, errorMessage: message.message, errorFlag: true })
          this.insertIfNotExistDataTypeAndResumeProcess1(message.message, message.key.toLowerCase(), message.key, EquipsArr[index][message.key], originalDataArray, index);
        }
       
        break;
      }

    }
    this.setState({ querySuccess: false })
  }

  AbortAndResumeProcess = (dataArray, originalArr, index,errorMessage) => {
    let row = index + 1;
    swal({
      title: "Are you sure?",
      text: `${errorMessage} skip this reason!  ` + dataArray[index].Reason + "  at row number " + row,
      icon: "warning",
      buttons: ["Abort Process", "Skip Continue"],
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        index = index + 1;
        this.sendDataToServer(dataArray, originalArr, index)
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index })
        swal('Process  Abort', { dangerMode: true });

        // swal("Your imaginary file is safe!");
      }
    });
  }



  insertIfNotExistDataTypeAndResumeProcess = (errorMessage, url, key, value, dataArray, index) => {
    swal({
      title: "Are you sure?",
      text: `${errorMessage}` + '  , Do you want to skip this Reason ?  ',
      icon: "error",
      buttons: ["Abort Process", "Skip Continue"],
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.setState({ errorFlag: false });
        index = index + 1;
        this.sendDataToServer(dataArray, dataArray, index)
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index })
        swal('Process  Abort')

        // swal("Your imaginary file is safe!");
      }
    });
  }

  insertIfNotExistDataTypeAndResumeProcess1 = (errorMessage, url, key, value, dataArray, index) => {
    swal({
      title: "Are you sure?",
      text: `${errorMessage}` + '  , Do you want to add this and continue ?  ',
      icon: "error",
      buttons: ["Abort Process", "Continue"],
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.setState({querySuccess:true})
          axios
              .post(api.api + 'downtimegroup' , { DownTimeReasonGroup: value })
              .then(async (result) => {
                  if (result.status==200) {
                    this.setState({errorFlag:false})
                    toast.success(`${`Downtime Group Added`}`, {
                      position: 'top-right',
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                  
                    });
                    this.sendDataToServer(dataArray,dataArray,index)
                   // setQuerySuccess(false);
                  }
              })
              .catch(err => {
                this.setState({querySuccess:false})
                    toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
              });
      } else {
        this.setState({querySuccess:false,arrayProcessIndex:index})
        swal('Process  Abort')
      
          // swal("Your imaginary file is safe!");
      }
  });
  }

  handleDownloadFormat = () => {
    axios.get(api.api + 'DowntimeMasterTemplate')
      .then((result) => {
        if (result.status == 200) {
          // const url = window.URL.createObjectURL(new Blob([result.data]));
          let link = document.createElement('a');
          link.setAttribute('href', result.data);
          link.setAttribute('download', 'DowntimeMasterFormat.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        else {
          swal('Error In Downloading File')
        }
      })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
  }

  handleHideModal = () => {
    this.setState({ errorModal: false })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.querySuccess ? <Loading /> : ""}
        <ToastContainer />
        <Modal
          show={this.state.errorModal}
          onHide={this.handleHideModal}
          backdrop="static"
          keyboard={false}
          size='sm'
        >
          <Modal.Header closeButton>
            <Modal.Title>Dwontime Master Validation Error List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(this.state.errors).map(key => {
              return <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors[key] + "  " + "Row Number  " + this.state.rowNumber}</p>
            })}
          </Modal.Body>
          {/* <Modal.Footer>
                <Button className='bg-primary' variant="secondary" >
                    Save
        </Button>
                <Button variant="secondary" onClick={handleHideModal}>
                    Close
        </Button>

            </Modal.Footer> */}
        </Modal>
        <div className='text-center'>
          <p>Downtime Master</p>
        </div>
        {this.props.getRightsForAccess('StaticDataRights', 'DowntimeMaster', 'write') ? <button
          style={{ cursor: "pointer" }}
          className="common-anchor btn btn-sm btn-primary ml-2"
          onClick={() => {
            this.props.history.push("/setting/downtimereason/add");
            this.props.handleOnAdd(
              "/setting/downtimereason/add",
              "add",
              "downTimeReasonList",
              this.state.downTimeReasonList
            );
          }}
        >
          {" "}
          <FontAwesomeIcon size="1x" icon={faPlus} /> Add
        </button> : ''}
        {this.props.getRightsForAccess('StaticDataRights', 'DowntimeMaster', 'write') ? <a
          style={{ cursor: "pointer", color: 'white' }}
          className="float-right btn btn-sm btn-primary "
          onClick={this.handleUploadGuagesDoc}
        >
          {" "}

          <FontAwesomeIcon size="1x" icon={faFileImport} /> Import Excel
        </a> : ''}

        {/* {this.props.getRightsForAccess('StaticDataRights', 'DowntimeMaster', 'write') ? <a
          style={{ cursor: "pointer", color: 'white' }}
          className="float-left btn btn-sm btn-primary "
          onClick={this.handleDownloadFormat}
        >
          {" "}

          <FontAwesomeIcon size="1x" icon={faDownload} /> Download Template
        </a> : ''} */}
        {this.state.downTimeReasonList.length < 0 ? (
          "Data Not Available"
        ) : (
          <Table
            columns={this.columns}
            onSort={this.handleSort}
            sortColumn={this.state.sortColumn}
            data={this.state.downTimeReasonList}
          />
        )}
      </React.Fragment>
    );
  }
}

export default DownTimeTable;
