import React,{useEffect,useState} from 'react';
import date from '../utill/dateTime';
import axios from 'axios';
import api from '../config.json'
const QualityReportTable = ({ qualityData, id,compList }) => {
 

  const getAliasName=(obj)=>{
    let component = [...compList];
    component = component.filter((one) => one.ComponentId == obj.Component)
    if (component.length > 0) {
      return <label>{component[0].Alias}</label>
    } else {
      return <label>N/A</label>
    }
  
}

  const getHeaderReason = () => {
    let string = 1;
    let data=[...qualityData]
    if ((data.length > 0) && (data[0].RejectionReason)) {
      data=[...data[0].RejectionReason]
      string = data.sort(function (a, b) {return b.Reason.length - a.Reason.length})[0]
      string=string.Reason.length;
    }
    return <tr className='table-primary' >
      {qualityData.length > 0 ? qualityData[0].RejectionReason ? qualityData[0].RejectionReason.map(one => {
        return <th className="rotate" style={{ verticalAlign: 'middle', height: (6*string) }} ><div className='mt-1'><span>{one.Reason}</span></div></th>
      }) : '' : ''}

    </tr>
  }

  return (
    <table id={id} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0 }}>
      <thead className='small text-center border'>
        <tr className='text-center align-self-center table-primary'>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2' >Date</th>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Shift</th>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Equipment</th>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Component</th>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Component Alias</th>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Operation</th>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Tool</th>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Total Parts Produced</th>




          <th style={{ verticalAlign: 'middle' }} colSpan={qualityData[0].RejectionReason ? qualityData[0].RejectionReason.length : 0}>Rejections Reasons (no of units)</th>

          <th style={{ verticalAlign: 'middle' }} rowSpan='2' >Reject</th>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2' >Accept</th>
          <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Quality Factor (%)</th>

        </tr>

        {getHeaderReason()}
      </thead>
      <tbody>
        {qualityData.map(one => {
          return <tr>
            <td>{date.getDateFormat(one.From)}</td>
            <td>{one.Shift}</td>
            <td>{one.Equipment}</td>
            <td>{one.Component}</td>
            <td>{getAliasName(one)}</td>
            <td>{one.Operation}</td>
            <td>{one.Tool == 'null' || one.Tool == undefined ? '-' : one.Tool}</td>
            <td>{one.NumOfParts ? one.NumOfParts : '0'}</td>
            {one.RejectionReason ? one.RejectionReason.map(r => {
              return <td>{r.Count}</td>
            }) : ''}
            <td>{one.Rejected ? one.Rejected : '0'}</td>
            <td>{one.Accepted ? one.Accepted : '0'}</td>
            <td>{one.QualityFactor ? one.QualityFactor : '0'}</td>
          </tr>
        })}
      </tbody>
    </table>
  );
}

export default QualityReportTable;