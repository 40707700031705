import React, { Component } from 'react';
import swal from 'sweetalert';
import endPoint from '../../../config';
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';
import color from './color.json';
import MultiSelect from '../../../Common/multiselect';
import { ToastContainer, toast } from 'react-toastify';


class DownTime extends Component {
  state = {
    downTimeReason: { IsFailure: false },
    oldObj: {},
    downTimeReasonGroupList: [],
    errors: {},
    colors: {}
  }
  schema = {
    OperatorResponsible: joi.boolean().label('Operator Responsible'),
    IsFailure: joi.boolean().label('Is Failure'),
    ReasonType: joi.string().required().label('Reason Type'),
    Reason: joi.string().required().label('Reason'),
    ReasonGroup: joi.string().required().label('Reason Group'),
    Color: joi.string().required().label('Color For Reason'),
  }
  componentDidMount() {
    axios.get(endPoint.api + 'downtimegroup').then((result) => {
      this.setState({ downTimeReasonGroupList: result.data })

    })
      .catch((err) => {
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })


    if (this.props.actionStatus === 'add') {
      axios.get(endPoint.api + 'downtimereason').then((result) => {
        let cloneColrs = { ...color }
        Object.keys(cloneColrs).forEach(one => {
          result.data.forEach((reason) => {
            if (reason.Color == one) {
              delete cloneColrs[one]
            }
          })
        })
        this.setState({ colors: cloneColrs, downTimeReason: {} })

      })
        .catch((err) => {
          toast.success(`${err}`, {
            position: 'bottom-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'red', // Set your desired background color
              color: 'white',     // Set your desired text color
            },
          });
        })

    }
    if (this.props.actionStatus === 'update') {
      const downTimeReason = { ...this.props.downTimeReasonData }


      this.setState({ downTimeReason: this.props.downTimeReasonData, oldObj: downTimeReason, colors: color });
    }
  }

  handleOnChange = (e) => {
    const { downTimeReason, errors } = { ...this.state };
    const errorMessage = valid.validateProperty(e.currentTarget, this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];
    if ((e.currentTarget.name === 'OperatorResponsible') || (e.currentTarget.name === 'IsFailure')) {
      downTimeReason[e.currentTarget.name] = e.currentTarget.checked;

    } else {
      downTimeReason[e.currentTarget.name] = e.currentTarget.value;

    }
    this.setState({ downTimeReason, errors: errors || {} });
    // consol(downTimeReason)


  }
  handleSubmit = (data) => {


    if (this.props.actionStatus === 'add') {
      const errors = valid.validate(data, this.schema);
      this.setState({ errors: errors || {} });
      if (errors) return;

      this.setState({ querySuccess: true });
      axios.post(endPoint.api + 'downtimereason', data)
        .then((result) => {
          if (result.status === 200) {
            this.setState({ querySuccess: false });
            this.props.history.push('/setting/downtimereason/list')
            swal('Save Data Successfully');
          }
          else {
            this.setState({ querySuccess: false });
            swal(`Data Not Saved`);
          }
        })
        .catch((err) => {
          this.setState({ querySuccess: false });
          swal(`${err.response.data.message}`);
        })

    }
    if (this.props.actionStatus === 'update') {
      this.setState({ querySuccess: true });
      axios.patch(endPoint.api + 'downtimereason/' + data._id, { old: this.state.oldObj, new: data })
        .then((result) => {
          const { n, nModified, ok } = result.data;
          if (ok == 1 && n == 1 && nModified == 1) {
            this.setState({ querySuccess: false });
            this.props.history.push('/setting/downtimereason/list')
            swal('Updated Data Successfully');
          }
          else {
            this.setState({ querySuccess: false });
            swal(`${result.data}`);
          }
        })
        .catch((err) => {
          this.setState({ querySuccess: false });
          swal(`${err.response.data.message}`);
        })

    }
  }





  render() {
    return (
      <React.Fragment>   <ToastContainer />
        {this.state.querySuccess ? <Loading /> : ''}


        <div className=" border">
          <div className="row justify-content-center">
            <div className="col-sm-4 ">
              <div className="form-group text-center">
                <label>Reason Group</label>
                <MultiSelect
                  isDisable={this.state.downTimeReason.Reason === 'Down' ? true : false}
                  name="ReasonGroup" master={true} selected={this.state.downTimeReason['ReasonGroup']} handleOnChange={this.handleOnChange} masterName="DownTimeReasonGroup" objLevel={1} all={this.state.downTimeReasonGroupList} />
                {this.state.errors.ReasonGroup && <div className="alert small alert-danger">{this.state.errors['ReasonGroup']}</div>}
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-4 ">
              <div className="form-group text-center">
                <label>Reason </label>
                <input
                  disabled={this.state.downTimeReason.Reason === 'Down' ? true : false}
                  type="text"
                  className="form-control"
                  placeholder="Enter DownTime Reason "
                  name="Reason"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.downTimeReason.Reason}
                />
                {this.state.errors.Reason && <div className="alert small alert-danger">{this.state.errors['Reason']}</div>}
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className='col-sm-4 '>
              <div className='d-flex pl-4'>
                <div >
                  <input disabled={this.state.downTimeReason.Reason === 'Down' ? true : false} type="radio" name="ReasonType" value="Planned" checked={this.state.downTimeReason.ReasonType === 'Planned'} onChange={this.handleOnChange} />
                  &nbsp;<label className='ml-2' htmlFor="yes">Planned</label>
                </div>
                <div>
                  <input className='ml-4' disabled={this.state.downTimeReason.Reason === 'Down' ? true : false} type="radio" name="ReasonType" value="Unplanned" checked={this.state.downTimeReason.ReasonType === 'Unplanned'} onChange={this.handleOnChange} />
                  &nbsp;<label className='ml-2' htmlFor="no">Unplanned</label>
                </div>
              </div>
              {this.state.errors.ReasonType && <div className="alert small alert-danger">{this.state.errors['ReasonType']}</div>}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className='col-sm-4'>
              <div className='ml-5'>
                <div className="form-group ">
                  <input className="form-check-input" type='checkbox' checked={this.state.downTimeReason.OperatorResponsible} onChange={this.handleOnChange} name="OperatorResponsible" />
                  <label className="form-check-label small mr-4 " for="flexCheckChecked">
                    Operator responsible
                  </label>
                </div>
                <div className="form-group">
                  <input className="form-check-input" type='checkbox' checked={this.state.downTimeReason.IsFailure} onChange={this.handleOnChange} name="IsFailure" />
                  <label className="form-check-label small mr-4 " for="flexCheckChecked">
                    Is Failure
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className='col-sm-4'>
              <div className="form-group text-center">
                <label>Color </label>
                <select
                  disabled={this.state.downTimeReason.Reason === 'Down' ? true : false}
                  type="text"
                  className="form-control"
                  placeholder="Select Color"
                  name="Color"
                  onChange={this.handleOnChange}
                  value={this.state.downTimeReason.Color}
                >
                  <option>---Select Color---</option>
                  {Object.keys(this.state.colors).map((one, i) => {
                    return <option style={{ backgroundColor: this.state.colors[one] }} key={i}>{one} </option>
                  })}
                </select>
                {this.state.errors.Color && <div className="alert small alert-danger">{this.state.errors['Color']}</div>}
              </div>
            </div>
          </div>

          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.handleSubmit(this.state.downTimeReason);
              }}
            >
              Submit
            </button>
          </div>
          <div className="text-center" style={{ marginTop: 10 }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.history.push('/setting/downtimereason/list')
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DownTime;