import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";

import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import EquipmentSelection from "../Common/equipmentSelection";
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from "../Common/DateSelection";

const Refrence = [
  { label: "Equipment", id: "1" },
  { label: "Component", id: "2" },
  { label: "Operator", id: "3" },
  { label: "Tool", id: "4" },
  { label: "Operation", id: "5" },
  { label: "Time", id: "6" },
  { label: "Reasons", id: "7" },
  { label: "Stage", id: "8" },
];

function QualityAnalysis({equipGroups,qualityReasonList,fromDateLimit,storeDataInLocalStorage}) {
  const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);
  const [periodOption, setperiodOption] = useState([
    { label: "Shift", id: "1" },
    { label: "Daily", id: "2" },
    { label: "Weekly", id: "3" },
    { label: "Monthly", id: "4" },
    { label: "Cummulative", id: "5" },
  ]);
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  const [qualityreasonGroup, setQualityreasonGroup] = useState([]);

  const [componentdata, setcomponentdata] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [operatordata, setoperatordata] = useState([]);
  const [tooldata, settooldata] = useState([]);
  const [operationdata, setoperationdata] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [data, settabledata] = useState([]);
  const [Distributionvalues, SetDistributionvalues] = useState([]);
  const [piedata, setpiedata] = useState([]);
  const [rejstage, setrejstage] = useState([]);

  const [selectedDates, setSelectedDates] = useState({
    From: "",
    Till: "",
  });
  const [Compareref, setCompareref] = useState([]);
  const [pieperiodicity, setpieperiodicity] = useState("");
  const [pieDateTime, setpieDateTime] = useState([]);
  const [newdata, setnewdata] = useState("");
  const [head, sethead] = useState("");
  const [root, setroot] = useState([]);
  const [pieheader, setpieheader] = useState([]);

  const [historicalData, setHistoricalData] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [previousInputData, setPreviousInputData] = useState([]);

  const [tableVisible, setTableVisible] = useState(false);
  const [tableVisible1, setTableVisible1] = useState(false);
  const [tableVisible2, setTableVisible2] = useState(false);
  const [tableVisible3, setTableVisible3] = useState(false);
  const [selecetdqualitygroup, setselecetdqualitygroup] = useState([]);

  const handleToggle = () => {
    setTableVisible((prevVisible) => !prevVisible);
  };
  const handleToggle1 = () => {
    setTableVisible1((prevVisible) => !prevVisible);
  };
  const handleToggle2 = () => {
    setTableVisible2((prevVisible) => !prevVisible);
  };
  const handleToggle3 = () => {
    setTableVisible3((prevVisible) => !prevVisible);
  };
  const [columns, setcolumn] = useState([]);

  const [sortColumn, setSortColumn] = useState({ path: "Date", order: "asc" });
  const [paretodata, setparetodata] = useState([]);
  const [columns1, setcolumns1] = useState([]);
  const [columns2, setcolumns2] = useState([]);
  const [selectedItemLengths, setSelectedItemLengths] = useState({});

  const [indexofpie, setindexofpie] = useState(null);


  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])




  const setSelection = (key, option, equips) => {
    if (key == 'EquipmentGroup') {
        SetEquips(equips ? equips : []);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if (key == 'Equipment') {

        setSelectedEquipOption(option)
    }
}

  // Your existing chart code goes here

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////



  const QualityReasonGroup ={
    heading: "QualityReasonGroup",
    label: "tablecolumns",
    initialItems: qualityreasonGroup,
    selectedItemIds: selectedItemIds,
    name: "QualityReasonGroup",
    enableLocalStorage: false,
    multiSelect: true,
    searchbar: true,
    selectAll:true,
  }


 const Reasons =    {
  heading: "Reasons",
  label: "tablecolumns",
  initialItems: dynamicData,
  selectedItemIds: selectedItemIds,
  name: "Reasons",
  enableLocalStorage: false,
  multiSelect: true,
  searchbar: true,
  selectAll:true,
}
  const DropDownData = [
    // {
    //   heading: "Equipment",
    //   label: "Equipment",
    //   initialItems: equpdata,
    //   selectedItemIds: selectedItemIds,
    //   name: "Equipment",
    //   enableLocalStorage: false,
    //   multiSelect: true,
    //   searchbar: true,
    // },
    {
      heading: "Operation",
      label: "Operation",
      initialItems: operationdata,
      selectedItemIds: selectedItemIds,
      name: "Operation",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    {
      heading: "Component",
      label: "Operator",
      initialItems: componentdata,
      selectedItemIds: selectedItemIds,
      name: "Component",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    {
      heading: "Operator",
      label: "Operator",
      initialItems: operatordata,
      selectedItemIds: selectedItemIds,
      name: "Operator",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    {
      heading: "Stage",
      label: "Stage",
      initialItems: rejstage,
      selectedItemIds: selectedItemIds,
      name: "RejectionStages",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: false,
      selectAll:true,
    },
    {
      heading: "Shift",
      label: "tablecolumns",
      initialItems: shiftOption,
      selectedItemIds: selectedItemIds,
      name: "Shift",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    // {
    //   heading: "Reasons",
    //   label: "tablecolumns",
    //   initialItems: dynamicData,
    //   selectedItemIds: selectedItemIds,
    //   name: "Reasons",
    //   enableLocalStorage: false,
    //   multiSelect: true,
    //   searchbar: true,
    // },
    {
      heading: "Tool",
      label: "tablecolumns",
      initialItems: tooldata,
      selectedItemIds: selectedItemIds,
      name: "Tool",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    {
      heading: "Periodicity",
      label: "tablecolumns",
      initialItems: periodOption,
      selectedItemIds: selectedItemIds,
      name: "Periodicity",
      enableLocalStorage: false,
      multiSelect: false,
      searchbar: false,
    },
    {
      heading: "Refrence",
      label: "tablecolumns",
      initialItems: Refrence,
      selectedItemIds: selectedItemIds,
      name: "Refrence",
      enableLocalStorage: false,
      multiSelect: false,
      searchbar: false,
    },
  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////
  const column = [
    { path: "RefrenceTag", label: `${selectedOption.Refrence}` },
    { path: "Count", label: "Rejection Count " },
  ];
  const column1 = [
    { path: "RefrenceTag", label: `${inputData.Refrence}` },
    { path: "Count", label: "Rejection Count " },
  ];
  const column2 = [
    { path: "Periodicity", label: "Period" },
    { path: "RefrenceTag", label: `${inputData.Refrence}` },
    { path: "Count", label: "Rejection Count" },
  ];

  const schema = {
    Equipment: joi.array().required().label("Equipment"),
    Component: joi.array().required().label("Component"),
    Operator: joi.array().required().label("Operator"),
    Shift: joi.array().required().label("please select at least one shift"),
    Tool: joi.array().required().label("Tool"),
    Periodicity: joi.array().required().label("Periodicity"),
    Reasons: joi.array().required().label("Reasons"),
    Operation: joi.array().required().label("Operation"),
    Refrence: joi.array().required().label("Refrence"),
    RejectionStages: joi.array().required().label("Stage"),
  };

  ////////////////// useref for the pie chart //////////////////////////////////////////////////////////////////////////////////////////////
  const chartContainerRef = useRef(null);

  /////////////////////////////////// Dropdown option  handler for the pie chart /////////////////////////////////////////////////////////////

  useEffect(() => {
    // Add a delegated event listener to the chart container
    // consol(chartContainerRef, "current reference", historicalData);
    if (chartContainerRef.current) {
      chartContainerRef.current.addEventListener("change", (event) => {
        if (event.target.tagName === "SELECT") {
          // Handle the change event for the select element in the tooltip
          const selectedValue = event.target.value;
          // consol(selectedValue); // Do something with the selected value

          setSelectedOption({ Refrence: selectedValue, root: 2 });
          setroot({ root: 2 });
          // consol("second runned ", { Refrence: selectedValue, root: 2 });
        }
      });
    }
  }, [selectedOption, historicalData]);

  //////////////////////////// HTML component of Dropdown option for pie chart /////////////////////////////////////////////////////////////

  // function createCustomHTMLContent(selectedOption) {

  //     return (
  //       '<div style="padding: 5px 5px 5px 5px;width:150px;height:200px">' +
  //       '<select className="rounded p-2 shadow" style="backgroundColor:white;border:none">' +
  //       '<option value="Select an option">Select an option</option>' +
  //       Refrence.map((one) => (
  //         '<option value="' + one.label + '">' + one.label + '</option>'
  //       )).join('') +
  //       '</select>' +
  //       '</div>'
  //     );

  // }

  /////////////////////////////////// HTML component for To view the data of bar chart onhover /////////////////////////////////////////////
  function createCustomHTMLContentBarchart(Ref, From, Till, Count) {
    return (
      '<div style="padding: 5px 5px 5px 5px;width:200px;backgroundColor: #dbdbdb2e">' +
      '<table className="medals_layout">' +
      "<tr>" +
      "<td>" +
      "Ref" +
      "</td>" +
      "<td><b>" +
      Ref +
      "</b></td>" +
      "</tr>" +
      "<tr>" +
      "<td>" +
      "Count" +
      "</td>" +
      "<td><b>" +
      Count +
      "</b></td>" +
      "</tr>" +
      "<td>" +
      "From" +
      "</td>" +
      "<td><b>" +
      From +
      "</b></td>" +
      "</tr>" +
      "<tr>" +
      "<td>" +
      "Till" +
      "</td>" +
      "<td><b>" +
      Till +
      "</b></td>" +
      "</tr>" +
      "<tr>" +
      "</table>" +
      "</div>"
    );
  }


  useEffect(() => {

    const fetchdata = async() => {
let qualityReasonList;
let result =await storeDataInLocalStorage('qualityreason')
 
   
if (result?.length > 0) {
  
  qualityReasonList = result;
        
  let filterdata = selecetdqualitygroup?.map(item=> qualityReasonList?.filter(reason=>reason.QualityReasonGroup === item))
  filterdata = _.flattenDeep(filterdata) 
  // consol(filterdata,"uykiuvjkjvfiugjk",selecetdqualitygroup)
     if(filterdata?.length > 0) {
       const Qualitylist = filterdata
       .map((reason, index) => ({
         id: `${index}`,
         label: reason.QualityReason,
       }))
       .slice()
       .sort((a, b) => a.label.localeCompare(b.label));
       setDynamicData(Qualitylist);
     }else{
      setDynamicData([])
     }
    
      }
  
    }
    fetchdata();
   }, [selecetdqualitygroup])
   
  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
      setQuerySuccess(true);

        // const qualitydata = await axios.get(api.api + "qualityreason");
        // const componentdata = await axios.get(api.api1 + "component");
        // const operatordata = await axios.get(api.api1 + "operator");
        // const tooldata = await axios.get(api.api1 + "tool");
        // const equipmentdata = await axios.get(api.api1 + "equipment");
        // const rejectionstages = await axios.get(api.api + "rejectionstage");


        let qualitydata = await storeDataInLocalStorage('qualityreason')
        let componentdata = await storeDataInLocalStorage('component')
        let operatordata = await storeDataInLocalStorage('operator')
        let tooldata = await storeDataInLocalStorage('tool')
        let equipmentdata = await storeDataInLocalStorage('equipment')
        let rejectionstages = await storeDataInLocalStorage('rejectionstage')

        // const qualitylist = qualitydata.data
        //   .map((reason, index) => ({
        //     id: `${index}`,
        //     label: reason.QualityReason,
        //   }))
        //   .slice()
        //   .sort((a, b) => a.label.localeCompare(b.label));
        const complist = componentdata
          .map((componentList, index) => ({
            id: `${index}`,
            label: componentList.ComponentId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        // Iterate through the data and extract operation IDs
        const operationIds = [];
        componentdata.forEach((item) => {
          item.Operation.forEach((operation) => {
            operationIds.push(operation.OperationID);
          });
        });
        // Sort and remove duplicates from operationIds
        const uniqueSortedOperations = [...new Set(operationIds)]
          .sort((a, b) => a - b)
          .map((operation, index) => ({ id: index, label: operation }));
        const operatorlist = operatordata
          .map((operator, index) => ({
            id: `${index}`,
            label: operator.Name,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        const toollist = tooldata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.ToolId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        const stagelist = rejectionstages
          .map((stage, index) => ({
            id: `${index}`,
            label: stage.Stage,
          }))
          .slice()
          .sort((a, b) => a.id.localeCompare(b.id));



          const uniqueReasonGroups = {};

// Filter the array to remove duplicates based on ReasonGroup
const uniqueData = qualitydata.filter((obj) => {
  const reasonGroup = obj.QualityReasonGroup;

  if (!uniqueReasonGroups[reasonGroup]) {
    // If this ReasonGroup is not in the uniqueReasonGroups object, mark it as seen and return true to keep it in the result
    uniqueReasonGroups[reasonGroup] = true;
    return true;
  }

  // If the ReasonGroup is already in the uniqueReasonGroups object, return false to filter it out
  return false;
});

          const qualityReasonGroup = uniqueData
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.QualityReasonGroup,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
          setQualityreasonGroup(qualityReasonGroup)
        setrejstage(stagelist);
        setequpdata(equiplist);
        setoperationdata(uniqueSortedOperations);
        settooldata(toollist);
        setoperatordata(operatorlist.sort((a, b) => a[1] - b[1]));
      
        setcomponentdata(complist);
        // consol("fetching data",qualityReasonGroup);
        
      } catch (error) {
        // console.error("Error fetching data:", error);
      setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts

  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);
    if(name==='QualityReasonGroup'){
      setselecetdqualitygroup(newSelectedItems)
    
    }
    const validHeadings = [
      "Equipment",
      "Component",
      "Operation",
      "Operator",
      "Shift",
      "Tool",
      "Periodicity",
      "Reasons",
      "Refrence",
      "RejectionStages",
      // "QualityReasonGroup"
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));

    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Reasons") {
      inputData[name] = newSelectedItems;
    } else {
      inputData[name] =
        newSelectedItems.length == totallength ? ["all"] : newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData);
    setsenddata(inputData);
    setCompareref(inputData.Refrence);
  };

  // ///////// handel pareto //////////
  const organiseParetoData = (clone, filter, periodicity) => {
    let data = [...clone];
    // consol(clone, filter, periodicity,"checking error for stage")
    let result = [];
    let testtable = [];
    if (filter == "RejectionReason" || filter == "Stage") {
      if (filter === "RejectionReason") {
        data.forEach((item) => {
          const existingItem = result.find(
            (obj) => obj.RejectionReason === item.RejectionReason
          );
          if (existingItem) {
            existingItem.Count += item.Count;
          } else {
            result.push({ ...item });
          }
        });
        let culumntabledata = [...result];

        culumntabledata.forEach((one) => {
          let Count = one.Count;
          let RefrenceTag = one.RejectionReason;
          if (inputData.Refrence == "time") {
            RefrenceTag = "Total Time";
          }

          testtable.push({
            Count: Count,
            RefrenceTag: RefrenceTag,
          });
        });
      } else {
        data.forEach((item) => {
          const existingItem = result.find((obj) => obj.Stage === item.Stage);
          if (existingItem) {
            existingItem.Count += item.Count;
          } else {
            result.push({ ...item });
          }
        });
        let culumntabledata = [...result];

        culumntabledata.forEach((one) => {
          let Count = one.Count;
          let RefrenceTag = one.Stage;
          if (inputData.Refrence == "time") {
            RefrenceTag = "Total Time";
          }

          testtable.push({
            Count: Count,
            RefrenceTag: RefrenceTag,
          });
        });
      }
    } else {
      data.forEach((item) => {
        const existingItem = result.find(
          (obj) => obj.RefrenceTag === item.RefrenceTag
        );
        if (existingItem) {
          existingItem.Count += item.Count;
        } else {
          result.push({ ...item });
        }
      });
      let culumntabledata = [...result];

      culumntabledata.forEach((one) => {
        let Count = one.Count;
        let RefrenceTag = one.RefrenceTag;
        if (inputData.Refrence == "time") {
          RefrenceTag = "Total Time";
        }
        testtable.push({
          Count: Count,
          RefrenceTag: RefrenceTag,
        });
      });
    }
    if (filter !== "yes") {

    // consol('table data stage')
      let groupbarchart = [];
      let Periodicity;
      data.forEach((one) => {

        if(senddata.Periodicity[0] === 'Shift'){
          Periodicity = formatInputDateRange1(one.Periodicity);

      
        }else{
           Periodicity = formatInputDateRange(one.Periodicity);
       
        }
        let Count = one.Count;
        let RefrenceTag = one.RejectionReason || one.RefrenceTag || one.Stage
        if (inputData.Refrence == "time") {
          RefrenceTag = "Total Time";
        }

        groupbarchart.push({
          Periodicity: Periodicity || periodicity,
          Count: Count,
          RefrenceTag: RefrenceTag,
        });
      });
      // consol(groupbarchart, "groupbarchart");
      setcolumns2(
        groupbarchart
          .filter((data) => data.Count > 0)
          .sort((a, b) => b.Count - a.Count)
      );
    }

    // consol(result, "pareto of daily data",data,testtable);
    result.sort((a, b) => b.Count - a.Count);

    setcolumns1(
      testtable
        .filter((data) => data.Count > 0)
        .sort((a, b) => b.Count - a.Count)
    );
    let RejectionParetoArray = [];
    let schema = [
      "State",
      "Time( in minutes)",
      { role: "style" },
      "Percentage(%)",
    ];
    RejectionParetoArray.push(schema);

    const totalCount = result.reduce((total, item) => total + item.Count, 0);
    let cumulativePercentage = 0;
    const paretoData = result.map((item) => {
      cumulativePercentage += (item.Count / totalCount) * 100;
      return {
        Name: item.RefrenceTag || item.RejectionReason,
        Count: item.Count,
        cumulativePercentage,
      };
    });

    for (let i = 0; i < paretoData.length; i++) {
      let StateArray = [];

      StateArray.push(paretoData[i].Name);
      StateArray.push(parseFloat(paretoData[i].Count.toFixed(1)));
      StateArray.push("opacity: 0.4; fill-color: #C5A5CF");
      StateArray.push(
        parseFloat(paretoData[i].cumulativePercentage.toFixed(1))
      );
      RejectionParetoArray.push(StateArray);
    }
    // }
    // consol(RejectionParetoArray, "paretodata");
    return RejectionParetoArray;
  };

  const renderParetoChart = (data) => {
    return (
      <div
        className="shadow d-flex justify-content-center mx-3 my-3 "
        style={{
          backgroundColor: "white",
          borderRadius: "20px",
          width: "98%",
          overflow: "hidden",
        }}
      >
        <Chart
          width="100%"
          height="500px"
          chartType="ComboChart"
          loader={<div>Loading Chart</div>}
          data={data}
          options={{
            // backgroundColor: { strokeWidth: 2 },
            chartArea: { left: 80, top: 60, right: 80, bottom: 100 },
            vAxes: [
              { title: "Rejection Count( in minutes)", minValue: 0 },
              { title: " Cumulative Percentage", minValue: 0, maxValue: 100 },
            ],

            hAxis: {
              title: `${Compareref[0]}`,
              slantedTextAngle: 90,
              slantedText: true,
              textStyle: { fontName: "Roboto", fontSize: 12 },
            },
            seriesType: "bars",
            series: { 1: { type: "line", targetAxisIndex: 1 } },
          }}
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  };

  ////////////////////////////////////////// Barchart Click event Handler    // also used for sending data to pie chart by setting data  to usestate   /////////////////////////////////////////////////////////////

  const handleBarClick = (selectedItem) => {
    const bar = data[selectedItem.row + 1][0];
    const barvalue = data[selectedItem.row + 1][selectedItem.column];
    const bardata = data[selectedItem.row + 1][selectedItem.column + 2];

    // // consol(
    //   data[selectedItem.row + 1],
    //   selectedItem,
    //   "selected column",
    //   barvalue,
    //   bardata
    // );
    SetDistributionvalues(bardata);
    setSelectedCity(bardata[0]);
    setSelectedOption("");
    setpieperiodicity(bar);
    setpieheader([]);
    setpiedata([]);
    setHistoricalData([]);
   
  };

  ///////////////////////////////        useeffect to generate data for the  pie chart Trigger on selction from dropdown /////////////////////////////////
  useEffect(() => {
    if (selectedOption.Refrence?.length > 0) {
      // consol(selectedOption, "runned ");
      let inputData = { ...senddata };
      let data = [...Distributionvalues];
      // consol(previousInputData, "previous value1");

      if (previousInputData.Refrence?.length > 0) {
        // consol(previousInputData, "previous value2");
        if (selectedOption.root == 2) {
          inputData = { ...previousInputData };
          // consol(previousInputData, "previous value3");
          inputData.Refrence = [selectedOption.Refrence];
          inputData[head] = [newdata];
        }
      } else {
        if (selectedOption.root == 1) {
          inputData.Refrence = [selectedOption.Refrence];
        }
      }
      inputData[Compareref[0]] = [data[0]];
      inputData.Periodicity = ["Cummulative"];
      inputData.Refrence = [selectedOption.Refrence];
      inputData.From = data[1];
      inputData.Till = data[2];
      const compressedData = pako.gzip(JSON.stringify(inputData));
      setQuerySuccess(true);
      axios
        .post(api.api + "GetQualityAnalysis", compressedData, {
          headers: {
            "Content-Encoding": "gzip", // Set the content encoding to gzip
            "Content-Type": "application/json", // Set the content type to JSON
          },
        })
        // const result = await axios.post(api.api + 'GetQualityAnalysis', inputData)
        .then((result) => {
          const decompressedData = pako.ungzip(result.data, { to: "string" });

          // Assuming the decompressed data is in JSON format, parse it
          const jsonData = JSON.parse(decompressedData);
          if (jsonData.length > 0) {
            if (jsonData[0].RefrenceTag !== "Reasons" && jsonData[0].RefrenceTag !== "StageSummary") {
              const header = ["String", "Rejection Count"];
              setpieDateTime({
                From: jsonData[0].From,
                Till: jsonData[0].Till,
              });
              const convertedArray = [
                header,
                ...jsonData.map((obj) => [obj.RefrenceTag, obj.Count]),
              ];
              // consol(convertedArray, "pie chart data chl", jsonData);
              setpiedata(convertedArray.sort((a, b) => a[1] - b[1]));
              // if(root.root == 2){
              setHistoricalData((prevData) => [...prevData, convertedArray]);
              setpieheader((prevData) => [
                ...prevData,
                { Refrence: selectedOption.Refrence, inputData: inputData },
              ]);
              const testtable = [];
              jsonData.forEach((data) => {
                let Count = data.Count;
                let RefrenceTag = data.RefrenceTag;

                testtable.push({
                  Count: Count,
                  RefrenceTag: RefrenceTag,
                });
              });
              // }
              setcolumn(
                testtable
                  .filter((data) => data.Count > 0)
                  .sort((a, b) => b.Count - a.Count)
              );
            } else {
              if(jsonData[0].RefrenceTag === "StageSummary"){
                setpieDateTime({
                  From: jsonData[0].From,
                  Till: jsonData[0].From,
                });
                const header = ["String", "Rejection Count"];
                jsonData.forEach((one) => {
                  // consol(one.Summary, "summary document");
                  const convertedArray = [
                    header,
                    ...one.Summary.map((obj) => [obj.Stage, obj.Count]),
                  ];
                  // consol(convertedArray, "pie chart data", jsonData);
                  setpiedata(convertedArray.sort((a, b) => a[1] - b[1]));
                  // if(root.root == 2){
                  setHistoricalData((prevData) => [...prevData, convertedArray]);
                  setpieheader((prevData) => [
                    ...prevData,
                    { Refrence: selectedOption.Refrence, inputData: inputData },
                  ]);
                  const testtable = [];
                  one.Summary.forEach((data) => {
                    let Count = data.Count;
                    let RefrenceTag = data.Stage;
  
                    testtable.push({
                      Count: Count,
                      RefrenceTag: RefrenceTag,
                    });
                  });
                  // }
                  // // consol(
                  //   testtable
                  //     .filter((data) => data.Count > 0)
                  //     .sort((a, b) => b.Count - a.Count),
                  //   "table for test data"
                  // );
                  setcolumn(
                    testtable
                      .filter((data) => data.Count > 0)
                      .sort((a, b) => b.Count - a.Count)
                  );
                });
              }else {   setpieDateTime({
                From: jsonData[0].From,
                Till: jsonData[0].From,
              });
              const header = ["String", "Rejection Count"];
              jsonData.forEach((one) => {
                // consol(one.Summary, "summary document");
                const convertedArray = [
                  header,
                  ...one.Summary.map((obj) => [obj.RejectionReason, obj.Count]),
                ];
                // consol(convertedArray, "pie chart data", jsonData);
                setpiedata(convertedArray.sort((a, b) => a[1] - b[1]));
                // if(root.root == 2){
                setHistoricalData((prevData) => [...prevData, convertedArray]);
                setpieheader((prevData) => [
                  ...prevData,
                  { Refrence: selectedOption.Refrence, inputData: inputData },
                ]);
                const testtable = [];
                one.Summary.forEach((data) => {
                  let Count = data.Count;
                  let RefrenceTag = data.RejectionReason;

                  testtable.push({
                    Count: Count,
                    RefrenceTag: RefrenceTag,
                  });
                });
                // }
                // // consol(
                //   testtable
                //     .filter((data) => data.Count > 0)
                //     .sort((a, b) => b.Count - a.Count),
                //   "table for test data"
                // );
                setcolumn(
                  testtable
                    .filter((data) => data.Count > 0)
                    .sort((a, b) => b.Count - a.Count)
                );
              })}
            }
          }else {
            swal("Data Not Available");
            setQuerySuccess(false);
          }
          // Save the current inputData as the previous inputData
          setPreviousInputData(inputData);
          setQuerySuccess(false);
        })
        .catch((err) => {
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          // consol(err);
          setQuerySuccess(false);
        });
    }
  }, [selectedOption.Refrence, selectedOption.root]);
  // consol(historicalData, "historical data list", selectedOption.Refrence);

  //////////////////////////// Select Distribution Event handler for barchart /////////////////////////////////////////////////////////////////////////////
  const handleSelectChange = (event) => {
    // consol(event.target.value, "current value of selected option");
    setSelectedOption({ Refrence: event.target.value, root: 1 });
    setroot({ root: 1 });
    setHistoricalData([]);
    setpieheader([]);
    setpiedata([]);
  };

  const handleSort = (sortColumn) => {
    if (columns.length > 0) {
      const sorted = _.orderBy(columns, [sortColumn.path], [sortColumn.order]);
      setcolumn(sorted);
      setSortColumn(sortColumn);
    }
  };
  const handleSort1 = (sortColumn) => {
    if (columns1.length > 0) {
      const sorted = _.orderBy(columns1, [sortColumn.path], [sortColumn.order]);
      setcolumns1(sorted);
      setSortColumn(sortColumn);
    }
  };
  const handleSort2 = (sortColumn) => {
    if (columns2.length > 0) {
      const sorted = _.orderBy(columns2, [sortColumn.path], [sortColumn.order]);
      setcolumns2(sorted);
      setSortColumn(sortColumn);
    }
  };

  function formatInputDateRange(inputStr) {
    if(inputStr === undefined ||  inputStr === 'undefined') {
      return
    }
    const parts = inputStr.split(",");
    const startStr = parts[1];
    const endStr = parts[2];
  
    const format = "DD MMM YYYY ddd";
    const formattedStartDate = moment(startStr, "ddd MMM DD YYYY h:mm:ss A").format(format);
    const formattedEndDate = moment(endStr, "ddd MMM DD YYYY h:mm:ss A").format(format);
  
    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
  
    return formattedDateRange;
  }
  function formatInputDateRange1(inputStr) {
    if(inputStr === undefined ||  inputStr === 'undefined') {
      return
    }
    const parts = inputStr.split(",");
    const startStr = parts[0];
    const endStr = parts[1];
  
    const format = "DD MMM YYYY ddd";
    const formattedStartDate = startStr;
    const formattedEndDate = moment(endStr, "ddd MMM DD YYYY h:mm:ss A").format(format);
  
    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
  
    return formattedDateRange;
  }
  
  /////////////////////// Button to cancel all the pie chart which also chear the pie history array /////////////////////////////////////////////////////////////
  const goBackToBarChart = () => {
    setSelectedCity(null);
    setpiedata([]);
    setHistoricalData([]);
    setpieheader([]);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };
  // consol(selectedDates, "date to be send");

  function compareDates(dateRangeA, dateRangeB) {
    const dateA = new Date(dateRangeA[0].split(' - ')[0]);
    const dateB = new Date(dateRangeB[0].split(' - ')[0]);
    return dateA - dateB;
  }
  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  let handleOnSubmit = () => {
    let inputData = { ...senddata };
    let eqg =selectedEquipOption.map((option) => option.label)
    let eqp =selectedEquipGroupOption.map((option) => option.label)
    inputData.Equipment = eqg

    const errors = validate.validate(inputData, schema);

    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true);
    let dateTime = { ...selectedDates };
    if(new Date(dateTime.From) <= new Date(dateTime.Till)){
      inputData.From = dateTime.From;
      inputData.Till = dateTime.Till;
    }else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    const compressedData = pako.gzip(JSON.stringify(inputData));

    axios
      .post(api.api + "GetQualityAnalysis", compressedData, {
        headers: {
          "Content-Encoding": "gzip", // Set the content encoding to gzip
          "Content-Type": "application/json", // Set the content type to JSON
        },
      })
      // const result = await axios.post(api.api + 'GetQualityAnalysis', inputData)
      .then((result) => {
        const decompressedData = pako.ungzip(result.data, { to: "string" });

        // Assuming the decompressed data is in JSON format, parse it
        const jsonData = JSON.parse(decompressedData);
        // consol(jsonData, "Rejection result received");
        if (jsonData !== null && jsonData !== undefined) {
          if (jsonData.length > 0) {
            const groupedData2 = [];

            const groupedData = {};
            if(jsonData[0].Summary <= 0 ){
              // consol('No Data Available')
              setQuerySuccess(false);
             return swal('No Data Available')
            }

            // jsonData.forEach(d => {
            if (jsonData[0].RefrenceTag !== "StageSummary" && jsonData[0].RefrenceTag !== "Reasons") {

              jsonData.sort((a, b) => b.Count - a.Count);
              // Loop through the raw data and group by 'Periodicity'

              let paretodata = organiseParetoData(jsonData, "RefrenceTag");
              setparetodata(paretodata);
              // Loop through the raw data and group by 'Periodicity'
              let Periodicity;
              jsonData.forEach((entry) => {
                if(senddata.Periodicity[0] === 'Shift'){
                  Periodicity = formatInputDateRange1(entry.Periodicity);
        
              
                }else{
                   Periodicity = formatInputDateRange(entry.Periodicity);
               
                }
                if (inputData.Periodicity == "Cummulative") {
                  Periodicity = "Cummulative";
                }
                let From = moment(entry.From).format("MMMM D, YYYY h:mm A");
                let Till = moment(entry.Till).format("MMMM D, YYYY h:mm A");
                let Ref = entry.RefrenceTag;
                if (inputData.Refrence == "time") {
                  Ref = "Total Rejection Count";
                }
                let Count = entry.Count;
                if (!groupedData[Periodicity]) {
                  groupedData[Periodicity] = [];
                }
                groupedData[Periodicity].push(
                  Count,
                  Ref,
                  [Ref, From, Till, Count],
                  createCustomHTMLContentBarchart(Ref, From, Till, Count)
                );
              });
              
              // Convert the grouped data into the desired format
              const transformed = Object.entries(groupedData).map(
                ([periodicity, Count]) => [periodicity, ...Count]
                );
                const  transformedData = transformed.sort(compareDates);
                // consol("transformedData data", transformedData);

              let allobject = Object.values(groupedData);
              // consol(groupedData, "grouped data", groupedData2, allobject);

              const maxLength = Math.max(
                ...transformedData.map((entry) => entry.length)
              );

              // Pad arrays with zeros
              const paddedData = transformedData.map((entry) => {
                while (entry.length < maxLength) {
                  entry.push(null);
                }
                return entry;
              });

              const columnLength = ["weeks"];
              for (let i = 0; i < transformedData[0].length / 4 - 1; i++) {
                columnLength.push(
                  "count",
                  { role: "annotation" },
                  { type: "number", role: "interval" },
                  { type: "string", role: "tooltip", p: { html: true } }
                );
              }
              
              // consol(transformedData, "final table data");
              let tabledata = [columnLength, ...transformedData];
              // consol(tabledata.slice().sort((a, b) => b[1] -a[1]),"data converted  for table")

              settabledata(tabledata);
            } else { 
let dailyrejection=[]
              // consol("for reasons")
              if (jsonData[0].RefrenceTag === "StageSummary") {
                let Periodicity;
                jsonData.forEach((d) => {
                  let periodicityfortable =d.Periodicity
                  if(senddata.Periodicity[0] === 'Shift'){
                    Periodicity = formatInputDateRange1(d.Periodicity);
          
                  
                    
                  }else{
                     Periodicity = formatInputDateRange(d.Periodicity);
                 
                  }
                  // let Periodicity = formatInputDateRange(d.Periodicity);
                  if (inputData.Periodicity == "Cummulative") {
                    Periodicity = "Cummulative";
                  }
                  let From = moment(d.From).format("MMMM D, YYYY h:mm A");
                  let Till = moment(d.Till).format("MMMM D, YYYY h:mm A");
                 
                  // let paretodata = organiseParetoData(d.Summary,'Stage',Periodicity)
                 
                  d.Summary.forEach((one) => {
                    // consol(d, "Enterd 1", one);
                   
                    let Ref = one.Stage;
                    if (inputData.Refrence == "time") {
                      Ref = "Total Stage Count";
                    }
                    let Count = one.Count;
                    dailyrejection.push({Periodicity:periodicityfortable ,Stage:one.Stage,Count:one.Count})
                    if (!groupedData[Periodicity]) {
                      groupedData[Periodicity] = [];
                    }
                    groupedData[Periodicity].push(
                      Count,
                      Ref,
                      [Ref, From, Till, Count],
                      createCustomHTMLContentBarchart(Ref, From, Till, Count)
                    );
                  });
                });
                let paretodata = organiseParetoData(dailyrejection,'Stage',Periodicity)
                setparetodata(paretodata)

                const transformed = Object.entries(groupedData).map(
                  ([periodicity, Count]) => [periodicity, ...Count]
                  );
                  const  transformedData = transformed.sort(compareDates);
                let allobject = Object.values(groupedData);
                // // consol(
                //   groupedData,
                //   "grouped data",
                //   groupedData2,
                //   allobject
                // );

                const maxLength = Math.max(
                  ...transformedData.map((entry) => entry.length)
                );

                // Pad arrays with zeros
                const paddedData = transformedData.map((entry) => {
                  while (entry.length < maxLength) {
                    entry.push(null);
                  }
                  return entry;
                });

                const columnLength = ["weeks"];
                for (let i = 0; i < transformedData[0].length / 4 - 1; i++) {
                  columnLength.push(
                    "count",
                    {
                      sourceColumn: 0,
                      role: "annotation",
                      type: "string",
                      calc: "stringify",
                    },
                    { type: "number", role: "interval" },
                    { type: "string", role: "tooltip", p: { html: true } }
                  );
                }
                // consol("transformedData data", transformedData);
                let tabledata = [columnLength, ...transformedData];
                // let tabledata =[["string", "Rejection Count 1",{role: 'style' },{type: 'string', role: 'tooltip'}], ...transformedData]
                // consol(tabledata, "Rejectionreason data");
                settabledata(tabledata);
              } else {

                let dailyrejection = [];
                let Periodicity;
                jsonData.forEach((d) => {
                  // consol("Enterd", d);
                 let periodicityfortable =d.Periodicity
                 if(senddata.Periodicity[0] === 'Shift'){
                  Periodicity = formatInputDateRange1(d.Periodicity);
        
                
                  
                }else{
                   Periodicity = formatInputDateRange(d.Periodicity);
               
                }
                  if (inputData.Periodicity == "Cummulative") {
                    Periodicity = "Cummulative";
                  }
                  let From = moment(d.From).format("MMMM D, YYYY h:mm A");
                  let Till = moment(d.Till).format("MMMM D, YYYY h:mm A");
                  
                 
                  // d.Summary.sort((a, b) => b.Count - a.Count);
                  
                  
                  d.Summary.forEach((one) => {
                    let Ref = one.RejectionReason;
                    if (inputData.Refrence == "time") {
                      Ref = "Total Rejection Count";
                    }
                    let Count = one.Count;
                    dailyrejection.push({Periodicity:periodicityfortable ,RejectionReason: one.RejectionReason,Count:one.Count})
                    if (!groupedData[Periodicity]) {
                      groupedData[Periodicity] = [];
                    }
                    groupedData[Periodicity].push(
                      Count,
                      Ref,
                      [Ref, From, Till, Count],
                      createCustomHTMLContentBarchart(Ref, From, Till, Count)
                    );
                    
                    // let ref= one.RejectionReason
                    // let down =one.Count
                    // shorteddata.push([ref,down,"opacity: 0.7",[Periodicity,From,Till,'Rejection Count:'+down]])
                  });
                });
                let paretodata = organiseParetoData(
                  dailyrejection,
                  "RejectionReason",
                  'Cummulative'
                );
                setparetodata(paretodata);
                 const transformed = Object.entries(groupedData).map(
                ([periodicity, Count]) => [periodicity, ...Count]
                );
                const  transformedData = transformed.sort(compareDates);
                let allobject = Object.values(groupedData);
                // consol(
                //   groupedData,
                //   "grouped data",
                //   dailyrejection
                // );

                const maxLength = Math.max(
                  ...transformedData.map((entry) => entry.length)
                );

                // Pad arrays with zeros
                const paddedData = transformedData.map((entry) => {
                  while (entry.length < maxLength) {
                    entry.push(null);
                  }
                  return entry;
                });

                const columnLength = ["weeks"];
                for (let i = 0; i < transformedData[0].length / 4 - 1; i++) {
                  columnLength.push(
                    "count",
                    {
                      sourceColumn: 0,
                      role: "annotation",
                      type: "string",
                      calc: "stringify",
                    },
                    { type: "number", role: "interval" },
                    { type: "string", role: "tooltip", p: { html: true } }
                  );
                }
                // consol("transformedData data", transformedData);
                let tabledata = [columnLength, ...transformedData];
                // let tabledata =[["string", "Rejection Count 1",{role: 'style' },{type: 'string', role: 'tooltip'}], ...transformedData]
                // consol(tabledata, "Rejectionreason data");
                settabledata(tabledata);
              }
            }

            if (piedata?.length > 0) {
              setSelectedCity(null);        
              setpiedata([]);
              setHistoricalData([]);
              setpieheader([]);
            }

            setQuerySuccess(false);
          } else {
            swal("Data Not Available");
            setQuerySuccess(false);
          }
        } else {
          setQuerySuccess(false);
        }
      })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err);
        setQuerySuccess(false);
      });
  };
  // consol(historicalData, "historicalData");
  const handelclosepie = (index) => {
    // consol(historicalData.length, "historicalData length");

    const removedArray = historicalData[0];
    const newArray = [pieheader[0]];
    setpieheader([]);
    setHistoricalData([]);
    setroot({ root: 1 });
    setpiedata(removedArray);
    const ref = newArray[0]?.Refrence;
    setSelectedOption({ Refrence: ref, root: 1 });
    setsenddata(newArray[0]?.inputData);
    // consol(removedArray, "removedArray", senddata, newArray);
  };

  // consol(root, "current root");
  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };

  /////////////////// pie chart options ///////////////////////////////////////////////////////////////////////////////////////////
  const options = {
    chartArea: { left: 100, top: 20, right: 100, bottom: 20 },
    title: `${selectedCity} / From: ${moment(pieDateTime.From).format(
      "MMMM D, YYYY h:mm A"
    )} Till: ${moment(pieDateTime.Till).format(
      "MMMM D, YYYY h:mm A"
    )} / Distribution BY ${selectedOption.Refrence} `,
    is3D: true,
    // tooltip: { isHtml: true, trigger: "selection" },
  };
  // consol(pieheader, "pihearder");
  const SelectedDates =(dates)=>{
    setSelectedDates(dates)
  }
  return (
    <div  className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Quality Detailed Analysis
        </h4>
      </div>
      <ToastContainer />

      <div className="row mx-3">
       {componentdata.length > 0 ?  <>
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

       {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
              />
          </div>
        </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              />
          </div>
        </div> */}
            
        <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />

        <div className="col-sm-2 ">
                <div className="form-group text-center ">
                 {QualityReasonGroup &&(<>
             

 <label className="small">{QualityReasonGroup.heading}</label>
                 
                 <ReusableCheckboxDropdown
                      initialItems={QualityReasonGroup.initialItems}
                      label={QualityReasonGroup.label}
                      heading={`${QualityReasonGroup.heading}`}
                      selectedItemIds={QualityReasonGroup.selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        // (newSelectedItems) for No. of item selected / current QualityReasonGroup name (QualityReasonGroup.name ) / (QualityReasonGroup.initialItems.length) is maximun length of list
                        handleSelectedItemsChange(newSelectedItems, QualityReasonGroup.name,QualityReasonGroup.initialItems.length) 
                      }
                      enableLocalStorage={QualityReasonGroup.enableLocalStorage} // Pass the enableLocalStorage prop to enable local storage (true/false)
                      multiSelect={QualityReasonGroup.multiSelect} // Pass the multiSelect prop to enable multiselect (true/false || " ")
                      searchbar={QualityReasonGroup.searchbar}
                      selectAll={QualityReasonGroup.selectAll}

                      />    </>)}</div></div>
 <div className="col-sm-2 ">
                <div className="form-group text-center ">
{dynamicData?.length == 0 ? <>
 <label className="small">{Reasons.heading}</label>
<ReusableCheckboxDropdown
                      initialItems={Reasons.initialItems}
                      label={Reasons.label}
                      heading={`${Reasons.heading}`}
                      selectedItemIds={Reasons.selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        // (newSelectedItems) for No. of item selected / current Reasons name (Reasons.name ) / (Reasons.initialItems.length) is maximun length of list
                        handleSelectedItemsChange(newSelectedItems, Reasons.name,Reasons.initialItems.length) 
                      }
                      enableLocalStorage={Reasons.enableLocalStorage} // Pass the enableLocalStorage prop to enable local storage (true/false)
                      multiSelect={Reasons.multiSelect} // Pass the multiSelect prop to enable multiselect (true/false || " ")
                      searchbar={Reasons.searchbar}
                      selectAll={Reasons.selectAll}

                      />
                      </> :''}
                      {dynamicData?.length > 0 ? (
  <React.Fragment key={dynamicData.length}>
    <label className="small">{Reasons.heading}</label>
    <ReusableCheckboxDropdown
      initialItems={Reasons.initialItems}
      label={Reasons.label}
      heading={`${Reasons.heading}`}
      selectedItemIds={Reasons.selectedItemIds}
      onSelectedItemsChange={(newSelectedItems) =>
        handleSelectedItemsChange(newSelectedItems, Reasons.name, Reasons.initialItems.length) 
      }
      enableLocalStorage={Reasons.enableLocalStorage}
      multiSelect={Reasons.multiSelect}
      searchbar={Reasons.searchbar}
      selectAll={Reasons.selectAll}

    />
  </React.Fragment>
) : ''}                 
                        </div></div>
        {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  {/* {componentdata.length > 0 && ( */}
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${
                        selectedItemLengths[data.name] ==
                        data.initialItems.length
                          ? "All"
                          : selectedItemLengths[data.name] || 0
                      }  ${data.heading}`}
                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar}
                      selectAll={data.selectAll}

                    />
                  {/* )} */}
                  {errors[data.heading] && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}
 
        <div className="col-sm-2">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div> </>
        :''}
        {paretodata?.length > 0 ? (
          <div className="col-sm-3">
            <div className="cl-toggle-switch mx-2 mt-3">
              <span className="cl-toggle-span">
                <label className="cl-switch m-0">
                  <input
                    type="checkbox"
                    checked={tableVisible3}
                    onChange={handleToggle3}
                  />
                  <span>View Pareto</span>
                </label>
              </span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {data.length > 0 ? (
        <div className="mt-5 ">
          <div
            className="shadow-sm d-flex justify-content-center mx-3 mb-3"
            style={{
              backgroundColor: "white",
              borderRadius: "20px",
              width: "98%",
              overflow: "hidden",
            }}
          >
            <Chart
              chartType="ColumnChart"
              width="100%"
              height="500px"
              data={data}
              options={{
                title: "Rejection Count Analysis",

                chartArea: { left: 80, top: 50, right: 50, bottom: 60 },
                legend: "none",
                annotations: {
                  style: "line",
                  alwaysOutside: false,
                  stem: {
                    color: "transparent",
                    length: 0, // Adjust the length of the annotation line
                  },
                  textStyle: {
                    fontSize: 12,
                  },
                },

                tooltip: { isHtml: true },
                hAxis: {
                  title: ` Reference By ${Compareref[0]}`,
                  minValue: 0,
                  // slantedText: true,
                  // slantedTextAngle: 90,
                },
                vAxis: {
                  title: "Total Rejection",
                },
                bar: {
                  groupWidth: "90%",
                },
                animation: {
                  Count: 1000,
                  easing: "out",
                  startup: true,
                },
              }}
              chartEvents={[
                {
                  eventName: "select",
                  callback: ({ chartWrapper }) => {
                    const selectedItem = chartWrapper
                      .getChart()
                      .getSelection()[0];
                    if (selectedItem) {
                      handleBarClick(selectedItem);
                    }
                  },
                },
              ]}
            />
          </div>
          {data.length > 0 ? (
            <div
              className="d-flex align-content-between flex-wrap shadow py-3  mx-3"
              style={{
                backgroundColor: "white",
                borderRadius: "15px",
                overflow: "hidden",
                width: "98%",
              }}
            >
              <div className="cl-toggle-switch mx-3 mt-3 mb-2">
                <span className="cl-toggle-span">
                  <label className="cl-switch">
                    <input
                      type="checkbox"
                      checked={tableVisible2}
                      onChange={handleToggle2}
                    />
                    <span>View ColumnChart In Table</span>
                  </label>
                </span>
              </div>
              {selectedCity && (
                <div className="mb-2 mx-2">
                  <p
                    style={{ color: "black", fontWeight: "500" }}
                    className=" p-2 bg-for-dishead rounded"
                  >
                    {Compareref[0]} : {Distributionvalues[0]} / {pieperiodicity}
                  </p>

                  <select
                    className="rounded p-2 mx-1 shadow"
                    style={{ backgroundColor: "white", border: "none" }}
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value="">Select Distribution</option>
                    {Refrence.filter((obj) => obj.label !== Compareref[0]).map(
                      (one) => (
                        <option value={one.label}>{one.label}</option>
                      )
                    )}
                  </select>

                  <button
                    style={{ height: "35px" }}
                    className="btn btn-danger "
                    onClick={goBackToBarChart}
                  >
                    {" "}
                    X
                  </button>
                </div>
              )}
            </div>
          ) : (
            ""
          )}

          {tableVisible2 &&
            (columns2?.length > 0 ? (
              <div
                style={{ backgroundColor: "white", borderRadius: "15px" }}
                className="mx-3 my-3 shadow "
              >
                <h2>Data Table</h2>
                <div className="d-flex mx-2">
                  <p
                    className=" text-center d-flex align-items-center align-middle p-0 m-0"
                    style={{ color: "black", fontWeight: "600" }}
                  >
                    From : {selectedDates.From} / Till :{selectedDates.Till}{" "}
                    Distribution By {Compareref[0]}
                  </p>
                  {/* <button
                 type="button" className="btn mx-2 btn-primary p-1"
                  onClick={() =>
                    handleDownloadExcel("RejectionGroupedBarchart")
                  }
                >
                  Download Excel
                </button> */}
                  <div
                    className="uiverse"
                    onClick={() =>
                      handleDownloadExcel("RejectionGroupedBarchart")
                    }
                  >
                    <span className="tooltip">Dowload Excel File</span>
                    <span>Download</span>
                  </div>
                </div>
                {columns2?.length > 0 ? (
                  <Table
                    id="RejectionGroupedBarchart"
                    onSort={handleSort2}
                    columns={column2}
                    sortColumn={sortColumn}
                    data={columns2}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            ))}

          {paretodata?.length > 0 && tableVisible3
            ? renderParetoChart(paretodata)
            : ""}

          {columns1?.length > 0 && tableVisible3 ? (
            <>
              <div
                className="d-flex align-content-between flex-wrap shadow py-3  mx-3"
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  overflow: "hidden",
                  width: "98%",
                }}
              >
                {tableVisible3 && (
                  <div className="cl-toggle-switch mx-3 mt-4">
                    <span className="cl-toggle-span">
                      <label className="cl-switch">
                        <input
                          type="checkbox"
                          checked={tableVisible1}
                          onChange={handleToggle1}
                        />
                        <span>View ColumnChart In Table</span>
                      </label>
                    </span>
                  </div>
                )}
              </div>

              {tableVisible1 && (
                <div
                  style={{ backgroundColor: "white", borderRadius: "15px" }}
                  className="mx-3 mt-3 shadow"
                >
                  <h2>Data Table</h2>
                  <div className="d-flex p-3">
                    <p
                      className=" text-center d-flex align-items-center align-middle p-0 m-0"
                      style={{ color: "black", fontWeight: "600" }}
                    >
                      Pareto Distribution by {Compareref[0]}
                    </p>
                    {/* <button
     type="button" className="btn p-1 btn-primary  mx-2"
     onClick={() =>
      handleDownloadExcel("RejectionDetailedAnalysiscolumn")
    }
    >
      Download Excel
    </button> */}
                    <div
                      className="uiverse"
                      onClick={() =>
                        handleDownloadExcel("RejectionDetailedAnalysiscolumn")
                      }
                    >
                      <span className="tooltip">Dowload Excel File</span>
                      <span>Download</span>
                    </div>
                  </div>
                  <Table
                    id="RejectionDetailedAnalysiscolumn"
                    onSort={handleSort1}
                    columns={column1}
                    sortColumn={sortColumn}
                    data={columns1}
                  />
                </div>
              )}
            </>
          ) : (
            ""
          )}
          <div className="d-flex justify-content-between px-3 pt-3">
            {selectedCity ? (
              <>
                {/* { selectedOption.length >0 ? (  */}
                <div
                  className="col-sm-12 shadow "
                  style={{
                    backgroundColor: "white",
                    borderRadius: "20px",
                    width: "99%",
                    overflow: "hidden",
                  }}
                >
                  {historicalData.length > 1 && (
                    <button
                      className="btn btn-danger p-1 float-end"
                      onClick={handelclosepie}
                    >
                      close
                    </button>
                  )}
                  <div className="">
                    {/* {root.root == 2 && historicalData.length > 0
                      ? historicalData.map((one, index) => (
                          <div ref={chartContainerRef}>
                            <Chart
                              loader={
                                <h1>Select Distribution For pie Chart </h1>
                              }
                              className="position-absolute top-50 start-50"
                              chartType="PieChart"
                              width="100%"
                              height="500px"
                              data={one}
                              options={{
                                chartArea: {left:100,top:20,right:100,bottom:20,},
                                title: `${selectedCity}  / ${pieperiodicity} / From: ${moment(
                                  pieDateTime.From
                                ).format("MMMM D, YYYY h:mm A")} Till: ${moment(
                                  pieDateTime.Till
                                ).format(
                                  "MMMM D, YYYY h:mm A"
                                )} / Distribution BY ${
                                  pieheader[index]?.Refrence
                                }`,
                                is3D: true,
                                tooltip: { isHtml: true, trigger: "selection" },
                              }}
                              chartEvents={[
                                {
                                  eventName: "select",
                                  callback: ({ chartWrapper }) => {
                                    const selectedItem = chartWrapper
                                      .getChart()
                                      .getSelection()[0];
                                    if (selectedItem) {
                                      // Handle the slice click as before
                                      const data = one[selectedItem.row + 1][0];
                                      const value =
                                        one[selectedItem.row + 1][1];
                                      // consol(
                                        data,
                                        "data",
                                        value,
                                        "no data"
                                      );
                                      const head = selectedOption.Refrence;
                                      setnewdata(data);
                                      sethead(head);
                                      setindexofpie(index)
                                    }
                                  },
                                },
                              ]}
                            />
                          </div>
                        ))
                      : ""}
                    {root.root == 1 && piedata.length > 0 ? (
                      <div ref={chartContainerRef}>
                        <Chart
                          loader={<h1>Select Distribution For pie Chart </h1>}
                          className="position-absolute top-50 start-50"
                          chartType="PieChart"
                          width="100%"
                          height="500px"
                          data={piedata}
                          options={options}
                          chartEvents={[
                            {
                              eventName: "select",
                              callback: ({ chartWrapper }) => {
                                const selectedItem = chartWrapper
                                  .getChart()
                                  .getSelection()[0];
                                if (selectedItem) {
                                  // Handle the slice click as before
                                  const data = piedata[selectedItem.row + 1][0];
                                  const value =
                                    piedata[selectedItem.row + 1][1];
                                  // consol(data, "data", value, "no data");
                                  const head = selectedOption.Refrence;
                                  setnewdata(data);
                                  sethead(head);
                                }
                              },
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )} */}
                    {piedata.length > 0 ? (
                      <div ref={chartContainerRef}>
                        <Chart
                          loader={<h1>Select Distribution For pie Chart </h1>}
                          // className="position-absolute top-50 start-50"
                          chartType="PieChart"
                          width="100%"
                          height="500px"
                          data={piedata}
                          options={options}
                          // chartEvents={[
                          //   {
                          //     eventName: "select",
                          //     callback: ({ chartWrapper }) => {
                          //       const selectedItem = chartWrapper
                          //         .getChart()
                          //         .getSelection()[0];
                          //       if (selectedItem) {
                          //         // Handle the slice click as before
                          //         const data = piedata[selectedItem.row + 1][0];
                          //         const value =
                          //           piedata[selectedItem.row + 1][1];
                          //         // consol(data, "data", value, "no data");
                          //         const head = selectedOption.Refrence;
                          //         setnewdata(data);
                          //         sethead(head);
                          //       }
                          //     },
                          //   },
                          // ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* ):(<div className="col-sm-2"></div>)} */}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      {piedata.length > 0 ? (
        <div className="mt-4 mx-3">
          <div className="cl-toggle-switch ">
            <span className="cl-toggle-span ">
              <label className="cl-switch">
                <input
                  type="checkbox"
                  checked={tableVisible}
                  onChange={handleToggle}
                />
                <span>View PieChart In Table</span>
              </label>
            </span>
          </div>{" "}
        </div>
      ) : (
        ""
      )}
      {piedata.length > 0
        ? tableVisible && (
            <div
              style={{ backgroundColor: "white", borderRadius: "15px" }}
              className="mx-3 my-3 shadow "
            >
              <h2>Data Table</h2>
              <div className="d-flex mx-2">
                <p
                  className="d-flex align-items-center text-center align-middle p-0 m-0"
                  style={{ color: "black", fontWeight: "600" }}
                >
                  {selectedCity} / From:{" "}
                  {moment(pieDateTime.From).format("MMMM D, YYYY h:mm A")} Till:{" "}
                  {moment(pieDateTime.Till).format("MMMM D, YYYY h:mm A")} /
                  Distribution BY {selectedOption.Refrence}
                </p>
                {/* <button
                 type="button" className="btn mx-2 btn-primary p-1"
                  onClick={() =>
                    handleDownloadExcel("RejectionDetailedAnalysispiechart")
                  }
                >
                  Download Excel
                </button> */}
                <div
                  className="uiverse"
                  onClick={() =>
                    handleDownloadExcel("RejectionDetailedAnalysispiechart")
                  }
                >
                  <span className="tooltip">Dowload Excel File</span>
                  <span>Download</span>
                </div>
              </div>
              {columns?.length > 0 ? (
                <Table
                  id="RejectionDetailedAnalysispiechart"
                  onSort={handleSort}
                  columns={column}
                  sortColumn={sortColumn}
                  data={columns}
                />
              ) : (
                ""
              )}
            </div>
          )
        : ""}
    </div>
  );
}

export default QualityAnalysis;
