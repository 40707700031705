import React, { useState, useEffect } from "react";
import Table from "../../../Common/table";
import api from '../../../config';
import axios from '../../../httpServices/httpService';
import swal from 'sweetalert';
import _ from 'lodash';
import dateTime from '../../../utill/dateTime';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faPaperPlane, faTrash } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../utill/LoadingComponent";
import { Button, Modal } from 'react-bootstrap';
import ComponentDataEntry from './component';
import joi from 'joi';
import validation from '../../../utill/validation';
import Paginate from '../../../Common/pagination';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import valid from '../../../utill/validation';
import date from '../../../utill/dateTime';
import MultiSelect from "../../../Common/multiselect";
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import './job.css';
import moment from 'moment';
//require("bootstrap/less/bootstrap.less");
import auth from "../../../httpServices/authService";

const JobEntryTabular = ({ getRightsForAccess }) => {
  const [selectedJobs, setSelectedJobs] = useState([])
  const [cacheData, setCacheData] = useState([])
  const [inputData, setInputData] = useState({ DateTime: new Date() })
  const [componentData, setComponentData] = useState({})
  const [errors, setErrors] = useState({})
  const [querySuccess, setQuerySuccess] = useState(false)
  const [shiftSetting, setShiftSetting] = useState([])
  const [index, setIndex] = useState(0);
  const [update, setUpdate] = useState(false);
  const [fromTime, setFromTime] = useState("");
  const [tillTime, setTillTime] = useState("");
  const [tillDate, setTillDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());



  const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })


  const [equipGroupList, setEquipGroupList] = useState([]);
  const [equipmentList, setEquipmentList] = useState([]);
  const [custList, setCustList] = useState([]);
  const [toolList, setToolList] = useState([]);
  const [componentList, setComponentList] = useState([])
  const [operationList, setOperationList] = useState([])
  const [toolIdList, setToolIdList] = useState([])
  const [operatorList, setOperatorList] = useState([])
  const [opertaorOption, setOperatorOption] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState([])
  const [action, setAction] = useState("")
  const [selectedObj, setSelectedObj] = useState({})
const [datemanager,setdatemanager]=useState(moment().format('YYYY-MM-DD'))
const [partpercycle,setpartpercycleReason] =useState([])
const [partsPerCycleReasons,setpartsPerCycleReasons]=useState([])
const[Reasons,setReasons] = useState([])
const [componetid,setcomponetid]=useState('')
const[status,setstatus]=useState('')
const[curentshift,setcurentshift] = useState([])






  useEffect(() => {
    getMasterData()
  }, [])

  const schema = {
    DateTime: joi.date().required().label('Datetime'),
    UserId: joi.string(),
    Shift: joi.string().required().label('ShiftName')
  }

  const getMasterData = () => {
    let eqpgrpList = axios.get(api.api1 + "equipmentgroup");
    let opList = axios.get(api.api1 + "component");
    let custList = axios.get(api.api + "customer");
    let allToolList = axios.get(api.api1 + "tool");
    let equipList = axios.get(api.api1 + "equipment")
    let operatorList = axios.get(api.api1 + "operator")
    Promise.all([eqpgrpList, opList, custList, allToolList, equipList, operatorList])
      .then((result) => {


        setCustList(result[2].data)
        setToolList(result[3].data)
        setComponentList(result[1].data);
        setEquipGroupList(result[0].data)
        setEquipmentList(result[4].data);
        setOperatorList(result[5].data);
        let options = []
        result[5].data.forEach((one) => {
          let obj = {}
          obj.value = one.OperatorID;
          obj.label = one.Name;
          options.push(obj);
        })
        setOperatorOption(options)
        // this.setState({
        //   operationIdList: operationList[0].Operation,
        //   toolIdList: toolIdList,///operationList[0].Operation[0].Tool == undefined ? [] : operationList[0].Operation[0].Tool,
        //   querySuccess: false,
        //   equipmentGroupList: result[0].data,

        //   componentList: result[1].data,
        //   customerList: result[2].data,
        //   allTools: result[3].data
        // });




      })



  }

  const  onDelete = (data) => {

    swal({
      title: "Are you sure?",
      text: "Removing a job will require you to manually manage the qaulity document for this job.Please do accordingly",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
       setQuerySuccess(true)
        axios
          .delete(api.api2 + "jobselected/" + data._id, { data: data })
          .then(result => {
            // consol(result.data)
        // consol(result,"total data")

            if (result.data.ok === 1) {
              let filterData = selectedJobs.filter(
                obj => obj._id !== data._id
              );
              setSelectedJobs(filterData)
              setQuerySuccess(false);
             
            }
          })
          .catch(err => {
            setQuerySuccess(false);
            swal(`${err.response.data.message}`);
          });
      } else {
        setQuerySuccess(false);
        // swal("Your imaginary file is safe!");
      }
    });
  }

  const SelectMy = () => {
    const Option = props => {
      return (
        <div>
          <components.Option {...props}>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
            />{" "}
            <label>{props.label}</label>
          </components.Option>
        </div>
      );
    };

    const MultiValue = props => (
      <components.MultiValue {...props}>
        <span>{props.data.label}</span>
      </components.MultiValue>
    );

    const animatedComponents = makeAnimated();
    return <Select

      options={opertaorOption}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{ Option, MultiValue, animatedComponents }}
      onChange={(values) => setSelectedOperator(values)}
      allowSelectAll={true}
      value={selectedOperator}
      menuPlacement="auto"

    // onChange={handleServiceChange}
    // value={getValues()}
    // isMulti={true}
    // options={options}
    // isClearable={false}
    />
  }

  const schema1 = {
    Component: joi.string().required(),
    ToolID: joi.string(),
    Equipment: joi.string().required(),
    EquipmentGroup: joi.string().required(),
    RunningStatus: joi.string(),//.required(),
    OperationID: joi.string().required(),
    CustomerName: joi.string(),
    TargetQty: joi.number(),
    PartsPerCycle: joi.number(),
    From: joi.date().required(),
    Till: joi.date().required(),
    // OperatorEnable: joi.bool().required(),
    // FromTime: joi.string(),//.required(),
    // FromDate: joi.string(),//.required(),
    Operators: joi.array().min(1).required().label('Operators'),
    Remarks: joi.string(),
    JobNumber: joi.string(),
    BatchNumber: joi.string(),
    PartsPerCycleReason: joi.string(),
    partsPerCycleReasons:joi.string(),
    //.when("OperatorEnable", { is: true, then: joi.required() }),
    // TillDate: joi
    //   .string(),
    //   //.when("RunningStatus", { is: "NotRunning", then: joi.required() }),
  };
  const getReasonList=()=>{
    axios.get(api.api + "partspercyclereason")
    .then((result)=>{
      setpartsPerCycleReasons(result.data);
      let filterReason=result.data.filter((one)=>one.Default);
      if(filterReason.length >0){

        componentData['PartsPerCycleReason']=[{PartsPerCycleReason:filterReason[0].Reason}];
        }
      let reasonlist = result.data.map((one)=> {
        return {PartsPerCycleReason:one.Reason}})
      setReasons(reasonlist)
      // consol(Reasons,"PartsPerCycleReason after update",componentData['PartsPerCycleReason'],reasonlist)
    })
    .catch((err)=>{
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
    })
  }

  const getDate = (datetime) => {
    if (datetime) {
      let date = new Date(datetime);
      return (
        date.getFullYear() +
        "-" +
        ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
        "-" +
        ((date.getDate() < 10 ? "0" : "") + date.getDate())
      );
    }
  };
  const getTime = (datetime) => {
    if (datetime) {
      let Time = new Date(datetime);
      return (
        (Time.getHours() < 10 ? "0" : "") +
        Time.getHours() +
        ":" +
        (Time.getMinutes() < 10 ? "0" : "") +
        Time.getMinutes()
      );
    }
  };

  const handleOnChangeComponent = (e) => {
    getReasonList()
   
    const data = { ...componentData };
    // consol(partsPerCycleReasons,"reasons of ",data,"edit data")
    const error = { ...errors };
    if (e.currentTarget.name !== 'FromTime' && e.currentTarget.name !== 'FromDate' && e.currentTarget.name !== 'TillTime' && e.currentTarget.name !== 'TillDate') {
      const errorMessage = valid.validateProperty(e.currentTarget, schema1);
      if (errorMessage) errors[e.currentTarget.name] = errorMessage;
      else delete errors[e.currentTarget.name];
    }
    if (
      e.currentTarget.name === "FromDate" ||
      e.currentTarget.name === "FromTime"
    ) {
      if (e.currentTarget.name === "FromDate") {
        // let date=new Date(e.currentTarget.value);
        // date.setHours(new Date().getHours(),new Date().getMinutes());
        // componentData["From"] = date;
        setFromDate(e.currentTarget.value);
      }
      if (e.currentTarget.name === "FromTime") {
        // let Time = e.currentTarget.value.split(":");
        // componentData["From"] = new Date(
        //   new Date(componentData.From).setHours(Time[0], Time[1])
        // );
        setFromTime(e.currentTarget.value)
      }
    } else if (
      e.currentTarget.name === "TillDate" ||
      e.currentTarget.name === "TillTime"
    ) {
      if (e.currentTarget.name === "TillDate") {
        // let date=new Date(e.currentTarget.value);
        // date.setHours(new Date().getHours(),new Date().getMinutes());
        // componentData["Till"] = date;
        setTillDate(e.currentTarget.value)
      }
      if (e.currentTarget.name === "TillTime") {
        // let Time = e.currentTarget.value.split(":");
        // componentData["Till"] = new Date(
        //   new Date(componentData.Till).setHours(Time[0], Time[1])
        // );
        setTillTime(e.currentTarget.value)
      }
    }
    else if (
      e.currentTarget.name === "Equipment"
    ) {
      data[e.currentTarget.name] = e.currentTarget.value;
      let componentList = equipmentList.filter(
        (one) => one.EquipmentID === e.currentTarget.value
      )
      data["EquipmentGroup"] = componentList[0].EquipmentGroup
      // let eqpGroup = this.state.equipmentGroupList.filter(one => one.EquipmentGroup == e.currentTarget.value);
      // axios.put(api.api1 + "equipment", { EquipmentGroup: eqpGroup[0]._id })
      //   .then((result) => {
      //     this.setState({ equipmentList: result.data })
      //   })
      //   .catch((err) => {
      //     swal(`${err}`)
      //   });
    }
    else if (
      e.currentTarget.name === "OperationID"
    ) {
      const ppcList=[]

      let componentList1 = componentList.filter(
        (one) => one.ComponentId === data.Component
      )
      data[e.currentTarget.name] = e.currentTarget.value;
      let operationList = componentList1[0].Operation.filter(one1 => one1.OperationID == e.currentTarget.value);
      data['PartsPerCycle'] = operationList[0].PartsPerCycle;
      if (operationList[0].ToolRequiredToProduce == 'true' || operationList[0].ToolRequiredToProduce) {
        let filterTool = toolList.filter(tool => tool.ToolId == operationList[0].Tool[0]);
        if (filterTool.length > 0) {
          data['PartsPerCycle'] = filterTool[0].PartsPerCycle;
          data['ToolID'] = filterTool[0].ToolId;
        }
      }
      for(let i=parseInt(data.PartsPerCycle);i>=1;i--){
        ppcList.push({PartsPerCycle:i.toString()})
      }
      // consol(ppcList,"setComponentData 1")
      setpartpercycleReason(ppcList)

      setToolIdList(operationList[0].Tool)
      // this.setState({ toolIdList: operationList[0].Tool, toolRequiredForSelectedOperation: operationList[0].ToolRequiredToProduce });
    }
    else {
      const ppcList=[]
      if (e.currentTarget.name === "Component") {

        let operationList = componentList.filter(
          (one) => one.ComponentId === e.currentTarget.value
        );
            // consol(operationList,"operationList")
        data['CustomerName'] = operationList[0].CustomerName;
        data['OperationID'] = operationList[0].Operation[0].OperationID;
        data['PartsPerCycle'] = operationList[0].Operation[0].PartsPerCycle;
        if (operationList[0].Operation[0].ToolRequiredToProduce == 'true' || operationList[0].Operation[0].ToolRequiredToProduce) {
          let filterTool = toolList.filter(tool => tool.ToolId == operationList[0].Operation[0].Tool[0]);
// consol(toolList,"toolList",filterTool)
          if (filterTool.length > 0) {
            data['PartsPerCycle'] = filterTool[0].PartsPerCycle;
            data['ToolID'] = filterTool[0].ToolId;
          }
        }  for(let i=parseInt(data.PartsPerCycle);i>=1;i--){
          ppcList.push({PartsPerCycle:i.toString()})
        }
        // consol(ppcList,"setComponentData")
        setpartpercycleReason(ppcList)
        setToolIdList(operationList[0].Operation[0].Tool);
        setOperationList(operationList[0].Operation)
        // this.setState({toolIdList: operationList[0].Operation[0].Tool, operationIdList: operationList[0].Operation, selectdeComp: e.currentTarget.value });
      }
      // consol(e.currentTarget.name,"current name")
      if(e.currentTarget.name=='PartsPerCycle'&&IsReasonEnable(e.currentTarget.value)){
        let filterReason=partsPerCycleReasons.filter((one)=>one.Default);
        // consol('filterReason',partsPerCycleReasons)
        if(filterReason.length >0){
          componentData['PartsPerCycleReason']=[{PartsPerCycleReason:filterReason[0].Reason}];
          // consol(componentData['PartsPerCycleReason'],"PartsPerCycleReason")
          // if(status == 'true'){
            // setReasons(componentData['PartsPerCycleReason'])
          // }
          // if(status == 'false'){
          //   setReasons(componentData['PartsPerCycleReason']=[{PartsPerCycleReason:''}])
          // }
         // consol(componentData['PartsPerCycleReason'],"last state")
        }
      }

      data[e.currentTarget.name] = e.currentTarget.value;
    }

    setComponentData(data)
    setErrors(errors || {})
    // consol(componentData);
  };
  
 
  

  let ppcList=[]
  const  getpartpercycleList=()=>{
  
 
  let operationList = componentList.filter(
    (one) => one.ComponentId === componetid
  );
  // consol(operationList,"f1")
    let filterTool = toolList.filter(tool => tool.ToolId == operationList[0].Operation[0].Tool[0]);
    // consol(filterTool,"f2444")
   

    // componentData['PartsPerCycle'] =filterTool[0].PartsPerCycle
    
  // Initialize ppcList as an empty array
let ppcList = [];

// Check if filterTool[0] and filterTool[0].PartsPerCycle exist and are valid
const partsPerCycle = parseInt(filterTool[0]?.PartsPerCycle, 10);

if (!isNaN(partsPerCycle) && partsPerCycle >= 1) {
  // Populate ppcList with valid PartsPerCycle values
  for (let i = partsPerCycle; i >= 1; i--) {
    ppcList.push({ PartsPerCycle: i.toString() });
  }
}

    const uniqueData = ppcList.filter((item, index, self) => {
      return (
        index === self.findIndex((obj) => obj.PartsPerCycle === item.PartsPerCycle)
      );
    });
  
    // consol(ppcList,"setComponentData3")
    // setpartpercycleReason(ppcList)
    return uniqueData
    
  }
  const IsReasonEnable=(PartsPerCycle)=>{
    let list;
   if(action == 'add'){
    list=[...partpercycle]
    
   }
  
 if(action == 'update'){

    
      // const ppcList=[]
 
      // let operationList = componentList.filter(
      //   (one) => one.ComponentId === componetid
      // );
      // // consol(operationList,"f1")
      //   let filterTool = toolList.filter(tool => tool.ToolId == operationList[0].Operation[0].Tool[0]);
      //   // consol(filterTool,"f2444")
       
    
      //   // componentData['PartsPerCycle'] =filterTool[0].PartsPerCycle
        
      //   for(let i=parseInt(filterTool[0].PartsPerCycle);i>=1;i--){
      //     ppcList.push({PartsPerCycle:i.toString()})
      //   }
  
    list=[...ppcList]
      
    }
    
    // consol(list,"maxlist")
    list=list.map(one=>parseInt(one.PartsPerCycle));
    let max=_.max(list);
    if(parseInt(PartsPerCycle)<max){
      // consol("true","listsss")
      setstatus('true')

      return true
    }else{
      setstatus('false')

      // consol("false","listsss")

      return false;
    }
  }
  // consol(Reasons,"Reasons List")
  const columns = [
    {
      path: 'BatchNumber', label: 'Batch Number', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <input type="text" placeholder='Enter BatchNumber...' value={componentData.JobNumber} name="BatchNumber" onChange={handleOnChange} />
          :
          <label>{obj.BatchNumber ? obj.BatchNumber : '-'}</label>

    },
    {
      path: 'EquipmentGroup', label: 'Equipment Group', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ?
          <MultiSelect name='EquipmentGroup' objLevel={1} masterName='EquipmentGroup' all={equipGroupList} handleOnChange={handleOnChange} selected={componentData['EquipmentGroup']} />
          : <label>{obj.EquipmentGroup}</label>}
      </React.Fragment>
    },
    //   { path: 'EquipmentType', label: 'EquipmentType' ,content:(obj)=><React.Fragment>
    //   <div className='form-group ' >
    //     <input type='text' name='EquipmentType' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.EquipmentType} className='form-control form-control-sm'/>
    //   </div>
    // </React.Fragment>  },
    {
      path: 'Equipment', label: 'Equipment', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <MultiSelect name='Equipment' objLevel={1} masterName="EquipmentID" all={equipmentList} handleOnChange={handleOnChange} selected={componentData['Equipment']} />
          : <label>{obj.Equipment}</label>}
      </React.Fragment>
    },
    
    {
      path: 'Component', label: 'Component', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <MultiSelect name='Component' objLevel={1} masterName="ComponentId" all={componentList} handleOnChange={handleOnChange} selected={componentData['Component']} />
          : <label>{obj.Component}</label>}
      </React.Fragment>
    },
    {
      path: 'OperationID', label: 'Operation ID', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <MultiSelect name='OperationID' objLevel={1} masterName="OperationID" all={[]} handleOnChange={handleOnChange} selected={componentData['OperationID']} />
          : <label>{obj.OperationID}</label>}
      </React.Fragment>
    },
    {
      path: 'ToolID', label: 'Tool ID', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <MultiSelect name='ToolID' objLevel={0} masterName="ToolID" all={toolList} handleOnChange={handleOnChange} selected={componentData['ToolID']} />
          : <label>{obj.OperationID}</label>}
      </React.Fragment>
    },
    {
      path: 'Operator', label: 'Operator', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <SelectMy />
          : <label>{obj.Operators && obj.Operators.map((one) => { return one.Operator }).join(' , ')}</label>
        }
      </React.Fragment>

    },
    {
      path: 'OperatorID', label: 'OperatorID', content: (obj) => <label>{obj.Operators && obj.Operators.map((one) => { return one.OperatorID }).join(' , ')}</label>
    },
    {
      path: 'PartsPerCycle', label: 'Parts Per Cycle / Num of cavity', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <input type="number" className='form-control form-control-sm' placeholder='Enter parts per cycle...' value={componentData.PartsPerCycle} name="PartsPerCycle" onChange={handleOnChange} />
          : <label>{obj.PartsPerCycle ? obj.PartsPerCycle : 'N/A'}</label>


    },
    {
      path: 'PartsPerCycleReason', label: 'Cavity Loss Reason', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <input type="text" className='form-control form-control-sm' placeholder='Enter parts per cycle...' value={componentData.PartsPerCycleReason} name="PartsPerCycleReason" onChange={handleOnChange} />
          : <label>{obj.PartsPerCycleReason ? obj.PartsPerCycleReason : 'N/A'}</label>


    },
    // {
    //   path: 'PartsPerCycleReason', label: 'PartsPerCycleReason', content: (obj) => <React.Fragment>
    //     {update && (obj._id == selectedObj._id) && IsReasonEnable(componentData.PartsPerCycle) ? <MultiSelect   name='PartsPerCycleReason' objLevel={1} masterName="PartsPerCycleReason" all={Reasons} handleOnChange={handleOnChangeComponent} selected={componentData['PartsPerCycleReason']} />
    //       : <label>{obj.PartsPerCycleReason}</label>}
    //   </React.Fragment>
    // },
    {
      path: 'CustomerName', label: 'Customer Name', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <MultiSelect name='CustomerName' objLevel={1} masterName="Name" all={custList} handleOnChange={handleOnChange} selected={componentData['CustomerName']} />
          : <label>{obj.CustomerName && (obj.CustomerName !== '--select cutomer--') ? obj.CustomerName : '-'}</label>


    },
    {
      path: 'TargetQty', label: 'Target Parts', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <input className='form-control form-control-sm'
            //disabled={this.props.actionStatus == 'update'}
            value={componentData.TargetQty}
            onChange={handleOnChange}
            placeholder='enter quantity'
            name='TargetQty'
            type='number'
            min='0'
          /> :
          <label>{obj.TargetQty ? obj.TargetQty : '0'}</label>


    },
    {
      path: 'Remarks', label: 'Remarks', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <input type="text" placeholder='Enter Remarks...' value={componentData.Remarks} name="Remarks" onChange={handleOnChange} />
          :
          <label>{obj.Remarks ? obj.Remarks : '-'}</label>

    },
    {
      path: 'JobNumber', label: 'Job Number', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <input type="text" placeholder='Enter JobNumber...' value={componentData.JobNumber} name="JobNumber" onChange={handleOnChange} />
          :
          <label>{obj.JobNumber ? obj.JobNumber : '-'}</label>

    },
    
    {
      path: 'From', label: 'From', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <div className='form-group ' >
          <input type='date' name='From' style={{ padding: 0 }} value={dateTime.getStringOfDateTime(obj.From)} className='form-control form-control-sm' />
          <input type='time' name='From' style={{ padding: 0 }} value={dateTime.getStringOfDateTime(obj.From)} className='form-control form-control-sm' />
        </div> : <label>{dateTime.getStringOfDateTime(obj.From)}</label>}
      </React.Fragment>
    },
    {
      path: 'Till', label: 'Till', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <div className='form-group ' >
          <input type='date' name='Till' style={{ padding: 0 }} value={dateTime.getStringOfDateTime(obj.Till)} className='form-control form-control-sm' />
          <input type='time' name='Till' style={{ padding: 0 }} value={dateTime.getStringOfDateTime(obj.Till)} className='form-control form-control-sm' />
        </div> : <label>{obj.Till ? dateTime.getStringOfDateTime(obj.Till) : '-'}</label>}
      </React.Fragment>
    },
    {
      path: 'Update', content: (obj) =>

        <> {!update ? <button className='btn btn-sm btn-primary ' onClick={() => {
          // partpercycle
          // let cyclelist = partpercycle.filter(
            
          //   (one) => one.ComponentId === obj.Component
          // );
          let componentList1 = componentList.filter(
            
            (one) => one.ComponentId === obj.Component
          );
          
          let operationList = componentList1[0].Operation.filter(one1 => one1.OperationID == obj.OperationID);
         
          let selectOperator = []
          obj.Operators.forEach((one) => {
            let obj = { value: one.OperatorID, label: one.Operator };
            selectOperator.push(obj);
          })
          // consol(componentList1,"compdata",operationList,obj)
          setFromDate(getDate(obj.From))
          setFromTime(getTime(obj.From))
          setTillDate(getDate(obj.Till))
          setTillTime(getTime(obj.Till))
          setSelectedOperator(selectOperator)
          setToolIdList(operationList[0].Tool);
          setOperationList(componentList1[0].Operation)
          setcomponetid(componentList1[0].ComponentId)
          setSelectedObj(obj);
          setUpdate(true);
          setAction('update');
          setComponentData(obj)
          getReasonList()

        }} >
          <FontAwesomeIcon icon={faEdit} color={'yellow'} /></button> : <></>}</>
    },
    {
      path: 'Delete', content: (obj) => <>{!update ? <button  onClick={()=>onDelete(obj)} disabled={!getRightsForAccess('DynamicDataRights', 'JobSelection', 'update')} className='btn btn-sm btn-primary '><FontAwesomeIcon icon={faTrash} color='red' /></button> : <></>}</>

    },
    //     { path: 'Reason', label: 'Reason' ,content: () => <React.Fragment>


    // <div className='form-group'>
    //   <select className='form-control form-control-sm custom-select' name='Reason' onChange={this.handleOnChange}>
    //   <option>--select reason--</option>
    //   {this.state.downTimeList.map(one=>{
    //    return  one.Reason.map(reason=>{
    //       return  <option>{reason}</option>
    //     })
    //   })}
    //   </select>
    // </div>
    //     </React.Fragment> },



  ]

  const columns1 = [
    // {
    //   path: 'EquipmentGroup', label: 'EquipmentGroup', content: (obj) => <React.Fragment>
    //     {update ?
    //       <MultiSelect name='EquipmentGroup' objLevel={1} masterName='EquipmentGroup' all={equipGroupList} handleOnChange={handleOnChange} selected={componentData['EquipmentGroup']} />
    //       : <label>{obj.EquipmentGroup}</label>}
    //   </React.Fragment>
    // },
    //   { path: 'EquipmentType', label: 'EquipmentType' ,content:(obj)=><React.Fragment>
    //   <div className='form-group ' >
    //     <input type='text' name='EquipmentType' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.EquipmentType} className='form-control form-control-sm'/>
    //   </div>
    // </React.Fragment>  },
    {
      path: 'BatchNumber', label: 'Batch Number', content: (obj) =>
      update && (obj._id == selectedObj._id) ?
            <input type="text" className='form-control form-control-sm p-0' placeholder='Enter BatchNumber...' value={componentData.BatchNumber} name="BatchNumber" onChange={handleOnChangeComponent} />
            :
            <label>{obj.BatchNumber ? obj.BatchNumber : '-'}</label>
  
      },
      
      {
      path: 'Equipment', label: 'Equipment', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <MultiSelect name='Equipment' objLevel={1} masterName="EquipmentID" all={equipmentList} handleOnChange={handleOnChangeComponent} selected={componentData['Equipment']} />
          : <label>{obj.Equipment}</label>}
      </React.Fragment>
    },
    {
      path: 'Component', label: 'Component', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <MultiSelect name='Component' objLevel={1} masterName="ComponentId" all={componentList} handleOnChange={handleOnChangeComponent} selected={componentData['Component']} />
          : <label>{obj.Component}</label>}
      </React.Fragment>
    },
    {
      path: 'OperationID', label: 'Operation ID', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <MultiSelect name='OperationID' objLevel={1} masterName="OperationID" all={operationList} handleOnChange={handleOnChangeComponent} selected={componentData['OperationID']} />
          : <label>{obj.OperationID}</label>}
      </React.Fragment>
    },
    {
      path: 'ToolID', label: 'Tool ID', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <MultiSelect name='ToolID' objLevel={0} masterName="ToolID" all={toolIdList} handleOnChange={handleOnChangeComponent} selected={componentData['ToolID']} />
          : <label>{obj.ToolID}</label>}
      </React.Fragment>
    },
    {
      path: 'Operator', label: 'Operator', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <SelectMy />
          : <label>{obj.Operators && obj.Operators.map((one) => { return one.Operator }).join(' , ')}</label>
        }
      </React.Fragment>

    },

    // {
    //   path: 'PartsPerCycle', label: 'PartsPerCycle', content: (obj) =>
    //     update && (obj._id == selectedObj._id) ?
    //       <input type="number" className='form-control form-control-sm' placeholder='Enter parts per cycle...' value={componentData.PartsPerCycle} name="PartsPerCycle" onChange={handleOnChangeComponent} />
    //       : <label>{obj.PartsPerCycle ? obj.PartsPerCycle : 'N/A'}</label>


    // },
    {
      path: 'PartsPerCycle', label: 'Parts Per Cycle / Num of cavity', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <MultiSelect  name='PartsPerCycle' objLevel={1} masterName="PartsPerCycle" all={action == 'update' ? getpartpercycleList():partpercycle} handleOnChange={handleOnChangeComponent} selected={componentData['PartsPerCycle']} />
          : <label>{obj.PartsPerCycle}</label>}
      </React.Fragment>
    },
    {
      path: 'PartsPerCycleReason', label: 'Cavity Loss Reason', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) && IsReasonEnable(componentData.PartsPerCycle) ? <MultiSelect   name='PartsPerCycleReason' objLevel={1} masterName="PartsPerCycleReason" all={Reasons} handleOnChange={handleOnChangeComponent} selected={componentData['PartsPerCycleReason']} />
          : <label>{}</label>}
      </React.Fragment>
    },
    {
      path: 'CustomerName', label: 'Customer Name', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <MultiSelect name='CustomerName' objLevel={1} masterName="Name" all={custList} handleOnChange={handleOnChangeComponent} selected={componentData['CustomerName']} />
          : <label>{obj.CustomerName && (obj.CustomerName !== '--select cutomer--') ? obj.CustomerName : '-'}</label>


    },
    {
      path: 'TargetQty', label: 'Target Parts', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <input className='form-control form-control-sm'
            //disabled={this.props.actionStatus == 'update'}
            value={componentData.TargetQty}
            onChange={handleOnChangeComponent}
            placeholder='enter quantity'
            name='TargetQty'
            type='number'
            min='0'
          /> :
          <label>{obj.TargetQty ? obj.TargetQty : '0'}</label>


    },
    {
      path: 'Remarks', label: 'Remarks', content: (obj) =>
        update && (obj._id == selectedObj._id) ?
          <input type="text" className='form-control form-control-sm' placeholder='Enter Remarks...' value={componentData.Remarks} name="Remarks" onChange={handleOnChangeComponent} />
          :
          <label>{obj.Remarks ? obj.Remarks : '-'}</label>

    },
    {
      path: 'From', label: 'From', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <div className='form-group ' >
          {/* <input type='date' name='FromDate' style={{ padding: 0 }} onChange={handleOnChangeComponent} defaultValue={fromDate} className='form-control form-control-sm' /> */}
          <input type='time' name='FromTime' style={{ padding: 0 }} onChange={handleOnChangeComponent} defaultValue={fromTime} className='form-control form-control-sm' />
        </div> : <label>{dateTime.getStringOfDateTime(obj.From)}</label>}
      </React.Fragment>
    },
    {
      path: 'Till', label: 'Till', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <div className='form-group ' >
          {/* <input type='date' name='TillDate' style={{ padding: 0 }} onChange={handleOnChangeComponent} defaultValue={tillDate} className='form-control form-control-sm' /> */}
          <input type='time' name='TillTime' style={{ padding: 0 }} onChange={handleOnChangeComponent} defaultValue={tillTime} className='form-control form-control-sm' />
        </div> : <label>{obj.Till ? dateTime.getStringOfDateTime(obj.Till) : '-'}</label>}
      </React.Fragment>
    },
    {
      path: 'Update', content: (obj) => <React.Fragment>{update && (obj._id == selectedObj._id) ?
        <> <button className='btn btn-sm btn-primary ' onClick={() =>
          handleSubmit(componentData)} >Submit</button>
        </> :
        <></>
        // <> {<button className='btn btn-sm btn-primary ' onClick={() => { 

        //   let componentList1 = componentList.filter(
        //     (one) => one.ComponentId === obj.Component
        //   );
        //   let operationList = componentList1[0].Operation.filter(one1 => one1.OperationID == obj.OperationID);

        //   let selectOperator=[]
        //   obj.Operators.forEach((one)=>{
        //     let obj={value:one.OperatorID,label:one.Operator};
        //     selectOperator.push(obj);
        //   })
        //   setFromDate(getDate(obj.From))
        //   setFromTime(getTime(obj.From))
        //   setTillDate(getDate(obj.Till))
        //   setTillTime(getTime(obj.Till))
        //   setSelectedOperator(selectOperator)
        //   setToolIdList(operationList[0].Tool);
        //   setOperationList(componentList1[0].Operation)
        //   setSelectedObj(obj);
        //   setUpdate(true);
        //   setAction('update');
        //   setComponentData(obj) 


        //   }} >
        //     <FontAwesomeIcon icon={faEdit} color={'yellow'} /></button>}</>



      }</React.Fragment>
    },
    {
      path: 'Delete', content: (obj) => <React.Fragment>
        {update && (obj._id == selectedObj._id) ? <button onClick={() => {
          if (action == 'add') {
            let jobs = [...selectedJobs];
            jobs.splice(0, 1);
            setSelectedJobs(jobs);
            setUpdate(false);
            setAction("");
          }
          if (action == 'update') {
            setUpdate(false);
        
            setAction("");
          }

        }} disabled={!getRightsForAccess('DynamicDataRights', 'JobSelection', 'update')} className='btn btn-sm btn-secondary '>Cancel</button> :

          <button onClick={()=>onDelete(obj)} disabled={!getRightsForAccess('DynamicDataRights', 'JobSelection', 'update')} className='btn btn-sm btn-primary '><FontAwesomeIcon icon={faTrash} color='red' /></button>}



      </React.Fragment>
    },

    //     { path: 'Reason', label: 'Reason' ,content: () => <React.Fragment>


    // <div className='form-group'>
    //   <select className='form-control form-control-sm custom-select' name='Reason' onChange={this.handleOnChange}>
    //   <option>--select reason--</option>
    //   {this.state.downTimeList.map(one=>{
    //    return  one.Reason.map(reason=>{
    //       return  <option>{reason}</option>
    //     })
    //   })}
    //   </select>
    // </div>
    //     </React.Fragment> },



  ]
useEffect(() => {
  const fetchdata = async(data) => {
  let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime });
      // let jobList = await GetJobData({ From: new Date(inputData.DateTime), Till: new Date(inputData.DateTime) })
      setShiftSetting(res.data);
  }
  fetchdata()
}, [datemanager])

  const handleOnChange = async (e) => {


    
    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
      inputData['Shift']="";
      let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime });
      // let jobList = await GetJobData({ From: new Date(inputData.DateTime), Till: new Date(inputData.DateTime) })
      setShiftSetting(res.data);
     
      // setSelectedJobs(jobList);
      // setCacheData(jobList)
      // setFromDate(new Date(e.currentTarget.value))
      // setTillDate(new Date(e.currentTarget.value))

    }
    else {
      if(e.currentTarget.value=='--select shift--'){
        return
      }else{
      let Value=e.currentTarget.value
      let filterShift = shiftSetting.filter(one => one.Shift == e.currentTarget.value)[0];
      // let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime });
      // let jobList = await GetJobData({ From: new Date(inputData.DateTime), Till: new Date(inputData.DateTime) })
      let jobList = await GetJobData({ From: new Date(filterShift.ShiftStart), Till: new Date(filterShift.ShiftEnd) })
      setcurentshift({ From: new Date(filterShift.ShiftStart), Till: new Date(filterShift.ShiftEnd) },"data to be processed")
      setSelectedJobs(jobList);
      setCacheData(jobList)
      jobList = jobList.filter(one => {
        if ((new Date(one.From) >= new Date(filterShift.ShiftStart)) && (new Date(filterShift.ShiftEnd) >= new Date(one.Till))) {
          return one;
        }
      })
      let from = new Date(filterShift.ShiftStart);
      let till = new Date(filterShift.ShiftEnd)
      from.setHours(0, 0, 0, 0);
      till.setHours(0, 0, 0, 0)
      setFromDate(from)
      setTillDate(till)

      inputData['Shift'] = Value;
    }
  }

    setInputData(inputData);
    setErrors({})  
  }

  const GetJobData = (inputData) => {
    return new Promise((resolve, reject) => {
      // const errors = validation.validate(inputData, this.schema);
      // // consol(errors)
      // this.setState({ errors1: errors || {} });
      // if (errors) return;
      inputData.shift = true;

      setQuerySuccess(true)
      axios.put(api.api2 + 'jobselected',inputData)
        .then((result) => {
        // consol(result,"total input",inputData)
          
          setQuerySuccess(false);
          resolve(result.data);
        })
        .catch((err) => {
          setQuerySuccess(false)

          reject(`${err}`)
        })


    })

  }

  const handleClickAddJobs = () => {
    const jobs = [...selectedJobs];
    jobs.unshift({})
    setUpdate(true);
    setSelectedJobs(jobs);
    setSelectedObj({});
    setComponentData({});
    setSelectedOperator([]);
    setFromTime("");
    setTillTime("");
    setAction('add');
  }

  const validateCavity = (data) => {
    let err = false
    data = { ...data }
    let componentList1 = componentList.filter(
      (one) => one.ComponentId === data.Component
    )
    if (componentList1.length > 0) {
      componentList1 = componentList1[0].Operation.filter(one => one.OperationID == data.OperationID);
      if (componentList1.length > 0) {
        if (componentList1[0].ToolRequiredToProduce == 'true' || componentList1[0].ToolRequiredToProduce) {
          let filterTool = toolList.filter(tool => tool.ToolId == data.ToolID);
          if (filterTool.length > 0) {
            if (parseFloat(data.PartsPerCycle) > parseFloat(filterTool[0].PartsPerCycle)) {
              err = true
            }
          }

        } else {
          if (parseFloat(data.PartsPerCycle) > parseFloat(componentList1[0].PartsPerCycle)) {
            err = true
          }
        }
      }
    }
    return err;
  }

  const setDateTimeToData = (data) => {
    let from = new Date(fromDate)
    let FromTime = fromTime.split(':')
    from.setHours(FromTime[0], FromTime[1])
    data['From'] = from
    let till = new Date(tillDate)
    let TillTime = tillTime.split(':')
    till.setHours(TillTime[0], TillTime[1])
    data['Till'] = till
    return data
  }

  const handleSubmit = (data1) => {
    let data = { ...data1 }
    if (validateCavity(data)) {
      swal("parts per cycle of job should be less than or equal to master data", { dangerMode: true });
      return
    }


    if (fromDate == "" || fromTime == "") { swal('From Date Time Not Allowed To Be Emphty'); return }
    if (tillDate == "" || tillTime == "") { swal('Till Date Time Not Allowed To Be Emphty'); return }


    data = setDateTimeToData(data)
    data.From.setMilliseconds(1)
    if (dateTime.getDifferenceBetweenTimes(data.From, data.Till).minutes < 1) {
      swal('From Time and Till Time Diff Should Be Greater Than One Minutes', { dangerMode: true })
      return
    }
    if (new Date(data.From) > new Date(data.Till)) {
      swal('from time is must be less than till time', { dangerMode: true })
      return
    }



    if (action === "update") {
     
      // if(data.RunningStatus==='NotRunning')
      // {
      //   data.RunningStatus=false
      //   //delete data.RunningStatus
      // }
      // if(data.RunningStatus==='Running')
      // {
      //   data.RunningStatus=true
      //   //delete data.RunningStatus
      //   delete data.Till
      // }
      setQuerySuccess(false);
      if(IsReasonEnable(data.PartsPerCycle)==false || IsReasonEnable(data.PartsPerCycle)=='false'){
        data.PartsPerCycleReason='';
      }

      axios
        .patch(api.api2 + "jobselected/" + selectedObj._id, {
          old: selectedObj,
          new: data,
        })
        .then(async(result) => {
         let from= selectedObj.From;
         let till= selectedObj.Till;
          // consol(selectedObj,"selected data value",from,till,inputData.DateTime)
          const { n, nModified, ok } = result.data;
          if (ok == 1 && n == 1 && nModified == 1) {
            let jobList = await GetJobData({ From: curentshift.From, Till: curentshift.Till })
            setSelectedJobs(jobList);
            setCacheData(jobList)
            setQuerySuccess(false)
            setUpdate(false);
            setSelectedObj({})
            setAction("")
            // this.props.handleCloseAddJob()
            // this.props.handleOnSubmit(this.props.inputData);
            swal("Update Data Successfully");
          } else {
            // this.props.handleCloseAddJob()
            // this.props.handleOnSubmit(this.props.inputData);
            setQuerySuccess(false)
            swal(`${result.data}`);
          }
        })
        .catch((err) => {
          swal(`${err.response.data.message}`);
          setQuerySuccess(false)
        });
    } else {
      let opertor = []
      selectedOperator.filter((one) => {
        let filterOp = operatorList.filter((op) => op.OperatorID == one.value)[0];
        let obj = { Name: filterOp.Name, OperatorID: filterOp.OperatorID, id: filterOp._id };
        opertor.push(obj)
      })
      data.Operators = opertor;
      delete data.Accept
      const errors = valid.validate(data, schema1);
      // consol(errors);
      setErrors(errors || {});
      if (errors) return;

      if (toolIdList.length > 0) {
        if (data.ToolID == '' || data.ToolID == null || data.ToolID == 'null' || data.ToolID == undefined) {
          swal('Tool is required', { dangerMode: true });
          return;
        }
      }


      // if(data.RunningStatus==='NotRunning')
      // {
      //   data.RunningStatus=false;
      //   // delete data.RunningStatus
      // }
      // if(data.RunningStatus==='Running')
      // {
      //   data.RunningStatus=true;
      //   //delete data.RunningStatus
      //   delete data.Till
      // }
      //data.Operator = this.state.operatorList.filter(one => one.OperatorID == data.OperatorID)[0].Name;
      setQuerySuccess(true)
      data.Accept = false;
      axios
        .post(api.api2 + "jobselected", data)

        .then(async (result) => {
          
          if (result.status == 200) {
            // consol(data,"adding jobs")
            let jobList = await GetJobData({ From:curentshift.From, Till: curentshift.Till })
            setSelectedJobs(jobList);
            setCacheData(jobList)
            setQuerySuccess(false)
            setUpdate(false);
            setSelectedObj({})
            setAction("")
            // swal(`Data Submitted Successfully`);
            swal(`Data Submitted Successfully job Number : ${result.data.jobNumber}`);

            // consol(result,"total data",inputData.DateTime)
            // this.props.handleCloseAddJob()

            // this.props.handleOnSubmit(this.props.inputData);

          } else {
            setQuerySuccess(false)
            swal(`${result.response.data.messgae}`);
          }
        })
        .catch((err) => {
          if (err.response.status == 409) {
            swal({
              title: "The operator is already assigned for following machines. Do you want to deploy him again?",
              text: `${err.response.data.message}`,
              icon: "warning",
              buttons: true,
              dangerMode: true
            }).then(willDelete => {
              if (willDelete) {
                setQuerySuccess(true)
                data.Accept = true;
                axios
                  .post(api.api2 + "jobselected", data)
                  .then(result => {
                    
                    if (result.status == 200) {
                      setQuerySuccess(false)
                      // swal(`Data Submitted Successfully`);
                      swal(`Data Submitted Successfully job number : ${result.data.jobNumber}`);

                      // consol(result,"total data")
                      // myThis.props.handleCloseAddJob()
                      // myThis.props.handleOnSubmit(this.props.inputData);
                    }
                    else {
                      setQuerySuccess(false)
                      swal(`${result.data.message}`)
                    }
                  })
                  .catch(err => {
                    setQuerySuccess(false)
                      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                  });
              } else {
                setQuerySuccess(false)
                // swal("Your imaginary file is safe!");
              }
            });
          } else {
            setQuerySuccess(false)
            swal(`${err.response.data.message}`);
          }
        });
    }
  };

  const getTable = () => {
    const  cloneCol=update?[...columns1]:[...columns];
    const data=[...selectedJobs]
    return <Table columns={cloneCol} sortColumn={sortColumn} onSort={handleSort} data={data} />
  }
  const handleSort = (sortColumn) => {
    if (selectedJobs.length > 0) {
      const sorted = _.orderBy(
        selectedJobs,
        [sortColumn.path],
        [sortColumn.order]
      );
      setSelectedJobs(sorted)
      setSortColumn(sortColumn)
    }
  }

  return (
    <React.Fragment>
      <div className='text-center'>
        <p>Tabular Job Entry</p>
      </div>
      <ToastContainer />

      {querySuccess ? <Loading /> : ''}
      <div className="row">
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Date</label>
            <input className={'form-control form-control-sm'} type='date' defaultValue={datemanager} onChange={handleOnChange} name='DateTime' />
            {errors.DateTime && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.DateTime}</p>}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm'  onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              {shiftSetting.map(one => {
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div>
        <div className="col-sm-2">
          <button className='btn btn-primary btn-sm mt-4' disabled={!getRightsForAccess('DynamicDataRights', 'JobSelection', 'read')} onClick={() => handleClickAddJobs()}>Add Job</button>
        </div>




        {/* <div className="col-sm-3 ">
            <button className='btn btn-primary btn-sm mt-4 float-right' disabled={!getRightsForAccess('DynamicDataRights', 'JobSelection', 'write')} onClick={this.handleDownloadFormat} >Download Template </button>
          </div>
          <div className="col-sm-2 ">
            <button className='btn btn-primary btn-sm mt-4 float-right' disabled={!getRightsForAccess('DynamicDataRights', 'JobSelection', 'write')} onClick={this.handleUploadJobDoc} >Import Excel </button>
          </div>

          <div className="col-sm-2 float-right">
            <button className='btn btn-primary btn-sm mt-4 float-right' disabled={!getRightsForAccess('DynamicDataRights', 'JobSelection', 'write')} onClick={this.handleAddJobClick} >Add Job</button>
          </div> */}






      </div>
      <div>
        {selectedJobs.length < 0 ? "Data Not Available" : getTable()}
      </div>
    </React.Fragment>
  );
}

export default JobEntryTabular;
