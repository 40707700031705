import React, { useEffect, useRef,useState  } from 'react';
import * as echarts from 'echarts';

const MyChart = ({ Barcolor, data,height,title,valuetype,sortOptionsVisible}) => {
  // console.log(data?.Tail, 'data1', data?.Head, 'Tail');
  const [sortOrder, setSortOrder] = useState(sortOptionsVisible=='none'?"":'Alphabetically'); 
  const chartRef = useRef(null);

  useEffect(() => {
    if (!data?.Tail || !chartRef.current) {
      // Do not initialize the chart if data is not available or chartRef is not set
      return;
    }
    const combinedData = data.Head.map((head, index) => ({
      head,
      tail: data.Tail[index],
    }));
    let sortedHead;
    let sortedTail;
    if (sortOrder === 'Alphabetically') {
      // Clone the array before sorting to avoid mutating the original array
      const sortedData = [...combinedData];
      
      // Sort the cloned array alphabetically by the 'head' property
      sortedData.sort((a, b) => a.head.localeCompare(b.head));
      
      // Extract the 'head' and 'tail' properties into separate arrays
      sortedHead = sortedData.map(item => item.head);
      sortedTail = sortedData.map(item => item.tail);
    }
    else if (sortOrder =="") {
      sortedHead = combinedData.map(item => item.head);
      sortedTail = combinedData.map(item => item.tail);
    }
    else{
      // Sort the combined data based on Tail values
      combinedData.sort((a, b) =>
      sortOrder === 'Ascending' ? a.tail - b.tail : b.tail - a.tail
      );
      // Extract the sorted Head and Tail arrays
      sortedHead = combinedData.map(item => item.head);
      sortedTail = combinedData.map(item => item.tail);
    }
 
    // Get the chart container element using the ref
    const chartDom = chartRef.current;

    // Initialize ECharts instance
    const myChart = echarts.init(chartDom);

    // Define the chart options
    const option = {
      title: {
        text: `${title}`, // Set your desired chart title
        textStyle: {
          fontWeight: 'bold', // You can customize the title text style
          fontSize: 16, // You can customize the title font size
        },
        left: 'center', // You can adjust the title position
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      // ...
      xAxis: [
        {
          type: 'category',
          data: sortedHead,
          axisTick: {
            alignWithLabel: true,
          },
          axisLabel: {
            rotate: 90,
            formatter: function (value) {
              // Customize the display of each label (e.g., truncate to 10 characters)
              return value.length > 20 ? value.slice(0, 10) + '...' : value;
              // return value;
            },
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: `${valuetype}`,
          type: 'bar',
          barWidth: '60%',
          itemStyle: {
            color: `${Barcolor}`,
          },
          data: sortedTail, // Use the sorted data here
        },
      ],
      label: {
        show: true,
        position: 'top', // Adjust the position as needed
        formatter: sortedHead, // Display the percentage value
      },
    };

    myChart.setOption(option);

    // Cleanup on component unmount
    return () => {
      myChart.dispose();
    };
  },  [Barcolor, data?.Tail, data?.Head,sortOrder]);
  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
  };
  const handleDownload = () => {
    if (chartRef.current) {
      // Get the chart instance
      const myChart = echarts.getInstanceByDom(chartRef.current);

      // Convert the chart to a base64-encoded data URL
      const imageDataURL = myChart.getDataURL({
        type: 'png', // You can change the type to 'jpeg' or 'svg' as needed
        pixelRatio: 2, // You can adjust the pixelRatio as needed
        backgroundColor: '#fff', // Set the background color of the image
      });

      // Create a download link
      const downloadLink = document.createElement('a');
      downloadLink.href = imageDataURL;
      downloadLink.download = 'chart.png'; // You can change the filename as needed
      document.body.appendChild(downloadLink);

      // Trigger the download
      downloadLink.click();

      // Remove the download link from the DOM
      document.body.removeChild(downloadLink);
    }
  };

  return (
    <>
      {data?.Tail && data?.Tail?.length > 0 ? <div>
              {/* <div className="uiverse mx-2" onClick={()=>{handleDownload()}}
>
 <span className="tooltip">Dowload Chart</span>
 <span>
 Download
 </span>
</div> */}
                  
      {sortOptionsVisible=='none'?<></>:  <select
        type="text"
        style={{width: '150px',}}
        className="form-control  text-center"
        placeholder="Select Sorting Order"
        name="SortOrder"
        onChange={handleSortOrderChange}
        value={sortOrder}
      >
   
       <option value="Ascending">Ascending</option>
        <option value="Descending">Descending</option>
        <option value="Alphabetically">Alphabetically</option>

      </select>}
          <div ref={chartRef} style={{ width: '100%', height: height }} />
      
        </div>
     :<> <div style={{ width: '100%', height: '250px' }}  className='animated-gradient d-flex align-items-center justify-content-center'><p className='text-center bolder'>No Data ...</p></div></> }
    </>
  );
};

export default MyChart;