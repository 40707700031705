import React, { useState } from 'react';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import validation from '../utill/validation';
import _, { result, sortedIndex } from 'lodash';
import joi from 'joi'
import Loading from '../utill/LoadingComponent';
import Chart from 'react-google-charts';
import date from '../utill/dateTime';
import TableForDowntimeAnalysis from '../utill/tableForMachineDowntimeAnalysis';
import { generateExcelFile } from '../utill/download';
import EquipmentSelection from '../Common/equipmentSelection';
import MultiSelectCheckBox from '../Common/MultiSelectCheckBox';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const DowntimeAnalysisMachine = ({ user, equips, equipGroups,fromDateLimit,storeDataInLocalStorage }) => {
    const [downtimeAnalysisData, setDowntimeAnalysisData] = useState([])
    const [view, setView] = useState([])
    const [inputData, setInputData] = useState({ ShowRuntime: false });
    const [rawData, setRawData] = useState([])
    const [errors, setErrors] = useState({})
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [equip, SetEquips] = useState([])
    const [querySuccess, setQuerySuccess] = useState(false)
    const [seriesColor, setSerieseColor] = useState({})
    const [shiftSetting, setShiftSetting] = useState([]);
    const [tableData, setDataForTable] = useState([])
    const [shiftOption, setShiftOption] = useState([
        { label: 'Shift1', value: 'Shift1', checked: false },
        { label: 'Shift2', value: 'Shift2', checked: false },
        { label: 'Shift3', value: 'Shift3', checked: false },
        { label: 'Shift4', value: 'Shift4', checked: false }])
        const [downtime, setdowntime] = useState([
            { label:'Planned',value:'Planned',checked:false},
    { label:'Unplanned',value:'Unplanned',checked:false},])
    const [index, setIndex] = useState(0)
    const setSelection = (key, option, equips) => {
        if (key == 'EquipmentGroup') {
            SetEquips(equips ? equips : []);
            setSelectedEquipGroupOption(option)
            setSelectedEquipOption([])
        }
        if (key == 'Equipment') {

            setSelectedEquipOption(option)
        }
    }

    const getEquipsForReport = () => {
        let equipArr = []
        equips.forEach((one) => {
            selectedEquipOption.forEach((option) => {
                if (one._id == option.value) {
                    equipArr.push(one);
                }
            })
        })
        return equipArr;
    }

    const schema = {
        From: joi.date().required().label('From Datetime'),
        Till: joi.date().required().label('Till Datetime'),
        UserId: joi.string(),
        Shift: joi.array().min(1).required().label('please select at least one shift'),
        Equips: joi.array(),
        ShowRuntime: joi.boolean(),
        ShowCost: joi.boolean(),
        Downtime: joi.array().min(1).required(),
        //Shift: joi.string().required().label('Shift')
    }


    const handleOnChange = async (e) => {

        if (e.currentTarget.type === 'date') {
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
            let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime })
            setShiftSetting(res.data);
            setInputData(inputData);
        }
        else if (e.currentTarget.type === 'time') {
            let Time = e.currentTarget.value.split(':');
            inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
            setInputData(inputData);
        }
        else if (e.currentTarget.name === 'ShowRuntime' && inputData.ShowCost !== true) {

            inputData[e.currentTarget.name] = e.currentTarget.checked;
           
            if(e.currentTarget.checked){
                showWithRuntimeData(rawData)
            }else{
                let res = organiseDowntimeData(rawData);
                setDowntimeAnalysisData(res.arr);
                setSerieseColor(res.color);
            }
            setInputData(inputData);
        }
        else if (e.currentTarget.name === 'ShowCost') {

            inputData[e.currentTarget.name] = e.currentTarget.checked;
            inputData['ShowRuntime'] = false;
            if(e.currentTarget.checked){
                showWithRuntimeData1(rawData)
            }else{
                let res = organiseDowntimeData(rawData);
                setDowntimeAnalysisData(res.arr);
                setSerieseColor(res.color);
            }
            setInputData(inputData);
        }
        else if (e.currentTarget.name == 'Shift') {
            const options = [...shiftOption];
            if (e.currentTarget.value && (e.currentTarget.value == 'All')) {
                options.forEach(one => one.checked = e.currentTarget.checked);
            } else {
                options.forEach(one => {
                    if (one.value == e.currentTarget.value) {
                        one.checked = e.currentTarget.checked
                    }
                });
            }
            inputData[e.currentTarget.name] = options.filter(one => one.checked);
            console.log(inputData,"vsdivbnsivbiv")

            setShiftOption(options);
        }
        else if (e.currentTarget.name == 'Downtime') {
            const options = [...downtime];
            if (e.currentTarget.value && (e.currentTarget.value == 'All')) {
                options.forEach(one => one.checked = e.currentTarget.checked);
            } else {
                options.forEach(one => {
                    if (one.value == e.currentTarget.value) {
                        one.checked = e.currentTarget.checked
                    }
                });
            }
            
            inputData[e.currentTarget.name] = options.filter(one => one.checked);
            console.log(inputData,"vsdivbnsivbiv")
            setdowntime(options);
        }
        else {
            inputData[e.currentTarget.name] = e.currentTarget.value
        }
        setErrors({})
        // consol(inputData);

    }

    const handleGetDataForTable = async (equips) => {
        // let res = await axios.get(api.api + 'downtimereason');
        let res = await storeDataInLocalStorage('downtimereason')
        
        let reasons = res.push({ Reason: null })
        equips.forEach((oneEquip) => {
            let arr = []
            res.forEach((one1) => {
                let obj = {}
                

                let filterObj = oneEquip.Downtime.filter(one => one.DowntimeReason == one1.Reason);
                if (filterObj.length > 0) {
                    let dur = 0
                    let cost =0
                    filterObj.forEach(one1 => {
                        dur = dur + one1.Duration
                        cost =cost + one1.Cost
                    })
                    obj.Cost = cost;
                    obj.Duration = dur;
                    obj.Reason = filterObj[0].DowntimeReason;
                    obj.Reason = obj.Reason === null ? 'Down' : obj.Reason;

                } 
                else {
                    obj.Cost = 0;
                    obj.Duration = 0;
                    obj.Reason = one1.Reason=== null ? 'Down' : one1.Reason;

                }

                arr.push(obj)
            })
            oneEquip.Downtime = arr.sort(function (a, b) {
                if (a.Reason < b.Reason) {
                    return -1;
                }
                else if (a.Reason > b.Reason) {
                    return 1;
                }
                return 0;
            });
        })
        return equips;

    }

    const removeDuplicates = (arr, field) => {

        // Create an array of objects 

        // Display the list of array objects 


        // Declare a new array 
        let newArray = [];

        // Declare an empty object 
        let uniqueObject = {};

        // Loop for the array elements 
        for (let i in arr) {

            // Extract the title 
            let objTitle = arr[i][field];

            // Use the title as the index 
            uniqueObject[objTitle] = arr[i];
        }

        // Loop to push unique object into array 
        for (let i in uniqueObject) {
            newArray.push(uniqueObject[i]);
        }



        // Display the unique objects 
        return newArray
    }

    const handleOnSubmit = async (inputData) => {
        inputData = { ...inputData }
        setIndex(0);
        rawData.splice(0, rawData.length);
        downtimeAnalysisData.splice(0, downtimeAnalysisData.length);
        tableData.splice(0, tableData.length);
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setQuerySuccess(true)
        inputData.UserId = user._id;
        if(new Date(inputData.From) <= new Date(inputData.Till)){
        }else{
            setQuerySuccess(false);
            return  toast.success('From date should be Less Than Till date', {
              position: 'bottom-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: '#babbbb', // Set your desired background color
                color: 'white',     // Set your desired text color
              },
            });
          }
        let equipForReport = getEquipsForReport()
        inputData.Shift = inputData.Shift.map(one => one.value);

        for (let i = 0; i < equipForReport.length; i++) {
            inputData.Equips = [equipForReport[i]]

            let result = await axios.post(api.api + 'GetDowntimeAnalysisMachine', inputData)
            if (result.data.length > 0) {
                let idx = i + 1
                let cloneArr = _.cloneDeep(result.data);
                cloneArr.forEach(one => {
                    rawData.push(one)
                })

                let data = _.cloneDeep(rawData);
                if(inputData.ShowRuntime){
                    showWithRuntimeData(rawData)
                }if(inputData.ShowCost){
                    showWithRuntimeData1(rawData)
                }else{
                    let res = organiseDowntimeData(rawData);
                    setDowntimeAnalysisData(res.arr);
                    setSerieseColor(res.color);
                }
             
             
                data = await handleGetDataForTable(data)
                setDataForTable(data);
            
                setIndex(idx)



                // let cloneArr=[...result.data];
                // result = organiseDowntimeData(result.data);
                // cloneArr=await handleGetDataForTable(cloneArr)
                // setDowntimeAnalysisData(result.arr);
                // setDataForTable(cloneArr);
                // setSerieseColor(result.color);
                // setQuerySuccess(false)
            }
        }
        setQuerySuccess(false)

    }

    const showWithRuntimeData=(data)=>{
        data=_.cloneDeep(data);
        data.forEach((one)=>{
            let runObj=_.cloneDeep(one);
            runObj.Duration=one.TotalRunTime;
            runObj.DowntimeReason="Running"
            runObj.State="Running"
            runObj.Color="rgb(15, 157, 88)"
            one.Downtime.unshift(runObj);
        })
        let res = organiseDowntimeData(data);
        setDowntimeAnalysisData(res.arr);
        setSerieseColor(res.color);
    }
    const showWithRuntimeData1=(data)=>{
        data=_.cloneDeep(data);
        data.forEach((one)=>{
            let runObj=_.cloneDeep(one);
            runObj.Duration=one.TotalRunTime;
            runObj.DowntimeReason="Running"
            runObj.State="Running"
            runObj.Color="rgb(15, 157, 88)"
            one.Downtime.unshift(runObj);
        })
        let res = organiseDowntimeData1(data);
        setDowntimeAnalysisData(res.arr);
        setSerieseColor(res.color);
    }
    const organiseDowntimeData1 = (bufferArr) => {

        let schema = ['Machine']
        // if (inputData.ShowRuntime) {
        //     schema = ['Machine', "Running",{role:"style"}]
        // }
        let arr = [];
        let colorWithReason = []
        // if(inputData.ShowRuntime){
        //     colorWithReason.push({Reason:"Running",Color:"rgb(15, 157, 88)"})
        // }
        let seriesObj = {}
        bufferArr.forEach(element => {
            element.Downtime.forEach((one, i) => {
                let IsAlready;
                let obj = {}

                if (one.DowntimeReason) {

                    IsAlready = schema.includes(one.DowntimeReason);
                } else {

                    IsAlready = schema.includes(one.State);
                }
                if (!IsAlready) {
                    if (one.DowntimeReason) {
                        obj.Reason = one.DowntimeReason;
                        obj.Color = one.Color;
                        colorWithReason.push(obj)
                        schema.push(one.DowntimeReason)
                        schema.push({ role: 'style' })
                    } else {
                        obj.Reason = one.State;
                        obj.Color = 'red';
                        colorWithReason.push(obj)
                        schema.push(one.State)
                        schema.push({ role: 'style' })
                    }
                }
            })
        });

        bufferArr.forEach(element => {
            let oneArr = []
            if (element.Downtime.length > 0) {
                oneArr = [element.Equipment]
                // if (inputData.ShowRuntime) {
                //     oneArr.push(element.TotalRunTime / 60)
                //     oneArr.push('rgb(15, 157, 88)')
                // }

            }
            let down = element.Downtime;
            if (down.length > 0) {
                down = aggreagteReasonDowntime(down)
                for (let i = 1; i < schema.length; i = i + 2) {
                    let filterDown = down.filter((one) => {
                        if (one.DowntimeReason) {
                            if (one.DowntimeReason == schema[i]) {
                                return one
                            }
                        } else {
                            if (one.State == schema[i]) {
                                return one
                            }
                        }
                    }
                    )
                    if (filterDown.length > 0) {
                        oneArr.push(parseFloat((filterDown[0].Cost )));
                        oneArr.push(filterDown[0].Color)

                    } else {
                        let filterColor = colorWithReason.filter(color => color.Reason == schema[i])
                        oneArr.push(0);
                        oneArr.push(filterColor[0].Color)
                    }
                }
                arr.push(oneArr)
            }

        })
        arr.unshift(schema)
        colorWithReason.forEach((one, i) => {
            seriesObj[i] = { color: one.Color }
        })
        let returnObj = { arr: arr, color: seriesObj }
        return returnObj
    }

    const organiseDowntimeData = (bufferArr) => {

        let schema = ['Machine']
        // if (inputData.ShowRuntime) {
        //     schema = ['Machine', "Running",{role:"style"}]
        // }
        let arr = [];
        let colorWithReason = []
        // if(inputData.ShowRuntime){
        //     colorWithReason.push({Reason:"Running",Color:"rgb(15, 157, 88)"})
        // }
        let seriesObj = {}
        bufferArr.forEach(element => {
            element.Downtime.forEach((one, i) => {
                let IsAlready;
                let obj = {}

                if (one.DowntimeReason) {

                    IsAlready = schema.includes(one.DowntimeReason);
                } else {

                    IsAlready = schema.includes(one.State);
                }
                if (!IsAlready) {
                    if (one.DowntimeReason) {
                        obj.Reason = one.DowntimeReason;
                        obj.Color = one.Color;
                        colorWithReason.push(obj)
                        schema.push(one.DowntimeReason)
                        schema.push({ role: 'style' })
                    } else {
                        obj.Reason = one.State;
                        obj.Color = 'red';
                        colorWithReason.push(obj)
                        schema.push(one.State)
                        schema.push({ role: 'style' })
                    }
                }
            })
        });

        bufferArr.forEach(element => {
            let oneArr = []
            if (element.Downtime.length > 0) {
                oneArr = [element.Equipment]
                // if (inputData.ShowRuntime) {
                //     oneArr.push(element.TotalRunTime / 60)
                //     oneArr.push('rgb(15, 157, 88)')
                // }

            }
            let down = element.Downtime;
            if (down.length > 0) {
                down = aggreagteReasonDowntime(down)
                for (let i = 1; i < schema.length; i = i + 2) {
                    let filterDown = down.filter((one) => {
                        if (one.DowntimeReason) {
                            if (one.DowntimeReason == schema[i]) {
                                return one
                            }
                        } else {
                            if (one.State == schema[i]) {
                                return one
                            }
                        }
                    }
                    )
                    if (filterDown.length > 0) {
                        oneArr.push(parseFloat((filterDown[0].Duration / 60).toFixed(0)));
                        oneArr.push(filterDown[0].Color)

                    } else {
                        let filterColor = colorWithReason.filter(color => color.Reason == schema[i])
                        oneArr.push(0);
                        oneArr.push(filterColor[0].Color)
                    }
                }
                arr.push(oneArr)
            }

        })
        arr.unshift(schema)
        colorWithReason.forEach((one, i) => {
            seriesObj[i] = { color: one.Color }
        })
        let returnObj = { arr: arr, color: seriesObj }
        return returnObj
    }

    function aggreagteReasonDowntime(bufferArry) {

        for (let i = 0; i < bufferArry.length; i++) {
            for (let j = i + 1; j < bufferArry.length; j++) {
                if (bufferArry[i].DowntimeReason === bufferArry[j].DowntimeReason) {
                    bufferArry[i].Duration += bufferArry[j].Duration
                    bufferArry.splice(j, 1);

                    // i--;
                    j--;
                    // i=j-1
                }
            }
        }
        return bufferArry
    }
    const handleDownload = (e, date) => {
        generateExcelFile(e, date)
    }

    const getChart = (data, color) => {
        return <Chart
            width={'100%'}
            height={(getEquipsForReport().length + 1) * 40}
            style={{ padding: 0, height: (getEquipsForReport().length + 1) * 40 }}
            chartType="BarChart"
            loader={<div>Loading Chart</div>}
            data={downtimeAnalysisData}
            options={{
                title: 'Downtime Analysis By Machine',
                // chartArea: { width: '50%' },
                isStacked: true,
                series: color,
                hAxis: {
                    title:`${inputData.ShowCost ===true ? 'Cost in (₹)' :'Downtime Duration (min)' }` ,
                },
                vAxis: {
                    title: 'Machines',
                    minValue: 0,

                },

            }}
            // For tests
            rootProps={{ 'data-testid': '3' }}
        />
    }

    const SelectedDates =(dates)=>{

        setInputData(prev => ({ ...prev, ...dates }));
      }
      
    return (
        <React.Fragment>
            {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`} /> : ''}
            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Downtime Analysis Machine
                </h4>
            </div>
            <ToastContainer/>
            <div className="row mx-2">
                {equipGroups.length > 0 &&<EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}
                {/* <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>From</label>
                        <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
                        {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Till</label>
                        <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                        {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                    </div>
                </div> */}
                <div className="col-sm-2">
            <div className="form-group text-center">
              <label className='small'>Downtime Type</label>
              <MultiSelectCheckBox name='Downtime' onChange={handleOnChange} selectedValues={inputData['Downtime']} options={downtime} />
              {errors.Downtime && <p className='text-danger ' style={{ fontSize: 11 }}>please select Downtime type</p>}
            </div>
          </div>
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Shift</label>
                        <MultiSelectCheckBox name='Shift' onChange={handleOnChange} selectedValues={inputData['Shift']} options={shiftOption} />
                        {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>please select at least one shift</p>}
                    </div>
                </div>
                {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Periodicity</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewBy'>
              <option>--select periodicity--</option>
              <option>Hourly</option>
              <option>Daily</option>
              <option>Shift</option>
            </select>
            {errors.ViewBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewBy}</p>}
          </div>
        </div> */}
                {/* <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Shift</label>
                        <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
                            <option>--select shift--</option>
                            <option>All</option>
                            {shiftSetting.map(one => {
                                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
                            })}
                        </select>
                        {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
                    </div>
                </div> */}

                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>
                <div className="col-sm-1">
                    Show Runtime  <input disabled={inputData.ShowCost === true ? true:false} type='checkbox' name='ShowRuntime' checked={inputData.ShowRuntime} onChange={handleOnChange} />
                </div>
                {/* <div className="col-sm-1">
                    Show Cost  <input type='checkbox' name='ShowCost' checked={inputData.ShowCost} onChange={handleOnChange} />
                </div> */}
      
                <div className="form-check form-switch d-flex">
                   <div><label className="form-check-label  mx-2" for="flexSwitchCheckDefault">Duration</label></div>
 
<div>
  <input className="form-check-input  mx-2" type="checkbox" name='ShowCost' checked={inputData.ShowCost} onChange={handleOnChange} role="switch" id="flexSwitchCheckDefault"/></div>
  <div><label className="form-check-label  mx-5" for="flexSwitchCheckDefault">Cost</label></div>
</div>



            </div>
            <div>


                {/* {view==='Daily'?<Table columns={columns2} sortColumn={sortColumn2} data={availabilityData}/>:view==='Shift'?<Table sortColumn={sortColumn1} columns={columns1} data={availabilityData}/>:view==='Hourly'?<Table columns={columns} sortColumn={sortColumn} data={availabilityData}/>:''}
        
         */}

                {/* <DowntimeAnalysisTable view={inputData.ViewBy} /> */}
                <div >
                    {downtimeAnalysisData.length > 1 ? getChart(downtimeAnalysisData, seriesColor) : <p></p>}
                </div>
                {tableData.length > 0 ? <div className="col-sm-2">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('MachineDowntimeAnalysis', inputData.DateTime)}>Download Excel</button>
                </div> : ''}
                <div>
                    {tableData.length > 0 ? <TableForDowntimeAnalysis  showRunTime={inputData.ShowRuntime} showCost={inputData.ShowCost} id='MachineDowntimeAnalysis' data={tableData} /> : ''}
                </div>
            </div>
        </React.Fragment>


    );
}

export default DowntimeAnalysisMachine;