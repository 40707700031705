import React, { Component } from 'react';
import swal from 'sweetalert';
import endPoint from '../../../config';
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';
import MultiSelect from '../../../Common/multiselect';

import { ToastContainer, toast } from 'react-toastify';


class QualityReason extends Component {
    state = { 
        qualityReason:{},
        oldObj:{},
        qualityReasonGroupList:[],
        errors:{},
        querySuccess:false,
        
     }
     schema={
      
      QualityReason:joi.string().required().label('Quality Reason'),
      QualityReasonGroup:joi.string().required().label('Quality Reason Group'),
    }

     componentDidMount()
 {
  this.setState({querySuccess:true});
  axios.get(endPoint.api+'qualityreasongroup').then((result)=>{
    this.setState({qualityReasonGroupList:result.data,querySuccess:false})
   
  })
  .catch((err)=>{
    this.setState({querySuccess:false});
      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
  })
   if(this.props.actionStatus==='add')
   {
     this.setState({delayReason:{}})
   }
   if(this.props.actionStatus==='update')
   {
     const qualityReason={...this.props.qualityReasonData}
     this.setState({qualityReason:this.props.qualityReasonData,oldObj:qualityReason});
   }
 }

    handleOnChange=(e)=>{
        const {qualityReason,errors} ={...this.state};
        const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

        qualityReason[e.currentTarget.name]=e.currentTarget.value;
        this.setState({qualityReason,errors:errors||{}});

    }
    handleSubmit=(data)=>{
      
     
      if(this.props.actionStatus==='add')
      {const errors = valid.validate(data,this.schema);
        this.setState({ errors: errors || {} });
        if (errors) return;
    
        this.setState({querySuccess:true});

        axios.post(endPoint.api+'qualityreason',data)
        .then((result)=>{
         if(result.status===200)
         {
          this.setState({querySuccess:false});
           this.props.history.push('/setting/qualityreason/list')
           swal('Save Data Successfully');
         }
         else
         {
          this.setState({querySuccess:false});
          swal(`Data Not Saved`);
         }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
          swal(`${err.response.data.message}`);
        })
    
      }
      if(this.props.actionStatus==='update')
      {
        this.setState({querySuccess:true});
        axios.patch(endPoint.api+'qualityreason/'+data._id,{old:this.state.oldObj,new:data})
        .then((result)=>{
          const {n,nModified,ok}=result.data;
         if(ok==1&&n==1&&nModified==1)
         {
          this.setState({querySuccess:false});
           this.props.history.push('/setting/qualityreason/list')
           swal('Updated Data Successfully');
         }
         else
         {
          this.setState({querySuccess:false});
          swal(`${result.data}`);
         }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        })
    
      }
    
     
    
    
    }


    render() { 
        return ( 
            <React.Fragment>
       <ToastContainer/>
       {this.state.querySuccess?<Loading/>:''}
      
            <div className=" border">
              <div className="row justify-content-center">
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Quality Reason Group</label>
                    {/* <select
                      type="text"
                      className="form-control"
                      placeholder="Enter DownTime Reason Group"
                      name="QualityReasonGroup"
                      onChange={this.handleOnChange}
                    value={this.state.qualityReason.QualityReasonGroup}
                    >
                    <option>---Select Quality Reason Group---</option>
                    {this.state.qualityReasonGroupList.map((one,i)=>{
                     return <option key={i}>{one.QualityReasonGroup}</option>
                   })}
                    </select> */}
                    <MultiSelect name="QualityReasonGroup" selected={this.state.qualityReason['QualityReasonGroup']} handleOnChange={this.handleOnChange} masterName="QualityReasonGroup" objLevel={1} all={this.state.qualityReasonGroupList}/>

                    {this.state.errors.QualityReasonGroup && <div className="alert small alert-danger">{this.state.errors['QualityReasonGroup']}</div>}
                  </div>
                 
    
                
                </div>
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label> Quality Reason </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Delay Reason "
                      name="QualityReason"
                      onChange={this.handleOnChange}
                    defaultValue={this.state.qualityReason.QualityReason}
                    />
                    {this.state.errors.DelayReason && <div className="alert small alert-danger">{this.state.errors['QualityReason']}</div>}
                  </div>
    
                
                </div>
    
               
               
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.qualityReason);
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="text-center" style={{marginTop:10}}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push('/setting/qualityreason/list')
                  }}
                >
                 Cancel
                </button>
              </div>
            </div>
          </React.Fragment>
         );
    }
}
 
export default QualityReason;