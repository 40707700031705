import axios from "axios";
import React, { Component } from 'react';
// import authService from './authService'
//import logger from "./logService";
import  jwtDecode  from 'jwt-decode';
import auth from './authService';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';

//let  rights='';
axios.interceptors.response.use(null, async error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;
     if(expectedError){
      if(error.response.status==403){
        let res=await swal({
          title: "This account is logged in from another device.",
          text: "logout from this device",
          icon: "warning",
          button: "Logout!",
          closeOnEsc : false,
       closeOnClickOutside: false
        })
        if(res){
          if(auth.getCurrentUser()){
            window.location='/'
            await auth.logout(auth.getCurrentUser().email);
            window.location='/'
          }else{
            window.location='/'
          }
        
        }
        
      }
     }
    
    // consol(expectedError);

  if (!expectedError) {
    // // consol("logging the error", error);
    // alert("An unexpected error occurred.");
    // swal(`${error}`)
    toast.success(`${error}`, {
      position: 'bottom-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        background: 'Red', // Set your desired background color
        color: 'white',     // Set your desired text color
      },
    });
    //logger.log(error);
    //toast.error("An unexpected error occurred.");
   

  }

 

  return Promise.reject(error);
});

// function setDataRights(dataRightsKey)
// {
//      rights=dataRightsKey;
// }


function setJwt(jwt) {
  if(jwt!==null)
  {
     try{
      const data=jwt?jwtDecode(jwt):'';
      axios.defaults.headers.common["authorization"] = jwt;
      // axios.defaults.headers.common["rights"] = rights;
      // // consol(rights)

       axios.defaults.headers.common["Company"] = data?data.company:'';
       axios.defaults.headers.common["id"] = data?data._id:'';
       axios.defaults.headers.common["Name"] = data?data.name:'';
       axios.defaults.headers.common["UserName"] = data?data.email:'';
       axios.defaults.headers.common["Access-Control-Allow-Origin"] = '*';
      // axios.defaults.headers.common["PlantName"] = data ?data.plants[0].plantName:'';
      return true
     }
     catch(e){
     return false
     }
     
    
  
  }
  
}

export default {
  //get: axios.get,
  put: axios.put,
  post: axios.post,
  get: axios.get,
  delete: axios.delete,
  patch:axios.patch,
  setJwt,
 // setDataRights
};