import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";

import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";

import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import DateSelection from "../Common/DateSelection";

const ProductionSummary = ({fromDateLimit,storeDataInLocalStorage,}) => {

    const [inputData, setInputData] = useState({});
    const [errors, setErrors] = useState({});
    const [querySuccess, setQuerySuccess] = useState(false);
    const [componentdata, setcomponentdata] = useState([]);
    const [selectedItemIds, setSelectedItemIds] = useState([]);
    const [sortColumn, setSortColumn] = useState({ path: "Component", order: "asc" });
    const [operationdata, setoperationdata] = useState([]);
    const [senddata, setsenddata] = useState([]); 
    const [selectedDates, setSelectedDates] = useState({
      From: "",
      Till: "",

    });
    const [selectedItemLengths, setSelectedItemLengths] = useState({});
    const [columns, setcolumn] = useState([]);
    const [dynamicData, setDynamicData] = useState([]);


    const column = [
      { path: "Component", label: "Component" },
      { path: "Operation", label: "Operation" },
      { path: "TargetParts", label: "TargetParts" },
      { path: "PartsProduced", label: "PartsProduced" },
      { path: "TotalRejection", label: "TotalRejection" },

    ];
    
    const schema = {
      Component: joi.array().required().label("Component"),
        Operation: joi.array().required().label("Operation"),
      };





    useEffect(() => {
        // Fetch dynamic data using Axios
        const fetchData = async () => {
          setQuerySuccess(true);
    
          try {
     let componentdata = await storeDataInLocalStorage('component')
     let downtimedata = await storeDataInLocalStorage('downtimereason')

     const downtimelist = downtimedata
        .map((reason, index) => ({
          id: `${index}`,
          label: reason.ReasonGroup,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));

        downtimelist.push({id:'40',label:"Down"})

     const uniqueObjects = [];

     // Filter out objects with duplicate combinations of label and id
     const filteredData = downtimelist.filter(obj => {
       // Check if the combination of label and id is already in the uniqueObjects array
       const isDuplicate = uniqueObjects.some(uniqueObj =>
         uniqueObj.label === obj.label
       );
     
       if (!isDuplicate) {
         // If not, add it to the array and keep the object
         uniqueObjects.push({ id: obj.id, label: obj.label });
         return true;
       }
     
       // If the combination of label and id is already in the array, filter it out
       return false;
     });

            const complist = componentdata
              .map((componentList, index) => ({
                id: `${index}`,
                label: componentList.ComponentId,
              }))
              .slice()
              .sort((a, b) => a.label.localeCompare(b.label));
            // Iterate through the data and extract operation IDs
            const operationIds = [];
            componentdata.forEach((item) => {
              item.Operation.forEach((operation) => {
                operationIds.push(operation.OperationID);
              });
            });
            // Sort and remove duplicates from operationIds
            const uniqueSortedOperations = [...new Set(operationIds)]
              .sort((a, b) => a - b)
              .map((operation, index) => ({ id: index, label: operation }));
              setDynamicData(uniqueObjects);

            setoperationdata(uniqueSortedOperations);
            setcomponentdata(complist);
            // // consol("fetching data",uniqueData,downtimelist);
    
          } catch (error) {
            // console.error("Error fetching data:", error);
          }
          setQuerySuccess(false);
    
        };
    
        fetchData();
      }, []); // Runs once when the component mounts
      const DropDownData = [
        {
            heading: "Component",
            label: "tablecolumns",
            initialItems: componentdata,
            selectedItemIds: selectedItemIds,
            name: "Component",
            enableLocalStorage: false,
            multiSelect: true,
            searchbar: true,
            selectAll:true
          },
           {
          heading: "Operation",
          label: "tablecolumns",
          initialItems: operationdata,
          selectedItemIds: selectedItemIds,
          name: "Operation",
          enableLocalStorage: false,
          multiSelect: true,
          searchbar: true,
          selectAll:true

        },
       
      ];
    
      const handleOnChange = (event) => {
        const { name, value } = event.target;
        setSelectedDates((prevDates) => ({
          ...prevDates,
          [name]: value,
        }));
      };
  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
  
    const errors = validate.validate(inputData, schema);
  
    setErrors(errors || {});
    if (errors) return;
  
    setQuerySuccess(true);
  
    try {
      const dateTime = { ...selectedDates };
      inputData.From = dateTime.From;
      inputData.Till = dateTime.Till;

        try {
          const result = await axios.post(api.api + 'ProductionReport1', inputData);
          const equipResponse = result.data;
          if(equipResponse.length > 0) {


            let TableData =     equipResponse.map(item => {
              let Component = item.Component ;
              let Operation= item.Operation;
             let TargetParts= item.TargetParts;
             let PartsProduced= item.PartsProduced;
             let TotalRejection= item.TotalRejection;
             let  DowntimeSummary =item.DowntimeSummary;
             
             const durations = item.DowntimeSummary.map(one => one.Duration);
             const totalDuration = durations.reduce((acc, duration) => acc + duration, 0);
             return {Component, TotalRejection, DowntimeSummary,PartsProduced,TargetParts,Operation,totalDuration}
            })
            let Sorted =TableData.sort((a,b)=> b.totalDuration -a.totalDuration);
            setcolumn(Sorted);
            setQuerySuccess(false);

          }else{
            swal(`No Data`); 
            setQuerySuccess(false);

          }
        
        //   setdatetime([inputData.DateTime,inputData])   
        } catch (error) {
          swal(`${error}`);
          // consol(error);
          setQuerySuccess(false);
        }
      
  
      // Start making requests from the first element


    } catch (error) {
      swal(`${error}`);
      // consol(error);
      setQuerySuccess(false);
    }
  };
  
      const handleSelectedItemsChange = (newSelectedItems, name,totallength) => {
        setSelectedItemIds(newSelectedItems);
        const validHeadings = [
          "Component",
          "Operation",
        ];
    
        // Check if the provided heading name is valid
        if (!validHeadings.includes(name)) {
          // // console.error("Invalid heading:", name);
          return; // Exit the function if heading is invalid
        }
        setSelectedItemLengths((prevState) => ({
          ...prevState,
          [name]: newSelectedItems.length,
        }));
     
          inputData[name] = newSelectedItems.length == totallength?["all"]:newSelectedItems
        setsenddata(inputData);
      };
      const handleDownloadExcel = (e) => {
        generateExcelFile(e);
      };
      const handleSort = (sortColumn) => {
          const sorted = _.orderBy(columns, [sortColumn.path], [sortColumn.order]);
          setcolumn(sorted);
          // consol(sortColumn,"after pressing sort",sorted)
          setSortColumn(sortColumn);
    
      }; 
      
      


      const getQualityReason = (ReasonArr) => {
        let returnArr = [];
    
        // Check if ReasonArr is undefined
        if (!ReasonArr) {
          ReasonArr = [{ DowntimeGroup: 'default', Duration: 0 }];
        }
    
        if (dynamicData.length === 0) {
            returnArr.push({ DowntimeGroup: 'default', Duration: 0 });
        } else {
            dynamicData.forEach((one1) => {
                let filterReason = ReasonArr.filter(obj => obj?.DowntimeGroup === one1.label);
                if (filterReason.length > 0) {
                    returnArr.push({ ...filterReason[0] });
                } else {
                    let obj = { DowntimeGroup: one1.label, Duration: 0 };
                    returnArr.push(obj);
                }
            });
        }
    
        return returnArr;
    };
        
  const SelectedDates =(dates)=>{
    setSelectedDates(dates)
  }
  return (
    <div  className="justify-content-center">
    {querySuccess ? <Loading /> : ""}
    <div className="text-center heading-bg mb-4">
      <h4 className="text-white p-0 m-0" >
       Production Summary(1)
      </h4>
    </div>
    <div className="row mx-3">
     {componentdata.length > 0 ?  <>
      <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded       bg-white"
              }
      min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={"form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded bg-white"}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              // max={TillDateLimit}
            />
          </div>
        </div> */}
          

      {DropDownData.map((data, index) => {
        return (
          <>
            <div className="col-sm-2">
              <div className="form-group text-center">
                <label className="small">{data.heading}</label>
                {/* {componentdata.length > 0 && ( */}
                  <ReusableCheckboxDropdown
                    initialItems={data.initialItems}
                    label={data.label}
                    heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}

                    selectedItemIds={selectedItemIds}
                    onSelectedItemsChange={(newSelectedItems) =>
                      handleSelectedItemsChange(
                        newSelectedItems,
                        data.name,
                        data.initialItems.length
                      )
                    }
                    enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                    multiSelect={data.multiSelect} // Pass
                    searchbar={data.searchbar}
                    selectAll={data.selectAll}
                  />
                {/* )} */}
                {errors[data.heading] && (
                  <p className="text-danger" style={{ fontSize: 11 }}>
                    {errors[data.heading]}
                  </p>
                )}
              </div>
            </div>
          </>
        );
      })}

<div className="col-sm-1">
        <button
          className="btn btn-primary btn-sm mt-4"
          onClick={() => handleOnSubmit()}
        >
          Apply
        </button>
      </div>

</>:""}           
  </div>

  {columns?.length > 0 ? ( <div style={{backgroundColor:"white",borderRadius:"15px",overflow:'auto'}} className="mx-3 my-3 shadow ">
              <h2>Production Summary Table</h2>
             
            
                <div className="uiverse" onClick={() =>
      handleDownloadExcel("ProductionSummaryReport1")}>
    <span className="tooltip">Dowload Excel File</span>
    <span>
    Download
    </span>
</div>
          
              
                {/* <Table
                  id="ProductionSummaryReport1"
                  onSort={handleSort}
                  columns={column}
                  sortColumn={sortColumn}
                  data={columns}
                />
 */}




<table id={"ProductionSummaryReport1"} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0 }}>
            <thead className='small text-center border'>
                <tr className='text-center align-self-center table-primary'>
                    
                    <th style={{ verticalAlign: 'middle' }} rowSpan={2}>Component</th>
                   
                    <th style={{ verticalAlign: 'middle' }} rowSpan={2}>Operation</th>
                  
                    <th style={{ verticalAlign: 'middle' }} rowSpan={2}>Target Parts</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan={2}>Parts Produced</th>
 
                    <th style={{ verticalAlign: 'middle' }} rowSpan={2}>Total Rejection (no. of units)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan={1}colSpan={dynamicData.length +1}>Downtime Groups (min) </th>
                   
                </tr>

                <tr className='table-primary' >

                
                {dynamicData.length > 0 ? dynamicData.map(one => {
                        return <th className=" text-center" style={{  height: (100 + dynamicData.length) * 2.8 ,verticalAlign: 'middle'}} ><div  style={{marginTop:'1rem'}}><span className="text-start">{one.label}</span></div></th>
                    }) : ''}
                    <th style={{ verticalAlign: 'middle' }}   >Total</th>
                </tr>

            </thead>
            <tbody>
                {columns.map(one => {
                    return <>
                    
                    <tr className='text-center align-self-center'>
                       
                        <td >{one.Component}</td>
                  
                        <td >{one.Operation}</td>
                        <td >{one.TargetParts ? one.TargetParts : '0'}</td>
                        <td >{one.PartsProduced ? one.PartsProduced : '0'}</td>
                        <td >{one.TotalRejection ? one.TotalRejection : 0}</td>
                        {getQualityReason(one?.DowntimeSummary).length > 0 ? getQualityReason(one?.DowntimeSummary).map(r => {
                            return <td  className='text-center align-self-center'>{r.Duration == 0 ? '0': ((Number(r.Duration)/60).toFixed(1))}</td>
                        }) : <td className='text-center align-self-center' >0</td>}
                        <td  className='text-center align-self-center' >{one.totalDuration ? ((Number(one.totalDuration)/60).toFixed(1)) : 0}</td>
                    </tr>
                   

                        
                    </>
                })}
            </tbody>
        </table>





             
            </div> ) 
            : (
              ""
            )}
  </div>
  )
}

export default ProductionSummary