import React, { Component } from 'react';
import swal from 'sweetalert';
import endPoint from '../../../config';
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';



class QualityReasonGroup extends Component {
  state = { 
    QualityReasonGroup:{},
    oldObj:{},
    errors:{},
    querySuccess:false

 }
 schema={
  
  QualityReasonGroup:joi.string().required().label('Quality Reason Group'),
}

componentDidMount()
 {
   // consol('QualityGroup')
   if(this.props.actionStatus==='add')
   {
     this.setState({QualityReasonGroup:{}})
   }
   if(this.props.actionStatus==='update')
   {
     const QualityReasonGroup={...this.props.qualityReasonGroupData}
     this.setState({QualityReasonGroup:this.props.qualityReasonGroupData,oldObj:QualityReasonGroup});
   }
 }

handleOnChange=(e)=>{
    const {QualityReasonGroup,errors} ={...this.state};
    const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    QualityReasonGroup[e.currentTarget.name]=e.currentTarget.value;
    this.setState({QualityReasonGroup,errors:errors||{}});
    

}
handleSubmit=(data)=>{
  
 
  if(this.props.actionStatus==='add')
  {
    const errors = valid.validate(data,this.schema);
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.setState({querySuccess:true});
    axios.post(endPoint.api+'qualityreasongroup',data)
    .then((result)=>{
     if(result.status===200)
     {
      
      this.setState({querySuccess:false});
       this.props.history.push('/setting/qualityreasongroup/list')
       swal('Save Data Successfully');
     }
     else
     {
      this.setState({querySuccess:false});
      swal(`Data Not Saved`);
     }
    })
    .catch((err)=>{
      this.setState({querySuccess:false});
      swal(`${err.response.data.message}`);
    })

  }
  if(this.props.actionStatus==='update')
  {
    this.setState({querySuccess:true});
    axios.patch(endPoint.api+'qualityreasongroup/'+data._id,{old:this.state.oldObj,new:data})
    .then((result)=>{
      const {n,nModified,ok}=result.data;
     if(ok==1&&n==1&&nModified==1)
     {
      this.setState({querySuccess:false});
       this.props.history.push('/setting/qualityreasongroup/list')
       swal('Updated Data Successfully');
     }
     else
     {
      this.setState({querySuccess:false});
      swal(`${result.data}`);
     }
    })
    .catch((err)=>{
      this.setState({querySuccess:false});
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
    })

  }

 


}




    render() { 
        return ( 
            <React.Fragment>
              <ToastContainer/>
       {this.state.querySuccess?<Loading/>:''}
      

            <div className=" border">
              <div className="row justify-content-center">
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Quality Rejection Reason Group</label>
                    <input
                      type="text"
                      required
                      className="form-control"
                      placeholder="Enter Quality Rejection Reason Group"
                      name="QualityReasonGroup"
                      onChange={this.handleOnChange}
                    defaultValue={this.state.QualityReasonGroup.QualityReasonGroup}
                    />
                    {this.state.errors.QualityReasonGroup && <div className="alert small alert-danger">{this.state.errors['QualityReasonGroup']}</div>}
                  </div>
    
                
                </div>
    
               
               
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.QualityReasonGroup);
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="text-center" style={{marginTop:10}}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push('/setting/qualityreasongroup/list')
                  }}
                >
                 Cancel
                </button>
              </div>
            </div>
          </React.Fragment>
         );
    }
}
 
export default QualityReasonGroup;