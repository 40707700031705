import React from 'react';
import { useState, useEffect } from 'react';
import date from '../utill/dateTime';
import api from '../config.json'
import axios from 'axios';
const DetailedProductionTable2 = ({ id, data, reasons, qualityReason, storeDataInLocalStorage }) => {
    const [compList, setCompList] = useState([]);
    useState(() => {
        axios.get(api.api1 + "component")
            .then((result) => {
                setCompList(result.data);
            })
            .catch((err) => {
                //   // consol(err);
            })
    }, [])

    const getRowSpan = (data1) => {
        // return '3'
        let ReasonArr = [...data1.Downtime]
        let arr = []
        let returnArr = []
        for (let i = 0; i < ReasonArr.length; i++) {
            let arr1 = []
            arr1.push(ReasonArr[i])
            for (let j = i + 1; j < ReasonArr.length; j++) {
                if (ReasonArr[i].DowntimeReason == ReasonArr[j].DowntimeReason) {
                    arr1.push(ReasonArr[j]);
                    ReasonArr.splice(j, 1)
                    j--;
                }
            }
            arr.push(arr1);
        }
        arr.forEach(one => {
            returnArr.push(one.length);
        })
        returnArr.sort(function (a, b) {
            return b - a;
        });
        if (returnArr.length > 0) {
            return returnArr[0]
        } else {
            return 1;
        }


    }

    const getDowntimeReason = (downsReason) => {

        let ReasonArr = [...downsReason];
        let arr = [];

        for (let i = 0; i < ReasonArr.length; i++) {
            let arr1 = [];
            let totalDuration = ReasonArr[i].Duration; // Assuming 'Duration' is the property to aggregate

            arr1.push(ReasonArr[i]);

            for (let j = i + 1; j < ReasonArr.length; j++) {
                if (ReasonArr[i].DowntimeReason === ReasonArr[j].DowntimeReason) {
                    totalDuration += ReasonArr[j].Duration;
                    arr1.push(ReasonArr[j]);
                    ReasonArr.splice(j, 1);
                    j--;
                }
            }
            arr.push({ reasons: arr1, totalDuration: totalDuration });
        }

        // If you need only the unique reasons with their total Duration
        let returnArr = arr.map(item => ({
            DowntimeReason: item.reasons[0].DowntimeReason,
            Duration: item.totalDuration
        }));


        let returnArr1 = [];

        // Check if ReasonArr is undefined
        if (!returnArr) {
            returnArr = [{ DowntimeReason: 'default', Duration: 0 }];
        }

        if (reasons.length === 0) {
            returnArr1.push({ DowntimeReason: 'default', Duration: 0 });
        } else {
            reasons.forEach((one1) => {
                let filterReason = returnArr.filter(obj => obj?.DowntimeReason === one1.Reason);
                if (filterReason.length > 0) {
                    returnArr1.push({ ...filterReason[0] });
                } else {
                    let obj = { DowntimeReason: one1.Reason, Duration: 0 };
                    returnArr1.push(obj);
                }
            });
        }

        return returnArr1;

    }
    const getDowntimeReason1 = (ReasonArr) => {
        let returnArr = []
        if (reasons.length == 0) {
            let obj = {}
            obj.Duration = null;
            obj.From = null
            obj.Till = null
            obj.Remarks = null
            returnArr.push(obj)
        } else {
            reasons.forEach((one1) => {
                let filterReason = ReasonArr.filter(obj => obj.DowntimeReason == one1.Reason)
                if (filterReason.length > 0) {
                    returnArr.push({ ...filterReason[0] })
                } else {
                    let obj = {}
                    obj.Duration = null;
                    obj.From = null
                    obj.Till = null
                    obj.Remarks = null
                    returnArr.push(obj)
                }
            })
        }

        return returnArr;
    }

    const getQualityReason = (ReasonArr) => {
        let returnArr = []

        if (qualityReason.length == 0) {
            returnArr.push({ Reason: 'default', Count: '-' });
        } else {
            qualityReason.forEach((one1) => {
                let filterReason = ReasonArr.filter(obj => obj.Reason == one1.QualityReason)
                if (filterReason.length > 0) {
                    returnArr.push({ ...filterReason[0] })
                } else {
                    let obj = { Reason: one1.QualityReason, Count: '-' }
                    returnArr.push(obj)
                }
            })
        }

        return returnArr;
    }

    const getAliaName = (Component) => {
        let component = [...compList];
        component = component.filter((one) => one.ComponentId == Component)
        if (component.length > 0) {
            return component[0].Alias
        } else {
            return 'N/A'
        }
    }


    return (
        <table id={id} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0 }}>
            <thead className='small text-center border'>
                <tr className='text-center align-self-center table-primary'>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2' >Date</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Shift</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>EquipmentGroup</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Equipment</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Component</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Component Alias</th>
                    {/* <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Operation</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Tool</th> */}
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Parts Per Cycle</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Operator</th>
                    {/* <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Customer</th> */}
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Job From Date</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Job From Time</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Job Till Date</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Job Till Time</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Remarks</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Job Duration (mins)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Break Duration(mins)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Planned Prod Time (mins)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Equipment StartDate</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Equipment StartTime</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Equipment StopDate</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Equipment StopTime</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Cycles Run</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='2'>Parts Produced</th>


                    <th style={{ verticalAlign: 'middle' }} colSpan={reasons.length}>Downtime Reasons by Duration in (min)
                    </th>
                    <th style={{ verticalAlign: 'middle' }} colSpan={6}> </th>
                    <th style={{ verticalAlign: 'middle' }} colSpan={qualityReason.length}>Quality Reasons
                    </th>
                    <th style={{ verticalAlign: 'middle' }} colSpan={7}> </th>
                </tr>

                <tr className='table-primary' >
                    {reasons.length > 0 ? reasons.map(one => {
                        return <th className="rotate" style={{ verticalAlign: 'middle', height: (100 + one.Reason.length) * 2.5 }} ><p className='text-wrap text-black fw-bold' style={{ marginTop: '6.5rem' }}><span>{one.Reason}</span></p></th>
                    }) : ''}

                    {/* {reasons.length > 0 ? reasons.map(one => {
                        return <th className="rotate" colSpan='1' style={{ verticalAlign: 'middle', height: (100 + one.Reason.length) * 2.5 }} ><div style={{marginTop:'6.5rem'}}><span>{one.Reason}</span></div></th>
                    }) : ''} */}
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1' >Unplanned Downtime Duration</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1' >Planned Downtime Duration</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>Total Downtime Duration</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>Equipment RunTime</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>Ideal SPM</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>Average SPM</th>
                    {qualityReason.length > 0 ? qualityReason.map(one => {
                        return <th className="rotate" style={{ verticalAlign: 'middle', height: (100 + one.QualityReason.length) * 2.5 }} ><div style={{ marginTop: '6.5rem' }}><span>{one.QualityReason}</span></div></th>
                    }) : ''}

                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>Total Rejection (no. of units)</th>

                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>Availability (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>Performance (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>QualityFactor (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>OEE (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>Target Parts</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='1'>Accepted Parts</th>

                </tr>
            </thead>

            <tbody>
                {data.map(one => {
                    return <>

                        <tr>
                            <td rowSpan={1}>{date.getDateFormat(one.From)}</td>
                            <td rowSpan={1}>{one.Shift}</td>
                            <td rowSpan={1}>{one.EquipmentGroup}</td>
                            <td rowSpan={1}>{one.Equipment}</td>
                            <td rowSpan={1}>{one.Component}</td>
                            <td rowSpan={1}>{getAliaName(one.Component)}</td>
                            {/*  <td>{one.Operation}</td>
                        <td rowSpan={1}>{(one.Tool == 'null' || one.Tool == undefined) ? '-' : one.Tool}</td> */}
                            <td rowSpan={1}>{one.PartsPerCycle}</td>
                            <td rowSpan={1}>{one.Operator}</td>
                            {/*  <td>{(one.CustomerName == 'null' || one.CustomerName == null|| one.CustomerName == undefined) ? '-' : one.CustomerName}</td> */}
                            <td rowSpan={1}>{date.getDateFormat(one.JobFrom)}</td>
                            <td rowSpan={1}>{date.getTime(one.JobFrom)}</td>
                            <td rowSpan={1}>{date.getDateFormat(one.JobTill)}</td>
                            <td rowSpan={1}>{date.getTime(one.JobTill)}</td>
                            <td rowSpan={1}>{one.JobRemarks}</td>
                            <td rowSpan={1}>{one.JobDuration}</td>
                            <td rowSpan={1}>{one.BreakDuration}</td>
                            <td rowSpan={1}>{one.PlannedProdTime}</td>
                            <td rowSpan={1}>{date.getDateFormat(one.EquipmentStartDateTime)}</td>
                            <td rowSpan={1}>{date.getTime(one.EquipmentStartDateTime)}</td>
                            <td rowSpan={1}>{date.getDateFormat(one.EquipmentStopDateTime)}</td>
                            <td rowSpan={1}>{date.getTime(one.EquipmentStopDateTime)}</td>
                            <td rowSpan={1}>{one.CyclesRun || 0}</td>
                            <td rowSpan={1}>{one.PartsProduced ? one.PartsProduced : 0}</td>
                            {getDowntimeReason(one?.Downtime).length > 0 ? getDowntimeReason(one?.Downtime).map(r => {
                                return <td >{r.Duration == 0 ? 0 : Math.ceil((Number(r.Duration) / 60))}</td>
                            }) : <td >0</td>}
                            <td>{one.UnplannedDowntimeDuration ? Math.ceil(one.UnplannedDowntimeDuration / 60) : 0}</td>
                            <td>{one.PlannedDowntimeDuration ? Math.ceil(one.PlannedDowntimeDuration / 60) : 0}</td>
                            <td>{one.TotalDowntimeDuration ? Math.ceil(one.TotalDowntimeDuration / 60) : 0}</td>
                            <td>{one.EquipmentRunTime ? one.EquipmentRunTime : 0}</td>
                            <td>{one.IdealSPM ? one.IdealSPM : 0}</td>
                            <td>{one.AverageSPM ? one.AverageSPM : 0}</td>
                            {getQualityReason(one.RejectionReason).length > 0 ? getQualityReason(one.RejectionReason).map(r => {
                                return <td rowSpan={1}>{r.Count}</td>
                            }) : <td rowSpan={1}>-</td>}
                            <td>{one.TotalRejection ? one.TotalRejection : 0}</td>
                            <td>{one.Availability ? one.Availability : 0}</td>
                            <td>{one.Performance ? one.Performance : 0}</td>
                            <td>{one.QualityFactor ? one.QualityFactor : 0}</td>
                            <td>{one.OEE ? one.OEE : 0}</td>
                            <td>{one.TargetParts ? one.TargetParts : 0}</td>
                            <td>{one.AcceptedParts ? one.AcceptedParts : 0}</td>


                        </tr>


                    </>
                })}
            </tbody>
        </table>
    );
}

export default DetailedProductionTable2;