import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import api from "../../../config";
import axios from '../../../httpServices/httpService';
import swal from "sweetalert";
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from "../../../utill/LoadingComponent";
import MultiSelect from "../../../Common/multiselect";
import { ToastContainer, toast } from 'react-toastify';


class User extends Component {
  state = {
    user: { userType:'user'
    },
    oldObj:{},
    modelState: false,
    selectedPlant: "",
    selectedRole: "",
    companyList:[],
    plantList:[],
    errors:{},
    querySuccess:false,
    userGroup:[]
  };

 
  schema={
    // company:joi.string().required().label('Company'),
    // plantName:joi.string().label('Plant Name'),
    name:joi.string().required().label('User Name'),
    email:joi.string().email().required().label('Email'),
    userType:joi.string(),
    UserGroup:joi.string().required().label('User Group'),
    // WhatsAppNumber:joi.number().required().label("WhatsApp No"),
    WhatsAppNumber: joi.number().min(10).required().label("WhatsApp No")
    // plants:joi.array().items(joi.object({
    //   plantName:joi.string().required(),
    //   userRole:joi.string().required()
    // })).min(1).required()
  }

  componentDidMount() {
  
    axios.get(api.api+'usergroup').then((result)=>{
      this.setState({userGroup:result.data});
    })
    .catch((err)=>{
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
    })
    // Promise.all([companyList,plantList]).then((result)=>{
    //     this.setState({companyList:result[0].data,plantList:result[1].data,querySuccess:false});
    // })
    // .catch((err)=>{
    //   this.setState({querySuccess:false});
    //     toast.success(`${err}`, {
       
    // })
    this.buttonRef=React.createRef();
    let obj={}
    if (this.props.actionStatus === "update")
    {
      const user={...this.props.user};
      this.setState({ user: this.props.user,oldObj:user });

    }
      
    if (this.props.actionStatus === "add")
      this.setState({user:obj});


     
  }
 

  handleUserSubmit = data => {

   
    if(this.props.actionStatus==='add')
    {
      if(data.WhatsAppNumber.length == 10){
        data.WhatsAppNumber = data.WhatsAppNumber;
      }else{
        swal('Enter a correct Phone Number')
        return 
      }
      const errors = valid.validate(data,this.schema);
      this.setState({ errors: errors || {} });
      if (errors) return;
      data.userType='user'
      // // consol(data);
      this.setState({querySuccess:true});
      axios.post(api.api+'user',data)
      .then((result)=>{
        // // consol(result)
        if(result.status===200)
        {
          this.setState({querySuccess:false});
          this.props.history.push('/setting/user/list')
          swal({
            title: "INSERT",
            text: "User Created Successfully!",
            type: "success"
        }).then((result)=>{
         
        })
		
        }
        else
        {
          this.setState({querySuccess:false});
          swal('Data Not Saved');
        }
   
      })
      .catch((err)=>{
        this.setState({querySuccess:false});
        swal(`${err.response.data.message}`)
        // // consol(err)
      })
      // // consol(data)
    }
    if(this.props.actionStatus==='update')
    {

      if(data.WhatsAppNumber.length == 10){
        data.WhatsAppNumber = data.WhatsAppNumber;
      }else{
        swal('Enter a correct Phone Number')
        return 
      }
      this.setState({querySuccess:true});
      axios.patch(api.api+'user/'+this.state.oldObj._id,{new:data,old:this.state.oldObj})
      .then((result)=>{
        // // consol(result.data,"update response")
        if(result.data.n==1&&result.data.nModified==1&&result.data.ok===1)
        {
          this.setState({querySuccess:false});
          this.props.history.push('/setting/user/list')
         swal('User Updated Successfully');
        }
        else
        {
          this.setState({querySuccess:false});
          //swal(result.data.error,'error');
        }
    
      })
      .catch((err)=>{
        this.setState({querySuccess:false});
        swal(`${err.response.data.message}`)
      })
      
    }
   
  };

  handleOnChange = e => {
    const { user, errors } = { ...this.state };
  // // consol(e,"for user group")
    const errorMessage = valid.validateProperty(e.currentTarget, this.schema);
    // // consol(errorMessage);
    
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];
  if(e.currentTarget.name === 'name'){
    user[e.currentTarget.name] = e.currentTarget.value;
  }
    else if (e.currentTarget.name === 'email') {
      user[e.currentTarget.name] = e.currentTarget.value;
    } else if (e.currentTarget.name === 'WhatsAppNumber') {
      user[e.currentTarget.name] = e.currentTarget.value;
      // user['WhatsAppNumber'] = e.currentTarget.value;
    }else if (e.currentTarget.name === 'UserGroup') {
      user[e.currentTarget.name] =e.currentTarget.value;
    } 
  
    this.setState({ user, errors: errors || {} });
    // // consol(user);
  };
  

  render() {
   
    return (
      <React.Fragment>              <ToastContainer/>

  {this.state.querySuccess?<Loading/>:''}

        <div className=" border">
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name "
                  name="name"
                  onChange={this.handleOnChange}
                  value={this.state.user.name}
                />
                {this.state.errors.name && <div className="alert small alert-danger">{this.state.errors['name']}</div>}
              </div>

            

            
            </div>
            <div className="col-sm-4">
            <div className="form-group text-center">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="email@example.com"
                  name="email"
                  onChange={this.handleOnChange}
                 defaultValue={this.state.user.email}
                  // defaultValue={this.props.actionStatus==='update'?this.props.user.Email:''}
                />
                {this.state.errors.email && <div className="alert small alert-danger">{this.state.errors['email']}</div>}
              </div>
            </div>
            <div className="col-sm-4">
            <div className="form-group text-center">
                <label>WhatsApp No</label>
                  <input
                       type="text"
                       className="form-control"
                       placeholder="WhatsApp Number"
                       name="WhatsAppNumber"
                       onChange={this.handleOnChange}
                       defaultValue={this.state.user.WhatsAppNumber}
                       maxLength={10}
                       onKeyPress={(e) => {
                        const inputChar = String.fromCharCode(e.charCode);
                        if (/^[0-9]+$/.test(inputChar)) {
                          // Allow only numeric characters
                        } else {
                          e.preventDefault();
                        }
                      }}
                     />
                     


                {this.state.errors.WhatsAppNumber && <div className="alert small alert-danger">{this.state.errors['WhatsAppNumber']}</div>}
              </div>
            </div>

            <div className="col-sm-4">
            <div className="form-group text-center">
                <label for="module">User Type</label>

                <select
                  className="custom-select form-control"
                  name="userType"
                  defaultValue='user'
                  disabled={true}
                  onChange={this.handleOnChange}
                  value={this.state.user.userType}
                >
                  <option>--Select UserType--</option>
                  <option>user</option>
                  <option>admin</option>
                </select>
                {this.state.errors.userType && <div className="alert small alert-danger">{this.state.errors['userType']}</div>}
              </div>
            </div>
            <div className="col-sm-4">
            <div className="form-group text-center">
                <label for="module">User Group</label>

                {/* <select
                  className="custom-select form-control"
                  name="UserGroup"
                  onChange={this.handleOnChange}
                  value={this.state.user.UserGroup}
                >
                  <option>--Select UserGroup--</option>
                 {this.state.userGroup.map((one)=>{
                   return <option>{one.UserGroup}</option>
                 })}
                 
                </select> */}
                    <MultiSelect name="UserGroup" selected={this.state.user['UserGroup']} handleOnChange={this.handleOnChange} masterName="UserGroup" objLevel={1} all={this.state.userGroup}/>

                {this.state.errors.UserGroup && <div className="alert small alert-danger">{this.state.errors['UserGroup']}</div>}
              </div>
            </div>

           
           
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.handleUserSubmit(this.state.user);
              }}
            >
              Submit
            </button>
          </div>
          <input
            ref={this.buttonRef}
            type='reset'
            style={{visibility:"hidden"}}
           />
          
        </div>
      </React.Fragment>
    );
  }
}

export default User;