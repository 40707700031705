import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";
import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import { ToastContainer, toast } from 'react-toastify';




const Refrence = [
  { label: "Equipment", id: "1" },
  { label: "Component", id: "2" },
  { label: "Operator", id: "3" },
  { label: "Tool", id: "4" },
  { label: "Operation", id: "5" },
  { label: "Time", id: "6" },
  { label: "Stage Reasons", id: "7" },
  { label: "Stage", id: "8" },
];

function CavityLossReasonReport({fromDateLimit,storeDataInLocalStorage}) {
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [Totaldata, setTotaldata] = useState([]);
  const [data, setchartdata] = useState([]);
  const [chartdatadaywise, setchartdatadaywise] = useState([]);


  
  const [selectedDates, setSelectedDates] = useState({
    From: "",Till:""
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState({});
  const [selectedItemLengths, setSelectedItemLengths] = useState({});
  const [columns1, setcolumns1] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "Date", order: "asc" });

 

console.log(columns1,"cavity losses")

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    {

      heading: "Equipment",
      label: "Equipment",
      initialItems: equpdata,
      selectedItemIds: selectedItemIds,
      name: "Equipment",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    },
   
 
  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////

  const column1 = [
    { path: "Date", label: "Date" , content: (obj) => <label>{moment(obj.ShiftOf).format('DD MMMM YYYY')}</label> },
    { path: "Shift", label: "Shift" },
    { path: "Equipment", label: "Equipment" },
    { path: "Component", label: "Component",content:(obj)=><label>{obj.Component?obj.Component:'-'}</label>  },
    { path: "Tool", label: "Tool",content:(obj)=><label>{obj.Tool?obj.Tool:'-'}</label>  },
    { path: "Operation", label: "Operation",content:(obj)=><label>{obj.Operation?obj.Operation:'-'}</label> },
    // { path: "JobCavity", label: "Cavities Utilization (%)",content: (obj) => <label>{obj.JobCavity?(((obj.JobCavity)/obj.ToolMaxCavity|| 0)*100):'-'}</label>  },
    { path: "JobPartsPerCycle", label: "Job Cavity",content: (obj) => <label>{ obj.JobPartsPerCycle?obj.JobPartsPerCycle:'-'}</label>  },
   
    { path: "PartsPerCycle", label: "ToolMaxCavity" ,content:(obj)=><label>{obj.PartsPerCycle?obj.PartsPerCycle:'-'}</label> },
    { path: "PartsPerCycleReason", label: "Cavity loss reason" ,content:(obj)=><label>{obj.PartsPerCycleReason?obj.PartsPerCycleReason:'-'}</label>},
    { path: "CyclesRun", label: "Cycles Run",content:(obj)=><label>{obj.CyclesRun?(obj.CyclesRun):'0'}</label> },
    { path: "PartsPerCycle", label: "Loss Parts",content:(obj)=><label>{obj.JobPartsPerCycle?(obj.PartsPerCycle?(((obj.JobPartsPerCycle ? obj.JobPartsPerCycle : 0 )-( obj.PartsPerCycle ? obj.PartsPerCycle : 0))*(obj.CyclesRun ? obj.CyclesRun : 0) ):'-'):0}</label> }, 
    // { path: "Rejection", label: "Rejection",content:(obj)=><label>{obj.Rejection?obj.Rejection:'-'}</label> },
    // { path: "AcceptedParts", label: "Accepted parts",content:(obj)=><label>{obj.Target?obj.AcceptedParts:'-'}</label> },
    // { path: "JobCavity", label: "JobCavity",content:(obj)=><label>{obj.JobCavity?obj.JobCavity:'-'}</label> },
    // { path: "Efficiency", label: "Efficiency (%)" ,content:(obj)=><label>{obj.Efficiency?(Number(obj.Efficiency)).toFixed(1):'-'}</label> },
    

    // { path: "EquipmentGroup", label: "EquipmentGroup" },
    // { path: "PartName", label: "PartName" },
    // { path: "ProcessName", label: "ProcessName" },
    // { path: "TargetParts", label: "TargetParts" },
    // { path: "QuantityProduced", label: "QuantityProduced" },
    // { path: "Rejected", label: "Rejected" },
    // { path: "TotalDownTime", label: "TotalDownTime" },


  ];

  const handleSort1 = (sortColumn) => {
    if (columns1.length > 0) {
      const sorted = _.orderBy(columns1, [sortColumn.path], [sortColumn.order]);
      setcolumns1(sorted);
      setSortColumn(sortColumn);
    }
  };
  const schema = {
    From:joi.string().required().label("From"),
    Till:joi.string().required().label("Till"),
  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
      setQuerySuccess(true);

        // const equipmentdata = await axios.get(api.api1 + "equipment");
    
        // const downtimedata = await axios.get(api.api + "downtimereason");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

         let equipmentdata = await storeDataInLocalStorage('equipment')
        const downtimelist = downtimedata
        .map((reason, index) => ({
          id: `${index}`,
          label: reason.ReasonGroup,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));

        downtimelist.push({id:'40',label:"Down"})

        const uniqueObjects = [];

        // Filter out objects with duplicate combinations of label and id
        const filteredData = downtimelist.filter(obj => {
          // Check if the combination of label and id is already in the uniqueObjects array
          const isDuplicate = uniqueObjects.some(uniqueObj =>
            uniqueObj.label === obj.label
          );
        
          if (!isDuplicate) {
            // If not, add it to the array and keep the object
            uniqueObjects.push({ id: obj.id, label: obj.label });
            return true;
          }
        
          // If the combination of label and id is already in the array, filter it out
          return false;
        });
        
        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

         
          
        setDynamicData(uniqueObjects);
        setequpdata(equiplist);
        setEquipmentDatalist(equipmentdata)
        // consol("fetching data", uniqueObjects);
        
      } catch (error) {
        // console.error("Error fetching data:", error);
      setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts


  const getQualityReason = (ReasonArr) => {
    let returnArr = []
    if (dynamicData.length == 0) {
        returnArr.push({ DowntimeGroup: 'default', Duration: 0 });
    } else {
      dynamicData.forEach((one1) => {
            let filterReason = ReasonArr.filter(obj => obj?.DowntimeGroup == one1.label)
            if (filterReason.length > 0) {
                returnArr.push({ ...filterReason[0] })
            } else {
                let obj = { DowntimeGroup: one1.label, Duration: 0 }
                returnArr.push(obj)
            }
        })
    }

    return returnArr;
}



  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
      "Equipment",
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Equipment") {

     let noobdata  = [];
      newSelectedItems.forEach((item) => {
      let noob = EquipmentDatalist.filter(equipment => equipment.EquipmentID === item);
      noobdata.push(noob[0])
      inputData['Equips'] = noobdata
    })
    } else {
      inputData[name] = newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

    // consol(selectedDates,"current date")
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
  
    const dateTime = { ...selectedDates };
    const errors = validate.validate(dateTime, schema);
  
    setErrors(errors || {});
    if (errors) return;
  
    setQuerySuccess(true);
  
   
      if(new Date(dateTime.From) <= new Date(dateTime.Till)){
        inputData.From = dateTime.From;
        inputData.Till = dateTime.Till;
      }else{
        setQuerySuccess(false);
      
        return  toast.success('From date should be Less Than Till date', {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#babbbb', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      }
      inputData.Report = 'getSummarySheet'
      const results =  axios.put(api.api + 'PartsPerCyle', inputData).then(result =>{
          if(result.data?.length > 0) {
       
            setcolumns1(result.data)
     setQuerySuccess(false);
  
      } else {
        swal("Data Not Available");
      }
  
      setQuerySuccess(false);
    }).catch((err) => {
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          // consol(err);
          setQuerySuccess(false);
        });
  };
  


  

  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };

  ///////////////////////////// Column chart data ///////////////////

  return (<>
      {querySuccess ? <Loading /> : ""}
    <div  className="justify-content-center">
     

      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Cavity Loss Reasons
        </h4>
      </div>
      <ToastContainer/>
      <div className="row mx-3">
       {equpdata.length > 0 ?  <>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
              />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              />
          </div>
        </div>

            

        {/* {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  {/* {componentdata.length > 0 && ( */}
                    {/* <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}

                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar} */}
                    {/* /> */}
                  {/* )} */}
                  {/* {errors[data.heading] && (
                    <p className="text-danger" style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          ); */}
        {/* })} */} 

<div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div>

 </>:""}           
    </div>


{column1.length >0 ?
 <div className="rounded shadow my-4 " style={{width:'100%',overflowX:'scroll'}}>
<div className="d-flex justify-content-start align-items-center  ">
<div className="uiverse mx-2" onClick={() =>
   handleDownloadExcel("ComponentSummary1")}>
 <span className="tooltip">Dowload Excel File</span>
 <span>
 Download
 </span>
</div>

</div>
<Table
                  id="ComponentSummary1"
                  onSort={handleSort1}
                  columns={column1}
                  sortColumn={sortColumn}
                  data={columns1}
                  />
                 
                   {/* <table id={'utilizationreport'} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0 }}>
            <thead className='small text-center border'>
         
                <tr className='text-center align-self-center table-primary'>
                  
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Date</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Shift</th>
                
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Machine</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Component</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Operation</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Produced Quantity </th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Shift Target</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >ToolMaxCavity</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >JobCavity</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >PartsPerCycleReason</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Rejection</th>
                <th style={{ verticalAlign: 'middle' }} rowSpan={2} >Efficiency</th>
              



                
    
                <th style={{ verticalAlign: 'middle' }} colSpan={dynamicData.length}>Loss Hours Analysis (Hrs)</th>

                <th style={{ verticalAlign: 'middle' }} colSpan={1}></th>
            
                </tr>

                <tr className='table-primary' >


                    {dynamicData.length > 0 ? dynamicData.map(one => {
                        return <th className="rotate p-0 m-0 text-start" style={{  height: (100 + dynamicData.length) * 2.8 }} ><div  style={{marginTop:'1rem'}}><span className="text-start">{one.label}</span></div></th>
                    }) : ''}
                <th style={{ verticalAlign: 'middle' }} rowSpan={1} >Total loss hours (Hrs)</th>

                </tr>
              
            </thead>
            <tbody>
               
           { columns2.map(one =><tr >
            <th className="text-center" style={{fontWeight:'400'}}>{` ${moment(one.Date).utcOffset('+05:30').format('DD-MM-YYYY') }`}</th>
            <th className="text-center" style={{fontWeight:'400'}}>{one.Shift
}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Equipment}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Operation}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Component}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.PartsProduced}</th>
                    <th className="text-center" style={{fontWeight:'400'}}>{one.Target
}</th>
                    <th style={{fontWeight:'400'}} className="text-center">{one.ToolMaxCavity}</th>                      
                    <th style={{fontWeight:'400'}} className="text-center">{one.JobCavity}</th>                      
                    <th style={{fontWeight:'400'}} className="text-center">{one.PartsPerCycleReason}</th>                      
                    <th style={{fontWeight:'400'}} className="text-center">{one.Rejection}</th>   
                    <th style={{fontWeight:'400'}} className="text-center">{one.Efficiency}</th>                      

               
               
                   
                    {getQualityReason(one.DownTimeGroupWise).length > 0 ? getQualityReason(one.DownTimeGroupWise).map(r => {
                            return <td >{ r.Duration == 0 ?'-':(r.Duration/60).toFixed(1)}</td>
                        }) : <td >0</td>}
                   <th className="text-center" style={{ fontWeight: '400' }}>
  {(one.DownTimeGroupWise.reduce((totalDuration, item) => totalDuration + item.Duration, 0)/60).toFixed(2)}
</th>
                    </tr>               
                   )}


            </tbody>
        </table> */}
        </div> :''}



    </div>
    </> );
}

export default CavityLossReasonReport;
