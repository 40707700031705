import React from 'react';
import { useState } from 'react';
import date from '../utill/dateTime';
import api from '../config.json'
import axios from 'axios';
const DetailedProdAnalysisPlantTbl = ({ id, data, reasons, qualityReason,inputData }) => {
    const [compList, setCompList] = useState([]);
    useState(() => {
        axios.get(api.api1 + "component")
            .then((result) => {
                setCompList(result.data);
            })
            .catch((err) => {
                // // consol(err);
            })
    }, [])

    const getRowSpan = (data1) => {
        // return '3'
        let ReasonArr = [...data1.Downtime]
        let arr = []
        let returnArr = []
        for (let i = 0; i < ReasonArr.length; i++) {
            let arr1 = []
            arr1.push(ReasonArr[i])
            for (let j = i + 1; j < ReasonArr.length; j++) {
                if (ReasonArr[i].DowntimeReason == ReasonArr[j].DowntimeReason) {
                    arr1.push(ReasonArr[j]);
                    ReasonArr.splice(j, 1)
                    j--;
                }
            }
            arr.push(arr1);
        }
        arr.forEach(one => {
            returnArr.push(one.length);
        })
        returnArr.sort(function (a, b) {
            return b - a;
        });
        if (returnArr.length > 0) {
            return returnArr[0]
        } else {
            return 1;
        }


    }

    const getDowntimeReason = (downsReason, limit) => {
        let ReasonArr = [...downsReason]
        let arr = []
        let returnArr = []
        for (let i = 0; i < ReasonArr.length; i++) {
            let arr1 = []
            arr1.push(ReasonArr[i])
            for (let j = i + 1; j < ReasonArr.length; j++) {
                if (ReasonArr[i].DowntimeReason == ReasonArr[j].DowntimeReason) {
                    arr1.push(ReasonArr[j]);
                    ReasonArr.splice(j, 1)
                    j--;
                }
            }
            arr.push(arr1);
        }

        for (let j = 0; j < limit; j++) {
            if (j < limit) {
                let arr2 = []
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i][j]) {
                        arr2.push(arr[i][j])
                    }
                }
                returnArr.push(getDowntimeReason1(arr2))
            }
        }

        // arr.forEach((one,idx)=>{


        // })

        return returnArr;
    }
    const getDowntimeReason1 = (ReasonArr) => {
        let returnArr = []
        if (reasons.length == 0) {
            let obj = {}
            obj.Duration = null;
            obj.From = null
            obj.Till = null
            obj.Remarks = null
            returnArr.push(obj)
        } else {
            reasons.forEach((one1) => {
                let filterReason = ReasonArr.filter(obj => obj.DowntimeReason == one1.Reason)
                if (filterReason.length > 0) {
                    returnArr.push({ ...filterReason[0] })
                } else {
                    let obj = {}
                    obj.Duration = null;
                    obj.From = null
                    obj.Till = null
                    obj.Remarks = null
                    returnArr.push(obj)
                }
            })
        }

        return returnArr;
    }

    const getQualityReason = (ReasonArr) => {
        let returnArr = []

        if (qualityReason.length == 0) {
            returnArr.push({ Reason: 'default', Count: 0 });
        } else {
            qualityReason.forEach((one1) => {
                let filterReason = ReasonArr.filter(obj => obj.Reason == one1.QualityReason)
                if (filterReason.length > 0) {
                    returnArr.push({ ...filterReason[0] })
                } else {
                    let obj = { Reason: one1.QualityReason, Count: 0 }
                    returnArr.push(obj)
                }
            })
        }

        return returnArr;
    }

    const getAliaName = (Component) => {
        let component = [...compList];
        component = component.filter((one) => one.ComponentId == Component)
        if (component.length > 0) {
            return component[0].Alias
        } else {
            return 'N/A'
        }
    }


    return (
        <table id={id} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0, overflowY: 'scroll' }}>
            <thead className='small text-center border'>
                <tr className='text-center align-self-center table-primary'>
                  { inputData.Periodicity=="Shift"|| inputData.Periodicity=="Daily"?
                  <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Date</th>:inputData.Periodicity=="Weekly"?
                  <><th style={{ verticalAlign: 'middle' }} rowSpan='3'>From Date</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Till Date</th></>:<></>}


                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Periodicity</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Planned Prod Time (mins)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Equipment RunTime</th>
                    <th style={{ verticalAlign: 'middle' }} colSpan={reasons.length}>Downtime Reasons </th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3' >Unplanned Downtime Duration</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3' >Planned Downtime Duration</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Total Downtime Duration</th>
                    <th style={{ verticalAlign: 'middle' }} colSpan={qualityReason.length}>Quality Reasons</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Total Rejection (no. of units)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Ideal Production</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Target Production</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Actual Production</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Availability (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>Performance (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>QualityFactor (%)</th>
                    <th style={{ verticalAlign: 'middle' }} rowSpan='3'>OEE (%)</th>

                </tr>

                <tr className='table-primary' >

                    {reasons.length > 0 ? reasons.map(one => {
                        return <th className="rotate" style={{ verticalAlign: 'middle', height: (100 + one.Reason.length) * 2.5 }} ><div style={{ marginTop: '6.5rem' }}><span>{one.Reason}</span></div></th>
                    }) : ''}

                    {qualityReason.length > 0 ? qualityReason.map(one => {
                        return <th className="rotate" style={{ verticalAlign: 'middle', height: (100 + one.QualityReason.length) * 2.5 }} ><div style={{ marginTop: '6.5rem' }}><span>{one.QualityReason}</span></div></th>
                    }) : ''}

                </tr>
                <tr className='table-primary' >
                    {reasons.length > 0 ? reasons.map(one => {
                        return <>
                            {/* <th  style={{ verticalAlign: 'middle'}}  >From Date</th>
                          <th  style={{ verticalAlign: 'middle'}} >From Time</th>
                          <th  style={{ verticalAlign: 'middle'}}  >Till Date</th>
                          <th  style={{ verticalAlign: 'middle'}} >Till Time</th> */}
                            <th style={{ verticalAlign: 'middle' }}  >Duration (mins)</th>
                            {/* <th  style={{ verticalAlign: 'middle'}}  >Remarks</th> */}
                        </>
                    }) : ''}

                </tr>
            </thead>
            <tbody>
                {data.map(one => {
                    let avila = ((one.EquipmentRunTime / (parseFloat(one.PlannedProdTime) - parseFloat(one.PlannedDowntimeDuration))) * 100).toPrecision(2);
                    let perf = (one.Performance_x_PlannedProdTime / parseFloat(one.PlannedProdTime)).toPrecision(4);
                    let qf = ((one.AcceptedParts / one.PartsProduced) * 100).toPrecision(4)
                    let oee = ((avila * perf * qf) / 10000).toPrecision(4)
                    return <>

                        <tr>
                        { inputData.Periodicity=="Shift"|| inputData.Periodicity=="Daily"?
                  <td rowSpan={getRowSpan(one)}>{one.FromPeriodicity?date.getDateFormat(one.FromPeriodicity):'-'}</td>:inputData.Periodicity=="Weekly"?
                  <><td rowSpan={getRowSpan(one)}>{one.FromPeriodicity?date.getDateFormat(one.FromPeriodicity):'-'}</td>
                    <td rowSpan={getRowSpan(one)}>{one.TillPeriodicity?date.getDateFormat(one.TillPeriodicity):'-'}</td></>:<></>}
                            {/* <td rowSpan={getRowSpan(one)}>{one.FromPeriodicity?date.getDateFormat(one.FromPeriodicity):'-'}</td>
                    <td rowSpan={getRowSpan(one)}>{one.TillPeriodicity?date.getDateFormat(one.TillPeriodicity):'-'}</td> */}
                            <td rowSpan={getRowSpan(one)}>{one.Periodicity ? one.Periodicity : '-'}</td>
                            <td rowSpan={getRowSpan(one)}>{one.PlannedProdTime ? parseFloat(one.PlannedProdTime).toFixed(2) : '0'}</td>
                            <td rowSpan={getRowSpan(one)}>{one.EquipmentRunTime ? parseFloat(one.EquipmentRunTime).toFixed(2) : '0'}</td>
                            {getDowntimeReason(one.Downtime, getRowSpan(one)).length > 0 ? getDowntimeReason(one.Downtime, getRowSpan(one)).map((o, i) => {
                                if (i == 0) {
                                    return o.map((r) => {
                                        return <>
                                            {/* <td>{r.From?date.getDateFormat(r.From):'-'}</td>
                                <td>{r.From?date.getTime(r.From):'-'}</td>
                                <td>{r.Till?date.getDateFormat(r.Till):'-'}</td>
                                <td>{r.Till?date.getTime(r.Till):'-'}</td> */}
                                            <td>{r.Duration ? r.Duration : '0'}</td>
                                            {/* <td>{r.Remarks?r.Remarks:'-'}</td> */}
                                        </>
                                    })

                                }

                            }) : ''}
                            <td rowSpan={getRowSpan(one)}>{one.UnplannedDowntimeDuration ? parseFloat(one.UnplannedDowntimeDuration).toFixed(2) : '0'}</td>
                            <td rowSpan={getRowSpan(one)}>{one.PlannedDowntimeDuration ? parseFloat(one.PlannedDowntimeDuration).toFixed(2) : '0'}</td>
                            <td rowSpan={getRowSpan(one)}>{one.TotalDowntimeDuration ? parseFloat(one.TotalDowntimeDuration).toFixed(2) : '0'}</td>


                            {getQualityReason(one.RejectionReason).length > 0 ? getQualityReason(one.RejectionReason).map(r => {
                                return <td rowSpan={getRowSpan(one)}>{r.Count}</td>
                            }) : <td rowSpan={getRowSpan(one)}>0</td>}
                            <td rowSpan={getRowSpan(one)}>{one.TotalRejection ? one.TotalRejection : 0}</td>
                            <td rowSpan={getRowSpan(one)}>{one.IdealSPM_x_EquipmentRunTime ? one.IdealSPM_x_EquipmentRunTime.toFixed(0) : '0'}</td>
                            <td rowSpan={getRowSpan(one)}>{one.TargetParts ? one.TargetParts : '0'}</td>
                            <td rowSpan={getRowSpan(one)}>{one.AcceptedParts ? one.AcceptedParts : '0'}</td>
                            <td rowSpan={getRowSpan(one)}>{avila ? avila : '0'}</td>
                            <td rowSpan={getRowSpan(one)}>{perf ? perf : '0'}</td>
                            <td rowSpan={getRowSpan(one)}>{qf ? qf : '0'}</td>
                            <td rowSpan={getRowSpan(one)}>{oee ? oee : '0'}</td>


                        </tr>

                        {getDowntimeReason(one.Downtime, getRowSpan(one)).length > 0 ? getDowntimeReason(one.Downtime, getRowSpan(one)).map((o, idx) => {
                            if (idx > 0) {
                                return <tr>
                                    {o.map((r) => {
                                        return <>
                                            {/* <td>{r.From?date.getDateFormat(r.From):'-'}</td>
                                <td>{r.From?date.getTime(r.From):'-'}</td>
                                <td>{r.Till?date.getDateFormat(r.Till):'-'}</td>
                                <td>{r.Till?date.getTime(r.Till):'-'}</td> */}
                                            <td>{r.Duration ? r.Duration : '0'}</td>
                                            {/* <td>{r.Remarks?r.Remarks:'-'}</td> */}
                                        </>
                                    })}

                                </tr>


                            }

                        }) : ''}
                    </>
                })}
            </tbody>
        </table>
    );
}

export default DetailedProdAnalysisPlantTbl;