import React from 'react';
import Chart from 'react-google-charts';
const ScatterChart = ({data,colors,id,titlev}) => {
  
    return ( 
<Chart
  width={'100%'}
  height={'400px'}
  chartType="ScatterChart"
  loader={<div>Loading Chart</div>}
  data={data}
  options={{
    series:colors,
    // Material design options
    chart: {
        
      title: "Students' Final Grades",
      subtitle: 'based on hours studied',
    },
    hAxis: { title: id=='activity'?'Shift Time(hh:mm)':'No of Machines Affected' },
    vAxis: { title: id=='activity'?'Time Duration(In Secends)':`${titlev}` },
    legend:'',
    
  }}
  rootProps={{ 'data-testid': '3' }}
/>

     );
}
 
export default ScatterChart;