import React, { Component } from 'react';
import '../../src/utill/multiRadialChart.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';
const DEFAULT_COLOR = '#040404';
class MultiRadialChart extends Component {
    state = {array:[1,2,3],

    }
    componentDidMount() {
        // For initial animation
        setTimeout(() => {
            this.setState({ setStrokeLength: true });
        });
    }
    render() {
        const { setStrokeLength } = this.state;
        const {
            className,
            radius,
            progress,
            progress1,
            strokeWidth,
            dimension,
            
            color
        } = this.props;

        const circleRadius0 = Math.min(radius, 85);
        const circumference0 = 2.5 * 3.14 * circleRadius0;
        const strokeLength0 = setStrokeLength ? circumference0 / 100 * progress : 0;
        
        const circleRadius = Math.min(radius, 70);
        const circumference = 2.0 * 3.14 * circleRadius;
        const strokeLength = setStrokeLength ? circumference / 100 * progress : 0;

        const circleRadius1 = Math.min(radius, 55);
        const circumference1 = 1.5 * 3.14 * circleRadius1;
        const strokeLength1 = setStrokeLength ? circumference1 / 100 * progress : 0;

        const circleRadius2 = Math.min(radius, 40);
        const circumference2 = 1 * 3.14 * circleRadius2;
        const strokeLength2 = setStrokeLength ? circumference2 / 100 * progress : 0;
return (
    <React.Fragment>
           <div
                className={classNames('radial-chart', className, {
                    'no-progress': strokeLength === 0
                })}
           >
               <svg viewBox="0 0 180 180" width={dimension} height={dimension}>


               <React.Fragment> <circle
                       className="radial-chart-total"
                       stroke={'skyblue'}
                       strokeWidth={10}
                       fill="none"
                       cx="90"
                       cy="90"
                       r={circleRadius0}
                   />
                   <circle
                       className="radial-chart-progress"
                       stroke={'skyblue'}
                       strokeWidth={10}
                       strokeDasharray={`${strokeLength0},${circumference0}`}
                       strokeLinecap="round"
                       fill="none"
                       cx="90"
                       cy="90"
                       r={circleRadius0}
                   /></React.Fragment>
               
                  
                  
                  <React.Fragment> <circle
                       className="radial-chart-total"
                       stroke={'red'}
                       strokeWidth={10}
                       fill="none"
                       cx="90"
                       cy="90"
                       r={circleRadius}
                   />
                   <circle
                       className="radial-chart-progress"
                       stroke={'red'}
                       strokeWidth={10}
                       strokeDasharray={`${strokeLength},${circumference}`}
                       strokeLinecap="round"
                       fill="none"
                       cx="90"
                       cy="90"
                       r={circleRadius}
                   /></React.Fragment>


                <React.Fragment> 
                <circle
                       className="radial-chart-total"
                       stroke={'green'}
                       strokeWidth={10}
                       fill="none"
                       cx="90"
                       cy="90"
                       r={circleRadius1}
                   />
                   <circle
                       className="radial-chart-progress"
                       stroke={'green'}
                       strokeWidth={10}
                       strokeDasharray={`${strokeLength1},${circumference1}`}
                       strokeLinecap="round"
                       fill="none"
                       cx="90"
                       cy="90"
                       r={circleRadius1}
                   /></React.Fragment>

            <React.Fragment> 

            <circle
                       className="radial-chart-total"
                       stroke={'blue'}
                       strokeWidth={10}
                       fill="none"
                       cx="90"
                       cy="90"
                       r={circleRadius2}
                   />
                   <circle
                       className="radial-chart-progress"
                       
                       stroke={'blue'}
                       strokeWidth={10}
                       strokeDasharray={`${strokeLength2},${circumference2}`}
                       strokeLinecap="round"
                       fill="none"
                       cx="90"
                       cy="90"
                       r={circleRadius2}
                   /></React.Fragment>

                   <React.Fragment>
                   <text x="78" y="80" fill="red">33%</text>
                   <text x="78" y="100" fill="green">20%</text>
                   <text x="78" y="120" fill="orange">30%</text>
                   </React.Fragment>
                
               </svg>
               
               
           </div>
           
           </React.Fragment>
        );
    }
}
MultiRadialChart.defaultProps = {
    radius: 85,
    progress: 100,
    progress1:100,
    strokeWidth: 10,
    dimension: 150,
    color: DEFAULT_COLOR
};
MultiRadialChart.propTypes = {
    className: PropTypes.string,
    radius: PropTypes.number,
    strokeWidth: PropTypes.number,
    color: PropTypes.string,
    progress: PropTypes.number,
    progress1: PropTypes.number,
    dimension: PropTypes.number
};
export default MultiRadialChart;
