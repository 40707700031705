import React, { Component } from "react";
import swal from "sweetalert";
import endPoint from "../../../config";
import axios from "../../../httpServices/httpService";
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from "../../../utill/LoadingComponent";
import MultiSelect from "../../../Common/multiselect";
import { ToastContainer, toast } from 'react-toastify';

class ToolMaster extends Component {
  state = {
    toolMaster: {PartOfFaimly:false},
    oldObj: {},
    equipmentTypeList: [],
    errors:{},
    querySuccess:false,
    cycleTimeRadioState:1,
  };

  schema={
    ToolId:joi.string().required().label('Tool Id'),
    EquipmentType:joi.string().required().label('Equipment Type'),
    //ScrapWeightCycle:joi.number().required().min(0).label('Scrap Weigth Cycle'),
    IdealCycleTime:joi.number().required().min(0),
    PartsPerCycle:joi.number().required().min(0),
    PartOfFaimly:joi.boolean()
    // CyclePerParts:joi.number().min(0),
    // PartsPreventiveMaintenance:joi.number().min(0).required().label('PartsPreventiveMaintenance'),
    // PartsSinceLastPreventiveMaintenance:joi.number().min(0).required().label('PartsSinceLastPreventiveMaintenance'),
    // LastPreventiveMaintenanceDate:joi.date().required().label('LastPreventiveMaintenanceDate'),
    // MaxDaysPreventiveMaintenance:joi.number().min(0).required().label('MaxDaysPreventiveMaintenance'),

  }


  componentDidMount() {
    this.setState({querySuccess:true});
    axios
      .get(endPoint.api + "equipmenttype")
      .then(result => {
        this.setState({ equipmentTypeList: result.data,querySuccess:false });
      })
      .catch(err => {
        this.setState({querySuccess:false});
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });

    if (this.props.actionStatus === "add") {
      this.setState({ toolMaster: {} });
    }
    if (this.props.actionStatus === "update") {
      const toolMaster = { ...this.props.toolMasterData };
      let radioState;
      if(toolMaster.CyclePerParts)
      radioState=0;
      
      if(toolMaster.PartsPerCycle)
      radioState=1;
      // // consol(toolMaster)
      this.setState({
        toolMaster: this.props.toolMasterData,
        oldObj: toolMaster,
        cycleTimeRadioState:radioState
      });
    }
  }

  getDate = datetime => {
    if (datetime) {
      let date = new Date(datetime);
      return (
        date.getFullYear() +
        "-" +
        ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
        "-" +
        ((date.getDate() < 10 ? "0" : "") + date.getDate())
      );
    }
  };

  handleOnChange = e => {
    const { toolMaster,errors } = { ...this.state };
    const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];
    if(this.state.cycleTimeRadioState===1)
    {
      delete toolMaster['CyclePerParts']
      toolMaster[e.currentTarget.name] = e.currentTarget.value;
    }
    if(this.state.cycleTimeRadioState===0)
    {
      delete toolMaster['PartsPerCycle']
      toolMaster[e.currentTarget.name] = e.currentTarget.value;
    }

    if(e.currentTarget.name=='PartOfFaimly'){
      toolMaster[e.currentTarget.name] = e.currentTarget.checked;
    }
    //toolMaster[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ toolMaster,errors:errors||{} });
    // // consol(toolMaster);
  };
  handleSubmit = data => {
   

  
      if (this.props.actionStatus === "add") {
        const errors = valid.validate(data,this.schema);
        // // consol(errors)
        this.setState({ errors: errors || {} });
        if (errors) return;

        this.setState({querySuccess:true});
        axios
          .post(endPoint.api + "tool", data)
          .then(result => {
            if (result.status===200) {
              this.setState({querySuccess:false});
              this.props.history.push("/setting/toolmaster/list");
              swal("Save Data Successfully");
            } else {
              this.setState({querySuccess:false});
              swal(`Data Not Saved`);
            }
          })
          .catch(err => {
            this.setState({querySuccess:false});
            swal(`${err.response.data.message}`);
          });
      }
      if (this.props.actionStatus === "update") {
        this.setState({querySuccess:true});
        axios
          .patch(endPoint.api + "tool/"+data._id, {
            old: this.state.oldObj,
            new: data
          })
          .then(result => {
            const { n, nModified, ok } = result.data;
            if (ok == 1 && n == 1 && nModified == 1) {
              this.setState({querySuccess:false});
              this.props.history.push("/setting/toolmaster/list");
              swal("Update Data Successfully");
            } else {
              this.setState({querySuccess:false});
              swal(`${result.data}`);
            }
          })
          .catch(err => {
            this.setState({querySuccess:false});
            swal(`${err.response.data.message}`);

          });
      }
  
  };
  handleRadioChange = (e) => {
    //const { component } = { ...this.state };
    //const { Operation } = { ...this.state };
    const { name, value } = e.currentTarget;

    
    if (name === "CycleTime") {
      this.setState({
        [name]: value === "PPC" ? 1 : 0,
        cycleTimeRadioState: value === "CPP" ? 0 : 1
      });
    }
  };

  render() {
    return (
      <React.Fragment>    <ToastContainer/>
      {this.state.querySuccess?<Loading/>:''}
      
        <div className=" border">
          <div className="row justify-content-center">
            <div className="col-sm-4 ">
              <div className="form-group text-center">
                <label>ToolId</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Tool Id"
                  name="ToolId"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.toolMaster.ToolId}
                />
                {this.state.errors.ToolId && <div className="alert small alert-danger">{this.state.errors['ToolId']}</div>}
              </div>
            </div>

            <div className="col-sm-4 ">
            <div className="form-group text-center">
                <label>Equipment Type</label>
                {/* <select
                  type="text"
                  className="form-control"
                  name="EquipmentType"
                  onChange={this.handleOnChange}
                  value={this.state.toolMaster.EquipmentType}
                >
                  <option>--select equipment type--</option>
                  {this.state.equipmentTypeList.map((one,i) => {
                    return <option key={i}>{one.EquipmentType}</option>;
                  })}
                </select> */}
                    <MultiSelect name="EquipmentType" selected={this.state.toolMaster['EquipmentType']} handleOnChange={this.handleOnChange} masterName="EquipmentType" objLevel={1} all={this.state.equipmentTypeList}/>

                {this.state.errors.EquipmentType && <div className="alert small alert-danger">{this.state.errors['EquipmentType']}</div>}
              </div>
            </div>

            <div className="col-sm-4 ">
              <div className="form-group text-center">
                <label>Ideal Time Per Cycle(In Sec)</label>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  placeholder="Ideal Time Per Cycle"
                  name="IdealCycleTime"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.toolMaster.IdealCycleTime}
                />
                {this.state.errors.IdealCycleTime && <div className="alert small alert-danger">{this.state.errors['IdealCycleTime']}</div>}
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="form-group ">
                <label>Parts Per Cycle</label>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  placeholder="Parts Per Cycle"
                  name="PartsPerCycle"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.toolMaster.PartsPerCycle}
                />
                {this.state.errors.PartsPerCycle && <div className="alert small alert-danger">{this.state.errors['PartsPerCycle']}</div>}
              </div>
            </div>
            <div className="col-sm-4 text-center mt-4">
            <label>Part Of Family &nbsp;&nbsp;&nbsp;</label>
                <input
                checked={this.state.toolMaster.PartOfFaimly}
                  type="checkbox"
                  name="PartOfFaimly"
                  onChange={this.handleOnChange}
                />
               
            </div>

           
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.handleSubmit(this.state.toolMaster);
              }}
            >
              Submit
            </button>
          </div>
          <div className="text-center" style={{ marginTop: 10 }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.history.push("/setting/toolmaster/list");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ToolMaster;
