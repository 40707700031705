import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import { generateExcelFile } from '../utill/download';
import Select from 'react-select';
import MultiSelect from '../Common/multiselect';
import { ToastContainer, toast } from 'react-toastify';

const ToolRun = ({ user,fromDateLimit,storeDataInLocalStorage }) => {
    const [machineData, setMachineData] = useState([])
    const [inputData, setInputData] = useState({Tool:[]})
    const [errors, setErrors] = useState({})
    const [querySuccess, setQuerySuccess] = useState(false);
    const [toolList, setToolList] = useState([]);


    // useEffect(() => {
    //     (async () => {
    //         const res = await axios.get(
    //             api.api + 'tool'
    //         );
    //         res.data.unshift({ToolId:'All',_id:'All'})
    //         setToolList(res.data);
    //     })();
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
          try {
            // const result = await axios.get(api.api1 + 'component');
     let result = await storeDataInLocalStorage('tool')
     result.unshift({ToolId:'All',_id:'All'})
            setToolList(result);
          } catch (error) {
            // console.error("Error fetching data:", error);
          }
        };
      
        fetchData();
      }, []);

    const schema = {
        From: joi.date().required().label('From Datetime'),
        Till: joi.date().required().label('Till Datetime'),
        Tool: joi.array().min(1).required().label('Tool'),

    }

    const sortColumn = { path: 'Date', order: 'asc' }
    const columns = [
        { path: 'Date', label: 'Date', content: (obj) => <label>{dateTime.getDateFormat(obj.From)}</label> },
        { path: 'Shift', label: 'Shift' },
        { path: 'From', label: 'From',content: (obj) => <label>{dateTime.getTime(obj.From)}</label> },
        { path: 'Till', label: 'Till',content: (obj) => <label>{dateTime.getTime(obj.Till)}</label> },
        { path: 'EquipmentGroup', label: 'EquipmentGroup' },
        { path: 'Equipment', label: 'Equipment' },
        { path: 'Tool', label: 'Tool', content: (obj) => <label>{obj.Tool == 'null' || obj.Tool == null ? '-' : obj.Tool}</label> },
        { path: 'CyclesRun', label: 'CyclesRun' },
        { path: 'PartsProduced', label: 'PartsProduced' },
    ]



    const handleOnChange = (e) => {

        if (e.currentTarget.type === 'date') {
            // consol(new Date(e.currentTarget.value))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);

            setInputData(inputData);
        }
        else if (e.currentTarget.type === 'time') {
            let Time = e.currentTarget.value.split(':');
            // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
            setInputData(inputData);
        }
        else if (e.currentTarget.name == 'Tool') {
            inputData.Tool.splice(0,inputData.Tool.length)
            if(e.currentTarget.value){
                e.currentTarget.value.forEach((tool)=>{
                    if(!inputData.Tool.includes(tool.label)){
                        inputData[e.currentTarget.name].push(tool.label) ;
                    }
                })
                if(inputData.Tool.length>1){
                    inputData.Tool=inputData.Tool.filter(one=>one!=='All');
                }
            }
            setInputData(inputData);
        }
        setErrors({})

    }
    const handleOnSubmit = (inputData1) => {
        const errors = validation.validate(inputData1, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setQuerySuccess(true)
        // const user = auth.getCurrentUser();
        if(new Date(inputData1.From) <= new Date(inputData1.Till)){
           
           }else{
	    setQuerySuccess(false);
            return  toast.success('From date should be Less Than Till date', {
              position: 'bottom-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: '#babbbb', // Set your desired background color
                color: 'white',     // Set your desired text color
              },
            });
          }
        inputData1.From.setHours(0, 0, 0);
        inputData1.Till.setHours(23, 59, 59);
        axios.post(api.api + 'ToolRun', inputData1).then((result) => {
            // consol(result.data)
            if (result.data.length > 0) {
                setMachineData(result.data)
                setQuerySuccess(false)
            }
            else {
                swal('Data Not Available');
                setQuerySuccess(false)
            }
        })
            .catch((err) => {
                  toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                setQuerySuccess(false)
                // consol(err)
            })
    }

    const handleDownloadExcel = (e) => {
        generateExcelFile(e, new Date())
    }



    return (
        <React.Fragment>
            {querySuccess ? <Loading /> : ''}
            <div className='text-center' >
                <p >Tool Run</p>
            </div>
            <ToastContainer/>
            <div className="row">

                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>From</label>
                        <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
                        {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}

                    </div>
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
                </div>
                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Till</label>
                        <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                        {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}

                    </div>
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='Till' />
          </div> */}
                </div>
                <div className="col-sm-5 mt-3">
                    <MultiSelect name='Tool' isMulti={true} objLevel={1} masterName='ToolId' all={toolList} handleOnChange={handleOnChange} selected={inputData['Tool']} />

                </div>


                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>
                {machineData.length > 0 ? <div className="col-sm-2">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownloadExcel("ToolRun")}>Download Excel</button>
                </div> : ''}

            </div>
            {machineData.length > 0 ? <div>
                <Table id="ToolRun" columns={columns} sortColumn={sortColumn} data={machineData} />
            </div> : ''}
        </React.Fragment>


    );
}

export default ToolRun;