import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlphaDown, faSortDown, faSortUp, faSortAmountDown, faSortAmountUp, faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';

class TableHeader extends Component {
    raiseSort = path => {
      if (!path) return;
        const sortColumn = { ...this.props.sortColumn };
        if (sortColumn.path === path)
          sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
        else {
          sortColumn.path = path;
          sortColumn.order = "asc";
        }
        this.props.onSort(sortColumn);
      };
    
      renderSortIcon = column => {
        const { sortColumn } = this.props;
        if (!column.path) return null;
        if (column.path !== sortColumn.path) return null;
        if (sortColumn.order === "asc") return <i >  <FontAwesomeIcon icon={faSortAlphaDown} size='1x'/></i>;
        return <i >  <FontAwesomeIcon icon={faSortAlphaUp} size='1x'/></i>;
      };
  
  
    render() { 
        return ( <thead  className='small '  >
            <tr className='table-primary text-center common-anchor '>
            {this.props.columns.map(column =><th className="clickable sticky-class"  key={column.path || column.key} onClick={()=>this.raiseSort(column.path)}>{column.label}{this.renderSortIcon(column)}</th>)}

                {/* {this.props.columns.map(column =><th style={this.props.id=='BenchMark'?{verticalAlign:'middle',height:250}:{}} className={this.props.id=='BenchMark'?"rotate clickable ":"clickable"}  key={column.path || column.key} onClick={()=>this.raiseSort(column.path)}><div className={this.props.id=='BenchMark'?'mt-1':''} ><span>{column.label}{this.renderSortIcon(column)}</span></div></th>)} */}
            </tr>
        </thead> );
    }
}
 
export default TableHeader;