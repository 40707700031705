import React, { Component } from 'react';
import { Chart } from 'react-google-charts';

class ParetoChart extends Component {
    state = { 

      downTimeParetoData:"",
      data: [
        [ 'Date', 'Time( in minutes)',{role:'style'}, 'Percentages' ],
    [ 'B', 125,'red',23.0],
    [ 'C', 35,'orange' ,23.0],
    [ 'D', 10,'red',23.0 ],
    [ 'E', 12,'yellow',23.0 ],
    [ 'F', 70 ,'red',23.0],
    [ 'G', 60,'green' ,23.0]
       
      ],
     }


     organiseParetoData=()=>
     {

      let downTimeParetoArray=[];
      let schema=[ 'State', 'Time( in minutes)',{role:'style'},'Percentage(%)' ];
      downTimeParetoArray.push(schema);

      // let sum=0;
      // for(let i=0;i<this.props.downTimeParetoData.length;i++)
      // {
      //   sum=sum+this.props.downTimeParetoData[i].Duration;
       
      // }

    //  // consol(this.props.downTimeParetoData);
      
     
    let accumPer=0;
      for(let i=0;i<this.props.downTimeParetoData.length;i++)
      {
        let StateArray=[];
        if(this.props.downTimeParetoData[i].Reason !== "OK"){
       StateArray.push(this.props.downTimeParetoData[i].Reason);
       StateArray.push(this.props.downTimeParetoData[i].Duration);
      StateArray.push(this.props.downTimeParetoData[i].Color.toLowerCase());
      StateArray.push(this.props.downTimeParetoData[i].Percentage);
      
      // accumPer= accumPer+(this.props.downTimeParetoData[i].Duration/(60*1000))/sum*100;
      // StateArray.push(accumPer);
      downTimeParetoArray.push(StateArray);
    }
       
      }

       return downTimeParetoArray;
     }
    render() { 
      // // consol(this.props.downTimeParetoData);
      if(typeof this.props.downTimeParetoData===typeof "")
      return <h3>{this.props.downTimeParetoData}</h3>;
      
        return ( 

            <Chart
  width={'1000px'}
  height={'500px'}
  chartType="ComboChart"
  loader={<div>Loading Chart</div>}
  data={this.organiseParetoData()}
  options={{
    backgroundColor: {strokeWidth: 2 },
    title: this.props.downTimeParetoText,
    vAxes:[
      {  title: 'Time( in minutes)',minValue:0 }, 
      { title: " Cumulative Percentage", minValue: 0, maxValue: 100 }
    ],
   
    hAxis: { title: 'States' ,slantedTextAngle:90 },
    seriesType: 'bars',
    series: {1: {type: "line", targetAxisIndex: 1 }},
    
   

   
  }}
  rootProps={{ 'data-testid': '1' }}
/>


         );
    }
}
 
export default ParetoChart;


