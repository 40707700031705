import React, { Component } from "react";
import Table from "../../../Common/table";
import axios from "../../../httpServices/httpService";
import api from "../../../config";
import swal from "sweetalert";
import _ from 'lodash';
import Loading from "../../../utill/LoadingComponent";
import { ToastContainer, toast } from 'react-toastify';

class Downtime extends Component {
  state = {
    downTimeList: [
      {
        Machine: "MC1",
        From: "12-1-2",
        Till: "12-1-2",
        ReasonGroup: ["ReasonG1", "ReasonG2"],
        Reason: ["Reason1", "Reason2"]
      }
    ],
    equipmentList: [],
    equipmentGroupList: [],
    equipmentTypeList: [],
    selectedEquipmentGroup: "",
    selectedEquipmentType: "",
    selectedEquipment: "",
    reasonGroupList: [],
    reasonList: [],
    oldObj: {},
    newObj: {},
    querySuccess: false,
    sortColumn: { path: 'Machine', order: 'asc' }
  };

  handleSort = sortColumn => {
    // consol(sortColumn);
    if (this.state.downTimeList.length > 0) {
      const sorted = _.orderBy(this.state.downTimeList, [sortColumn.path], [sortColumn.order]);
      this.setState({ sortColumn, downTimeList: sorted });
    }

  }


  componentDidMount() {
    this.setState({ querySuccess: true })
    axios
      .get(api.api + "equipmentgroup",{headers:{rights:this.props.dataRightsKey}})
      .then(result => {
        if (typeof result.data === typeof []) {
          // consol(result.data);
          this.setState({ equipmentGroupList: result.data, querySuccess: false });
        } else {
          this.setState({ querySuccess: false })
          swal(`${result.data}`);
        }
      })
      .catch(err => {
        this.setState({ querySuccess: false })
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });

    this.setState({ querySuccess: true })
    axios
      .get(api.api + "equipmenttype",{headers:{rights:this.props.dataRightsKey}})
      .then(result => {
        if (typeof result.data === typeof []) {
          // consol(result.data);
          this.setState({ equipmentTypeList: result.data, querySuccess: false });
        } else {
          this.setState({ querySuccess: false })
          swal(`${result.data}`);
        }
      })
      .catch(err => {
        this.setState({ querySuccess: false })
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });

    this.setState({ querySuccess: true })
    axios
      .get(api.api + "downtimegroup",{headers:{rights:this.props.dataRightsKey}})
      .then(result => {
        if (typeof result.data === typeof []) {
          // consol(result.data);
          this.setState({ reasonGroupList: result.data, querySuccess: false });
        } else {
          this.setState({ querySuccess: false })
          swal(`${result.data}`);
        }
      })
      .catch(err => {
        this.setState({ querySuccess: false })
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });

    this.setState({ querySuccess: true })
    axios
      .get(api.api + "downtimereason",{headers:{rights:this.props.dataRightsKey}})
      .then(result => {
        if (typeof result.data === typeof []) {
          // consol(result.data);
          this.setState({ reasonList: result.data, querySuccess: false });
        } else {
          this.setState({ querySuccess: false })
          swal(`${result.data}`);
        }
      })
      .catch(err => {
        this.setState({ querySuccess: false })
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
  }

  handleOnChange = e => {
    var { newObj } = { ...this.state }
    // consol(JSON.parse(e.currentTarget.id))
    newObj = { ...newObj, ...JSON.parse(e.currentTarget.id) }
    newObj[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ newObj });
    // consol(newObj)
  };


  handleSubmit = (newObj, oldObj) => {
    this.setState({ querySuccess: true })
    axios
      .patch(api.api2 + "downtime/"+oldObj._id, { new: newObj, old: oldObj })
      .then(result => {
        // consol(result.data);
        this.setState({ querySuccess: false })
      })
      .catch(err => {
        this.setState({ querySuccess: false })
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
  };

  columns = [
    { path: "Machine", label: "Machine" },
    { path: "From", label: "From" },
    { path: "Till", label: "Till" },
    {
      path: "ReasonGroup",
      label: "Reason Group",
      content: obj => (
        <React.Fragment>
          <div className="form-group">
            <select
              className="form-control form-control-sm "
              name="ReasonGroup"
              id={JSON.stringify(obj)}
              onChange={this.handleOnChange}
            >
              <option>--select reason group--</option>
              {this.state.reasonGroupList.map(one => {

                return <option>{one.DownTimeReasonGroup}</option>;

              })}
            </select>
          </div>
        </React.Fragment>
      )
    },
    {
      path: "Reason",
      label: "Reason",
      content: obj => (
        <React.Fragment>
          <div className="form-group">
            <select
              className="form-control form-control-sm "
              name="Reason"
              id={JSON.stringify(obj)}
              onChange={this.handleOnChange}
            >
              <option>--select reason--</option>
              {this.state.reasonList.map(one => {

                return <option>{one.Reason}</option>;

              })}
            </select>
          </div>
        </React.Fragment>
      )
    },

    {
      key: "Submit",
      content: obj => (
        <React.Fragment>
          <button
            onClick={() => this.handleSubmit(this.state.newObj, obj)}
            className="btn btn-sm btn-primary"
          >
            submit
          </button>
        </React.Fragment>
      )
    }
  ];

  handleOnChangeGroup = e => {
    this.setState({ selectedEquipmentGroup: e.currentTarget.value });
  };

  handleOnChangeType = e => {
    this.setState({ querySuccess: true })
    axios
      .get(api.api1 + "equipment", {
        EquipmentGroup: this.state.selectedEquipmentGroup,
        EquipmentType: e.currentTarget.value
      })
      .then(result => {
        if (typeof result.data === typeof []) {
          // consol(result.data);
          this.setState({
            equipmentList: result.data,
            selectedEquipmentType: e.currentTarget.value,
            querySuccess: false
          });
        } else {
          this.setState({ querySuccess: false })
          swal(`${result.data}`);
        }
      })
      .catch(err => {
        this.setState({ querySuccess: false })
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
  };

  handleOnChangeEquipment = e => {
    this.setState({ querySuccess: true })
    axios
      .get(api.api2 + "downtime", {
        EquipmentGroup: this.state.selectedEquipmentGroup,
        EquipmentType: this.state.selectedEquipmentType,
        Equipment: e.currentTarget.value
      })
      .then(result => {
        if (typeof result.data === typeof []) {
          // consol(result.data);
          this.setState({
            downTimeList: result.data,
            selectedEquipment: e.currentTarget.value,
            querySuccess: false
          });
        } else {
          this.setState({ querySuccess: false })
          swal(`${result.data}`);
        }
      })
      .catch(err => {
        this.setState({ querySuccess: false })
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });
  };
  render() {
    return (
      <React.Fragment>   <ToastContainer/>
        {this.state.querySuccess ? <Loading /> : ''}
        <div className="text-center">
          <p>Downtime Entry</p>
        </div>
        <div className="row text-center">
          <div className="col-sm-3">
            <div className="form-group text-center">
              <label className="small">Equipment Group</label>
              <select
                className="form-control form-control-sm "
                onChange={this.handleOnChangeGroup}
                name="EquipmentGroup"
              >
                <option>--select equipment group--</option>
                {this.state.equipmentGroupList.map(one => {
                  return <option>{one.EquipmentGroup}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group text-center">
              <label className="small">Equipment Type</label>
              <select
                className="form-control form-control-sm "
                onChange={this.handleOnChangeType}
                name="EquipmentType"
              >
                <option>--select equipment type--</option>
                {this.state.equipmentTypeList.map(one => {
                  return <option>{one.EquipmentType}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="form-group text-center">
              <label className="small">Equipment</label>
              <select
                className="form-control form-control-sm "
                onChange={this.handleOnChangeEquipment}
                name="Equipment"
              >
                <option>--select equipment --</option>
                {this.state.equipmentList.map(one => {
                  return <option>{one.EquipmentID}</option>;
                })}
              </select>
            </div>
          </div>
        </div>
        <div>
          {this.state.downTimeList.length < 0 ? (
            "Data Not Available"
          ) : (
              <Table columns={this.columns} sortColumn={this.state.sortColumn} onSort={this.handleSort} data={this.state.downTimeList} />
            )}
        </div>
      </React.Fragment>
    );
  }
}

export default Downtime;
