import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _, { cloneDeep } from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import EquipmentSelection from '../Common/equipmentSelection';
import DetailedProductionTable from '../utill/detailedProductionTable';
import AnalysisDetailedProduction from './AnalysisDetailedProduction';
import '../App.css';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const DetailedProduction = ({ handleDownloadTableToExcel, equips, equipGroups, reasons, qualityReason ,fromDateLimit}) => {
    const [productionData, setProductionData] = useState([])
    const [cacheData, setCacheData] = useState([])
    const [inputData, setInputData] = useState({})
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [equip, SetEquips] = useState([])
    const [errors, setErrors] = useState({});
    const [index, setIndex] = useState(0);
    const [querySuccess, setQuerySuccess] = useState(false);
    const [processEmail, setProcessEmail] = useState(false);
    const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })
    const [analyseState, setAnalyseState] = useState(false)


    const [selectedEquipGroupOption1, setSelectedEquipGroupOption1] = useState([]);
    const [inputData1, setInputData1] = useState({});
    const [inputData2, setInputData2] = useState({});
    const [selectedEquipOption1, setSelectedEquipOption1] = useState([]);
    const [equip1, SetEquips1] = useState([])
    const [shiftOption1, setShiftOption1] = useState([
        { label: 'Shift1', value: 'Shift1', checked: false },
        { label: 'Shift2', value: 'Shift2', checked: false },
        { label: 'Shift3', value: 'Shift3', checked: false },
        { label: 'Shift4', value: 'Shift4', checked: false }])

    const schema = {
        From: joi.date().required().label('From Date'),
        UserId: joi.string(),
        Till: joi.date().required().label('Till Date'),
        Equips: joi.array()
    }

    const GetDailyFrames = (FromDate, TillDate) => {
        let frame = []
        let from = new Date(new Date(FromDate).setHours(0, 0, 0, 0));
        let till = new Date(new Date(TillDate).setHours(0, 0, 0, 0));
        // from.setHours(from.getHours()-5);
        // from.setMinutes(from.getMinutes()-30);

        // till.setHours(till.getHours()-5);
        // till.setMinutes(till.getMinutes()-30);
        while (from <= till) {
            let obj = { From: new Date(from) };
            from.setHours(23, 59, 59, 999)
            obj.Till = new Date(from);
            frame.push(obj);
            from.setDate(from.getDate() + 1)
            from.setHours(0, 0, 0, 0);


        }
        return frame
    }








    const ProductionReportSchema = [
        { path: 'Date', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
        { path: 'Shift', label: 'Shift' },
        { path: 'EquipmentGroup', label: 'EquipmentGroup' },
        { path: 'Equipment', label: 'Equipment' },
        { path: 'Cycles', label: 'Cycles', content: (obj) => <label>{obj.Cycles ? obj.Cycles : '-'}</label> },
        { path: 'AvailableTime', label: 'Runtime', content: (obj) => <label>{obj.AvailableTime ? obj.AvailableTime : '-'}</label> },

    ]

    const handleOnChnageAnalysis = (e) => {
        const data = { ...inputData1 };
        const input2 = { ...inputData2 }
        if (e.currentTarget.name == 'Shift') {
            const options = [...shiftOption1];
            if (e.currentTarget.value && (e.currentTarget.value == 'All')) {
                options.forEach(one => one.checked = e.currentTarget.checked);
            } else {
                options.forEach(one => {
                    if (one.value == e.currentTarget.value) {
                        one.checked = e.currentTarget.checked
                    }
                });
            }
            data[e.currentTarget.name] = options.filter(one => one.checked);
            setShiftOption1(options);
        }
        else {
            if (e.currentTarget.type == 'date') {

                if ((e.currentTarget.name == "From")) {
                    let oldFrom = new Date(inputData.From)
                    oldFrom.setHours(0, 0, 0, 0);
                    let oldFrom1 = new Date(e.currentTarget.value)
                    oldFrom1.setHours(0, 0, 0, 0);
                    if (oldFrom > oldFrom1) {
                        swal("from date can not out of range", { dangerMode: true })
                        return
                    }
                }
                if ((e.currentTarget.name == "Till")) {
                    let oldTill = new Date(inputData.Till)
                    oldTill.setHours(23, 59, 59, 999);
                    let oldTill1 = new Date(e.currentTarget.value)
                    oldTill1.setHours(23, 59, 59, 999);
                    if (oldTill < oldTill1) {
                        swal("till date can not out of range", { dangerMode: true })
                        return
                    }

                }
                data[e.currentTarget.name] = e.currentTarget.value
            } else {
                data[e.currentTarget.name] = e.currentTarget.value
            }

        }

        if (e.currentTarget.name == "ReportType") {
            let obj = {}
            const shifts = [...shiftOption1]
            obj[e.currentTarget.name] = e.currentTarget.value;
            obj["Periodicity"] = input2.Periodicity;
            setInputData1(obj);
            setInputData2(obj);
            shifts.forEach(shift => {
                shift.checked = false
            })
            setShiftOption1(shifts)
            setSelectedEquipOption1([]);
            setSelectedEquipGroupOption1([])
        } else {
            setInputData1(data);
        }

    }
    const handleOnChange = (e) => {
        if (e.currentTarget.type === 'date') {
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
            inputData1[e.currentTarget.name] = new Date(e.currentTarget.value);
        }
        else {
            inputData[e.currentTarget.name] = e.currentTarget.value;
        }
        setInputData(inputData);
        setInputData1(inputData1);
        setErrors({})
    }
    const getEquipsForReport = () => {
        let equipArr = []
        equips.forEach((one) => {
            selectedEquipOption.forEach((option) => {
                if (one._id == option.value) {
                    equipArr.push(one);
                }
            })
        })
        return equipArr;
    }
    const handleOnSubmit = async (inputData) => {
        productionData.splice(0, productionData.length);
        setIndex(0)
        // consol(inputData)
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setProcessEmail(false)
        setQuerySuccess(true)
        const user = auth.getCurrentUser();
        inputData.UserId = user._id

       if(new Date(inputData.From) <= new Date(inputData.Till)){
            
           }else{
	   setQuerySuccess(false);
            return  toast.success('From date should be Less Than Till date', {
              position: 'bottom-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: {
                background: '#babbbb', // Set your desired background color
                color: 'white',     // Set your desired text color
              },
            });
          }
        let equipForReport = getEquipsForReport()
        for (let i = 0; i < equipForReport.length; i++) {
            try {
                let list = GetDailyFrames(new Date(inputData.From), new Date(inputData.Till));
                for (let frame = 0; frame < list.length; frame++) {
                    let obj = { From: list[frame].From, Till: list[frame].Till, Equips: [equipForReport[i]] }
                    let result = await axios.post(api.api + 'DetailedProduction', obj)
                    if (result.data !== null && result.data !== undefined) {
                        if (result.data.length > 0) {

                            result.data.forEach((one) => {
                                productionData.push(one);
                            })
                            setProductionData(productionData)
                            setCacheData([...productionData])
                            let idx = i + 1

                            setIndex(idx)

                        }
                    }
                    else {
                        setQuerySuccess(false)
                    }
                }





            }
            catch (err) {

                if (err.message && err.message == "Network Error") {
                    let alert = await swal({
                        title: "Network Error",
                        text: `You want to restore connection and fetch data from server`,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true
                    })
                    if (alert) {
                        continue;
                    } else {
                        // consol(err)
                        setQuerySuccess(false)
                        break;
                    }

                    // swal({
                    //     title: "Network Error",
                    //     text: `You want  restore connection and  fetch data from server`,
                    //     icon: "warning",
                    //     buttons: true,
                    //     dangerMode: true
                    // }).then(async (willDelete) => {

                    //     // if (willDelete) {
                    //     //     try {

                    //     //         let list = GetDailyFrames(new Date(inputData.From), new Date(inputData.Till));
                    //     //         for (let frame = 0; frame < list.length; frame++) {
                    //     //             let obj = { From: list[frame].From, Till: list[frame].Till, Equips: [equipForReport[i]] }
                    //     //             let result = await axios.post(api.api + 'DetailedProduction', obj)
                    //     //             if (result.data !== null && result.data !== undefined) {
                    //     //                 if (result.data.length > 0) {

                    //     //                     result.data.forEach((one) => {
                    //     //                         productionData.push(one);
                    //     //                     })
                    //     //                     setProductionData(productionData)
                    //     //                     setCacheData([...productionData])
                    //     //                     let idx = i + 1

                    //     //                     setIndex(idx)

                    //     //                 }
                    //     //             }
                    //     //             else {
                    //     //                 setQuerySuccess(false)
                    //     //             }
                    //     //         }





                    //     //     }
                    //     //     catch (err) {
                    //     //         throw err;
                    //     //     }

                    //     // }
                    //     // else {

                    //     //     setQuerySuccess(false)

                    //     // }
                    // });
                } else {
                      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                    // consol(err)
                    setQuerySuccess(false)
                    break;
                }

            }
            // }

        }
        setQuerySuccess(false)


    }

    const handleOnSendEmail = async (inputData) => {
        // productionData.splice(0, productionData.length);
        // setIndex(0)
        // consol(inputData)
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setProcessEmail(true)
        setQuerySuccess(true)
        const user = auth.getCurrentUser();
        inputData.UserId = user._id
        let equipForReport = getEquipsForReport()
        try {
            let obj = { From: new Date(inputData.From),UserId:user._id, Till: new Date(inputData.Till), Equips: equipForReport,SendEmail:true }
            let result = await axios.post(api.api + 'DetailedProductionSendEmail', obj)
            swal(`Email Send Successfully`);
            // setQuerySuccess(false)
        }
        catch (err) {

            if (err.message && err.message == "Network Error") {
                setProcessEmail(false)
                setQuerySuccess(false)
                let alert = await swal({
                    title: "Network Error",
                    text: `You want to restore connection and fetch data from server`,
                    icon: "warning",
                    buttons: true,
                    dangerMode: true
                })
            } else {
                  toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                // consol(err)
                setProcessEmail(false)
                setQuerySuccess(false)

            }

        }
        setProcessEmail(false)
        setQuerySuccess(false)


    }


    const handleSort = (sortColumn) => {
        if (productionData.length > 0) {
            const sorted = _.orderBy(
                productionData,
                [sortColumn.path],
                [sortColumn.order]
            );
            setProductionData(sorted)
            setSortColumn(sortColumn)
        }
    }

    const setSelection = (key, option, equips) => {
        if (key == 'EquipmentGroup') {
            SetEquips(equips ? equips : []);
            setSelectedEquipGroupOption(option)
        }
        if (key == 'Equipment') {

            setSelectedEquipOption(option)
        }
    }

    const gettAnalysisFilterData = (data) => {
        data = _.cloneDeep(cacheData)
        if (data.length > 0) {
            let equips = [...selectedEquipOption1]
            if (inputData2.Periodicity && inputData2.From && inputData2.Till && shiftOption1.some(a => a.checked) && equips.length > 0) {
                data = data.filter((object) => {
                    if (equips.some(a => a.label == object.Equipment)) {
                        return object
                    }
                })
                data = data.filter((object) => {
                    let oldFrom1 = new Date(inputData2.From);
                    oldFrom1.setHours(0, 0, 0, 0);
                    let oldTill1 = new Date(inputData2.Till);
                    oldTill1.setHours(23, 59, 59, 999)
                    if ((oldFrom1 <= new Date(object.From)) && (new Date(object.Till) <= oldTill1)) {
                        return object
                    }
                })
                data = data.filter((object) => {
                    if (shiftOption1.some(a => a.checked && object.Shift == a.value)) {
                        return object;
                    }
                })
                return data;
            } else {
                return []
            }
        }

        return data
    }

    const onApply = () => {
        let obj = { ...inputData1 };
        setInputData2(obj);
    }

    const SelectedDates =(dates)=>{
        setInputData(dates)
      }
    return (
        <React.Fragment>
            {querySuccess ? <Loading labelShow={true} label={processEmail ? 'Please wait while procesing your request' : `${index}/${getEquipsForReport().length}`} /> : ''}
            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Detailed Production
                </h4>
            </div>
      <ToastContainer/>

            <div className="row">
               {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}
                {/* <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>From</label>
                        <input className={'form-control form-control-sm'} min={fromDateLimit} placeholder='dd/mm/yyyy' type='date' onChange={handleOnChange} name='From' />
                        {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Till</label>
                        <input className='form-control form-control-sm' type='date' placeholder='dd/mm/yyyy' onChange={handleOnChange} name='Till' />
                        {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                    </div>
                </div> */}
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />


                {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              <option>All</option>
              <option>Shift1</option>
              <option>Shift2</option>
              <option>Shift3</option>
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div> */}
                {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>
                <div className={productionData.length > 0 ? 'col-sm-1' : 'col-sm-1 d-none'}>
                    <a className='btn btn-sm btn-primary mt-4' onClick={() => handleDownloadTableToExcel('DetailedProduction')} style={{ float: 'right', color: 'white' }}>Download</a>
                </div>
                <div className="col-sm-2">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() =>{
                         swal({
                            title: "Warning",
                            text:'Are you sure you want to send the email?',
                            icon: "warning",
                            buttons: true,
                            
                          })
                          .then((willDelete) => {
                            if (willDelete) {
                                handleOnSendEmail(inputData)
                            } 
                          });
                       
                        }
                         
                         }>Send Email</button>
                </div>
                <div className={productionData.length > 0 ? 'col-sm-1' : 'col-sm-1 d-none'}>
                    <a className='btn btn-sm btn-primary mt-4' onClick={() => {
                        setAnalyseState(true);
                        setInputData1({});
                        setInputData2({});
                    }} style={{ float: 'right', color: 'white' }}>Analyse</a>
                </div>
               


            </div>



            <div>
                <AnalysisDetailedProduction
                    onApply={onApply}
                    handleDownloadTableToExcel={handleDownloadTableToExcel}
                    qualityReason={qualityReason}
                    reasons={reasons}
                    selectedEquipGroupOption={selectedEquipGroupOption1}
                    setSelectedEquipGroupOption={setSelectedEquipGroupOption1}
                    inputData={inputData1}
                    setInputData={setInputData1}
                    equip={equip1}
                    shiftOption={shiftOption1}
                    setShiftOption={setShiftOption1}
                    selectedEquipOption={selectedEquipOption1}
                    setEquips={SetEquips1}
                    setSelectedEquipOption={setSelectedEquipOption1}
                    handleOnChnage={handleOnChnageAnalysis}
                    data={[...gettAnalysisFilterData([...productionData])]}
                    equips={equips} equipGroups={equipGroups}
                    state={analyseState}
                    onHide={() => setAnalyseState(false)} />
                {productionData.length > 0 ?<div className='mt-2 table-responsive'> <DetailedProductionTable  id='DetailedProduction' qualityReason={qualityReason} reasons={reasons} data={productionData} /></div> : <p></p>}
            </div>
        </React.Fragment>
    );
}
export default DetailedProduction;