import React, { Component } from 'react';
import axios from '../../../httpServices/httpService';
import api from '../../../config';
import swal from 'sweetalert';
import httpService from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';


class Consumable extends Component {
    state = {
      equipmentGroupList:[],
      equipmentList:[],
      consumableGroupList:[],
      consumableList:[],
      consumableData:{},  
      FromDate:'',
      FromTime:'',
      errors:{}, 
      querySuccess:false,  
      old:{}      
      }
      schema={
        Consumable:joi.string().required(),
        ConsumableGroup :joi.string().required(),
        Equipment:joi.string().required(),
         EquipmentGroup :joi.string().required(),
         LoadedQty:joi.number().required(),
         FromTime:joi.string().required().label('Loaded Date'),
         FromDate:joi.string().required().label('Loaded Time'),

      }
      schema1={
        Consumable:joi.string().required(),
        ConsumableGroup :joi.string().required(),
        Equipment:joi.string().required(),
         EquipmentGroup :joi.string().required(),
         LoadedQty:joi.number().required(),

         LoadedAt:joi.date().required().label('Loaded Datetime'),
        

      }
      getDate = (datetime) => {
        if (datetime) {
          let date = new Date(datetime);
          return (
            date.getFullYear() +
            "-" +
            ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
            "-" +
            ((date.getDate() < 10 ? "0" : "") + date.getDate())
          );
        }
      };
      getTime = (datetime) => {
        if (datetime) {
          let Time = new Date(datetime);
          return (
            (Time.getHours() < 10 ? "0" : "") +
            Time.getHours() +
            ":" +
            (Time.getMinutes() < 10 ? "0" : "") +
            Time.getMinutes()
          );
        }
      };


      componentDidMount()
      {
        if (this.props.actionStatus === "update") {
    
          const old={...this.props.dataEntryConsumableData}
          this.setState({
            old:old,
            consumableData: this.props.dataEntryConsumableData,
            RunningStatus: this.props.dataEntryConsumableData.Till
              ? "NotRunning"
              : "Running",
          });
        }
        if (this.props.actionStatus === "add") {
          this.setState({ consumableData: { RunningStatus: "Running" } });
        }
        this.setState({querySuccess:true})
        let eqpgrpList=axios.get(api.api+'GetAllEquipmentGroups');
        let compList=axios.get(api.api+'GetAllEquipments');
        let opList=axios.get(api.api+'GetAllConsumableGroups');
        let toolList=axios.get(api.api+'GetAllConsumables');
        Promise.all([eqpgrpList,compList,opList,toolList]).then((result)=>{
          this.setState({querySuccess:false,equipmentGroupList:result[0].data,equipmentList:result[1].data,consumableGroupList:result[2].data,consumableList:result[3].data});
        })
        .catch((err)=>{
          this.setState({querySuccess:false})
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        })
 
      }

      handleOnChange=(e)=>{
      
        const {consumableData,errors}={...this.state};

        const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    if(e.currentTarget.name==='FromDate' || e.currentTarget.name==='FromTime')
    {
      if(e.currentTarget.name==='FromDate')
      {
        consumableData['LoadedAt']=new Date(e.currentTarget.value); 
      }
      if(e.currentTarget.name==='FromTime')
      {
        // consol(e.currentTarget.value)
        let Time=e.currentTarget.value.split(':');
        consumableData['LoadedAt']=new Date(new Date(consumableData.LoadedAt).setHours(Time[0],Time[1]))
      }
    }
         else
         {
          consumableData[e.currentTarget.name]=e.currentTarget.value;
         }
         this.setState({consumableData,errors:errors||{}});
         // consol(consumableData);
     
      
       }

       handleSubmit=(data)=>{
  
        if(this.props.actionStatus==='update')
        {
         
          this.setState({ querySuccess: true });

      axios
        .patch(api.api + "UpdateConsumableSelected", {
          old: this.state.old,
          new: data,
        })
        .then((result) => {
          const { n, nModified, ok } = result.data;
          if (ok == 1 && n == 1 && nModified == 1) {
            this.setState({ querySuccess: false });
            //this.props.history.push("/dataentry/component/view");
            swal("Update Data Successfully");
          } else {
            this.setState({ querySuccess: false });
            swal('Not Updated Some Internal Error');
          }
        })
        .catch((err) => {
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          this.setState({querySuccess: false });
        });
        }
        else
        {
          const errors = valid.validate(data,this.schema1);
          this.setState({ errors: errors || {} });
          if (errors) return;
  
          this.setState({querySuccess:true})
          axios.post(api.api+'AddConsumableSelected',data)
          .then((result)=>{
            this.setState({querySuccess:false})
            swal(`Data Submitted Successfully`);
   
          })
          .catch((err)=>{
            this.setState({querySuccess:false})
              toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          })
        }
    
        
 
      }
  
    render() { 
        return (  
            <div className='border ' >      <ToastContainer/>
            {this.state.querySuccess?<Loading/>:''}
            <div className="row  justify-content-center">
            <div className="col-sm-6 text-center">
            <p>Load Consumable</p>
           <div className="form-group text-center">
            <label className='small'>Equipment Group</label>
            <select
              className="form-control form-control-sm "
              onChange={this.handleOnChange}
              name='EquipmentGroup'
              value={this.state.consumableData.EquipmentGroup}
            >
              <option>--select equipment group--</option>
              {this.state.equipmentGroupList.map(one=>{
                return <option>{one.EquipmentGroup}</option>
              })}
            </select>
            {this.state.errors.EquipmentGroup && <div className="alert small alert-danger">{this.state.errors['EquipmentGroup']}</div>}
 
          </div>
          <div className="form-group text-center">
            <label className='small'>Equipment</label>
            <select
              className="form-control form-control-sm "
              onChange={this.handleOnChange}
              name='Equipment'
              value={this.state.consumableData.Equipment}
              
            >
              <option>--select equipment  --</option>
              {this.state.equipmentList.map(one=>{
                return <option>{one.EquipmentID}</option>
              })}
            </select>
            {this.state.errors.Equipment && <div className="alert small alert-danger">{this.state.errors['Equipment']}</div>}
 
          </div>
          <div className="form-group text-center">
            <label className='small'>Consumable Group</label>
            <select
              className="form-control form-control-sm "
              onChange={this.handleOnChange}
              name='ConsumableGroup'
              value={this.state.consumableData.ConsumableGroup}
            >
              <option>--select consumable group--</option>
              {this.state.consumableGroupList.map(one=>{
                return <option>{one.ConsumableGroup}</option>
              })}
            </select>
            {this.state.errors.ConsumableGroup && <div className="alert small alert-danger">{this.state.errors['ConsumableGroup']}</div>}
 
          </div>
          <div className="form-group text-center">
            <label className='small'>Consumable</label>
            <select
              className="form-control form-control-sm "
              onChange={this.handleOnChange}
              name='Consumable'
              value={this.state.consumableData.Consumable}
              
            >
              <option>--select consumable  --</option>
              {this.state.consumableList.map(one=>{
                return <option>{one.Consumable}</option>
              })}
            </select>
            {this.state.errors.Consumable && <div className="alert small alert-danger">{this.state.errors['Consumable']}</div>}
 
          </div>
         
         
          <div className="form-group text-center">
            <label className='small'>Load Quantity</label>
           <input type='number'  name='LoadedQty' defaultValue={this.state.consumableData.LoadedQty} className='form-control form-control-sm' onChange={this.handleOnChange} placeholder='Load Quantity'/>
           {this.state.errors.LoadedQty && <div className="alert small alert-danger">{this.state.errors['LoadedQty']}</div>}
 
          </div>

          <div className='row'>
          <div className="form-group text-center col-sm-6">
            <label className='small'>Load Date</label>
           <input className='form-control form-control-sm' type='date' defaultValue={this.getDate(this.state.consumableData.LoadedAt)} onChange={this.handleOnChange} name='FromDate'/>
           {this.state.errors.FromDate && <div className="alert small alert-danger">{this.state.errors['FromDate']}</div>}
 
          </div>
          <div className="form-group text-center col-sm-6">
            <label className='small'>Load Time</label>
           <input className='form-control form-control-sm' type='time' defaultValue={this.getTime(this.state.consumableData.LoadedAt)} onChange={this.handleOnChange} name='FromTime'/>
           {this.state.errors.FromTime && <div className="alert small alert-danger">{this.state.errors['FromTime']}</div>}
 
          </div>
          {this.state.errors.LoadedAt && <div className="alert small alert-danger">{this.state.errors['LoadedAt']}</div>}
 
          </div>
          </div>
          </div>
           <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.consumableData);
                  }}
                >
                  Submit
                </button>
              </div>

             
            </div>
         );
    }
}
 
export default Consumable;