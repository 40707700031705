import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import endPoint from "../../../config";
import swal from "sweetalert";
import axios from "../../../httpServices/httpService";
import valid from "../../../utill/validation";
import joi from "joi";
import Loading from "../../../utill/LoadingComponent";
import MultiSelect from "../../../Common/multiselect";
import { ToastContainer, toast } from "react-toastify";
import { Button as Btn, Modal as Mdl, Box, Typography } from "@mui/material";
import UserRights from "../UserRights/UserRight";

class Equipment extends Component {
  state = {
    equipment: {},
    oldObj: {},
    equipmentTypeList: [],
    equipmentGroupList: [],
    deviceList: [],
    deviceChannelList: [
      { DeviceChannel: "1" },
      { DeviceChannel: "2" },
      { DeviceChannel: "3" },
      { DeviceChannel: "4" },
    ],
    deviceConfigList: [
      { DeviceConfig: "ProductionCounter" },
      { DeviceConfig: "ProductionEvent" },
      { DeviceConfig: "ProductionLogger" },
      { DeviceConfig: "ProductionCounterLogger" },
    ],
    errors: {},
    querySuccess: false,
    deviceList: [],
    userList: [],
    setIsModalOpen: false,
    userRightList: [],
    SavedEquipmentData: {},
    Equipments: {},
    AllUserRight:{enable:false,disable:true},
  };
  handleOpenModal = () => this.setState({ setIsModalOpen: true });
  handleCloseModal = () => this.setState({ setIsModalOpen: false });
  handleConfirmAction = (data) => {
    console.log("Action confirmed!");
    axios.put(endPoint.api + "EnableEquipment" ,{ new: data }).then((result) => {
      if (result.status === 200) {
        swal('User Rights Update Successfully');
            this.props.history.push('/setting/equipment/list')

      }
      else {
        swal(`${result.statusText}`);
      }
    })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });


    this.setState({ setIsModalOpen: false });
    this.handleCloseModal();
  };
  // handleSingleRadioChange = (e, Type, mail, name) => {
  //   let updatedUserList = [];
  //   let updatedUserRightList = [];
  
  //   if (Type && Type === 'enable') {
  //     // Updating userRightList
  //     updatedUserRightList = this.state.userRightList.map((user) => {
  //       if (user.email === mail) {
  //         return {
  //           ...user,
  //           enable: false,
  //           disable: true,
  //           equipments: user.equipments.map((one) => {
  //             if (one.data === '6194d37c7a3a7e5af7dada87') {
  //               return { ...one, enable: true, disable: false };
  //             }
  //             return one;
  //           }),
  //         };
  //       }
  //       return user;
  //     });
  
  //     // Updating userList
  //     updatedUserList = this.state.userList.map((user) => {
  //       if (user.email === mail) {
  //         return {
  //           ...user,
  //           enable: true,
  //           disable: false,
  //         };
  //       }
  //       return user;
  //     });
    
     
      
     
  //     console.log('User equipment enable list', updatedUserList);
  //   } else {
  //     // Updating userRightList
  //     updatedUserRightList = this.state.userRightList.map((user) => {
  //       if (user.email === mail) {
  //         return {
  //           ...user,
  //           enable: false,
  //           disable: true,
  //           equipments: user.equipments.map((one) => {
  //             if (one.data === '6194d37c7a3a7e5af7dada87') {
  //               console.log('User equipment enable list', one);
  //               return { ...one, enable: false, disable: true };
  //             }
  //             return one;
  //           }),
  //         };
  //       }
  //       return user;
  //     });
  
  //     // Updating userList
  //     updatedUserList = this.state.userList.map((user) => {
  //       if (user.email === mail) {
  //         return {
  //           ...user,
  //           enable: false,
  //           disable: true,
  //         };
  //       }
  //       return user;
  //     });
    

  //   }
  //   const allEnabled = updatedUserList.every((user) => user.enabled === true);
  //   const allDisabled = updatedUserList.every((user) => user.enabled === false);
    
    
  //   // Set the updated userList and userRightList state
  //   if (allEnabled) {
  //     this.setState({
  //       AllUserRight: { enable: true, disable: false },
  //     });
  //   } else if (allDisabled) {
  //     this.setState({
  //       AllUserRight: { enable: false, disable: true },
  //     });
  //   } else {
  //     this.setState({
  //       AllUserRight: { enable: false, disable: false },
  //     });
  //   }
  //   console.log(allEnabled,allDisabled,"vsibvisdbvs",updatedUserList)

  //   this.setState({ userList: updatedUserList, userRightList: updatedUserRightList }, () => {
  //     console.log('Updated user equipment userList', updatedUserList, updatedUserRightList);
  //   });
  // };
  

   
      // Updating userList
      handleSingleRadioChange = (e, Type, mail, name) => {
        const { userList, userRightList } = this.state;
      
        // Update userRightList
        const updatedUserRightList = userRightList.map((user) => {
          if (user.email === mail) {
            return {
              ...user,
              enable: Type === 'enable',
              disable: Type !== 'enable',
              equipments: user.equipments.map((equipment) => {
                if (equipment.data === '6194d37c7a3a7e5af7dada87') {
                  return { ...equipment, enable: Type === 'enable', disable: Type !== 'enable' };
                }
                return equipment;
              }),
            };
          }
          return user;
        });
      
        // Update userList
        const updatedUserList = userList.map((user) => {
          if (user.email === mail) {
            return { ...user, enable: Type === 'enable', disable: Type !== 'enable' };
          }
          return user;
        });
      
        // Determine overall enable/disable state
        const allEnabled = updatedUserList.every((user) => user.enable === true);
        const allDisabled = updatedUserList.every((user) => user.enable === false);
      
        const AllUserRight = {
          enable: allEnabled,
          disable: allDisabled,
        };
      
        // Update the state with the new values
        this.setState(
          { userList: updatedUserList, userRightList: updatedUserRightList, AllUserRight },
          () => {
            console.log('Updated user equipment lists:', updatedUserList, updatedUserRightList);
            console.log('AllUserRight:', AllUserRight);
          }
        );
      };
      
  
  handleRadioChange = (e,Type) => {
    console.log(
      e.currentTarget.value,
      "current user right",
      this.state.SavedEquipmentData,
      this.state.userRightList
    );
    let userrightlist =[];
    let updatedUserRightList =[];
if(Type && Type ==='disable'){
   updatedUserRightList = this.state.userRightList.map((item) => ({
    ...item,
    enable: false, // Add the new field here
    disable: true, // Add the new field here
    equipments: item.equipments.map((one) => {
      if (one.data === this.state.SavedEquipmentData._id) {
        return { ...one, enable: false, disable: true };
      }
      return one;
    }),
  }));
   userrightlist = this.state.userList.map((data) => ({
    ...data,
    enable: false,
    disable: true,
  }));
  this.setState({
    AllUserRight: {enable:false,disable:true},
  });

}else{
   updatedUserRightList = this.state.userRightList.map((item) => ({
    ...item,
    enable: true, // Add the new field here
    disable: false, // Add the new field here
    equipments: item.equipments.map((one) => {
      if (one.data === this.state.SavedEquipmentData._id) {
        return { ...one, enable: true, disable: false };
      }
      return one;
    }),
  }));
   userrightlist = this.state.userList.map((data) => ({
    ...data,
    enable: true,
    disable: false,
  }));
  this.setState({
    AllUserRight: {enable:true,disable:false},
  });

}
   
    console.log("updated User rights", updatedUserRightList);
    this.setState({
      userRightList: updatedUserRightList,
      userList: userrightlist,
    });
  };

  schema = {
    // PartsPreventiveMaintenance: joi.number().min(0).required().label('PartsPreventiveMaintenance'),
    // PartsSinceLastPreventiveMaintenance: joi.number().min(0).required().label('PartsSinceLastPreventiveMaintenance'),
    // LastPreventiveMaintenanceDate: joi.date().required().label('LastPreventiveMaintenanceDate'),
    // MaxDaysPreventiveMaintenance: joi.number().min(0).required().label('MaxDaysPreventiveMaintenance'),
    DeviceConfig: joi.string().required().label("Device Config"),
    DownTimeThresold: joi.number().required().min(0).label("DownTime Thresold"),
    DeviceChannel: joi.string().required().label("Device Channel"),
    IdealCycleTime: joi.number().required().min(0).label("Ideal Cycle Time"),
    DeviceID: joi.string().required().label("Device ID"),
    EquipmentID: joi.string().required().label("Equipment Id"),
    HourlyRate: joi.string().label("Hourly Rate"),

    EquipmentGroup: joi.string().required().label("Equipment Group"),
    EquipmentType: joi.string().required().label("Equipment Type"),
  };

  componentDidMount() {
    this.setState({ querySuccess: true });
    axios
      .get(endPoint.api + "user")
      .then((result) => {
        console.log(result.data, "testing user rights update");
        const user = result.data.map((data) => ({
          ...data,
          enable: false,
          disable: true,
        }));

        this.setState({ querySuccess: false, userList: user });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: "red", // Set your desired background color
            color: "white", // Set your desired text color
          },
        });
      });
    axios
      .get(endPoint.api + "userrights")
      .then((result) => {
        console.log(result.data, "testing user rights update");

        result.data.map((data) => ({ ...data, enable: false, disable: true }));
        this.setState({ querySuccess: false, userRightList: result.data });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: "red", // Set your desired background color
            color: "white", // Set your desired text color
          },
        });
      });

    axios
      .get(endPoint.api + "equipmentgroup")
      .then((result) => {
        this.setState({ equipmentGroupList: result.data, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: "red", // Set your desired background color
            color: "white", // Set your desired text color
          },
        });
      });
    this.setState({ querySuccess: true });
    axios
      .get(endPoint.api + "equipmenttype")
      .then((result) => {
        this.setState({ equipmentTypeList: result.data, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: "red", // Set your desired background color
            color: "white", // Set your desired text color
          },
        });
      });

    axios
      .get(endPoint.api + "getDeviceList")
      .then((result) => {
        this.setState({ deviceList: result.data, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: "red", // Set your desired background color
            color: "white", // Set your desired text color
          },
        });
      });

    if (this.props.actionStatus === "add") {
      this.setState({ equipment: {} });
    }
    if (this.props.actionStatus === "update") {
      const equipment = { ...this.props.equipmentData };
      // consol(equipment)
      this.setState({ equipment: this.props.equipmentData, oldObj: equipment });
    }
  }

  handleOnChange = (e) => {
    const { equipment, errors } = { ...this.state };
    const errorMessage = valid.validateProperty(e.currentTarget, this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    equipment[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ equipment, errors: errors || {} });
  };

  getDate = (datetime) => {
    if (datetime) {
      let date = new Date(datetime);
      return (
        date.getFullYear() +
        "-" +
        ((date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1)) +
        "-" +
        ((date.getDate() < 10 ? "0" : "") + date.getDate())
      );
    }
  };

  handleSubmit = (data) => {
    if (this.props.actionStatus === "add") {
      const errors = valid.validate(data, this.schema);
      this.setState({ errors: errors || {} });
      if (errors) return;

      this.setState({ querySuccess: true });
      axios
        .post(endPoint.api1 + "equipment", data)
        .then((result) => {
          if (result.status === 200) {
            this.setState({ querySuccess: false });
            console.log(result, "vsjhbvshdbv");
            axios
              .get(endPoint.api1 + "userrights")
              .then((res) => {
                this.setState({
                  SavedEquipmentData: result.data,
                  userRightList: res.data,
                });
              })
              .catch((err) => {});
            // this.props.history.push('/setting/equipment/list')
            swal("Save Data Successfully");
            this.handleOpenModal();
          } else {
            this.setState({ querySuccess: false });
            swal("Data Not saved");
          }
        })
        .catch((err) => {
          // consol(err)
          this.setState({ querySuccess: false });
          swal(`${err.response.data.message}`);
        });
    }
    if (this.props.actionStatus === "update") {
      this.setState({ querySuccess: true });
      axios
        .patch(endPoint.api + "equipment/" + data._id, {
          old: this.state.oldObj,
          new: data,
        })
        .then((result) => {
          const { n, nModified, ok } = result.data;
          if (ok == 1 && n == 1 && nModified == 1) {
            this.setState({ querySuccess: false });
            this.setState({ SavedEquipmentData: data });
            this.handleOpenModal();
            // this.props.history.push('/setting/equipment/list')
            swal("Updated Data Successfully");
          } else {
            this.setState({ querySuccess: false });
            swal(`${result.data}`);
          }
        })
        .catch((err) => {
          this.setState({ querySuccess: false });
          swal(`${err.response.data.message}`);
        });
    }
  };

  render() {
    // consol(this.state.equipment)

    return (
      <React.Fragment>
        {" "}
        <ToastContainer />
        {this.state.querySuccess ? <Loading /> : ""}
        <div className=" border">
          <div className="row">
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>EquipmentType</label>

                {/* <select
                  className="custom-select form-control"
                  name="EquipmentType"
                  onChange={this.handleOnChange}
                  value={this.state.equipment.EquipmentType}

                >
                  <option>--Select Equipment Type--</option>
                  {this.state.equipmentTypeList.map(one => {
                    return <option>{one.EquipmentType}</option>
                  })}
                </select> */}
                <MultiSelect
                  name="EquipmentType"
                  handleOnChange={this.handleOnChange}
                  masterName="EquipmentType"
                  objLevel={1}
                  all={this.state.equipmentTypeList}
                  selected={this.state.equipment["EquipmentType"]}
                />
                {this.state.errors.EquipmentType && (
                  <div className="alert small alert-danger">
                    {this.state.errors["EquipmentType"]}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Equipment Group</label>

                {/* <select
                  className="custom-select form-control"
                  name="EquipmentGroup"
                  onChange={this.handleOnChange}
                  value={this.state.equipment.EquipmentGroup}

                >
                  <option>--Select Equipment Group--</option>
                  {this.state.equipmentGroupList.map(one => {
                    return <option>{one.EquipmentGroup}</option>
                  })}
                </select> */}
                <MultiSelect
                  name="EquipmentGroup"
                  handleOnChange={this.handleOnChange}
                  masterName="EquipmentGroup"
                  objLevel={1}
                  all={this.state.equipmentGroupList}
                  selected={this.state.equipment["EquipmentGroup"]}
                />

                {this.state.errors.EquipmentGroup && (
                  <div className="alert small alert-danger">
                    {this.state.errors["EquipmentGroup"]}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Equipment ID </label>
                <input
                  disabled={this.props.actionStatus == "update" ? true : false}
                  type="text"
                  maxLength="32"
                  className="form-control"
                  placeholder="Equipment Id "
                  name="EquipmentID"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.equipment.EquipmentID}
                />
                {this.state.errors.EquipmentID && (
                  <div className="alert small alert-danger">
                    {this.state.errors["EquipmentID"]}
                  </div>
                )}
              </div>
            </div>

            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Ideal Cyle Time(In Sec)</label>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  placeholder="Ideal Cycle Time"
                  name="IdealCycleTime"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.equipment.IdealCycleTime}
                />
                {this.state.errors.IdealCycleTime && (
                  <div className="alert small alert-danger">
                    {this.state.errors["IdealCycleTime"]}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Device ID</label>

                {/* <select
                  className="custom-select form-control"
                  name="DeviceID"
                  onChange={this.handleOnChange}
                  value={this.state.equipment.DeviceID}

                >
                  <option>--Select Device ID--</option>
                  {this.state.deviceList.map(device => {
                    return <option>{device}</option>
                  })}
                </select> */}
                <MultiSelect
                  name="DeviceID"
                  handleOnChange={this.handleOnChange}
                  masterName="DeviceID"
                  objLevel={0}
                  all={this.state.deviceList}
                  selected={this.state.equipment["DeviceID"]}
                />

                {this.state.errors.DeviceID && (
                  <div className="alert small alert-danger">
                    {this.state.errors["DeviceID"]}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Device Channel</label>

                <select
                  className="custom-select form-control"
                  name="DeviceChannel"
                  onChange={this.handleOnChange}
                  value={this.state.equipment.DeviceChannel}
                >
                  <option>--Select Device Channel--</option>
                  {this.state.deviceChannelList.map((one) => {
                    return <option>{one.DeviceChannel}</option>;
                  })}
                </select>
                {this.state.errors.DeviceChannel && (
                  <div className="alert small alert-danger">
                    {this.state.errors["DeviceChannel"]}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Downtime Thresold(In Sec)</label>
                <input
                  min="0"
                  type="number"
                  className="form-control"
                  placeholder="Enter DownTime Thresold In Minutes"
                  name="DownTimeThresold"
                  onChange={this.handleOnChange}
                  defaultValue={this.state.equipment.DownTimeThresold}
                />
                {this.state.errors.DownTimeThresold && (
                  <div className="alert small alert-danger">
                    {this.state.errors["DownTimeThresold"]}
                  </div>
                )}
              </div>
            </div>
            <div className="col-sm-4">
              <div className="form-group text-center">
                <label>Device Configuration</label>
                <select
                  className="custom-select form-control"
                  name="DeviceConfig"
                  disabled={this.props.actionStatus == "update" ? true : false}
                  onChange={this.handleOnChange}
                  value={this.state.equipment.DeviceConfig}
                >
                  <option>--Select Device Configuration--</option>
                  {this.state.deviceConfigList.map((one) => {
                    return <option>{one.DeviceConfig}</option>;
                  })}
                </select>
                {this.state.errors.DeviceConfig && (
                  <div className="alert small alert-danger">
                    {this.state.errors["DeviceConfig"]}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.handleSubmit(this.state.equipment);
              }}
            >
              Submit
            </button>
          </div>

          <div className="text-center" style={{ marginTop: 10 }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                this.props.history.push("/setting/equipment/list");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <div>
          {/* <Btn
            variant="contained"
            color="primary"
            onClick={() => this.handleOpenModal()}
          >
            Open Confirm Modal
          </Btn> */}
          <Mdl
            open={this.state.setIsModalOpen}
            onConfirm={() => this.handleConfirmAction()}
            aria-labelledby="confirm-modal-title"
            aria-describedby="confirm-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 600, // Increase the width here
                bgcolor: "background.paper",
                border: "2px solid #000",
                boxShadow: 24,
                p: 4,
                textAlign: "center",
              }}
            >
              <Typography id="confirm-modal-title" variant="h6" component="h2">
                Assign Equipment To Users
              </Typography>
              <Typography id="confirm-modal-description" sx={{ mt: 2 }}>
                Are you sure you want to proceed?
              </Typography>
              <Box sx={{ mt: 3 }}>
                {console.log(
                  this.state.userList,
                  "kbhcekhcbe",
                  this.state.userRightList
                )}

                <div className="d-flex">
                  <table className="table  table-bordered table-striped small table-sm table-hover common">
                    <tbody>
                      <tr>
                        <td>Users</td>
                        <td  className="d-flex justify-content-evenly">
                          {" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;Enable All :&nbsp;&nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            checked={this.state.AllUserRight.enable}
                            onChange={(e) => this.handleRadioChange(e,'enable')}
                            name="All"
                            defaultValue="enable"
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Disable All :
                          &nbsp;&nbsp;&nbsp;
                          <input
                            type="radio"
                            checked={this.state.AllUserRight.disable} 
                            name="All"
                            onChange={(e) => this.handleRadioChange(e,'disable')}
                            defaultValue="disable"
                          />
                        </td>
                      </tr>
                      {this.state.userList.length > 0 &&
                        this.state.userList.map((one) => (
                          <tr>
                            {/* {// consol(one.data+'|'+i)} */}

                            <td>{one.name}</td>
                            <td className="d-flex justify-content-evenly">
                              Enable:&nbsp;&nbsp;&nbsp;
                              {console.log(one, one.enable, "testing user")}
                              <input
                                type="radio"
                                onChange={(e) => this.handleSingleRadioChange(e,'enable',one.email,one.name)}
                                checked={one.enable}
                                //  id={selectedUserRight + 'enable'} onChange={(e) => handleRadioChange(e)} name={one.data}
                                //  defaultValue="enable"
                              />
                              &nbsp;&nbsp;&nbsp;Disable:&nbsp;&nbsp;&nbsp;
                              <input
                                type="radio"
                                checked={one.disable}
                                onChange={(e) => this.handleSingleRadioChange(e,'disable',one.email,one.name)}

                                // id={selectedUserRight + 'disable'} onChange={(e) => handleRadioChange(e)} name={one.data}
                                //  defaultValue="disable"
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                <Btn
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleConfirmAction(this.state.userRightList)}
                  sx={{ mr: 2 }}
                >
                  Confirm
                </Btn>
                <Button variant="outlined" onClick={()=>this.handleCloseModal()}>
        Cancel
      </Button>
              </Box>
            </Box>
          </Mdl>
        </div>
      </React.Fragment>
    );
  }
}

export default Equipment;
