import React, { Component } from "react";
import Table from "../../../Common/table";
import api from "../../../config";
import axios from "../../../httpServices/httpService";
import swal from "sweetalert";
import _ from "lodash";
import dateTime from "../../../utill/dateTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEdit,
  faPaperPlane,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../utill/LoadingComponent";
import { Button, Modal } from "react-bootstrap";
import ComponentDataEntry from "./component";
import joi from "joi";
import validation from "../../../utill/validation";
import Paginate from "../../../Common/pagination";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import XLSX from "xlsx";
import valid from "../../../utill/validation";
import ComponentDataEntryByTool from "./componentByTool";
//require("bootstrap/less/bootstrap.less");
import auth from "../../../httpServices/authService";
import { validateHoursToEnterPreviousData } from "../../../utill/common";

class ComponentView extends Component {
  state = {
    compMaster: [],
    equipList: [],
    operatorList: [],
    componentList: [],
    action: "",
    inputData: { From: new Date(Date.now()), Till: new Date(Date.now()) },
    componentData: {},
    oldObj: {},
    newObj: {},
    update: false,
    sortColumn: { path: "EquipmentGroup", order: "asc" },
    querySuccess: false,
    addJobModal: false,
    errors1: {},
    updateObj: {},
    actionStatus: "",
    entryType: { EntryType: "Detail" },

    compList: [],
    jobNumber: {},

    excelData: [],
    arrayProcessIndex: 0,
    errorModal: false,
    errorMessage: "",
    errorFlag: false,
    rowNumber: 0,
    errors: {},
    objKey: "",
    processIndex: 0,
  };

  async componentDidMount() {
    // this.props.Heading('Job Entry')
    let operator = await axios.get(api.api1 + "operator");
    let compList = [];
    if (this.props.compList) {
      compList = this.props.compList;
    }
    // let comp = await axios.get(api.api1 + 'component');
    let equips = await axios.get(api.api1 + "equipment");
    this.setState(
      {
        equipList: this.props.equips,
        operatorList: operator.data,
        equipList: equips.data,
        compList: compList,
      },
      () => {
        this.handleOnSubmit(this.state.inputData);
      }
    );
  }

  schema = {
    From: joi.date().required().label("From Datetime"),
    Till: joi.date().required().label("Till Datetime"),
  };

  handleSort = (sortColumn) => {
    if (this.state.componentList.length > 0) {
      const sorted = _.orderBy(
        this.state.componentList,
        [sortColumn.path],
        [sortColumn.order]
      );
      this.setState({ sortColumn, componentList: sorted });
    }
  };

  handleOnChange = (e) => {
    const { inputData } = { ...this.state };
    inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
    this.setState({ inputData });
  };
  handleOnSubmit = (inputData) => {
    const errors = validation.validate(inputData, this.schema);
    // consol(errors)
    this.setState({ errors1: errors || {} });
    if (errors) return;
    this.setState({ querySuccess: true });
    axios
      .get(api.api1 + "component")
      .then((result) => {
        this.setState({ compMaster: result.data, querySuccess: false });
      })
      .catch((err) => {
        // consol(err);
      });
    this.setState({ querySuccess: true });
    axios
      .put(api.api2 + "jobselected", inputData)
      .then((result) => {
        // consol(result.data)
        this.setState({ componentList: result.data, querySuccess: false });
      })
      .catch((err) => {
        this.setState({ querySuccess: false });

        swal(`${err}`);
      });
  };
  handleOnChangeUpdate = (e) => {
    const { oldObj } = { ...this.state };
    oldObj[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ newObj: oldObj });
    // consol(oldObj,"update data")
  };
  handleOnUpdate = (obj) => {
    this.setState({ update: true, oldObj: obj });
  };
  handleOnRowSubmit = (obj) => {};

  columns = [
    {
      path: "BatchNumber",
      label: "BatchNumber",
      content: (obj) => (
        <React.Fragment>
          {this.state.update ? (
            <div className="form-group ">
              <input
                type="text"
                name="BatchNumber"
                style={{ padding: 0 }}
                onChange={this.handleOnChangeUpdate}
                value={obj.BatchNumber}
                className="form-control form-control-sm"
              />
            </div>
          ) : (
            <label>{obj.BatchNumber || "-"}</label>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "EquipmentGroup",
      label: "EquipmentGroup",
      content: (obj) => (
        <React.Fragment>
          {this.state.update ? (
            <div className="form-group ">
              <input
                type="text"
                name="EquipmentGroup"
                style={{ padding: 0 }}
                onChange={this.handleOnChangeUpdate}
                value={obj.EquipmentGroup}
                className="form-control form-control-sm"
              />
            </div>
          ) : (
            <label>{obj.EquipmentGroup}</label>
          )}
        </React.Fragment>
      ),
    },
    //   { path: 'EquipmentType', label: 'EquipmentType' ,content:(obj)=><React.Fragment>
    //   <div className='form-group ' >
    //     <input type='text' name='EquipmentType' style={{padding:0}} readOnly={this.state.update} onChange={this.handleOnChangeUpdate} value={obj.EquipmentType} className='form-control form-control-sm'/>
    //   </div>
    // </React.Fragment>  },
    {
      path: "Equipment",
      label: "Equipment",
      content: (obj) => (
        <React.Fragment>
          {this.state.update ? (
            <div className="form-group ">
              <input
                type="text"
                name="Equipment"
                style={{ padding: 0 }}
                onChange={this.handleOnChangeUpdate}
                value={obj.Equipment}
                className="form-control form-control-sm"
              />
            </div>
          ) : (
            <label>{obj.Equipment}</label>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "Component",
      label: "Component",
      content: (obj) => (
        <React.Fragment>
          {this.state.update ? (
            <div className="form-group ">
              <input
                type="text"
                name="Component"
                style={{ padding: 0 }}
                onChange={this.handleOnChangeUpdate}
                value={obj.Component}
                className="form-control form-control-sm"
              />
            </div>
          ) : (
            <label>{obj.Component}</label>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "OperationID",
      label: "OperationID",
      content: (obj) => (
        <React.Fragment>
          {this.state.update ? (
            <div className="form-group ">
              <input
                type="text"
                name="OperationID"
                style={{ padding: 0 }}
                onChange={this.handleOnChangeUpdate}
                value={obj.OperationID}
                className="form-control form-control-sm"
              />
            </div>
          ) : (
            <label>{obj.OperationID}</label>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "Operator",
      label: "Operator",
      content: (obj) => (
        <label>
          {obj.Operators &&
            obj.Operators.map((one) => {
              return one.Operator;
            }).join(" , ")}
        </label>
      ),
    },
    {
      path: "OperatorID",
      label: "OperatorID",
      content: (obj) => (
        <label>
          {obj.Operators &&
            obj.Operators.map((one) => {
              return one.OperatorID;
            }).join(" , ")}
        </label>
      ),
    },
    {
      path: "PartsPerCycle",
      label: "PartsPerCycle/Num of Cavity",
      content: (obj) => (
        <label>{obj.PartsPerCycle ? obj.PartsPerCycle : "-"}</label>
      ),
    },
    {
      path: "PartsPerCycleReason",
      label: "Cavity Loss Reason",
      content: (obj) => (
        <label>{obj.PartsPerCycleReason ? obj.PartsPerCycleReason : "-"}</label>
      ),
    },
    {
      path: "CustomerName",
      label: "Customer Name",
      content: (obj) => (
        <label>
          {obj.CustomerName && obj.CustomerName !== "--select cutomer--"
            ? obj.CustomerName
            : "-"}
        </label>
      ),
    },
    {
      key: "ComponentAlias",
      label: "Alias",
      content: (obj) => {
        let component = [...this.state.compMaster];
        component = component.filter((one) => one.ComponentId == obj.Component);
        if (component.length > 0) {
          return <label>{component[0].Alias}</label>;
        } else {
          return <label>N/A</label>;
        }
      },
    },
    {
      path: "TargetQty",
      label: "Target Parts",
      content: (obj) => <label>{obj.TargetQty ? obj.TargetQty : "0"}</label>,
    },
    {
      path: "Remarks",
      label: "Remarks",
      content: (obj) => <label>{obj.Remarks ? obj.Remarks : "-"}</label>,
    },
    {
      path: "ToolID",
      label: "ToolID",
      content: (obj) => (
        <label>{obj.ToolID !== "null" ? obj.ToolID : "-"}</label>
      ),
    },
    {
      path: "JobNumber",
      label: "JobNumber",
      content: (obj) => (
        <label>{obj.JobNumber !== "null" ? obj.JobNumber : "-"}</label>
      ),
    },

    {
      path: "From",
      label: "From",
      content: (obj) => (
        <React.Fragment>
          {this.state.update ? (
            <div className="form-group ">
              <input
                type="date"
                name="From"
                style={{ padding: 0 }}
                onChange={this.handleOnChangeUpdate}
                value={dateTime.getStringOfDateTime(obj.From)}
                className="form-control form-control-sm"
              />
            </div>
          ) : (
            <label>{dateTime.getStringOfDateTime(obj.From)}</label>
          )}
        </React.Fragment>
      ),
    },
    {
      path: "Till",
      label: "Till",
      content: (obj) => (
        <React.Fragment>
          {this.state.update ? (
            <div className="form-group ">
              <input
                type="date"
                name="Till"
                style={{ padding: 0 }}
                onChange={this.handleOnChangeUpdate}
                value={dateTime.getStringOfDateTime(obj.Till)}
                className="form-control form-control-sm"
              />
            </div>
          ) : (
            <label>
              {obj.Till ? dateTime.getStringOfDateTime(obj.Till) : "-"}
            </label>
          )}
        </React.Fragment>
      ),
    },

    {
      path: "LastUpdatedBy",
      label: "Updated By",
      content: (obj) => <label>{obj.LastUpdatedBy || "-"}</label>,
    },
    {
      path: "Created",
      label: "Created At",
      content: (obj) => (
        <label>
          {obj.Created && dateTime.getStringOfDateTime(obj.Created)}
        </label>
      ),
    },
    {
      path: "LastUpdated",
      label: "Last Updated",
      content: (obj) => (
        <label>
          {obj.LastUpdated && dateTime.getStringOfDateTime(obj.LastUpdated)}
        </label>
      ),
    },
    {
      key: "Update",
      content: (obj) => (
        <React.Fragment>
          {!this.state.update ? (
            <button
              className="btn btn-sm btn-primary "
              onClick={() =>
                this.setState({
                  updateObj: obj,
                  addJobModal: true,
                  actionStatus: "update",
                })
              }
            >
              <FontAwesomeIcon icon={faEdit} color={"yellow"} />
            </button>
          ) : (
            <button
              className="btn btn-sm btn-primary "
              onClick={() => {
                this.handleOnRowSubmit(obj);
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} color={"yellow"} />
            </button>
          )}
        </React.Fragment>
      ),
    },
    {
      key: "delete",
      content: (obj) => (
        <button
          disabled={
            !this.props.getRightsForAccess(
              "DynamicDataRights",
              "JobSelection",
              "update"
            )
          }
          className="btn btn-sm btn-primary "
          onClick={() => {
            this.onDelete(obj);
          }}
        >
          <FontAwesomeIcon icon={faTrash} color="red" />
        </button>
      ),
    },
    //     { path: 'Reason', label: 'Reason' ,content: () => <React.Fragment>

    // <div className='form-group'>
    //   <select className='form-control form-control-sm custom-select' name='Reason' onChange={this.handleOnChange}>
    //   <option>--select reason--</option>
    //   {this.state.downTimeList.map(one=>{
    //    return  one.Reason.map(reason=>{
    //       return  <option>{reason}</option>
    //     })
    //   })}
    //   </select>
    // </div>
    //     </React.Fragment> },
  ];

  handleAddJobClick = () => {
    this.setState({
      addJobModal: true,
      actionStatus: "add",
      entryType: { EntryType: "Detail" },
    });
  };

  handleCloseAddJob = () => {
    this.setState({
      addJobModal: false,
      actionStatus: "",
      entryType: { EntryType: "Detail" },
    });
  };

  onDelete = (data) => {
    swal({
      title: "Are you sure?",
      text: "Removing a job will require you to manually manage the qaulity document for this job.Please do accordingly",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        axios
          .delete(api.api2 + "jobselected/" + data._id, { data: data })
          .then((result) => {
            // consol(result.data,"sucessfully deleted")
            if (result.data.ok === 1) {
              let filterData = this.state.componentList.filter(
                (obj) => obj._id !== data._id
              );
              this.setState({ componentList: filterData, querySuccess: false });
            }
          })
          .catch((err) => {
            this.setState({ querySuccess: false });
            swal(`${err.response.data.message}`);
          });
      } else {
        this.setState({ querySuccess: false });
        // swal("Your imaginary file is safe!");
      }
    });
  };

  schema1 = {
    Component: joi.string().required(),
    ToolID: joi.string(),
    Equipment: joi.string().required(),
    EquipmentGroup: joi.string(),
    RunningStatus: joi.string(), //.required(),
    OperationID: joi.string().required(),
    Operators: joi.array(),
    CustomerName: joi.string(),
    TargetQty: joi.number(),
    Remarks: joi.string(),
    PartsPerCycle: joi.number(),
    PartsPerCycleReason: joi.string(),
    JobNumber: joi.string(),
    BatchNumber: joi.string(),
    createMultipleShiftJobData: joi.string(),
    // OperatorEnable: joi.bool().required(),
    // FromTime: joi.string(),//.required(),
    // FromDate: joi.string(),//.required(),
    // OperatorID: joi
    //   .string().required().label('OperatorID'),
    From: joi.date(),
    Till: joi.date(),
    //.when("OperatorEnable", { is: true, then: joi.required() }),
    // TillDate: joi
    //   .string(),
    //   //.when("RunningStatus", { is: "NotRunning", then: joi.required() }),
  };

  handleDownloadFormat = () => {
    axios
      .get(api.api2 + "JobEntryTemplate")
      .then((result) => {
        if (result.status == 200) {
          // const url = window.URL.createObjectURL(new Blob([result.data]));
          let link = document.createElement("a");
          link.setAttribute("href", result.data);
          link.setAttribute("download", "JobEntryFormat.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          swal("Error In Downloading File");
        }
      })
      .catch((err) => {
        toast.success(`${err}`, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: "red", // Set your desired background color
            color: "white", // Set your desired text color
          },
        });
      });
  };

  handleUploadJobDoc = () => {
    let link = document.createElement("input");
    link.setAttribute("type", "file");
    link.setAttribute("name", "JobList");
    // link.setAttribute('onChange', handleOnChangeFile);
    let self = this;
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      e.preventDefault();

      let fr = new FileReader();

      // if (e.currentTarget.files[0].size < 51200 && e.currentTarget.files[0].size > 2000) {
      fr.readAsBinaryString(e.currentTarget.files[0]);
      fr.onloadend = async function (e) {
        const wb = XLSX.read(e.target.result, {
          type: "binary",
          cellDates: true,
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let dataArray = [];
        let keyArr = data[0];
        let arr = data.slice(1, data.length);
        arr.forEach((oneArr) => {
          let obj = {};
          oneArr.forEach((value, i) => {
            if (keyArr[i] == "Equipment") {
              obj[keyArr[i]] = value.toString().trim().toLowerCase();
              if (
                self.state.equipList.filter((one) => one.EquipmentID == value)
                  .length > 0
              ) {
                obj["EquipmentGroup"] = self.state.equipList
                  .filter((one) => one.EquipmentID == value)[0]
                  .EquipmentGroup.trim()
                  .toLowerCase();
              } else {
              }
            } else if (keyArr[i] == "ToolID") {
              obj[keyArr[i]] = value.toString().trim().toLowerCase();
            } else if (keyArr[i] == "Component") {
              obj[keyArr[i]] = value.toString().trim().toLowerCase();
            } else if (keyArr[i] == "OperationID") {
              obj[keyArr[i]] = value.toString().trim().toLowerCase();
            } else if (keyArr[i] == "Remarks") {
              obj[keyArr[i]] = value.toString().trim().toLowerCase();
            } else if (keyArr[i] == "TargetParts") {
              obj["TargetQty"] = value;
            } else if (keyArr[i] == "PartsPerCycle") {
              obj["PartsPerCycle"] = value;
            } else if (keyArr[i] == "BatchNumber") {
              obj["BatchNumber"] = `${value}`;
            } else if (keyArr[i] == "Operator") {
              if (typeof value === "string") {
                value = value.split(",");
                // Handle the parts array as needed
              } else {
                value = [];

                // Handle the case where value is not a string
                console.error("Value is not a string:", value);
              }

              let operators = [];
              value.forEach((v) => {
                if (v !== "") {
                  v = v.trim().toLowerCase();
                  let obj1 = {};
                  let op = self.state.operatorList.filter(
                    (one) => one.Name == v
                  );
                  if (op.length > 0) {
                    obj1["OperatorID"] = op[0].OperatorID;
                    obj1["Name"] = op[0].Name.toLowerCase().trim();
                    obj1["id"] = op[0]._id;
                  } else {
                    obj1["OperatorID"] = value[0];
                    obj1["Name"] = value[0].toLowerCase().trim();
                  }
                  operators.push(obj1);
                }
              });
              obj["Operators"] = operators;
            } else {
              obj[keyArr[i]] = value;
            }
          });

          if (oneArr.length > 0) {
            if (!obj.CustomerName) {
              if (obj.Component) {
                //   let filterComponent=(async ()=>{
                //     let res=await axios.put(api.api1+'component',{ComponentId:obj.Component})
                //     return res.data;
                // })()
                let filterComponent = self.state.compList.filter(
                  (one) => one.ComponentId == obj.Component
                );
                if (filterComponent.length > 0) {
                  obj.CustomerName = filterComponent[0].CustomerName;
                }
              }
            }
          }
          if (oneArr.length > 0) {
            obj.From = new Date(obj.FromDate);
            obj.From.setSeconds(obj.From.getSeconds() + 10);
            let fromTime = new Date(obj.FromTime);
            obj.From.setHours(fromTime.getHours(), fromTime.getMinutes(), 0, 1);
            delete obj.FromDate;
            delete obj.FromTime;

            obj.Till = new Date(obj.TillDate);
            obj.Till.setSeconds(obj.Till.getSeconds() + 10);
            let tillTime = new Date(obj.TillTime);
            obj.Till.setHours(tillTime.getHours(), tillTime.getMinutes());
            delete obj.TillDate;
            delete obj.TillTime;
            dataArray.push(obj);
          }
        });
        dataArray = dataArray.sort(
          (a, b) => new Date(a.From) - new Date(b.From)
        );
        self.setState(
          { excelData: dataArray, inputData: {}, arrayProcessIndex: 0 },
          async () => {
            self.setState({
              inputData: {
                From: dataArray[0].From,
                Till: dataArray[dataArray.length - 1].Till,
              },
            });
            await self.sendDataToServer(
              dataArray,
              dataArray,
              self.state.arrayProcessIndex
            );
          }
        );

        // consol("Data>>>" + dataArray);
      };
      // }
      // else {
      //   swal('File Size Should Be Between 2KB to 50KB');
      // }
    };
    document.body.removeChild(link);
  };

  sendDataToServer = async (EquipsArr, originalDataArray, idx) => {
    this.setState({ querySuccess: true });

    let lastIndex = idx;
    let lastIndex1 = idx;
    for (let index = idx; index < EquipsArr.length; index += 1) {
      try {
        lastIndex = index;
        lastIndex1 = index + 1;
        let error = valid.validate(EquipsArr[index], this.schema1);
        if (error !== null) {
          let row = index + 1;
          this.setState({
            errors: error,
            querySuccess: false,
            errorModal: true,
            rowNumber: row,
          });
          break;
        } else {
          this.setState({ processIndex: index });
          if (
            new Date(EquipsArr[index].From) > new Date(EquipsArr[index].Till)
          ) {
            this.DateTimeValidateOfData(EquipsArr, originalDataArray, index);
            break;
          }

          let res = await axios.post(api.api + "UploadExcelJobData", {
            row: index + 1,
            data: EquipsArr[index],
          });
          await (async () => {
            const result = await axios.put(
              api.api2 + "jobselected",
              this.state.inputData
            );
            // setQuerySuccess(false)
            this.setState({ componentList: result.data });
          })();
          if (res.data == "Insert") {
            toast.success("🦄 Save Job For - " + EquipsArr[index].Equipment, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          // this.setState({ querySuccess: false })
        }
      } catch (err) {
        if (err.response.data) {
          if (err.response.data.code == 409) {
            await this.operatorSharedToEquipment(
              err,
              EquipsArr,
              originalDataArray,
              index
            );
          } else if (err.response.data.code == 11000) {
            this.AbortAndResumeProcessForDuplicate(
              err.response.data.message,
              EquipsArr,
              originalDataArray,
              index
            );
          } else if (err.response.data.code == 511) {
            this.AbortAndResumeProcessForError(
              err,
              EquipsArr,
              originalDataArray,
              index
            );
          } else if (err.response.data.code == 410) {
            this.AbortAndResumeProcessForMultipleShift(
              err,
              EquipsArr,
              originalDataArray,
              index
            );
          } else {
            let message = err.response.data.message;
            // consol(err.response.data.message,"error of message",message.key)
            this.setState({
              querySuccess: true,
              objKey: message,
              errorMessage: message,
              errorFlag: true,
            });
            this.insertIfNotExistDataTypeAndResumeProcess(
              message,
              message,
              message,
              EquipsArr[index][message],
              originalDataArray,
              index
            );
          }
        } else {
          toast.success(`${err}`, {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: "red", // Set your desired background color
              color: "white", // Set your desired text color
            },
          });
        }

        break;
      }
    }
    if (lastIndex1 == originalDataArray.length) {
      setTimeout(() => {
        toast.success("Job Import Process Complete", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }, 1000);
    }
    this.setState({ querySuccess: false });
  };

  operatorSharedToEquipment = async (err, dataArray, originalArr, index) => {
    let row = index + 1;
    swal({
      title:
        "The operator is already assigned for following machines. Do you want to deploy him again?",
      text:
        `${err.response.data.message}  Please correct entry at row number ` +
        row,
      icon: "warning",
      buttons: ["Abort Process", "Shared"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        dataArray[index].Accept = true;
        axios
          .post(api.api2 + "jobselected", dataArray[index])

          .then(async (result) => {
            if (result.status == 200) {
              this.setState({ querySuccess: false });

              toast.success("🦄 Save Job For - " + dataArray[index].Equipment, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              await (async () => {
                const result = await axios.put(
                  api.api2 + "jobselected",
                  this.state.inputData
                );
                // setQuerySuccess(false)
                this.setState({ componentList: result.data });
              })();
              index = index + 1;
              this.sendDataToServer(dataArray, originalArr, index);
            } else {
              this.setState({ querySuccess: false, arrayProcessIndex: index });
              swal("Process  Abort", `${result.data.message}`, {
                dangerMode: true,
              });
            }
          })
          .catch((err) => {
            this.setState({ querySuccess: false, arrayProcessIndex: index });
            swal("Process  Abort", `${err.response.data.message}`, {
              dangerMode: true,
            });
          });
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index });
        swal("Process  Abort", ` ${err.response.data.message}`, {
          dangerMode: true,
        });
      }
    });
  };

  DateTimeValidateOfData = (dataArray, originalArr, index) => {
    let row = index + 1;
    swal({
      title: "Are you sure?",
      text:
        " From Date/Time of job should be less than its Till Date/Time. Please correct entry at Row Number " +
        row,
      icon: "warning",
      buttons: ["Abort Process", "Skip Continue"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        index = index + 1;
        this.sendDataToServer(dataArray, originalArr, index);
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index });
        swal("Process  Abort", { dangerMode: true });

        // swal("Your imaginary file is safe!");
      }
    });
  };

  AbortAndResumeProcessForError = (err, dataArray, originalArr, index) => {
    let row = index + 1;
    swal({
      title: "Are you sure?",
      text:
        `${err.response.data.message}` +
        " Please correct entry at row number " +
        row,
      icon: "warning",
      buttons: ["Abort Process", "Skip Continue"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        index = index + 1;
        this.sendDataToServer(dataArray, originalArr, index);
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index });
        swal("Process  Abort", { dangerMode: true });

        // swal("Your imaginary file is safe!");
      }
    });
  };

  AbortAndResumeProcessForMultipleShift = (
    err,
    dataArray,
    originalArr,
    index
  ) => {
    let row = index + 1;

    swal({
      title: "Job Accross multiple shift",
      text: `${err.response.data.message}`,
      icon: "warning",
      buttons: ["No", "Yes"], // Reversed the order of buttons to have "Yes" as the last button
      dangerMode: true,
    }).then((confirmed) => {
      if (confirmed) {
        this.setState({ querySuccess: true });
        dataArray[index].Accept = true;
        let newata = dataArray[index];
        newata["createMultipleShiftJobData"] = true;
        axios
          .post(api.api2 + "jobselected", dataArray[index])

          .then(async (result) => {
            if (result.status == 200) {
              this.setState({ querySuccess: false });

              toast.success("🦄 Save Job For - " + dataArray[index].Equipment, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              await (async () => {
                const result = await axios.put(
                  api.api2 + "jobselected",
                  this.state.inputData
                );
                // setQuerySuccess(false)
                this.setState({ componentList: result.data });
              })();
              index = index + 1;
              this.sendDataToServer(dataArray, originalArr, index);
            } else {
              this.setState({ querySuccess: false, arrayProcessIndex: index });
              swal("Process  Abort", `${result.data.message}`, {
                dangerMode: true,
              });
            }
          })
          .catch((err) => {
            this.setState({ querySuccess: false, arrayProcessIndex: index });

            swal("Process  Abort", `${err.response.data.message}`, {
              dangerMode: true,
            });
          });
      } else {
        this.setState({ querySuccess: false });
        // consol('User clicked "No" or closed the dialog');
      }
    });

    // swal({
    //   title: "Are you sure?",
    //   text: `${err.response.data.message}` + " Please correct entry at row number " + row,
    //   icon: "warning",
    //   buttons: ["Abort Process", "Skip Continue"],
    //   dangerMode: true
    // }).then(willDelete => {
    //   if (willDelete) {
    //     index = index + 1;
    //     this.sendDataToServer(dataArray, originalArr, index)
    //   } else {
    //     this.setState({ querySuccess: false, arrayProcessIndex: index })
    //     swal('Process  Abort', { dangerMode: true });

    //     // swal("Your imaginary file is safe!");
    //   }
    // });
  };

  AbortAndResumeProcess = (dataArray, originalArr, index) => {
    // let row = index + 1;
    index = index + 1;
    this.sendDataToServer(dataArray, originalArr, index);
    // swal({
    //   title: "Are you sure?",
    //   text: ' This job already exist skip this job!  ' + dataArray[index].Component + ',' + dataArray[index].OperationID + "  at row number " + row,
    //   icon: "warning",
    //   buttons: ["Abort Process", "Skip Continue"],
    //   dangerMode: true
    // }).then(willDelete => {
    //   if (willDelete) {
    //     index = index + 1;
    //     this.sendDataToServer(dataArray, originalArr, index)
    //   } else {
    //     this.setState({ querySuccess: false, arrayProcessIndex: index })
    //     swal('Process  Abort', { dangerMode: true });

    //     // swal("Your imaginary file is safe!");
    //   }
    // });
  };

  AbortAndResumeProcessForDuplicate = (err, dataArray, originalArr, index) => {
    let row = index + 1;
    // index = index + 1;
    // this.sendDataToServer(dataArray, originalArr, index)
    swal({
      title: "Are you sure?",
      text: err + "  Please correct entry  at row number " + row,
      icon: "warning",
      buttons: ["Abort Process", "Skip Continue"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        index = index + 1;
        this.sendDataToServer(dataArray, originalArr, index);
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index });
        swal("Process  Abort", { dangerMode: true });

        // swal("Your imaginary file is safe!");
      }
    });
  };

  insertIfNotExistDataTypeAndResumeProcess = (
    errorMessage,
    url,
    key,
    value,
    dataArray,
    index
  ) => {
    swal({
      title: "Are you sure?",
      text: `${errorMessage}` + "  , Do you want to skip this job ?  ",
      icon: "error",
      buttons: ["Abort Process", "Skip Continue"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ errorFlag: false });
        index = index + 1;
        this.sendDataToServer(dataArray, dataArray, index);
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index });
        swal("Process  Abort");

        // swal("Your imaginary file is safe!");
      }
    });
  };

  handleHideModal = () => {
    this.setState({ errorModal: false });
  };

  handleOnChangeEntryType = (e) => {
    const { entryType } = { ...this.state };
    entryType[e.currentTarget.name] = e.currentTarget.value;
    // consol(entryType[e.currentTarget.name],'on change function')
    this.setState({ entryType });
  };

  render() {
    const { errors1 } = this.state;
    const { getRightsForAccess } = this.props;
    // consol(errors1)

    return (
      <React.Fragment>
        <div className="text-center">
          <p>Job Entry</p>
        </div>
        <ToastContainer />
        <Modal
          show={this.state.errorModal}
          onHide={this.handleHideModal}
          backdrop="static"
          keyboard={false}
          size="sm"
        >
          <Modal.Header closeButton>
            <Modal.Title>Job Validate Error List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(this.state.errors).map((key) => {
              return (
                <p className="text-danger " style={{ fontSize: 11 }}>
                  {this.state.errors[key] +
                    "  " +
                    "Row Number  " +
                    this.state.rowNumber}
                </p>
              );
            })}
          </Modal.Body>
          {/* <Modal.Footer>
                <Button className='bg-primary' variant="secondary" >
                    Save
        </Button>
                <Button variant="secondary" onClick={handleHideModal}>
                    Close
        </Button>

            </Modal.Footer> */}
        </Modal>
        <Modal
          show={this.state.addJobModal}
          onHide={this.handleCloseAddJob}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>New Job Selection Form</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <input
                type="radio"
                name="EntryType"
                value="Detail"
                checked={this.state.entryType.EntryType == "Detail"}
                onChange={this.handleOnChangeEntryType}
              />
              &nbsp;&nbsp;&nbsp; Detail &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <input
                type="radio"
                name="EntryType"
                value="ByTool"
                checked={this.state.entryType.EntryType == "ByTool"}
                onChange={this.handleOnChangeEntryType}
              />
              &nbsp;&nbsp;&nbsp; By Tool
            </div>
            {this.state.entryType.EntryType == "Detail" ? (
              <ComponentDataEntry
                company={this.props.company}
                flag={this.props.flag}
                dataEntryComponentData={this.state.updateObj}
                handleOnSubmit={this.handleOnSubmit}
                inputData={this.state.inputData}
                actionStatus={this.state.actionStatus}
                handleCloseAddJob={this.handleCloseAddJob}
              />
            ) : (
              <ComponentDataEntryByTool
                company={this.props.company}
                flag={this.props.flag}
                dataEntryComponentData={this.state.updateObj}
                handleOnSubmit={this.handleOnSubmit}
                inputData={this.state.inputData}
                actionStatus={this.state.actionStatus}
                handleCloseAddJob={this.handleCloseAddJob}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseAddJob}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.querySuccess ? <Loading /> : ""}
        <div className="row mx-2">
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">From</label>
              <input
                className="form-control form-control-sm"
                value={dateTime.getDateFormatForInputTypeHtml(
                  this.state.inputData.From
                )}
                onChange={this.handleOnChange}
                type="date"
                name="From"
              />
              {errors1.From && (
                <p className="text-danger " style={{ fontSize: 11 }}>
                  {errors1.From}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group text-center">
              <label className="small">Till</label>
              <input
                className="form-control form-control-sm"
                value={dateTime.getDateFormatForInputTypeHtml(
                  this.state.inputData.Till
                )}
                onChange={this.handleOnChange}
                type="date"
                name="Till"
              />
              {errors1.Till && (
                <p className="text-danger " style={{ fontSize: 11 }}>
                  {errors1.Till}
                </p>
              )}
            </div>
          </div>
          <div className="col-sm-1">
            <button
              className="btn btn-primary btn-sm mt-4"
              disabled={
                !getRightsForAccess("DynamicDataRights", "JobSelection", "read")
              }
              onClick={() => this.handleOnSubmit(this.state.inputData)}
            >
              OK
            </button>
          </div>

          {/* <div className="col-sm-1 ">
            <button className='btn btn-primary btn-sm mt-4 ' disabled={!getRightsForAccess('DynamicDataRights', 'JobSelection', 'write')} onClick={this.handleDownloadFormat} >Download Template </button>
          </div> */}
          <div className="col-sm-2">
            <button
              className="btn btn-primary btn-sm mt-4 "
              disabled={
                !getRightsForAccess(
                  "DynamicDataRights",
                  "JobSelection",
                  "write"
                )
              }
              onClick={this.handleUploadJobDoc}
            >
              Import Excel{" "}
            </button>
          </div>

          <div className="col-sm-2">
            <button
              className="btn btn-primary btn-sm mt-4 "
              disabled={
                !getRightsForAccess(
                  "DynamicDataRights",
                  "JobSelection",
                  "write"
                )
              }
              onClick={this.handleAddJobClick}
            >
              Add Job
            </button>
          </div>
        </div>
        <div>
          {this.state.componentList.length < 0 ? (
            "Data Not Available"
          ) : (
            <Table
              columns={this.columns}
              sortColumn={this.state.sortColumn}
              onSort={this.handleSort}
              data={this.state.componentList}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ComponentView;
