import React, { Component } from 'react';
import swal from 'sweetalert';
import endPoint from '../../../config';
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';
import color from '../DownTimeMaster/color.json';
import MultiSelect from '../../../Common/multiselect';
import { ToastContainer, toast } from 'react-toastify';



class ReportSetting extends Component {
  state = { 
    downTimeReason:{},
    oldObj:{},
    downTimeReasonGroupList:[],
      errors:{},
      colors:{}
 }
schema={
  ReasonType:joi.string().required().label('Reason Type'),
  Reason:joi.string().required().label('Reason'),
  ReasonGroup:joi.string().required().label('Reason Group'),
  Color:joi.string().required().label('Color For Reason'),
}
componentDidMount()
 {
  axios.get(endPoint.api+'downtimegroup').then((result)=>{
    this.setState({downTimeReasonGroupList:result.data})
   
  })
  .catch((err)=>{
      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
  })

 
  axios.get(endPoint.api+'downtimereason').then((result)=>{
    let cloneColrs={...color}
    Object.keys(cloneColrs).forEach(one=>{
      result.data.forEach((reason)=>{
        if(reason.Color==one){
          delete cloneColrs[one]
        }
      })
    })
    this.setState({colors:cloneColrs,downTimeReason:{}})
   
  })
  .catch((err)=>{
      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
  })
 }

handleOnChange=(e)=>{
    const {downTimeReason,errors} ={...this.state};
    const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    downTimeReason[e.currentTarget.name]=e.currentTarget.value;
    this.setState({downTimeReason,errors:errors||{}});
    // // consol(downTimeReason)
    

}
handleSubmit=(data)=>{
  
  
  if(this.props.actionStatus==='add')
  {
    const errors = valid.validate(data,this.schema);
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.setState({querySuccess:true});
    axios.post(endPoint.api+'downtimereason',data)
    .then((result)=>{
     if(result.status===200)
     {
      this.setState({querySuccess:false});
       this.props.history.push('/setting/downtimereason/list')
       swal('Save Data Successfully');
     }
     else
     {
      this.setState({querySuccess:false});
      swal(`Data Not Saved`);
     }
    })
    .catch((err)=>{
      this.setState({querySuccess:false});
      swal(`${err.response.data.message}`);
    })

  }
  if(this.props.actionStatus==='update')
  {
    this.setState({querySuccess:true});
    axios.patch(endPoint.api+'downtimereason/'+data._id,{old:this.state.oldObj,new:data})
    .then((result)=>{
      const {n,nModified,ok}=result.data;
     if(ok==1&&n==1&&nModified==1)
     {
      this.setState({querySuccess:false});
       this.props.history.push('/setting/downtimereason/list')
       swal('Updated Data Successfully');
     }
     else
     {
      this.setState({querySuccess:false});
      swal(`${result.data}`);
     }
    })
    .catch((err)=>{
      this.setState({querySuccess:false});
      swal(`${err.response.data.message}`);
    })

  }
}





    render() { 
        return ( 
            <React.Fragment>
            <ToastContainer/>

       {this.state.querySuccess?<Loading/>:''}
      

            <div className=" border">
              <div className="row justify-content-center">
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Report Name</label>
                    {/* <select
                      type="text"
                      className="form-control"
                      placeholder="Enter DownTime Reason Group"
                      name="ReasonGroup"
                      onChange={this.handleOnChange}
                    value={this.state.downTimeReason.ReasonGroup}
                    >
                    <option>---Select Reason Group---</option>
                    {this.state.downTimeReasonGroupList.map((one,i)=>{
                     return <option key={i}>{one.DownTimeReasonGroup}</option>
                   })}
                    </select> */}
                    <MultiSelect name="ReasonGroup" master={true} selected={this.state.downTimeReason['ReasonGroup']} handleOnChange={this.handleOnChange} masterName="DownTimeReasonGroup" objLevel={1} all={this.state.downTimeReasonGroupList}/>
                    {this.state.errors.ReasonGroup && <div className="alert small alert-danger">{this.state.errors['ReasonGroup']}</div>}
                  </div>
                  
                
                </div>
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Range </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter DownTime Reason "
                      name="Reason"
                      onChange={this.handleOnChange}
                    defaultValue={this.state.downTimeReason.Reason}
                    />
                    {this.state.errors.Reason && <div className="alert small alert-danger">{this.state.errors['Reason']}</div>}
                  </div>
                  <div className="form-group text-center">
                    <label>Color </label>
                    <select
                      type="text"
                      className="form-control"
                      placeholder="Select Color"
                      name="Color"
                      onChange={this.handleOnChange}
                    value={this.state.downTimeReason.Color}
                    >
                    <option>---Select Color---</option>
                    {Object.keys(this.state.colors).map((one,i)=>{
                     return <option style={{backgroundColor:this.state.colors[one]}} key={i}>{one} </option>
                   })}
                    </select>
                    {this.state.errors.Color && <div className="alert small alert-danger">{this.state.errors['Color']}</div>}
                  </div>
    
                
                </div>
    
               
               
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.downTimeReason);
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="text-center" style={{marginTop:10}}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push('/setting/downtimereason/list')
                  }}
                >
                 Cancel
                </button>
              </div>
            </div>
          </React.Fragment>
         );
    }
}
 
export default ReportSetting;