import React, { useState,useEffect } from "react";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import Table from '../Common/table';
import ParetoChart from "./paretoChart";
import LineChart from "./lineChart";
import BarChart from 'react-google-charts';
import dateTime from '../utill/dateTime.js';
import axios from "axios";
import api from '../config.json';

const MachineDetailedPage = ({ allData, modelState, setModelState, singleMachineData, cardData, jobTableData, singleMachineData1 }) => {
  // const [modelState, setModelState] = useState(false);
  const [machineDetail, setMachineDetail] = useState([]);
  const [operatorDetail, setOperatorDetail] = useState([]);
  const [viewChart, setViewChart] = useState({ SOP_EOP: false, EOP_SOP: false, SOP_SOP: false })
  const [compList, setCompList] = useState([])
  useEffect(() => {
    axios.get(api.api1 + 'component')
      .then((result) => {
        setCompList(result.data);
      })
      .catch((err) => {
        // consol(err);
      })
  }, [])

  // // consol(cardData)

  const sortColumn = { path: 'Date', order: 'asc' }
  const columns = [
    { path: 'Date', label: 'Date', content: (obj) => <label>{dateTime.getDateFormat(obj.From)}</label> },
    { path: 'Shift', label: 'Shift' },
    { path: 'From', label: 'From', content: (obj) => <label>{dateTime.getTime(obj.From)}</label> },
    { path: 'Till', label: 'Till', content: (obj) => <label>{dateTime.getTime(obj.Till)}</label> },

    { path: 'ActualCycles', label: 'Num Of Cycles' },
    {
      key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
        let component = [...compList];
        component = component.filter((one) => one.ComponentId == obj.Component)
        if (component.length > 0) {
          return <label>{component[0].Alias}</label>
        } else {
          return <label>N/A</label>
        }
      }
    },
    { path: 'Component', label: 'Component' },
    { path: 'OperationID', label: 'Operation' },
    { path: 'ToolID', label: 'Tool', content: (obj) => <label>{obj.ToolID == 'null' || obj.ToolID == null ? '-' : obj.ToolID}</label> },
    { path: 'IdealSPM', label: 'Ideal SPM', content: (obj) => <label>{obj.IdealSPM.toFixed(1)}</label> },
    { path: 'AverageSPM', label: 'Average SPM', content: (obj) => <label>{obj.AverageSPM.toFixed(1)}</label> },
    { path: 'IdealCycleTime', label: 'Ideal Cycle Time', content: (obj) => <label>{obj.IdealCycleTime.toFixed(1)}</label> },
    { path: 'AverageCycleTime', label: 'Average Cycle Time', content: (obj) => <label>{obj.AverageCycleTime.toFixed(1)}</label> },

  ]
  const
    chartRender = (data) => {
      return (
        <BarChart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={data}
          loader={<div>Loading Chart</div>}
          options={{
            vAxes: {
              // Define properties for both vertical axes
              0: {
                title: "Device Power",
                format: "#",
                minValue: 0,
                maxValue: 1,
              },
              1: {
                title: "Time Diffrence (sec)",
                format: "#",
                minValue: 0,
              },
            },
            hAxis: {
              slantedText: true,
              slantedTextAngle: 90,
              textStyle: { fontSize: 9 },
              title: "Time", // This title is for the horizontal axis
            },

            tooltip: { isHtml: true },
            animation: { startup: true, duration: 400, easing: "out" },
            explorer: {
              maxZoomIn: 10,
              maxZoomOut: 8,
              zoomDelta: 2,
              actions: ["dragToZoom", "rightClickToReset"],
              axis: "horizontal",
              // axis: "vertical",
              // keepInBounds: true,
              // maxZoomIn: 4.0
            },
            seriesType: "bars",
            series: {
              0: { targetAxisIndex: 0 }, // Use axis 0 for the first series (bars)
              1: { targetAxisIndex: 1 }, // Use axis 1 for the second series (line)
            },
            annotations: {
              style: 'line', // or 'point', or 'text', or 'arrow'
              textStyle: {
                fontSize: 10,
                fontName: 'Arial',
              },
              stemColor: 'none', // No stem
              position: 'vertical',
              stem: {
                color: 'transparent',
                length: '50%', // Set the stem length to 50% to position it in the middle of the bars
              },
              // Align annotations vertically
            },
            // annotations: {
            //   textStyle: { fontSize: 10, color: "black" },
            //   datum: {
            //     stem: { color: "transparent" },
            //     textPosition: "out",
            //   },
            //   highContrast: true,
            // },
            legend: { position: "none" },
          }}
        />
      );
    };


  const show_Chart_Diff = (diffType) => {
    if (cardData.DeviceConfig == "ProductionCounterLogger" || cardData.DeviceConfig == "ProductionLogger") {
      const lineChart = [["Time(In Minutes)", "Time Diffrence", { role: "style" }]]
      const histogramChart = [["Time(In Seconds)", "No Of Incident", { role: "style" }, { role: "tooltip", type: "string" }]]
      allData[diffType] && allData[diffType].Line.forEach((x, idx) => {
        const arr = [];
        arr.push(new Date(x.DateTime));
        arr.push(x.Value)
        arr.push("orange");
        lineChart.push(arr);
      })
      allData[diffType] && allData[diffType].Histogram.forEach((x, idx) => {
        const arr = [];
        arr.push(x.Value);
        arr.push(x.Count);
        arr.push("orange");
        arr.push(`Time (In Sec): ${x.Value} \n No Of Incident: ${x.Count}`)
        histogramChart.push(arr);
      })
      return <>
        <BarChart
          chartType="LineChart"
          width="100%"
          height="400px"
          data={lineChart}
          options={{
            vAxes: {
              0: { title: "Time Difference (sec)", format: "#", minValue: 0 },
            },
            hAxis: {
              slantedText: true,
              slantedTextAngle: 45, // More readable angle
              textStyle: { fontSize: 10 }, // Slightly increased font size
              title: "Time", // Title for the horizontal axis
            },
            tooltip: {
              isHtml: true,
              textStyle: { fontSize: 12 },
            },
            animation: { startup: true, duration: 400, easing: "out" },
            explorer: {
              maxZoomIn: 10,
              maxZoomOut: 8,
              zoomDelta: 2,
              actions: ["dragToZoom", "rightClickToReset"],
              axis: "horizontal",
              keepInBounds: true, // Prevents going beyond the data range
            },
            series: {
              0: { targetAxisIndex: 0, type: "line" },
            },
            colors: ["orange", "#FF00FF", "#0000FF"],
            annotations: {
              textStyle: { fontSize: 10, color: "black" },
              datum: {
                stem: { color: "transparent" },
                textPosition: "out",
              },
              highContrast: true,
            },
            legend: { position: "none" },
          }}
        />
        <BarChart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={histogramChart}
          options={{
            title: cardData.DeviceConfig === 'ProductionCounter'
              ? "SPM / Cycles Per Minute Histogram"
              : "Time (In Sec) / No Of Incident  Histogram",
            vAxes: {
              0: {
                title: cardData.DeviceConfig === 'ProductionCounter'
                  ? "Number Of Minutes"
                  : "No of Incident",
                format: "#",
                minValue: 0
              },
            },
            hAxis: {
              slantedText: true,
              slantedTextAngle: 45, // More readable angle
              textStyle: { fontSize: 10 }, // Slightly increased font size
              title: cardData.DeviceConfig === 'ProductionCounter'
                ? "Cycles Per Minute / SPM"
                : "Time (In Sec)", // Title for the horizontal axis
            },
            tooltip: {
              isHtml: true, // Use custom HTML tooltips
            },
            animation: {
              startup: true,
              duration: 400,
              easing: "out"
            },
            explorer: {
              maxZoomIn: 10,
              maxZoomOut: 8,
              zoomDelta: 2,
              actions: ["dragToZoom", "rightClickToReset"],
              axis: "horizontal",
              keepInBounds: true, // Prevents going beyond the data range
            },
            annotations: {
              textStyle: { fontSize: 10, color: "black" },
              datum: {
                stem: { color: "transparent" },
                textPosition: "out",
              },
              highContrast: true,
            },
            legend: { position: "none" },
          }}
        />


      </>
    } else {
      const histogramChart = [["Cycle Per Minute", "No Of Minutes", { role: "style" }, { role: "tooltip", type: "string" }]]
      allData[diffType] && allData[diffType].Histogram.forEach((x, idx) => {
        const arr = [];
        arr.push(x.Value);
        arr.push(x.Count);
        arr.push("orange");
        arr.push(`Cycle Per Minute: ${x.Value} \n No Of Minutes: ${x.Count}`)
        histogramChart.push(arr);
      })
      return <BarChart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={histogramChart}
        options={{
          title: cardData.DeviceConfig === 'ProductionCounter'
            ? "SPM / Cycles Per Minute Histogram"
            : "Time (In Sec) / No Of Incident  Histogram",
          vAxes: {
            0: {
              title: cardData.DeviceConfig === 'ProductionCounter'
                ? "Number Of Minutes"
                : "No of Incident",
              format: "#",
              minValue: 0
            },
          },
          hAxis: {
            slantedText: true,
            slantedTextAngle: 45, // More readable angle
            textStyle: { fontSize: 10 }, // Slightly increased font size
            title: cardData.DeviceConfig === 'ProductionCounter'
              ? "Cycles Per Minute / SPM"
              : "Time (In Sec)", // Title for the horizontal axis
          },
          tooltip: {
            isHtml: true, // Use custom HTML tooltips
          },
          animation: {
            startup: true,
            duration: 400,
            easing: "out"
          },
          explorer: {
            maxZoomIn: 10,
            maxZoomOut: 8,
            zoomDelta: 2,
            actions: ["dragToZoom", "rightClickToReset"],
            axis: "horizontal",
            keepInBounds: true, // Prevents going beyond the data range
          },
          annotations: {
            textStyle: { fontSize: 10, color: "black" },
            datum: {
              stem: { color: "transparent" },
              textPosition: "out",
            },
            highContrast: true,
          },
          legend: { position: "none" },
        }}
      />
    }
  }

  return (
    <>
      <Modal
        show={modelState}
        onHide={() => setModelState(false)}
        size='xl'
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {Object.keys(cardData).length > 0 ? cardData.Equipment.toUpperCase() + ' Detailed Production' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {singleMachineData.length > 1 ? <Row>

            {/* <Col sm={12}>
          <Table columns={columns} sortColumn={sortColumn} data={machineDetail}/>
        </Col>
        <Col sm={4}>
        <Table columns={columns1} sortColumn={sortColumn1} data={operatorDetail}/>

        </Col> */}
            <Col sm={12}>
              <Row>
                <Col sm={12} className="d-flex flex-row align-items-start">
                  <div className="col-sm-8">
                    <h6 style={{ padding: 0, margin: 0 }}>
                      Availability: {cardData.Availability ? parseFloat(cardData.Availability).toFixed(1) : 0}%
                    </h6>
                    <h6 style={{ padding: 0, margin: 0 }}>
                      Performance: {cardData.Performance ? parseFloat(cardData.Performance).toFixed(1) : 0}%
                    </h6>
                    <h6 style={{ padding: 0, margin: 0 }}>
                      Quality Factor: {cardData.QualityFactor ? parseFloat(cardData.QualityFactor).toFixed(1) : 0}%
                    </h6>
                    <h6 style={{ padding: 0, margin: 0 }}>
                      OEE: {cardData.OEE ? cardData.OEE.toFixed(1) : 0}%
                    </h6>
                  </div>
                  {cardData.DeviceConfig == 'ProductionLogger' || cardData.DeviceConfig == 'ProductionCounterLogger' && <div className="d-flex flex-column ml-4">
                    <div className="form-check">
                      <input checked={viewChart && viewChart.SOP_EOP} onChange={(e) => {
                        setViewChart((prev) => ({
                          ...prev, // Spread operator to copy all other properties
                          SOP_EOP: !viewChart.SOP_EOP, // Update EOP_SOP property
                        }))
                      }
                      } type="checkbox" className="form-check-input" id="sop-eop" />
                      <label className="form-check-label" htmlFor="sop-eop">
                        SOP - EOP ( Process Time )
                      </label>
                    </div>
                    <div className="form-check">
                      <input checked={viewChart && viewChart.EOP_SOP} onChange={(e) =>
                        setViewChart((prev) => ({
                          ...prev, // Spread operator to copy all other properties
                          EOP_SOP: !viewChart.EOP_SOP, // Update EOP_SOP property
                        }))
                      } type="checkbox" className="form-check-input" id="eop-sop" />
                      <label className="form-check-label" htmlFor="eop-sop">
                        EOP - SOP ( Prepration Time )
                      </label>
                    </div>
                    <div className="form-check">
                      <input checked={viewChart && viewChart.SOP_SOP} onChange={(e) =>
                        setViewChart((prev) => ({
                          ...prev, // Spread operator to copy all other properties
                          SOP_SOP: !viewChart.SOP_SOP, // Update EOP_SOP property
                        }))
                      } type="checkbox" className="form-check-input" id="sop-sop" />
                      <label className="form-check-label" htmlFor="sop-sop">
                        SOP - SOP ( Cycle Time )
                      </label>
                    </div>
                  </div>}
                </Col>
              </Row>


              <Row>
                <Col sm={12}>
                  <BarChart
                    //style={{ marginTop: 200 }}
                    chartType="ColumnChart"
                    width="100%"
                    height="400px"
                    data={singleMachineData}
                    options={{
                      vAxes: {
                        // Define properties for both vertical axes
                        0: { title: (cardData.DeviceConfig == 'ProductionLogger' || cardData.DeviceConfig == 'ProductionCounterLogger') ? "Cycles Count" : 'Process', format: "#", minValue: 0 },
                        1: { title: "Time Diffrence (sec)", format: "#", minValue: 0 },
                      },
                      hAxis: {
                        slantedText: true,
                        slantedTextAngle: 90,
                        textStyle: { fontSize: 9 },
                        title: "Time", // This title is for the horizontal axis
                      },

                      tooltip: { isHtml: true },
                      animation: { startup: true, duration: 400, easing: "out" },
                      explorer: {
                        maxZoomIn: 10,
                        maxZoomOut: 8,
                        zoomDelta: 2,
                        actions: ["dragToZoom", "rightClickToReset"],
                        axis: "horizontal",
                        // axis: "vertical",
                        // keepInBounds: true,
                        // maxZoomIn: 4.0
                      },
                      seriesType: "bars",
                      series: {
                        0: { targetAxisIndex: 0, visibleInLegend: false }, // Use axis 0 for the first series (bars)
                        1: { targetAxisIndex: 1, type: "line" }, // Use axis 1 for the second series (line)
                      },
                      colors: ["orange", "#FF00FF", "#0000FF"],
                      annotations: {
                        textStyle: { fontSize: 10, color: "black" },
                        datum: {
                          stem: { color: "transparent" },
                          textPosition: "out",
                        },
                        highContrast: true,
                      },
                      legend: { position: "none" },
                    }}
                  />
                </Col>
                <Col sm={12}>
                  {singleMachineData1.length > 0 && singleMachineData1[1] && singleMachineData1[1].length > 1 ? (
                    chartRender(singleMachineData1)
                  ) :
                    ""
                  }
                </Col>
                {cardData && ((cardData.DeviceConfig === "ProductionCounter") || (cardData.DeviceConfig === "ProductionEvent")) && show_Chart_Diff("SPM")}
                {viewChart.SOP_EOP && <Col className="border text-center" sm={12}>
                  <strong >SOP - EOP ( Process Time )</strong>
                  {show_Chart_Diff("SOP_EOP_Diffrence")}
                </Col>}
                {viewChart.EOP_SOP && <Col className="border text-center" sm={12}>
                  <strong >EOP - SOP ( Prepration Time )</strong>
                  {show_Chart_Diff("EOP_SOP_Diffrence")}
                </Col>}
                {viewChart.SOP_SOP && <Col className="border text-center" sm={12}>
                  <strong >SOP - SOP ( Cycle Time )</strong>
                  {show_Chart_Diff("SOP_SOP_Diffrence")}
                </Col>}
              </Row>
              <Row>
                <Table columns={columns} com data={jobTableData} sortColumn={sortColumn} />
              </Row>
            </Col>
          </Row> : <p>No Data Available</p>}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MachineDetailedPage;
