import _ from 'lodash';
import React, { Component } from 'react';
import GuageChart from './../../utill/GuageChart';
import axios from '../../httpServices/httpService';
import swal from 'sweetalert';
import api from '../../config.json';
import Chart from 'react-google-charts'
import Table from '../../Common/table';
import Loading from '../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';

class OCXView extends Component {
    state = {
        guageChartData: [],
        data: [],
        downtimeGroupData: [],
        seriesObj: {},
        topReasons: [],
        querySuccess:false
    }
    MyInterval;

    getDataForGuageChart = (oeeData) => {
        let data = _.cloneDeep(oeeData);
        let arr = [['Label', 'Value'], [this.props.user.company, data.length > 0 ? parseFloat(data[0].Plant) : 0]]
        data.forEach(element => {
            let arr1 = [];
            arr1.push(element.EquipmentGroup);
            arr1.push(parseFloat(element.OEE))
            arr.push(arr1);
        });
        return arr;
    }


    organiseDowntimeData = (bufferArr) => {
        //  bufferArr.forEach(one=>{
        //      one.Event.forEach((event)=>{
        //          event.Color=one.Color;
        //      })
        //  })
        // [
        //     ['City', '2010 Population', '2000 Population'],
        //     ['New York City, NY', 8175000, 8008000],
        //     ['Los Angeles, CA', 3792000, 3694000],
        //     ['Chicago, IL', 2695000, 2896000],
        //     ['Houston, TX', 2099000, 1953000],
        //     ['Philadelphia, PA', 1526000, 1517000],
        // ]
        let schema = ['EquipmentGroup']
        let arr = [];
        let colorWithReason = []
        let seriesObj = {}
        bufferArr.forEach(element => {
            element.Downtime.forEach((one, i) => {
                let IsAlready;
                let obj = {}

                if (one.DowntimeReason) {

                    IsAlready = schema.includes(one.DowntimeReason);
                } else {

                    IsAlready = schema.includes(one.State);
                }
                if (!IsAlready) {
                    if (one.DowntimeReason) {
                        obj.Reason = one.DowntimeReason;
                        obj.Color = one.Color;
                        colorWithReason.push(obj)
                        schema.push(one.DowntimeReason)
                        schema.push({ role: 'style' })
                    } else {
                        obj.Reason = one.State;
                        obj.Color = 'red';
                        colorWithReason.push(obj)
                        schema.push(one.State)
                        schema.push({ role: 'style' })
                    }
                }
            })
        });

        bufferArr.forEach(element => {
            let oneArr = []
            if (element.Downtime.length > 0) {
                oneArr = [element.EquipmentGroup]
            }
            let down = element.Downtime;
            down = this.aggreagteReasonDowntime(down)
            for (let i = 1; i < schema.length; i = i + 2) {
                let filterDown = down.filter((one) => {
                    if (one.DowntimeReason) {
                        if (one.DowntimeReason == schema[i]) {
                            return one
                        }
                    } else {
                        if (one.State == schema[i]) {
                            return one
                        }
                    }
                }
                )
                if (filterDown.length > 0) {
                    oneArr.push(Math.ceil(filterDown[0].Duration / 60));
                    oneArr.push(filterDown[0].Color)
                    // const colors={...seriesColor}
                    //      colors[i]={color:filterDown[0].Color}
                    //      setSerieseColor(colors);
                    // if(filterDown[0].DowntimeReason){
                    //     oneArr.push(filterDown[0].DowntimeReason)
                    // }else{
                    //     oneArr.push(filterDown[0].State)
                    // }

                } else {
                    let filterColor = colorWithReason.filter(color => color.Reason == schema[i])
                    // const colors={...seriesColor}
                    // colors[i]={color:filterColor[0].Color}
                    // setSerieseColor(colors);
                    oneArr.push(0);
                    oneArr.push(filterColor[0].Color)
                }
            }
            arr.push(oneArr)
        })
        arr.unshift(schema)
        colorWithReason.forEach((one, i) => {
            seriesObj[i] = { color: one.Color }
        })
        // bufferArr.forEach(element => {
        //     let oneArr=[]
        //     if(element.Downtime.length>0){
        //         oneArr=[element.Equipment]
        //     }

        //     element.Downtime.forEach((one,i)=>{
        //      const colors={...seriesColor}
        //      colors[i]={color:one.Color}
        //      setSerieseColor(colors);
        //             oneArr.push(one.Duration)
        //             oneArr.push(one.Color)

        //     })
        //     let iterateIdx=oneArr.length;
        //     for(iterateIdx;iterateIdx<schema.length;iterateIdx=iterateIdx+2){
        //         oneArr.push(0)
        //         oneArr.push('red')
        //     }
        //     if(oneArr.length>0)
        //     arr.push(oneArr)
        // });
        // arr.unshift(schema)
        let returnObj = { arr: arr, color: seriesObj }
        return returnObj
    }

    aggreagteReasonDowntime = (bufferArry) => {

        for (let i = 0; i < bufferArry.length; i++) {
            for (let j = i + 1; j < bufferArry.length; j++) {
                if (bufferArry[i].DowntimeReason === bufferArry[j].DowntimeReason) {
                    bufferArry[i].Duration += bufferArry[j].Duration
                    bufferArry.splice(j, 1);

                    // i--;
                    j--;
                    // i=j-1
                }
            }
        }
        return bufferArry
    }


    componentDidMount() {
        if (this.props.user) {
            this.setState({ querySuccess: true })
            axios.get(api.api1 + 'GetOCXViewDashboard')
                .then((result) => {
                    let down = this.organiseDowntimeData(result.data.Downtime)
                    // consol(down,"table data 1")
                    this.setState({ guageChartData: this.getDataForGuageChart(result.data.OEE), topReasons: result.data.TopReason, downtimeGroupData: down.arr, seriesObj: down.color, data: result.data, querySuccess: false });
                })
                .catch((err) => {
                    this.setState({ querySuccess: false })
                      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                })
        }
        this.MyInterval = setInterval(() => {
            if (this.props.user) {

                axios.get(api.api1 + 'GetOCXViewDashboard')
                .then((result) => {

                    let down = this.organiseDowntimeData(result.data.Downtime)
                        this.setState({ guageChartData: this.getDataForGuageChart(result.data.OEE), topReasons: result.data.TopReason, downtimeGroupData: down.arr, seriesObj: down.color, data: result.data, querySuccess: false });
                    })
                    .catch((err) => {
                        this.setState({ querySuccess: false })
                          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                    })
            }
        }, 500000)


    }

    componentWillUnmount() {
        clearInterval(this.MyInterval)
    }


    renderChart = () => {

        if (this.state.downtimeGroupData.length <=1) { 
            return <p>No Downtime Data Available</p>
         }
        return <Chart
            width={'100%'}
            height={'100%'}

            style={{ padding: 0, height: 500 }}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={this.state.downtimeGroupData}
            options={{
                title: 'Downtime Analysis By Equipment Group ',
                // chartArea: { width: '50%' },
                isStacked: true,
                series: this.state.seriesObj,
                hAxis: {
                    title: 'Equipment Group',
                },
                vAxis: {
                    title: 'Downtime Duration',
                    minValue: 0,

                },

            }}
            // For tests
            rootProps={{ 'data-testid': '3' }}
        />
    }
    column1 =     [{ path: 'EquipmentGroup', label: 'Equipment Group' }, { path: 'CyclesRun', label: 'Total Cycles' }]

    column = [{ path: 'JobCount', label: 'Num of Jobs Run' }, { path: 'OperatorCount', label: 'Num of Operators Available' }]
    columnTopReasons = [{ path: 'State', label: 'Reason' }, { path: 'Duration', label: 'Duration (In Mins)', content: (obj) => <label>{(obj.Duration / 60).toFixed(1)}</label> }]
    render() {
        if(this.state.querySuccess){
            return <Loading/>
        }
        return (

            <div className='text-center Plantview ' > <ToastContainer/>
                <h4 className='text-white'>Plant Overview</h4>
                <div className='col'>


                    <div className='row mx-2'>
                        <GuageChart data={this.state.guageChartData} />
                    </div>
                    {/* {console.log(this.state.data,"vfdjlvhbsdx")}
                    { this.state.data?.CyclesRun?.length  > 0 && this.state.data?.CyclesRun.map(item=><div className='   '>
                    <div className='d-flex flex-column w-25 bg-info-subtle rounded-3 mx-4 p-3 m-1'>{'Equipment Group'}:{'Total Cyles'}</div>

                            <div className='d-flex flex-column w-25 bg-info-subtle rounded-3 mx-4 p-3 m-1'>{item.EquipmentGroup}:{item.CyclesRun}</div>
                            </div>)} */}
                    <div className='row mb-3 mx-2' >
                   {   this.state.data?.CyclesRun?.length  > 0 &&<div className='col-sm bg-white  mx-2 p-1' style={{borderRadius:'10px'}}>
                            <Table height={'auto'} data={this.state.data?.CyclesRun} columns={this.column1} 
                            sortColumn={{ path: 'EquipmentGroup', order: 'asc' }}
                             />
                        </div>}
                        <div className='col-sm bg-white  mx-2 p-1' style={{borderRadius:'10px'}}>
                    {/* {this.state.data> 0 ? <> */}
                            <Table  height={'auto'}data={[this.state.data]} columns={this.column} 
                            sortColumn={{ path: 'JobCount', order: 'asc' }}
                             />
                            {/* </>:''} */}
                        </div>
                        <div className='col-sm ml-20 bg-white  mx-2 p-1' style={{borderRadius:'10px'}}>
                        {/* {this.state.topReasons> 0 ? <> */}
                            <Table height={'auto'} data={this.state.topReasons} columns={this.columnTopReasons}
                             sortColumn={{ path: 'State', order: 'asc' }}
                              />
                            {/* </>:''} */}
                        </div>

                    </div>
                    <div className='row mx-3' style={{ height: 500 ,overflow:'hidden',borderRadius:'10px'}}>
                        {this.renderChart()}
                    </div>

                </div>

            </div>

        );
    }
}

export default OCXView;
