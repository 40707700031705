import React, { useState,useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi, { label } from 'joi'
import Loading from './../utill/LoadingComponent';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const JobRunReport = ({handleDownloadTableToExcel,equips,equipGroups,fromDateLimit,storeDataInLocalStorage}) => {
    const [JobRunData, setJobRunData] = useState([])
    const [view, setView] = useState([])
    const [inputData, setInputData] = useState({})
    const [errors, setErrors] = useState({})
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [equip, SetEquips] = useState([])
    const [querySuccess, setQuerySuccess] = useState(false)

    const[compList,setCompList]=useState([])
  // useEffect(()=>{
  //   axios.get(api.api1 + "component")
  //   .then((result)=>{
  //     setCompList(result.data)
  //   })
  //   .catch((err)=>{
  //     // consol(err);
  //   })

  // },[])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const result = await axios.get(api.api1 + 'component');
 let result = await storeDataInLocalStorage('component')

 setCompList(result);
      } catch (error) {
        // console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, []);

    const schema = {
      From: joi.date().required().label('From Date'),
      UserId: joi.string(),
      Till: joi.date().required().label('Till Date'),
      Equips:joi.array(),
      // Shift: joi.string().required().label('Shift')
    }

    const setSelection=(key,option,equips)=>{
      if(key=='EquipmentGroup'){
          SetEquips(equips?equips:[]);
          setSelectedEquipGroupOption(option)
          setSelectedEquipOption([])
      }
      if(key=='Equipment'){
         
          setSelectedEquipOption(option)
      }
  }

const getEquipsForReport=()=>{
      let equipArr=[]
      equips.forEach((one)=>{
          selectedEquipOption.forEach((option)=>{
              if(one._id==option.value){
                  equipArr.push(one);
              }
          })
      })
      return equipArr;
  }

    const sortColumn={ path: 'Date',order:'asc' }
    const columns=[
      { path: 'Date', label: 'Date',content:(obj)=><label>{dateTime.getDate(obj.From)}</label> },
      { path: 'Shift', label: 'Shift' },
      { path: 'EquipmentGroup', label: 'EquipmentGroup' },
        { path: 'Equipment', label: 'Equipment' },
        { path: 'Component', label: 'Component' },
        {
          key: 'ComponentAlias', label: 'Component Alias', content: (obj) => {
            let component = [...compList];
            component = component.filter((one) => one.ComponentId == obj.Component)
            if (component.length > 0) {
              return <label>{component[0].Alias}</label>
            } else {
              return <label>N/A</label>
            }
          }
        },
        
        { path: 'OperationID', label: 'Operation ID' },
        { path: 'From', label: 'From' ,content:(obj)=><label>{dateTime.getTime(obj.From)}</label> },
        { path: 'Till', label: 'Till',content:(obj)=><label>{dateTime.getTime(obj.Till)}</label> },
       
      ]

      const sortColumn1={ path: 'Machine',order:'asc' }
      const columns1=[
        
        { path: 'Equipment', label: 'Machine' },
        { path: 'Component', label: 'Component' },
        { path: 'OperationID', label: 'Operation ID' },
        { path: 'Operator', label: 'Operator' },
        { path: 'Date', label: 'Date' },
        
      ]
     
      const handleOnChange = (e) => {

        if (e.currentTarget.type === 'date') {
          // consol(new Date(e.currentTarget.value))
          // consol(e.currentTarget.value)
          inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
          setInputData(inputData);
        }
        else if (e.currentTarget.type === 'time') {
          let Time = e.currentTarget.value.split(':');
          // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
          // consol(e.currentTarget.value)
          inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
          setInputData(inputData);
        }
        else {
          inputData[e.currentTarget.name] = e.currentTarget.value
        }
        setErrors({})
    
      }
      const handleOnSubmit = (inputData) => {
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setQuerySuccess(true)
        const user = auth.getCurrentUser();
        inputData.UserId=user._id
        
  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
        inputData.Equips=getEquipsForReport()
        axios.post(api.api + 'JobsRunReport', inputData).then((result) => {
          // consol(result.data)
          if (result.data.length > 0) {
            setJobRunData(result.data)
            setQuerySuccess(false)
          }
          else {
            swal('Data Not Available');
            setJobRunData([])
            setQuerySuccess(false)
          }
        })
          .catch((err) => {
              toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
            setQuerySuccess(false)
            // consol(err)
          })
      }

     const  handleDownloadExcel=()=>{

      }

      
      const SelectedDates =(dates)=>{
        setInputData(dates)
      }
     
    return (
      <React.Fragment>
         {querySuccess ? <Loading /> : ''}
       <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Job Run
        </h4>
      </div>
	      <ToastContainer />
      <div className="row">
        {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
        {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>From</label>
                 <input className='form-control form-control-sm' type='date' min={fromDateLimit} onChange={handleOnChange} name='From'/>
                 {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
 
                </div>
                </div> */}
                {/* <div className="form-group ">
                 <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From'/>
                </div> */}
            {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>Till</label>
                  <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                  {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
 
                </div>
                </div> */}
                {/* <div className="form-group ">
                  <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='Till' />
                 
                </div> */}
              {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>Shift</label>
                  <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
                  <option>--select shift--</option>
                      <option>All</option>
                      <option>Shift1</option>
                      <option>Shift2</option>
                      <option>Shift3</option>
                  </select>
                  {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
 
                </div>
              </div> */}
             
              <div className="col-sm-1">
               <button className='btn btn-primary btn-sm mt-4' onClick={()=>handleOnSubmit(inputData)}>Apply</button>
              </div>
             
              <div   className={JobRunData.length>0?' col-sm-1':'d-none col-sm-1'}>
              <a className='btn btn-sm btn-primary mt-4' onClick={()=>handleDownloadTableToExcel('JobRunReport')} style={{float:'right',color:'white'}}>Download</a>
              </div>
            
             
              
            </div>
            <div>
           
            {JobRunData.length>0?<Table id='JobRunReport' columns={columns} sortColumn={sortColumn} data={JobRunData}/>:''}
            </div>
            </React.Fragment>

       
      );
}
 
export default JobRunReport;