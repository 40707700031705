import { observer } from "mobx-react-lite";

import React, { useState, useEffect } from 'react';
import { Dropdown, Form, Button, ButtonGroup } from 'react-bootstrap';

const CheckboxMenu = React.forwardRef(
  (
    {
      children,
      style,
      className,
      "aria-labelledby": labeledBy,
      onSelectAll,
      onSelectNone,
      multiSelect,
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        style={style}
        className={`${className} CheckboxMenu`}
        aria-labelledby={labeledBy}
      >
        {multiSelect && (
          <div className="dropdown-item border-top pt-2 pb-0">
            <ButtonGroup size="sm">
              <Button variant="link" onClick={onSelectAll}>
                Select All
              </Button>
              <Button variant="link" onClick={onSelectNone}>
                Select None
              </Button>
            </ButtonGroup>
          </div>
        )}
        <div
          className="d-flex flex-column"
          style={{ maxHeight: "calc(100vh)", overflow: "none" }}
        >
          <ul
            className="list-unstyled flex-shrink mb-0"
            style={{ overflow: "auto" }}
          >
            {children}
          </ul>
        </div>
      </div>
    );
  }
);

const CheckDropdownItem = React.forwardRef(
  ({ children, id, checked, onChange, multiSelect }, ref) => {
    return (
      <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
        <Form.Check
          type={multiSelect ? 'checkbox' : 'radio'}
          label={children}
          checked={checked}
          onChange={onChange && onChange.bind(onChange, id)}
        />
      </Form.Group>
    );
  }
);

export const CheckboxDropdown = ({
  items,
  onCheckboxChange,
  heading,
  name,
  searchbar,
  multiSelect,
  selectAll
}) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Automatically select all items when selectAll is true
    if (selectAll) {
      handleSelectAll();
    }
  }, [selectAll]); // Run this effect whenever selectAll changes

  const handleChecked = (key, event) => {
    const updatedItems = items.map(item => {
      if (item.id === key) {
        return { ...item, checked: event.target.checked };
      }
      return item;
    });

    if (multiSelect) {
      onCheckboxChange(updatedItems, name);
    } else {
      const selectedItemId = updatedItems.find(item => item.id === key).id;
      const updatedSingleItem = updatedItems.map(item => ({
        ...item,
        checked: item.id === selectedItemId,
      }));
      onCheckboxChange(updatedSingleItem, name);
    }
  };

  const handleSelectAll = () => {
    const updatedItems = items.map(item => ({ ...item, checked: true }));
    onCheckboxChange(updatedItems, name);
  };

  const handleSelectNone = () => {
    const updatedItems = items.map(item => ({ ...item, checked: false }));
    onCheckboxChange(updatedItems, name);
  };

  const filteredItems = items.filter(item =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dropdown style={{ width: '100%' }}>
      <Dropdown.Toggle
        variant="primary"
        style={{ borderColor: '#eaeaea', width: '90%' }}
        className="p-0 m-0 shadow-sm bg-white rounded"
        id="dropdown-basic"
      >
        <p className="py-0 m-0 text-center" style={{ color: 'black' }}>
          {heading}
        </p>
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CheckboxMenu}
        multiSelect={multiSelect}
        onSelectAll={handleSelectAll}
        onSelectNone={handleSelectNone}
      >
        {searchbar !== false && (
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        )}

        {filteredItems.map(i => (
          <Dropdown.Item
            key={i.id}
            as={CheckDropdownItem}
            id={i.id}
            checked={i.checked}
            onChange={handleChecked}
            multiSelect={multiSelect}
          >
            {i.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
