import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Chart from 'react-google-charts';
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import {generateExcelFileFull,generateExcelFile} from '../utill/download'
import HourlyTableProdction from '../Common/hourlyProductionTable';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';


const FirstPartReport = ({user,equips,equipGroups,fromDateLimit}) => {
  const [productionData, setProductionData] = useState([])
  const [inputData, setInputData] = useState({})
  const [selectedInput, setSelectedInput] = useState({})
  const [errors, setErrors] = useState({})
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [equip, SetEquips] = useState([])
  const [querySuccess, setQuerySuccess] = useState(false)
  const [shiftSetting, setShiftSetting] = useState([]);
   
  const schema = {
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
    UserId: joi.string(),
    Equips:joi.array(),
    Event:joi.string().required().label('Event'),
    //Shift: joi.string().required().label('ShiftName'),
    ReportType: joi.string().required().label('ReportType')
  }

  const event ={
      'FirstPart':[{lable:'Start Of Shift',value:'StartOfShift'},{lable:'Break End',value:'BreakEnd'}],
      'LastPart':[{lable:'End Of Shift',value:'EndOfShift'},{lable:'Break Start',value:'BreakStart'}],
  }


  const handleOnChange = async(e) => {
    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
    }
    // else {
      if(e.currentTarget.name=='ReportType'){
       if(e.currentTarget.value ==='FirstPart'){
         setShiftSetting(event.FirstPart)
         inputData[e.currentTarget.name] = e.currentTarget.value;
         inputData['Event']='StartOfShift'


       }else{
        setShiftSetting(event.LastPart)
         inputData[e.currentTarget.name] = e.currentTarget.value;
         inputData['Event']='EndOfShift'
         
       }
       
      }else{

        inputData[e.currentTarget.name] = e.currentTarget.value;
      }
    // }
  //  let res= await axios.put(api.api+'GetShiftSettingForDateShift',{DateTime:inputData.DateTime})
  //  setShiftSetting(res.data);
    setInputData(inputData);
    setErrors({})
  }
  const setSelection=(key,option,equips)=>{
    if(key=='EquipmentGroup'){
        SetEquips(equips?equips:[]);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if(key=='Equipment'){
       
        setSelectedEquipOption(option)
    }
}

const getEquipsForReport=()=>{
    let equipArr=[]
    equips.forEach((one)=>{
        selectedEquipOption.forEach((option)=>{
            if(one._id==option.value){
                equipArr.push(one);
            }
        })
    })
    return equipArr;
}
  const handleOnSubmit = (inputData) => {
  
    // consol(inputData)
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    inputData.UserId=user._id
  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    setProductionData([])
    inputData.Equips=getEquipsForReport();
    axios.post(api.api + 'GetFirstPartReport',inputData).then((result) => {
      // consol(result)
      if (result.data !== null && result.data !== undefined) {
        if (result.data.length > 0) {
          setProductionData(result.data)
          setQuerySuccess(false)
          setSelectedInput({...inputData})
        }
        else {
          swal('Data Not Available');
          setQuerySuccess(false)
        }
      }
      else {
        setQuerySuccess(false)
      }

    })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err)
        setQuerySuccess(false)
      })

  }

  const handleDownload=(e)=>{
    generateExcelFile(e)
  }
  const organiseDowntimeData = (bufferArr) => {
    // [
    //     ['City', '2010 Population', '2000 Population'],
    //     ['New York City, NY', 8175000, 8008000],
    //     ['Los Angeles, CA', 3792000, 3694000],
    //     ['Chicago, IL', 2695000, 2896000],
    //     ['Houston, TX', 2099000, 1953000],
    //     ['Philadelphia, PA', 1526000, 1517000],
    // ]
    let schema = ['Machine']
    let arr = [];
    //let minusFlag=false;
  
    bufferArr.forEach(element => {
        element.FirstCount.forEach((one, i) => {
            let IsAlready;
            IsAlready = schema.includes(one.Shift+' '+(one.Event+i)+' '+date.getDateFormat(one.DateTime));
            if (!IsAlready) {
                schema.push(one.Shift+' '+(one.Event+i)+" "+date.getDateFormat(one.DateTime))
            }
        })
    });
    bufferArr.forEach(element => {
        let oneArr = []
        if (element.FirstCount.length > 0) {
            oneArr = [element.Equipment]
            element.FirstCount.forEach(one=>{
              if(one.Diff==-1){
                oneArr.push(one.Diff)
              }else
              {
                oneArr.push(one.Diff/60)
              }
              
            })
        }
       
        arr.push(oneArr)
    })
    arr.unshift(schema)
   // setMinusFlag(minusFlag);
    return arr
}


  const chartRender = (data) => {
    return <Chart
      width={window.innerWidth * 0.75}
      height={'400px'}
      chartType="Bar"
      loader={<div>Loading Chart</div>}
      data={organiseDowntimeData(data)}
      options={{
        // Material design options
        chart: {
          title: selectedInput.ReportType + ' Report ',
          subtitle: selectedInput.ReportType + ' Production of  ' + selectedInput.Event,
        },
        series: {
          0: { axis: 'Delay' }, // Bind series 0 to an axis named 'distance'.
          // 1: { axis: 'brightness' } // Bind series 1 to an axis named 'brightness'.
        },
        axes: {
          y: {
            Delay: { label: 'Delay In Minutes' }, // Left y-axis.

          }
        },
        hAxis: {
          ticks: [0, 25, 50, 75, 100]
        }

      }}
      // For tests
      rootProps={{ 'data-testid': '2' }}
    />
  }
  const SelectedDates =(dates)=>{
    setInputData(dates)
  }
  return (
    <React.Fragment>
      {querySuccess ? <Loading /> : ''}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          First Part
        </h4>
      </div>
      <ToastContainer/>

      <div className="row mx-2">
   {   equipGroups.length > 0 &&<EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input className={'form-control form-control-sm'} min={fromDateLimit} placeholder='dd/mm/yyyy' type='date' onChange={handleOnChange} name='From' />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
        </div> */}

<DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />


        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              {shiftSetting.map(one=>{
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <div className="form-group text-center">
            <label className='small'>Report Type</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ReportType'>
              <option>--select part--</option>
              <option value={'FirstPart'}>First Part</option>
              <option value={'LastPart'}>Last Part Aspect</option>
            </select>
            {errors.ReportType && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ReportType}</p>}
          </div>
        </div>
        <div className="col-sm-1">
          <div className="form-group text-center">
            <label className='small'>Event Type</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='Event'>
              <option>--select event--</option>
              {shiftSetting.map(one=>{
                return <option value={one.value}>{one.lable}</option>
              })}
            </select>
            {errors.Event && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Event}</p>}
          </div>
        </div>
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => {
            handleOnSubmit(inputData)}
            }>Apply</button>
        </div>
        <div className="col-sm-1">
          <button disabled={productionData.length > 0 ? false : true} className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload(inputData.ReportType)}>Download Excel</button>
        </div>
      </div>
      <div>
          {productionData.length>0? <div style={{overflow:'auto'}} className='ml-4 w-100'>{chartRender(productionData)}</div>:''}
        {productionData.length>0?<p style={{fontSize:15}}>-1 : No Cycles Run</p>:''}
      </div>
      <div>
      
     {productionData.length>0? <table id={inputData.ReportType} className="table table-hover table-striped table-sm">
              <thead>
                <tr className="table-primary">
                <th scope="col">Equipment</th>
                {productionData[0].FirstCount.map((one,i)=>{
                    return  <th scope="col">{one.Shift=='BreakEnd'?'Break'+i:one.Shift+' '+date.getDateFormat(one.DateTime)}(In Min)</th>
                })}
                 
                </tr>
              </thead>
              <tbody>
              {productionData.map(equip=>{
                  return <tr>
                 <td>{equip.Equipment}</td> 
                 {equip.FirstCount.map(one=>{
                     return  <td>{one.Diff==-1?"did not run in this interval":Math.floor(one.Diff/60)}</td>
                 })}
                  </tr>
              })}
              </tbody>

            </table>:''}

        {/* <Table columns={getProductionReportSchema} onSort={handleSort} sortColumn={sortColumnDate} data={productionData} /> */}
      </div>
    </React.Fragment>
  );
}
export default FirstPartReport;