import React, { Component } from 'react';

//import '../QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faEye, faPlus, faFileImport, faDownload } from '@fortawesome/free-solid-svg-icons';
import dateTime from '../../../utill/dateTime';
import swal from 'sweetalert';
import api from '../../../config';
import axios from '../../../httpServices/httpService';
import Table from '../../../Common/table';

import _, { split } from 'lodash'
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import XLSX from 'xlsx';
import valid from '../../../utill/validation';
import joi from 'joi';
import { Modal } from 'react-bootstrap';



class ComponentTable extends Component {

  state = {
    componentList: [],
    sortColumn: { path: 'ComponentId', order: 'asc' },
    querySuccess: false,


    excelData: [],
    arrayProcessIndex: 0,
    errorModal: false,
    errorMessage: '',
    errorFlag: false,
    rowNumber: 0,
    errors: {},
    objKey: '',
  }

  componentDidMount() {
    this.setState({ querySuccess: true });
    axios.get(api.api1 + 'component')
      .then((result) => {
        // consol(result.data)
        if (result.data.length > 0) {
          this.setState({ componentList: result.data, querySuccess: false });
        }
        else {
          this.setState({ querySuccess: false })
        }
      })
      .catch((err) => {
        this.setState({ querySuccess: false });
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });

      })


  }

  onDelete = (data) => {

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this",
      icon: "warning",
      buttons: true,
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.setState({ querySuccess: true });
        axios
          .delete(api.api + "component/" + data._id, { data: data })
          .then(result => {
            // consol(result.data)
            if (result.data.ok === 1 && result.data.deletedCount === 1) {
              let filterData = this.state.componentList.filter(
                obj => obj._id !== data._id
              );
              this.setState({ componentList: filterData, querySuccess: false });
            }
          })
          .catch(err => {
            this.setState({ querySuccess: false });
              toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          });
      } else {
        this.setState({ querySuccess: false });
        // swal("Your imaginary file is safe!");
      }
    });




  }


  columns = [
    { path: 'ComponentId', label: 'Component Id' },
    { path: 'Alias', label: 'Alias', content: (obj) => <label>{obj.Alias ? obj.Alias : '-'}</label> },
    { path: 'CustomerName', label: 'Customer', content: (obj) => <label>{obj.CustomerName ? obj.CustomerName : '-'}</label> },
    { path: 'NumberOfOperations', label: 'Number of Operation' },
    { key: 'OperationSerial', label: 'Operation Serial', content: (obj) => obj.Operation.map(one => <React.Fragment><label>{one.OperationSerial}</label><br /></React.Fragment>) },
    { key: 'OperationID', label: 'Operation ID', content: (obj) => obj.Operation.map(one => <React.Fragment><label>{one.OperationID ? one.OperationID : '-'}</label><br /></React.Fragment>) },

    { key: 'EquipmentType', label: 'Equipment Required', content: (obj) => obj.Operation.map(one => <React.Fragment><label>{one.EquipmentType}</label><br /></React.Fragment>) },
    //{key:'NetWeightPerComponent',label:'Net Component Weight',content:(obj)=>obj.Operation.map(one=><React.Fragment><label>{one.NetWeightPerComponent}</label><br/></React.Fragment>)},
    { key: 'ToolRequiredToProduce', label: 'Tool Required', content: (obj) => obj.Operation.map(one => <React.Fragment><label>{one.ToolRequiredToProduce.toString()}</label><br /></React.Fragment>) },
    { key: 'Tool', label: 'Tool', content: (obj) => obj.Operation.map(one => <React.Fragment><label>{one.Tool ? one.Tool : '-'}</label><br /></React.Fragment>) },
    //{key:'ScrapWeightCycle',label:'Scrap Weight / Cycle',content:(obj)=>obj.Operation.map(one=><React.Fragment><label>{one.ScrapWeightCycle?one.ScrapWeightCycle.toString():'-'}</label><br/></React.Fragment>)},
    { key: 'IdealCycleTime', label: 'Ideal Cycle Time', content: (obj) => obj.Operation.map(one => <React.Fragment><label>{one.IdealCycleTime ? one.IdealCycleTime.toString() : '-'}</label><br /></React.Fragment>) },

    { key: 'PartsPerCycle', label: 'Parts / Cycle', content: (obj) => obj.Operation.map(one => <React.Fragment><label>{one.PartsPerCycle ? one.PartsPerCycle : '-'}</label><br /></React.Fragment>) },
    //{key:'CyclePerParts',label:'Cycles / Part',content:(obj)=>obj.Operation.map(one=><React.Fragment><label>{one.CyclePerParts?one.CyclePerParts:'-'}</label><br/></React.Fragment>)},

    { key: 'createdAt', label: 'Created Date', content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.createdAt)}</label><br /></React.Fragment> },
    { key: 'updatedAt', label: 'Last Updated Date', content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.updatedAt)}</label><br /></React.Fragment> },
    { key: 'update', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights', 'ComponentMaster', 'update')} onClick={() => this.props.onUpdate(user)} className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faEdit} /></button> },
    // { key: 'view', content: user => <button onClick={() => this.props.onView(user)} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faEye} /></button> },
    { key: 'delete', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights', 'ComponentMaster', 'write')} onClick={() => this.onDelete(user)} className="btn btn-sm btn-danger"><FontAwesomeIcon icon={faTrash} /></button> }

  ]

  handleSort = sortColumn => {
    // consol(sortColumn);
    if (this.state.componentList.length > 0) {
      const sorted = _.orderBy(this.state.componentList, [sortColumn.path], [sortColumn.order]);
      this.setState({ sortColumn, componentList: sorted });
    }
  }


  handleUploadGuagesDoc = () => {
    let link = document.createElement('input');
    link.setAttribute('type', 'file');
    link.setAttribute('name', 'ComponentList');
    // link.setAttribute('onChange', handleOnChangeFile);
    let self = this;
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      e.preventDefault();

      let fr = new FileReader();

      // if (e.currentTarget.files[0].size < 51200 && e.currentTarget.files[0].size > 2000) {
      fr.readAsBinaryString(e.currentTarget.files[0]);
      fr.onloadend = async function (e) {
        const wb = XLSX.read(e.target.result, { type: 'binary', cellDates: true });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let dataArray = []
        let keyArr = data[0]
        let arr = data.slice(1, data.length)

        arr.forEach((oneArr) => {
          if (oneArr.length > 0) {
            let obj = {}
            oneArr.forEach((value, i) => {
              if (keyArr[i] == 'Tool') {
                if (value && value !== '') {
                  obj.ToolRequiredToProduce = true;
                  value = value.split('#');
                  obj[[keyArr[i]]] = value;
                } else {
                  obj.ToolRequiredToProduce = false;
                }

              } else if (keyArr[i] == 'OperationRejectionCost') {
                obj['operationRejectionCost'] = value
              } else {
                obj[[keyArr[i]]] = value.trim();
              }
            })
            if (obj.Tool) {
              obj.ToolRequiredToProduce = true;
            } else {
              obj.ToolRequiredToProduce = false;
            }
            dataArray.push(obj);
          }

        })

        let finalDataArr = []
        for (let i = 0; i < dataArray.length; i++) {
          if (finalDataArr.length == 0) {
            let obj1 = { ...dataArray[i] }
            delete obj1.ComponentId;
            delete obj1.Alias;
            delete obj1.CustomerName;
            let obj = { ComponentId: dataArray[i].ComponentId,Alias:dataArray[i].Alias,CustomerName:dataArray[i].CustomerName, Operation: [] };
            obj.Operation.push(obj1)
            finalDataArr.push(obj);
          } else {
            let filterData = finalDataArr.filter(one => one.ComponentId == dataArray[i].ComponentId)
            if (filterData.length > 0) {
              let obj = { ...dataArray[i] }
              delete obj.ComponentId;
              delete obj.Alias;
              delete obj.CustomerName;
              finalDataArr.filter(one => one.ComponentId == dataArray[i].ComponentId)[0].Operation.push(obj)
            } else {
              let obj1 = { ...dataArray[i] }
              delete obj1.ComponentId;
              delete obj1.Alias;
              delete obj1.CustomerName;
              let obj = { ComponentId: dataArray[i].ComponentId,Alias:dataArray[i].Alias,CustomerName:dataArray[i].CustomerName, Operation: [] };
              obj.Operation.push(obj1)
              finalDataArr.push(obj);
            }
          }
        }
        finalDataArr.forEach((one)=>{
          one.ComponentId=one.ComponentId?one.ComponentId.toString():one.ComponentId;
          one.Alias=one.Alias?one.Alias.toString():one.Alias;
          one.NumberOfOperations=one.Operation.length;
          let opSerial=1
          one.Operation.forEach((op,)=>{
            op.OperationSerial=opSerial;
            opSerial++;
          })
        })


        self.setState({ excelData: finalDataArr });
        await self.sendDataToServer(finalDataArr, finalDataArr, self.state.arrayProcessIndex)
        // consol("Data>>>" + finalDataArr);
      };
      // }
      // else {
      //   swal('File Size Should Be Between 2KB to 50KB');
      // }
    }
    document.body.removeChild(link);

  }

  schemaFull = {
    ComponentId: joi.string().required().label("Component Id"),
    CustomerName: joi.string(),
    Alias:joi.string(),
    NumberOfOperations: joi.number().required().label("No Of Operations"),

    Operation: joi
      .array()
      .items(
        joi.object().keys({
          Tool: joi
            .array().min(1).unique()
            .when("ToolRequiredToProduce", { is: true, then: joi.required() }),
          OperationID: joi.string().required(),
          OperationSerial: joi.number().min(0).required(),
          EquipmentType: joi.string().required(),
          operationRejectionCost:joi.number(),
         // NetWeightPerComponent: joi.number().min(1).required(),
          ToolRequiredToProduce: joi.bool().required(),
          PartsPerCycle:  joi.number().min(0).when("ToolRequiredToProduce", { is: false, then: joi.required() }),
          //CyclePerParts: joi.number(),
          // ScrapWeightCycle: joi
          //   .number()
          //   .min(1)
          //   .when("ToolRequiredToProduce", { is: false, then: joi.required() }),
          IdealCycleTime: joi
            .number()
            .min(1)
            .when("ToolRequiredToProduce", { is: false, then: joi.required() }),
          //CycleTime:joi.number().min(0).when('ToolRequiredToProduce',{is:false,then:joi.required()})
        })
      )
      .unique('OperationID')
      .min(1)
      .required()
      .label("Operations"),
  };



  sendDataToServer = async (EquipsArr, originalDataArray, idx) => {
    this.setState({ querySuccess: true })

    for (let index = idx; index < EquipsArr.length; index += 1) {
      try {
        let error = valid.validate(EquipsArr[index], this.schemaFull);
        if (error !== null) {
          let row = index + 1
          this.setState({ errors: error, querySuccess: false, errorModal: true, rowNumber: row });
          break;

        } else {
          let res = await axios.post(api.api + 'UploadExcelComponentData', { row: index + 1, data: EquipsArr[index] })
          await (async () => {
            const result = await axios.get(
              api.api1 + 'component'
            );
            // setQuerySuccess(false)
            this.setState({ componentList: result.data });
          })();
          if (res.data == 'Insert') {
            toast.success('🦄 Save Component For - ' + EquipsArr[index].ComponentId, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

        }
      }
      catch (err) {

        if (err.response.data.code == 11000) {
          this.AbortAndResumeProcess(EquipsArr, originalDataArray, index)
        } else {
          let message = JSON.parse(err.response.data.message);
          this.setState({ querySuccess: true, objKey: message.key, errorMessage: message.message, errorFlag: true })
          this.insertIfNotExistDataTypeAndResumeProcess(message.message, message.key.toLowerCase(), message.key, EquipsArr[index][message.key], originalDataArray, index);
        }
        break;
      }

    }
    this.setState({ querySuccess: false })
  }

  AbortAndResumeProcess = (dataArray, originalArr, index) => {
    let row = index + 1;
    swal({
      title: "Are you sure?",
      text: ' This component already exist skip this component!  ' + dataArray[index].ComponentId + "  at row number " + row,
      icon: "warning",
      buttons: ["Abort Process", "Skip Continue"],
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        index = index + 1;
        this.sendDataToServer(dataArray, originalArr, index)
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index })
        swal('Process  Abort', { dangerMode: true });

        // swal("Your imaginary file is safe!");
      }
    });
  }



  insertIfNotExistDataTypeAndResumeProcess = (errorMessage, url, key, value, dataArray, index) => {
    swal({
      title: "Are you sure?",
      text: `${errorMessage}` + '  , Do you want to skip this component ?  ',
      icon: "error",
      buttons: ["Abort Process", "Skip Continue"],
      dangerMode: true
    }).then(willDelete => {
      if (willDelete) {
        this.setState({ errorFlag: false });
        index = index + 1;
        this.sendDataToServer(dataArray, dataArray, index)
      } else {
        this.setState({ querySuccess: false, arrayProcessIndex: index })
        swal('Process  Abort')

        // swal("Your imaginary file is safe!");
      }
    });
  }



  handleDownloadFormat = () => {
    axios.get(api.api + 'ComponentMasterTemplate')
      .then((result) => {
        if (result.status == 200) {
          // const url = window.URL.createObjectURL(new Blob([result.data]));
          let link = document.createElement('a');
          link.setAttribute('href', result.data);
          link.setAttribute('download', 'ComponentMasterFormat.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        else {
          swal('Error In Downloading File')
        }
      })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
  }

  handleHideModal = () => {
    this.setState({ errorModal: false })
  }



  render() {


    return (
      <React.Fragment>
        {this.state.querySuccess ? <Loading /> : ''}
        <ToastContainer />
        <Modal
          show={this.state.errorModal}
          onHide={this.handleHideModal}
          backdrop="static"
          keyboard={false}
          size='sm'
        >
          <Modal.Header closeButton>
            <Modal.Title>Equipment Validate Error List</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {Object.keys(this.state.errors).map(key => {
              return <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors[key] + "  " + "Row Number  " + this.state.rowNumber}</p>
            })}
          </Modal.Body>
          {/* <Modal.Footer>
                <Button className='bg-primary' variant="secondary" >
                    Save
        </Button>
                <Button variant="secondary" onClick={handleHideModal}>
                    Close
        </Button>

            </Modal.Footer> */}
        </Modal>
        <div className='text-center'>
          <p>Component Master</p>
        </div>
        {/* {this.props.getRightsForAccess('StaticDataRights', 'ComponentMaster', 'write') ? <a style={{ cursor: 'pointer' }} className='float-right common-anchor'
          onClick={() => {
            this.props.history.push('/setting/component/add');
            this.props.handleOnAdd('/setting/component/add', 'add', 'componentList', this.state.componentList)
          }}>
          <FontAwesomeIcon size='1x' icon={faPlus} /> Add</a> : ''} */}

        {this.props.getRightsForAccess('StaticDataRights', 'ComponentMaster', 'write') ? <a
          style={{ cursor: "pointer", color: 'white' }}
          className="float-right btn btn-sm btn-primary "
          onClick={this.handleUploadGuagesDoc}
        >
          {" "}
          <FontAwesomeIcon size="1x" icon={faFileImport} /> Import Excel
        </a> : ''}

        {this.props.getRightsForAccess('StaticDataRights', 'ComponentMaster', 'write') ? <a
          style={{ cursor: "pointer", color: 'white' }}
          className="btn btn-sm btn-primary ml-2"
          onClick={() => {
            this.props.history.push('/setting/component/add');
            this.props.handleOnAdd('/setting/component/add', 'add', 'componentList', this.state.componentList)
          }}
        >
          {" "}

          <FontAwesomeIcon size="1x" icon={faPlus} /> Add
        </a> : ''}
        {this.state.componentList.length < 0 ? "Data Not Available" : <Table columns={this.columns} onSort={this.handleSort} sortColumn={this.state.sortColumn} data={this.state.componentList} />}
      </React.Fragment>

    );
  }
}

export default ComponentTable;
