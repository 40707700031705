import React, { useState, useEffect ,useRef} from 'react';
import Table from '../Common/table';
import ColumnChart from '../Charts/ColumnChart';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import 'bootstrap/dist/js/bootstrap'
import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import { Chart } from 'react-google-charts';
import common from '../utill/common';
import TableForDowntimeAnalysis from './../utill/tableForMachineDowntimeAnalysis';
import EquipmentSelection from '../Common/equipmentSelection';
import DateSelection from '../Common/DateSelection';
import { ToastContainer, toast } from 'react-toastify';
import DownloadButton from '../utill/ChartImageDownload';

const DowntimeMachineTrend = ({ user, equipGroups, reasons,fromDateLimit,storeDataInLocalStorage }) => {
    const [availabilityData, setAvailabilityData] = useState([]);
    const [data, setData] = useState([])
    const [inputData, setInputData] = useState({})
    // const [equip, setEquip] = useState([])
    const [equip, SetEquips] = useState([])

    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [selectedReason, setSelectedReason] = useState([])
    const [errors, setErrors] = useState({})
    const [querySuccess, setQuerySuccess] = useState(false)
    const [colorSeries, setColorSeries] = useState({});
    const [tableData, setTableData] = useState([]);
    const [TableDataForCumulative, setTableDataForCumulative] = useState([]);
    const [TableDataForEquipment,setTableDataForEquipment] = useState([]);
    const [Checkbox, setCheckbox] = useState(false);
    const [Checkbox1, setCheckbox1] = useState(false);
    const [LoadingIndex, setLoadingIndex] = useState(0);

    

    const chartRef = useRef(null);
    const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })

    const schema = {
        From: joi.date().required().label('From'),
        Till: joi.date().required().label('Till'),
        // Equipment: joi.array().min(1).required(),
        EquipmentGroup: joi.array().min(1).required(),
        Reason: joi.array().min(1).required(),
        Periodicity:joi.string().required(),
        UserId: joi.string(),
    }
    const handleOnChange = async (e) => {

        if (e.currentTarget.type === 'date') {
            // consol(new Date(e.currentTarget.value))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
            setInputData(inputData);
        }
        else if (e.currentTarget.type === 'time') {
            let Time = e.currentTarget.value.split(':');
            // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
            setInputData(inputData);
        }
        else if (e.currentTarget.name === 'EquipmentGroup') {
            inputData[e.currentTarget.name] = e.currentTarget.value;
            let filterObj = equipGroups.filter(one => one.EquipmentGroup == e.currentTarget.value)[0]
            let res = await axios.put(api.api1 + 'equipment', { EquipmentGroup: filterObj._id })
            SetEquips(res.data)

            setInputData(inputData);
        }
        else {
            inputData[e.currentTarget.name] = e.currentTarget.value
        }
        setErrors({})

    }
useEffect(() => {
  let CumulativeData =  [...TableDataForCumulative]
  let EquipmentData = [...TableDataForEquipment]
if(Checkbox ===true){
    setTableData(EquipmentData) 
    
}else{
    setTableData(CumulativeData) 
}
}, [Checkbox,Checkbox1])

    const organiseDowntimeData = (bufferArr) => {
        // [
        //     ['City', '2010 Population', '2000 Population'],
        //     ['New York City, NY', 8175000, 8008000],
        //     ['Los Angeles, CA', 3792000, 3694000],
        //     ['Chicago, IL', 2695000, 2896000],
        //     ['Houston, TX', 2099000, 1953000],
        //     ['Philadelphia, PA', 1526000, 1517000],
        // ]
        let schema = ['Date']
        let arr = [];
        let colorWithReason = []
        let seriesObj = {}
        bufferArr.forEach(element => {
            element.Downtime.forEach((one, i) => {
                let IsAlready;
                let obj = {}

                if (one.DowntimeReason) {

                    IsAlready = schema.includes(one.DowntimeReason);
                } else {

                    IsAlready = schema.includes(one.State);
                }
                if (!IsAlready) {
                    if (one.DowntimeReason) {
                        obj.Reason = one.DowntimeReason;
                        obj.Color = one.Color;
                        colorWithReason.push(obj)
                        schema.push(one.DowntimeReason)
                        schema.push({ role: 'style' })
                    } else {
                        obj.Reason = one.State;
                        obj.Color = 'red';
                        colorWithReason.push(obj)
                        schema.push(one.State)
                        schema.push({ role: 'style' })
                    }
                }
            })
        });

        bufferArr.forEach(element => {
            let oneArr = []
            if (element.Downtime.length > 0) {
                if(inputData.Periodicity=='Weekly'||inputData.Periodicity=='Monthly'){
                    oneArr = [date.getDateFormat(new Date(element.DateTime)) +'-'+date.getDateFormat(new Date(element.DateTime1))+ '/' + element.Shift]
                }else{
                    oneArr = [date.getDateFormat(new Date(element.DateTime)) + '-' + element.Shift]
                }
            }
            let down = element.Downtime;
            down = aggreagteReasonDowntime(down)
            for (let i = 1; i < schema.length; i = i + 2) {
                let filterDown = down.filter((one) => {
                    if (one.DowntimeReason) {
                        if (one.DowntimeReason == schema[i]) {
                            return one
                        }
                    } else {
                        if (one.State == schema[i]) {
                            return one
                        }
                    }
                }
                )
                if (filterDown.length > 0) {
                    oneArr.push(Math.ceil(filterDown[0].Duration / 60));
                    oneArr.push(filterDown[0].Color)

                } else {
                    let filterColor = colorWithReason.filter(color => color.Reason == schema[i])
                    oneArr.push(0);
                    oneArr.push(filterColor[0].Color)
                }
            }
            arr.push(oneArr)
        })
        arr.unshift(schema)
        colorWithReason.forEach((one, i) => {
            seriesObj[i] = { color: one.Color }
        })
        let finalarr=[]
        if(arr.length>1){
            let length=arr[0].length;
            arr.forEach((one)=>{
                if(one.length==length){
                    finalarr.push(one)
                }
            })
        }

        let returnObj = { arr: finalarr, color: seriesObj }
        return returnObj
    }

    function aggreagteReasonDowntime(bufferArry) {

        for (let i = 0; i < bufferArry.length; i++) {
            for (let j = i + 1; j < bufferArry.length; j++) {
                if (bufferArry[i].DowntimeReason === bufferArry[j].DowntimeReason) {
                    bufferArry[i].Duration += bufferArry[j].Duration
                    bufferArry.splice(j, 1);

                    // i--;
                    j--;
                    // i=j-1
                }
            }
        }
        return bufferArry
    }
    const handleDownload = (e, date) => {
        generateExcelFile(e, date)
    }

    const handleOnSubmit = async (inputData) => {
        inputData.EquipmentGroup = selectedEquipGroupOption;
        let Equipments = [...selectedEquipOption];
        Equipments.shift();
        inputData.Reason = selectedReason;
        setQuerySuccess(true);
        inputData.UserId = user._id;
        const errors = validation.validate(inputData, schema); //
        setErrors(errors || {}); 
        if (errors) return  setQuerySuccess(false); 
        let totaldata = [];
    
        try {
            for (let i = 0; i < Math.ceil(Equipments.length / 1); i++) {
                let startIndex = i * 1;
                let endIndex = (i + 1) * 1;
                let senddata = Equipments.slice(startIndex, endIndex);
                setLoadingIndex(i);
                // Create a new copy of inputData for each iteration
                let inputDataCopy = { ...inputData };
                inputDataCopy['Equipment'] = senddata;
          
                // Make the Axios request
                let result = await axios.post(api.api + 'GetDowntimeMachineTrend', inputDataCopy);
                if (result.data.length > 0) {
                    let cloneArr = [...result.data];
                    // setAvailabilityData(result.data);
                    result = organiseDowntimeData(result.data);
                    let cloneArr1 = await handleGetDataForTable(cloneArr);
                    cloneArr1.forEach(item => totaldata.push(item));
                    // setData(result.arr)
                    // setColorSeries(result.color);
                } else {
                    swal('Data Not Available');
                    setQuerySuccess(false);
                }
            }
            setTableData(totaldata)
            setTableDataForEquipment(totaldata);

            setQuerySuccess(false);
        } catch (err) {
              toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
            setQuerySuccess(false);
        }

    let inputDataCopy1 = { ...inputData };
    inputDataCopy1.Equipment =selectedEquipOption
        axios.post(api.api + 'GetDowntimeMachineTrend', inputDataCopy1).then(async (result) => {
            if (result.data.length > 0) {
                let cloneArr = [...result.data];
                setAvailabilityData(result.data);
                result = organiseDowntimeData(result.data)
                cloneArr = await handleGetDataForTable(cloneArr)
                setTableDataForCumulative(cloneArr)
            setCheckbox1(!Checkbox1)

                setData(result.arr)
                setColorSeries(result.color);
                setQuerySuccess(false)
            }
            else {
                swal('Data Not Available');
                setQuerySuccess(false)
            }
        })
            .catch((err) => {
                  toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                setQuerySuccess(false)
                // consol(err)
            })
    }
    const handleDonwLoad = (e, date) => {
        generateExcelFile(e, date)
    }

    const handleSort = (sortColumn) => {
        if (availabilityData.length > 0) {
            const sorted = _.orderBy(
                availabilityData,
                [sortColumn.path],
                [sortColumn.order]
            );
            setAvailabilityData(sorted)
            setSortColumn(sortColumn)
        }
    }

    const handleOnChangeSelect = async (e, value, key) => {
        let option;
        if (key == 'EquipmentGroup') {
            option = [...selectedEquipGroupOption];
        }
        if (key == 'Equipment') {
            option = [...selectedEquipOption];
        }
        if (key == 'Reason') {
            option = [...selectedReason];
        }
        if (value == 'All') {
            if (e.currentTarget.checked) {
                let obj = {}
                obj.label = value;
                obj.value = e.currentTarget.id;
                option.push(obj);
                if (key == 'Equipment') {
                    equip.forEach(one => {
                        let obj = {}
                        obj.label = one['EquipmentID'];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }
                else if (key == 'Reason') { 
                    option.push({label:'Down',value:'Down'})
                    reasons.forEach(one => {
                        let obj = {}
                        obj.label = one['Reason'];
                        obj.value = one._id;
                        option.push(obj);
                    })
                } else {
                    equipGroups.forEach(one => {
                        let obj = {}
                        obj.label = one[e.currentTarget.name];
                        obj.value = one._id;
                        option.push(obj);
                    })
                }

            } else {
                option.splice(0, option.length);
            }
        } else {
            if (e.currentTarget.checked) {
                let obj = {}
                obj.label = value;
                obj.value = e.currentTarget.id;
                option.push(obj);

            } else {
                option = option.filter(one => one.value !== e.currentTarget.id);

            }
        }
        let query = []
        option.forEach(one => {
            let obj = {}
            if (one.value !== 'All') {
                obj.EquipmentGroup = one.value;
                query.push(obj)
            }
        })

        option = _.uniqWith(
            option,
            (a, b) =>
                a.value === b.value
        );
        if (key == 'EquipmentGroup') {
            if (query.length > 0) {
                let res = await axios.put(api.api1 + 'equipment', { $or: query })
                SetEquips(res.data)
            }

            setSelectedEquipGroupOption(option);
        }
        if (key == 'Equipment') {
            setSelectedEquipOption(option)
        }
        if (key == 'Reason') {
            setSelectedReason(option)
        }

    }

    const getCheckeStatus = (value, key) => {
        let option;
        if (key == 'EquipmentGroup') {
            option = [...selectedEquipGroupOption];
        }
        if (key == 'Equipment') {
            option = [...selectedEquipOption];
        }
        if (key == 'Reason') {
            option = [...selectedReason];
        }
        let filterData = option.filter(one => value == one.value)
        if (filterData.length > 0) {
            return true
        } else {
            return false
        }
    }
   
    const handleGetDataForTable = async (equips) => {
        // let res = await axios.get(api.api + 'downtimereason');
        let res = await storeDataInLocalStorage('downtimereason')

        let reasons = res.push({ Reason: null })
        equips.forEach((oneEquip) => {
            let arr = []
            res.forEach((one1) => {
                let obj = {}
                let filterObj = oneEquip.Downtime.filter(one => one.DowntimeReason == one1.Reason);
                if (filterObj.length > 0) {
                    obj.Duration = filterObj[0].Duration;
                    obj.Reason = filterObj[0].DowntimeReason;
                    obj.Reason = obj.Reason === null ? 'Down' : obj.Reason;

                } else {
                    obj.Duration = 0;
                    obj.Reason = one1.Reason;
                }

                arr.push(obj)
            })
            oneEquip.Downtime = arr.sort(function (a, b) {
                if (a.Reason < b.Reason) {
                    return -1;
                }
                else if (a.Reason > b.Reason) {
                    return 1;
                }
                return 0;
            });
        })
        return equips;

    }


    const chartRender = (data) => {
        let mac = '';
        selectedEquipOption.forEach(one => {
            if (one.value !== 'All') {
                mac = one.label + ' , ' + mac;
            }
        })


        return <Chart
            width={'100%'}
            height={450}
            style={{ padding: 0 }}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
                title: 'Downtime Analysis For ' + mac,
                // chartArea: { width: '50%' },
                isStacked: true,
                series: colorSeries,
                hAxis: {

                    title: 'Period',
                }, 
                vAxis: {

                    title: 'Duration (In Minutes)',
                    minValue: 0,

                },

            }}
            // For tests
            rootProps={{ 'data-testid': '3' }}
        />
    }


    const getCount = (data) => {
        let count = 0
        if (data.filter(one => one.value == 'All').length > 0) {
            count = data.length - 1;
        } else {
            count = data.length;
        }

        return count
    }
    const setSelection=(key,option,equips)=>{
        if(key=='EquipmentGroup'){
            SetEquips(equips?equips:[]);
            setSelectedEquipGroupOption(option)
            setSelectedEquipOption([])
        }
        if(key=='Equipment'){
           
            setSelectedEquipOption(option)
        }
    }
    const SelectedDates =(dates)=>{
        setInputData(dates)
      }
      const handleOnChangeView =(e)=>{
setCheckbox(e.currentTarget.checked)
      }
    return (
        <React.Fragment>       <ToastContainer/>
            {/* {querySuccess ? <Loading /> : ''} */}
        {querySuccess ? <Loading labelShow={true} label={`${LoadingIndex}/${selectedEquipOption.length}`} /> : ''}

            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Downtime Machine Trend
                </h4>
            </div>
            <div className="row ml-2">  { equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}
                {/* <div className="col-sm-3">
                    <div className='form-group text-center'>
                        <label className='small'>Equipment Group</label>
                        <div id="list1" className="dropdown p-0 m-0  form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0  btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {getCount(selectedEquipGroupOption) > 0 ? getCount(selectedEquipGroupOption) + ' Equipment Group' : 'Select Equipment Group'}
                            </button>
                            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'EquipmentGroup')} onChange={(e) => handleOnChangeSelect(e, 'All', 'EquipmentGroup')} id='All' name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;All </li>
                                {equipGroups.map((one) => {
                                    return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'EquipmentGroup')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentGroup, 'EquipmentGroup')} name='EquipmentGroup' />&nbsp;&nbsp;&nbsp;{one.EquipmentGroup} </li>
                                })}
                            </ul>
                        </div>
                        {errors.EquipmentGroup && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.EquipmentGroup}</p>}

                    </div>
                </div>
                <div className="col-sm-3">
                    <div className='form-group text-center'>
                        <label className='small'>Equipment</label>
                        <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {getCount(selectedEquipOption) > 0 ? getCount(selectedEquipOption) + ' Equipment' : 'Select Equipment'}
                            </button>
                            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'Equipment')} onChange={(e) => handleOnChangeSelect(e, 'All', 'Equipment')} id='All' name='Equipment' />&nbsp;&nbsp;&nbsp;All </li>
                                {equip.map((one) => {
                                    return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'Equipment')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.EquipmentID, 'Equipment')} name='Equipment' />&nbsp;&nbsp;&nbsp;{one.EquipmentID} </li>
                                })}
                            </ul>
                        </div>
                        {errors.Equipment && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Equipment}</p>}


                    </div>
                </div> */}

                <div className="col-sm-2">
                    <div className='form-group text-center'>
                        <label className='small'>Downtime Reason</label>
                        <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm" style={{ display: 'inherit' }} >
                            <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {getCount(selectedReason) > 0 ? getCount(selectedReason) + ' Reason' : 'Select Reason'}
                            </button>
                            <ul className="dropdown-menu" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('All', 'Reason')} onChange={(e) => handleOnChangeSelect(e, 'All', 'Reason')} id='All' name='Reason' />&nbsp;&nbsp;&nbsp;All </li>
                                <li className="dropdown-item"><input type="checkbox" checked={getCheckeStatus('Down', 'Reason')} onChange={(e) => handleOnChangeSelect(e, 'Down', 'Reason')} id='Down' name='Reason' />&nbsp;&nbsp;&nbsp;Down </li>
                                {reasons.map((one) => {
                                    return <li className="dropdown-item"> <input type="checkbox" checked={getCheckeStatus(one._id, 'Reason')} id={one._id} onChange={(e) => handleOnChangeSelect(e, one.Reason, 'Reason')} name='Reason' />&nbsp;&nbsp;&nbsp;{one.Reason} </li>
                                })}
                            </ul>
                        </div>
                        {errors.Reason && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Reason}</p>}


                    </div>
                </div>
                <div className="col-sm-2">
                    <div className='form-group text-center'>
                        <label className='small'>Periodicity</label>
                        <select className='form-control form-control-sm' onChange={handleOnChange} name='Periodicity'>
                        <option>--Select Periodicity--</option>
                            <option>Shift</option>
                            <option>Daily</option>
                            <option>Weekly</option>
                            <option>Monthly</option>
                        </select>
                        {errors.Periodicity && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Periodicity}</p>}
                    </div>
                </div>
            {/* </div>
            <div className='row ml-2'> */}
      <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

                {/* <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>From</label>
                        <input className='form-control form-control-sm' type='date' min={fromDateLimit} onChange={handleOnChange} name='From' />
                        {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                    </div>
                    </div> */}
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
                {/* <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Till</label>
                        <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                        {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                    </div>
                    </div> */}
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>
                <div className="col-sm-3">             <div className="my-4 form-check form-switch d-flex">
                   <div>
                    <label className="form-check-label  mx-2" for="flexSwitchCheckDefault"> Cumulative</label></div>
 
<div>
  <input className="form-check-input  mx-2" type="checkbox" checked={Checkbox} onChange={handleOnChangeView} role="switch" id="flexSwitchCheckDefault"/></div>
  <div><label className="form-check-label  mx-5" for="flexSwitchCheckDefault">Equipment wise</label></div>
</div>
      
                </div>
        

                {/* {availabilityData.length > 0 ? <div className="col-sm-2">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDonwLoad('Availability', inputData.DateTime)}>Download Excel</button>
                </div> : ''} */}
            </div>
            <DownloadButton targetRef={chartRef} filename="chart.png" />
            <div ref={chartRef}>
                {availabilityData.length > 0 ? chartRender(data) : ''}


            </div>
            { tableData.length > 0 ? <div className="col-sm-2">
                <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('MachineDowntimeAnalysisTrend')}>Download Excel</button>
            </div> : ''}
            <div>
                {tableData.length > 0 ? <TableForDowntimeAnalysis id='MachineDowntimeAnalysisTrend' periodicity={inputData.Periodicity} data={tableData} Checkbox={Checkbox} /> : ''}
            </div>
        </React.Fragment>


    );
}

export default DowntimeMachineTrend;