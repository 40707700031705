import React from 'react';
import { Chart } from 'react-google-charts';
import  api from '../config.json';

const MacStateForJobUpdate = ({data}) => {
    const getCurrentDateTime = dateTime => {
        let currentDate = new Date(dateTime);
        // currentDate.setHours(currentDate.getHours()+5);
        // currentDate.setMinutes(currentDate.getMinutes()+30);
        let CurrentDateTime =
          currentDate.getDate() +
          "-" +
          ("0" + (currentDate.getMonth() + 1)).slice(-2) +
          "-" +
          currentDate.getFullYear() +
          "   |   " +
          currentDate.getHours() +
          ":" +
          (currentDate.getMinutes() < 10 ? "0" : "") +
          currentDate.getMinutes();
        //   ":" +
        //   currentDate.getSeconds();
        return CurrentDateTime;
      };
    
      const getTimeDuration = (start, end) => {
        let from = new Date(start);
        let till = new Date(end);
    
        //  let duration=new Date(till.getTime()-from.getTime()).getHours()<=0?new Date(till.getTime()-from.getTime()).getMinutes()+' '+'Mins':new Date(till.getTime()-from.getTime()).getHours()+' '+'Hours, '+new Date(till.getTime()-from.getTime()).getMinutes()+' '+'Mins';
        let duration = Math.round(
          Math.abs(new Date(till.getTime() - from.getTime()) / 6e4)
        );
        let min = duration % 60;
        let hour = Math.floor(duration / 60);
    
        return `${hour} Hour ` + ` ${min} Min`;
      };
    const organiseTimeLineDataWithJobs = (list) => {
        let timeLineArray = [];
        let chartSchema = [
          { type: "string", id: "MachineName" },
          { type: "string", id: "State" },
          { type: "string", role: "tooltip", p: { html: true } },
          { type: "string", role: "style" },
          { type: "date", id: "Start" },
          { type: "date", id: "End" }
        ];
        timeLineArray.push(chartSchema);
        let machineNameArray = [];
    
        for (let i = 0; i < list.length; i++) {
          let machineName = list[i].Equipment;
    
          for (let j = 0; j < list[i].ListOfEvents.length; j++) {
            var oneArray = [];
            oneArray.push(machineName);
            if (list[i].ListOfEvents[j].State == "Running") {
              if (list[i].ListOfEvents[j].DowntimeReason == null || list[i].ListOfEvents[j].DowntimeReason == 'null') {
                oneArray.push(list[i].ListOfEvents[j].State);
                oneArray.push(
                  `<p><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${getCurrentDateTime(
                    list[i].ListOfEvents[j].From
                  )}</td></tr><tr><td>Till</td><td>${getCurrentDateTime(
                    list[i].ListOfEvents[j].Till
                  )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
                  }</td></tr><tr><td>Duration</td><td>${getTimeDuration(
                    list[i].ListOfEvents[j].From,
                    list[i].ListOfEvents[j].Till
                  )}</td></tr></table></p>`
                );
                oneArray.push("#0F9D58");
              } else {
                oneArray.push(list[i].ListOfEvents[j].State);
                oneArray.push(
                  `<p><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>$getCurrentDateTime(
                    list[i].ListOfEvents[j].From
                  )}</td></tr><tr><td>Till</td><td>${getCurrentDateTime(
                    list[i].ListOfEvents[j].Till
                  )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
                  }</td></tr><tr><td>Duration</td><td>${getTimeDuration(
                    list[i].ListOfEvents[j].From,
                    list[i].ListOfEvents[j].Till
                  )}</td></tr></table></p>`
                );
                oneArray.push("#F4B400");
              }
    
            } else if (
              list[i].ListOfEvents[j].State == "Down"
            ) {
              oneArray.push(list[i].ListOfEvents[j].State);
              oneArray.push(
                `<p><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${getCurrentDateTime(
                  list[i].ListOfEvents[j].From
                )}</td></tr><tr><td>Till</td><td>${getCurrentDateTime(
                  list[i].ListOfEvents[j].Till
                )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
                }</td></tr><tr><td>Duration</td><td>${getTimeDuration(
                  list[i].ListOfEvents[j].From,
                  list[i].ListOfEvents[j].Till
                )}</td></tr></table></p>`
              );
              oneArray.push("#DB4437");
            }
            else if (
              list[i].ListOfEvents[j].State == "Break"
            ) {
              oneArray.push(list[i].ListOfEvents[j].State);
              oneArray.push(
                `<p><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${getCurrentDateTime(
                  list[i].ListOfEvents[j].From
                )}</td></tr><tr><td>Till</td><td>${getCurrentDateTime(
                  list[i].ListOfEvents[j].Till
                )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
                }</td></tr><tr><td>Duration</td><td>${getTimeDuration(
                  list[i].ListOfEvents[j].From,
                  list[i].ListOfEvents[j].Till
                )}</td></tr></table></p>`
              );
              oneArray.push("#F4B400");
            }
    
            else {
              oneArray.push(list[i].ListOfEvents[j].State);
              oneArray.push(
                `<p><table><tr><td>Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${getCurrentDateTime(
                  list[i].ListOfEvents[j].From
                )}</td></tr><tr><td>Till</td><td>${getCurrentDateTime(
                  list[i].ListOfEvents[j].Till
                )}</td></tr><tr><td>Event</td><td>${list[i].ListOfEvents[j].State
                }</td></tr><tr><td>Duration</td><td>${getTimeDuration(
                  list[i].ListOfEvents[j].From,
                  list[i].ListOfEvents[j].Till
                )}</td></tr></table></p>`
              );
              oneArray.push(list[i].ListOfEvents[j].Color);
            }
    
            // let from=new Date(this.props.timeLineData[i].ListOfEvents[j].From);
            // let to=this.props.timeLineData[i].ListOfEvents[j].Till===undefined?new Date():new Date(this.props.timeLineData[i].ListOfEvents[j].Till);
            oneArray.push(
              new Date(list[i].ListOfEvents[j].From)
            );
            oneArray.push(
              new Date(list[i].ListOfEvents[j].Till)
            );
            // let dateFrom=new Date(this.props.timeLineData[i].ListOfEvents[j].From);
    
            // oneArray.push(dateFrom.getDate()+'-'+(dateFrom.getMonth()+1)+'-'+dateFrom.getFullYear());
            timeLineArray.push(oneArray);
          }
          //State Event Eend
    
          //Jobs Array Start
          if (list[i].Jobs) {
            for (let j = 0; j < list[i].Jobs.length; j++) {
              var oneArray = [];
              oneArray.push(machineName);
              let job = 'Job - ' + list[i].Jobs[j].Component + ' | ' + list[i].Jobs[j].OperationID + ' | ' + (list[i].Jobs[j].ToolID !== 'null' ? list[i].Jobs[j].ToolID : 'NA')
              oneArray.push(job);
              oneArray.push(
                `<p><table><tr><td >Machine</td><td>${machineName}</td></tr><tr><td>From</td><td>${getCurrentDateTime(
                  list[i].Jobs[j].From
                )}</td></tr><tr><td>Till</td><td>${getCurrentDateTime(
                  list[i].Jobs[j].Till
                )}</td></tr><tr><td>Event</td><td>${job
                }</td></tr><tr><td>Duration</td><td>${getTimeDuration(
                  list[i].Jobs[j].From,
                  list[i].Jobs[j].Till
                )}</td></tr></table></p>`
              );
              oneArray.push(getRandomColor(1,4));
              oneArray.push(
                new Date(list[i].Jobs[j].From)
              );
              oneArray.push(
                new Date(list[i].Jobs[j].Till)
              );
              timeLineArray.push(oneArray);
            }
          }
    
          ///Job Array End
        }
    
        return timeLineArray;
      };

     const  getRandomColor=(min,max)=> {
     
        min = Math.ceil(min);
        max = Math.floor(max);
        let objKeys=Object.keys(api.JobColour[Math.floor(Math.random() * (max - min + 1)) + min])
        return api.JobColour[Math.floor(Math.random() * (max - min + 1)) + min][objKeys[0]] ;
      
      }

    return ( 
        <Chart
      
        // height={window.screen.availHeight-(window.screen.availHeight/3)}
        //width={'100%'}
        //style={{minHeight:500}}
        // style={{width:'100%',}
       
        graphID='timeline1'
        style={{
          width: "100%",
          marginRight: 5,
          // height: window.screen.height - window.screen.height / 3
        }}

       // chartEvents={this.chartEvents}
        chartType="Timeline"
        loader={<div>Loading Chart</div>}
        data={organiseTimeLineDataWithJobs(data)}
        options={{
          tooltip: { isHtml: true },
          //chartArea:{width:'100%',height:'100%'}
          timeline: {
            rowLabelStyle: { fontSize: 9 },
            barLabelStyle: { fontSize: 8 }
          }

        }}
        rootProps={{ "data-testid": "2" }}
      /> 

     );
}
 
export default MacStateForJobUpdate;