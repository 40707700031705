import React, { useState } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from '../utill/LoadingComponent';
import date from '../utill/dateTime';
import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const Performance_Overview = ({ user, equips,equipGroups,fromDateLimit }) => {
  const [machineData, setMachineData] = useState([])
  const [view, setView] = useState([])
  const [inputData, setInputData] = useState({})
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [errors, setErrors] = useState({})
  const [querySuccess, setQuerySuccess] = useState(false);
  const [index, setIndex] = useState(0)
  const [sortColumn, setSortColumn] = useState({ path: 'Date', order: 'asc' })
  const schema = {
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
    UserId: joi.string(),
    Equips: joi.array(),
  }

  const handleSort = (sortColumn) => {
    if (machineData.length > 0) {
      const sorted = _.orderBy(
        machineData,
        [sortColumn.path],
        [sortColumn.order]
      );
      setMachineData(sorted)
      setSortColumn(sortColumn)
    }
  }


  const columns = [

    { path: 'Date', label: 'Date', content: (obj) => <label>{date.getDateFormat(obj.From)}</label> },
    { path: 'Shift', label: 'Shift' },
    { path: 'Equipment', label: 'Equipment' },
    { path: 'EquipmentGroup', label: 'EquipmentGroup' },
    { path: 'WeightedPerformance', label: 'Performance (%)' },
  ]

  const handleOnChange = (e) => {

    if (e.currentTarget.type === 'date') {
      // consol(new Date(e.currentTarget.value))
      // consol(e.currentTarget.value)
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
      setInputData(inputData);
    }
    else if (e.currentTarget.type === 'time') {
      let Time = e.currentTarget.value.split(':');
      // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
      // consol(e.currentTarget.value)
      inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
      setInputData(inputData);
    }
    else {
      inputData[e.currentTarget.name] = e.currentTarget.value
    }
    setErrors({})

  }
  const handleOnSubmit = async (inputData) => {
    setIndex(0)
    machineData.splice(0,machineData.length);
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    inputData.UserId = user._id
  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    let equipFroReport=getEquipsForReport()
    for (let i = 0; i < equipFroReport.length; i++) {
      try {
        inputData.Equips = [equipFroReport[i]]
        let result = await axios.post(api.api + 'GetMachinePerformance', inputData)
        if (result.data.length > 0) {
          let idx = i + 1;
          result.data.forEach((one)=>{
            machineData.push(one)
          })
          setMachineData(machineData)
          setIndex(idx)
        }
      }
      catch (err) {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        setQuerySuccess(false)
      }
    }

    setQuerySuccess(false)
  }
  const handleDownload = (e, dateTime) => {
    generateExcelFile(e, dateTime);
  }

  const setSelection=(key,option,equips)=>{
    if(key=='EquipmentGroup'){
        SetEquips(equips?equips:[]);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if(key=='Equipment'){
       
        setSelectedEquipOption(option)
    }
}

const getEquipsForReport=()=>{
    let equipArr=[]
    equips.forEach((one)=>{
        selectedEquipOption.forEach((option)=>{
            if(one._id==option.value){
                equipArr.push(one);
            }
        })
    })
    return equipArr;
}

const SelectedDates =(dates)=>{
  setInputData(dates)
}

  return (
    <React.Fragment>
      {querySuccess ? <Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`}/> : ''}
      <div className='text-center' >
        <p >Machine Performance Overview</p>
      </div>
      <ToastContainer/>
      <div className="row">
     { equipGroups && equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}
      <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}

          </div>
          </div> */}
          {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Periodicity</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewBy'>
              <option>--select  report--</option>
              <option>Detailed</option>
              <option>Overview</option>
            </select>
            {errors.ViewBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewBy}</p>}
         
          </div>
        </div> */}
          {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}

          </div>
          </div> */}
          {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='Till' />
          </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Periodicity</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewBy'>
              <option>--select periodicity--</option>
              <option>Hourly</option>
              <option>Daily</option>
              <option>Shift</option>
            </select>
            {errors.ViewBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewBy}</p>}
         
          </div>
        </div> */}

        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>
        {machineData.length > 0 ? <div className="col-sm-2">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('Machine_Performance_Overview', inputData.DateTime)}>Download Excel</button>
        </div> : ''}



      </div>
      <div>
        {machineData.length > 0 ? <Table columns={columns} id='Machine_Performance_Overview' onSort={handleSort} sortColumn={sortColumn} data={machineData} /> : ''}
      </div>
    </React.Fragment>


  );
}

export default Performance_Overview;