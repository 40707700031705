import React, { useState ,useEffect} from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import auth from '../httpServices/authService';
import api from '../config'
import swal from 'sweetalert';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from '../utill/LoadingComponent';
import date from '../utill/dateTime';
import {generateExcelFile,generateExcelFileFull} from '../utill/download';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}



const Machine_OEE = ({user,equips,equipGroups,fromDateLimit}) => {
  const [value, setValue] = React.useState(0);

    const [OEEData, setOEEData] = useState([])
    const [JobWeightedAverageData, setJobWeightedAverageData] = useState([])
    const [ShiftWeightedAverageData, setShiftWeightedAverageData] = useState([])

    const [view, setView] = useState([])
    const [inputData, setInputData] = useState({})
    const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [equip, SetEquips] = useState([])
    const [errors, setErrors] = useState({})
    const [querySuccess, setQuerySuccess] = useState(false);
    const [index, setIndex] = useState(0);
    const [shiftSetting, setShiftSettings] = useState([])
    const [sortColumn, setSortColumn] = useState({path:'From',order:'asc'})

    const schema = {
      From: joi.date().required().label('From Datetime'),
      Till: joi.date().required().label('Till Datetime'),
      Equips: joi.array(),
      UserId: joi.string(),
     
    }
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
// console.log(errors,"errors")
    const columns=[
      { path: 'Date', label: 'Date',content:(obj)=><label>{date.getDateFormat(obj.ShiftOf)}</label> },
      { path: 'Shift', label: 'Shift' },
      { path: 'Equipment', label: 'Equipment' },
      // { path: 'PlannedOperatingTime', label: 'Planned Operating Time (Mins)' },
      // { path: 'ActualOperatingTime', label: 'Actual Operating Time (Mins)' },
      // { path: 'IdealProduction', label: 'Ideal Production (units)' },
      // { path: 'ActualProduction', label: 'Actual Production (units)' },  
      // { path: 'AcceptableProduction', label: 'Acceptable Production' },
      { path: 'Availability', label: 'Availability (%)',content:(obj)=><label>{(obj.Availability === 'NaN'  ||obj.Availability === '0.0'||obj.Availability === 0 ?'-':obj.Availability)}</label>  },
          { path: 'Performance', label: 'Performance (%)',content:(obj)=><label>{(obj.Performance === 'NaN' ||obj.Performance === '0.0' ||obj.Performance === 0 ?'-':obj.Performance)}</label>  },
          { path: 'QualityFactor', label: 'Quality Factor (%)' ,content:(obj)=><label>{(obj.QualityFactor === 'NaN' || obj.QualityFactor === '0.0'|| obj.QualityFactor === 0 ?'-':obj.QualityFactor)}</label> },
          { path: 'OEE', label: 'OEE' ,content:(obj)=><label>{(obj.OEE === 'NaN' || obj.OEE === '0.0' || obj.OEE === 0 ?'-':obj.OEE)}</label>  },
      // { path: 'TEE', label: 'TEE' },
        
       
        
         
        
      ]
      const columns2=[
        
        { path: 'From', label: 'Date',content:(obj)=><label>{date.getDateFormat(obj.From)}</label> },
        // { path: 'Till', label: 'Till',content:(obj)=><label>{date.getDateFormat(obj.Till)}</label> },

        // { path: 'Date', label: 'Date',content:(obj)=><label>{date.getDateFormat(obj.ShiftOf)}</label> },
        { path: 'Shift', label: 'Shift' },
        { path: 'Equipment', label: 'Equipment' },
        { path: 'ComponentId', label: 'Job (Component / Operation)',content:(obj)=><label>{obj.ComponentId +' / ' + obj.Operation}</label> },
        { path: 'JobDuration', label: 'Job Duration (min)' },

        
          // { path: 'PlannedOperatingTime', label: 'Planned Operating Time (Mins)' },
          // { path: 'ActualOperatingTime', label: 'Actual Operating Time (Mins)' },
          // { path: 'IdealProduction', label: 'Ideal Production (units)' },
          // { path: 'ActualProduction', label: 'Actual Production (units)' },
          
          // { path: 'AcceptableProduction', label: 'Acceptable Production' },
          { path: 'Availability', label: 'Availability (%)',content:(obj)=><label>{(obj.Availability === 'NaN'  ||obj.Availability === '0.0'||obj.Availability === 0 ?'-':obj.Availability)}</label>  },
          { path: 'Performance', label: 'Performance (%)',content:(obj)=><label>{(obj.Performance === 'NaN' ||obj.Performance === '0.0' ||obj.Performance === 0 ?'-':obj.Performance)}</label>  },
          { path: 'QualityFactor', label: 'Quality Factor (%)' ,content:(obj)=><label>{(obj.QualityFactor === 'NaN' || obj.QualityFactor === '0.0'|| obj.QualityFactor === 0 ?'-':obj.QualityFactor)}</label> },
          { path: 'OEE', label: 'OEE' ,content:(obj)=><label>{(obj.OEE === 'NaN' || obj.OEE === '0.0' || obj.OEE === 0 ?'-':obj.OEE)}</label>  },
          // { path: 'TEE', label: 'TEE' },
          
         
          
           
          
        ]
      const columns1=[
        { path: 'Date', label: 'Date',content:(obj)=><label>{date.getDateFormat(obj.ShiftOf)}</label> },

        { path: 'Shift', label: 'Shift' },
        { path: 'Equipment', label: 'Equipment' },
          // { path: 'PlannedOperatingTime', label: 'Planned Operating Time (Mins)' },
          // { path: 'ActualOperatingTime', label: 'Actual Operating Time (Mins)' },
          // { path: 'IdealProduction', label: 'Ideal Production (units)' },
          // { path: 'ActualProduction', label: 'Actual Production (units)' },
          
          // { path: 'AcceptableProduction', label: 'Acceptable Production' },
          { path: 'Availability', label: 'Availability (%)',content:(obj)=><label>{(obj.Availability === 'NaN'  ||obj.Availability === '0.0'||obj.Availability === 0 ?'-':obj.Availability)}</label>  },
          { path: 'Performance', label: 'Performance (%)',content:(obj)=><label>{(obj.Performance === 'NaN' ||obj.Performance === '0.0' ||obj.Performance === 0 ?'-':obj.Performance)}</label>  },
          { path: 'QualityFactor', label: 'Quality Factor (%)' ,content:(obj)=><label>{(obj.QualityFactor === 'NaN' || obj.QualityFactor === '0.0'|| obj.QualityFactor === 0 ?'-':obj.QualityFactor)}</label> },
          { path: 'OEE', label: 'OEE' ,content:(obj)=><label>{(obj.OEE === 'NaN' || obj.OEE === '0.0' || obj.OEE === 0 ?'-':obj.OEE)}</label>  },
          // { path: 'TEE', label: 'TEE' },
          
         
         
           
          
        ]
    // console.log(JobWeightedAverageData,"vfdivbifvbdfuyvb ")
      const handleOnChange = async(e) => {
        if (e.currentTarget.type === 'date') {
          inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
          let res= await axios.put(api.api+'GetShiftSettingForDateShift',{DateTime:inputData.DateTime})
          setShiftSettings(res.data)
        }
        else {
          inputData[e.currentTarget.name] = e.currentTarget.value;
        }
        setInputData(inputData);
        setErrors({})
      }
      const handleOnSubmit = async (inputData) => {
        setJobWeightedAverageData([])
        setOEEData([]);
         setShiftWeightedAverageData([]);
        // console.log(inputData,"Data send to the backend");
        // consol(inputData)
        setIndex(0);
        OEEData.splice(0,OEEData.length)
        const errors = validation.validate(inputData, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return 
        setQuerySuccess(true)
       inputData.UserId=user._id
       if(new Date(inputData.From) <= new Date(inputData.Till)){
      }else{
         setQuerySuccess(false);
        return  toast.success('From date should be Less Than Till date', {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#babbbb', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      }
       let equipForReort=getEquipsForReport()
       for(let i=0;i<equipForReort.length;i++){
        inputData.Equips=[equipForReort[i]]
        try{
          let result=await axios.post(api.api + 'GetEquipmentOEE',inputData )
          let idx=i+1
          // let data=[...OEEData,...result.data]
          if (result.data.length > 0) {
            // console.log(result.data, "vfjvbfvbick");
            result.data.forEach((one) => {
              one.machineShiftOEEDetailed && one.machineShiftOEEDetailed.forEach((item) => {
                    setOEEData(prevData => [...prevData, item]);
                });
                setJobWeightedAverageData(prevData => [...prevData, one.MachineOverallJobOEE]);
                setShiftWeightedAverageData(prevData => [...prevData, one.machineOverallShiftOEE
                ]);
            });
            setIndex(idx);
        }
        }
        catch(err){
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
            // consol(err)
            setQuerySuccess(false)
        }
       
       }
       setQuerySuccess(false)
      
      }

      const handleDownload=(e,date)=>{
        generateExcelFile(e,new Date(date));
      }

      const setSelection=(key,option,equips)=>{
        if(key=='EquipmentGroup'){
            SetEquips(equips?equips:[]);
            setSelectedEquipGroupOption(option)
            setSelectedEquipOption([])
        }
        if(key=='Equipment'){
           
            setSelectedEquipOption(option)
        }
    }

 const getEquipsForReport=()=>{
        let equipArr=[]
        equips.forEach((one)=>{
            selectedEquipOption.forEach((option)=>{
                if(one._id==option.value){
                    equipArr.push(one);
                }
            })
        })
        return equipArr;
    }

    const handleSort = (sortColumn) => {
      if (OEEData.length > 0) {
        const sorted = _.orderBy(
          OEEData,
          [sortColumn.path],
          [sortColumn.order]
        );
        setOEEData(sorted)
        setSortColumn(sortColumn)
      }
    }
    const handleSort1 = (sortColumn) => {
      if (JobWeightedAverageData.length > 0) {
        const sorted = _.orderBy(
          JobWeightedAverageData,
          [sortColumn.path],
          [sortColumn.order]
        );
        setJobWeightedAverageData(sorted);
        setSortColumn(sortColumn)
      }
    }
    const handleSort2 = (sortColumn) => {
      if (ShiftWeightedAverageData.length > 0) {
        const sorted = _.orderBy(
          ShiftWeightedAverageData,
          [sortColumn.path],
          [sortColumn.order]
        );
        setShiftWeightedAverageData(sorted)

        setSortColumn(sortColumn)
      }
    }
    const SelectedDates =(dates)=>{
      setInputData(dates)
    }
     
    return (
      <React.Fragment>
      {querySuccess?<Loading labelShow={true} label={`${index}/${getEquipsForReport().length}`}/>:''}
      <div className=" text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
        Machine OEE
        </h4>
        </div>
     

      <ToastContainer/>

            <div className="row">
           {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}
            <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
            {errors['Equips'] && <p className='text-danger ' style={{ fontSize: 11 }}>{errors['Equips']}</p>}
            <div className="col-sm-1">
               <button className='btn btn-primary btn-sm mt-4' onClick={()=>{handleOnSubmit(inputData)}}>Apply</button>
              </div>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", margin: "auto"}}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  // variant="scrollable"
                  centered={true}

                  scrollButtons="auto" 
                >
                  <Tab label="Machine Shift OEE Detailed" {...a11yProps(0)} />
                  <Tab label="Machine Overall Job OEE" {...a11yProps(1)} />
                  <Tab label="Machine Overall Shift Job OEE" {...a11yProps(2)} />

                  {/* <Tab label="Machine Performance" {...a11yProps(2)} />
                  <Tab label="Machine Efficiency" {...a11yProps(3)} />
                  <Tab label="Daily OEE / LOss Hrs" {...a11yProps(4)} />
                  <Tab label="mnth D/t by reasons " {...a11yProps(5)} />
                  <Tab label="mnth Rejection by reasons " {...a11yProps(6)} /> */}

                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
              <div>
              {OEEData.length>0?<div className="col-sm-2">
               <button className='btn btn-primary btn-sm mt-4' onClick={()=>handleDownload('MachineShiftJobOEEDetailed',inputData.DateTime)}>Download Excel</button>
              </div>:''}
           {OEEData.length>0? <Table id='MachineShiftJobOEEDetailed'  onSort={handleSort} columns={columns2} sortColumn={sortColumn} data={OEEData}/>:<div style={{ width: '100%', height: '600px' }}  className='animated-gradient d-flex align-items-center justify-content-center'><span className='text-center bolder'>No Data ...</span></div>}
           
            </div>
          
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
             

            <div>
              {JobWeightedAverageData.length>0?<div className="col-sm-2">
               <button className='btn btn-primary btn-sm mt-4' onClick={()=>handleDownload('MachineOverallJobOEE',inputData.DateTime)}>Download Excel</button>
              </div>:''}
           {JobWeightedAverageData.length>0? <Table id='MachineOverallJobOEE'  onSort={handleSort1} columns={columns1} sortColumn={sortColumn} data={JobWeightedAverageData}/>:<div style={{ width: '100%', height: '600px' }}  className='animated-gradient d-flex align-items-center justify-content-center'><span className='text-center bolder'>No Data ...</span></div>}
            </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
              <div>
                {/* {console.log(ShiftWeightedAverageData,"crashing tab")} */}
              {ShiftWeightedAverageData?.length>0?<div className="col-sm-2">
               <button className='btn btn-primary btn-sm mt-4' onClick={()=>handleDownload('MachineOverallShiftJobOEE',inputData.DateTime)}>Download Excel</button>
              </div>:''}
           {ShiftWeightedAverageData?.length>0? <Table id='MachineOverallShiftJobOEE'  onSort={handleSort2} columns={columns} sortColumn={sortColumn} data={ShiftWeightedAverageData}/>:<div style={{ width: '100%', height: '600px' }}  className='animated-gradient d-flex align-items-center justify-content-center'><span className='text-center bolder'>No Data ...</span></div>}
            </div>
              </CustomTabPanel>
              </Box>
            {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>From</label>
                 <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From'/>
                 {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
                </div>
              </div>
            <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>Till</label>
                  <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                  {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
                </div>
              </div> */}
              {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>View By</label>
                  <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewBy'>
                  <option>--select view--</option>
                      <option>Day</option>
                      <option>Shift</option>
                  </select>
                  {errors.ViewBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewBy}</p>}
       
                </div>
              </div> */}
              {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>Shift</label>
                  <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
                  <option>--select shift--</option>
                  {shiftSetting.map(one=>{
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
                  </select>
                  {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
       
                </div>
              </div> */}
             
             

            
             
              
            </div>
           
            </React.Fragment>

       
      );
}
 
export default Machine_OEE;