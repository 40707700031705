import date from 'joi/lib/types/date';
import React, { Component } from 'react';
import dateTime from './dateTime';

const TableForDowntimeAnalysis = ({data,id,periodicity,showRunTime,showCost,Checkbox}) => {
    const getTotalDowntime=(arr)=>{
       let total=0;
       arr.forEach(one=>{
        total+=one.Duration;
       })
       return (total/60).toFixed(1)
    }
    const getTotalCost=(arr)=>{
        let total=0;
        arr.forEach(one=>{
         total+=one.Cost;
        })
        return (total).toFixed(1)
     }
    const getTotalRunTime=(arr,obj)=>{
        let downtime=parseFloat(getTotalDowntime(arr));
        let TotalRunTime=parseInt(obj.PlannedProdTime)-downtime;
        return (TotalRunTime/60).toFixed(0);
    }

    const getTableHeaderForDateTime=()=>{
        if(periodicity=='Shift'||periodicity=='Weekly'||periodicity=='Monthly'){
            return <><th >From</th><th >Till</th>{ Checkbox ? <th >Equipment</th>:''}</>
        }
        if(periodicity=='Daily'){
            return <><th >Date</th> 
            { Checkbox ? <th >Equipment</th>:''}</>
        }
    }

    const getTableDateTimeForDateTime=(one)=>{
        if(periodicity=='Shift'||periodicity=='Weekly'||periodicity=='Monthly'){
            return <><td >{dateTime.getDateFormat(new Date(one.DateTime))}</td><td >{dateTime.getDateFormat(new Date(one.DateTime1))}</td>{ Checkbox ? <th >{one.Equipment}</th>:''}</>
        }
        if(periodicity=='Daily'){
            return <><td >{dateTime.getDateFormat(one.DateTime)}</td>
            { Checkbox ? <th >{one.Equipment}</th>:''}</>
        }
    }
    return ( 
        <div style={{width:'100%',overflowX:'scroll'}}>
        <table id={id} className="table table-sm table-hover small mt-5 table-striped ">
        <thead className='small text-center border'>
            <tr className='table-primary ' style={{position:'sticky',top:0}}>
               { id=='MachineDowntimeAnalysisTrend'? <>{getTableHeaderForDateTime()}
                <th>{periodicity}</th></>:<>
                <th >Equipment</th>
                <th>EquipmentGroup</th>
                </>}
                {data.length > 0 ? data[0].Downtime.map(one => {
                    return <th  className='rotate' style={{verticalAlign:'middle',height:one.Reason?one.Reason.length*7:0}} ><div  className='mt-1'><span>{one.Reason}</span></div></th>
                }) : ''}
                {showCost?<th>Total Cost in (₹)</th>:<th>Total Downtime</th>}
               {showRunTime? <th>Total Runtime</th>:<></>}
            </tr>
        </thead>
        <tbody className='text-center'>
        {
            data.map((one)=>{
               return  <tr className='text-center'>
               { id=='MachineDowntimeAnalysisTrend'? <>{getTableDateTimeForDateTime(one)}
                <td>{one.Shift}</td></>:<>
                <td >{one.Equipment}</td>
                <td>{one.EquipmentGroup}</td>
                </>}
                {showCost ?one.Downtime.map(one1=>{
                  return   <td>{one1.Reason?(one1.Cost) == 0?'0':(one1.Cost)?.toFixed(1):'0'}</td>
                    
                }):one.Downtime.map(one1=>{
                  return   <td>{one1.Reason?(one1.Duration) == 0?'0':(one1.Duration/60).toFixed(1):'0'}</td>
                    
                })}
                {showCost?<td>{getTotalCost(one.Downtime)}</td>:<td>{getTotalDowntime(one.Downtime)}</td>}
                {showRunTime?<td>{one.TotalRunTime?(one.TotalRunTime/60).toFixed(1):'0'}</td>:<></>}
            </tr>
            })
        }
           
           
        </tbody>

    </table></div>
     );
}
 
export default TableForDowntimeAnalysis;