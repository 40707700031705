import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";
import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import pako from "pako"
import EquipmentSelection from "../Common/equipmentSelection";
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from "../Common/DateSelection";

const Refrence = [
  { label: "Equipment", id: "1" },
  { label: "Component", id: "2" },
  { label: "Operator", id: "3" },
  { label: "Tool", id: "4" },
  { label: "Operation", id: "5" },
  { label: "Time", id: "6" },
  { label: "DowntimeReason", id: "7" },
  { label: "EquipmentGroup", id: "8" },
  { label: "ReasonGroup", id: "9" },


];

function DowntimeDetailedAnalysis({equipGroups,reasons,fromDateLimit,storeDataInLocalStorage}) {
const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);
  const [periodOption, setperiodOption] = useState([
    { label: "Shift", id: "1" },
    { label: "Daily", id: "2" },
    { label: "Weekly", id: "3" },
    { label: "Monthly", id: "4" },
    { label: "Cummulative", id: "5" },
  ]);

  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  const [componentdata, setcomponentdata] = useState([]);
  const [downtimereasongroup, setdowntimereasongroup] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [selecetddowntimegroup, setselecetddowntimegroup] = useState([]);

  
  const [operatordata, setoperatordata] = useState([]);
  const [tooldata, settooldata] = useState([]);
  const [operationdata, setoperationdata] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [data, settabledata] = useState([]);
  const [Equipmentgroup, setEquipmentgroup] = useState([]);
  const [selectedequipgroups, setselectedequipgroups] = useState([]);

  
  
  const [Distributionvalues, SetDistributionvalues] = useState([]);
  const [piedata, setpiedata] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    From: "",
    Till: "",
  });
  const [Compareref, setCompareref] = useState([]);

  const [pieperiodicity, setpieperiodicity] = useState("");
  const [pieDateTime, setpieDateTime] = useState([]);
  const [newdata, setnewdata] = useState("");
  const [head, sethead] = useState("");
  const [root, setroot] = useState([]);
  const [pieheader, setpieheader] = useState([]);

  const [historicalData, setHistoricalData] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");
  const [previousInputData, setPreviousInputData] = useState([]);

  const [tableVisible, setTableVisible] = useState(false);
  const [tableVisible1, setTableVisible1] = useState(false);
  const [tableVisible2, setTableVisible2] = useState(false);
  const [tableVisible3, setTableVisible3] = useState(false);
  const [selectedItemLengths, setSelectedItemLengths] = useState({});
  const [color, setcolor] = useState();
  const [TillDateLimit, setTillDateLimit] = useState(null);

  

  const handleToggle = () => {
    setTableVisible((prevVisible) => !prevVisible);
  };
  const handleToggle1 = () => {
    setTableVisible1((prevVisible) => !prevVisible);
  };
  const handleToggle2 = () => {
    setTableVisible2((prevVisible) => !prevVisible);
  };
  const handleToggle3 = () => {
    setTableVisible3((prevVisible) => !prevVisible);
  };
  const [columns, setcolumn] = useState([]);

  const [sortColumn, setSortColumn] = useState({ path: "Date", order: "asc" });
  const [paretodata, setparetodata] = useState([]);
  const [columns1, setcolumns1] = useState([]);
  const [columns2, setcolumns2] = useState([]);

  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])

  const [indexofpie, setindexofpie] = useState(null);
  const DowntimeReasonGroup ={
    heading: "ReasonGroup",
    label: "tablecolumns",
    initialItems: downtimereasongroup,
    selectedItemIds: selectedItemIds,
    name: "ReasonGroup",
    enableLocalStorage: false,
    multiSelect: true,
    searchbar: true,
    selectAll:true,
  }


 const downtime ={
    heading: "DowntimeReason",
    label: "tablecolumns",
    initialItems: dynamicData,
    selectedItemIds: selectedItemIds,
    name: "DowntimeReason",
    enableLocalStorage: false,
    multiSelect: true,
    searchbar: true,
    selectAll:true,
  }

//////////// function to store data in local storage ///////////////



  // const storeDataInLocalStorage = (apiName) => {

  //   const StoredData = localStorage.getItem(apiName);

  //   if (StoredData) {
  //     // Data exists in local storage, use it
  //     const currentdata = JSON.parse(StoredData);
  //     return   currentdata;
     
  //   } else {
  //     // Data doesn't exist in local storage, make API call to fetch it
  //     axios.get(api.api + apiName).then((result) => {
  //       if (result.data) {
  //         // Save equipGroups data in local storage
  //         localStorage.setItem(apiName, JSON.stringify(result.data)) 
  //         // Update state
  //     return   result.data 
  //       } 
  //     });
  //   }
  // }


  const DropDownData = [
    // {
    //   heading: "EquipmentGroup",
    //   label: "tablecolumns",
    //   initialItems: Equipmentgroup,
    //   selectedItemIds: selectedItemIds,
    //   name: "EquipmentGroup",
    //   enableLocalStorage: false,
    //   multiSelect: true,
    //   searchbar: true,
    // selectAll:true,
    // },
    // {
    //   heading: "Equipment",
    //   label: "tablecolumns",
    //   initialItems: equpdata,
    //   selectedItemIds: selectedItemIds,
    //   name: "Equipment",
    //   enableLocalStorage: false,
    //   multiSelect: true,
    //   searchbar: true,
    // selectAll:true,
    // },
    {
      heading: "Operation",
      label: "tablecolumns",
      initialItems: operationdata,
      selectedItemIds: selectedItemIds,
      name: "Operation",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    {
      heading: "Component",
      label: "tablecolumns",
      initialItems: componentdata,
      selectedItemIds: selectedItemIds,
      name: "Component",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    {
      heading: "Operator",
      label: "tablecolumns",
      initialItems: operatordata,
      selectedItemIds: selectedItemIds,
      name: "Operator",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    {
      heading: "Shift",
      label: "tablecolumns",
      initialItems: shiftOption,
      selectedItemIds: selectedItemIds,
      name: "Shift",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    // {
    //   heading: "DowntimeReason",
    //   label: "tablecolumns",
    //   initialItems: dynamicData,
    //   selectedItemIds: selectedItemIds,
    //   name: "DowntimeReason",
    //   enableLocalStorage: false,
    //   multiSelect: true,
    //   searchbar: true,
    // selectAll:true,
    // },
    {
      heading: "Tool",
      label: "tablecolumns",
      initialItems: tooldata,
      selectedItemIds: selectedItemIds,
      name: "Tool",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
      selectAll:true,
    },
    {
      heading: "Periodicity",
      label: "tablecolumns",
      initialItems: periodOption,
      selectedItemIds: selectedItemIds,
      name: "Periodicity",
      enableLocalStorage: false,
      multiSelect: false,
      searchbar: false,
      selectAll:false,

    },
    {
      heading: "Refrence",
      label: "tablecolumns",
      initialItems: Refrence,
      selectedItemIds: selectedItemIds,
      name: "Refrence",
      enableLocalStorage: false,
      multiSelect: false,
      searchbar: false,
      selectAll:false,

    },
  ];








  const setSelection = (key, option, equips) => {
    if (key == 'EquipmentGroup') {
        SetEquips(equips ? equips : []);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if (key == 'Equipment') {

        setSelectedEquipOption(option)
    }
}






  const column = [
    { path: "RefrenceTag", label: `${selectedOption.Refrence}` },
    { path: "Duration", label: "DownTime Duration in(min)" },
    { path: "DurationInDays", label: "DownTime Duration" },
  ];
  const column1 = [
    { path: "RefrenceTag", label: `${inputData.Refrence}` },
    { path: "Duration", label: "DownTime Duration in(min)" },
    { path: "DurationInDays", label: "DownTime Duration" },
  ];
  const column2 = [
    { path: "Periodicity", label: 'Period' },
    { path: "RefrenceTag", label: `${inputData.Refrence}` },
    { path: "Duration", label: "DownTime Duration in(min)" },
    { path: "DurationInDays", label: "DownTime Duration" },
  ];
  const schema = {
    Equipment: joi.array().required(),
    Component: joi.array().required(),
    Operator: joi.array().required(),
    Shift: joi.array().required(),
    Tool: joi.array().required(),
    Periodicity: joi.array().required(),
    DowntimeReason: joi.array().required(),
    Operation: joi.array().required(),
    Refrence: joi.array().required(),
    ReasonGroup:joi.array().required(),
  };
  const chartContainerRef = useRef(null);


useEffect(() => {
 let filterdata = selecetddowntimegroup.map(item=> reasons.filter(reason=>reason.ReasonGroup === item))
  filterdata = _.flattenDeep(filterdata)
  if(filterdata?.length > 0) {
    const downtimelist = filterdata
    .map((reason, index) => ({
      id: `${index}`,
      label: reason.Reason,
    }))
    .slice()
    .sort((a, b) => a.label.localeCompare(b.label));


    setDynamicData(downtimelist);
  }else{
      setDynamicData([])
     }

// const currentdate =  new Date().toJSON().slice(0, 10);
// const currentDate = new Date();
// const oneYearAgoDate = new Date(currentDate);
// oneYearAgoDate.setFullYear(currentDate.getFullYear() - 1);

// // Format the date to "YYYY-MM-DD"
// const formattedOneYearAgo = oneYearAgoDate.toISOString().split('T')[0];
// // consol(currentdate,formattedOneYearAgo,"suvhosihvodif")
// if(companyCreationDate.split('T')[0] > formattedOneYearAgo){

// }else{
//   setMinimumDate(formattedOneYearAgo);

// }


// const threeMonthsLaterDate = new Date(currentDate);
//     threeMonthsLaterDate.setMonth(currentDate.getMonth() + 3);

//     // Format the date to "YYYY-MM-DD"
//     const formattedThreeMonthsLater = threeMonthsLaterDate.toISOString().split('T')[0];

//     setTillDateLimit(formattedThreeMonthsLater);
  
}, [selecetddowntimegroup.length])

  
  useEffect(() => {
    // Add a delegated event listener to the chart container
    // // consol(chartContainerRef, "current reference",selectedOption);
    if (chartContainerRef.current) {
      chartContainerRef.current.addEventListener("change", (event) => {
        if (event.target.tagName === "SELECT") {
          // Handle the change event for the select element in the tooltip
          const selectedValue = event.target.value;
          // // consol(selectedValue); // Do something with the selected value

          setSelectedOption({ Refrence: selectedValue, root: 2 });
          setroot({ root: 2 });
          // // consol("second runned ", { Refrence: selectedValue, root: 2 });
        }
      });
    }
 
  }, [selectedOption, historicalData,indexofpie]);

  // useEffect(() => {
  //   // consol(indexofpie,historicalData.length,"current index")
  //   if(indexofpie == 0 && historicalData?.length >= 2){
  //     const removedArray = historicalData[0];
  //     const newArray = [pieheader[0]];
  //     setindexofpie(null)
  //     setpieheader([]);
  //     setHistoricalData([]);
  //     setroot({ root: 1 });
  //     setpiedata(removedArray);
  //     const ref = newArray[0]?.Refrence;
  //     setSelectedOption({ Refrence: ref, root: 1 });
  //     setsenddata(newArray[0]?.inputData);
 
  //   }
    
  
  // }, [indexofpie])
  
  // function createCustomHTMLContent(selectedOption) {
  //   // consol("element changed")
  //   return (
  //     '<div style="padding: 5px 5px 5px 5px;width:150px;height:200px">' +
  //     '<select  className="rounded p-2 shadow" style="backgroundColor:white;border:none">' +
  //     '<option value="Select an option">Select an option</option>' +
  //     Refrence.map(
  //       (one) => '<option value="' + one.label + '">' + one.label + "</option>"
  //     ).join("") +
  //     "</select>" +
  //     "</div>"
  //   );
  // }
 
  function createCustomHTMLContentBarchart(Ref, From, Till, down) {
    return (
      '<div style="padding: 5px; width: 200px; background-color: rgba(199, 236, 199, 0.4); border-radius: 10px; overflow: hidden;">' +
      '<table className="medals_layout">' +
      "<tr>" +
      "<td>" +
      "Ref" +
      "</td>" +
      "<td><b>" +
      Ref +
      "</b></td>" +
      "</tr>" +
      "<tr>" +
      "<td>" +
      "Down Time" +
      "</td>" +
      "<td><b>" +
      down +
      "(min)" +
      "</b></td>" +
      "</tr>" +
      "<td>" +
      "From" +
      "</td>" +
      "<td><b>" +
      From +
      "</b></td>" +
      "</tr>" +
      "<tr>" +
      "<td>" +
      "Till" +
      "</td>" +
      "<td><b>" +
      Till +
      "</b></td>" +
      "</tr>" +
      "</table>" +
      "</div>"
    );
  }

  
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      setQuerySuccess(true);

      try {
        // const downtimedata = await axios.get(api.api + "downtimereason");
        // const componentdata = await axios.get(api.api1 + "component");
        // const operatordata = await axios.get(api.api1 + "operator");
        // const tooldata = await axios.get(api.api + "tool");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

 let componentdata = await storeDataInLocalStorage('component')

 let operatordata = await storeDataInLocalStorage('operator')

 let tooldata = await storeDataInLocalStorage('tool')

 let equipmentdata = await storeDataInLocalStorage('equipment')

        // let equipmentdata = await axios.get(api.api1 + "equipment");
        // const Equipgrouplist =  equipGroups.map((reason, index) => ({
        //   id: `${index}`,
        //   label: reason.EquipmentGroup,
        // }))
        // .slice()
        // .sort((a, b) => a.label.localeCompare(b.label));
        const downtimelist = downtimedata
          .map((reason, index) => ({
            id: `${index}`,
            label: reason.Reason,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        const complist = componentdata
          .map((componentList, index) => ({
            id: `${index}`,
            label: componentList.ComponentId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        // Iterate through the data and extract operation IDs
        const operationIds = [];
        componentdata.forEach((item) => {
          item.Operation.forEach((operation) => {
            operationIds.push(operation.OperationID);
          });
        });
        // Sort and remove duplicates from operationIds
        const uniqueSortedOperations = [...new Set(operationIds)]
          .sort((a, b) => a - b)
          .map((operation, index) => ({ id: index, label: operation }));
        const operatorlist = operatordata
          .map((operator, index) => ({
            id: `${index}`,
            label: operator.Name,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        const toollist = tooldata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.ToolId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        
          // let uydgschbsch
          // if(selectedequipgroups?.length > 0) {
          //   uydgschbsch  =   selectedequipgroups.map(item =>{
          //  return equipmentdata.filter(one=>one.EquipmentGroup === item) 

          // })}
 
          // const flatArray = _.flatten(uydgschbsch);
        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        
          setequpdata(equiplist);
     
         
          const DowntimeColors =downtimedata.map((index)=>([index.Reason,index.Color]));

          const uniqueReasonGroups = {};

// Filter the array to remove duplicates based on ReasonGroup
const uniqueData = downtimedata.filter((obj) => {
  const reasonGroup = obj.ReasonGroup;

  if (!uniqueReasonGroups[reasonGroup]) {
    // If this ReasonGroup is not in the uniqueReasonGroups object, mark it as seen and return true to keep it in the result
    uniqueReasonGroups[reasonGroup] = true;
    return true;
  }

  // If the ReasonGroup is already in the uniqueReasonGroups object, return false to filter it out
  return false;
});

          const DowntimeReasonGroup = uniqueData
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.ReasonGroup,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
          setcolor(DowntimeColors)
        setoperationdata(uniqueSortedOperations);
        settooldata(toollist);
        setoperatordata(operatorlist.sort((a, b) => a[1] - b[1]));
       
        setcomponentdata(complist);
        setdowntimereasongroup(DowntimeReasonGroup)
        // // consol("fetching data",uniqueData,downtimelist);

      } catch (error) {
        // console.error("Error fetching data:", error);
      }
      setQuerySuccess(false);

    };

    fetchData();
  }, [equipGroups,selectedequipgroups]); // Runs once when the component mounts

  const handleSelectedItemsChange = (newSelectedItems, name,totallength) => {
    setSelectedItemIds(newSelectedItems);
if(name==='ReasonGroup'){
  setselecetddowntimegroup(newSelectedItems)

}
    const validHeadings = [
      "Equipment",
      "Component",
      "Operation",
      "Operator",
      "Shift",
      "Tool",
      "Periodicity",
      "DowntimeReason",
      "Refrence",
      "EquipmentGroup",
      "ReasonGroup"
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    if(name == 'DowntimeReason'){
      inputData[name] = newSelectedItems
    }else{
      inputData[name] = newSelectedItems.length == totallength?["all"]:newSelectedItems
    }
    if(name == 'EquipmentGroup'){
      setselectedequipgroups(newSelectedItems)
    }


    // Now you have access to both newSelectedItems and heading
  //  // consol(newSelectedItems.length == totallength?"All":"Noob","totallength")
    // // consol("Heading:", name, "inputData :", inputData);
    setsenddata(inputData);
    setCompareref(inputData.Refrence);
  };




// ///////// handel pareto //////////
const organiseParetoData = (clone,filter,periodicity) => {
  let data = [...clone];
  let result = [];
  let testtable =[]
  if(filter == 'DownTimeReason'){
    data.forEach(item => {
      const existingItem = result.find(obj => obj.DowntimeReason === item.DowntimeReason);
      if (existingItem) {
        existingItem.Duration += item.Duration;
      } else {
        result.push({ ...item });
      }
    }); 
    let culumntabledata =[...result]
  
    culumntabledata.forEach((one) => {
      
      let Duration = Math.ceil(one.Duration/60);
      if (Duration === 0) {
        return; // Skip this iteration if down is 0
      }
      
      let RefrenceTag = one.DowntimeReason;
    let DurationInMins = Math.ceil(one.Duration/60);
    const days = Math.floor(DurationInMins / (24 * 60));
    const hours = Math.floor((DurationInMins % (24 * 60)) / 60);
    const minutes = DurationInMins % 60;
    testtable.push({
      
      Duration: Duration,
      RefrenceTag: RefrenceTag,
      DurationInDays: `${
        DurationInMins >= 1440 ? days + " " + "Days" : ""
      }  ${DurationInMins >= 60 ? hours + " " + "hours" : ""}  ${
        minutes + " " + "min"
      } `,
    });
  
  })
    
  }else if(filter == 'ReasonGroup'){
    data.forEach(item => {
      const existingItem = result.find(obj => obj.ReasonGroup === item.ReasonGroup);
      if (existingItem) {
        existingItem.Duration += item.Duration;
      } else {
        result.push({ ...item });
      }
    }); 
    let culumntabledata =[...result]
  
    culumntabledata.forEach((one) => {
      
      let Duration = Math.ceil(one.Duration/60);
      if (Duration === 0) {
        return; // Skip this iteration if down is 0
      }
      
      let RefrenceTag = one.ReasonGroup;
    let DurationInMins = Math.ceil(one.Duration/60);
    const days = Math.floor(DurationInMins / (24 * 60));
    const hours = Math.floor((DurationInMins % (24 * 60)) / 60);
    const minutes = DurationInMins % 60;
    testtable.push({
      
      Duration: Duration,
      RefrenceTag: RefrenceTag,
      DurationInDays: `${
        DurationInMins >= 1440 ? days + " " + "Days" : ""
      }  ${DurationInMins >= 60 ? hours + " " + "hours" : ""}  ${
        minutes + " " + "min"
      } `,
    });
  
  })
  }else{
    data.forEach(item => {
      const existingItem = result.find(obj => obj.RefrenceTag === item.RefrenceTag);
      if (existingItem) {
        existingItem.Duration += item.Duration;
      } else {
        result.push({ ...item });
      }
    });
    let culumntabledata =[...result]
  
  culumntabledata.forEach((one) => {
    let Duration = Math.ceil(one.Duration/60);
    if (Duration === 0) {
      return; // Skip this iteration if down is 0
    }
    let RefrenceTag = one.RefrenceTag;
    if(inputData.Refrence == 'time'){
      RefrenceTag = 'Total DownTime'
    }
  let DurationInMins = Math.ceil(one.Duration/60);
  const days = Math.floor(DurationInMins / (24 * 60));
  const hours = Math.floor((DurationInMins % (24 * 60)) / 60);
  const minutes = DurationInMins % 60;
  testtable.push({
    Duration: Duration,
    RefrenceTag: RefrenceTag,
    DurationInDays: `${
      DurationInMins >= 1440 ? days + " " + "Days" : ""
    }  ${DurationInMins >= 60 ? hours + " " + "hours" : ""}  ${
      minutes + " " + "min"
    } `,
  });

})
  }
if(filter !== 'yes'){
   let groupbarchart =[];
   let Periodicity;
  data.forEach((one) => {

     if(senddata.Periodicity[0] === 'Shift'){
                  Periodicity = formatInputDateRange1(one.Periodicity);
        
              
                }else{
                   Periodicity = formatInputDateRange(one.Periodicity);
               
                }
    if (inputData.Periodicity == "Cummulative") {
      Periodicity = "Cummulative";
    }
    let Duration = Math.ceil(one.Duration/60);
    if (Duration === 0) {
      return; // Skip this iteration if down is 0
    }
    let RefrenceTag = one.RefrenceTag ||one.DowntimeReason || one.ReasonGroup;
    if(inputData.Refrence == 'time'){
      RefrenceTag = 'Total DownTime'
    }
  let DurationInMins = Math.ceil(one.Duration/60);
  const days = Math.floor(DurationInMins / (24 * 60));
  const hours = Math.floor((DurationInMins % (24 * 60)) / 60);
  const minutes = DurationInMins % 60;
  groupbarchart.push({ Periodicity:Periodicity ||periodicity,
    Duration: Duration,
    RefrenceTag: RefrenceTag,
    DurationInDays: `${
      DurationInMins >= 1440 ? days + " " + "Days" : ""
    }  ${DurationInMins >= 60 ? hours + " " + "hours" : ""}  ${
      minutes + " " + "min"
    } `,
  });

})
// // consol(groupbarchart,"groupbarchart")
setcolumns2(groupbarchart.filter((data)=>data.Duration > 0).sort((a, b) => b.Duration - a.Duration))
}


  // // consol(result,"pareto",data)
  // result.sort((a, b) =>b.Duration - a.Duration)
  
  
  
  setcolumns1(testtable.filter((data)=>data.Duration > 0).sort((a, b) => b.Duration - a.Duration))
  result.sort((a, b) =>b.Duration - a.Duration).filter((data)=>data.Duration > 0)
  let downTimeParetoArray = [];
  let schema = ['State', 'Time( in minutes)', { role: 'style' }, 'Percentage(%)'];
  downTimeParetoArray.push(schema);
  
  const totalDuration = result.reduce((total, item) => total + Math.ceil(item.Duration/60), 0);
  let cumulativePercentage = 0;
const paretoData = result.filter((data)=>data.Duration > 0).map((item) => {
  cumulativePercentage += (Math.ceil(item.Duration/60) / totalDuration) * 100;
  return {
    Name: item.RefrenceTag || item.DowntimeReason || item.ReasonGroup,
    Duration: item.Duration,
    cumulativePercentage,
  };
});


  for (let i = 0; i < paretoData.length; i++) {
    let StateArray = [];
  
      StateArray.push(paretoData[i].Name);
      StateArray.push(parseFloat((Math.ceil(paretoData[i].Duration/60))));
      StateArray.push('opacity: 0.4; fill-color: #C5A5CF');
      StateArray.push(parseFloat((paretoData[i].cumulativePercentage).toFixed(1)));
      downTimeParetoArray.push(StateArray);
    
  }
  // }
  // // consol(downTimeParetoArray,"paretodata",paretoData);
  return downTimeParetoArray;
}


const renderParetoChart = (data) => {
  return<div className="shadow d-flex justify-content-center mx-3 my-3 " style={{backgroundColor:"white",borderRadius:"20px",width:"98%",overflow:'hidden'}}>
     <Chart
  width="100%"
  height="500px"
    chartType="ComboChart"
    loader={<div>Loading Chart</div>}
    data={data}
    options={{
      // backgroundColor: { strokeWidth: 2 },
      chartArea: {left:80,top:60,right:80,bottom:100},
      vAxes: [
        { title: 'DownTime Duration( in minutes)', minValue: 0 },
        { title: " Cumulative Percentage", minValue: 0, maxValue: 100 }
      ],
      colors: ['#C5A5CF','#FA8072'],
      hAxis: { title: `${Compareref[0]}`, slantedTextAngle: 90, slantedText: true, textStyle: { fontName: 'Roboto', fontSize: 12 } },
      seriesType: 'bars',
      series: { 1: { type: "line", targetAxisIndex: 1 }
     },
     
    }
  }
    rootProps={{ 'data-testid': '1' }}
  
  />
  </div>
}









  const handleBarClick = (selectedItem) => {
    const bar = data[selectedItem.row + 1][0];
    const barvalue = data[selectedItem.row + 1][selectedItem.column];
    const bardata = data[selectedItem.row + 1][selectedItem.column + 2];

    // // consol(
    //   data[selectedItem.row + 1],
    //   selectedItem,
    //   "selected column",
    //   barvalue,
    //   bardata
    // );
    SetDistributionvalues(bardata);
    setSelectedCity(bardata[0]);
    setSelectedOption("");
    setpieperiodicity(bar);
    setpieheader([]);

    setpiedata([]);
    setHistoricalData([]);
  };

  useEffect(() => {
    if (selectedOption.Refrence?.length > 0) {
      // // consol(selectedOption, "runned ");
      let inputData = { ...senddata };
      let data = [...Distributionvalues];
      // // consol(previousInputData, "previous value1");

      if (previousInputData.Refrence?.length > 0) {
        // // consol(previousInputData, "previous value2");
        if (selectedOption.root == 2) {
          inputData = { ...previousInputData };
          // // consol(previousInputData, "previous value3");
          inputData.Refrence = [selectedOption.Refrence];
          inputData[head] = [newdata];
        }
      } else {
        if (selectedOption.root == 1) {
          inputData.Refrence = [selectedOption.Refrence];
        }
      }
      inputData[Compareref[0]] = [data[0]];
      inputData.Periodicity = ["Cummulative"];

      inputData.Refrence = [selectedOption.Refrence];
      inputData.From = data[1];
      inputData.Till = data[2];
      // // consol(
      //   data,
      //   newdata,
      //   root,
      //   "distribution data",
      //   inputData,
      //   Compareref[0]
      // );

      const compressedData = pako.gzip(JSON.stringify(inputData)); 

      axios.post(api.api + 'GetDownTimeAnalysis', compressedData, {
        headers: {
          'Content-Encoding': 'gzip', // Set the content encoding to gzip
          'Content-Type': 'application/json', // Set the content type to JSON
        },
      })
      // const result = await axios.post(api.api + 'GetDownTimeAnalysis', inputData)
        .then((result) => {
          const decompressedData = pako.ungzip(result.data, { to: 'string' });
  
          // Assuming the decompressed data is in JSON format, parse it
          const jsonData = JSON.parse(decompressedData);
          // consol(jsonData, "result received pie",inputData);
          if (jsonData.length > 0) {
            if (jsonData[0].RefrenceTag !== "DowntimeReason" && jsonData[0].RefrenceTag !== "ReasonGroup") {
              const header = [
                "String",
                "DownTime",
                // { type: "string", role: "tooltip", p: { html: true } },
              ];
              setpieDateTime({
                From: jsonData[0].From,
                Till: jsonData[0].Till,
              });
              // const convertedArray = [header,...jsonData.map((obj) => [obj.RefrenceTag,obj.Duration,createCustomHTMLContent("Equipment")])];
             
              const convertedArray = [header,...jsonData.map((obj) => [obj.RefrenceTag,obj.Duration])];
              // // consol(convertedArray, "pie chart data chl", jsonData);
              setpiedata(convertedArray.sort((a,b) => a[1] - b[1]));
              // if(root.root == 2){
              setHistoricalData((prevData) => [...prevData, convertedArray]);
              setpieheader((prevData) => [
                ...prevData,
                { Refrence: selectedOption.Refrence, inputData: inputData },
              ]);

              const testtable = [];
              jsonData.forEach((data) => {
                let Duration = Math.ceil(data.Duration/60);

                let RefrenceTag = data.RefrenceTag;
                let DurationInMins = Math.ceil(data.Duration/60);
                const days = Math.floor(DurationInMins / (24 * 60));
                const hours = Math.floor((DurationInMins % (24 * 60)) / 60);
                const minutes = DurationInMins % 60;
                testtable.push({
                  Duration: Duration,
                  RefrenceTag: RefrenceTag,
                  DurationInDays: `${DurationInMins >= 1440 ? days + " " + "Days" : ""}  ${DurationInMins >= 60 ? hours + " " + "hours" : ""}  ${minutes + " " + "min"} `});
              });
              // }
              // // consol(testtable, "table for test data");
              setcolumn(testtable.filter((data)=>data.Duration > 0).sort((a, b) => b.Duration - a.Duration));
            } else { 


              if(jsonData[0].RefrenceTag === "ReasonGroup"){
                setpieDateTime({
                  From: jsonData[0].From,
                  Till: jsonData[0].From,
                });
                const header = [
                  "String",
                  "DownTime",
                  // { type: "string", role: "tooltip", p: { html: true } },
                ];
                jsonData.forEach((one) => {
                  // // consol(one.Summary, "summary document");
                  // const convertedArray = [
                  //   header,
                  //   ...one.Summary.map((obj) => [
                  //     obj.DowntimeReason,
                  //     obj.Duration,
                  //     createCustomHTMLContent("Equipment"),
                  //   ]),
                  // ];
                  const convertedArray = [
                    header,
                    ...one.Summary.map((obj) => [
                      obj.DowntimeGroup,
                      obj.Duration
                      
                    ]),
                  ];
                  // // consol(convertedArray, "pie chart data", jsonData);
                  setpiedata(convertedArray.sort((a,b) => a[1] - b[1]));
                  // if(root.root == 2){
                  setHistoricalData((prevData) => [...prevData, convertedArray]);
                  setpieheader((prevData) => [
                    ...prevData,
                    { Refrence: selectedOption.Refrence, inputData: inputData },
                  ]);
                  const testtable = [];
                  one.Summary.forEach((data) => {
                    let Duration = Math.ceil(data.Duration/60);
                    let RefrenceTag = data.DowntimeGroup;
                    let DurationInMins = Math.ceil(data.Duration/60);
                    const days = Math.floor(DurationInMins / (24 * 60));
                    const hours = Math.floor((DurationInMins % (24 * 60)) / 60);
                    const minutes = DurationInMins % 60;
                    testtable.push({
                      Duration: Duration,
                      RefrenceTag: RefrenceTag,
                      DurationInDays: `${
                        DurationInMins >= 1440 ? days + " " + "Days" : ""
                      }  ${DurationInMins >= 60 ? hours + " " + "hours" : ""}  ${
                        minutes + " " + "min"
                      } `,
                    });
                  });
                  // }
                  // // consol(testtable.filter((data)=>data.Duration > 0).sort((a, b) => b.Duration - a.Duration), "table for test data");
                  setcolumn(testtable.filter((data)=>data.Duration > 0).sort((a, b) => b.Duration - a.Duration));
                  // }
                });

              }else{
              setpieDateTime({
                From: jsonData[0].From,
                Till: jsonData[0].From,
              });
              const header = [
                "String",
                "DownTime",
                // { type: "string", role: "tooltip", p: { html: true } },
              ];
              jsonData.forEach((one) => {
                // // consol(one.Summary, "summary document");
                // const convertedArray = [
                //   header,
                //   ...one.Summary.map((obj) => [
                //     obj.DowntimeReason,
                //     obj.Duration,
                //     createCustomHTMLContent("Equipment"),
                //   ]),
                // ];
                const convertedArray = [
                  header,
                  ...one.Summary.map((obj) => [
                    obj.DowntimeReason,
                    obj.Duration
                    
                  ]),
                ];
                // // consol(convertedArray, "pie chart data", jsonData);
                setpiedata(convertedArray.sort((a,b) => a[1] - b[1]));
                // if(root.root == 2){
                setHistoricalData((prevData) => [...prevData, convertedArray]);
                setpieheader((prevData) => [
                  ...prevData,
                  { Refrence: selectedOption.Refrence, inputData: inputData },
                ]);
                const testtable = [];
                one.Summary.forEach((data) => {
                  let Duration = Math.ceil(data.Duration/60);
                  let RefrenceTag = data.DowntimeReason;
                  let DurationInMins = Math.ceil(data.Duration/60);
                  const days = Math.floor(DurationInMins / (24 * 60));
                  const hours = Math.floor((DurationInMins % (24 * 60)) / 60);
                  const minutes = DurationInMins % 60;
                  testtable.push({
                    Duration: Duration,
                    RefrenceTag: RefrenceTag,
                    DurationInDays: `${
                      DurationInMins >= 1440 ? days + " " + "Days" : ""
                    }  ${DurationInMins >= 60 ? hours + " " + "hours" : ""}  ${
                      minutes + " " + "min"
                    } `,
                  });
                });
                // }
                // // consol(testtable.filter((data)=>data.Duration > 0).sort((a, b) => b.Duration - a.Duration), "table for test data");
                setcolumn(testtable.filter((data)=>data.Duration > 0).sort((a, b) => b.Duration - a.Duration));
                // }
              });
            }}
          }
          // Save the current inputData as the previous inputData
          setPreviousInputData(inputData);
        })
        .catch((err) => {
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          // consol(err);
          setQuerySuccess(false);
        });
    }
  }, [selectedOption.Refrence, selectedOption.root]);
  // // consol(selectedEquipOption, "historical data list");

  const handleSelectChange = (event) => {
    // // consol(event.target.value, "current value of selected option");
    setSelectedOption({ Refrence: event.target.value, root: 1 });
    setroot({ root: 1 });
    setHistoricalData([]);
    setpieheader([]);
    setpiedata([]);
  };

  const handleSort = (sortColumn) => {
    if (columns.length > 0) {
      const sorted = _.orderBy(columns, [sortColumn.path], [sortColumn.order]);
      setcolumn(sorted);
      setSortColumn(sortColumn);
    }
  };
  const handleSort1 = (sortColumn) => {
    if (columns1.length > 0) {
      const sorted = _.orderBy(columns1, [sortColumn.path], [sortColumn.order]);
      setcolumns1(sorted);
      setSortColumn(sortColumn);
    }
  };
  const handleSort2 = (sortColumn) => {
    if (columns2.length > 0) {
      const sorted = _.orderBy(columns2, [sortColumn.path], [sortColumn.order]);
      setcolumns2(sorted);
      setSortColumn(sortColumn);
    }
  };
  
  const goBackToBarChart = () => {
    setSelectedCity(null);
    setpiedata([]);
    setHistoricalData([]);
    setpieheader([]);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));
  };
  // // consol(selectedDates, "date to be send");
  function formatInputDateRange(inputStr) {
    if(inputStr === undefined ||  inputStr === 'undefined') {
      return
    }
    const parts = inputStr.split(",");
    const startStr = parts[1];
    const endStr = parts[2];
  
    const format = "DD MMM YYYY ddd";
    const formattedStartDate = moment(startStr, "ddd MMM DD YYYY h:mm:ss A").format(format);
    const formattedEndDate = moment(endStr, "ddd MMM DD YYYY h:mm:ss A").format(format);
  
    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
  
    return formattedDateRange;
  }
  function formatInputDateRange1(inputStr) {
    if(inputStr === undefined ||  inputStr === 'undefined') {
      return
    }
    const parts = inputStr.split(",");
    const startStr = parts[0];
    const endStr = parts[1];
  
    const format = "DD MMM YYYY ddd";
    const formattedStartDate =startStr;
    const formattedEndDate = moment(endStr, "ddd MMM DD YYYY h:mm:ss A").format(format);
  
    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
  
    return formattedDateRange;
  }
  function compareDates(dateRangeA, dateRangeB) {
    const startDateA = new Date(dateRangeA[0].split(' - ')[0]);
    const startDateB = new Date(dateRangeB[0].split(' - ')[0]);
    return startDateA - startDateB;
  }
  let handleOnSubmit =async () => {
    let inputData = { ...senddata };
    let eqg =selectedEquipOption.map((option) => option.label)
    let eqp =selectedEquipGroupOption.map((option) => option.label)
    inputData.Equipment = eqg
    // inputData.EquipmentGroup =  eqp
    // // consol(selectedDates, inputData, "refrence tag1",eqp,eqg,selectedEquipOption);
    const errors = validate.validate(inputData, schema);

    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true);
    let dateTime = { ...selectedDates };
    if(new Date(dateTime.From) <= new Date(dateTime.Till)){
      inputData.From = dateTime.From;
      inputData.Till = dateTime.Till;
    }else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
    
    const compressedData = pako.gzip(JSON.stringify(inputData)); 

    const result = await axios.post(api.api + 'GetDownTimeAnalysis', compressedData, {
      headers: {
        'Content-Encoding': 'gzip', // Set the content encoding to gzip
        'Content-Type': 'application/json', // Set the content type to JSON
      },
    })
      .then((result) => {
        const decompressedData = pako.ungzip(result.data, { to: 'string' });
        // Assuming the decompressed data is in JSON format, parse it
        const jsonData = JSON.parse(decompressedData);
       
        if (jsonData !== null && jsonData !== undefined) {
          if (jsonData.length > 0) {
            // console.log(jsonData, "downtime result received");
           
            const groupedData2 = [];

            const groupedData = {};
            const tablehead = {};

            // jsonData.forEach(d => {
            if (jsonData[0].RefrenceTag !== "DowntimeReason" && jsonData[0].RefrenceTag !== "ReasonGroup"){
              
              // Loop through the raw data and group by 'Periodicity'
              jsonData.sort((a,b)=> b.Duration - a.Duration)
              let paretodata = organiseParetoData(jsonData,'RefrenceTag')
              setparetodata(paretodata)
              let Periodicity;
              jsonData.forEach((entry) => {
                if(senddata.Periodicity[0] === 'Shift'){
                  Periodicity = formatInputDateRange1(entry.Periodicity);
        
              
                }else{
                   Periodicity = formatInputDateRange(entry.Periodicity);
               
                }
                if (inputData.Periodicity == "Cummulative") {
                  Periodicity = "Cummulative";
                }
                // // consol(Periodicity, "Periodicity", inputData.Periodicity);
                let From = moment(entry.From).format("MMMM D, YYYY h:mm A");
                let Till = moment(entry.Till).format("MMMM D, YYYY h:mm A");
                let Ref = entry.RefrenceTag;
                if (inputData.Refrence == "time") {
                  Ref = "Total DownTIme";
                }
                let down = Math.ceil(entry.Duration/60);
                if (down === 0) {
                  return; // Skip this iteration if down is 0
                }
                
                if (!groupedData[Periodicity]) {
                  groupedData[Periodicity] = [];
                  // tablehead[Periodicity] = [];
                  // // consol("IN");
                }
                // // consol("OUT");

                groupedData[Periodicity].push(
                  down,
                  Ref,
                  [Ref, From, Till, "DownTime:" + down],
                  createCustomHTMLContentBarchart(Ref, From, Till, down)
                );
                // tablehead[Periodicity].push(Ref);
              });

              // Convert the grouped data into the desired format
              const transformed = Object.entries(groupedData).map(
                ([periodicity, durations]) => [periodicity, ...durations]
              );
              const  transformedData = transformed.sort(compareDates);
              
              // const longestArray = Object.values(tablehead).reduce((longest, current) => {
              //   return current.length > longest.length ? current : longest;
              // }, []);

              let allobject = Object.values(groupedData);
              
              const maxLength = Math.max(
                ...transformedData.map((entry) => entry.length)
              );
            
              // Pad arrays with zeros
              const paddedData = transformedData.map((entry) => {
                while (entry.length < maxLength) {
                  entry.push(null);
                }
                return entry;
              });
              const columnLength = ["weeks"];
              for (let i = 0; i < transformedData[0].length / 4 - 1; i++) {
                columnLength.push(
                  "count",
                  { role: "annotation" },
                  { type: "number", role: "interval" },
                  { type: "string", role: "tooltip", p: { html: true } }
                );
              }
          
       
             
              let tabledata = [columnLength, ...transformedData];
              // // consol(tabledata,transformedData, "final table data 1");

              settabledata(tabledata);
            } else {


              if(jsonData[0].RefrenceTag === "ReasonGroup"){
               
                let dailyrejection = [];
                let Periodicity;
                jsonData.forEach((d) => {
                  let periodicityfortable =d.Periodicity
                  if(senddata.Periodicity[0] === 'Shift'){
                    Periodicity = formatInputDateRange1(d.Periodicity);
          
                
                  }else{
                     Periodicity = formatInputDateRange(d.Periodicity);
                 
                  }
                  if (inputData.Periodicity == "Cummulative") {
                    Periodicity = "Cummulative";
                  }
                  let From = moment(d.From).format("MMMM D, YYYY h:mm A");
                  let Till = moment(d.Till).format("MMMM D, YYYY h:mm A");
                  // d.Summary.sort((a,b)=> b.Duration - a.Duration)
                  // // consol('current group',jsonData[0].RefrenceTag,jsonData,d.Summary)
                  d.Summary.forEach((one) => {
                    let Ref = one.DowntimeGroup;
                    if (inputData.Refrence == "time") {
                      Ref = "Total DownTIme";
                    }
                    let down =Math.ceil(one.Duration/60);
                    if (down === 0) {
                      return; // Skip this iteration if down is 0
                    }
                    dailyrejection.push({Periodicity:periodicityfortable ,ReasonGroup: one.DowntimeGroup,Duration:one.Duration})
                    
                    if (!groupedData[Periodicity]) {
                      groupedData[Periodicity] = [];
                    }

                    groupedData[Periodicity].push(
                      down,
                      Ref,
                      [Ref, From, Till, down],
                      createCustomHTMLContentBarchart(Ref, From, Till, down),color,
                    );
                  
                  });
                });
                let paretodata = organiseParetoData(dailyrejection,'ReasonGroup',Periodicity)
                setparetodata(paretodata)
                
                const transformed = Object.entries(groupedData).map(
                  ([periodicity, down]) => [periodicity, ...down]
                );
                const  transformedData = transformed.sort(compareDates);
  
                let allobject = Object.values(groupedData);
                // // consol(groupedData, "grouped data", transformedData, allobject);
                const maxLength = Math.max(
                  ...transformedData.map((entry) => entry.length)
                );
  
                // Pad arrays with zeros
                const paddedData = transformedData.map((entry) => {
                  while (entry.length < maxLength) {
                    entry.push(null);
                  }
                  return entry;
                });
                transformedData.sort((a, b) =>a[0] - b[0]);
  
                const columnLength = ["weeks"];
                for (let i = 0; i < transformedData[0].length / 5 - 1; i++) {
                  columnLength.push(
                    "count",
                    {
                      sourceColumn: 0,
                      role: "annotation",
                      type: "string",
                      calc: "stringify",
                    },
                    { type: "number", role: "interval" },
                    { type: "string", role: "tooltip", p: { html: true } },{ role: 'style' }
                  );
                }
                // // consol("transformedData data", transformedData);
                let tabledata = [columnLength, ...transformedData];
                // let tabledata =[["string", "Rejection Count 1",{role: 'style' },{type: 'string', role: 'tooltip'}], ...transformedData]
                // // consol(tabledata, "downtimereason data");
                settabledata(tabledata);
              }else{
                let dailyrejection = [];
                let Periodicity;
                jsonData.forEach((d) => {
                  let periodicityfortable =d.Periodicity
                  if(senddata.Periodicity[0] === 'Shift'){
                    Periodicity = formatInputDateRange1(d.Periodicity);
          
                
                  }else{
                     Periodicity = formatInputDateRange(d.Periodicity);
                 
                  }
                  if (inputData.Periodicity == "Cummulative") {
                    Periodicity = "Cummulative";
                  }
                  let From = moment(d.From).format("MMMM D, YYYY h:mm A");
                  let Till = moment(d.Till).format("MMMM D, YYYY h:mm A");
                  d.Summary.sort((a,b)=> b.Duration - a.Duration)
  
                
  const downtimeReasonsMap = new Map(color);
  
                  d.Summary.forEach((one) => {
                    let Ref = one.DowntimeReason;
                    if (inputData.Refrence == "time") {
                      Ref = "Total DownTIme";
                    }
                    let down =Math.ceil(one.Duration/60);
                    if (down === 0) {
                      return; // Skip this iteration if down is 0
                    }
                    dailyrejection.push({Periodicity:periodicityfortable ,DowntimeReason: one.DowntimeReason,Duration:one.Duration})
                    
                    if (!groupedData[Periodicity]) {
                      groupedData[Periodicity] = [];
                    }
                    const color = downtimeReasonsMap.get(Ref) || 'defaultColor'; // Use a default color if not found
                    // // consol(color,"colors for the barchart",downtimeReasonsMap)
                    groupedData[Periodicity].push(
                      down,
                      Ref,
                      [Ref, From, Till, down],
                      createCustomHTMLContentBarchart(Ref, From, Till, down),color,
                    );
                  
                  });
                });
                let paretodata = organiseParetoData(dailyrejection,'DownTimeReason',Periodicity)
                setparetodata(paretodata)
                
                const transformed = Object.entries(groupedData).map(
                  ([periodicity, down]) => [periodicity, ...down]
                );
                const  transformedData = transformed.sort(compareDates);
  
                let allobject = Object.values(groupedData);
                // // consol(groupedData, "grouped data", transformedData, allobject);
                const maxLength = Math.max(
                  ...transformedData.map((entry) => entry.length)
                );
  
                // Pad arrays with zeros
                const paddedData = transformedData.map((entry) => {
                  while (entry.length < maxLength) {
                    entry.push(null);
                  }
                  return entry;
                });
                transformedData.sort((a, b) =>a[0] - b[0]);
  
                const columnLength = ["weeks"];
                for (let i = 0; i < transformedData[0].length / 5 - 1; i++) {
                  columnLength.push(
                    "count",
                    {
                      sourceColumn: 0,
                      role: "annotation",
                      type: "string",
                      calc: "stringify",
                    },
                    { type: "number", role: "interval" },
                    { type: "string", role: "tooltip", p: { html: true } },{ role: 'style' }
                  );
                }
                // // consol("transformedData data", transformedData);
                let tabledata = [columnLength, ...transformedData];
                // let tabledata =[["string", "Rejection Count 1",{role: 'style' },{type: 'string', role: 'tooltip'}], ...transformedData]
                // // consol(tabledata, "downtimereason data");
                settabledata(tabledata);
              }
              
            }
            if(piedata?.length > 0){
              setSelectedCity(null);
              setpiedata([])
              setHistoricalData([])
              setpieheader([])
            }
            setQuerySuccess(false);
          } else {
            swal("Data Not Available");
            setQuerySuccess(false);
          }
        } else {
          setQuerySuccess(false);
        }
      })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err);
        setQuerySuccess(false);
      });
  };
  const handelclosepie = (index) => {
    // // consol(historicalData.length,"historicalData length");

    const removedArray = historicalData[0];
    const newArray = [pieheader[0]];
    setpieheader([]);
    setHistoricalData([]);
    setroot({ root: 1 });
    setpiedata(removedArray);
    const ref = newArray[0]?.Refrence;
    setSelectedOption({ Refrence: ref, root: 1 });
    setsenddata(newArray[0]?.inputData);
    // // consol(removedArray, "removedArray", senddata, newArray);
  };
  // // consol(root,"current root");

  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };

  const options = {
    chartArea: {left:100,top:30,right:100,bottom:0,},
    title: `${selectedCity}  / From: ${moment(
      pieDateTime.From
    ).format("MMMM D, YYYY h:mm A")} Till: ${moment(pieDateTime.Till).format(
      "MMMM D, YYYY h:mm A"
    )} / Distribution BY ${selectedOption?.Refrence} `,
    is3D: true,
    // tooltip: { isHtml: true, trigger: "selection" },
  };
  const SelectedDates =(dates)=>{
    setSelectedDates(dates)
  }
  return (
    <div className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Detailed Downtime Analysis
        </h4>
      </div>
 <ToastContainer />
      <div className="row mx-2">
      {componentdata.length > 0  &&  (<>
        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

{/*      
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
      min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
            />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={"form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              // max={TillDateLimit}
            />
          </div>
        </div> */}

       
{equipGroups.length >0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection} />}
       
        <div className="col-sm-2 ">
                <div className="form-group text-center ">
                 {downtimereasongroup?.length > 0 ?<>
             

 <label className="small">{DowntimeReasonGroup.heading}</label>
                 
                 <ReusableCheckboxDropdown
                      initialItems={DowntimeReasonGroup.initialItems}
                      label={DowntimeReasonGroup.label}
                      heading={`${DowntimeReasonGroup.heading}`}
                      selectedItemIds={DowntimeReasonGroup.selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        // (newSelectedItems) for No. of item selected / current DowntimeReasonGroup name (DowntimeReasonGroup.name ) / (DowntimeReasonGroup.initialItems.length) is maximun length of list
                        handleSelectedItemsChange(newSelectedItems, DowntimeReasonGroup.name,DowntimeReasonGroup.initialItems.length) 
                      }
                      enableLocalStorage={DowntimeReasonGroup.enableLocalStorage} // Pass the enableLocalStorage prop to enable local storage (true/false)
                      multiSelect={DowntimeReasonGroup.multiSelect} // Pass the multiSelect prop to enable multiselect (true/false || " ")
                      searchbar={DowntimeReasonGroup.searchbar}
                      selectAll={DowntimeReasonGroup.selectAll}
                      />    </>:''}</div></div>
 <div className="col-sm-2 ">
                <div className="form-group text-center ">
{dynamicData?.length == 0 ? <>
 <label className="small">{downtime.heading}</label>
<ReusableCheckboxDropdown
                      initialItems={downtime.initialItems}
                      label={downtime.label}
                      heading={`${downtime.heading}`}
                      selectedItemIds={downtime.selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        // (newSelectedItems) for No. of item selected / current downtime name (downtime.name ) / (downtime.initialItems.length) is maximun length of list
                        handleSelectedItemsChange(newSelectedItems, downtime.name,downtime.initialItems.length) 
                      }
                      enableLocalStorage={downtime.enableLocalStorage} // Pass the enableLocalStorage prop to enable local storage (true/false)
                      multiSelect={downtime.multiSelect} // Pass the multiSelect prop to enable multiselect (true/false || " ")
                      searchbar={downtime.searchbar}
                      selectAll={downtime.selectAll}

                      />
                      </> :''}
                      {dynamicData?.length > 0 ? (
  <React.Fragment key={dynamicData.length}>
    <label className="small">{downtime.heading}</label>
    <ReusableCheckboxDropdown
      initialItems={downtime.initialItems}
      label={downtime.label}
      heading={`${downtime.heading}`}
      selectedItemIds={downtime.selectedItemIds}
      onSelectedItemsChange={(newSelectedItems) =>
        handleSelectedItemsChange(newSelectedItems, downtime.name, downtime.initialItems.length) 
      }
      enableLocalStorage={downtime.enableLocalStorage}
      multiSelect={downtime.multiSelect}
      searchbar={downtime.searchbar}
      selectAll={downtime.selectAll}

    />
  </React.Fragment>
) : ''}

                       
                        </div></div>
        {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  
                    <>
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}
                      selectedItemIds={data.selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        // (newSelectedItems) for No. of item selected / current data name (data.name ) / (data.initialItems.length) is maximun length of list
                        handleSelectedItemsChange(newSelectedItems, data.name,data.initialItems.length) 
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop to enable local storage (true/false)
                      multiSelect={data.multiSelect} // Pass the multiSelect prop to enable multiselect (true/false || " ")
                      searchbar={data.searchbar}
                      selectAll={data.selectAll}

                      />
                     
                      </>
                 
   

                  {  errors[data.heading] && (
                    <p className="text-danger " style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}

        




        <div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div> </>
        )}
        
{paretodata?.length> 0?(<div className="col-sm-3">
<div className="cl-toggle-switch mx-2 mt-3">
    <span className="cl-toggle-span">

  <label className="cl-switch">
    <input type="checkbox" checked={tableVisible3}
        onChange={handleToggle3}/>
    <span>View Pareto</span>
  </label>
        </span>
 
</div>
        </div>):''}

      </div>

      {data.length > 0 ? (
        <div className="mt-5 ">
          <div className="shadow d-flex justify-content-center mx-3 mb-3" style={{backgroundColor:"white",borderRadius:"20px",width:"98%",overflow:'hidden'}}>
          <Chart
         
            chartType="ColumnChart"
            width="100%"
            height="500px"
            data={data}
            options={{
              
              title: "DownTime Analysis",
              chartArea: {left:80,top:50,right:50,bottom:60},
              annotations: {
                style: "line",
                role: "annotation",
                alwaysOutside: false,
                stem: {
                  color: "transparent",
                  length: 0, // Adjust the length of the annotation line
                },
                textStyle: {
                  fontSize: 12,
                },
              },
              legend:"none",
              hAxis: {
                title: ` Reference By ${Compareref[0]}`,
                minValue: 0,
                // slantedText: true,
                // slantedTextAngle: 90,
               
              },
              tooltip: { isHtml: true },
              vAxis: {
                title: "DownTime Duration( in minutes)",
              },
              bar: {
                groupWidth: "90%",
              },
            }}
            chartEvents={[
              {
                eventName: "select",
                callback: ({ chartWrapper }) => {
                  const selectedItem = chartWrapper
                    .getChart()
                    .getSelection()[0];
                  if (selectedItem) {
                    handleBarClick(selectedItem);
                  }
                },
              },
            ]}
          />
          </div>
          {data.length > 0 ? ( <div className="d-flex align-content-between flex-wrap shadow py-3  mx-3" style={{backgroundColor:"white",borderRadius:"15px",overflow:"hidden",width:"98%"}}>
          <div className="cl-toggle-switch mx-3 mt-3 mb-2">
    <span className="cl-toggle-span">

  <label className="cl-switch">
    <input type="checkbox" checked={tableVisible2}
        onChange={handleToggle2}/>
    <span>View ColumnChart In Table</span>
  </label>
        </span>
 
</div>
{selectedCity &&(<div className="mb-2 mx-2">
                  <p
                    style={{ color: "black", fontWeight: "500" }}
                    className=" p-2 bg-for-dishead rounded"
                  >
                  {Compareref[0]} : {Distributionvalues[0]} / {pieperiodicity}
                   
                  </p>

                  <select
                    className="rounded p-2 mx-1 shadow"
                    style={{ backgroundColor: "white", border: "none" }}
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value="">Select Distribution</option>
                    {Refrence.filter((obj) => obj.label !== Compareref[0]).map(
                      (one) => (
                        <option value={one.label}>{one.label}</option>
                      )
                    )}
                  </select>

                  <button
                    style={{ height: "35px" }}
                    className="btn btn-danger "
                    onClick={goBackToBarChart}
                  >
                    {" "}
                    X
                  </button>
                  </div>)}
</div>):""}
          
          {tableVisible2 && (columns2?.length > 0 ?(<div style={{backgroundColor:"white",borderRadius:"15px"}} className="mx-3 my-3 shadow ">
              <h2>Data Table</h2>
              <div className="d-flex mx-2">
                <p className="d-flex align-items-center text-center align-middle p-0 m-0" style={{color:"black",fontWeight:"600"}}>
                From : {selectedDates.From} /  Till :{selectedDates.Till} Distribution By {Compareref[0]}
                </p>
                {/* <button
                 type="button" className="btn mx-2 btn-primary p-1"
                  onClick={() =>
                    handleDownloadExcel("DownTimeGroupedBarchart")
                  }
                >
                  Download Excel
                </button> */}
                <div className="uiverse" onClick={() =>
      handleDownloadExcel("DownTimeGroupedBarchart")}>
    <span className="tooltip">Dowload Excel File</span>
    <span>
    Download
    </span>
</div>
              </div>
              {columns2?.length > 0 ? (
                <Table
                  id="DownTimeGroupedBarchart"
                  onSort={handleSort2}
                  columns={column2}
                  sortColumn={sortColumn}
                  data={columns2}
                />
              ) : (
                ""
              )}
            </div>):'')}

{paretodata?.length> 0 && tableVisible3 ? (renderParetoChart(paretodata)):''}

{columns1?.length > 0 && tableVisible3 ? (
  <>
     <div className="d-flex align-content-between flex-wrap shadow py-3  mx-3" style={{backgroundColor:"white",borderRadius:"15px",overflow:"hidden",width:"98%"}}>
             
     {tableVisible3 &&(<div className="cl-toggle-switch mx-3 mt-4">
    <span className="cl-toggle-span">

  <label className="cl-switch">
    <input type="checkbox" checked={tableVisible1}
        onChange={handleToggle1}/>
    <span>View ColumnChart In Table</span>
  </label>
        </span>
 
</div>)}

                
              
                </div>
 

          {tableVisible1 && ( <div style={{backgroundColor:"white",borderRadius:"15px"}} className="mx-3 mt-3 shadow">
  <h2>Data Table</h2>
  <div className="d-flex p-3">
    <p className="d-flex align-items-center text-center align-middle p-0 m-0" style={{color:"black",fontWeight:"600"}}>
    Pareto Distribution by {Compareref[0]} 

    </p>
    {/* <button
     type="button" className="btn p-1 btn-primary  mx-2"
     onClick={() =>
      handleDownloadExcel("DownTimeDetailedAnalysiscolumn")
    }
    >
      Download Excel
    </button> */}
    <div className="uiverse" onClick={() =>
      handleDownloadExcel("DownTimeDetailedAnalysiscolumn")}>
    <span className="tooltip">Dowload Excel File</span>
    <span>
    Download
    </span>
</div>
  </div>
                <Table
                  id="DownTimeDetailedAnalysiscolumn"
                  onSort={handleSort1}
                  columns={column1}
                  sortColumn={sortColumn}
                  data={columns1}
                  />
                  </div>)}
                  </>
              ) : (
                ""
              )}
          <div className="d-flex justify-content-between px-3 pt-3">
            {selectedCity ? (
              <>
                {/* { selectedOption.length >0 ? (  */}
                <div className="col-sm-12 shadow "  style={{backgroundColor:"white",borderRadius:"20px",width:"99%",overflow:"hidden"}}>
                  {historicalData.length > 1 && (
                    <button
                      className="btn btn-danger p-1 float-end"
                      onClick={handelclosepie}
                    >
                      close
                    </button>
                  )}
                  <div className="" >
                    {/* {root.root == 2 && historicalData.length > 0
                      ? historicalData.map((one, index) => (
                          <div ref={chartContainerRef}>
                            <Chart
                              loader={
                                <h1>Select Distribution For pie Chart </h1>
                              }
                              className="position-absolute top-50 start-50"
                              chartType="PieChart"
                              width="100%"
                              height="500px"
                              data={one}
                              options={{
                                chartArea: {left:100,top:20,right:100,bottom:20,},
                                title: `${selectedCity}  / ${pieperiodicity} / From: ${moment(
                                  pieDateTime.From
                                ).format("MMMM D, YYYY h:mm A")} Till: ${moment(
                                  pieDateTime.Till
                                ).format(
                                  "MMMM D, YYYY h:mm A"
                                )} / Distribution BY ${
                                  pieheader[index]?.Refrence
                                }`,
                                is3D: true,
                                tooltip: { isHtml: true, trigger: "selection" },
                              }}
                              chartEvents={[
                                {
                                  eventName: "select",
                                  callback: ({ chartWrapper }) => {
                                    const selectedItem = chartWrapper
                                      .getChart()
                                      .getSelection()[0];
                                    if (selectedItem) {
                                      // Handle the slice click as before
                                      const data = one[selectedItem.row + 1][0];
                                      const value =
                                        one[selectedItem.row + 1][1];
                                      // consol(
                                        data,
                                        "data",
                                        value,
                                        "no data"
                                      );
                                      const head = selectedOption.Refrence;
                                      setnewdata(data);
                                      sethead(head);
                                      setindexofpie(index)
                                    }
                                  },
                                },
                              ]}
                            />
                          </div>
                        ))
                      : ""}
                    {root.root == 1 && piedata.length > 0 ? (
                      <div ref={chartContainerRef}>
                        <Chart
                          loader={<h1>Select Distribution For pie Chart </h1>}
                          className="position-absolute top-50 start-50"
                          chartType="PieChart"
                          width="100%"
                          height="500px"
                          data={piedata}
                          options={options}
                          chartEvents={[
                            {
                              eventName: "select",
                              callback: ({ chartWrapper }) => {
                                const selectedItem = chartWrapper
                                  .getChart()
                                  .getSelection()[0];
                                if (selectedItem) {
                                  // Handle the slice click as before
                                  const data = piedata[selectedItem.row + 1][0];
                                  const value =
                                    piedata[selectedItem.row + 1][1];
                                  // consol(data, "data", value, "no data");
                                  const head = selectedOption.Refrence;
                                  setnewdata(data);
                                  sethead(head);
                                }
                              },
                            },
                          ]}
                        />
                      </div>
                    ) : (
                      ""
                    )} */}
                    { piedata.length > 0 ? (
                      <div ref={chartContainerRef}>
                        <Chart
                          loader={<h1>Select Distribution For pie Chart </h1>}
                          // className="position-absolute top-50 start-50"
                          chartType="PieChart"
                          width="100%"
                          height="500px"
                          data={piedata}
                          options={options}
                          // chartEvents={[
                          //   {
                          //     eventName: "select",Fsubmit
                          //     callback: ({ chartWrapper }) => {
                          //       const selectedItem = chartWrapper
                          //         .getChart()
                          //         .getSelection()[0];
                          //       if (selectedItem) {
                          //         // Handle the slice click as before
                          //         const data = piedata[selectedItem.row + 1][0];
                          //         const value =
                          //           piedata[selectedItem.row + 1][1];
                          //         // consol(data, "data", value, "no data");
                          //         const head = selectedOption.Refrence;
                          //         setnewdata(data);
                          //         sethead(head);
                          //       }
                          //     },
                          //   },
                          // ]}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* ):(<div className="col-sm-2"></div>)} */}
             
              </>
            ) : (
              ""
            )}
          </div>
          
        </div>
      ) : (
        ""
      )}
      {piedata.length > 0 ? (<div className="mt-4 mx-3">
                  <div className="cl-toggle-switch ">
          <span className="cl-toggle-span ">

              <label className="cl-switch">
                   <input type="checkbox" checked={tableVisible}
                       onChange={handleToggle}/>
                   <span>View PieChart In Table</span>
                  </label>
                  </span>
 
               </div> </div>):''}
      {piedata.length > 0 ? ( 
         
          tableVisible && (
            <div style={{backgroundColor:"white",borderRadius:"15px"}} className="mx-3 my-3 shadow ">
              <h2>Data Table</h2>
              <div className="d-flex mx-2">
                <p className="d-flex align-items-center text-center align-middle p-0 m-0" style={{color:"black",fontWeight:"600"}}>
                  {Compareref[0]} : {Distributionvalues[0]} / {pieperiodicity}
                </p>
                {/* <button
                 type="button" className="btn mx-2 btn-primary p-1"
                  onClick={() =>
                    handleDownloadExcel("DownTimeDetailedAnalysispiechart")
                  }
                >
                  Download Excel
                </button> */}
                <div className="uiverse" onClick={() =>
      handleDownloadExcel("DownTimeDetailedAnalysispiechart")}>
    <span className="tooltip">Dowload Excel File</span>
    <span>
    Download
    </span>
</div>
              </div>
              {columns?.length > 0 ? (
                <Table
                  id="DownTimeDetailedAnalysispiechart"
                  onSort={handleSort}
                  columns={column}
                  sortColumn={sortColumn}
                  data={columns}
                />
              ) : (
                ""
              )}
            </div>
          )
       
      ) : (
        ""
      )}

    </div>
  );
}

export default DowntimeDetailedAnalysis;

















// import React, { Component } from 'react';
// import 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min.js';
// import Chart from 'react-google-charts';
// import { generateExcelFile } from '../utill/download';
// import api from '../config.json';
// import swal from 'sweetalert';
// import axios from '../httpServices/httpService';
// import Loading from '../utill/LoadingComponent';
// import validate from '../utill/validation';
// import joi from 'joi'
// import Table from '../Common/table';
// import EquipmentSelection from '../Common/equipmentSelection';
// import MultiSelectCheckBox from '../Common/MultiSelectCheckBox';

// className QualityAnalysis extends Component {
//   state = {
//     shiftOption: [
//       { label: 'Shift1', value: 'Shift1', checked: false },
//       { label: 'Shift2', value: 'Shift2', checked: false },
//       { label: 'Shift3', value: 'Shift3', checked: false },
//       { label: 'Shift4', value: 'Shift4', checked: false }],
//     myNewChart: '',
//     viewBarChartOnClick: false,
//     filterBy: '',
//     barColorStateWise: 'red',
//     downtimeParetoOrganiseData: [],
//     downtimeParetoData: [],
//     downtimeReasons: [],
//     shiftSetting: [],
//     Bardata: [
//       ["Machine", "Time(In Mins)", { role: "style" }],

//     ],

//     inputData: {},
//     errors: {},
//     downtimeDataCumulative: [],
//     querySuccess: false,
//     viewBarChartOnClick: false,
//     selectedEquipGroupOption: [],
//     equip: [],
//     selectedEquipOption: []

//   }
//   Bardata = [
//     ["Machine", "Time(In Mins)", { role: "style" }],

//   ];

//   setSelection = (key, option, equips) => {
//     if (key == 'EquipmentGroup') {
//       this.setState({ equip: equips ? equips : [], selectedEquipGroupOption: option, selectedEquipOption: [] })
//     }
//     if (key == 'Equipment') {

//       this.setState({ selectedEquipOption: option })
//     }
//   }

//   getEquipsForReport = () => {
//     let equipArr = []
//     this.props.equips.forEach((one) => {
//       this.state.selectedEquipOption.forEach((option) => {
//         if (one._id == option.value) {
//           equipArr.push(one);
//         }
//       })
//     })
//     return equipArr;
//   }

//   schema = {

//     UserId: joi.string(),

//     Equips: joi.array(),
//     From: joi.date().required().label('From Datetime'),
//     Till: joi.date().required().label('Till Datetime'),
//     Shift: joi.array().min(1).required().label('please select at least one shift'),
//     //Shift: joi.string().required().label('Shift')
//   }

//   columns = [
//     { path: 'ReasonGroup', label: 'Reason Group' },
//     { path: 'State', label: 'Reason' },
//     { path: 'Duration', label: 'Duration (In Mins)', content: (obj) => <label>{((obj.Duration / (60)).toFixed(1))}</label> },
//     { path: 'OriginalPercentage', label: 'Individual Percentage', content: (obj) => <label>{(parseFloat(obj.OriginalPercentage)).toFixed(1)}</label> },
//     { path: 'Percentage', label: 'Cumulative Percentage', content: (obj) => <label>{(parseFloat(obj.Percentage)).toFixed(1)}</label> },
//   ]

//   handleDownloadExcel = (e) => {
//     generateExcelFile(e)
//   }

//   handleOnChange = async (e) => {
//     const { inputData, errors } = { ...this.state }
//     if (e.currentTarget.type === 'date') {
//       inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
//     }
//     else {
//       if (e.currentTarget.name == 'Shift') {
//         const options = [...this.state.shiftOption];
//         if (e.currentTarget.value && (e.currentTarget.value == 'All')) {
//           options.forEach(one => one.checked = e.currentTarget.checked);
//         } else {
//           options.forEach(one => {
//             if (one.value == e.currentTarget.value) {
//               one.checked = e.currentTarget.checked
//             }
//           });
//         }
//         inputData[e.currentTarget.name] = options.filter(one => one.checked);
//         this.setState({ shiftOption: options });
//       } else {
//         inputData[e.currentTarget.name] = e.currentTarget.value;
//       }
//     }
//     let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime })
//     this.setState({ inputData: inputData, errors: errors || {}, shiftSetting: res.data })
//   }

//   handleOnSubmit = (inputData) => {
//     inputData = { ...inputData }

//     const errors = validate.validate(inputData, this.schema);

//     this.setState({ errors: errors || {} })
//     if (errors) return;
//     this.setState({ querySuccess: true })

//     inputData.UserId = this.props.user._id
//     inputData.Equips = this.getEquipsForReport()
//     inputData.Shift = inputData.Shift.map(one => one.value);
//     axios.post(api.api + 'GetDowntimePareto', inputData).then((result) => {
//       // consol(result)
//       if (jsonData !== null && result.data !== undefined) {
//         if (result.data.length > 0) {
//           let data = this.organiseParetoData(result.data)
//           this.setState({ querySuccess: false, downtimeParetoOrganiseData: data, downtimeParetoData: result.data });

//         }
//         else {
//           swal('Data Not Available');
//           this.setState({ querySuccess: false })
//         }
//       }
//       else {
//         this.setState({ querySuccess: false })
//       }

//     })
//       .catch((err) => {
//         swal(`${err}`);
//         // consol(err)
//         this.setState({ querySuccess: false })
//       })

//   }

//   organiseParetoData = (clone) => {
//     let data = [...clone];
//     let downTimeParetoArray = [];
//     let schema = ['State', 'Time( in minutes)', { role: 'style' }, 'Percentage(%)'];
//     downTimeParetoArray.push(schema);
//     //    if (this.props.reasonSearchText.length > 0) {
//     //      data = data.filter(reason => { return reason.Reason.replace(/_/gi, '').toLowerCase().startsWith(this.props.reasonSearchText.toLowerCase()) })
//     //      for (let i = 0; i < data.length; i++) {
//     //        let StateArray = [];
//     //        if (data[i].Reason !== "OK") {
//     //          StateArray.push(data[i].Reason);
//     //          StateArray.push(data[i].Duration);
//     //          StateArray.push(data[i].Color.toLowerCase());
//     //          StateArray.push(data[i].Percentage);
//     //          // accumPer= accumPer+(this.props.downTimeParetoData[i].Duration/(60*1000))/sum*100;
//     //          // StateArray.push(accumPer);
//     //          downTimeParetoArray.push(StateArray);
//     //        }
//     //      }
//     //    }
//     //    else
//     //    {
//     for (let i = 0; i < data.length; i++) {
//       let StateArray = [];
//       if (data[i].Reason !== "OK") {
//         StateArray.push(data[i].State);
//         StateArray.push(parseFloat((data[i].Duration / (60)).toFixed(1)));
//         StateArray.push(data[i].Color);
//         StateArray.push(parseFloat((data[i].Percentage).toFixed(1)));
//         // accumPer= accumPer+(this.props.downTimeParetoData[i].Duration/(60*1000))/sum*100;
//         // StateArray.push(accumPer);
//         downTimeParetoArray.push(StateArray);
//       }
//     }
//     // }
//     // consol(data);
//     let accumPer = 0;
//     return downTimeParetoArray;
//   }

//   render() {

//     return (
//       <div style={{ marginTop: 30 }} className='justify-content-center'>
//         {this.state.querySuccess ? <Loading /> : ''}
//         <div className='text-center' >
//           <p style={{ padding: 0 }}>Downtime Detailed Analysis</p>
//         </div>
//         <div className='row'>

//         <div className="col-sm-2">
//             <div className="form-group text-center">
//               <label className='small'>Till</label>
//               <input className={'form-control form-control-sm'} placeholder='dd/mm/yyyy' type='date' onChange={this.handleOnChange} name='Till' />
//               {this.state.errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.Till}</p>}
//             </div>
//           </div>
//           <div className="col-sm-2">
//             <div className="form-group text-center">
//               <label className='small'>From</label>
//               <input className={'form-control form-control-sm'} placeholder='dd/mm/yyyy' type='date' onChange={this.handleOnChange} name='From' />
//               {this.state.errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.From}</p>}
//             </div>
//           </div>
//           <div className="col-sm-2">
//             <div className="form-group text-center">
//               <label className='small'>Shift</label>
//               <MultiSelectCheckBox name='Shift' onChange={this.handleOnChange} selectedValues={this.state.inputData['Shift']} options={this.state.shiftOption} />
//               {this.state.errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{this.state.errors.Shift}</p>}
//             </div>
//           </div>
//           <EquipmentSelection equipGroups={this.props.equipGroups} equip={this.state.equip} selectedEquipGroupOption={this.state.selectedEquipGroupOption} selectedEquipOption={this.state.selectedEquipOption} setSelection={this.setSelection} />

//           <div className="col-sm-1">
//             <button className='btn btn-primary btn-sm mt-4' onClick={() => this.handleOnSubmit(this.state.inputData)}>Apply</button>
//           </div>
//         </div>
//         {this.state.downtimeParetoOrganiseData.length > 0 ? <div className='row ml-2'>
//           {this.renderParetoChart(this.state.downtimeParetoOrganiseData)}

//         </div> : ''}

//         {this.state.downtimeParetoData.length > 0 ? <div className="col-sm-7 table-responsive-sm table-striped" >
//           <p className='btn btn-primary btn-sm' onClick={() => this.handleDownloadExcel('DowntimePareto')}>Download Excel</p>
//           <Table columns={this.columns} id='DowntimePareto' data={this.state.downtimeParetoData} sortColumn={{ path: 'Reason', order: 'asc' }} />
//         </div > : ''}

//       </div>

//     );
//   }
// }

// export default QualityAnalysis;

// import Select from "react-select";
// import React, { useState, useEffect, useMemo } from "react";
// import "chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.min.js";
// // import Chart from 'react-google-charts';
// import { generateExcelFile } from "../utill/download";
// import api from "../config.json";
// import swal from "sweetalert";
// import axios from "../httpServices/httpService";
// import Loading from "../utill/LoadingComponent";
// import validate from "../utill/validation";
// import joi from "joi";
// import Table from "../Common/table";
// import EquipmentSelection from "../Common/equipmentSelection";
// import MultiSelectCheckBox from "../Common/MultiSelectCheckBox";
// import { Chart } from "react-google-charts";
// import Modal from "react-modal"; // Import react-modal
// import ReusableCheckboxDropdown from "./CheckBoxDropdowComponent";

// const data = [
//   ["City", "DownTime 1",{role: 'style' }],
//   ["m-01", 51575, "opacity: 0.2"],
//   ["pp-01", 179620, "opacity: 0.2;fill-color: #C5A5CF"],
//   ["pp-02", 11500,"opacity: 0.2"],
//   ["m-d02", 20699,"opacity: 0.2"],
//   ["m-012", 15265, "opacity: 0.2"],
//   ["pp-05", 17056, "opacity: 0.2"],
// ];

// const options = {
//   title: "DownTime Analysis",
//   chartArea: { width: "60%" },
//   isStacked: true,
//   hAxis: {
//     title: "Total Downtime",
//     minValue: 0,
//   },
//   vAxis: {
//     title: "City",
//   },
//   legend: { position: "top", maxLines: 3 },
// };

// function DowntimeDetailedAnalysis(props) {
//   const [shiftOption, setShiftOption] = useState([
//     { label: "Shift1", id: "1" },
//     { label: "Shift2", id: "2" },
//     { label: "Shift3", id: "3" },
//     { label: "Shift4", id: "4" },
//   ]);
//   const [periodOption, setperiodOption] = useState([
//     { label: "Daily", id: "1" },
//     { label: "Weekly", id: "2" },
//     { label: "Monthly", id: "3" },
//   ]);
//   const [Refrence, setRefrence] = useState([
//     { label: "Equipment", id: "1" },
//     { label: "Component", id: "2" },
//     { label: "Operator", id: "3" },
//     { label: "Tool", id: "4" },
//     { label: "Operation", id: "5" },
//     { label: "time", id: "6" },
//     { label: "DowntimeReason", id: "7" },

//   ]);

//   const [inputData, setInputData] = useState({});
//   const [errors, setErrors] = useState({});
//   const [querySuccess, setQuerySuccess] = useState(false);
//   const [selectedEquipOption, setSelectedEquipOption] = useState([]);
//   const [dynamicData, setDynamicData] = useState([]);
//   const [componentdata, setcomponentdata] = useState([]);
//   const [selectedCity, setSelectedCity] = useState(null);
//   const [selectedParts, setSelectedParts] = useState([
//     { value: 1, label: "DownTime 1" },
//   ]);
//   // Function to handle bar click
//   const [selectedItemIds, setSelectedItemIds] = useState([]);
//   const [operatordata, setoperatordata] = useState([]);
//   const [tooldata, settooldata] = useState([]);
//   const [operationdata, setoperationdata] = useState([]);
//   const [equpdata, setequpdata] = useState([]);
//   const [senddata, setsenddata] = useState([]);

//   const DropDownData =[
//     {heading:'Equipment' ,label:'tablecolumns',initialItems:equpdata,   selectedItemIds:selectedItemIds,name:"Equipment",enableLocalStorage:false,multiSelect:true},
//     {heading:'Operation' ,label:'tablecolumns',initialItems:operationdata,   selectedItemIds:selectedItemIds,name:"Operation",enableLocalStorage:false,multiSelect:true},
//     {heading:'Component' ,label:'tablecolumns',initialItems:componentdata,   selectedItemIds:selectedItemIds,name:"Component",enableLocalStorage:false,multiSelect:true},
//     {heading:'Operator' ,label:'tablecolumns',initialItems:operatordata,   selectedItemIds:selectedItemIds,name:"Operator",enableLocalStorage:false,multiSelect:true},
//     {heading:'Shift' ,label:'tablecolumns',initialItems:shiftOption,   selectedItemIds:selectedItemIds,name:"Shift",enableLocalStorage:false,multiSelect:true},
//     {heading:'DowntimeReason' ,label:'tablecolumns',initialItems:equpdata,   selectedItemIds:selectedItemIds,name:"DowntimeReason",enableLocalStorage:false,multiSelect:true},
//     {heading:'Tool' ,label:'tablecolumns',initialItems:tooldata,   selectedItemIds:selectedItemIds,name:"Tool",enableLocalStorage:false,multiSelect:true},
//     {heading:'Periodicity' ,label:'tablecolumns',initialItems:periodOption,   selectedItemIds:selectedItemIds,name:"Periodicity",enableLocalStorage:false,multiSelect:true},
//     {heading:'Refrence' ,label:'tablecolumns',initialItems:Refrence,   selectedItemIds:selectedItemIds,name:"Refrence",enableLocalStorage:false,multiSelect:true},

//   ]

//   const schema = {
//     UserId: joi.string(),
//     Equips: joi.array(),
//     From: joi.date().required().label("From Datetime"),
//     Till: joi.date().required().label("Till Datetime"),
//     Shift: joi
//       .array()
//       .min(1)
//       .required()
//       .label("please select at least one shift"),
//     //Shift: joi.string().required().label('Shift')
//   };

//   // const initialItems = (optionlist,DocumentType) => {
//   //   let itemss=optionlist.map(option =>option[DocumentType])
//   //   // consol(itemss.map((item,index) => ({
//   //     label: ` ${item}`,
//   //     id: `${index}`, // Change this line to use the 'id' property

//   //   })),"testing data",itemss)
//   //   return itemss.map((item,index) => ({
//   //     id: `${index}`,
//   //     label: `${item}`,
//   //   }))
//   // }

//   useEffect(() => {
//     // Fetch dynamic data using Axios
//     const fetchData = async () => {
//       try {
//         const downtimedata = await axios.get(api.api + "downtimereason");
//         const componentdata = await axios.get(api.api1 + "component");
//         const operatordata = await axios.get(api.api1 + "operator");
//         const tooldata = await axios.get(api.api + "tool");
//         let equipmentdata = await axios.get(api.api + 'equipment');

//         const downtimelist = downtimedata.data.map((reason, index) => ({
//           id: `${index}`,
//           label: reason.Reason,
//         }));
//         const complist = componentdata.data.map((componentList, index) => ({
//           id: `${index}`,
//           label: componentList.ComponentId,
//         }));

//         // Iterate through the data and extract operation IDs
//         const operationIds = [];
//         componentdata.data.forEach(item => {
//           item.Operation.forEach(operation => {
//             operationIds.push(operation.OperationID);
//           });
//         });

//         // Sort and remove duplicates from operationIds
//         const uniqueSortedOperations = [...new Set(operationIds)].sort((a, b) => a - b).map((operation,index)=> ({id:index,label:operation}));
//         const operatorlist = operatordata.data.map((operator, index) => ({
//           id: `${index}`,
//           label: operator.Name,
//         }));
//         const toollist = tooldata.data.map((tool, index) => ({
//           id: `${index}`,
//           label: tool.ToolId
//         }));

//         const equiplist =equipmentdata.data.map((tool, index) => ({
//           id: `${index}`,
//           label: tool.EquipmentID
//         }));
//         setequpdata(equiplist)
//         setoperationdata(uniqueSortedOperations)
//         settooldata(toollist)
//         setoperatordata(operatorlist);
//         setDynamicData(downtimelist);
//         setcomponentdata(complist);
//         // consol("fetching data",equipmentdata);
//       } catch (error) {
//         // console.error("Error fetching data:", error);
//       }
//     };

//     fetchData();
//   }, []); // Runs once when the component mounts

//   // consol(selectedItemIds, "testing data");

//   // consol(props.componentList, "component data");

//   const getEquipsForReport = () => {
//     let equipArr = [];
//     props.equips.forEach((one) => {
//       selectedEquipOption.forEach((option) => {
//         if (one._id === option.value) {
//           equipArr.push(one);
//         }
//       });
//     });
//     return equipArr;
//   };
//   const handleSelectedItemsChange = (newSelectedItems, name) => {
//     setSelectedItemIds(newSelectedItems);

//    let InputData =[]
//    const validHeadings = [
//     "Equipment",
//     "Component",
//     "Operation",
//     "Operator",
//     "Shift",
//     "Tool",
//     "Periodicity",
//     "DowntimeReason",
//     "Refrence"
//   ];

//   // Check if the provided heading name is valid
//   if (!validHeadings.includes(name)) {
//     // console.error("Invalid heading:", name);
//     return; // Exit the function if heading is invalid
//   }

//     inputData[name] = newSelectedItems; // For headings with multiple selections

//     // Now you have access to both newSelectedItems and heading
//     // consol("Selected Items:", newSelectedItems);
//     // consol("Heading:", name,"inputData :",inputData);
//     setsenddata(inputData)
//   };

//   // const handleSelectedItemsChange = (newSelectedItems) => {
//   //   setSelectedItemIds(newSelectedItems);

//   //   // consol(newSelectedItems, "selected");
//   // };
//   const handleBarClick = (selectedItem) => {
//     const city = data[selectedItem.row + 1][0];
//     setSelectedCity(city);
//     setSelectedParts([{ value: 1, label: "DownTime 1" }]);
//   };

//   const handleDropdownChange = (selectedOptions) => {
//     setSelectedParts(selectedOptions);
//   };
//   const goBackToBarChart = () => {
//     setSelectedCity(null);
//   };

//   const partOptions = data[0].slice(1).map((_, index) => ({
//     value: index + 1,
//     label: `DownTime ${index + 1}`,
//   }));

//   const selectedCityData = data.find((item) => item[0] === selectedCity);
//   const pieChartData = selectedCityData
//     ? selectedParts.map((DownTime) => [
//         `DownTime ${DownTime.value}`,
//         selectedCityData[DownTime.value],
//       ])
//     : [];
//   const handleDownloadExcel = (e) => {
//     generateExcelFile(e);
//   };

//   let handleOnSubmit = () => {
//    let inputData = { ...senddata };

//     // const errors = validate.validate(inputData, schema);

//     // setErrors(errors || {});
//     // if (errors) return;
//     setQuerySuccess(true);
//     inputData.Operation=[ 'all']
//     inputData.From ="2023-08-01"
//     inputData.Till = "2023-08-24"
//     inputData.Tool = ["null"]

//     axios
//       .post("http://192.168.1.39:4000/LeafNetServer/api/GetDownTimeAnalysis", inputData)
//       .then((result) => {
//         // consol(result,"result received");
//         if (result.data !== null && result.data !== undefined) {
//           if (result.data.length > 0) {
//             // consol(result.data,"downtime result received")
//           } else {
//             swal("Data Not Available");
//             setQuerySuccess(false);
//           }
//         } else {
//           setQuerySuccess(false);
//         }
//       })
//       .catch((err) => {
//           toast.success(`${err}`, {
         
//         // consol(err);
//         setQuerySuccess(false);
//       });
//   };

//   const organiseParetoData = (clone) => {
//     let data = [...clone];
//     let downTimeParetoArray = [];
//     let schema = [
//       "State",
//       "Time( in minutes)",
//       { role: "style" },
//       "Percentage(%)",
//     ];
//     downTimeParetoArray.push(schema);
//     for (let i = 0; i < data.length; i++) {
//       let StateArray = [];
//       if (data[i].Reason !== "OK") {
//         StateArray.push(data[i].State);
//         StateArray.push(parseFloat((data[i].Duration / 60).toFixed(1)));
//         StateArray.push(data[i].Color);
//         StateArray.push(parseFloat(data[i].Percentage.toFixed(1)));
//         downTimeParetoArray.push(StateArray);
//       }
//     }
//     let accumPer = 0;
//     return downTimeParetoArray;
//   };

//   return (
//     <div style={{ marginTop: 30 }} className="justify-content-center">
//       {querySuccess ? <Loading /> : ""}
//       <div className="text-center">
//         <h4 style={{ padding: "20px", color: "grey" }}>
//           Downtime Detailed Analysis
//         </h4>
//       </div>

//       <div className="row">
//       <div className="col-sm-2">
//           {/* <div className="form-group text-center">
//             <label className="small">From</label>
//             <input
//              style={{borderColor:"#eaeaea"}}
//               className={"form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"}
//               placeholder="dd/mm/yyyy"
//               type="date"
//               onChange={handleOnChange}
//               name="From"
//             />
//             {errors.From && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.From}
//               </p>
//             )}
//           </div>
//         </div>
//         <div className="col-sm-2">
//           <div className="form-group text-center">
//             <label className="small">Till</label>
//             <input
//              style={{borderColor:"#eaeaea"}}
//             className={"form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"}
//               placeholder="dd/mm/yyyy"
//               type="date"
//               onChange={handleOnChange}
//               name="Till"
//             />
//             {errors.Till && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.Till}
//               </p>
//             )}
//           </div> */}
//         </div>
// {
//   DropDownData.map((data,index)=>{
//     return(<>
//     <div className="col-sm-2">
//     <div className="form-group text-center">
//       <label className="small">{data.heading}</label>
//       {componentdata.length > 0 && (

//          <ReusableCheckboxDropdown
//            initialItems={data.initialItems}
//            label={data.label}
//            heading={data.heading}
//            selectedItemIds={selectedItemIds}
//            onSelectedItemsChange={newSelectedItems =>
//              handleSelectedItemsChange(newSelectedItems,data.name)
//            }
//             enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
//             multiSelect={data.multiSelect} // Pass

//          />

//       )}
//       {errors.Equipment && (
//         <p className="text-danger " style={{ fontSize: 11 }}>
//           {errors.Equipment}
//         </p>
//       )}
//     </div>
//   </div>
//   </>)})

// }
//         <div className="col-sm-2">
//           <div className="form-group text-center">
//             <label className="small">Equipment</label>
//             {componentdata.length > 0 && (

//                <ReusableCheckboxDropdown
//                  initialItems={equpdata}
//                  label="tablecolumns"
//                  heading="Equipment"
//                  selectedItemIds={selectedItemIds}
//                  onSelectedItemsChange={newSelectedItems =>
//                    handleSelectedItemsChange(newSelectedItems,"Equipment")
//                  }
//                   enableLocalStorage={false} // Pass the enableLocalStorage prop
//                   multiSelect={true}

//                />

//             )}
//             {errors.Equipment && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.Equipment}
//               </p>
//             )}
//           </div>
//         </div>
//         <div className="col-sm-2">
//           <div className="form-group text-center">
//             <label className="small">Operation</label>
//             {componentdata.length > 0 && (
//              <ReusableCheckboxDropdown
//              initialItems={operationdata}
//              label="tablecolumns"
//              heading={"Operation"}
//              selectedItemIds={selectedItemIds}
//              onSelectedItemsChange={newSelectedItems =>
//                handleSelectedItemsChange(newSelectedItems,"Operation")
//              }
//              enableLocalStorage={false}
//              multiSelect={true}

//            />

//             )}
//             {errors.Operation && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.Operation}
//               </p>
//             )}
//           </div>
//         </div>

//         <div className="col-sm-2">
//           <div className="form-group text-center">
//             <label className="small">Component</label>
//             {componentdata.length > 0 && (
//               <ReusableCheckboxDropdown
//                 initialItems={componentdata} // Pass the dynamic data
//                 label="tablecolumns"
//                 heading={"Component"}
//                 selectedItemIds={selectedItemIds}
//                 onSelectedItemsChange={newSelectedItems =>
//                handleSelectedItemsChange(newSelectedItems,"Component")
//              }
//              enableLocalStorage={false}
//              multiSelect={true}

//               />
//             )}
//             {errors.Component && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.Component}
//               </p>
//             )}
//           </div>
//         </div>
//         <div className="col-sm-2">
//   <div className="form-group text-center">
//     <label className="small">Operator</label>
//     {operatordata.length > 0 && (
//       <div > {/* Apply w-100 className here */}
//         <ReusableCheckboxDropdown
//           initialItems={operatordata}
//           label="tablecolumns"
//           heading={"Operator"}
//           selectedItemIds={selectedItemIds}
//           onSelectedItemsChange={newSelectedItems =>
//                handleSelectedItemsChange(newSelectedItems,"Operator")
//              }
//              enableLocalStorage={false}
//              multiSelect={true}

//         />
//       </div>
//     )}
//     {errors.Operator && (
//       <p className="text-danger " style={{ fontSize: 11 }}>
//         {errors.Operator}
//       </p>
//     )}
//   </div>
// </div>
//         <div className="col-sm-2">
//           <div className="form-group text-center">
//             <label className="small">Shift</label>

//               <ReusableCheckboxDropdown
//               searchbar={false}
//                 initialItems={shiftOption} // Pass the dynamic data
//                 label="tablecolumns"
//                 heading={"Shift"}
//                 selectedItemIds={selectedItemIds}
//                 onSelectedItemsChange={newSelectedItems =>
//                handleSelectedItemsChange(newSelectedItems,"Shift")
//              }
//              enableLocalStorage={false}
//              multiSelect={true}

//               />

//             {errors.Shift && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.Shift}
//               </p>
//             )}
//           </div>
//         </div>

//         {/* /////////////new dropdown menu/////////////// */}
//         <div className="col-sm-2">
//         <div className="form-group text-center">

//           <label className="small fw-bolder">DownTimeReason </label>
//           {dynamicData.length > 0 && (
//             <ReusableCheckboxDropdown
//             initialItems={dynamicData} // Pass the dynamic data
//             label="tablecolumns"
//             heading={"DownTime Reason"}
//             selectedItemIds={selectedItemIds}
//             onSelectedItemsChange={newSelectedItems =>
//                handleSelectedItemsChange(newSelectedItems,"DowntimeReason")
//              }
//              enableLocalStorage={false}
//              multiSelect={true}

//             />
//             )}
//             </div>
//         </div>

//         {/* /////////////old dropdown menu/////////////// */}

//         {/* <div className="col-sm-2">
//           <div className="form-group text-center">
//             <label className="small">DownTimeReason</label>
//             <MultiSelectCheckBox
//               name="DownTimeReason"
//               onChange={handleOnChange}
//               selectedValues={inputData["DownTimeReason"]}
//               options={Optionconverter(props.reasons, "Reason")}
//             />
//             {errors.DownTimeReason && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.DownTimeReason}
//               </p>
//             )}
//           </div>
//         </div> */}
//         <div className="col-sm-2">
//           <div className="form-group text-center">
//             <label className="small">Tool</label>
//             {componentdata.length > 0 && (
//               <ReusableCheckboxDropdown
//                 initialItems={tooldata} // Pass the dynamic data
//                 label="tablecolumns"
//                 heading={"Tool"}
//                 selectedItemIds={selectedItemIds}
//                 onSelectedItemsChange={newSelectedItems =>
//                handleSelectedItemsChange(newSelectedItems,"Tool")
//              }
//              enableLocalStorage={false}
//              multiSelect={true}

//               />
//             )}
//             {errors.Tool && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.Tool}
//               </p>
//             )}
//           </div>
//         </div>
//         <div className="col-sm-2">
//           <div className="form-group text-center">
//             <label className="small">Periodicity</label>

//               <ReusableCheckboxDropdown
//                 initialItems={periodOption} // Pass the dynamic data
//                 label="tablecolumns"
//                 heading={"Periodicity"}
//                 searchbar={false}

//                 selectedItemIds={selectedItemIds}
//                 onSelectedItemsChange={newSelectedItems =>
//                handleSelectedItemsChange(newSelectedItems,"Periodicity")
//              }
//              enableLocalStorage={false}
//              multiSelect={false}
//               />

//             {errors.Periodicity && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.Periodicity}
//               </p>
//             )}
//           </div>
//         </div>
//         <div className="col-sm-2">
//           <div className="form-group text-center">
//             <label className="small">WithRef</label>

//               <ReusableCheckboxDropdown
//               multiSelect={false}
//                 initialItems={Refrence} // Pass the dynamic data
//                 label="tablecolumns"
//                 heading={"WithRef"}
//                 selectedItemIds={selectedItemIds}
//                 searchbar={false}
//                 onSelectedItemsChange={newSelectedItems =>
//                handleSelectedItemsChange(newSelectedItems,"Refrence")
//              }
//              enableLocalStorage={false}
//               />

//             {errors.WithRef && (
//               <p className="text-danger " style={{ fontSize: 11 }}>
//                 {errors.WithRef}
//               </p>
//             )}
//           </div>
//         </div>

//         <div className="col-sm-1">
//           <button
//             className="btn btn-primary btn-sm mt-4"
//             onClick={() => handleOnSubmit()}
//           >
//             Apply
//           </button>
//         </div>
//       </div>
//       <div className="mt-5">
//         <Chart
//           chartType="ColumnChart"
//           width="100%"
//           height="400px"
//           data={data}
//           options={options}
//           chartEvents={[
//             {
//               eventName: "select",
//               callback: ({ chartWrapper }) => {
//                 const selectedItem = chartWrapper.getChart().getSelection()[0];
//                 if (selectedItem) {
//                   handleBarClick(selectedItem);
//                 }
//               },
//             },
//           ]}
//         />
//         {selectedCity ? (
//           <div className=" d-flex justify-content-center">
//             <div className="col-sm-6">
//               <Chart
//                 chartType="PieChart"
//                 width="100%"
//                 height="400px"
//                 data={[["DownTime", "Population"], ...pieChartData]}
//                 options={{
//                   title: `${selectedCity} Downtime by Equipment`,
//                   is3D: true,
//                 }}
//               />
//             </div>
//             <div className="col-sm-3 ">
//               <Select
//                 isMulti
//                 options={partOptions}
//                 onChange={handleDropdownChange}
//                 value={selectedParts}
//               />
//             </div>
//             <button
//               style={{ height: "35px" }}
//               className="btn btn-danger x-2"
//               onClick={goBackToBarChart}
//             >
//               X
//             </button>
//           </div>
//         ) : (
//           ""
//         )}
//       </div>
//     </div>
//   );
// }

// export default DowntimeDetailedAnalysis;
