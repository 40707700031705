import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { Modal, Button } from 'react-bootstrap';
import date from '../../utill/dateTime';
import MacStateForJobUpdate from '../../utill/macStateForJobUpdate';

const JobUpdateForChart = ({ modelState, handleClose, Jobs,handleSaveJobChanges,handleDeleteJob,updateEquipObj }) => {
    const [doubleClickState, setDoubleClickState] = useState(false);
    const [updateState, setUpdateState] = useState(false);
    const [newObj, setNewObj] = useState([]);
    const handleSetDoubleClikc = () => {
        setDoubleClickState(true)
    }

    const setDateTimeToData = (data1) => {
        let data={...data1}
        let from;
        if(data.FromDate){
            from = new Date(data.FromDate)
            let From=new Date(data.From);
            from.setHours(From.getHours(),From.getMinutes(),From.getSeconds())
            data['From'] = from
        }
        if(data.FromTime){
            from = new Date(data.From)
            let fromTime = data.FromTime.split(':')
            from.setHours(fromTime[0], fromTime[1])
            let From=new Date(data.From);
            from.setDate(From.getDate());
            from.setMonth(From.getMonth());
            from.setFullYear(From.getFullYear());
            data['From'] = from
        }
      
        let till;
        if(data.TillDate){
            till = new Date(data.TillDate)
            let Till=new Date(data.Till);
            till.setHours(Till.getHours(),Till.getMinutes(),Till.getSeconds());
            data['Till'] = till
        }
        if(data.TillTime){
            till = new Date(data.Till)
            let tillTime = data.TillTime.split(':')
            till.setHours(tillTime[0], tillTime[1])
            let Till=new Date(data.Till);
            till.setDate(Till.getDate());
            till.setMonth(Till.getMonth());
            till.setFullYear(Till.getFullYear());
            data['Till'] = till
        }
       

        delete data.FromDate;
        delete data.TillDate;
        delete data.TillTime;
        delete data.FromTime;
        return data
      }

    const handleOnChange=(obj,e,index)=>{
        setUpdateState(true);
        let object={...Jobs[index]};
        object['index']=index;
        object[e.currentTarget.name]=e.currentTarget.value;
        newObj[index]=setDateTimeToData(object);
        Jobs[index]=setDateTimeToData(object);
        setNewObj(newObj);
        setUpdateState(false);
    }

    const handleUpdateJob=(one,data)=>{
        let filterJob=data.filter(job=>job._id==one._id)
        filterJob.forEach((obj,idx)=>{
            filterJob[idx].Operators=[{Name:obj.OperatorName,OperatorID:obj.OperatorID}]
        })
        handleSaveJobChanges(filterJob)
    }

   
    return (
        <Modal
            show={modelState}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size='xl'

        >
            <Modal.Header closeButton>
                <Modal.Title>Jobs Update</Modal.Title>
            </Modal.Header>
            <Modal.Body>
          {<MacStateForJobUpdate  data={[updateEquipObj]}  /> }

                
                        <table className='table table-sm table-bordered table-hover small table-striped table-responsive p-0 m-0'>
                            <thead>
                                <tr className='table-primary' >
                                    <th>Equipment</th>
                                    <th>Component</th>
                                    <th>Operation</th>
                                    <th>Tool</th>
                                    <th>Customer</th>
                                    <th>Target Parts</th>
                                    
                                    <th>From Date</th>
                                    
                                    <th>From Time</th>
                                    <th>Till Date</th>
                                    <th>Till Time</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {Jobs.map((one,i) => {
                                    return <tr  >
                                        <th>{one.Equipment}</th>
                                        <th>{one.Component}</th>
                                        <th>{one.OperationID}</th>
                                        <th>{one.ToolID}</th>
                                        <th onDoubleClick={handleSetDoubleClikc} ><input type='text' name='CustomerName' onChange={(e)=>handleOnChange(one,e,i)} disabled={!doubleClickState} defaultValue={one.CustomerName} /></th>
                                        <th onDoubleClick={handleSetDoubleClikc}><input type='number' name='TargetQty' onChange={(e)=>handleOnChange(one,e,i)}  disabled={!doubleClickState} defaultValue={one.TargetQty} /></th>
                                        <th onDoubleClick={handleSetDoubleClikc}><input type='date' name='FromDate' onChange={(e)=>handleOnChange(one,e,i)}  disabled={!doubleClickState} defaultValue={date.getDateForHtml(one.From)} /></th>
                                        <th onDoubleClick={handleSetDoubleClikc}><input type='time' name='FromTime' onChange={(e)=>handleOnChange(one,e,i)}  disabled={!doubleClickState} defaultValue={date.getTimeForHtml(one.From)} /></th>
                                        <th onDoubleClick={handleSetDoubleClikc}><input type='date' name='TillDate' onChange={(e)=>handleOnChange(one,e,i)}  disabled={!doubleClickState} defaultValue={date.getDateForHtml(one.Till)} /></th>
                                        <th onDoubleClick={handleSetDoubleClikc}><input type='time' name='TillTime' onChange={(e)=>handleOnChange(one,e,i)}  disabled={!doubleClickState} defaultValue={date.getTimeForHtml(one.Till)} /></th>
                                        <th ><button onClick={()=>handleDeleteJob(one)} className='btn btn-primary btn-sm'><FontAwesomeIcon color='red' icon={faTrashAlt}/></button></th>
                                        <th ><button onClick={()=>handleUpdateJob(one,newObj)} className='btn btn-primary btn-sm'><FontAwesomeIcon  icon={faEdit}/></button></th>
                                    </tr>
                                })}

                            </tbody>
                        </table>
                    

            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={()=>handleSave(newObj)}>
                     Update
                </Button>

            </Modal.Footer> */}
        </Modal>
    );
}

export default JobUpdateForChart;