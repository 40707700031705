import React, { Component } from 'react';
import swal from 'sweetalert';
import endPoint from '../../../config';
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';


class DownTimeReasonGroup extends Component {
  state = { 
    downTimeReasonGroup:{},
    oldObj:{},
    errors:{},
    querySuccess:false,

 }
 schema={
  DownTimeReasonGroup:joi.string().required().label('Reason Type'),
 }
componentDidMount()
 {
   if(this.props.actionStatus==='add')
   {
     this.setState({downTimeReasonGroup:{}})
   }
   if(this.props.actionStatus==='update')
   {
     const downTimeReasonGroup={...this.props.downTimeReasonGroupData}
     this.setState({downTimeReasonGroup:this.props.downTimeReasonGroupData,oldObj:downTimeReasonGroup});
   }
 }

handleOnChange=(e)=>{
    const {downTimeReasonGroup,errors} ={...this.state};
    const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    downTimeReasonGroup[e.currentTarget.name]=e.currentTarget.value;
    this.setState({downTimeReasonGroup,errors:errors||{}});
    

}
handleSubmit=(data)=>{
  
 
  if(this.props.actionStatus==='add')
  {
    const errors = valid.validate(data,this.schema);
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.setState({querySuccess:true});
    axios.post(endPoint.api+'downtimegroup',data)
    .then((result)=>{
     if(result.status===200)
     {
      this.setState({querySuccess:false});
       this.props.history.push('/setting/downtimereasongroup/list')
       swal('Save Data Successfully');
     }
     else
     {
      this.setState({querySuccess:false});
      swal(`Data Not Saved`);
     }
    })
    .catch((err)=>{
      this.setState({querySuccess:false});
      swal(`${err.response.data.message}`);
    })

  }
  if(this.props.actionStatus==='update')
  {
    this.setState({querySuccess:true});
    axios.patch(endPoint.api+'downtimegroup/'+data._id,{old:this.state.oldObj,new:data})
    .then((result)=>{
      const {n,nModified,ok}=result.data;
     if(ok==1&&n==1&&nModified==1)
     {
      this.setState({querySuccess:false});
       this.props.history.push('/setting/downtimereasongroup/list')
       swal('Updated Data Successfully');
     }
     else
     {
      this.setState({querySuccess:false});
      swal(`${result.data}`);
     }
    })
    .catch((err)=>{
      this.setState({querySuccess:false});
      swal(`${err.response.data.message}`);
    })

  }

 


}


    render() { 
        return ( 
            <React.Fragment>
       {this.state.querySuccess?<Loading/>:''}
      

            <div className=" border">
              <div className="row justify-content-center">
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>DownTime Reason Group</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter DownTime Reason Group"
                      name="DownTimeReasonGroup"
                      onChange={this.handleOnChange}
                    defaultValue={this.state.downTimeReasonGroup.DownTimeReasonGroup}
                    />
                    {this.state.errors.DownTimeReasonGroup && <div className="alert small alert-danger">{this.state.errors['DownTimeReasonGroup']}</div>}
                  </div>
    
                
                </div>
    
               
               
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.downTimeReasonGroup);
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="text-center" style={{marginTop:10}}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push('/setting/downtimereasongroup/list')
                  }}
                >
                 Cancel
                </button>
              </div>
            </div>
          </React.Fragment>
         );
    }
}
 
export default DownTimeReasonGroup;