import React, { Component } from "react";
import Table from "../../../Common/table";
import axios from '../../../httpServices/httpService';
import api from '../../../config.json';
import swal from 'sweetalert';
import joi from 'joi';
import validation from '../../../utill/validation';
import dateTime from '../../../utill/dateTime.js';
import Loading from './../../../utill/LoadingComponent';

class QualityView extends Component {
  state = {
    qualityList:[{EquipmentGroup:'EquipmentGroup','EquipmentType':'EquipmentType',Component:'Component',OperationID:'OperationID',
    
    ToolID:'ToolID',
    querySuccess:false,
    From:'12-1-2',Till:'12-1-2'}],
    action:'',
    sortColumn:{path:'EquipmentGroup',order:'asc'},
    inputData:{From:new Date(Date.now()),Till:new Date(Date.now())},
    errors:{},
  };
  schema = {
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
   
  }



  columns = [
    { path: 'EquipmentGroup', label: 'EquipmentGroup'  },
    { path: 'EquipmentType', label: 'EquipmentType'  },
    { path: 'Date', label: 'Date'  },
    { path: 'Shift', label: 'Shift' ,
    
    
//     content: () => <React.Fragment>

// <div className='form-group'>
//   <select className='form-control form-control-sm custom-select' name='ReasonGroup' onChange={this.handleOnChange}>
//   <option>--select reason group--</option>
//   {this.state.downTimeList.map(one=>{
//    return one.ReasonGroup.map(one=>{
//       return <option>{one}</option>
//     })
//   })}
    
//   </select>
// </div>

//     </React.Fragment>  
    
},
    { path: 'Reason', label: 'Reason' ,
    
//     content: () => <React.Fragment>


// <div className='form-group'>
//   <select className='form-control form-control-sm custom-select' name='Reason' onChange={this.handleOnChange}>
//   <option>--select reason--</option>
//   {this.state.downTimeList.map(one=>{
//    return  one.Reason.map(reason=>{
//       return  <option>{reason}</option>
//     })
//   })}
//   </select>
// </div>
//     </React.Fragment>
    
},
    { path: 'Component', label: 'Component'  },
    { path: 'OperationId', label: 'OperationId'  },
    { path: 'PartsPerCycle', label: 'PartsPerCycle'  },
    { path: 'Total', label: 'Total'  },
    { path: 'NotOk', label: 'NotOk'  },
  
    { key: 'Update',content: (obj) => <React.Fragment><button className='btn btn-sm btn-primary'>Update</button></React.Fragment> },
    
]

  handleOnChange = e => {
    const {inputData}={...this.state};
    inputData[e.currentTarget.name]=e.currentTarget.value;
    this.setState({inputData});
  };
  handleClickOK=(inputData)=>{
    const errors = validation.validate(inputData, this.schema);
    // consol(errors)
    this.setState({errors:errors||{} });
    if (errors) return;
    this.setState({querySuccess:true})
      axios.put(api.api2+'quality',inputData,{headers:{rights:this.props.dataRightsKey}})
      .then((result)=>{
        // consol(result.data);
       this.setState({qualityList:result.data,querySuccess:false});
      })
      .catch((err)=>{
        this.setState({querySuccess:false})
   
        swal(`${err.response.data.message}`)
      })
  }
  render() {
    const{errors,inputData,sortColumn,qualityList}=this.state
    return (
      <React.Fragment>
       {this.state.querySuccess ? <Loading /> : ""}
      <div className="row">
      <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
           <input className='form-control form-control-sm' onChange={this.handleOnChange} defaultValue={dateTime.getDateFormatForInputTypeHtml(new Date(Date.now()))} type='date' name='From'/>
           {errors.From && <p className='text-danger '  style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
        </div>
      <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={this.handleOnChange} defaultValue={dateTime.getDateFormatForInputTypeHtml(new Date(Date.now()))} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
        </div>
        <div className="col-sm-1">
          <div className="form-group text-center">
          <button className='btn btn-md btn-primary mt-4' onClick={()=>this.handleClickOK(inputData)}>OK</button>
          </div>
        </div>
    
       
     
      </div>
      <div>
      {qualityList.length < 0 ? "Data Not Available" : <Table columns={this.columns} sortColumn={sortColumn} data={qualityList} />}
      </div>
      </React.Fragment>
    );
  }
}

export default QualityView;
