import React, { useState,useEffect } from 'react';
import Table from '../Common/table';
import QualityReportTable from '../utill/qualityReportTable';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import Chart from 'react-google-charts';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import { generateExcelFile, generateExcelFileFull } from '../utill/download';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from '../Common/DateSelection';

const QualityReport = ({ user,equips,equipGroups,fromDateLimit,storeDataInLocalStorage }) => {
  const [qualityData, setQualityData] = useState([])
  const [inputData, setInputData] = useState({})
  const [errors, setErrors] = useState({})
  const [querySuccess, setQuerySuccess] = useState(false);
  const [shiftSetting, setShiftSettings] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
    const [selectedEquipOption, setSelectedEquipOption] = useState([]);
    const [equip, SetEquips] = useState([])
    const[compList,setCompList]=useState([])


    // useEffect(()=>{
    //   axios.get(api.api1 + "component")
    //   .then((result)=>{
    //     setCompList(result.data)
    //   })
    //   .catch((err)=>{
    //     // consol(err);
    //   })
  
    // },[])


    useEffect(() => {
      const fetchData = async () => {
        try {
          // const result = await axios.get(api.api1 + 'component');
   let result = await storeDataInLocalStorage('component')
  
   setCompList(result);
        } catch (error) {
          // console.error("Error fetching data:", error);
        }
      };
    
      fetchData();
    }, []);

  const sortColumn = { path: 'Component', order: 'asc' }
  const schema = {
    From: joi.date().required().label('From Datetime'),
    Till: joi.date().required().label('Till Datetime'),
    Equips: joi.array(),
    UserId: joi.string(),
    //ViewProductionBy: joi.string().required().label('View By')
  }

  const setSelection=(key,option,equips)=>{
    if(key=='EquipmentGroup'){
        SetEquips(equips?equips:[]);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if(key=='Equipment'){
       
        setSelectedEquipOption(option)
    }
}

const getEquipsForReport=()=>{
    let equipArr=[]
    equips.forEach((one)=>{
        selectedEquipOption.forEach((option)=>{
            if(one._id==option.value){
                equipArr.push(one);
            }
        })
    })
    return equipArr;
}

  const handleOnChange = async (e) => {
    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
      let res = await axios.put(api.api + 'GetShiftSettingForDateShift', { DateTime: inputData.DateTime })
      setShiftSettings(res.data)
    } else {
      inputData[e.currentTarget.name] = e.currentTarget.value;
    }

    setInputData(inputData);
    setErrors({})
  }
  const handleOnSubmit = (inputData) => {

    // consol(inputData)
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    inputData.UserId = user._id
  if(new Date(inputData.From) <= new Date(inputData.Till)){
      
    }
    else{
      setQuerySuccess(false);
     
      return  toast.success('From date should be Less Than Till date', {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#babbbb', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
 
    inputData.Equips=getEquipsForReport()
    axios.post(api.api + 'GetJobQualityFactor', inputData).then((result) => {
      // consol(result)
      if (result.data !== null && result.data !== undefined) {
        if (result.data.length > 0) {
         
          setQualityData(result.data)
          let chartData=getChartData(result.data);
          setChartData(chartData)
          setQuerySuccess(false)
        }
        else {
          swal('Data Not Available');
          setQuerySuccess(false)
        }
      }
      else {
        setQuerySuccess(false)
      }

    })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err)
        setQuerySuccess(false)
      })

  }

  const handleDownload = (e, date) => {
    generateExcelFile(e, date)
  }

  const getChartData = (clone) => {
    let data=_.cloneDeep(clone);
    let bufferArry = []
    data.forEach(element => {
      if(element.RejectionReason){
        bufferArry = [...element.RejectionReason, ...bufferArry];
      }
    });

    for (let i = 0; i < bufferArry.length; i++) {
      for (let j = i + 1; j < bufferArry.length; j++) {
        if (bufferArry[i].Reason === bufferArry[j].Reason) {
          bufferArry[i].Count += bufferArry[j].Count;
          bufferArry.splice(j, 1);

          // i--;
          j--;
          // i=j-1
        }
      }
    }

    let schema = [['Reason', 'Rejection Count']]
  
    bufferArry.forEach(one => {
      let arr = []
      arr.push(one.Reason);
      arr.push(one.Count)
      schema.push(arr);
    })
    return schema;
  }


  const renderChart = (data) => {
    if(data.length==1){
      return <p>No Chart Data Available</p>
    }
    return <Chart
      width={'100%'}
      height={'500px'}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={data}
      options={{
        // Material design options
        chart: {
          title: 'Availability Trend',
          subtitle: 'Trend Report for ',
        },

        hAxis: {
          slantedText: true,
          slantedTextAngle: 90,
          textStyle: { fontSize: 13 },

        },
      }}
      // For tests
      rootProps={{ 'data-testid': '2' }}
    />
  }
  const SelectedDates =(dates)=>{
    setInputData(dates)
  }
  return (
    <React.Fragment>
      {querySuccess ? <Loading /> : ''}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Job Quality Report
        </h4>
      </div>
      <ToastContainer/>
      <div className="row">
      {equipGroups && equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}
      <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />

        
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>From</label>
            <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
            {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}
          </div>
        </div> */}
        {/* <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className='small'>Shift</label>
                  <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
                  <option>--select shift--</option>
                  {shiftSetting.map(one=>{
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
                  </select>
                  {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
 
                </div>
              </div> */}
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Till</label>
            <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
            {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}
          </div>
        </div> */}


        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>
        {qualityData.length > 0 ? <div className="col-sm-2">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('JobQualityReport', inputData.DateTime)}>Download Excel</button>
        </div> : ''}



      </div>
      {chartData.length > 0 ? <div >
        {renderChart(chartData)}
      </div> : ''}
      <div>
        {qualityData.length > 0 ? <QualityReportTable id='JobQualityReport' qualityData={qualityData} compList={compList} /> : ''}
      </div>
    </React.Fragment>


  );
}

export default QualityReport;