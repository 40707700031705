import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import date from '../utill/dateTime';
import {generateExcelFileFull,generateExcelFile} from '../utill/download'
import HourlyTableProdction from '../Common/hourlyProductionTable';
import { MachineStateChart } from '../utill/machineStateChart';
import EquipmentSelection from '../Common/equipmentSelection';
import { ToastContainer, toast } from 'react-toastify';


const HistoryMachineState = ({user,equips,equipGroups,fromDateLimit}) => {
  const [actvityData, setActivityData] = useState([])
  const [inputData, setInputData] = useState({})
  const [errors, setErrors] = useState({})
  const [querySuccess, setQuerySuccess] = useState(false)
  const [shiftSetting, setShiftSetting] = useState([])
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([])
  const [jobView, setJobView] = useState({ JobView: false });
  const [filterBy, setFilterBy] = useState({  })
  
useEffect(() => {
 
    let preSelectedDate=getTodayDate();
   
    handleOnChange({currentTarget:{type:'date',name:'DateTime',value:preSelectedDate}})
  
  //   let firstShift = res.data[0]?.Shift
  //  handleOnChange({currentTarget:{type:'Shift',name:'Shift',value:firstShift}})
    
    // setInputData(preSelectedDate);
  }, []);


  const convertDateFormat = (dateString) => {
    const [month, day, year] = dateString?.split('-');
    return `${month}-${day}-${year}`;
  };
      const getTodayDate = () => {
      const today = new Date();
      let month = String(today.getMonth() + 1).padStart(2, '0');
      let day = String(today.getDate()).padStart(2, '0');
      const year = today.getFullYear();

      return `${year}-${month}-${day}`;
    };
  const setSelection=(key,option,equips)=>{
    const obj={...filterBy}
    if(key=='EquipmentGroup'){
      let equipGroups=equips?equips:[];
      equipGroups.forEach((one)=>{
        obj[one.EquipmentGroup]=true;
      })
      setFilterBy(obj)
        SetEquips(equips?equips:[]);
        setSelectedEquipGroupOption(option)
        setSelectedEquipOption([])
    }
    if(key=='Equipment'){
       
        setSelectedEquipOption(option)
    }
}

const getEquipsForReport=()=>{
    let equipArr=[]
    equips.forEach((one)=>{
        selectedEquipOption.forEach((option)=>{
            if(one._id==option.value){
                equipArr.push(one);
            }
        })
    })
    return equipArr;
}
  const schema = {
    DateTime: joi.date().required().label('Datetime'),
   // Till: joi.date().required().label('Till Datetime'),
   Equips:joi.array(),
    UserId: joi.string(),
  Shift: joi.string().required().label('ShiftName')
  }


 
  const handleOnChange = async(e) => {
    if (e.currentTarget.type === 'date') {
      inputData[e.currentTarget.name] =convertDateFormat(e.currentTarget.value);
      setShiftSetting([])
      let res= await axios.put(api.api+'GetShiftSettingForDateShift',{DateTime:inputData.DateTime})
      setShiftSetting(res.data);
    }
    else {
      inputData[e.currentTarget.name] = e.currentTarget.value;
    }
  
    setInputData(inputData);
    setErrors({})
  }
  const handleOnSubmit = (inputData) => {
  
    // consol(inputData)
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    setErrors(errors || {});
    if (errors) return;
    setQuerySuccess(true)
    inputData.UserId=user._id
    inputData.Equips=getEquipsForReport()
    axios.post(api.api + 'GetMachineActivityForHistorical',inputData).then((result) => {
      // consol(result)
      if (result.data !== null && result.data !== undefined) {
        if (result.data.length > 0) {
          setActivityData(result.data)
          setQuerySuccess(false)
        }
        else {
          swal('Data Not Available');
          setQuerySuccess(false)
        }
      }
      else {
        setQuerySuccess(false)
      }

    })
      .catch((err) => {
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        // consol(err)
        setQuerySuccess(false)
      })

  }

 

  return (
    <React.Fragment>
    <ToastContainer/>
      {querySuccess ? <Loading /> : ''}
      <div className='text-center' >
        <p >Machine State</p>
      </div>
      <div className="row">
     {equipGroups.length > 0 && <EquipmentSelection equipGroups={equipGroups} equip={equip} selectedEquipGroupOption={selectedEquipGroupOption} selectedEquipOption={selectedEquipOption} setSelection={setSelection}/>}

        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Date</label>
            <input className={'form-control form-control-sm'} value={inputData.DateTime} min={fromDateLimit} placeholder='dd/mm/yyyy' type='date' onChange={handleOnChange} name='DateTime' />
            {errors.DateTime && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.DateTime}</p>}
          </div>
        </div>
       
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm'  onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              {shiftSetting.map(one=>{
                return <option value={one.Shift}>{one.Shift}({date.getTime(one.ShiftStart)}-{date.getTime(one.ShiftEnd)})</option>
              })}
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>please select shift</p>}
          </div>
        </div>
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>View Production By</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='ViewProductionBy'>
              <option>--view production by</option>
              <option>Component</option>
              <option>Operator</option>
              <option>Machine</option>
            </select>
            {errors.ViewProductionBy && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.ViewProductionBy}</p>}
          </div>
        </div> */}
        <div className="col-sm-1">
          <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
        </div>
      </div>
      <div>
      {actvityData.length>0?!querySuccess?<MachineStateChart jobView={jobView} id='reporting' filterBy={filterBy}  data={actvityData} reportType='History'  />:<p>Loading...</p>:''}

        {/* <Table columns={getProductionReportSchema} onSort={handleSort} sortColumn={sortColumnDate} data={productionData} /> */}
      </div>
    </React.Fragment>
  );
}
export default HistoryMachineState;