import React, { useState, useEffect } from 'react';
import Table from '../Common/table';
import axios from '../httpServices/httpService';
import dateTime from '../utill/dateTime';
import api from '../config'
import swal from 'sweetalert';
import auth from '../httpServices/authService';
import validation from '../utill/validation';
import _ from 'lodash';
import joi from 'joi'
import Loading from './../utill/LoadingComponent';
import { generateExcelFile } from '../utill/download';
import MultiSelect from '../Common/multiselect';
import { ToastContainer, toast } from 'react-toastify';

const JobPerformance = ({ user,fromDateLimit ,storeDataInLocalStorage}) => {
    const [machineData, setMachineData] = useState([])
    const [inputData, setInputData] = useState({})
    const [errors, setErrors] = useState({})
    const [querySuccess, setQuerySuccess] = useState(false);
    const [compList, setCompList] = useState([]);
    const [operationList, setOperationList] = useState([]);
    const [operationMasterList, setOperationMasterList] = useState([])

    // useEffect( () => {
    //     (async () => {
    //         const res = await axios.get(
    //           api.api1+'component'
    //         );
    //         setCompList(res.data);
    //     })();
    //     (async () => {
    //         const res = await axios.get(
    //           api.api1+'operation'
    //         );
    //         setOperationMasterList(res.data);
    //     })();
    // },[])

    useEffect(() => {
        const fetchData = async () => {
          try {
            let compRes = await storeDataInLocalStorage('component')

            // const compRes = await axios.get(api.api1 + 'component');
            setCompList(compRes);
          } catch (error) {
            // console.error("Error fetching component data:", error);
          }
      
          try {
            let operationRes = await storeDataInLocalStorage('operation')

            // const operationRes = await axios.get(api.api1 + 'operation');
            setOperationMasterList(operationRes);
          } catch (error) {
            // console.error("Error fetching operation data:", error);
          }
        };
      
        fetchData();
      }, []);
      
    const schema = {
        From: joi.date().required().label('From Datetime'),
        Till: joi.date().required().label('Till Datetime'),
        Component: joi.string().required().label('Component'),
        OperationID: joi.string().required().label('Operation'),
        ComponentRef:joi.string(),
        OperationRef:joi.string()
        //ViewBy: joi.string().required().label('View By')
    }

    const sortColumn = { path: 'Date', order: 'asc' }
    const columns = [
        { path: 'Date', label: 'Date',content:(obj)=><label>{dateTime.getDateFormat(obj.Date)}</label> },
        { path: 'Shift', label: 'Shift' },
        { path: 'Equipment', label: 'Equipment' },
        { path: 'Tool', label: 'Tool',content:(obj)=><label>{obj.Tool=='null'||obj.Tool==null?'-':obj.Tool}</label>  },
        { path: 'PlannedProdTime', label: 'PlannedProdTime(In Min)' },
        { path: 'AvailableTime', label: 'Available Time' },
        { path: 'IdealCycle', label: 'Ideal Cycles' },
        { path: 'ActualCycles', label: 'Actual Cycles' },
        { path: 'Availability', label: 'Availability(%)', },
        { path: 'Performance', label: 'Performance(%)'},
        { path: 'Effectiveness', label: 'Effectiveness(%)'},
    ]



    const handleOnChange = (e) => {

        if (e.currentTarget.type === 'date') {
            // consol(new Date(e.currentTarget.value))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(e.currentTarget.value);
           
            setInputData(inputData);
        }
        else if (e.currentTarget.type === 'time') {
            let Time = e.currentTarget.value.split(':');
            // consol(new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1])))
            // consol(e.currentTarget.value)
            inputData[e.currentTarget.name] = new Date(new Date(inputData[e.currentTarget.name]).setHours(Time[0], Time[1]));
            setInputData(inputData);
        }
        else if (e.currentTarget.name == 'Component') {
          //  let com = JSON.parse(e.currentTarget.value);
          inputData[e.currentTarget.name] = e.currentTarget.value;
          let comp=compList.filter(one=>one.ComponentId==e.currentTarget.value);
          if(comp.length>0){
              setOperationList(comp[0].Operation);
              setInputData(inputData);
          }else{
              setOperationList([]);
          setInputData(inputData);
          }
        } else {
            inputData[e.currentTarget.name] = e.currentTarget.value
            setInputData(inputData);
        }
        setErrors({})

    }
    const handleOnSubmit = (inputData1) => {
        const errors = validation.validate(inputData1, schema);
        // consol(errors)
        setErrors(errors || {});
        if (errors) return;
        setQuerySuccess(true)
        // const user = auth.getCurrentUser();
       
        inputData1.From.setHours(0,0,0);
        inputData1.Till.setHours(23,59,59);
        inputData1.ComponentRef=compList.filter(one=>one.ComponentId==inputData1.Component)[0]._id;
        inputData1.OperationRef=operationMasterList.filter(one=>one.OperationName==inputData1.OperationID)[0]._id;
        axios.post(api.api + 'JobPerformance', inputData1).then((result) => {
            // consol(result.data)
            if (result.data.length > 0) {
                setMachineData(result.data)
                setQuerySuccess(false)
            }
            else {
                swal('Data Not Available');
                setQuerySuccess(false)
            }
        })
            .catch((err) => {
                  toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                setQuerySuccess(false)
                // consol(err)
            })
    }

    const handleDownloadExcel=(e)=>{
     generateExcelFile(e,new Date())
    }



    return (
        <React.Fragment>
        <ToastContainer/>
            {querySuccess ? <Loading /> : ''}
            <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Job Performance
                </h4>
            </div>
            <div className="row">
                
                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>From</label>
                        <input className='form-control form-control-sm' min={fromDateLimit} type='date' onChange={handleOnChange} name='From' />
                        {errors.From && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.From}</p>}

                    </div>
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='From' />
          </div> */}
                </div>
                <div className="col-sm-2">
                    <div className="form-group text-center">
                        <label className='small'>Till</label>
                        <input className='form-control form-control-sm' type='date' onChange={handleOnChange} name='Till' />
                        {errors.Till && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Till}</p>}

                    </div>
                    {/* <div className="form-group ">
            <input className='form-control form-control-sm' type='time' onChange={handleOnChange} name='Till' />
          </div> */}
                </div>
                <div className="col-sm-3 mt-3">
                  <MultiSelect name='Component'  objLevel={1} masterName="ComponentId" all={compList} handleOnChange={handleOnChange} selected={inputData.Component} />
                  {errors.Component && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Component}</p>}
                    {/* <div className="form-group text-center">
                        <label className='small'>Component</label>
                        <select className='form-control form-control-sm' onChange={handleOnChange} name='Component'>
                            <option>--select component--</option>
                            {compList.map(one => {
                                return <option value={JSON.stringify(one)} >{one.ComponentId}</option>
                            })}
                        </select>
                        {errors.Component && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Component}</p>}

                    </div> */}
                </div>
                <div className="col-sm-3 mt-3">
                <MultiSelect name='OperationID'  objLevel={1} masterName="OperationID" all={operationList} handleOnChange={handleOnChange} selected={inputData.OperationID} />
                  {errors.OperationID && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.OperationID}</p>}
                    {/* <div className="form-group text-center">
                        <label className='small'>Operation</label>
                        <select className='form-control form-control-sm' onChange={handleOnChange} name='OperationID'>
                            <option>--select operation--</option>
                            {operationList.map(one => {
                                return <option>{one.OperationID}</option>
                            })}
                        </select>
                        {errors.OperationID && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.OperationID}</p>}

                    </div> */}
                </div>

                <div className="col-sm-1">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleOnSubmit(inputData)}>Apply</button>
                </div>
               {machineData.length>0? <div className="col-sm-2">
                    <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownloadExcel(inputData.Component+'_'+inputData.OperationID)}>Download Excel</button>
                </div>:''}

            </div>
           {machineData.length>0? <div>
                <Table id={inputData.Component+'_'+inputData.OperationID} columns={columns} sortColumn={sortColumn} data={machineData} />
            </div>:''}
        </React.Fragment>


    );
}

export default JobPerformance;