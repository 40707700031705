import React, { Component } from 'react';
import Table from '../../../Common/table';
//import '../QualityReasonGroup/node_modules/bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrash, faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import dateTime from '../../../utill/dateTime';
import  swal  from 'sweetalert';
import api from '../../../config';
import axios from '../../../httpServices/httpService';
import _ from 'lodash'
import { BoxLoading ,BlockLoading } from 'react-loadingg';
import  ReactDOM  from 'react-dom';
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';


class UserGroupTable extends Component {

    state = {
        userGroupList:[],
        sortColumn:{path:'UserGroup',order:'asc'}
    }

    handleSort = sortColumn => {
      // // consol(sortColumn);
      if(this.state.userGroupList.length>0)
      {
          const sorted = _.orderBy(this.state.userGroupList, [sortColumn.path], [sortColumn.order]);
          this.setState({ sortColumn,userGroupList:sorted });
      }
  }

    componentDidMount()
    {
      this.setState({querySuccess:true});
        axios.get(api.api+'usergroup')
        .then((result)=>{
         if(result.data.length>0)
         {
           this.setState({userGroupList:result.data,querySuccess:false});
         }
         else{
          this.setState({querySuccess:false})
        }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });

        })
        

    }
    onDelete=(data)=>{
       
            swal({
              title: "Are you sure?",
              text: "Once deleted, you will not be able to recover this",
              icon: "warning",
              buttons: true,
              dangerMode: true
            }).then(willDelete => {
              if (willDelete) {
                this.setState({querySuccess:true});
                axios
                  .delete(api.api + "usergroup/"+data._id,data)
                  .then(result => {
                    // // consol(result.data)
                    if (result.data.ok === 1 && result.data.deletedCount === 1) {
                        let filterData = this.state.userGroupList.filter(
                            obj => obj._id !== data._id
                          );
                          this.setState({ userGroupList: filterData ,querySuccess:false});
                    }
                  })
                  .catch(err => {
                    this.setState({querySuccess:false});
                    swal(`${err.response.data.message}`);
                  });
              } else {
                this.setState({querySuccess:false});
                // swal("Your imaginary file is safe!");
              }
            });
     
         


    }


    columns = [
        { path: 'UserGroup', label: 'User Group' },
      
        { key: 'createdAt', label: 'Created Date',content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.createdAt)}</label><br/></React.Fragment> },
        { key: 'updatedAt', label: 'Last Updated Date',content: (obj) => <React.Fragment><label>{dateTime.getStringOfDateTime(obj.updatedAt)}</label><br/></React.Fragment>  },
        { key: 'update', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights','UserMaster','update')} onClick={() => this.props.onUpdate(user)} className="btn btn-sm btn-primary"><FontAwesomeIcon icon={faEdit} /></button> },
        // { key: 'view', content: user => <button onClick={() => this.props.onView(user)} className="btn btn-sm btn-info"><FontAwesomeIcon icon={faEye} /></button> },
        { key: 'delete', content: user => <button disabled={!this.props.getRightsForAccess('StaticDataRights','UserMaster','write') || user.UserGroup=='default'} onClick={() => this.onDelete(user)} className="btn btn-sm btn-danger"><FontAwesomeIcon icon={faTrash} /></button> }

    ]


    render() {
       
          
        return (
            <React.Fragment>
 <ToastContainer/>
{this.state.querySuccess?<Loading/>:''}
                 <div className='text-center'>
                   <p>User Group Master</p>
                 </div>

        {this.props.getRightsForAccess('StaticDataRights', 'UserMaster', 'write') ? <a style={{ cursor: 'pointer' }} className='btn btn-sm btn-primary ml-2 common-anchor' onClick={() => { this.props.history.push('/setting/usergroup/add'); this.props.handleOnAdd('/setting/usergroup/add', 'add') }}> <FontAwesomeIcon size='1x' icon={faPlus} /> Add</a> : ''}
        {this.state.userGroupList.length < 0 ? "Data Not Available" : <Table columns={this.columns} onSort={this.handleSort} sortColumn={this.state.sortColumn} data={this.state.userGroupList} />}

      </React.Fragment>

        );
    }
}

export default UserGroupTable;
