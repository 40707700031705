import React, { useState, useEffect, useRef } from "react";
import Table from "../Common/table";
import axios from "../httpServices/httpService";
import api from "../config";
import swal from "sweetalert";
import auth from "../httpServices/authService";
import validation from "../utill/validation";
import _ from "lodash";
import joi from "joi";
import Loading from "./../utill/LoadingComponent";
import date from "../utill/dateTime";
import EquipmentSelection from "../Common/equipmentSelection";
import { ToastContainer, toast } from "react-toastify";
import DateSelection from "../Common/DateSelection";
import GroupedBarChart from "../Charts/GroupedBarchart";
import { Stackedbarchart } from "../Charts/StackedChart";
import { DynamicVerticalChart1 } from "../Charts/VerticalStackChart";
import PieChart from "../Charts/PieChart";
import ScatterChart from "../utill/scatterChart";
import PieChart1 from "../Charts/ChartJSPie";
import { generateExcelFile } from "../utill/download";

const JobCycleTime_DowntimeChart = ({
  handleDownloadTableToExcel,
  equips,
  equipGroups,
  fromDateLimit,
  Heading,
  reasons
}) => {
  const [productionData, setProductionData] = useState([]);
  const [inputData, setInputData] = useState({});
  const [selectedEquipGroupOption, setSelectedEquipGroupOption] = useState([]);
  const [selectedEquipOption, setSelectedEquipOption] = useState([]);
  const [equip, SetEquips] = useState([]);
  const [errors, setErrors] = useState({});
  const [index, setIndex] = useState(0);
  const [querySuccess, setQuerySuccess] = useState(false);
  const [sortColumn, setSortColumn] = useState({ path: "Date", order: "asc" });
  const [ShiftData, setShiftData] = useState('');

  const [enabletable, setenabletable] = useState(false);
  const [enabletable1, setenabletable1] = useState(false);
  const [enabletable2, setenabletable2] = useState(false);
  const [enabletable3, setenabletable3] = useState(false);
  const [enabletable4, setenabletable4] = useState(false);
  const [enabletable5, setenabletable5] = useState(false);
  const [scatterChart, setscatterChart] = useState([]);

  


  const handleDownload = (e, date) => {
    generateExcelFile(e, date)
}
 const chartRef = useRef()
  const schema = {
    From: joi.date().required().label("From Date"),
    UserId: joi.string(),
    Till: joi.date().required().label("Till Date"),
    Element: joi.array(),
    Shift:joi.string(),
  };
 const  column =[
  // { path: "ShiftStart", label: "Date",content:(obj)=><label>{moment(obj.ShiftStart).format('DD MMMM YYYY')}</label> },
  { path: "Equipment", label: "Equipment" },
 
  { path: "TotalTime", label: "Total Time (mins)" },
  { path: "JobTime", label: "Job Time  (mins)"},]


  const  column1 =[
    // { path: "ShiftStart", label: "Date",content:(obj)=><label>{moment(obj.ShiftStart).format('DD MMMM YYYY')}</label> },
    { path: "Equipment", label: "Equipment" },
    { path: "Component", label: "Component" },
    { path: "Operation", label: "Operation" },
    { path: "IdealCount", label: "IdealCount" },
    { path: "ActualCount", label: "ActualCount" },
  ]
const renderScatterChart = () => {
  let data = getPercentage(scatterChart);
  let arr = []
  let seriesObj = {}
  data.forEach((one, i) => {
    seriesObj[i] = { color: one.Color }
  })
  let schema = ['NumOfMachines'];
  data.forEach((one) => {
    one.Reason = one.Reason + '(Machines , Percentage)'
    schema.push(one.Reason);
    schema.push({ 'role': 'style', 'type': 'string' });
  })
  arr.push(schema);


  data.forEach(one => {
    let arr1 = []
    arr1.push(one.NumOfMachines);

    for (let i = 1; i < schema.length; i = i + 2) {
      if (schema[i] == one.Reason) {
        arr1.push(parseFloat(one.Percentage));//'point {size: 14; fill-color: green'
        arr1.push('point {size: 7; fill-color: ' + one.Color);
      } else {
        arr1.push(null);
        arr1.push(null)
      }
    }
    arr.push(arr1);
  })

  return arr.length > 1 ? <ScatterChart data={arr} colors={seriesObj}  titlev={'Downtime Percentage'}/> : ''
}
const getPercentage = (clone) => {
  let data = [...clone]
  let arr = [];
  let sum = 0;
  // if (this.props.reasonSearchText.length > 0) {
  //   data = data.filter(reason => { return reason.State.replace(/_/gi, '').toLowerCase().startsWith(this.props.reasonSearchText.toLowerCase()) })
  //   data.map(data1 => {
  //     sum += data1.Duration;
  //   });

  //   data.forEach((one, i) => {
  //     let obj = {}
  //     obj.Reason = one.State
  //     obj.Duration = one.Duration;
  //     obj.Percentage = (one.Duration * 100 / sum).toFixed(2);
  //     arr.push(obj)
  //   })
  //   arr.sort((a, b) => {
  //     return b.Duration - a.Duration;
  //     // if(a.Duration>b.Duration) return -1
  //     //if(a.Duration<b.Duration) return -1
  //   })
  // }
  // else {
  data.map(data1 => {
    sum += data1.Duration;
  });
  sum = sum / 60;
  data.forEach((one, i) => {
    let obj = {}
    obj.Reason = one.State;
    obj.Duration = Math.ceil(one.Duration / 60);
    obj.Cost =(one.Cost);
    obj.Percentage = ((obj.Duration * 100) / sum).toFixed(1);
    obj.NumOfMachines = one.Event.length;
    obj.Color = one.Color;
    obj.DowntimeGroup = one.Event[0].DowntimeGroup ? one.Event[0].DowntimeGroup : 'Default';
    arr.push(obj)
  })
  arr.sort(function (a, b) {
    if (a.Reason < b.Reason) {
      return -1;
    }
    else if (a.Reason > b.Reason) {
      return 1;
    }
    return 0;
  });
  // }
  console.log(arr,"vfdkbvdfk")
 
  return arr

}

  // useEffect(() => {
  //     Heading('Cycles/Runtime')
  // }, [])

  const getEquipsForReport = () => {
    let equipArr = [];
    equips.forEach((one) => {
      selectedEquipOption.forEach((option) => {
        if (one._id == option.value) {
          equipArr.push(one);
        }
      });
    });
    return equipArr;
  };
  const handleOnSubmit = async (inputData) => {
    productionData.splice(0, productionData.length);
    setIndex(0);
    // consol(inputData)
    const errors = validation.validate(inputData, schema);
    // consol(errors)
    // setErrors(errors || {});
    // if (errors) return;
    setQuerySuccess(true);
    const user = auth.getCurrentUser();
    inputData.UserId = user._id;

    if (new Date(inputData.From) <= new Date(inputData.Till)) {
    } else {
      setQuerySuccess(false);

      return toast.success("From date should be Less Than Till date", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: "#babbbb", // Set your desired background color
          color: "white", // Set your desired text color
        },
      });
    }
    let equipForReport = getEquipsForReport();
      inputData.Equipment = equipForReport;
      inputData.Shift =ShiftData
    let data = [...productionData];
    try {
      let result = await axios.post(
        api.api + "GetCustomSanAutoReport",
        inputData
      );
      const inputdata2 ={...inputData,}
      inputdata2.Downtime =[
        {
            "label": "planned",
            "value": "planned",
            "checked": true
        },
        {
            "label": "unplanned",
            "value": "unplanned",
            "checked": true
        }
    ]
      inputdata2.Equips =inputdata2.Equipment

      inputdata2.Shift =['Shift1']

      let result1 =  await axios.post(api.api + 'GetDowntimeAnalysisCumulative', inputdata2);
        if (result1.data !== null && result1.data !== undefined) {
          console.log(result1.data, "current data result 2");
          setscatterChart(result1.data)
        }
      if (result.data !== null && result.data !== undefined) {
        if (result.data.length > 0) {
          console.log(result.data, "current data");
          setProductionData(result.data);
        }
        // else {
        //   setProductionData([])
        //   swal('Data Not Available');
        //   setQuerySuccess(false)
        // }
      } else {
        setQuerySuccess(false);
      }
    } catch (err) {
      toast.success(`${err}`, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: "red", // Set your desired background color
          color: "white", // Set your desired text color
        },
      });
      // consol(err)
      setQuerySuccess(false);
    }
    setQuerySuccess(false);
  };

  const setSelection = (key, option, equips) => {
    if (key == "EquipmentGroup") {
      SetEquips(equips ? equips : []);
      setSelectedEquipGroupOption(option);
    }
    if (key == "Equipment") {
      setSelectedEquipOption(option);
    }
  };
  const SelectedDates = (dates) => {
    setInputData(dates);
  };


  // Example Usage
  const IdealActualdata = (data,Type) => {
    const ChartData = [["Machine", "Ideal Count",{role:"annotation"}, "Actual Count",{ role: "annotation" }]];
    const TableData =[];
    const OverTableData =[];
    const UnderTableData =[];

const UnderAchiver=[["Machine", "Ideal Count",{ role: "annotation" }, "Actual Count",{ role: "annotation" }]];
const OverAchiver =[["Machine", "Ideal Count",{ role: "annotation" }, "Actual Count",{ role: "annotation" }]];
    data.forEach((element) => {
      element[Type].forEach((job) => {
        ChartData.push([
          `${element.Equipment} / ${job.Component} - ${job.Operation}`,
          Number(job.IdealCount.toFixed(0)),
          Number(job.IdealCount.toFixed(0)),
          Number(job.ActualCount.toFixed(0)),
          Number(job.ActualCount.toFixed(0)),
        ]);
if(job.IdealCount >= job.ActualCount){

  UnderAchiver.push([
    `${element.Equipment} / ${job.Component} - ${job.Operation}`,
    Number(job.IdealCount.toFixed(0)),
    Number(job.IdealCount.toFixed(0)),
    Number(job.ActualCount.toFixed(0)),
    Number(job.ActualCount.toFixed(0)),
  ]);
  UnderTableData.push({
    Equipment: `${element.Equipment ?? '-'}`,
    Component: `${job.Component ?? "-"}`,
    Operation: job.Operation ?? '-',
    IdealCount: job.IdealCount.toFixed(0),
    ActualCount: job.ActualCount.toFixed(0),
   });
}
if(job.IdealCount < job.ActualCount){
  OverAchiver.push([
    `${element.Equipment} / ${job.Component} - ${job.Operation}`,
    Number(job.IdealCount.toFixed(0)),
    Number(job.IdealCount.toFixed(0)),
    Number(job.ActualCount.toFixed(0)),
    Number(job.ActualCount.toFixed(0)),
  ]);

  OverTableData.push({
    Equipment: `${element.Equipment ?? '-'}`,
    Component: `${job.Component ?? "-"}`,
    Operation: job.Operation ?? '-',
    IdealCount: job.IdealCount.toFixed(0),
    ActualCount: job.ActualCount.toFixed(0),
   });
}
        TableData.push({
         Equipment: `${element.Equipment ?? '-'}`,
         Component: `${job.Component ?? "-"}`,
         Operation: job.Operation ?? '-',
         IdealCount: job.IdealCount.toFixed(0),
         ActualCount: job.ActualCount.toFixed(0),
        });
      });
    });

    const  IdealTotal =TableData.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.IdealCount)),
      0,
    )
    const  ActualTotal =TableData.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.ActualCount)),
      0,
    )

    const  IdealOverTotal =OverTableData.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.IdealCount)),
      0,
    )
    const  ActualOverTotal =OverTableData.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.ActualCount)),
      0,
    )


        const  IdealUnderTotal =UnderTableData.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.IdealCount)),
      0,
    )
    const  ActualUnderTotal =UnderTableData.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.ActualCount)),
      0,
    )
    TableData.push({
      Equipment: `Total`,
      Component: ``,
      Operation: '',
      IdealCount: IdealTotal,
      ActualCount: ActualTotal,
     });
     OverTableData.push({
      Equipment: `Total`,
      Component: ``,
      Operation: '',
      IdealCount: IdealOverTotal,
      ActualCount: ActualOverTotal,
     });
     UnderTableData.push({
      Equipment: `Total`,
      Component: ``,
      Operation: '',
      IdealCount: IdealUnderTotal,
      ActualCount: ActualUnderTotal,
     });
    console.log(ChartData, "current chart data",IdealTotal, ActualTotal,data);

    const PiechartData =[['Achiver / Under Achiver','Number'],['Under Achiver',UnderAchiver.length -1],
    ['Over Achiver',OverAchiver.length -1],]
    return {ChartData:ChartData, TableData:TableData,OverAchiver,UnderAchiver,OverTableData,UnderTableData,PiechartData};
  };
 
  const StackedbarchartData = (data) => {
    const test = [["Machine", "Total Duration" ,{ role: "annotation" },"Job Duration",{ role: "annotation" }]];
   const TableData =[]

    const OrganisedData = getChartData(data,'Jobs')
    const ChartData = [["Machine"]]; // Initial row for the stacked bar chart
    const maxJobs = Math.max(...OrganisedData.map((equipment) => equipment.Jobs.length));
    const chart = data.map((item) => {
      let TotalJobTime = 0;
      
      item.Jobs.forEach((element) => {
        TotalJobTime += Number(element.TotalTime) ?? 0; // Summing up each job's TotalTime
      });
      TableData.push({Equipment :item.Equipment,TotalTime: item.TotalTime ?? 0,JobTime: TotalJobTime})
      return [item.Equipment, item.TotalTime ?? 0,item.TotalTime ?? 0,  TotalJobTime ,TotalJobTime,];
    });
    const  JobTotal =TableData.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.JobTime ?? 0)),
      0,
    )
    const  TotalTimeTotal =TableData.reduce(
      (accumulator, currentValue) => accumulator + Number((currentValue.TotalTime ?? 0)),
      0,
    )
    TableData.push({Equipment :'Total',TotalTime: TotalTimeTotal,JobTime: JobTotal})
    
    // If you need `chart` or `test` to store the output
    
    chart.forEach((row) => test.push(row));
    
    console.log(TableData,"tweuyqyqweyweyi",test)
  return {test: test,TableData: TableData}
    // Add fake jobs to each equipment to match the maximum number of jobs
    const updatedData = OrganisedData.map((equipment) => {
      const jobCount = equipment.Jobs.length;
      if (jobCount < maxJobs) {
        const fakeJobs = Array.from({ length: maxJobs - jobCount }, (_, index) => ({
          Name: `${index + 1}`,
          TotalTime: 0,
          Downtime: [],
          Component: "Total Time", // Add a placeholder for the Component
        }));
        return { ...equipment, Jobs: [...equipment.Jobs, ...fakeJobs] };
      }
      return equipment;
    });
  
    // Extract unique Equipment/Component labels for the chart
    const labels = new Set();
    updatedData.forEach((element) => {
      element.Jobs.forEach((job) => {
        labels.add(`${element.Equipment}/${job.Component}/${job.Shift}`);
      });
    });
  
    // Convert the Set of labels to an array and add to the first row
    ChartData[0].push(...Array.from(labels));
  
    // Process each data entry
    updatedData.forEach((element) => {
      const TimeArray = Array.from(labels).map((label) => {
        const [equipment, component] = label.split("/");
        const job = element.Jobs.find((job) => 
          `${element.Equipment}/${job.Component}/${job.Shift}` === label
        );
        return job ? job.TotalTime : 0;
      });
  
      // Add Equipment name and TotalTime to the row
      TimeArray.unshift(element.Equipment);
      // TimeArray.push(element.TotalTime);
  
      // Push the row into ChartData
      ChartData.push(TimeArray);
    });
  
    // Add "Total Duration" as the last column header
    // ChartData[0].push("Total Duration");
    const newData =transformData(ChartData,updatedData)
    console.log(ChartData, "Stacked bar 34534chart", updatedData);
    return newData;
  };
  const transformData = (data,updatedData) => {
    const [header, ...rows] = data;
  
    const result = [header];
  console.log(rows, "Stacked baryhhrthrth", result);
  rows &&
  rows.forEach((row) => {
    if (row.length >= 2) {
      // Valid row, process normally
      const zerosRow = [
        row[0].split("/")[0],
        updatedData[0]?.TotalTime || 0, // Fallback if TotalTime is undefined
        ...Array(row.length - 2).fill(0),
      ];
      result.push(zerosRow); // Add the additional row with TotalTime followed by zeros
      result.push(row); // Add the existing row
    } else {
      // Handle invalid rows with a fallback or log them
      console.warn("Invalid row encountered: ", row);
    }
  });

  
    return result;
  };

  const DownTimeData = (data,Type) => {
    const header = [["Job"]];
  
 const OrganisedData = getChartData(data,Type)

 const uniqueDowntimeReasons = new Set();
 
const Color = new Set();
OrganisedData.forEach((item) => {
  item[Type].forEach((job) => {
    job.Downtime.forEach((downtime) => {
      if (downtime.DowntimeReason) {
        Color.add(downtime.Color ?? '#ff0000');
      }
    });
  });
});
const Colors = [...Color]
  console.log(Colors,"cdkfvjdfhbvj")
 // Loop through the data to extract all DowntimeReason values
 OrganisedData.forEach(item => {
   item[Type].forEach(job => {
     job.Downtime.forEach(downtime => {
       if (downtime.DowntimeReason) {
         uniqueDowntimeReasons.add(downtime.DowntimeReason + `(mins)`);
        //  uniqueDowntimeReasons.add({style:'annotation'});

       }
     });
   });
 });

 // Convert the Set to an array (if needed)
 const uniqueDowntimeReasonArray = [...uniqueDowntimeReasons];


  console.log("Unique Downtime Reasons:", uniqueDowntimeReasons);


const count =(uniqueDowntimeReasonArray.length)
  // Generate column headers for the chart
  uniqueDowntimeReasonArray.forEach((_, index) => {
   
      header[0].push(`${_}`); // Default label for other columns
  header[0].push({ role: "annotation" });
    
  });
    // Prepare data for the chart
    const JobDataForChart = [];

    OrganisedData.forEach((equipment) => {
      equipment[Type] &&
        equipment[Type].length > 0 &&
        equipment[Type].forEach((job) => {
          let row = []; // Represents one row of data in the chart
          row.push(equipment.Equipment + "/" +job.Component + "/" + job.Operation); // Add the job's Component/Operation as the first column
          job.Downtime.forEach((downtime) => {
            row.push(Number((downtime.Duration/(60)).toFixed(1))); // Add the downtime duration
            // row.push(((downtime.Duration/(60)).toFixed(1)+'mins')); // Add the downtime duration
          row.push(downtime.Duration == 0 ? null :Number((downtime.Duration/(60)).toFixed(1))); // Add the downtime count


          });
          JobDataForChart.push(row); // Push each job's data as a separate row
        });
    });
  
    const chartData = [...header, ...JobDataForChart];
    console.log("Final Chart Data:", chartData, OrganisedData);
  
    // Return the chart data
    return {ChartData:chartData, TableData :OrganisedData,Colors};
  };
  


const DownTimeData1 = (data,Type) => {
  const header = [["Job"]];

 const OrganisedData = getChartData(data,Type)


 const uniqueDowntimeReasons = new Set();
 
 const Color = new Set();
 OrganisedData.forEach((item) => {
   item[Type].forEach((job) => {
     job.Downtime.forEach((downtime) => {
       if (downtime.DowntimeReason) {
         Color.add(downtime.Color ?? '#ff0000');
       }
     });
   });
 });
 const Colors = [...Color]
 Colors.push("#af86f1");

 // Loop through the data to extract all DowntimeReason values
 OrganisedData.forEach(item => {
   item[Type].forEach(job => {
     job.Downtime.forEach(downtime => {
       if (downtime.DowntimeReason) {
         uniqueDowntimeReasons.add(downtime.DowntimeReason == null ? 'Down': downtime.DowntimeReason);

         
       }
     });
   });
 });

 // Convert the Set to an array (if needed)
 const uniqueDowntimeReasonArray = [...uniqueDowntimeReasons];


  console.log("Unique Downtime Reasons:", uniqueDowntimeReasons);


const count =(uniqueDowntimeReasonArray.length)
  // Generate column headers for the chart
  uniqueDowntimeReasonArray.forEach((_, index) => {
   
      header[0].push(`${_}`); // Default label for other columns
      header[0].push({ role: "annotation" });
    
  });
  header[0].push("Performance Loss");
  header[0].push({ role: "annotation" });

  

  const JobDataForChart = [];
  OrganisedData.forEach((equipment) => {
    if (equipment[Type] && equipment[Type].length > 0) {
      equipment[Type].forEach((job) => { // jobIndex is the index of the current job in the Jobs array
        let row = []; // Represents one row of data in the chart
        row.push(equipment.Equipment + "/" + job.Component + "/" + job.Operation); // Add the job's Component/Operation as the first column
  
        job.Downtime.forEach((downtime,idx) => {
          row.push(downtime.Count); // Add the downtime count
          row.push(downtime.Count == 0 ? null :downtime.Count); // Add the downtime count


          if (job.Downtime.length - 1 === idx) {
            row.push(job.PerformanceLoss); // Add PerformanceLoss to the last job
            row.push(job.PerformanceLoss); // Add PerformanceLoss to the last job

          }
        });
  
        // If it's the last job in the equipment, add PerformanceLoss
       
  
        JobDataForChart.push(row); // Push each job's data as a separate row
      });
    }
  });
  
  const chartData = [...header, ...JobDataForChart];
  console.log("Current Downtime Length:", count);
  console.log("Final Chart Data: for Performance loss", chartData,OrganisedData);

  // Return the chart data
  return {ChartData:chartData,TableData:OrganisedData,Colors};
};


const getChartData = (data,Type) => {
  const uniqueDowntimeReasons = new Set();

  // Loop through the data to extract all DowntimeReason values
  data.forEach(item => {
    item[Type].forEach(job => {
      job.Downtime.forEach(downtime => {
        if (downtime.DowntimeReason) {
          uniqueDowntimeReasons.add(downtime.DowntimeReason == null ? 'Down': downtime.DowntimeReason);
        }
      });
    });
  });
  // Convert the Set to an array (if needed)
  const uniqueDowntimeReasonArray = [...uniqueDowntimeReasons];

  // Loop through the data to ensure each Job has all unique downtime reasons and merge duplicates
  data.forEach(equipment => {
    equipment[Type].forEach(job => {
      // Create a map to store the merged DowntimeReason entries for this job
      
      const downtimeMap = new Map();
      // Iterate over the current Downtime entries of the job
      job.Downtime.forEach(downtime => {
        // Default to "Down" if DowntimeReason is null or undefined
        const DowntimeReason = downtime.DowntimeReason || "Down";
        const { Duration, Count } = downtime;
      
        // If the DowntimeReason is already in the map, merge the values (Duration and Count)
        if (downtimeMap.has(DowntimeReason)) {
          const existingDowntime = downtimeMap.get(DowntimeReason);
          existingDowntime.Duration += Duration;
          existingDowntime.Count += Count;
        } else {
          // If the DowntimeReason is not in the map, add it with the current values
          downtimeMap.set(DowntimeReason, { ...downtime, DowntimeReason });
        }
      });
      

      // Now ensure that the job's Downtime array has all unique downtime reasons
      uniqueDowntimeReasonArray.forEach(reason => {
        if (!downtimeMap.has(reason)) {
          downtimeMap.set(reason, {
            DocumentType: 'MachineState',
            DowntimeGroup: 'production/प्रोडक्शन', // Default DowntimeGroup
            DowntimeReason: reason,
            NotificationSent: null,
            NotificationSentAt: null,
            Duration: 0, // Default duration if no downtime
            Count: 0, // Default count if no downtime
          });
        }
      });

      // Convert the map back to an array and sort (if needed)
      job.Downtime = Array.from(downtimeMap.values()).sort((a, b) => {
        // Check if DowntimeReason exists before comparing
        if (a.DowntimeReason && b.DowntimeReason) {
          return a.DowntimeReason.localeCompare(b.DowntimeReason);
        }
        return 0; // If either DowntimeReason is missing, do not change order
      });
      
    });
  });

  // Log the updated data with equal downtime reasons
  console.log(uniqueDowntimeReasonArray, "Unique Downtime Reasons", data);
  return data
};
const total = []
const total1 = []

const handleOnChange =(e)=>{
 const Shift = e.currentTarget.value;
 console.log(Shift,"vdhsvksdkv")
 setShiftData(Shift)

}

const handelEnabletanle=(e,table)=>{
  const Table ={...enabletable}
  Table[table] = e
setenabletable(Table[table])
}


  return (
    <React.Fragment>
      {querySuccess ? (
        <Loading
          
        />
      ) : (
        ""
      )}
      {/* <div className="text-center heading-bg mb-4">
                <h4 className="text-white p-0 m-0" >
                    Cycles/Runtime
                </h4>
            </div> */}
      <ToastContainer />

      <div className="row">
        {equipGroups.length > 0 && (
          <EquipmentSelection
            equipGroups={equipGroups}
            equip={equip}
            selectedEquipGroupOption={selectedEquipGroupOption}
            selectedEquipOption={selectedEquipOption}
            setSelection={setSelection}
          />
        )}

        <DateSelection
          SelectedDates={SelectedDates}
          fromDateLimit={fromDateLimit}
        />
<div className="col-sm-2">
          <div className="form-group text-center">
            <label className='small'>Shift</label>
            <select className='form-control form-control-sm' onChange={handleOnChange} name='Shift'>
              <option>--select shift--</option>
              <option>All</option>
              <option>Shift1</option>
              <option>Shift2</option>
              <option>Shift3</option>
            </select>
            {errors.Shift && <p className='text-danger ' style={{ fontSize: 11 }}>{errors.Shift}</p>}
          </div>
        </div>
        <div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit(inputData)}
          >
            Apply
          </button>
        </div>
        {/* <div
          className={productionData.length > 0 ? "col-sm-1" : "col-sm-1 d-none"}
        >
          <a
            className="btn btn-sm btn-primary mt-4"
            onClick={() => handleDownloadTableToExcel("CycleRun")}
            style={{ float: "right", color: "white" }}
          >
            Download
          </a>
        </div> */}
      </div>
      <div>
        {/* { productionData.length > 0 && (
          <DynamicVerticalChart1 data={StackedbarchartData(productionData)} />
        )} */}
         { productionData.length > 0 && ( <div className="">
        <h1 className="mx-2">Machine - Job Booking</h1>

          <GroupedBarChart ref={chartRef} haxisTitle={'Time (mins)'}  chartType={'BarChart'}  height={1200} vaxisTitle={'Equipments'} data={StackedbarchartData(productionData).test} color={['#d33600','#1b9e77']} />
          </div>  )}
        {/* <h1>Dynamic Bar Chart</h1>
        <DynamicStackedBarChart
          series={convertDataToChartFormat(productionData).chartData}
          categories={convertDataToChartFormat(productionData).categories}
          chartHeight={400}
          colors={["#FF5733", "#33FF57", "#3357FF", "#FF33A1"]}
        /> */}

<div class="form-check form-switch mx-4 d-flex">
  <div className="">
  <input style={{height:'20px',width:'40px'}}  class="form-check-input  " checked={enabletable} type="checkbox" role="switch"  onClick={(e)=>setenabletable(e.currentTarget.checked)}/>
  <div className="p-0 mx-3" >Enable Table</div>

  </div>
</div>
{enabletable && <div >
  <button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('Machine_job_booking')}>Download Excel</button>
  <Table
  id="Machine_job_booking"
  onSort={()=>{}}
  columns={column}
  sortColumn={sortColumn}
  data={StackedbarchartData(productionData).TableData}
  />
   </div> }
      </div>
      {productionData.length > 0 && ( <div className="">
        <h1 className="mx-2"> Ideal V/S Actual Count</h1>
       
          <GroupedBarChart  chartType={'BarChart'}  height={1200} vaxisTitle={'Jobs'} data={IdealActualdata(productionData,'Jobs') && IdealActualdata(productionData,'Jobs').ChartData} />
          <div class="form-check form-switch mx-4">
  <input style={{height:'20px',width:'40px'}} class="form-check-input" checked={enabletable2} type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(e)=>setenabletable2(e.currentTarget.checked)}/>
  <label class="form-check-label mx-3" for="flexSwitchCheckDefault">Enable Table</label>
</div>

         { enabletable2 && <div >
<button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('Ideal_vs_actual')}>Download Excel</button>
          <Table
  id="Ideal_vs_actual"
  onSort={()=>{}}
  columns={column1}
  sortColumn={sortColumn}
  data={IdealActualdata(productionData,'Jobs') && IdealActualdata(productionData,'Jobs').TableData}
  /> </div> }
  <div className="" >

  <div className="">
  <h1 className="mx-2"> Over Achiver</h1>
       
       <GroupedBarChart haxisTitle={'Count'} chartType={'BarChart'}  height={800} vaxisTitle={'Jobs'} data={IdealActualdata(productionData,'Jobs') && IdealActualdata(productionData,'Jobs').OverAchiver} />
     
<div class="form-check form-switch mx-4">
  <input class="form-check-input" checked={enabletable4} type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(e)=>setenabletable4(e.currentTarget.checked)}/>
  <label class="form-check-label" for="flexSwitchCheckDefault">Enable Table</label>
</div>

       { enabletable4 && <div >
<button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('OverAchiver')}>Download Excel</button>
        <Table
  id="OverAchiver"
  onSort={()=>{}}
  columns={column1}
  sortColumn={sortColumn}
  data={IdealActualdata(productionData,'Jobs') && IdealActualdata(productionData,'Jobs').OverTableData}
  /> </div> }
  </div>
  <div className="">
  <h1 className="mx-2"> Under Achiver</h1>
       
       <GroupedBarChart haxisTitle={'time (min)'} chartType={'BarChart'}  height={1000} vaxisTitle={'Jobs'} data={IdealActualdata(productionData,'Jobs') && IdealActualdata(productionData,'Jobs').UnderAchiver} />
      
<div class="form-check form-switch mx-4">
  <input class="form-check-input" checked={enabletable3} type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(e)=>setenabletable3(e.currentTarget.checked)}/>
  <label class="form-check-label" for="flexSwitchCheckDefault">Enable Table</label>
</div>

      { enabletable3 && <div >
<button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('UnderAchiver')}>Download Excel</button>
        <Table
  id="UnderAchiver"
  onSort={()=>{}}
  columns={column1}
  sortColumn={sortColumn}
  data={IdealActualdata(productionData,'Jobs') && IdealActualdata(productionData,'Jobs').UnderTableData}
  /> </div> }
  </div>

  <div className=""
  >
    <div className="w-100">

     <h1 className="mx-2 text-start">Over Achiver V/S Under Achiver</h1>
<div className="d-flex justify-content-center">

      <PieChart data={IdealActualdata(productionData,'Jobs').PiechartData} />
</div>
    </div>

  </div>
  </div>
      </div> )}

      {productionData.length > 0 && ( <div className="">
        <h1 className="mx-2">Job Downtime Duration (mins)</h1>
       <Stackedbarchart Color={ DownTimeData(productionData,'Jobs') && DownTimeData(productionData,'Jobs').Colors} yaxisTitle={"Time (mins)"} data={ DownTimeData(productionData,'Jobs') && DownTimeData(productionData,'Jobs').ChartData} /> 
       <div >
        
       <div class="form-check form-switch mx-4">
  <input class="form-check-input" checked={enabletable1} type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(e)=>setenabletable1(e.currentTarget.checked)}/>
  <label class="form-check-label" for="flexSwitchCheckDefault">Enable Table</label>
</div>

    {   enabletable1 &&  <div>
<button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('Job_Downtime_Duration')}>Download Excel</button>

       <table id={'Job_Downtime_Duration'} className={"table table-striped small table-sm table-hover common table-bordered "}>
  <thead className='small '>
    <tr className='table-primary text-center common-anchor '>
      <th scope="col">Equipment</th>
      <th scope="col">Component</th>
      <th scope="col">Operation</th>
      
      {DownTimeData(productionData, 'Jobs') &&
        DownTimeData(productionData, 'Jobs')?.TableData
        ?.filter(table => table.Jobs.length > 0)[0].Jobs[0]?.Downtime.map(
          (one, index) => <th key={index} scope="col">{one.DowntimeReason}</th>
        )}
          <th scope="col">Total</th>

    </tr>
  </thead>
  <tbody>
    {DownTimeData(productionData, 'Jobs') &&
      DownTimeData(productionData, 'Jobs')?.TableData.map((equipment, index) => {
        const length = DownTimeData(productionData, 'Jobs').TableData.length -1
       return <React.Fragment key={index}>
          {/* {equipment.Jobs.length > 0&& <tr>
            <th scope="row" >{equipment.Equipment}</th>
          </tr>} */}
          {equipment.Jobs.map((job, jobIndex) => (
            <React.Fragment key={jobIndex}>
              <tr>
               <th  >{equipment.Equipment}</th>
                <td>{job.Component}</td>
                <td>{job.Operation}</td>
               {job.Downtime.map((duration, downtimeIndex) => {
  // Check if the index exists in total, initialize it to 0 if not
  if (!total[downtimeIndex]) {
    total[downtimeIndex] = 0;
  }
  // Add the current duration to the total
  total[downtimeIndex] += Number((duration.Duration / 60).toFixed(1));
  
  return <td key={downtimeIndex}>{(duration.Duration / 60).toFixed(1)}</td>;
})}

                <td > {job.Downtime.reduce(
  (accumulator, currentValue) => accumulator + Number((currentValue.Duration/60).toFixed(1)),
  0,
)}</td>
         
              </tr>
            </React.Fragment>
          ))}

           {(index ==length)  && <tr>
      <th  >Total</th>
      <td  ></td>
      <td ></td>

      { total && total.map(one =><td  >{one}</td>)}
 <td > {total && total.reduce(
  (accumulator, currentValue) => accumulator + Number((currentValue).toFixed(1)),
  0,
)}</td>
      </tr>}
        </React.Fragment>
})}

     
  </tbody>

</table>
</div>
}

  </div> 
      </div>)}
      {productionData.length > 0 && ( <div className="">
        <h1 className="mx-2">Job Downtime Count & Performance Loss</h1>
        <Stackedbarchart Color={ DownTimeData1(productionData,'Jobs').Colors} yaxisTitle={"Count"} data={ DownTimeData1(productionData,'Jobs') &&  DownTimeData1(productionData,'Jobs').ChartData} /> 
        <div class="form-check form-switch mx-4">
  <input class="form-check-input" checked={enabletable4} type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(e)=>setenabletable4(e.currentTarget.checked)}/>
  <label class="form-check-label" for="flexSwitchCheckDefault">Enable Table</label>
</div>

      { enabletable4 &&  <div >
<button className='btn btn-primary btn-sm mt-4' onClick={() => handleDownload('Job_Downtime_Count_&_Performance_Loss')}>Download Excel</button>
        
        
       <table id={'Job_Downtime_Count_&_Performance_Loss'} className={"table table-striped small table-sm table-hover common table-bordered "}>
  <thead className='small '>
    <tr className='table-primary text-center common-anchor '>
      <th scope="col">Equipment</th>
      <th scope="col">Component</th>
      <th scope="col">Operation</th>
      {DownTimeData1(productionData, 'Jobs') &&
  DownTimeData1(productionData, 'Jobs')?.TableData
  ?.filter(table => table.Jobs.length > 0)[0].Jobs[0]?.Downtime.map(
    (one, index) => (
      <React.Fragment key={index}>
      { one.DowntimeReason =='Down'? <th className="" scope="col">{one.DowntimeReason}</th>:  <th scope="col">{one.DowntimeReason}</th>}
        {DownTimeData1(productionData, 'Jobs')?.TableData
  ?.filter(table => table.Jobs.length > 0)[0].Jobs[0]?.Downtime.length - 1 === index && (<>
          <th scope="col">Total</th>
          <th scope="col">Performance Loss</th>
  </>
        )}

      </React.Fragment>
    )
  )}

    </tr>
  </thead>
  <tbody>
    {console.log(DownTimeData1(productionData, 'Jobs').TableData,"vdfhkbvfvfh")}
    {DownTimeData1(productionData, 'Jobs') &&
      DownTimeData1(productionData, 'Jobs').TableData.map((equipment, index) => (
        <React.Fragment key={index}>
          {/* {equipment.Jobs.length > 0&& <tr>
            <th scope="row" >{equipment.Equipment}</th>
          </tr>} */}
          {equipment.Jobs.map((job, jobIndex) => (
            <React.Fragment key={jobIndex}>
              <tr>
               <th  >{equipment.Equipment}</th>
                <td>{job.Component}</td>
                <td>{job.Operation}</td>

                 {job.Downtime.map((duration, downtimeIndex) => {
  // Check if the index exists in total, initialize it to 0 if not
  if (!total1[downtimeIndex]) {
    total1[downtimeIndex] = 0;
  }
  // Add the current duration to the total
  total1[downtimeIndex] += Number((duration.Count??0).toFixed(1));
  
  return <>
                
                {  <td key={downtimeIndex}>{(duration.Count).toFixed(1)}</td>}
                
                
                {(job.Downtime.length -1  ==downtimeIndex) &&<>
                        <td > {job.Downtime.reduce(
  (accumulator, currentValue) => accumulator + Number((currentValue.Count).toFixed(1)),
  0,
)}</td>
                <td>{job.PerformanceLoss}</td></>}
                </>;
})}
               
        
              </tr>
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
         { <tr>
      <th  >Total</th>
      <td  ></td>
      <td ></td>

      { total1 && total1.map(one =><td  >{one}</td>)}
 <td > {total1 && total1.reduce(
  (accumulator, currentValue) => accumulator + Number((currentValue).toFixed(1)),
  0,
)}</td>
      </tr>}
  </tbody>
</table>

  </div> }
  <div className="">

<h1 className="mx-2"> Impact On Equipment Due to Downtime </h1>

{scatterChart.length > 0 &&  renderScatterChart()}
</div>
      </div>)}
      {scatterChart.length > 0 && <div className=" d-flex justify-content-center">
      <div className="w-100">

      <h1 className="mx-2"> Downtime Reasons (mins)</h1>
      <div className="d-flex justify-content-center">

     <PieChart1 data={scatterChart}/>
      </div>
     <div class="form-check form-switch mx-4">
  <input class="form-check-input" checked={enabletable5} type="checkbox" role="switch" id="flexSwitchCheckDefault" onClick={(e)=>setenabletable5(e.currentTarget.checked)}/>
  <label class="form-check-label" for="flexSwitchCheckDefault">Enable Table</label>
</div>
      </div>

      </div>}
      {enabletable5  && scatterChart.length > 0 ? <div className="table-responsive-sm table-striped" >
            <p className='btn btn-primary btn-sm' onClick={() => handleDownload('DownTimeAnalysis')}>Download Excel</p>
            <table id="DownTimeAnalysis" className="table table-sm table-bordered ">
              <thead>
                <tr className="table-primary">
                  <th scope="col">Downtime Group</th>
                  <th scope="col">Downtime Reason</th>
                  <th scope="col">Downtime(In Mins)</th>
                  <th scope="col">Percentage(%)</th>
                  <th scope="col">Impact On Equipments</th>

                </tr>
              </thead>
              <tbody>
              {
  getPercentage(scatterChart)
    .sort((a, b) => b.Duration - a.Duration) // Sort in descending order based on Duration
    .map((one) => (
      <tr key={one.DowntimeGroup + one.Reason}> {/* Use a unique key */}
        <td>{one.DowntimeGroup}</td>
        <td>{one.Reason}</td>
        <td>{one.Duration}</td>
        <td>{(Number(one.Percentage)).toFixed(1)}</td> {/* Ensure consistent decimal formatting */}
        <td>{one.NumOfMachines}</td>
        {/* Uncomment this line if needed */}
        {/* <td>{one.Cost > 0 ? Number(one.Cost.toFixed(1)) : 0}</td> */}
      </tr>
    ))
}
<tr > {/* Use a unique key */}
        <td className="bold">Total</td>
        <td></td>
        <td>{
  getPercentage(scatterChart).reduce(
    (accumulator, currentValue) => accumulator + Number((currentValue.Duration)),
    0,
  )
}</td>
        <td>{
  getPercentage(scatterChart).reduce(
    (accumulator, currentValue) => accumulator + Number((Number(currentValue.Percentage)).toFixed(2)),
    0,
  )/getPercentage(scatterChart).length
}</td> {/* Ensure consistent decimal formatting */}
        <td>{
  getPercentage(scatterChart).reduce(
    (accumulator, currentValue) => accumulator + Number((currentValue.NumOfMachines)),
    0,
  )
}</td>{/* Uncomment this line if needed */}
        {/* <td>{one.Cost > 0 ? Number(one.Cost.toFixed(1)) : 0}</td> */}
      </tr>
              </tbody>

            </table>
          </div > : ''}
      {/* {productionData.length > 0 && ( <div className="">
        <h1>Job Ideal and Actual Count</h1>
       
          <GroupedBarChart data={IdealActualdata(productionData,'FinalOperation') && IdealActualdata(productionData,'FinalOperation').ChartData} />
       
      </div> )} */}

      {/* {productionData.length > 0 && ( <div className="">
        <h1>Job Downtime Stacked Chart</h1>
       <Stackedbarchart  yaxisTitle={"Time"} data={DownTimeData(productionData,'FinalOperation') && DownTimeData(productionData,'FinalOperation').TableData} /> 
      </div>)} */}
      {productionData.length > 0 && ( <div className="">
        {/* <h1 className="mx-2">Job Downtime Stacked Chart & Performance Loss</h1> */}
        {/* <Stackedbarchart yaxisTitle={"Count"} data={ DownTimeData1(productionData,'FinalOperation') &&  DownTimeData1(productionData,'FinalOperation').ChartData} />  */}
    
      </div>)}
    </React.Fragment>
  );
};
export default JobCycleTime_DowntimeChart;
