import React from 'react';
import html2canvas from 'html2canvas';

const DownloadButton = ({ targetRef, filename = 'download.png' }) => {
    
    const downloadImage = () => {
      const element = targetRef.current;
      html2canvas(element).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = filename;
        link.click();
      });
    };
  return (
    <button className='btn-sm btn-primary mx-2' onClick={downloadImage}>Download as Image</button>
  );
};

export default DownloadButton;
