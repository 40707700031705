import React, { Component } from 'react';
import swal from 'sweetalert';
import endPoint from '../../../config';
import axios from '../../../httpServices/httpService';
import valid from '../../../utill/validation';
import joi from 'joi';
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';




class ConsumablesGroup extends Component {
  state = { 
    ConsumableGroup:{},
    oldObj:{},
    errors:{},
    querySuccess:false,

 }
 componentDidMount()
 {
   if(this.props.actionStatus==='add')
   {
     this.setState({ConsumableGroup:{}})
   }
   if(this.props.actionStatus==='update')
   {
     const ConsumableGroup={...this.props.consumableGroupData}
     this.setState({ConsumableGroup:this.props.consumableGroupData,oldObj:ConsumableGroup});
   }
 }

handleOnChange=(e)=>{
    const {ConsumableGroup,errors} ={...this.state};
    const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    ConsumableGroup[e.currentTarget.name]=e.currentTarget.value;
    this.setState({ConsumableGroup,errors:errors||{}});
    

}
schema={
  ConsumableGroup:joi.string().required().label('Consumable Group'),
}
handleSubmit=(data)=>{
 
  if(this.props.actionStatus==='add')
  {
    const errors = valid.validate(data,this.schema);
    this.setState({ errors: errors || {} });
    if (errors) return;

    // consol(data)
    this.setState({querySuccess:true});
     if(this.props.consumableGroupList.filter(one=>one.ConsumableGroup==data.ConsumableGroup).length<=0)
    {
      axios.post(endPoint.api+'AddConsumableGroup',data)
      .then((result)=>{
        // consol(result.data)
       if(result.status===200)
       {
        this.setState({querySuccess:false});
         this.props.history.push('/setting/consumablegroup/list')
         swal('Save Data Successfully');
       }
       else
       {
        this.setState({querySuccess:false});
        swal(`Data Not Saved`);
       }
      })
      .catch((err)=>{
        this.setState({querySuccess:false});
          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      })
    }
    else
    {
      this.setState({querySuccess:false});
      swal('Duplicate Entry Not Inserted');
    }
   

  }
  if(this.props.actionStatus==='update')
  {
    this.setState({querySuccess:true});
    axios.patch(endPoint.api+'UpdateConsumableGroup',{old:this.state.oldObj,new:data})
    .then((result)=>{
      const {n,nModified,ok}=result.data;
     if(ok==1&&n==1&&nModified==1)
     {
      this.setState({querySuccess:false});
       this.props.history.push('/setting/consumablegroup/list')
       swal('Update Data Successfully');
     }
     else
     {
      this.setState({querySuccess:false});
      swal(`${result.data}`);
     }
    })
    .catch((err)=>{
      this.setState({querySuccess:false});
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
    })

  }
 


}



    render() { 
        return ( 
            <React.Fragment>
          <ToastContainer/>
       {this.state.querySuccess?<Loading/>:''}
      
            <div className=" border">
              <div className="row justify-content-center">
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Consumables Group</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="consumables group"
                      name="ConsumableGroup"
                      onChange={this.handleOnChange}
                    defaultValue={this.state.ConsumableGroup.ConsumableGroup}
                    />
                    {this.state.errors.ConsumableGroup && <div className="alert small alert-danger">{this.state.errors['ConsumableGroup']}</div>}
                  </div>
    
                
                </div>
    
               
               
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.ConsumableGroup);
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="text-center" style={{marginTop:10}}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push('/setting/consumablegroup/list')
                  }}
                >
                 Cancel
                </button>
              </div>
            </div>
          </React.Fragment>
         );
    }
}
 
export default ConsumablesGroup;