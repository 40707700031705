import React, { Component } from 'react';
import axios from '../../../httpServices/httpService';
import endPoint from '../../../config';
import swal from 'sweetalert';
import valid from '../../../utill/validation';
import joi from 'joi';
import LoadingOverlay from 'react-loading-overlay'
import Loading from '../../../utill/LoadingComponent';
import { ToastContainer, toast } from 'react-toastify';


class CustomerMaster extends Component {
    state = { 
        UserGroup:{},
        oldObj:{},
        errors:{},
        querySuccess:false,

     }
     schema={
       Name:joi.string().required()
     }
     componentDidMount()
     {    
      
       if(this.props.actionStatus==='add')
       {
         this.setState({UserGroup:{}})
       }
       if(this.props.actionStatus==='update')
       {
         const UserGroup={...this.props.userGroupData}
         this.setState({UserGroup:this.props.userGroupData,oldObj:UserGroup});
       }
     }

    handleOnChange=(e)=>{
      const {UserGroup,errors} ={...this.state};
      const errorMessage = valid.validateProperty(e.currentTarget,this.schema);
    if (errorMessage) errors[e.currentTarget.name] = errorMessage;
    else delete errors[e.currentTarget.name];

    
        UserGroup[e.currentTarget.name]=e.currentTarget.value;
        this.setState({UserGroup,errors:errors||{}});
        
        

    }
    handleSubmit=(data)=>{
      
     
      if(this.props.actionStatus==='add')
      {
        const errors = valid.validate(data,this.schema);
        // consol(data)
        // consol(errors)
      this.setState({ errors: errors || {} });
      if (errors) return;
  
      this.setState({querySuccess:true});
        axios.post(endPoint.api+'customer',data)
        .then((result)=>{
         if(result.status===200)
         {
          this.setState({querySuccess:false});
           this.props.history.push('/setting/customer/list')
           swal('Save Data Successfully');
         }
         else
         {
          this.setState({querySuccess:false});
          swal(`Data Not Saved`);
         }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
          swal(`${err.response.data.message}`);
        })
  
      }
      if(this.props.actionStatus==='update')
      {
        this.setState({querySuccess:true});
      
        axios.patch(endPoint.api+'customer/'+data._id,{old:this.state.oldObj,new:data})
        .then((result)=>{
          const {n,nModified,ok}=result.data;
         if(ok==1&&n==1&&nModified==1)
         {
          this.setState({querySuccess:false});
           this.props.history.push('/setting/customer/list')
           swal('Updated Data Successfully');
         }
         else
         {
          this.setState({querySuccess:false});
          swal(`${result.data}`);
         }
        })
        .catch((err)=>{
          this.setState({querySuccess:false});
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
        })
  
      }
   


    }


    render() { 
        return ( 
            <React.Fragment> <ToastContainer/>
       {this.state.querySuccess?<Loading/>:''}
      

            <div className=" border">
              <div className="row justify-content-center">
                <div className="col-sm-4 ">
                  <div className="form-group text-center">
                    <label>Customer Name </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="customer name"
                      name="Name"
                      onChange={this.handleOnChange}
                    defaultValue={this.state.UserGroup.Name}
                    />
                    {this.state.errors.Name && <div className="alert small alert-danger">{this.state.errors['Name']}</div>}
                  </div>
    
                
                </div>
    
               
               
              </div>
              <div className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.handleSubmit(this.state.UserGroup);
                  }}
                >
                  Submit
                </button>
              </div>
              <div className="text-center" style={{marginTop:10}}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push('/setting/customer/list')
                  }}
                >
                 Cancel
                </button>
              </div>
            </div>
          </React.Fragment>
         );
    }
}
 
export default CustomerMaster;