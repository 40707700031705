import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";
import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import { ToastContainer, toast } from 'react-toastify';
import DateSelection from "../Common/DateSelection";




const Refrence = [
  { label: "Equipment", id: "1" },
  { label: "Component", id: "2" },
  { label: "Operator", id: "3" },
  { label: "Tool", id: "4" },
  { label: "Operation", id: "5" },
  { label: "Time", id: "6" },
  { label: "Stage Reasons", id: "7" },
  { label: "Stage", id: "8" },
];

function HistogramReport({fromDateLimit,storeDataInLocalStorage}) {
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [Totaldata, setTotaldata] = useState([]);
  const [data, setchartdata] = useState([]);
  const [chartdatadaywise, setchartdatadaywise] = useState([]);


  
  const [selectedDates, setSelectedDates] = useState({
    From: "",Till:""
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState({});
  const [selectedItemLengths, setSelectedItemLengths] = useState({});


  const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);

  // Your existing chart code goes here

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    {
      heading: "Equipment",
      label: "Equipment",
      initialItems: equpdata,
      selectedItemIds: selectedItemIds,
      name: "Equipment",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    },
    {
      heading: "Shift",
      label: "tablecolumns",
      initialItems: shiftOption,
      selectedItemIds: selectedItemIds,
      name: "Shift",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    },
 
  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////

  const column2 = [
    { path: "Time", label: "Time" },
    // { path: "Equipment", label: "Equipment" },
    // { path: "EquipmentGroup", label: "EquipmentGroup" },
    { path: "PartName", label: "PartName" },
    { path: "ProcessName", label: "ProcessName" },
    { path: "TargetParts", label: "TargetParts" },
    { path: "QuantityProduced", label: "QuantityProduced" },
    { path: "Rejected", label: "Rejected" },
    { path: "TotalDownTime", label: "TotalDownTime" },

    // { path: "Shift", label: "Shift" },

  ];

  const schema = {
    From:joi.string().required().label("From"),
    Till:joi.string().required().label("Till"),
  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
      setQuerySuccess(true);

        // const equipmentdata = await axios.get(api.api1 + "equipment");
    
        // const downtimedata = await axios.get(api.api + "downtimereason");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

        let equipmentdata = await storeDataInLocalStorage('equipment')
        const downtimelist = downtimedata
        .map((reason, index) => ({
          id: `${index}`,
          label: reason.ReasonGroup,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));

        downtimelist.push({id:'40',label:"Down"})


        const uniqueObjects = [];

        // Filter out objects with duplicate combinations of label and id
        const filteredData = downtimelist.filter(obj => {
          // Check if the combination of label and id is already in the uniqueObjects array
          const isDuplicate = uniqueObjects.some(uniqueObj =>
            uniqueObj.label === obj.label
          );
        
          if (!isDuplicate) {
            // If not, add it to the array and keep the object
            uniqueObjects.push({ id: obj.id, label: obj.label });
            return true;
          }
        
          // If the combination of label and id is already in the array, filter it out
          return false;
        });
        
        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        setDynamicData(uniqueObjects);
        setequpdata(equiplist);
        setEquipmentDatalist(equipmentdata)
        // consol("fetching data", downtimelist);
        
      } catch (error) {
        // console.error("Error fetching data:", error);
      setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts

  const getQualityReason = (ReasonArr) => {
    let returnArr = []
    if (dynamicData.length == 0) {
        returnArr.push({ DowntimeGroup: 'default', Duration: 0 });
    } else {
      dynamicData.forEach((one1) => {
            let filterReason = ReasonArr.filter(obj => obj?.DowntimeGroup == one1.label)
            if (filterReason.length > 0) {
                returnArr.push({ ...filterReason[0] })
            } else {
                let obj = { DowntimeGroup: one1.label, Duration: 0 }
                returnArr.push(obj)
            }
        })
    }

    return returnArr;
}


const 
Dailydatacomponent =()=>{
    let indexCounter = 0;

return Object.entries(columns2).map(([downtimeReason, entries]) => {
  indexCounter++;

  return (
    <div  className="shadow Histrogram  mx-auto mb-3 p-3" style={{backgroundColor:"white",borderRadius:"20px",width:"98%"
    // ,overflow:'hidden'
    }} >
        <div  className=" histogram-width mb-4">
        <div className="d-flex justify-content-start align-items-center  ">
<div className="uiverse mx-2" onClick={() =>
   handleDownloadExcel("histogram_"+downtimeReason)}>
 <span className="tooltip">Dowload Excel File</span>
 <span>
 Download
 </span>
</div>

</div>
      <h4 className="p-1 bg-light"> {downtimeReason}</h4>
     <div style={{ padding: 0,overflow:'auto' }}> <table id={'histogram_'+downtimeReason} className=" table table-sm table-bordered table-hover small table-striped" style={{ padding: 0}}>
        <thead className='small text-center border'>
        <tr className='text-center align-self-center table-primary'>
    <th></th>
    {entries
        .slice() // Create a copy of the array to avoid mutating the original
        .sort((a, b) => a.ShiftOf.localeCompare(b.ShiftOf)) // Sort the array based on ShiftOf property
        .map((one, index) => <th key={indexCounter}>{one.ShiftOf}</th>)}
</tr>

        </thead>
        <tbody>
          <tr className='text-center align-self-center'>
            <th>Down Time in Minutes</th>
            {entries
        .slice() // Create a copy of the array to avoid mutating the original
        .sort((a, b) => a.ShiftOf.localeCompare(b.ShiftOf)) // Sort the array based on ShiftOf property
        .map((one, index) => <th key={indexCounter}>{one.Duration}</th>)}
            {/* {entries.map(one => <th key={indexCounter}>{one.Duration}</th>)} */}
          </tr>
          <tr className='text-center align-self-center'>
            <th>No of incident recurring</th>
            {entries
        .slice() // Create a copy of the array to avoid mutating the original
        .sort((a, b) => a.ShiftOf.localeCompare(b.ShiftOf)) // Sort the array based on ShiftOf property
        .map((one, index) => <th key={indexCounter}>{one.NumberOfIncidents}</th>)}
            {/* {entries.map(one => <th key={indexCounter}>{one.NumberOfIncidents}</th>)} */}
          </tr>
        </tbody>
      </table>
      </div>
      </div>
      <div className="histogram-width " >
        <h4 className="text-center p-1 bg-light">{downtimeReason}</h4>
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={chartdatadaywise[indexCounter - 1]}
          options={{
            title: "Histogram of Down time with the number of incidents occurred",
            seriesType: "bars",
            series: {
              0: { targetAxisIndex: 0 }, // Use axis 0 for the first series
              1: { targetAxisIndex: 1 }, // Use axis 1 for the second series
            },
            vAxes: {
              // Define properties for both vertical axes
              0: { title: "Downtime (min)", format: "#", minValue: 0 },
              1: { title: "No. of incidents", format: "#", minValue: 0 },
            },
            hAxis: { title: "Dates" },
            annotations: {
              style: 'line', // or 'point', or 'text', or 'arrow'
              textStyle: {
                fontSize: 12,
                fontName: 'Arial',
              
              },
              stemColor: 'none', // No stem
              position: 'vertical', // Align annotations vertically
            },
          }}
        />
      </div>
    </div>
    
  );
})

}

  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
      "Equipment",
      "Shift",
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }
    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Equipment") {

     let noobdata  = [];
      newSelectedItems.forEach((item) => {
      let noob = EquipmentDatalist.filter(equipment => equipment.EquipmentID === item);
      noobdata.push(noob[0])
      inputData['Equips'] = noobdata
    })
    } else {
      inputData[name] = newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

    // consol(selectedDates,"current date")
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
  
    const dateTime = { ...selectedDates };
    const errors = validate.validate(dateTime, schema);
  
    setErrors(errors || {});
    if (errors) return;
  
    setQuerySuccess(true);
  
   
      if(new Date(dateTime.From) <= new Date(dateTime.Till)){
        inputData.From = dateTime.From;
        inputData.Till = dateTime.Till;
      }else{
        setQuerySuccess(false);
       
        return  toast.success('From date should be Less Than Till date', {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: '#babbbb', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      }
      inputData.Report = 'getSummarySheet'
      const results =  axios.put(api.api + 'GetDownTimeDataForHistogram', inputData).then(result =>{
          if(result.data?.length > 0) {
            let total = [];
let totaldata =[];
            const groupedData = {};

result.data.forEach((item,index) => {
    const downtimeReason = item.DowntimeReason;

    if (!groupedData[downtimeReason]) {
        groupedData[downtimeReason] = [];
    }

    groupedData[downtimeReason].push({
        ShiftOf: item.ShiftOf.split('T')[0],
        DowntimeGroup: item.DowntimeGroup,
        Duration: Math.ceil(item.Duration/60),
        NumberOfIncidents: item.NumberOfIncidents
    });
});

Object.entries(groupedData).map(([downtimeReason, entries]) =>{
    let Dayarr=[["Date", "Duration",{ role: 'annotation' }, "no. of incident",{ role: 'annotation' }],];
  
    entries
    .slice() // Create a copy of the array to avoid mutating the original
    .sort((a, b) => a.ShiftOf.localeCompare(b.ShiftOf)).map(item=>{
      Dayarr.push([item.ShiftOf,item.Duration,item.Duration,item.NumberOfIncidents,item.NumberOfIncidents])
  })
    totaldata.push(Dayarr);
   
} )
// consol(groupedData,"fbdfbdfb",totaldata);

result.data.forEach(element => {
  let Duration = element.Duration;
  let NumberOfIncidents = element.NumberOfIncidents;

  // Check if the downtime reason already exists in the 'total' array
  const existingReasonIndex = total.findIndex(entry => entry.Reason === element.DowntimeReason);

  if (existingReasonIndex !== -1) {
      // If yes, update the existing entry
      total[existingReasonIndex].NumberOfIncidents += NumberOfIncidents;
      total[existingReasonIndex].Duration += Duration;
  } else {
      // If not, add a new entry
      total.push({ Reason: element.DowntimeReason, Duration: Duration, NumberOfIncidents: NumberOfIncidents });
  }
});
            let Chartentry =[["incident Reason", "Duration",{ role: 'annotation' }, "no. of incident",{ role: 'annotation' }]];
            total.forEach(entry =>Chartentry.push([entry.Reason,Math.ceil((entry.Duration)/60),Math.ceil((entry.Duration)/60),(entry.NumberOfIncidents),entry.NumberOfIncidents] ))
            
          // consol(result.data,"data received",total,Chartentry);
      // Process the data as needed
     // Assuming result.data is an array of objects


     setchartdata(Chartentry);
     setTotaldata(total)
        setcolumns2(groupedData);
     setchartdatadaywise( totaldata);
      } else {
        swal("Data Not Available");
      }
  
      setQuerySuccess(false);
    }).catch((err) => {
            toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
          // consol(err);
          setQuerySuccess(false);
        });
  };
  


  

  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };
  const SelectedDates =(dates)=>{
    setSelectedDates(dates)
  }
  ///////////////////////////// Column chart data ///////////////////

  return (
    <div  className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Downtime Histogram
        </h4>
      </div>
      <ToastContainer/>
      <div className="row mx-3">
       {equpdata.length > 0 ?  <>
        {/* <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">From</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
              />
          </div>
        </div>
        <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Till</label>
            <input
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded"
              }
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="Till"
              />
          </div>
        </div> */}

        <DateSelection SelectedDates={SelectedDates} fromDateLimit={fromDateLimit} />
            

     {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  {EquipmentDatalist.length > 0 && (
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}

                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar} 
                 /> 
               )}
                 {errors[data.heading] && (
                    <p className="text-danger" style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          ); 
       })} 

<div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div>

 </>:""}     
<div className="row">

<div className="col-sm-12">

    </div >
    {
        Totaldata.length > 0 && <div style={{overflow:'auto'}}> <table id={'utilizationreport'} className="table table-sm table-bordered table-hover small table-striped " style={{ padding: 0 }}>
            <thead className='small text-center border'>
         
                <tr className='text-center align-self-center table-primary'>

                <th   ></th>
                
                {Totaldata.map(item=><th  >{item.Reason}</th>)}
           
                   
                </tr>

                
              
            </thead>
            <tbody>
                 <tr>
                <th   >Down Time in Minutes</th>

                {Totaldata.map(item=><th   >{Math.round(Number(item.Duration)/60)}</th>)}

                 </tr>
                 <tr>
                <th   >No of incident recurring</th>

                {Totaldata.map(item=><th   >{item.NumberOfIncidents}</th>)}

                 </tr>
            </tbody>
        </table>
        </div>
        
    }
    <div className=" col-12 m-3 p-3">

 { data.length>0 &&  <Chart
  chartType="ComboChart"
  width="100%"
  height="400px"
  data={data}
  options={{
    title: "Histogram of Down time with the number of incidents occurred",
    seriesType: "bars",
    series: {
      0: { targetAxisIndex: 0 }, // Use axis 0 for the first series
      1: { targetAxisIndex: 1 }, // Use axis 1 for the second series
    },
    vAxes: {
      // Define properties for both vertical axes
      0: { title: "Downtime (min)", format: "#", minValue: 0 },
      1: { title: "No. of incidents", format: "#", minValue: 0 },
    },
    hAxis: { title: "Horizontal Axis" },
    annotations: {
      style: 'line', // or 'point', or 'text', or 'arrow'
      textStyle: {
        fontSize: 12,
        fontName: 'Arial',
        
      },
      stemColor: 'none', // No stem
      position: 'vertical', // Align annotations vertically
    },
  }}
/>
}
</div>
   {columns2 ?
 <div className="col-12 my-4 d-flex flex-column" style={{width:'100%',overflowX:'scroll'}}>

                 
{Dailydatacomponent()}





        </div> :''} 

    </div >
        </div> 
        {/* {chartdatadaywise && chartdatadaywise.map(item =><Chart chartType="ColumnChart"  width="100%" height="400px"  data={item} 
 options={{
   title:"Histogram of Down time with number of incidents occured",
    annotations: {
      style: "none",
      alwaysOutside: true,
    },}}
/>)} */}

    </div>
  );
}

export default HistogramReport;
