import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";

function AvilabilitySummary1({fromDateLimit,storeDataInLocalStorage}) {
  const [shiftOption, setShiftOption] = useState([
    { label: "Shift1", id: "1" },
    { label: "Shift2", id: "2" },
    { label: "Shift3", id: "3" },
    { label: "Shift4", id: "4" },
  ]);

  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [equpdata, setequpdata] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [datacount, setdatacount] = useState('');
  const [datetime, setdatetime] = useState(null);

  
  const [selectedDates, setSelectedDates] = useState({
    From: "",
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState([]);
  const [selectedItemLengths, setSelectedItemLengths] = useState({});

  
  // let DowntimegroupTotal =[];

  // columns2.forEach(column=>{
  //   const totalDuration = {};
  //   column.forEach(one => // consol(one?.DowntimeGroups !== undefined ? one?.DowntimeGroups.map((obj) => {
  //     const { DowntimeGroups, Duration } = obj;
  //     totalDuration[DowntimeGroups] = (totalDuration[DowntimeGroups] || 0) + Duration;
  //   }) :'',"testing the function"))

    
  //   const downtimeGroupObjects = Object.keys(totalDuration).map((group) => ({
  //     DowntimeGroups: group,
  //     Duration: totalDuration[group],
  //   }));
  //   DowntimegroupTotal.push(downtimeGroupObjects)
  
  //   // consol("isuvbiubvvisdfvbjisdufbv ",DowntimegroupTotal.map(one => one.map(item=>item.Duration)))
  // })

  // Iterate through the array and accumulate the duration for each downtime group
  // Log the separate objects for each downtime group
  // Your existing chart code goes here

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    {
      heading: "Equipment",
      label: "Equipment",
      initialItems: equpdata,
      selectedItemIds: selectedItemIds,
      name: "Equipment",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    },
    {
      heading: "Shift",
      label: "tablecolumns",
      initialItems: shiftOption,
      selectedItemIds: selectedItemIds,
      name: "Shift",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: false,
    },
    
 
  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////


  const schema = {
    Equips: joi.array().required().label("Equipment"),
    Shift: joi.array().required().label("please select at least one shift"),
   
  };

  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    // Fetch dynamic data using Axios
    const fetchData = async () => {
      try {
      setQuerySuccess(true);

        // const equipmentdata = await axios.get(api.api1 + "equipment");
    
        // const downtimedata = await axios.get(api.api + "downtimereason");
        let downtimedata = await storeDataInLocalStorage('downtimereason')

        let equipmentdata = await storeDataInLocalStorage('equipment')
        const downtimelist = downtimedata
        .map((reason, index) => ({
          id: `${index}`,
          label: reason.Reason,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));
        downtimelist.push({id:'90',label:"Down"})


        // const uniqueObjects = [];

        // // Filter out objects with duplicate combinations of label and id
        // const filteredData = downtimelist.filter(obj => {
        //   // Check if the combination of label and id is already in the uniqueObjects array
        //   const isDuplicate = uniqueObjects.some(uniqueObj =>
        //     uniqueObj.label === obj.label
        //   );
        
        //   if (!isDuplicate) {
        //     // If not, add it to the array and keep the object
        //     uniqueObjects.push({ id: obj.id, label: obj.label });
        //     return true;
        //   }
        
        //   // If the combination of label and id is already in the array, filter it out
        //   return false;
        // });
        

        const equiplist = equipmentdata
          .map((tool, index) => ({
            id: `${index}`,
            label: tool.EquipmentID,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));

        setDynamicData(downtimelist);
        setequpdata(equiplist);
        setEquipmentDatalist(equipmentdata)
        // consol("fetching data",downtimelist);
        
      } catch (error) {
        // console.error("Error fetching data:", error);
      setQuerySuccess(false);

      }
      setQuerySuccess(false);
    };

    fetchData();
  }, []); // Runs once when the component mounts

 const getDowntimeReason = (ReasonArr) => {
    let returnArr = [];

    // Check if ReasonArr is undefined
    if (!ReasonArr) {
      ReasonArr = [{ Reason: 'default', Duration: 0,Percentage:0 }];
    }

    if (dynamicData.length === 0) {
        returnArr.push({ Reason: 'default', Duration: 0,Percentage:0 });
    } else {
        dynamicData.forEach((one1) => {
            let filterReason = ReasonArr.filter(obj => obj?.Reason === one1.label);
            if (filterReason.length > 0) {
                returnArr.push({ ...filterReason[0] });
            } else {
                let obj = { Reason: one1.label, Duration: 0 ,Percentage:0};
                returnArr.push(obj);
            }
        });
    }

    return returnArr;
};



  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

    const validHeadings = [
      "Equipment",
      "Shift",
    ];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }

    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections
    if (name == "Equipment") {

     let noobdata  = [];
      newSelectedItems.forEach((item) => {
      let noob = EquipmentDatalist.filter(equipment => equipment.EquipmentID === item);
      noobdata.push(noob[0])
      inputData['Equips'] = noobdata
    })
    } else {
      inputData[name] = newSelectedItems;
    }

    // Now you have access to both newSelectedItems and heading
    // consol("Selected Items:", newSelectedItems);
    // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

    // consol(selectedDates,"current date")
  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
  
    const errors = validate.validate(inputData, schema);
  
    setErrors(errors || {});
    if (errors) return;
  
    setQuerySuccess(true);
  
    try {
      const dateTime = { ...selectedDates };
      inputData.DateTime = dateTime.From;
  
      // Initialize an array to store the response data
      
      // Define an async function to make requests sequentially

        try {
          const result = await axios.post(api.api + '/AvailablitySummary1', inputData);
          const equipResponse = result.data;
          if(equipResponse.length > 0) {
          let StoredData =[];
          let Totaldata =[];
         
          let data =equipResponse.map(item => {item.ShiftArr.map(one => { 
            StoredData.push(one)
          })
          StoredData.push({DateTime:' T ',Shift:'',Equipment:'Total',TotalShiftTime:item.TotalShiftTime,RunTime:item.RunTime,Break:item.Break,PlannedDowntime:item.PlannedDowntime,UnplannedDowntime:item.UnplannedDowntime,RunTimePercentage:item.RunTimePercentage,BreakPercentage:item.BreakPercentage,PlannedDowntimePercentage:item.PlannedDowntimePercentage,UnplannedDowntimePercentage:item.UnplannedDowntimePercentage,Availability:item.Availability,DownTimeReasonWise:item.TotalDowTimeReasonWise
        })
        })
          // consol(equipResponse,"after pushing",StoredData)
          setcolumns2(StoredData)
          setQuerySuccess(false);


        }else{
          swal(`No Data`);
          
          setQuerySuccess(false);
        }
        } catch (error) {
          swal(`${error}`);
          // consol(error);
          setQuerySuccess(false);
        }
      
  
      // Start making requests from the first element
    

    } catch (error) {
      swal(`${error}`);
      // consol(error);
      setQuerySuccess(false);
    }
  };
  
  


  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };


 // consol(datacount,"index number of data")
  return (
    <div  className="justify-content-center">
      {querySuccess ? <Loading/> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Availability Summary(1)
        </h4>
      </div>
      <div className="row mx-3">
       {equpdata.length > 0 ?  <>
       <div className="col-sm-2">
          <div className="form-group text-center">
            <label className="small">Date</label>
            <input
          
              style={{ borderColor: "#eaeaea" }}
              className={
                "form-control form-control-sm p-0 m-0 px-2 shadow-sm bg-body-tertiary rounded bg-white"
              }
              min={fromDateLimit}
              placeholder="dd/mm/yyyy"
              type="date"
              onChange={handleOnChange}
              name="From"
              />
          </div>
        </div>
            

        {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  {/* {componentdata.length > 0 && ( */}
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}

                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar}
                    />
                  {/* )} */}
                  {errors[data.heading] && (
                    <p className="text-danger" style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}

<div className="col-sm-1">
          <button
            className="btn btn-primary btn-sm mt-4"
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div>

 </>:""}           
    </div>
<div className="mx-1" style={{overflow:'auto'}}>
<div className="uiverse mx-2" onClick={() =>
   handleDownloadExcel(`${selectedDates.From}`+"-AvailabilityTable")}>
 <span className="tooltip">Dowload Excel File</span>
 <span>
 Download
 </span>
</div>
<table id={`${selectedDates.From}`+"-AvailabilityTable"} className="table table-sm table-bordered table-hover small  " style={{ padding: 0 }}>
            <thead className='small text-center border'>
                <tr className='text-center align-self-center table-primary'>
                    
                    <th style={{ verticalAlign: 'middle' }}  >Date</th>
                 
                    <th style={{ verticalAlign: 'middle' }}  >Shift</th>
          
                    <th style={{ verticalAlign: 'middle' }}  >Equipment</th>
                    <th style={{ verticalAlign: 'middle' }}  >Total Shift (mins)</th>

                    <th style={{ verticalAlign: 'middle' }}  >Run Time (mins)</th>
                    <th style={{ verticalAlign: 'middle' }}  >Run Time (%)</th>
                    <th style={{ verticalAlign: 'middle' }}  >Breaks (mins)</th>
                    <th style={{ verticalAlign: 'middle' }}  >BreakPercentage (%)</th>
                    <th style={{ verticalAlign: 'middle' }}  >Planned Downtime (mins)</th>
                    <th style={{ verticalAlign: 'middle' }}  >Planned Downtime (%)</th>
                    <th style={{ verticalAlign: 'middle' }}  >Unplanned Downtime (mins)</th>
                    <th style={{ verticalAlign: 'middle' }}  >Unplanned Downtime (%)</th>
                    
                    

                   
                    <th style={{ verticalAlign: 'middle' }}  >Availability (%)</th>
                    {dynamicData.length > 0 ? dynamicData.map(one => {
                        return <><th  className="rotate p-3 m-0 text-start"  style={{  height: (100 + dynamicData.length) * 2.8 ,verticalAlign: 'middle'}} ><div  style={{marginTop:'1rem'}}><span className="text-start">{one.label + ' (min)'}</span></div></th><th  className="rotate p-3 m-0 text-start"  style={{  height: (100 + dynamicData.length) * 2.8 ,verticalAlign: 'middle'}} ><div  style={{marginTop:'1rem'}}><span className="text-start">{one.label + '  (%)'}</span></div></th></>
                    }) : ''}
                </tr>

                {/* <tr className='table-primary' >

               
                   

                </tr> */}

            </thead>
            <tbody>
                {columns2.map((one,index)=> {
                    return <> 
                    <tr key={index} className={` ${one.Equipment === 'Total' ? 'table-warning text-center align-self-center':'text-center align-self-center'}`} >
                       
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.DateTime.split('T')[0]}</td>
                  
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.Shift}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.Equipment}</td>
 
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.TotalShiftTime ? Math.round(Number(one.TotalShiftTime)): 0}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.RunTime ? Math.round(Number(one.RunTime)) : 0}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.RunTimePercentage ? Math.round(Number(one.RunTimePercentage)) : 0}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.Break ? Math.round(Number(one.Break)) : 0}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.BreakPercentage ? Math.round(Number(one.BreakPercentage)) : 0}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.PlannedDowntime ? Math.round(Number(one.PlannedDowntime)) : 0}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.PlannedDowntimePercentage ? Math.round(Number(one.PlannedDowntimePercentage )): 0}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.UnplannedDowntime ? Math.round(Number(one.UnplannedDowntime)) : 0}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.UnplannedDowntimePercentage ? Math.round(Number(one.UnplannedDowntimePercentage)) : 0}</td>
                        <td   className="text-center" style={{fontWeight:'400'}}>{one.Availability ?Math.round(Number( one.Availability)) : 0}</td>

                        {
  getDowntimeReason(one?.DownTimeReasonWise).length > 0 ? (
    getDowntimeReason(one?.DownTimeReasonWise).map((r, index) => (
      <React.Fragment key={index}>
        <td  className={`text-center ${one.Equipment === 'Total' ? 'table-warning' : ''}`}>
        {r.Duration === 0 ? '0' : (r.Duration)?.toFixed(1)}
        </td>
        <td  className={`text-center ${one.Equipment === 'Total' ? 'table-warning' : ''}`}>
        {r.Percentage === 0 ? '0' : (r.Percentage !== undefined ? (Number(r.Percentage))?.toFixed(1) : 0)}
         
        </td>
      </React.Fragment>
    ))
  ) : (
    <td  className='text-center align-self-center'>0</td>
  )
}

                    </tr>
                    </>

                        
                   
                })}
            </tbody>
        </table>
</div>

    </div>
  );
}

export default AvilabilitySummary1;
