import React, { Component } from 'react';
import Chart from 'react-google-charts';

class LineChart extends Component {
    state = {  }
    render() { 
        return ( 
            <Chart
  width={'600px'}
  height={'400px'}
  chartType="LineChart"
  loader={<div>Loading Chart</div>}
  data={[
    ['x', 'Parts'],
    [0, 0],
    [1, 10],
    [2, 23],
    [3, 17],
    [4, 18],
    [5, 9],
    [6, 11],
    [7, 27],
    [8, 33],
    [9, 40],
    [10, 32],
    [11, 35],
  ]}
  options={{
    hAxis: {
      title: 'Time',
    },
    vAxis: {
      title: 'Production Count',
    },
  }}
  rootProps={{ 'data-testid': '1' }}
/>
         );
    }
}
 
export default LineChart;