
import React, { Component } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom"
import logo from './logo.svg';
import './App.css';

import 'bootstrap/js/dist/collapse';
import Login from './Common/Login';
import ForgotForm from './Common/ForgotForm';
import axios from 'axios';
import './navbar.css'
import api from './config';
import auth from './httpServices/authService';
import NavBarUser from './Common/NavBarUser';
import swal from 'sweetalert';
import valid from './utill/validation';
import Loading from './utill/LoadingComponent';
import * as serviceWorker from './serviceWorker';


class App extends Component {
  state = {
    userData: null,
    errors: {},
    querySuccess: false,
    modalSettingState: false,
    platform: "unknown"
  }
  handleMessage = (event) => {
    try {
      const message = JSON.parse(event.data);
      if (message.platform) {
        console.log(message.platform, "platform");
        this.setState({ platform: message.platform });
      }
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
  };

  // componentWillUnmount() {
  //   window.removeEventListener('message', this.handleMessage);
  // }

  componentDidMount() {
    window.addEventListener('message', this.handleMessage);
    if (this.state.platform == 'android' || this.state.platform == 'ios') {
      this.handleCheckForAndroidUserIsActive()
    } else {
      this.setState({ querySuccess: true })
      const user = auth.getCurrentUser();
      if (user) {
        //this.setState({ userData: user, });
        this.handleCheckUserIsActive(user, (err, isActive) => {
          if (err) {
            this.setState({ querySuccess: false });
            swal(`${err.message}`);
          } else {
            if (isActive) {
              //this.setState({ userData: user, querySuccess: false });

            this.handleSusbcribeForAskPermission(user);
            this.setState({ userData: user, querySuccess: false });
          }
          else {

            window.location = '/'

          }

        }

      })

      } else {
        this.setState({ userData: user, querySuccess: false });
      }
    }


  }

  handleCheckForAndroidUserIsActive = () => {
    this.setState({ querySuccess: true })
    const user = auth.getCurrentUser();
    auth.userActiveStatus(user.email)
      .then((result) => {
        if (result) {
          this.setState({ userData: user, querySuccess: false });
        } else {
          return auth.logout(user.email);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  handleCheckUserIsActive = (user, callback) => {
    let flag = false;
    if (navigator.onLine) {
      auth.userActiveStatus(user.email).then((result) => {
        if (result.isAxiosError == undefined) {
          flag = result.data
          if (!result.data) {
            return auth.logout(user.email);
          }
          else {
            return result
          }
        } else {
          if (result.isAxiosError) {
            console.log(result)
            throw new Error('Network Error Check Internet Connection')
          }
        }


      })
        .then((result) => {
          callback(null, flag)
        })
        .catch((err) => {
          if (err.response) {
            alert('error')
            if (err.response.status == 401) {
              auth.logout(user.email)
              callback(err, null)
            } else {
              callback(err, null)
            }
          } else {
            callback(err, null)
          }
        })
    } else {
      callback('Internet not connected check your connection', null);
    }

  }


  handleSusbcribeForAskPermission = (user) => {
    let self = this
    try{
      Notification.requestPermission(function (result) {
        if (result === 'granted') {
  
          navigator.serviceWorker.ready.then(async function (registration) {
            registration.showNotification('Permission Granted');
            var pushSubscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlB64ToUint8Array(api.public_key)
            });
            auth.subscribe(user.email, pushSubscription).then((susbscription) => {
              if (susbscription) {
  
              }
            })
          });
        }
      });
      if (Notification.permission !== 'denied' || Notification.permission === "default") {
        Notification.requestPermission(function (permission) {
          console.log(permission)
        });
      }
    }catch(err){
      console.log(err,"Notification")
    }
    
  }


  handleLogin = (user) => {

    
    if (user.email !== '' && user.email !== undefined && user.password !== '' && user.password !== undefined && user !== undefined) {
      this.setState({ querySuccess: true })
      auth.login(user, (err, token) => {
        if (err) {
          if (err.response == undefined) {
            this.setState({ querySuccess: false })
            swal(`${err} `)
          } else {
            this.setState({ querySuccess: false })
            swal(`${err.response.data.message}`)
          }
          
        }
        if (token) {
          console.log(user);
          localStorage.setItem('token', token.data);
    document.cookie = `myCookie=${token.data}; Domain=localhost; Path=/`;

          this.setState({ userData: auth.getCurrentUser(), querySuccess: false });
          if (this.state.platform !== 'android' && this.state.platform !== 'ios') {
            this.handleSusbcribeForAskPermission(user)
          }
        }
      })
    }






  }

  render() {

    return (

      <Switch>

        {this.state.querySuccess ? <Loading /> : ''}


        {!this.state.userData ?

          <React.Fragment>
            <Route path='/' exact render={props => <Login {...props} errors={this.state.errors} handleLogin={this.handleLogin} />} />
            <Route path='/forgot' render={props => <ForgotForm {...props} />} />
            {/* <Redirect from='/' exact to='/' /> */}
          </React.Fragment>
          :
          <Route path='/*' render={props => <NavBarUser {...props} platform={this.state.platform} user={this.state.userData} />} />
        }
      </Switch>


    );
  }
}

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export default App;


