import React, { useState, useEffect } from "react";
import axios from '../../../httpServices/httpService';
import api from '../../../config'
import swal from 'sweetalert';
import { Modal, Button } from 'react-bootstrap';
import Loading from "../../../utill/LoadingComponent";
import EquipmentGroup from './../EquipmentGroup/EquipmentGroup';
import Equipment from './../Equipment/Equipment';
import auth from "../../../httpServices/authService";
import { ToastContainer, toast } from 'react-toastify';


const UserRights = ({ history, modalShow, handleClose, updateUser, updateUserRights, equipList }) => {
  const [modalState, setModalState] = useState(modalShow)
  const [userRightsUpdate, setUserRightsUpdate] = useState(updateUserRights);
  const [selectedUserRight, setSelectedUserRight] = useState(null)
  const [modalRightsShow, setModalRightsShow] = useState(false);
  const [equip, setEquip] = useState(null)
  const [equipUpdateFlag, setEquipUpdateFlag] = useState(false)
  useEffect(() => {
    setModalState(modalShow);
    setUserRightsUpdate(updateUserRights);
  }, [modalShow, modalState, userRightsUpdate, selectedUserRight, modalRightsShow, equipUpdateFlag]);

  const handleOnChange = (e) => {
    // var row = document.getElementsByTagName('tbody')[0];
    // row.parentNode.removeChild(row);
    // var table = document.getElementsByTagName('table')[0];
    // table.append(getTableBodyChildEelement())
    // row.appendChild(getTableBodyEelement())
    setSelectedUserRight(e.currentTarget.value)
    setModalRightsShow(true)
  }


  const handleRadioChange = (e) => {
    let perm = [
      { perm: 'read', perm: 'write', perm: 'update', perm: 'none' },
      { perm: 'enable', perm: 'disable' },
      { perm: 'level1', perm: 'level2', perm: 'level3', perm: 'none' }]
    let perm1 = ['enable', 'disable']
    let perm2 = ['level1', 'level2', 'level3', 'none']
    let splitData = e.currentTarget.name.split('|');
    // // consol(e.currentTarget.checked)
    const allRightsUpdate={...userRightsUpdate};
    allRightsUpdate[selectedUserRight].map((one, i) => {
      if (splitData[0] == one.EmailReport) {
        if (selectedUserRight == 'EmailReports') {

          let filterPerm = perm1.filter(one => e.currentTarget.value !== one);
          allRightsUpdate[selectedUserRight][i][filterPerm[0]] = false;
          allRightsUpdate[selectedUserRight][i][e.currentTarget.value] = e.currentTarget.checked;
        }
      }
      if (e.currentTarget.name == "All") {
        let filterPerm = perm1.filter(one => e.currentTarget.value !== one);
        allRightsUpdate[selectedUserRight][i][filterPerm[0]] = false;
        allRightsUpdate[selectedUserRight][i][e.currentTarget.value] = e.currentTarget.checked;
      }
      if (splitData[0] == one.data) {
        if (selectedUserRight == 'userGroup' || selectedUserRight == 'equipments' || selectedUserRight == 'EmailReports' || selectedUserRight == 'Email' || selectedUserRight == 'equipmentGroups' || selectedUserRight == 'ReportRights') {
          let filterPerm = perm1.filter(one => e.currentTarget.value !== one);
          allRightsUpdate[selectedUserRight][i][filterPerm[0]] = false;
          allRightsUpdate[selectedUserRight][i][e.currentTarget.value] = e.currentTarget.checked;
        }
        else if (selectedUserRight == 'Notifications') {
          let filterPerm = perm2.filter(one => e.currentTarget.value !== one);
          allRightsUpdate[selectedUserRight][i][filterPerm[0]] = false;
          allRightsUpdate[selectedUserRight][i][filterPerm[1]] = false;
          allRightsUpdate[selectedUserRight][i][filterPerm[2]] = false;
          allRightsUpdate[selectedUserRight][i][e.currentTarget.value] = e.currentTarget.checked;
        }
        else if (selectedUserRight == 'StaticDataRights' || selectedUserRight == 'DynamicDataRights') {
          if (e.currentTarget.value == 'none' && e.currentTarget.checked) {
            allRightsUpdate[selectedUserRight][i][e.currentTarget.value] = e.currentTarget.checked
            allRightsUpdate[selectedUserRight][i]['read'] = false;
            allRightsUpdate[selectedUserRight][i]['write'] = false;
            allRightsUpdate[selectedUserRight][i]['update'] = false;
          }
          if (e.currentTarget.value == 'r' && e.currentTarget.checked) {
            allRightsUpdate[selectedUserRight][i]['none'] = false
            allRightsUpdate[selectedUserRight][i]['read'] = true;
            allRightsUpdate[selectedUserRight][i]['write'] = false;
            allRightsUpdate[selectedUserRight][i]['update'] = false;
          }
          if (e.currentTarget.value == 'r/w' && e.currentTarget.checked) {
            allRightsUpdate[selectedUserRight][i]['none'] = false
            allRightsUpdate[selectedUserRight][i]['read'] = true;
            allRightsUpdate[selectedUserRight][i]['write'] = true;
            allRightsUpdate[selectedUserRight][i]['update'] = false;
          }
          if (e.currentTarget.value == 'r/w/u' && e.currentTarget.checked) {
            allRightsUpdate[selectedUserRight][i]['none'] = false
            allRightsUpdate[selectedUserRight][i]['read'] = true;
            allRightsUpdate[selectedUserRight][i]['write'] = true;
            allRightsUpdate[selectedUserRight][i]['update'] = true;
          }
        }
        else {
          if (e.currentTarget.value == 'none' && e.currentTarget.checked) {
            allRightsUpdate[selectedUserRight][i][e.currentTarget.value] = e.currentTarget.checked
            allRightsUpdate[selectedUserRight][i]['read'] = false;
            allRightsUpdate[selectedUserRight][i]['write'] = false;
            allRightsUpdate[selectedUserRight][i]['update'] = false;
          }
          if ((e.currentTarget.value == 'read' && e.currentTarget.checked) ||
            (e.currentTarget.value == 'write' && e.currentTarget.checked) ||
            (e.currentTarget.value == 'update' && e.currentTarget.checked)) {
              allRightsUpdate[selectedUserRight][i][e.currentTarget.value] = e.currentTarget.checked;
              allRightsUpdate[selectedUserRight][i]['none'] = false;
          }
          handleCloseChild()
        }

        // filterPerm.forEach(one => {
        //   userRightsUpdate[selectedUserRight][i][one] = false;
        // })
      }
    })
    setModalRightsShow(true)
    setUserRightsUpdate(allRightsUpdate);
    
    // // consol(userRightsUpdate)
  }
  const handleSubmitUserRights = () => {
    axios.patch(api.api + "userrights/" + userRightsUpdate._id, { new: userRightsUpdate }).then((result) => {
      if (result.status === 200) {
        handleClose()
        swal('User Rights Update Successfully');
      }
      else {
        swal(`${result.statusText}`);
      }
    })
      .catch((err) => {
        this.setState({ querySuccess: false });
        toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
      });

  }

  const handleRadioCheckEnable = (one, perm, id) => {
    if (one == "All") {
      let result=false;
      let all = userRightsUpdate[id]
      for (let i = 0; i < all.length; i++) {
        if(!all[i][perm]){
          result=false;
          break; 
        }
        result=true;
      }
      return result;
    } else {
      let filter = userRightsUpdate[id].filter(data => data.data == one.data)
      if (filter.length > 0) {
        if (filter[0][perm]) {
          return true
        }
        else {
          return false
        }
      }
      else {
        return false
      }
    }

  }

  const handleRadioCheckEnable1 = (one, perm, id) => {
    let filter = userRightsUpdate[id].filter(data => data.EmailReport == one.EmailReport)
    if (filter.length > 0) {
      if (filter[0][perm]) {
        return true
      }
      else {
        return false
      }
    }
    else {
      return false
    }
  }
  const handleRadioCheckRWU = (one, perm, id) => {
    let filter = userRightsUpdate[id].filter(data => data.data == one.data)
    if (perm == 'none') {
      if (filter[0].none) {
        return true
      }
      else {
        return false
      }
    }
    if (perm == 'r') {
      if (filter[0].read && !filter[0].write && !filter[0].update) {
        return true
      }
      else {
        return false
      }
    }
    if (perm == 'r/w') {
      if (filter[0].read && filter[0].write && !filter[0].update) {
        return true
      }
      else {
        return false
      }
    }
    if (perm == 'r/w/u') {
      if (filter[0].read && filter[0].write && filter[0].update) {
        return true
      }
      else {
        return false
      }
    }
  }










  const getTableBodyBySelectdeRights = () => {
    switch (selectedUserRight) {
      case 'userGroups':
        return getTableBodyChildEelement1();
      case 'equipmentGroups':
        return getTableBodyChildEelement1();
      case 'equipments':
        return getTableBodyChildEelement1();
      case 'ReportRights':
        return getTableBodyChildEelementForReportRights();
      case 'StaticDataRights':
        return getTableBodyChildEelement();
      case 'DynamicDataRights':
        return getTableBodyChildEelement();
      case 'Notifications':
        return getTableBodyChildEelement2();
      case 'Email':
        return getTableBodyChildEelementForReportRights();
      case 'EmailReports':
        return getTableBodyChildEelementForEmailReportRights();
    }
  }

  // const findDataAndSetLatestUserRightsUpdateData=async (data,key)=>{
  //   if(selectedUserRight=='equipmentGroups'||selectedUserRight=='equipments'){
  //     if(selectedUserRight=='equipmentGroups'){
  //       const mutateData={...userRightsUpdate};
  //       const result=await axios.get(api.api+'equipmentgroup')
  //       let arr=[]
  //       result.data.forEach(one=>{
  //         let obj={}
  //         obj.EquipmentGroup=one.EquipmentGroup;
  //         obj.enable=false;
  //         obj.disable=false;
  //         arr.push(obj);
  //       })
  //       mutateData[key]=arr;
  //       setUserRightsUpdate(mutateData)
  //       return mutateData[key]
  //     }
  //     if(selectedUserRight=='equipments'){
  //       const mutateData={...userRightsUpdate};
  //       const result=await axios.get(api.api1+'equipment')
  //       let arr=[]
  //       result.data.forEach(one=>{
  //         let obj={}
  //         obj.Equipment=one.EquipmentID;
  //         obj.enable=false;
  //         obj.disable=false;
  //         arr.push(obj);

  //       })
  //       mutateData[key]=result.data;
  //       setUserRightsUpdate(mutateData)
  //       retrun 
  //     }
  //   }else
  //   {
  //     return data[key]
  //   }
  // }

  const handleGetEquipmentGroupAndType = (equipment) => {

    let equip = equipList.filter(one => one.EquipmentID == equipment)[0]
    return equip;
  }

  const getTableBodyChildEelement1 = () => {

    return <tbody>
      <tr>
        <td>All</td>
        <td>All</td>
        <td>All</td>
        <td> &nbsp;&nbsp;&nbsp;&nbsp;All :&nbsp;&nbsp;&nbsp;&nbsp;

          <input type='radio' checked={handleRadioCheckEnable("All", 'enable', selectedUserRight)} id={selectedUserRight + 'enable'} onChange={(e) => handleRadioChange(e)} name="All" defaultValue="enable" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All : &nbsp;&nbsp;&nbsp;
          <input type='radio' checked={handleRadioCheckEnable("All", 'disable', selectedUserRight)} id={selectedUserRight + 'disable'} onChange={(e) => handleRadioChange(e)} name="All" defaultValue="disable" /></td>
      </tr>
      {userRightsUpdate && selectedUserRight ? userRightsUpdate[selectedUserRight].map((one, i) => {
        return <tr>

          {/* {// consol(one.data+'|'+i)} */}
          <td>{selectedUserRight == 'equipments' ? equipList.filter(one1 => one1.EquipmentID == one.data)[0].EquipmentType : ''}</td>
          <td>{selectedUserRight == 'equipments' ? equipList.filter(one1 => one1.EquipmentID == one.data)[0].EquipmentGroup : ''}</td>
          <td>{one.data}</td>
          <td>Enable:&nbsp;&nbsp;&nbsp;

            <input type='radio' checked={handleRadioCheckEnable(one, 'enable', selectedUserRight)} id={selectedUserRight + 'enable'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="enable" />&nbsp;&nbsp;&nbsp;Disable:&nbsp;&nbsp;&nbsp;
            <input type='radio' checked={handleRadioCheckEnable(one, 'disable', selectedUserRight)} id={selectedUserRight + 'disable'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="disable" /></td>
        </tr>
      }) : ''}
    </tbody>
  }

  const getTableBodyChildEelementForReportRights = () => {

    return <tbody>
       <tr>
        <td>All</td>
        <td> &nbsp;&nbsp;&nbsp;&nbsp;All :&nbsp;&nbsp;&nbsp;&nbsp;

          <input type='radio' checked={handleRadioCheckEnable("All", 'enable', selectedUserRight)} id={selectedUserRight + 'enable'} onChange={(e) => handleRadioChange(e)} name="All" defaultValue="enable" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All : &nbsp;&nbsp;&nbsp;
          <input type='radio' checked={handleRadioCheckEnable("All", 'disable', selectedUserRight)} id={selectedUserRight + 'disable'} onChange={(e) => handleRadioChange(e)} name="All" defaultValue="disable" /></td>
      </tr>
      {userRightsUpdate && selectedUserRight ? userRightsUpdate[selectedUserRight].map((one, i) => {
        return <tr>
          <td>{one.data}</td>
          <td>Enable:&nbsp;&nbsp;&nbsp;

            <input type='radio' checked={handleRadioCheckEnable(one, 'enable', selectedUserRight)} id={selectedUserRight + 'enable'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="enable" />&nbsp;&nbsp;&nbsp;Disable:&nbsp;&nbsp;&nbsp;
            <input type='radio' checked={handleRadioCheckEnable(one, 'disable', selectedUserRight)} id={selectedUserRight + 'disable'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="disable" /></td>
        </tr>
      }) : ''}
    </tbody>
  }
  const getTableBodyChildEelementForEmailReportRights = () => {

    return <tbody>
         <tr>
        <td>All</td>
        <td> &nbsp;&nbsp;&nbsp;&nbsp;All :&nbsp;&nbsp;&nbsp;&nbsp;

          <input type='radio' checked={handleRadioCheckEnable("All", 'enable', selectedUserRight)} id={selectedUserRight + 'enable'} onChange={(e) => handleRadioChange(e)} name="All" defaultValue="enable" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All : &nbsp;&nbsp;&nbsp;
          <input type='radio' checked={handleRadioCheckEnable("All", 'disable', selectedUserRight)} id={selectedUserRight + 'disable'} onChange={(e) => handleRadioChange(e)} name="All" defaultValue="disable" /></td>
      </tr>

      {userRightsUpdate && selectedUserRight ? userRightsUpdate[selectedUserRight].map((one, i) => {
        return <tr>
          <td>{one.EmailReport}</td>
          <td>Enable:&nbsp;&nbsp;&nbsp;

            <input type='radio' checked={handleRadioCheckEnable1(one, 'enable', selectedUserRight)} id={selectedUserRight + 'enable'} onChange={(e) => handleRadioChange(e)} name={one.EmailReport} defaultValue="enable" />&nbsp;&nbsp;&nbsp;Disable:&nbsp;&nbsp;&nbsp;
            <input type='radio' checked={handleRadioCheckEnable1(one, 'disable', selectedUserRight)} id={selectedUserRight + 'disable'} onChange={(e) => handleRadioChange(e)} name={one.EmailReport} defaultValue="disable" /></td>
        </tr>
      }) : ''}
    </tbody>
  }
  const getTableBodyChildEelement2 = () => {
    return <tbody>
      {userRightsUpdate && selectedUserRight ? userRightsUpdate[selectedUserRight].map((one, i) => {
        return <tr>
          {/* {// consol(one.data+'|'+i)} */}
          <td>{one.data}</td>
          <td>None:&nbsp;&nbsp;&nbsp;
            <input type='radio' id={selectedUserRight + 'none'} defaultChecked={handleRadioCheckEnable(one, 'none', selectedUserRight)} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="none" />&nbsp;&nbsp;&nbsp;Level-1:&nbsp;&nbsp;&nbsp;
            <input type='radio' defaultChecked={handleRadioCheckEnable(one, 'level1', selectedUserRight)} id={selectedUserRight + 'level1'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="level1" />&nbsp;&nbsp;&nbsp;Level-2:&nbsp;&nbsp;&nbsp;
            <input type='radio' defaultChecked={handleRadioCheckEnable(one, 'level2', selectedUserRight)} id={selectedUserRight + 'level2'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="level2" />&nbsp;&nbsp;&nbsp;Level-3:&nbsp;&nbsp;&nbsp;
            <input type='radio' defaultChecked={handleRadioCheckEnable(one, 'level3', selectedUserRight)} id={selectedUserRight + 'level3'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="level3" />
          </td>
        </tr>
      }) : ''}
    </tbody>
  }

  const getTableBodyChildEelement = () => {
    return <tbody>
      {userRightsUpdate && selectedUserRight ? userRightsUpdate[selectedUserRight].map((one, i) => {
        return <tr>
          {/* {// consol(one.data+'|'+i)} */}
          <td>{one.data}</td>
          <td>None:&nbsp;&nbsp;&nbsp;
            <input type='radio' id={selectedUserRight + 'none'} defaultChecked={handleRadioCheckRWU(one, 'none', selectedUserRight)} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="none" />&nbsp;&nbsp;&nbsp;R:&nbsp;&nbsp;&nbsp;
            <input type='radio' defaultChecked={handleRadioCheckRWU(one, 'r', selectedUserRight)} id={selectedUserRight + 'read'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="r" />&nbsp;&nbsp;&nbsp;R/W:&nbsp;&nbsp;&nbsp;
            <input type='radio' defaultChecked={handleRadioCheckRWU(one, 'r/w', selectedUserRight)} id={selectedUserRight + 'write'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="r/w" />&nbsp;&nbsp;&nbsp;R/W/U:&nbsp;&nbsp;&nbsp;
            <input type='radio' defaultChecked={handleRadioCheckRWU(one, 'r/w/u', selectedUserRight)} id={selectedUserRight + 'update'} onChange={(e) => handleRadioChange(e)} name={one.data} defaultValue="r/w/u" /></td>
        </tr>
      }) : ''}
    </tbody>
  }

  const handleCloseChild = () => {
    setModalRightsShow(false)
  }

  const handleSaveUserRights = () => {
    setModalRightsShow(false)
  }

  const renderModal = () => {
    return (<Modal backdrop="static" size='lg' show={modalRightsShow} onHide={handleCloseChild}>
      <Modal.Header closeButton>
        <Modal.Title>User Rights Setting Panel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
              {selectedUserRight === 'equipments' ? <React.Fragment><th scope="col">EquipmentType</th><th scope="col">EquipmentGroup</th><th scope="col">Equipment</th></React.Fragment> : <th scope="col">{selectedUserRight}</th>}
              <th scope="col">Permisssions</th>
            </tr>
          </thead>
          {getTableBodyBySelectdeRights()}
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseChild}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveUserRights}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>)
  }
  return (
    <React.Fragment>   <ToastContainer />
      <Modal backdrop="static" show={modalState} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>User Rights Setting Panel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='form-group'>
            <select onChange={handleOnChange} className='form-control form-control-sm'>
              <option >Select User Rights For Update</option>
              {userRightsUpdate ? Object.keys(userRightsUpdate).filter(one => one != '_id' && one != 'email' && one != '__v' && one != 'modified' && one != 'createdAt' && one != 'updatedAt').map((one, i) => {
                return <option key={i}>{one}</option>
              }) : ''}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmitUserRights}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {renderModal()}



    </React.Fragment>
  );
};

export default UserRights;



