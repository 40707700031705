import Select from "react-select";
import React, { useState, useEffect, useRef } from "react";
import api from "../config.json";
import swal from "sweetalert";
import axios from "../httpServices/httpService";
import Loading from "../utill/LoadingComponent";
import validate from "../utill/validation";
import joi from "joi";
import { Chart } from "react-google-charts";
import ReusableCheckboxDropdown from "../Common/CheckBoxDropdowComponent";
import moment from "moment";

import Table from "../Common/table";
import _, { isNumber } from "lodash";
import { generateExcelFile } from "../utill/download";
import dateTime from "../utill/dateTime";
import pako from "pako";
import object from "joi/lib/types/object";
import YourDateRangePickerComponent from "../utill/DateRangePicker";
import { push } from "joi/lib/ref";


function QualitySummary1({fromDateLimit,storeDataInLocalStorage}) {

const Rejection =[ 
   "All",
   "Top 5 Rejection (count)",
   "Top 5 Rejection (%)", 
   "Top 10 Rejection (count)",
    "Top 10 Rejection (%)", 
    ];
  const [inputData, setInputData] = useState({});
  const [errors, setErrors] = useState({});
  const [querySuccess, setQuerySuccess] = useState(false);
  // Function to handle bar click
  const [selectedItemIds, setSelectedItemIds] = useState([]);
  const [senddata, setsenddata] = useState([]);
  const [Fiterdata, setFiterdata] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    From: "",
  });
  const [EquipmentDatalist, setEquipmentDatalist] = useState([]);
  const [columns2, setcolumns2] = useState([]);
  const [selectedItemLengths, setSelectedItemLengths] = useState({});
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [column, setcolumns] = useState([]);
  const [columns, setcolumn] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "Component", order: "asc" });
  const [Summarydata, setSummarydata] = useState({});
  const [componentdata, setcomponentdata] = useState([]);
  const [operationdata, setoperationdata] = useState([]);
  const [tooldata, settooldata] = useState([]);

  /////////////////////////////////////////// Array to ADD new DROP Down ///////////////////////////////////////////////////////////////
  const DropDownData = [
    {
      heading: "Component",
      label: "tablecolumns",
      initialItems: componentdata,
      selectedItemIds: selectedItemIds,
      name: "Component",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    selectAll:true,

    },
    {
      heading: "Tool",
      label: "tablecolumns",
      initialItems: tooldata,
      selectedItemIds: selectedItemIds,
      name: "Tool",
      enableLocalStorage: false,
      multiSelect: true,
      searchbar: true,
    selectAll:true,

    },
    {
        heading: "Operation",
        label: "tablecolumns",
        initialItems: operationdata,
        selectedItemIds: selectedItemIds,
        name: "Operation",
        enableLocalStorage: false,
        multiSelect: true,
        searchbar: true,
    selectAll:true,

      },
    
  ];

  ///////////////////////////////////// schema check for validation before sumitting data to the api ////////////////////////////////////////


  const schema = {
    Equips: joi.array().required().label("Equipment"),
    Shift: joi.array().required().label("please select at least one shift"),
   
  };
  /////////////////////////////////////////////////////////// Fetching data for all the dropdown list components ////////////////////////////////
  useEffect(() => {
    const fetchData = async () => {
      

      try {
        let componentdata = await storeDataInLocalStorage("component");
        let qualitydata = await storeDataInLocalStorage('qualityreason')
        let tooldata = await storeDataInLocalStorage('tool')
        const toollist = tooldata
        .map((tool, index) => ({
          id: `${index}`,
          label: tool.ToolId,
        }))
        .slice()
        .sort((a, b) => a.label.localeCompare(b.label));

        const complist = componentdata
          .map((componentList, index) => ({
            id: `${index}`,
            label: componentList.ComponentId,
          }))
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
          let Qualitylist =[
          { path: "Component", label: "Component" },
          { path: "ComponentAlias", label: "ComponentAlias" },
          
          { path: "Operation", label: "Operation" },
          { path: "PartsProduced", label: "Total Produced" },
          { path: "Rejection", label: "Rejection (count)" },
          { path: "RejectionPercentage", label: "Rejection (%)" },
          { path: "RejectionPercentage", label: "Rejection Cost in (₹)",content:(obj)=><label>{obj.Rejection?obj.Rejection * 5:'0'}</label> },
        ]
         let ordered = qualitydata
          .map((reason, index) => (
            Qualitylist.push({
          path: reason.QualityReason,
          label: reason.QualityReason,
        })))
          .slice();
     
                 // Iterate through the data and extract operation IDs
                 const operationIds = [];
                 componentdata.forEach((item) => {
                   item.Operation.forEach((operation) => {
                     operationIds.push(operation.OperationID);
                   });
                 });
                 // Sort and remove duplicates from operationIds
                 const uniqueSortedOperations = [...new Set(operationIds)]
                   .sort((a, b) => a - b)
                   .map((operation, index) => ({ id: index, label: operation }));
                  
     
        settooldata(toollist);
            
                   setoperationdata(uniqueSortedOperations);
        setcomponentdata(complist);
     
        setQuerySuccess(false);
        setcolumns(Qualitylist)
        setQuerySuccess(false);
      } catch (error) {
        setQuerySuccess(false);

        // console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);




  const handleDateChange = (dateRange) => {
 
    if( new Date(dateRange.startDate) === new Date(dateRange.endDate)){
    }
  
    if(new Date(dateRange.startDate) !== new Date(dateRange.endDate)){
  
  
    }
      setSelectedDateRange(dateRange);
     
    };



  ////////////////////////////////////////////// Handel on chnage for Dropdowns ///////////////////////////////////////////////////////////////////////

  const handleSelectedItemsChange = (newSelectedItems, name, totallength) => {
    setSelectedItemIds(newSelectedItems);

   
      const validHeadings = ["Equipment", "Component", "Operation","Tool",];

    // Check if the provided heading name is valid
    if (!validHeadings.includes(name)) {
      // console.error("Invalid heading:", name);
      return; // Exit the function if heading is invalid
    }

    setSelectedItemLengths((prevState) => ({
      ...prevState,
      [name]: newSelectedItems.length,
    }));
    // inputData[name] = newSelectedItems; // For headings with multiple selections


        inputData[name] = newSelectedItems.length == totallength ? ["all"] : newSelectedItems;
      
  
    // // consol("Selected Items:", newSelectedItems);
    // // consol("Heading:", name, "inputData :", inputData,EquipmentDatalist);
    setsenddata(inputData);
    // setCompareref(inputData.Refrence);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setSelectedDates((prevDates) => ({
      ...prevDates,
      [name]: value,
    }));

  };

  /////////////////////////////////// Submit Function Trigger on clicking ON apply button ///////////////////////////////////////////////////////////////
  const handleOnSubmit = async () => {
    let inputData = { ...senddata };
  
    // const errors = validate.validate(inputData, schema);
  
    // setErrors(errors || {});
    // if (errors) return;
  
    setQuerySuccess(true);
  
    try {
        const from =
        selectedDateRange[0]?.startDate?.length > 0
          ? selectedDateRange[0].startDate
          : moment().clone().add(-1, "day").format("YYYY-MM-DD");
      const till =
        selectedDateRange[0]?.endDate?.length > 0
          ? selectedDateRange[0].endDate
          : moment().clone().add(-1, "day").format("YYYY-MM-DD");
      inputData.From = from;
      inputData.Till = till;
    
        try {
          const result = await axios.post(api.api + 'QualitySummary1', inputData);
          
       
console.log(result,"xhwdvwgd");
          let equipResponse = [];
          if( result.data.length > 0) {
            result.data.forEach((element, index) => {
              let Component = element.Component;
              let From = element.From;
              let Operation = element.Operation;
              let PartsProduced = element.PartsProduced;
              let Rejection = element.Rejection;
              let RejectionPercentage = element.RejectionPercentage;
              let Till = element.Till;
              equipResponse.push({
                Component,
                From,
                Operation,
                PartsProduced,
                Rejection,
                RejectionPercentage,
                Till,
              });
            
              element.RejectionSummary.forEach((item) => {
                equipResponse[index][item.Reason] = item.Count === 0 ? '0' : item.Count;
              });
            });
            const totalPartsProduced = equipResponse.reduce((accumulator, item) => {
                return accumulator + item.PartsProduced;
            }, 0);
            const totalRejection = equipResponse.reduce((accumulator, item) => {
                return accumulator + item.Rejection;
            }, 0);

            const RejectionPercentage = (totalRejection/totalPartsProduced *1000000)

            setSummarydata({totalPartsProduced,totalRejection,RejectionPercentage})
            let  OrderedData;
// if(inputData?.Rejection[0] ==='All'){

  OrderedData =  equipResponse.sort((a,b) => b.RejectionPercentage -a.RejectionPercentage );

// }else{

//   let Count =inputData?.Rejection[0].split(' ')[1];
//   let Type =inputData?.Rejection[0].split(' ')[2]+inputData?.Rejection[0].split(' ')[3];
//   if(Count == 5 || Count == 10 ){
//     if(Type ==='Rejection (%)'){
//       let filtered = equipResponse.sort((a,b) => b.RejectionPercentage -a.RejectionPercentage );
//       OrderedData =filtered.slice(0, Count);
//     }else{
//       let filtered = equipResponse.sort((a,b) => b.Rejection -a.Rejection );
//       OrderedData =filtered.slice(0, Count);

//     }
   

//   }

// }



            // consol(equipResponse,"siugvufviofub")
            setcolumn(OrderedData);
            setFiterdata(OrderedData)
            setQuerySuccess(false);
          }else{
            swal(`No Data`);
          setQuerySuccess(false);

          }
        
        //   setdatetime([inputData.DateTime,inputData])   
        } catch (error) {
          swal(`${error}`);
          // consol(error);
          setQuerySuccess(false);
        }
      
  
      // Start making requests from the first element


    } catch (error) {
      swal(`${error}`);
      // consol(error);
      setQuerySuccess(false);
    }
  };
  
  const handleSort = (sortColumn) => {
    const sorted = _.orderBy(columns, [sortColumn.path], [sortColumn.order]);
    setcolumn(sorted);
    // // consol(sortColumn,"after pressing sort",sorted)
    setSortColumn(sortColumn);

}; 

const handleTopFilter =(e) => {
  let value = e.currentTarget.value
  let OrderedData =[];
  let equipResponse = [...Fiterdata];
  
if(value  !=="Select Display For"){
  if(value ==='All'){

    OrderedData = equipResponse.sort((a,b) => b.RejectionPercentage -a.RejectionPercentage );
  
  }else{
  
    let Count =value.split(' ')[1];
    let Type =value.split(' ')[2]+ ' '+value.split(' ')[3];
  // consol(Type,"df9vjdfjd90fv")

    if(Count == 5 || Count == 10 ){
      if(Type ==='Rejection (%)'){
        let filtered = equipResponse.sort((a,b) => b.RejectionPercentage -a.RejectionPercentage );
        OrderedData =filtered.slice(0, Count);
      }else{
        let filtered = equipResponse.sort((a,b) => b.Rejection -a.Rejection );
        OrderedData =filtered.slice(0, Count);
  
      }
     
  
    }
  
  }}else{return}
  // consol(OrderedData,'df9vsdsdjdfjd90fv')
  setcolumn(OrderedData);

};

  const handleDownloadExcel = (e) => {
    generateExcelFile(e);
  };


  return (
    <div  className="justify-content-center">
      {querySuccess ? <Loading /> : ""}
      <div className="text-center heading-bg mb-4">
        <h4 className="text-white p-0 m-0" >
          Quality Summary(1) 
        </h4>
      </div>
      <div className="row mx-3">
       {componentdata.length > 0 ?  <>
        <div className=" col-sm-2 d-flex align-items-center   justify-content-center  text-center ">
             <div className="p-0 m-0  d-flex align-items-center"> <YourDateRangePickerComponent onDateChange={handleDateChange} /></div>
            </div>
            

        {DropDownData.map((data, index) => {
          return (
            <>
              <div className="col-sm-2">
                <div className="form-group text-center">
                  <label className="small">{data.heading}</label>
                  {/* {componentdata.length > 0 && ( */}
                    <ReusableCheckboxDropdown
                      initialItems={data.initialItems}
                      label={data.label}
                      heading={`${selectedItemLengths[data.name] == data.initialItems.length?"All":selectedItemLengths[data.name] || 0}  ${data.heading}`}

                      selectedItemIds={selectedItemIds}
                      onSelectedItemsChange={(newSelectedItems) =>
                        handleSelectedItemsChange(
                          newSelectedItems,
                          data.name,
                          data.initialItems.length
                        )
                      }
                      enableLocalStorage={data.enableLocalStorage} // Pass the enableLocalStorage prop
                      multiSelect={data.multiSelect} // Pass
                      searchbar={data.searchbar}
                      selectAll={data.selectAll}

                    />
                  {/* )} */}
                  {errors[data.heading] && (
                    <p className="text-danger" style={{ fontSize: 11 }}>
                      {errors[data.heading]}
                    </p>
                  )}
                </div>
              </div>
            </>
          );
        })}

<div className="col-sm-1 d-flex align-items-center    justify-content-center">
          <button
            className="btn btn-primary btn-sm mt-2 "
            onClick={() => handleOnSubmit()}
          >
            Apply
          </button>
        </div>
        <div className="col-sm-3">
              <div className="form-group text-center">
                <label>Display For</label>

                <select
                  className="custom-select form-control shadow-sm"
                  name="EquipmentGroup"
                  onChange={handleTopFilter}
                  value={Rejection.Rejection}
                 disabled={columns.length > 0 ? false : true}
                >
                  <option>Select Display For </option>
                  {Rejection.map(one => {
                    return <option>{one}</option>
                  })}
                </select>

              </div>
            </div>

 </>:""}           
    </div>
    {columns?.length > 0 ? ( <div style={{backgroundColor:"white",borderRadius:"15px",}} className="mx-3 my-3 shadow ">

        <div className="Qualitysummary   m-3 pt-3">
            <div className="border flex-grow-1 rounded-4 py-2 px-3 gradient-background mx-2 text-white"  ><h4>Total Production : {Summarydata?.totalPartsProduced }</h4></div>
            <div className="border flex-grow-1  rounded-4 py-2 px-3 gradient-background mx-2 text-white"  ><h4>Total Rejection : {Summarydata?.totalRejection }</h4></div>
            <div className="border  flex-grow-1 rounded-4 py-2 px-3 gradient-background mx-2 text-white"  ><h4>Rejection (ppm) : {(Number(Summarydata?.RejectionPercentage)).toFixed(1) }</h4></div>
            <div className="border  flex-grow-1 rounded-4 py-2 px-3 gradient-background mx-2 text-white"  ><h4>Total Rejection Cost in (₹) : {Summarydata?.totalRejection*3 }</h4></div>

            

        </div>
              <h2>Quality Summary Table</h2>
             
            
                <div className="uiverse" onClick={() =>
      handleDownloadExcel("QualitySummaryReport1")}>
    <span className="tooltip">Dowload Excel File</span>
    <span>
    Download
    </span>
</div>         <div style={{width:'100%',overflowX:'scroll'}}>
                <Table
                  id="QualitySummaryReport1"
                  onSort={handleSort}
                  columns={column}
                  sortColumn={sortColumn}
                  data={columns}
                /></div>    
            </div> ) 
            : (
              ""
            )}

    </div>
  );
}

export default QualitySummary1;
