import React from 'react';
import '../loader.css';


const Loading=({labelShow,label})=> {
    

        return (  <div className="background-overlay ">
        <div className="box">
            <div className="loader-18"> {labelShow?<p style={{fontSize:25,marginLeft:13,paddingTop:18}}>{label}</p>:''}</div>
        </div>  
       
    </div> );
    }

 
export default Loading;
