// import React, { Component } from "react";
// import Logo from '../assets/leafnet-logo.jpg';
// import '../root.css'
// import valid from '../utill/validation';
// import joi from 'joi';
// import Loading from './../utill/LoadingComponent';
// import config from '../config.json';
// import swal from "sweetalert";


// className Login extends Component {
//   state = {
//     user: {},
//     errors: '',


//   };
//   componentDidMount() {

//     // consol(this.props.errors)
//     this.setState({ errors: this.props.errors || {} });
//   }

//   schema = {
//     email: joi.string().email().required(),
//     password: joi.string().required(),
//     company: joi.string().required()
//   }

//   handleOnChange = (e) => {
//     const { user, errors } = { ...this.state };
//     const errorMessage = valid.validateProperty(e.currentTarget, this.schema);
//     if (errorMessage) errors[e.currentTarget.name] = errorMessage;
//     else delete errors[e.currentTarget.name];

//     user[e.currentTarget.name] = e.currentTarget.value;
//     this.setState({ user, errors: errors || {} });
//     // consol(this.state.user);
//   }
//   handleCheck = (data) => {
//     if (!config.Shared) {
//       if (config.Company == undefined) { swal('Wrong Configuration'); return }
//       data.company = config.Company;
//     }
//     const errors = valid.validate(data, this.schema);
//     this.setState({ errors: errors || {} });
//     if (errors) return;



//     this.props.handleLogin(this.state.user);

//   }
//   render() {
//     return (<div>

//       <div className="wrapper1 fadeInDown1">


//         <div id="formContent1">
//           <div className="fadeIn1 first1">
//             <img
//               src={Logo}
//               id="icon1"
//               alt="User Icon"
//             />
//           </div>


//           <input
//             required
//             type="text"
//             id="login1"
//             className="fadeIn1 second1"
//             name="email"
//             placeholder="Enter Email"
//             onChange={this.handleOnChange}
//           />
//           {this.state.errors.email && <div className="alert small alert-danger">{this.state.errors['email']}</div>}
//           <input
//             required
//             type="password"
//             id="password1"
//             className="fadeIn1 third1"
//             name="password"
//             placeholder="password"
//             onChange={this.handleOnChange}
//           />
//           {this.state.errors.password && <div className="alert small alert-danger">{this.state.errors['password']}</div>}
//           {config.Shared ? <input
//             required
//             type="text"
//             id="companyName"
//             className="fadeIn1 fourth1"
//             name="company"
//             placeholder="Company Name"
//             onChange={this.handleOnChange}
//           /> : <div className="medium ">{config.Company ? config.Company.toUpperCase() : ''}</div>}
//           {this.state.errors.company && <div className="alert small alert-danger">{this.state.errors['company']}</div>}
//           <input type="submit" className="fadeIn1 fourth1" value="Log In" onClick={() => { this.handleCheck(this.state.user) }} />


//           <div id="formFooter1">
//             <span className="underlineHover1" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/forgot')}>
//               Forgot Password?
//             </span>
//             {/* <p className='small'>Powered By <a title="Driti Tech." target="_blank" rel="noopener noreferrer" href="https://drititech.com/">: Driti Technologies LLP.</a></p> */}


//           </div>
//         </div>
//         <div className="mt-4">
            
//             <p style={{fontSize:'20px'}} className='small '>Powered By <a className="links" style={{textDecoration:'none',color:'black',fontSize:'20px'}} title="Driti Tech." target="_blank" rel="noopener noreferrer" href="https://drititech.com/">: Driti Technologies LLP</a></p>


//           </div>
//       </div>
      
      
//       </div>
//     );
//   }
// }

// export default Login;



import React, { useState, useEffect } from "react";
import Logo from '../assets/leafnet-logo.jpg';
import '../root.css';
import valid from '../utill/validation';
import joi from 'joi';
import Loading from './../utill/LoadingComponent';

import config from '../config.json';
import swal from "sweetalert";


const Login = (props) => {
  const [user, setUser] = useState({});
  const [errors, setErrors] = useState(props.errors || {});

  const schema = {
    email: joi.string().email().required(),
    password: joi.string().required(),
    company: joi.string().required(),
  };

  useEffect(() => {
    // consol(props.errors);
  }, [props.errors]);

  const handleOnChange = (e) => {
    const errorMessage = valid.validateProperty(e.currentTarget, schema);
    setUser({
      ...user,
      [e.currentTarget.name]: e.currentTarget.value,
    });

    setErrors({
      ...errors,
      [e.currentTarget.name]: errorMessage,
    });
  };

  const handleCheck = (data) => {
    if (!config.Shared) {
      if (!config.Company) {
        swal('Wrong Configuration');
        return;
      }
      data.company = config.Company;
    }

    const validationErrors = valid.validate(data, schema);
    setErrors(validationErrors || {});

    if (!validationErrors) {
      props.handleLogin(user);
    }
  };

  return (
    <div>
      <div className="wrapper1 fadeInDown1">
        <div id="formContent1">
          <div className="fadeIn1 first1">
            <img src={Logo} id="icon1" alt="User Icon" />
          </div>

          <input
            required
            type="text"
            id="login1"
            className="fadeIn1 second1"
            name="email"
            placeholder="Enter Email"
            onChange={handleOnChange}
          />
          {errors.email && <div className="alert small alert-danger">{errors.email}</div>}

          {/* Other input fields and error displays */}
          
          <input
            required
            type="password"
            id="password1"
            className="fadeIn1 third1"
            name="password"
            placeholder="password"
            onChange={handleOnChange}
          />
          {errors.password && <div className="alert small alert-danger">{errors.password}</div>}
          {config.Shared ? (
            <input
              required
              type="text"
              id="companyName"
              className="fadeIn1 fourth1"
              name="company"
              placeholder="Company Name"
              onChange={handleOnChange}
            />
          ) : (
            <div className="medium ">{config.Company ? config.Company.toUpperCase() : ''}</div>
          )}
          {errors.company && <div className="alert small alert-danger">{errors.company}</div>}
          <input type="submit" className="fadeIn1 fourth1" value="Log In" onClick={() => { handleCheck(user) }} />

          <div id="formFooter1">
            <span className="underlineHover1" style={{ cursor: 'pointer' }} onClick={() => props.history.push('/forgot')}>
              Forgot Password?
            </span>
          </div>
        </div>

        <div className="mt-4">
          <p style={{ fontSize: '20px' }} className='small '>Powered By <a className="links" style={{ textDecoration: 'none', color: 'black', fontSize: '20px' }} title="Driti Tech." target="_blank" rel="noopener noreferrer" href="https://drititech.com/">: Driti Technologies LLP</a></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
