

import XLSX from 'xlsx';

function LoadData() {
    // var xlsxChart=new XLSXChart();
    // var opts = {
    //     file: "chart.xlsx",
    //     chart: "column",
    //     titles: [
    //         "Title 1",
    //         "Title 2",
    //         "Title 3"
    //     ],
    //     fields: [
    //         "Field 1",
    //         "Field 2",
    //         "Field 3",
    //         "Field 4"
    //     ],
    //     data: {
    //         "Title 1": {
    //             "Field 1": 5,
    //             "Field 2": 10,
    //             "Field 3": 15,
    //             "Field 4": 20 
    //         },
    //         "Title 2": {
    //             "Field 1": 10,
    //             "Field 2": 5,
    //             "Field 3": 20,
    //             "Field 4": 15
    //         },
    //         "Title 3": {
    //             "Field 1": 20,
    //             "Field 2": 15,
    //             "Field 3": 10,
    //             "Field 4": 5
    //         }
    //     }
    // };
    // xlsxChart.writeFile (opts, function (err,data) {
    //   // consol ("File: ", opts.file);
      
    // });
    
    // // xlsxChart.generate (opts, function (err, data) {
    // //     res.set ({
    // //       "Content-Type": "application/vnd.ms-excel",
    // //       "Content-Disposition": "attachment; filename=chart.xlsx",
    // //       "Content-Length": data.length
    // //     });
    // //     res.status (200).send (data);
    // // });
    
    
     
    
    
}

function generateExcelFile(e,dateTime)
{
    var workbook = XLSX.utils.book_new();
    var ws1 = XLSX.utils.table_to_sheet(document.getElementById(e),{raw:false});
XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");

/* set up async GET request */
// var req = new XMLHttpRequest();
// req.open("GET", url, true);
// req.responseType = "arraybuffer";

// req.onload = function(e) {
//   var data = new Uint8Array(req.response);
//   var workbook = XLSX.read(data, {type:"array"});

  /* DO SOMETHING WITH workbook HERE */
// }

// req.send();
  if(!dateTime){dateTime=new Date(Date.now())}
XLSX.writeFile(workbook,`${e}${new Date(dateTime).toLocaleString()}.xlsx`);
}

function generateExcelFileFull(data,view)
{
    var workbook = XLSX.utils.book_new();
    var ws1 = XLSX.utils.json_to_sheet(data);
XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");

/* set up async GET request */
// var req = new XMLHttpRequest();
// req.open("GET", url, true);
// req.responseType = "arraybuffer";

// req.onload = function(e) {
//   var data = new Uint8Array(req.response);
//   var workbook = XLSX.read(data, {type:"array"});

  /* DO SOMETHING WITH workbook HERE */
// }

// req.send();

XLSX.writeFile(workbook,`${view}${new Date(Date.now()).toLocaleString()}.xlsx`);
}

export{
    LoadData,
    generateExcelFile,
    generateExcelFileFull,
}