import React, { Component } from "react";
import MultiRadialChart from "../../utill/MultiRadialChart";
import PieChart from "../../utill/PieChartAVEP";
import MachineDetailedPage from "../../utill/MachineDetailedPage";
import api from '../../config.json';
import axios from '../../httpServices/httpService';
import auth from '../../httpServices/authService';
import swal from 'sweetalert';
import BarChart from 'react-google-charts'

import {
    faSignOutAlt, faSignal

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from "joi/lib/types/symbol";
import Loading from "../../utill/LoadingComponent";
import { ToastContainer, toast } from 'react-toastify';

class ActivityDashboard extends Component {

    state = {
        array: [],
        modelState: false,
        cacheData: [],
        singleMachineData: [["Time(In Minutes)", "Production Count", { role: "style" }]],
        querySuccess: false,
        selectedCard: {},
        filterObj: { SortBy: 'Name' },
        startIndex: 0,

        jobTableData: [],
        andOnDasboardObjOrder: {
            Shift: 0, Equipment: 1, ConnStatus: 2, State: 3,
            OperatorName: 4, Component: 5, Operation: 6, Tool: 7, CustomerName: 8, TotalCycles: 9, TargetParts: 10, PartsProduced: 11, BalanceParts: 12, Availability: 13, Performance: 14, QualityFactor: 15, OEE: 16
        },
    }

    MyInterval;
    ScreensInterval;

    displayFor = () => {

        if (this.props.filterObj.DisplayFor && (this.props.filterObj.DisplayFor != "") && this.props.filterObj.NoOfMachineScreen && (this.props.filterObj.NoOfMachineScreen != "")) {
            this.ScreensInterval = setInterval(() => {
                let result = this.getFilterData(this.state.cacheData, this.props.filterObj)
                this.setState({ array: result })
            }, (parseInt(this.props.filterObj.DisplayFor) * 1000))
        }


    }

    async componentDidMount() {
        localStorage.setItem("startIndex", 0)
        if (this.props.user) {
            this.setState({ querySuccess: true })
            for (let i = 0; i < this.props.equipList.length; i++) {
                try {
                    let result = await axios.put(api.api1 + 'GetMachineActivityDashboard', { UserId: this.props.user._id, Equips: [this.props.equipList[i]] })

                    let array = [...this.state.array];
                    array = [...array, ...result.data];
                    let array1 = this.getFilterData([...array], this.props.filterObj);
                    this.setState({ array: array1, cacheData: [...array], filterObj: this.props.filterObj }, () => {
                        clearInterval(this.ScreensInterval);
                        this.ScreensInterval = null
                        this.displayFor()
                    });
                }
                catch (err) {
                    this.setState({ querySuccess: false })
                      toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                }

            }
            this.setState({ querySuccess: false })
        }
        this.MyInterval = setInterval(async () => {
            if (this.props.user) {
                let data = [...this.state.array];
                let result;
                for (let i = 0; i < this.props.equipList.length; i++) {
                    try {
                        result = await axios.put(api.api1 + 'GetMachineActivityDashboard', { UserId: this.props.user._id, Equips: [this.props.equipList[i]] })
                        if (result.data.length > 0) {
                            data[i] = result.data[0];
                        }

                    }
                    catch (err) {
                        this.setState({ querySuccess: false })
                          toast.success(`${err}`, {
          position: 'bottom-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          style: {
            background: 'red', // Set your desired background color
            color: 'white',     // Set your desired text color
          },
        });
                    }

                }
                let array1 = this.getFilterData([...data], this.props.filterObj);
                this.setState({ array: array1, cacheData: [...data], filterObj: this.props.filterObj }, () => {
                    clearInterval(this.ScreensInterval);
                    this.ScreensInterval = null
                    this.displayFor()
                });
            }
        }, 75000)


    }

    componentWillUnmount() {
        clearInterval(this.MyInterval);
        clearInterval(this.ScreensInterval);

    }

    componentDidUpdate() {
        if ((JSON.stringify(this.state.filterObj) !== JSON.stringify(this.props.filterObj)) || (this.state.filterObj.SortBy !== this.props.filterObj.SortBy)) {
            let result = this.getFilterData(this.state.cacheData, this.props.filterObj)
            this.setState({ array: result, filterBy: this.props.filterBy, filterObj: this.props.filterObj, querySuccess: false }, () => {
                clearInterval(this.ScreensInterval);
                this.ScreensInterval = null
                this.displayFor()
            });
        }
    }



    getFilterData = (data, filterKey) => {
        data = [...data];

        if (filterKey.SortBy == 'Name') {
            data = data.sort(function (a, b) {
                if (a.Equipment < b.Equipment) {
                    return -1;
                }
                else if (a.Equipment > b.Equipment) {
                    return 1;
                }
                return 0;
            });
        }
        if (filterKey.SortBy == 'EquipmentGroup') {
            data = data.sort(function (a, b) {
                if (a.EquipmentGroup < b.EquipmentGroup) {
                    return -1;
                }
                else if (a.EquipmentGroup > b.EquipmentGroup) {
                    return 1;
                }
                return 0;
            });
        }
        if (filterKey.NoOfMachineScreen && (filterKey.NoOfMachineScreen !== "")) {
            if (filterKey.SortBy == 'Name') {
                if (filterKey.DisplayFor && (filterKey.DisplayFor !== "")) {
                    let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(filterKey.NoOfMachineScreen)
                    data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                    if (endIndex >= (this.props.equipList.length - 1)) {
                        localStorage.setItem("startIndex", 0);
                    } else {
                        localStorage.setItem("startIndex", endIndex);
                    }

                } else {
                    data = data.slice(parseInt(localStorage.getItem("startIndex")), parseInt(filterKey.NoOfMachineScreen))
                }
            } else {
                if (filterKey.DisplayFor && (filterKey.DisplayFor !== "")) {
                   
                    let endIndex = parseInt(localStorage.getItem("startIndex")) + parseInt(filterKey.NoOfMachineScreen)
                    data = data.slice(parseInt(localStorage.getItem("startIndex")), endIndex)
                    data = data.filter((one) => data[0].EquipmentGroup == one.EquipmentGroup);
                    if (endIndex >= (this.props.equipList.length - 1)) {
                        localStorage.setItem("startIndex", 0);
                    } else {
                        localStorage.setItem("startIndex", endIndex);
                    }

                } else {
                    data = data.filter((one) => data[0].EquipmentGroup == one.EquipmentGroup);
                    data = data.slice(parseInt(localStorage.getItem("startIndex")), parseInt(filterKey.NoOfMachineScreen))
                }
            }


        }

        return data
    }

    handleGetData = (data, equipment) => {
        const singleMacData = [["Time(In Minutes)", "Production Count", { role: "style" }, { role: 'annotation' }]];
        if (data.length > 0) {

            data.forEach(element => {
                let arr = []
                arr.push(new Date(element.DateTime))
                if (equipment.DeviceConfig == 'ProductionCounter') {
                    arr.push(element.Count);
                    if (element.Count >= element.IdealSPM) {
                        arr.push('#008000');
                    } else {
                        arr.push('red');
                    }

                    arr.push('')
                }
                if (equipment.DeviceConfig == 'ProductionEvent') {
                    arr.push(1);
                    if (element.Count >= element.IdealSPM) {
                        arr.push('#008000');
                    } else {
                        arr.push('red');
                    }
                    arr.push('')
                }
                if ((equipment.DeviceConfig == 'ProductionLogger') || (equipment.DeviceConfig == 'ProductionCounterLogger')) {
                    arr.push(1);
                    if (element.EventType == 'EOP') {
                        arr.push('yellow');
                        arr.push(equipment.EventType)

                    }
                    else if (element.EventType == 'SOP') {
                        arr.push('green');
                        arr.push(element.EventType)

                    } else {
                        arr.push('red');
                        arr.push(element.Event)

                    }

                }
                singleMacData.push(arr)
            });
        }
        return singleMacData;

    }
    onHide = () => {
        this.setState({ selectedCard: {}, modelState: false, singleMachineData: [["Time(In Minutes)", "Production Count", { role: "style" }]] });
    }

    chartRender = (data, equip) => {
        if (data.length == 0) {
            return <p>Data Not Available </p>
        }
        data = this.handleGetData(data, equip)
        return <BarChart
            //style={{ marginTop: 200 }}
            chartType="ColumnChart"
            width="100%"
            height="200px"
            data={data}
            loader={<div>Loading Chart</div>}
            options={{
                vAxis: { title: "Cycles Count" },
                tooltip: { isHtml: true },
                animation: { startup: true, duration: 400, easing: "out" },

                explorer: {
                    maxZoomIn: 10,
                    maxZoomOut: 8,
                    zoomDelta: 2,
                    actions: ["dragToZoom", "rightClickToReset"],
                    axis: "horizontal",
                    // axis: "vertical",
                    // keepInBounds: true,
                    // maxZoomIn: 4.0
                },

                hAxis: {
                    slantedText: true,
                    slantedTextAngle: 90,
                    textStyle: { fontSize: 9 },
                    title: "Time"
                },
                annotations: { textStyle: { fontSize: 10, color: "" } },
                legend: { position: 'none' }

            }}
        />
    }
    render() {
        return (
            <>
                {this.state.querySuccess ? <Loading labelShow={true} label={`${this.state.array.length}/${this.props.equipList.length}`} /> : ''}
               
              <ToastContainer/> <div className='text-center'>
                    <p>Activity Dashboard</p>
                </div>

                <div className='text-center'>

                    <div className='row pl-3'>

                        {this.state.array.map(one => {
                            return <div className='col-sm-12 pl-1 ' >
                                <div className="jumbotron p-0 jumbotron-fluid  text-center " >
                                    <h6 className="display-6 mb-0" style={{ backgroundColor: '#bbb291', padding: 0 }}>{one.Equipment}</h6>
                                    <h6 className="display-6 mb-0" style={{ backgroundColor: '#bbb291', padding: 0 }}>{one.EquipmentGroup}</h6>
                                    <div className="row" style={{ marginLeft: '0px', marginRight: "0px" }}>
                                        <h4 className=" col-sm-6 small mt-0 text-left" style={{ backgroundColor: '#bbb291', padding: 0 }} >Component : {one.Component}</h4>
                                        <h4 className=" col-sm-6 small mt-0 text-right" style={{ backgroundColor: '#bbb291', padding: 0 }} >Operator : {one.Operator}</h4>
                                    </div>

                                    <div className="container ">
                                        {this.chartRender(one.Docs, one)}
                                    </div>
                                </div>
                            </div>
                        })}


                    </div>
                </div>
            </>
        );
    }
}

export default ActivityDashboard;
