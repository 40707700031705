import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAlignLeft,
  faAlignJustify,
  faUser,
  faTh,
  faPlus,
  faGrinAlt,
  faSignOutAlt,
  faCloudDownloadAlt, faFile, faDatabase, faCog, faUserEdit, faMinus, faQuestion


} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/js/dist/collapse";
import DashBoardV1 from './../User/Dashboard/dashboardV1.1';
import { Switch, Route, Redirect } from "react-router-dom";
import { MachineStateChart } from './../utill/machineStateChart';
import HistoryMachineState from './../Reporting/HistoryMachineState';
import axios from "../httpServices/httpService";
import api from "../config";
import auth from '../httpServices/authService';
import ChangePassword from './ChangePasswordForm';
import { generateExcelFile } from '../utill/download';
import swal from "sweetalert";
import UserGroup from "../User/Setting/UserGroup/UserGroup";
import UserGroupTable from "../User/Setting/UserGroup/UserGroupTable";
import EquipmentGroupTable from "../User/Setting/EquipmentGroup/EquipmentGroupTable";
import EquipmentGroup from "../User/Setting/EquipmentGroup/EquipmentGroup";
import EquipmentTypeTable from "../User/Setting/EquipmentType/EquipmentTypeTable";
import EquipmentType from "../User/Setting/EquipmentType/EquipmentType";
import Equipment from "../User/Setting/Equipment/Equipment";
import EquipmentTable from "../User/Setting/Equipment/EquipmentTable";
import ConsumablesGroup from "../User/Setting/ConsumablesGroup/ConsumablesGroup";
import ConsumablesGroupTable from "../User/Setting/ConsumablesGroup/ConsumablesTable";
import ConsumablesMaster from "../User/Setting/ConsumablesMaster/ConsumablesMaster";
import ConsumablesMasterTable from "../User/Setting/ConsumablesMaster/ConsumablesMasterTable";
import OperatorMaster from "../User/Setting/OperatorMaster/OperatorMaster";
import OperatorMasterTable from "../User/Setting/OperatorMaster/OperatorMasterTable";
import DownTimeReasonGroup from "../User/Setting/DownTimeGroup/DownTimeReasonGroup";
import DownTimeReasonGroupTable from "../User/Setting/DownTimeGroup/DownTimeReasonGroupTable";
import DownTime from "../User/Setting/DownTimeMaster/DownTime";
import DownTimeTable from "../User/Setting/DownTimeMaster/DownTimeTable";
import UserRights from "../User/Setting/UserRights/UserRight";
import UserRightTable from "../User/Setting/UserRights/UserRightTable";
import QualityReasonGroup from "../User/Setting/QualityReasonGroup/QualityReasonGroup";
import QualityReasonGroupTable from "../User/Setting/QualityReasonGroup/QualityReasonGroupTable";
import QualityReasonTable from "../User/Setting/QualityReasonMaster/QualityReasonTable";
import QualityReason from "../User/Setting/QualityReasonMaster/QualityReason";
import ToolMaster from "../User/Setting/ToolMaster/ToolMaster";
import ToolMasterTable from "../User/Setting/ToolMaster/ToolMasterTable";
import ComponentMaster from "../User/Setting/ComponentMaster/Component";
import ComponentTable from "../User/Setting/ComponentMaster/ComponentTable";
import ShiftSetting from "../User/Setting/ShiftSetting/ShiftSetting";
import ShiftSettingTable from "../User/Setting/ShiftSetting/ShiftSettingTable";
import Downtime from "../User/DataEntry/DownTime/Downtime";
import DowntimeView from "../User/DataEntry/DownTime/DowntimeView";
import ComponentDataEntry from "../User/DataEntry/Component/component";
import ComponentView from "../User/DataEntry/Component/componentView";
import Operator from "../User/DataEntry/Operator/Operator";
import OperatorView from "../User/DataEntry/Operator/OperatorView";
import QualityView from "../User/DataEntry/Quality/QualityView";
import Quality from "../User/DataEntry/Quality/Quality";
import Consumable from "../User/DataEntry/Consumables/Consumable";
import ConsumableView from "../User/DataEntry/Consumables/ConsumableView";
import DashBoard from "../User/Dashboard/dashboard";
import ProductionReport from './../Reporting/ProductionReport';
import Machine_OEE from "../Reporting/Machine_OEE";
import JobRunReport from "../Reporting/JobRunReport";
import Availability from "../Reporting/Availability";
import JobPerformance from './../Reporting/JobPerformanceReport';
import DowntimeMachineTrend from './../Reporting/DowntimeMachineTrend';
import OperatorPerformance from "../Reporting/OperatorPerformance";
import OperatorPerfDetailed from "../Reporting/OperatorPerfDetailed";
import DowntimeAnalysisCumulative from "../Reporting/DowntimeAnalysisCumulative";
import DowntimeAnalysisMachine from "../Reporting/DowntimeAnalysisMachine";
import QualityReport from "../Reporting/QualityReport";
import ComponentTest from './../User/Setting/ComponentMaster/ComponentTest';
import Loading from "../utill/LoadingComponent";
import User from './../User/Setting/User/User';
import UserTable from './../User/Setting/User/UserTable';
import OperationMaster from './../User/Setting/Operation/OperationMaster';
import OperationTable from './../User/Setting/Operation/OperationTable';
import { SocketClient } from "./SocketIoCompoenent";
import FirstPartReport from './../Reporting/FirstPartReport';
import Performance_Overview from './../Reporting/Performance_Overview';
import PerformanceDetailed from './../Reporting/Performance_Detailed';
import MachineActvity from "../Reporting/MachineActivity";
import Job_Operator_Performance from './../Reporting/Job_Operator_Performance';
import AvailabilityTrend from './../Reporting/AvailabilityTrend';
import JobOEE from './../Reporting/JobOee';
import DowntimePareto from './../Reporting/DowntimePareto';
import QualityPareto from './../Reporting/QualityPareto';
import OCXView from './../User/Dashboard/OCXView';
import MachineStateEventLog from './../Reporting/MachineStateEventLog';
import CustomerMaster from './../User/Setting/CustomerMaster/customer';
import CustomerTable from './../User/Setting/CustomerMaster/customerTable';
import AndonDashboard from './../User/Dashboard/andonDashboard';
import AvailabilityReportHourly from "../Reporting/AvailabilityHourly";
import NotificationClickDowntimeView from "../User/DataEntry/DownTime/NotificationClickDowntimeView";
import CycleRunAndRuntime from "../Reporting/CycleRunAndRuntime";
import DetailedProduction from "../Reporting/DetailedProduction";
import BenchMarkTable from "../Reporting/BenchMarkTable";
import ToolRun from "../Reporting/ToolRun";
import ActivityDashboard from "../User/Dashboard/activityDashboard";
import HourlyActivityDashboard from "../User/Dashboard/hourlyActivityDashboard";
import ReportSetting from "../User/Setting/Report/ReportSetting";
import CycleDuration from "../Reporting/CycleDuration";
import JobEntryTabular from "../User/DataEntry/Component/JobEntryTabular";
import { Modal, Button } from 'react-bootstrap';
import RejectionStatge from "../User/Setting/RejectionStage/RejectionStage";
import RejectionStageTable from "../User/Setting/RejectionStage/RejectionStageTable";
import PartsPerCycleReason from "../User/Setting/PartsPerCycleReason/PartsPerCycleReason";
import PartsPerCycleReasonTable from "../User/Setting/PartsPerCycleReason/PartsPerCycleReasonTable";
import DowntimeDetailedAnalysis from "../Reporting/DownTimeDetailedAnalysis";
import QualityAnalysis from "../Reporting/QualityAnalysis";
import QualityRejectionStageReport from "../Reporting/QualityRejectionStageReport";
import DetailedProduction1 from "../Reporting/DetailedProduction1";
import UtilizationReport from "../Reporting/UtilizationReport";
import Dailyproductionreport from "../Reporting/DailyProductionReport";
import TalbrosProductionReport from "../Reporting/Talbrosproductionreport";
import EquipmentWiseProductionData from "../Reporting/EquipmentWiseProductionData";
import McWiseData from "../Reporting/McWiseData";
import TalbrosProductionSummary from "../Reporting/TalbrosproductionSummary";

import LiveDashBoards from "../Reporting/LiveDashBoards";
import ProductionSummary from "../Reporting/ProductionSummary";
import AvilabilitySummary1 from "../Reporting/AvailabilitySummary1";
import QualitySummary1 from "../Reporting/QualitySummary1";
import HistogramReport from "../Reporting/HistogramReport";
import ComponentSummaryReport from "../Reporting/Componentummary";
import LiveDashBoards2 from "../Reporting/LiveDashBoards2";
import IncidentsReport1 from "../Reporting/IncidentsReport1";
import ProductionReportHourly from "../Reporting/ProductionReportHourly";

import ProductionReportHourly2 from "../Reporting/ProductionReportHourly2";
import DetailedProduction2 from "../Reporting/DetailedProduction2";
import EquipmentHourlyRate from "../User/Setting/EquipmentHourlyRate/EquipmentHourlyRate";
import EquipmentHourlyRateTable from "../User/Setting/EquipmentHourlyRate/EquipmentHourlyRateTbale";
import CavityLossReasonReport from "../Reporting/CavityLossReasons";
import { ToastContainer, toast } from 'react-toastify';
import DailyProductionSummary from "../Reporting/DailyProductionSummary";
import FAQPage from "./FAQPage";

import DaySummaryOneReport from "../Reporting/DaySummaryOneReport";

import AvailabilityRejectionOEEReport from "../Reporting/AvailabilityRejectionOeeReport";
import OverallMachineOEE from "../talbros_report/OverallMachineOEE";
import OverallMonthOEE from "../talbros_report/OverallMonthOEE";
import ProductionReportHourly3 from "../Reporting/ProductionReportHourly3";
import ToolProductionReport from "../Reporting/ToolProductionReport";
import CycleRunReportDynamic from "../Reporting/CycleRunReportDynamic";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import JobCycleTime_DowntimeChart from "../Reporting/Job_Cycletime&DowntimeChartReport";



class NavBarUser extends Component {
  state = {
    JobData: null,
    userRights: null,
    navSelected: '',

    fromDateLimit: null,
    toggle: isMobile ? false : true,
    userGroupList: [{ userGroup: 'UG1', userGroup: 'UG2' }],
    equipmentGroupList: [],
    equipmentTypeList: [],
    equipmentList: [],
    consumableGroupList: [],
    consumableMasterList: [],
    operatorMasterList: [],
    downTimeReasonGroupList: [],
    downTimeReasonList: [],
    userRightsList: [],
    userList: [],
    qualityReasonGroupList: [],
    qualityReasonList: [],
    toolMasterList: [],
    componentList: [],
    shiftSettingList: [],
    operationList: [],
    reports: [],
    imgUrl: '',
    navSelected: "",
    userData: "",
    actionStatus: "",
    chnagePasswordModelState: false,
    chnagePasswordUserData: null,
    equipGroups: [],
    //data
    showModal: false,
    open: false,
    userData: {},
    userGroupData: {},
    consumableGroupData: {},
    consumableMasterData: {},
    equipmentGroupData: {},
    equipmentTypeData: {},
    equipmentData: {},
    operatorMasterData: {},
    downTimeReasonGroupData: {},
    downTimeReasonData: {},
    qualityReasonGroupData: {},
    rejectionStage: {},
    partsPerCycleReason: {},
    qualityReasonData: {},
    toolMasterData: {},
    componentData: {},
    shiftSettingData: {},
    userRightsData: {},
    customerData: {},
    dataEntryComponentData: {},
    dataEntryOperatorData: {},
    dataEntryConsumableData: {},
    operationMasterData: {},
    querySuccess: false,
    job_view_enable: { JobView: false },
    equipList: [],
    macGroups: ['All'],
    filterObj: { FilterBy: 'All', SortBy: 'Ascending', JobView: false },
    sortObj: { SortBy: "Name" },
    filterBy: { 'All': true, },
    cardDashboardState: false,
    macStateDashboardState: false,
    andonDashboardState: false,
    activityDashboardState: false,
    version: "",
    downtimeReasonList: [],
    componentList: [],
    andonDashboardData: [],
    andOnData: [],
    equipGroups: [],
    andOnDasboardObj: {
      Equipment: true, ConnStatus: true, DeviceConfig: true,
      Component: true, CustomerName: true, PartsProduced: true, TargetParts: true, TotalTime: true, RunTime: true, BalanceParts: true, OperatorName: true, Operation: false, Tool: false, TotalCycles: false, Shift: false,
      Availability: false, State: true, Performance: false, QualityFactor: false, OEE: false, ComponentAlias: false, TotalDownTime: false,
      JobAverageSPM: false, JobAverageCycleTime: false, ShiftAverageSPM: false, ShiftAverageCycleTime: false,BatchNumber:false,

    },




    reportGroupClick: '',
    company: {},
    qualityReasonList: [],
    modalSettingState: false,
    autoScrollObj: { AutoScroll: false }


  };

  handleOnChangeAutoScroll = (e) => {
    const obj = { ...this.state.autoScrollObj };
    if (e.currentTarget.name == "AutoScroll") {
      obj[e.currentTarget.name] = e.currentTarget.checked;
    } else {
      obj[e.currentTarget.name] = e.currentTarget.value;
    }
    this.setState({ autoScrollObj: obj });
  }

  handleAutoScrollSetting = () => {
    return <Modal size='md' show={this.state.modalSettingState} onHide={() => this.setState({ modalSettingState: false })}>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: 17 }}>
          Auto Scroll Setting
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-sm-2">
            Enable
          </div>
          <div className="col-sm-2">
            <input type="radio" checked={this.state.autoScrollObj.AutoScroll == true ? true : false} name="AutoScroll" onChange={(e) => this.handleOnChangeAutoScroll({ currentTarget: { name: "AutoScroll", checked: true } })} placeholder="" />
          </div>
          <div className="col-sm-2">
            Disable
          </div>
          <div className="col-sm-2">
            <input type="radio" name="AutoScroll" checked={this.state.autoScrollObj.AutoScroll == false ? true : false} onChange={(e) => this.handleOnChangeAutoScroll({ currentTarget: { name: "AutoScroll", checked: false } })} placeholder="" />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-sm-6">
            Number Of Machine Screen
          </div>
          <div className="col-sm-6">
            <input type="text" value={this.state.autoScrollObj.NumberOfMachine} name="NumberOfMachine" onChange={this.handleOnChangeAutoScroll} className="form-control form-control-sm" placeholder="Enter..." />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6">
            Display For (Sec)
          </div>
          <div className="col-sm-6">
            <input type="text" name="DisplayFor" value={this.state.autoScrollObj.DisplayFor} onChange={this.handleOnChangeAutoScroll} className="form-control form-control-sm" placeholder="Enter..." />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => {
          if (this.state.macStateDashboardState) {
            localStorage.setItem("mac_state", JSON.stringify(this.state.autoScrollObj))
          }
          if (this.state.andonDashboardState) {
            localStorage.setItem("andon_state", JSON.stringify(this.state.autoScrollObj))
          }
          if (this.state.cardDashboardState) {
            localStorage.setItem("card_state", JSON.stringify(this.state.autoScrollObj))
          }
          this.setState({ modalSettingState: false })

        }} >
          Save Changes
        </Button>
      </Modal.Footer>

    </Modal>
  }

  async componentDidMount() {
    localStorage.removeItem('equipment');
    localStorage.removeItem('equipmentgroup');
    localStorage.removeItem('downtimereason');
    localStorage.removeItem('qualityreason');
    localStorage.removeItem('operator');
    localStorage.removeItem('tool');
    localStorage.removeItem('component'); 
    const macStateFilterObj = localStorage.getItem("macStateDashboardFilter");
    if (macStateFilterObj) {
      this.setState({ filterObj: JSON.parse(macStateFilterObj) });
    } else {
      this.setState({ filterObj: { FilterBy: 'All', SortBy: 'Ascending', JobView: false } });
    }

    let andOnState = JSON.parse(localStorage.getItem("andon_state"))
    if (andOnState && andOnState.AutoScroll) {
      this.setState({ autoScrollObj: andOnState });
    }
    let filterObj = JSON.parse(localStorage.getItem("activityFilter"));
    let data = localStorage.getItem('andon');
    let url = localStorage.getItem('url');
    let filterBy = { 'All': true }
    if (url) {
      this.props.history.push(url);
    }
    if (data) {
      this.setState({ andOnDasboardObj: JSON.parse(data) });
    }
    if (filterObj) {
      this.setState({ sortObj: filterObj });
    } else {
      this.setState({ sortObj: { SortBy: "Name" } });
    }


    const user = auth.getCurrentUser();
    if (user) {
      this.setState({ querySuccess: true })

      try {

        // let result = await axios.get(api.api1 + 'equipment');
        let result = await this.storeDataInLocalStorage('equipment');

        let equipGroups = await this.storeDataInLocalStorage('equipmentgroup');
        this.setState({ querySuccess: true })
        // let equipGroups = await axios.get(api.api + 'equipmentgroup');
        // consol(result,"starting function returns",result)
        if (result !== undefined) {

          let macGroups = ['All']
          result.forEach((one => {
            if (!macGroups.includes(one.EquipmentGroup)) {
              macGroups.push(one.EquipmentGroup);
              filterBy[one.EquipmentGroup] = true;
            }
          }))
          filterBy['All'] = true;
          macGroups.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
          // result=result.slice(0,5);
          this.setState({ equipList: result, macGroups: macGroups, filterBy: filterBy, equipGroups: equipGroups, querySuccess: false })
        }
        else {
          this.setState({ querySuccess: false });
        }

      } catch (e) {
        this.setState({ querySuccess: false })
      }
      this.setState({ querySuccess: true })
      axios.put(api.api + 'userrights', { email: user._id }).then((result) => {
        // // consol(result.data)
        if (result.data) {
          let arr = []
          result.data.ReportRights.forEach(one => {
            if (one.enable) {
              // let DisplayName=one.data
              // let RouteName=one.data;
              // let obj={}
              //  RouteName=RouteName.split(/\W+/).join('')
              //  obj.DisplayName=DisplayName;
              //  obj.RouteName=RouteName.toLowerCase();
              arr.push(one)
            }
          })
          arr = this.getReport(arr);
          this.setState({ userRights: result.data, reports: arr, querySuccess: false })
        }
        else {
          this.setState({ querySuccess: false });
        }
      })
        .catch((err) => {
          this.setState({ querySuccess: false })
          toast.success(`${err}`, {
            position: 'bottom-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'red', // Set your desired background color
              color: 'white',     // Set your desired text color
            },
          });
        })




      axios.get(api.api + 'company/' + auth.getCurrentUser().company).then((result) => {
        if (result.data) {


          this.setState({ company: result.data, querySuccess: false })
        }
        else {
          this.setState({ querySuccess: false });
        }
      })
        .catch((err) => {
          this.setState({ querySuccess: false })
          toast.success(`${err}`, {
            position: 'bottom-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: 'red', // Set your desired background color
              color: 'white',     // Set your desired text color
            },
          });
        })


      let downtimereason = await this.storeDataInLocalStorage('downtimereason');

      if (downtimereason) {

        downtimereason = downtimereason.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        this.setState({ downtimeReasonList: downtimereason, querySuccess: false })
      }
      else {
        this.setState({ querySuccess: false });
      }


      this.setState({ querySuccess: true })
      const qualityreason = await this.storeDataInLocalStorage('qualityreason');

      if (qualityreason) {
        // Save equipGroups data in local storage
        localStorage.setItem('qualityreason', JSON.stringify(qualityreason));
        // consol('from Api call')

        // Update state
        this.setState({ qualityReasonList: qualityreason, querySuccess: false })
      } else {
        this.setState({ querySuccess: false });
      }


      let client = await this.storeDataInLocalStorage('client');
      if (client) {


        let companycreationdate = client[0].createdAt.split('T')[0]  //yyyy-MM-dd format date
        // consol("company creation date: " + companycreationdate)
        const currentdate = new Date().toJSON().slice(0, 10);
        const currentDate = new Date();
        const oneYearAgoDate = new Date(currentDate);
        oneYearAgoDate.setFullYear(currentDate.getFullYear() - 1);

        // Format the date to "YYYY-MM-DD"
        const formattedOneYearAgo = oneYearAgoDate.toISOString().split('T')[0];
        // consol(companycreationdate, formattedOneYearAgo,"current date is grater")
        if (companycreationdate > formattedOneYearAgo) {
          this.setState({ fromDateLimit: companycreationdate })

        } else {

          this.setState({ fromDateLimit: formattedOneYearAgo })
          this.setState({ querySuccess: false });

        }
      }


      //////////////////// this function is used to fetch data and store in the local storage for the reports to avoid unwanted api calls they can take data from local storage
      let operatordata = await this.storeDataInLocalStorage('operator')
      let tooldata = await this.storeDataInLocalStorage('tool')
      let rejectionstages = await this.storeDataInLocalStorage('rejectionstage')
      ////////////////////////////////////////////////////

      let component = await this.storeDataInLocalStorage('component');
      if (component) {
        this.setState({ componentList: component, querySuccess: false })
      }
      const BackendVersion = await this.storeDataInLocalStorage('BackendVersion');
      if (BackendVersion) {
        // Update state
        this.setState({ version: BackendVersion.Version })
        this.setState({ querySuccess: false });

      }
      this.setState({ querySuccess: false });
    }

    if (window.location.pathname == '/') {
      this.setState({ cardDashboardState: false, macStateDashboardState: true, andonDashboardState: false, navSelected: window.location.pathname })
    } else if (window.location.pathname == '/dashboard/cardview') {
      this.setState({ activityDashboardState: false, macStateDashboardState: false, cardDashboardState: true, andonDashboardState: false, navSelected: window.location.pathname })
    }
    else if (window.location.pathname == '/dashboard/andon') {
      this.setState({ activityDashboardState: false, macStateDashboardState: false, cardDashboardState: false, andonDashboardState: true, navSelected: window.location.pathname })
    }
    else if (window.location.pathname == '/dashboard/activity') {
      this.setState({ activityDashboardState: true, macStateDashboardState: false, cardDashboardState: false, andonDashboardState: false, navSelected: window.location.pathname })
    }
    else {
      this.setState({

        navSelected: window.location.pathname,
        cardDashboardState: false,
        macStateDashboardState: false,
        andonDashboardState: false
      });
    }



  }
  setNavSelectedState = (navigation) => {
    this.setState({

      navSelected: navigation
    });

  }

  ///////////////////////// this is the function to store the data into localstorage, from the API when logged in into an company and then Provide data from the local storage instead of calling api ////////////////////
  storeDataInLocalStorage = async (apiName) => {
    const storedData = localStorage.getItem(apiName);

    if (storedData) {
      // Data exists in local storage, use it
      const currentData = JSON.parse(storedData);
      return currentData;
    } else {
      try {
        // Data doesn't exist in local storage, make API call to fetch it
        const response = await axios.get(api.api + apiName);

        if (response.data) {
          // Save data in local storage
          localStorage.setItem(apiName, JSON.stringify(response.data));
          // Return the fetched data
          return response.data;
        } else {
          // Handle the case where the API response doesn't contain data
          throw new Error("API response is empty");
        }
      } catch (error) {
        // Handle errors during the API call
        // console.error("Error fetching data:", error);
        throw error;
      }
    }
  };


  handleUpdateEquips = async () => {
    this.setState({ querySuccess: true });
    try {
      let equipmentdata = await this.storeDataInLocalStorage('equipment');
      this.setState({ equipList: equipmentdata, querySuccess: false });
    } catch (err) {
      this.setState({ querySuccess: false });
      toast.success(`${err}`, {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: 'red', // Set your desired background color
          color: 'white',     // Set your desired text color
        },
      });
    }
  }



  handleOnUpdate = (data) => {
    // // consol(this.state.navSelected)
    // // consol(data)
    const urlArr = this.state.navSelected.split('/')
    let navSelected;
    if (urlArr[3] == 'add') {
      navSelected = `/${urlArr[1]}/${urlArr[2]}/list`;
      this.setState({ navSelected: `/${urlArr[1]}/${urlArr[2]}/list` })
    }



    if (this.state.navSelected === '/setting/usergroup/list' || (urlArr[3] == 'add' && this.state.navSelected === '/setting/usergroup/list')) {
      this.setState({ actionStatus: 'update', userGroupData: data });
      this.props.history.push('/setting/usergroup/add');
    }
    if (this.state.navSelected === '/setting/operation/list' || (urlArr[3] == 'add' && this.state.navSelected === '/setting/operation/list')) {
      this.setState({ actionStatus: 'update', operationMasterData: data });
      this.props.history.push('/setting/operation/add');
    }
    if (this.state.navSelected === '/setting/consumablegroup/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/consumablegroup/list')) {
      this.setState({ actionStatus: 'update', consumableGroupData: data });
      this.props.history.push('/setting/consumablegroup/add');
    }

    if (this.state.navSelected === '/setting/consumablemaster/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/consumablemaster/list')) {
      this.setState({ actionStatus: 'update', consumableMasterData: data });
      this.props.history.push('/setting/consumablemaster/add');
    }
    if (this.state.navSelected === '/setting/equipmentgroup/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/equipmentgroup/list')) {
      this.setState({ actionStatus: 'update', equipmentGroupData: data });
      this.props.history.push('/setting/equipmentgroup/add');
    }
    if (this.state.navSelected === '/setting/equipmenttype/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/equipmenttype/list')) {
      this.setState({ actionStatus: 'update', equipmentTypeData: data });
      this.props.history.push('/setting/equipmenttype/add');
    }
    if (this.state.navSelected === '/setting/equipment/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/equipment/list')) {
      this.setState({ actionStatus: 'update', equipmentData: data });
      this.props.history.push('/setting/equipment/add');
    }
    if (this.state.navSelected === '/setting/equipmenthourlyrate/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/equipmenthourlyrate/list')) {
      this.setState({ actionStatus: 'update', equipmentData: data });
      this.props.history.push('/setting/equipmenthourlyrate/add');
    }
    if (this.state.navSelected === '/setting/operatormaster/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/operatormaster/list')) {
      this.setState({ actionStatus: 'update', operatorMasterData: data });
      this.props.history.push('/setting/operatormaster/add');
    }
    if (this.state.navSelected === '/setting/rejectionstage/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/rejectionstage/list')) {
      this.setState({ actionStatus: 'update', rejectionStage: data });
      this.props.history.push('/setting/rejectionstage/add');
    }
    if (this.state.navSelected === '/setting/partspercyclereason/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/partspercyclereason/list')) {
      this.setState({ actionStatus: 'update', partsPerCycleReason: data });
      this.props.history.push('/setting/partspercyclereason/add');
    }
    if (this.state.navSelected === '/setting/downtimereasongroup/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/downtimereasongroup/list')) {
      this.setState({ actionStatus: 'update', downTimeReasonGroupData: data });
      this.props.history.push('/setting/downtimereasongroup/add');
    }
    if (this.state.navSelected === '/setting/downtimereason/list' || (urlArr[3] == 'add' && this.state.navSceleted === '/setting/downtimereason/list')) {
      this.setState({ actionStatus: 'update', downTimeReasonData: data });
      this.props.history.push('/setting/downtimereason/add');
    }
    if (this.state.navSelected === '/setting/qualityreasongroup/list' || (urlArr[3] === 'add' && this.state.navSceleted === '/setting/qualityreasongroup/list')) {
      this.setState({ actionStatus: 'update', qualityReasonGroupData: data });
      this.props.history.push('/setting/qualityreasongroup/add');
    }
    if (this.state.navSelected === '/setting/qualityreason/list' || (urlArr[3] === 'add' && this.state.navSceleted === '/setting/qualityreason/list')) {
      this.setState({ actionStatus: 'update', qualityReasonData: data });
      this.props.history.push('/setting/qualityreason/add');
    }
    if (this.state.navSelected === '/setting/toolmaster/list' || (urlArr[3] === 'add' && this.state.navSceleted === '/setting/toolmaster/list')) {
      this.setState({ actionStatus: 'update', toolMasterData: data });
      this.props.history.push('/setting/toolmaster/add');
    }

    if (this.state.navSelected === '/setting/component/list' || (urlArr[3] === 'add' && this.state.navSceleted === '/setting/component/list')) {
      this.setState({ actionStatus: 'update', componentData: data });
      this.props.history.push('/setting/component/add');
    }
    if (this.state.navSelected === '/setting/shiftsetting/list' || (urlArr[3] === 'add' && this.state.navSceleted === '/setting/shiftsetting/list')) {
      this.setState({ actionStatus: 'update', shiftSettingData: data });
      this.props.history.push('/setting/shiftsetting/add');
    }
    if (this.state.navSelected === '/setting/customer/list' || (urlArr[3] === 'add' && this.state.navSceleted === '/setting/customer/list')) {
      this.setState({ actionStatus: 'update', customerData: data });
      this.props.history.push('/setting/customer/add');
    }
    if (this.state.navSelected === '/setting/userrights/list' || (urlArr[3] === 'add' && this.state.navSceleted === '/setting/userrights/list')) {
      this.setState({ actionStatus: 'update', userRightsData: data });
      this.props.history.push('/setting/userrights/add');
    }
    if (this.state.navSelected === '/setting/user/list' || (urlArr[3] === 'add' && this.state.navSceleted === '/setting/user/list')) {
      this.setState({ actionStatus: 'update', userData: data });
      // // consol(data)
      this.props.history.push('/setting/user/add');
    }

  };

  handleDataEntryUpdate = (key, data, url, actionStatus) => {
    this.setState({ [key]: data, actionStatus });
    this.props.history.push(url);
  }


  handleOnAdd = (urlPath, action, keyState, valueState) => {

    this.setState({ actionStatus: action, [keyState]: valueState });
  }

  handleToggleClick = () => {
    this.setState({ toggle: this.state.toggle ? false : true });
  };

  handleUserChangePassword = () => {
    this.setState({ chnagePasswordModelState: true, chnagePasswordUserData: auth.getCurrentUser() })
  }
  handleCloseChnagePassword = () => {
    this.setState({ chnagePasswordModelState: false })
  }

  handleUserRightsToVisibleOrNot = (dataMemebre, data, perType) => {
    const rights = this.state.userRights;
    let allow = false;
    if (rights != null) {
      rights[dataMemebre].map(one => {
        if (one.data === data) {
          allow = one[perType]
        }
      })
    }
    return allow;
  }

  handleClickJobNavItem = () => {

  }
  hanldeClickOperatorNavItem = () => {

  }

  handleDownloadTableToExcel = (tableId) => {
    generateExcelFile(tableId, new Date(Date.now()))
  }

  handleApplyFilter = () => {

  }

  handleChangeActivityFilter = (e) => {
    const filterObj = { ...this.state.sortObj }
    filterObj[e.currentTarget.name] = e.currentTarget.value;
    localStorage.setItem("activityFilter", JSON.stringify(filterObj))
    this.setState({ sortObj: filterObj });
  }

  handleFilterAndSort = (e) => {
    const filterObj = { ...this.state.filterObj }
    filterObj[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ filterObj: filterObj });
  }

  handleFilterBy = (e) => {
    const filterObj = { ...this.state.filterBy }
    filterObj[e.currentTarget.name] = e.currentTarget.checked;
    if (e.currentTarget.name === 'All') {
      this.state.macGroups.forEach((one) => {
        filterObj[one] = e.currentTarget.checked;
      })
    } else {
      filterObj['All'] = false;
    }
    this.setState({ filterBy: filterObj });
  }


  getReport = (report) => {
    let data = []

    for (let i = 0; i < report.length; i++) {
      let obj = { ReportGroup: report[i].ReportGroup, Report: [] }
      obj.Report.push(report[i]);
      for (let j = i + 1; j < report.length; j++) {
        if (report[i].ReportGroup === report[j].ReportGroup) {
          obj.Report.push(report[j]);
          report.splice(j, 1);

          // i--;
          j--;
          // i=j-1
        }
      }
      data.push(obj)
    }
    return data;
  }

  handleGroupClick = (group) => {
    this.setState({ reportGroupClick: group });
  }


  getAndonDashboardData = (data) => {
    const andOnDasboardObj = { ...this.state.andOnDasboardObj }
    let arr = []
    data.forEach((one) => {
      let obj = {}
      Object.keys(one).forEach((key) => {
        if (andOnDasboardObj[key] || key === 'DeviceConfig') {
          obj[key] = one[key]
        }
      })
      arr.push(obj)
    })
    localStorage.setItem('andon', JSON.stringify(andOnDasboardObj));
    this.setState({ andOnData: data, andonDashboardData: arr })
  }

  handleOnChangeSelect = (e) => {
    const andOnDasboardObj = { ...this.state.andOnDasboardObj }
    andOnDasboardObj['DeviceConfig'] = true
    const andOnData = [...this.state.andOnData]
    if (andOnDasboardObj[e.currentTarget.name]) {
      andOnDasboardObj[e.currentTarget.name] = false
    } else {
      andOnDasboardObj[e.currentTarget.name] = true
    }

    let arr = []
    andOnData.forEach((one) => {
      let obj = {}
      Object.keys(andOnDasboardObj).forEach((key) => {
        if (andOnDasboardObj[key]) {
          obj[key] = one[key]
        }
      })
      arr.push(obj)
    })
    localStorage.setItem('andon', JSON.stringify(andOnDasboardObj));
    this.setState({ andOnDasboardObj, andonDashboardData: arr })
  }



  handleGetAddJobData = (jobData) => {
    this.setState({ JobData: jobData });
  }


  handleCancel =()=>{
    this.setState({ open: false });

  }

  showModal=()=>{
    this.setState({open: true});

  }
  render() {
    const user = auth.getCurrentUser();
    const { handleUserRightsToVisibleOrNot } = this
    // const lazyComponent=React.lazy(()=>import("../User/Setting/Equipment/Equipment"))
    // // consol("lazyComponent")import UserComponent from './../Admin/User/UserComponent';
    // // consol(lazyComponent)
    return (<>
      {/* {({ ref, onRequest, onExit }) => (
        <div
          ref={ref}
          style={{background:'white'}}
        > */}
      <div className="wrapper">
        {this.handleAutoScrollSetting()}
        {this.state.querySuccess ? <Loading /> : ''}
        <ChangePassword handleClose={this.handleCloseChnagePassword} modelState={this.state.chnagePasswordModelState} user={this.state.chnagePasswordUserData} />
        <ToastContainer />


        <nav id="sidebar" className={this.state.toggle ? "" : "active"}>
          <div className="sidebar-header ">
            <img src={this.state.company.logo} className='avatar' style={{ borderRadius: 200, width: 150, backgroundColor: 'white' }} />
            <h4 className="text-white fw-bold">{''}<br /><label className='small'>{new Object(user).hasOwnProperty('company') ? user.company.toUpperCase() : ''}</label></h4>
          </div>

          <ul className="list-unstyled components" id='parent'>
            <p style={{ paddingTop: 0, paddingBottom: 0 }}><FontAwesomeIcon icon={faUser} />&nbsp;&nbsp;{user ? user.name : ''} &nbsp;&nbsp;<label className='small font-italic'>{user ? `( ${user.userType} )` : ''}</label></p>
            <p style={{ fontSize: 15 }}><a className='btn btn-sm btn-primary' onClick={() => this.handleUserChangePassword()}>Change Password</a><br></br><a onClick={() => { auth.logout(user.email); window.location = '/' }} className='btn btn-sm btn-primary mt-1'>Logout&nbsp;<FontAwesomeIcon icon={faSignOutAlt} /></a></p>

            <li >
              <a href="#live" data-toggle="collapse" aria-expanded="false" aria-controls="reporting" className="collapsed dropdown-toggle text-white" ><FontAwesomeIcon icon={faTh} color='white' />&nbsp;Live Reports</a>
              <ul className="collapse list-unstyled" id="live" data-parent="#parent">
                <li>
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/'); this.setState({ cardDashboardState: false, activityDashboardState: false, macStateDashboardState: true, andonDashboardState: false }) }}>DashBoard</a>
                </li>
                {user.company === "hitech engg" ? <li>
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/dashboard/activity'); this.setState({ activityDashboardState: true, cardDashboardState: false, macStateDashboardState: false, andonDashboardState: false }) }}>Hourly View</a>
                </li> : <></>}
                <li>
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/dashboard/andon'); this.setState({ activityDashboardState: false, cardDashboardState: false, macStateDashboardState: false, andonDashboardState: true }) }}>Andon Dashboard</a>
                </li>
                <li>
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/dashboard/cardview'); this.setState({ activityDashboardState: false, cardDashboardState: true, macStateDashboardState: false, andonDashboardState: false }) }}>Card View Dashboard</a>
                </li>
                <li>
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/dashboard/cxoview'); this.setState({ activityDashboardState: false, cardDashboardState: false, macStateDashboardState: false, andonDashboardState: false }) }}>Plant View</a>
                </li>
                {/* <li>
                    <a href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/')}}>Overview</a>
                  </li> */}
              </ul>
            </li>
            <li >
              <a href="#reporting" data-toggle="collapse" aria-expanded="false" aria-controls="reporting" className=" text-white collapsed dropdown-toggle text-white"><FontAwesomeIcon icon={faFile} color='white' />&nbsp;Reporting</a>
              <ul className="collapse list-unstyled" id="reporting" data-parent="#parent">
                {this.state.reports.map((one, index) => {
                  return <li key={index} >
                    <a href={`#${one.ReportGroup ? one.ReportGroup : ''}`} onClick={() => this.handleGroupClick(one.ReportGroup)} data-toggle="collapse" aria-expanded="false" aria-controls={one.ReportGroup} className="collapsed dropdown-toggle text-white"><FontAwesomeIcon icon={this.state.reportGroupClick === one.ReportGroup ? faMinus : faPlus} />  {one.ReportGroup ? one.ReportGroup.toUpperCase() : ''}</a>
                    <ul className="collapse list-unstyled" id={one.ReportGroup} data-parent="#reporting">

                      {one.Report.map((one1, idx) => {
                        return <li key={one1.__id}>
                          <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push(`/reporting/${one1.RouteName}`); this.setState({ activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>{one1.data}</a>
                        </li>
                      }
                      )}

                      {/* <li key="test_report">
                        <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push(`/reporting/tool_production`); this.setState({ activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Tool Production</a>
                      </li> */}

                    </ul>
                  </li>
                })}



                {/* <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/reporting/oee')}}>OEE</a>
                                                </li>
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/reporting/jobrun')}}>Job Runs</a>
                                                </li>

                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/reporting/availability')}}>Availability</a>
                                                </li>
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/reporting/performance_bycomponent')}}>Performance Analysis By Component</a>
                                                </li>
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/reporting/performance_bymachine')}}>Performance Analysis By Machine</a>
                                                </li>
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/reporting/operatorperformance')}}>Operator Performance</a>
                                                </li>
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/reporting/downtimeanalysis')}}>Downtime Analysis</a>
                                                </li>
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/reporting/qualityreport')}}>Quality Report</a>
                                                </li> */}




              </ul>
            </li>
            <li >
              <a href="#dataentry" data-toggle="collapse" aria-expanded="false" className=" collapsed dropdown-toggle text-white" aria-controls="dataentry"><FontAwesomeIcon icon={faDatabase} color='white' />&nbsp;Data Entry</a>
              <ul className="collapse list-unstyled" id="dataentry" data-parent="#parent">

                {/* <li >
                                        <a href="#downtime" onClick={()=>{this.props.history.push('/dataentry/downtime')}} data-toggle="collapse" aria-expanded="false" className='collapsed' aria-controls="downtime" className="dropdown-toggle text-white">Downtime</a>
                                        <ul className="collapse list-unstyled" id="downtime" data-parent="#dataentry">
                            
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/dataentry/downtime/view')}}>View</a>
                                                </li>
                                               
                        
                                    </ul>
                                </li> */}
                {!handleUserRightsToVisibleOrNot('DynamicDataRights', 'Downtime', 'none') ?
                  <li >
                    <a className="text-white " href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/dataentry/downtime/view'); this.setState({ activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Downtime </a>
                  </li> : ''}
                {/* <li >
                                        <a href="#component" onClick={()=>{this.props.history.push('/dataentry/component');this.setState({actionStatus:'add',dataEntryComponentData:{RunningStatus:'Running'}})}} data-toggle="collapse" aria-expanded="false" className='collapsed' aria-controls="component" className="dropdown-toggle text-white">Job Selection</a>
                                        <ul className="collapse list-unstyled" id="component" data-parent="#dataentry">
                            
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/dataentry/component/view')}}>View</a>
                                                </li>
                                               
                        
                                    </ul>
                                </li> */}
                {!handleUserRightsToVisibleOrNot('DynamicDataRights', 'JobSelection', 'none') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/dataentry/component/view'); this.setState({ activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Job </a>
                </li> : ''}

                {!handleUserRightsToVisibleOrNot('DynamicDataRights', 'JobSelection', 'none') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/dataentry/job/table'); this.setState({ activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Job Entry Tabular </a>
                </li> : ''}


                {/* <li >
                                        <a href="#operator" onClick={()=>{this.props.history.push('/dataentry/operator')}} data-toggle="collapse" aria-expanded="false" className='collapsed' aria-controls="operator" className="dropdown-toggle text-white">Operator Selection</a>
                                        <ul className="collapse list-unstyled" id="operator" data-parent="#dataentry">
                            
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/dataentry/operator/view')}}>View</a>
                                                </li>
                                               
                        
                                    </ul>
                                </li> */}
                {/* {!handleUserRightsToVisibleOrNot('DynamicDataRights', 'OperatorSelection', 'none') ? <li >
                  <a href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/dataentry/operator/view'); this.setState({cardDashboardState:false,macStateDashboardState:false})}}>Operator</a>
                </li> : ''} */}
                {/* <li >
                                        <a href="#quality" onClick={()=>{this.props.history.push('/dataentry/quality')}} data-toggle="collapse" aria-expanded="false" className='collapsed' aria-controls="quality" className="dropdown-toggle text-white">Quality</a>
                                        <ul className="collapse list-unstyled" id="quality" data-parent="#dataentry">
                            
                                                <li>
                                                    <a href="#" style={{cursor:'pointer'}} onClick={()=>{this.props.history.push('/dataentry/quality')}}>View</a>
                                                </li>
                                               
                        
                                    </ul>
                                </li> */}
                {!handleUserRightsToVisibleOrNot('DynamicDataRights', 'Quality', 'none') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/dataentry/quality'); this.setState({ activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Quality</a>
                </li> : ''}
              </ul>
            </li>

            <li>
              <a href="#setting" data-toggle="collapse" aria-expanded="false" className=" collapsed dropdown-toggle text-white" aria-controls="setting"><FontAwesomeIcon icon={faCog} color='white' />&nbsp;Settings</a>
              <ul className="collapse list-unstyled" id="setting" data-parent="#parent" >

                {handleUserRightsToVisibleOrNot('StaticDataRights', 'UserMaster', 'read') ? <li >
                  <a href="#user" data-toggle="collapse" aria-expanded="false" aria-controls="user" className="collapsed dropdown-toggle text-white" >User</a>
                  <ul className="collapse list-unstyled" id="user" data-parent="#setting"  >

                    <li data-parent="#user" >
                      <a style={{ cursor: 'pointer' }} onClick={() => {
                        this.props.history.push('/setting/usergroup/list');
                        this.setState({ navSelected: '/setting/usergroup/list', cardDashboardState: false, andonDashboardState: false, macStateDashboardState: false, activityDashboardState: false });



                      }}>User Group</a>
                    </li>
                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => {
                        this.props.history.push('/setting/userrights/list');
                        this.setState({ navSelected: '/setting/userrights/list', cardDashboardState: false, andonDashboardState: false, macStateDashboardState: false, activityDashboardState: false });


                      }}>User Rights</a>
                    </li>
                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => {
                        this.props.history.push('/setting/user/list');
                        this.setState({ navSelected: '/setting/user/list', cardDashboardState: false, andonDashboardState: false, macStateDashboardState: false, activityDashboardState: false });


                      }}>User</a>
                    </li>


                  </ul>
                </li> : ''}

                {handleUserRightsToVisibleOrNot('StaticDataRights', 'EquipmentMaster', 'read') ? <li >
                  <a href="#equipment" data-toggle="collapse" aria-expanded="false" aria-controls="equipment" className="collapsed dropdown-toggle text-white">Equipment</a>
                  <ul className="collapse list-unstyled" id="equipment" data-parent="#setting">

                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/equipmentgroup/list'); this.setState({ navSelected: '/setting/equipmentgroup/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Equipment Group</a>
                    </li>
                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/equipmenttype/list'); this.setState({ navSelected: '/setting/equipmenttype/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Equipment Type</a>
                    </li>
                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/equipment/list'); this.setState({ navSelected: '/setting/equipment/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Equipment</a>
                    </li>

                  </ul>
                </li> : ''}

                {handleUserRightsToVisibleOrNot('StaticDataRights', 'EquipmentHourlyRate', 'read') ? <li >
                  <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/equipmenthourlyrate/list'); this.setState({ navSelected: '/setting/equipmenthourlyrate/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Equipment Hourly Rate</a>

                  {/* <a href="#equipmenthourlyrate" data-toggle="collapse" aria-expanded="false" aria-controls="equipmenthourlyrate" className="collapsed dropdown-toggle text-white">Equipment</a>
                  <ul className="collapse list-unstyled" id="equipmenthourlyrate" data-parent="#setting">
 
                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/equipmenthourlyrate/list'); this.setState({ navSelected: '/setting/equipmenthourlyrate/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Equipment Hourly Rate</a>
                    </li>
                  </ul> */}
                </li> : ''}

                {handleUserRightsToVisibleOrNot('StaticDataRights', 'DowntimeMaster', 'read') ? <li >
                  <a href="#downtime" data-toggle="collapse" aria-expanded="false" aria-controls="downtime" className="collapsed dropdown-toggle text-white">Downtime</a>
                  <ul className="collapse list-unstyled" id="downtime" data-parent='#setting'>

                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/downtimereasongroup/list'); this.setState({ navSelected: '/setting/downtimereasongroup/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Dowtime Reason Group</a>
                    </li>
                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/downtimereason/list'); this.setState({ navSelected: '/setting/downtimereason/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Downtime Reason</a>
                    </li>


                  </ul>
                </li> : ''}
                {handleUserRightsToVisibleOrNot('StaticDataRights', 'QualityMaster', 'read') ? <li>
                  <a href="#quality" data-toggle="collapse" aria-expanded="false" aria-controls="quality" className=" collapsed dropdown-toggle text-white">Quality</a>
                  <ul className="collapse list-unstyled" id="quality" data-parent='#setting'>

                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/qualityreasongroup/list'); this.setState({ navSelected: '/setting/qualityreasongroup/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Rejection Reason Group</a>
                    </li>
                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/qualityreason/list'); this.setState({ navSelected: '/setting/qualityreason/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Rejection Reason</a>
                    </li>
                    <li>
                      <a style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/rejectionstage/list'); this.setState({ navSelected: '/setting/rejectionstage/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Rejection Stage</a>
                    </li>


                  </ul>
                </li> : ''}


                {handleUserRightsToVisibleOrNot('StaticDataRights', 'OperatorMaster', 'read') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/operatormaster/list'); this.setState({ navSelected: '/setting/operatormaster/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Operator Master</a>
                </li> : ''}
                {handleUserRightsToVisibleOrNot('StaticDataRights', 'OperatorMaster', 'read') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/partspercyclereason/list'); this.setState({ navSelected: '/setting/partspercyclereason/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Cavity Loss Reason</a>
                </li> : ''}
                {handleUserRightsToVisibleOrNot('StaticDataRights', 'ComponentMaster', 'read') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/component/list'); this.setState({ navSelected: '/setting/component/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Component Master</a>
                </li> : ''}
                {handleUserRightsToVisibleOrNot('StaticDataRights', 'OperationMaster', 'read') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/operation/list'); this.setState({ navSelected: '/setting/operation/list', activityDashboardState: false, andonDashboardState: false, andonDashboardState: false, cardDashboardState: false }) }}>Operation Master</a>
                </li> : ''}

                {handleUserRightsToVisibleOrNot('StaticDataRights', 'ToolMaster', 'read') ? <li  >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/toolmaster/list'); this.setState({ navSelected: '/setting/toolmaster/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Tool Master</a>
                </li> : ''}

                {handleUserRightsToVisibleOrNot('StaticDataRights', 'ShiftMaster', 'read') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/shiftsetting/list'); this.setState({ navSelected: '/setting/shiftsetting/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Shift</a>
                </li> : ''}

                {/* {handleUserRightsToVisibleOrNot('StaticDataRights', 'ShiftMaster', 'read') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/report'); this.setState({ navSelected: '/setting/report', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Report</a>
                </li> : ''} */}

                {handleUserRightsToVisibleOrNot('StaticDataRights', 'CustomerMaster', 'read') ? <li >
                  <a className="text-white" href="#" style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/customer/list'); this.setState({ navSelected: '/setting/customer/list', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}>Customer Master</a>
                </li> : ''}



              </ul>
            </li>
            {/* <li className=''>
              <a className="text-white"  style={{ cursor: 'pointer' }} onClick={() => { this.props.history.push('/setting/faq'); this.setState({ navSelected: '/setting/faq', activityDashboardState: false, andonDashboardState: false, cardDashboardState: false, macStateDashboardState: false }) }}><FontAwesomeIcon icon={faQuestion} color='white'  />&nbsp;FAQ</a>
          
            </li> */}

          </ul>

          {/* <ul className="copyright">
              <li className='small'>&copy; Copyright 2021 Leafnet</li>
              <li className='small'>Powered by <a title="Saffron Logics" href="https://saffronlogics.com/">Saffron Logics</a></li>
           </ul> */}
          <div className='mt-5 text-center'>
            <p className='small mb-0'>Version:{api.UIVersion}/{this.state.version}</p>
            <p className='small mb-0'>&copy; Copyright 2021 Leafnet</p>
            <p className='small'>Powered By <a title="Driti Tech." target="_blank" rel="noopener noreferrer" href="https://drititech.com/">: Driti Technologies LLP.</a></p>


          </div>


        </nav>

        <div id="content" style={{ padding: 0 }}>
          {/* <button
            id="sidebarCollapse"
            onClick={this.handleToggleClick}
            className="btn btn-sm btn-info"
          >
            <FontAwesomeIcon icon={faAlignLeft} />
          </button> */}


          {this.state.activityDashboardState ?
            <nav className="navbar navbar-expand-lg m-0 py-0 navbar-light bg-light" style={{ padding: 0 }}>
              <div className="container-fluid px-1">
                <input id="checkbox" type="checkbox" onClick={this.handleToggleClick} />
                <label className="toggle btn-info m-0" htmlFor="checkbox">
                  <div id="bar1" className="bars"></div>
                  <div id="bar2" className="bars"></div>
                  <div id="bar3" className="bars"></div>
                </label>
                {this.state.activityDashboardState ?
                  <React.Fragment><button
                    className="btn btn-dark d-inline-block d-lg-none ml-auto"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <FontAwesomeIcon icon={faAlignJustify} />
                  </button>

                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="nav navbar-nav ml-auto">

                        <li>
                          <div className='row'>
                            <div className='col-lg-6'>
                              <label className='small '>Number Of Machine Screen:</label>
                            </div>
                            <div className='col-lg-6'>
                              <input placeholder="number" defaultValue={this.state.sortObj.NoOfMachineScreen} onChange={this.handleChangeActivityFilter} className="form-control form-control-sm" name="NoOfMachineScreen" />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className='row'>
                            <div className='col-lg-6'>
                              <label className='small '>Display For(Sec):</label>
                            </div>
                            <div className='col-lg-6'>
                              <input placeholder="seconds" defaultValue={this.state.sortObj.DisplayFor} onChange={this.handleChangeActivityFilter} className="form-control form-control-sm" name="DisplayFor" />
                            </div>
                          </div>
                        </li>
                        <li>
                          <div className='row'>
                            <div className='col-lg-3'>
                              <label className='small '>SortBy:</label>
                            </div>
                            <div className='col'>
                              <select onChange={this.handleChangeActivityFilter} value={this.state.sortObj.SortBy} name='SortBy' className='form-control form-control-sm'>
                                <option value={"Name"}>Name</option>
                                <option value={"EquipmentGroup"}>Equipment Group</option>
                                <option value={"EquipmentType"}>Equipment Type</option>

                              </select>
                            </div>
                          </div>
                        </li>
                        {/* <li>
                          <button onClick={this.handleApplyFilter} className="btn btn-sm btn-primary">Apply</button>
                        </li> */}




                      </ul>
                    </div></React.Fragment> : ''}
              </div>
            </nav>
            :



            <nav className="navbar navbar-expand-lg m-0 py-0 navbar-light bg-light" style={{ padding: 0 }}>
              <div className="container-fluid px-1">
                {/* <button

                  type="button"
                  id="sidebarCollapse"
                  onClick={this.handleToggleClick}
                  className="btn btn-info"
                > */}
                <input id="checkbox" type="checkbox" onClick={this.handleToggleClick} />
                <label className="toggle btn-info m-0" htmlFor="checkbox">
                  <div id="bar1" className="bars"></div>
                  <div id="bar2" className="bars"></div>
                  <div id="bar3" className="bars"></div>
                </label>

                {this.state.cardDashboardState || this.state.macStateDashboardState || this.state.andonDashboardState ? <React.Fragment><button
                  className="btn btn-dark d-inline-block d-lg-none ml-auto"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <FontAwesomeIcon icon={faAlignJustify} />
                </button>

                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="nav navbar-nav ml-auto">
                      {/* <li className="nav-item " >
                    <a className="nav-link py-0" href="#" >
                      <SocketClient />
                    </a>
                  </li> */}

                      {/* <li className="nav-item " >
                    <a className="nav-link py-0" href="#" onClick={() => { }}>
                      <FontAwesomeIcon icon={faCloudDownloadAlt} />
                    </a>
                  </li> */}
                      {/* <div className="mx-1"  onClick={() => onRequest()}><FontAwesomeIcon icon={faExpand}  size="2xl" /></div>
        <div  className="mx-3"  onClick={() => onExit()}><FontAwesomeIcon icon={faCompress} size="2xl" /></div> */}

                      {this.state.macStateDashboardState ? <li className="nav-item ">
                        <div className='row'>
                          <div className='col-sm-8'>
                            <label className='small '>Job View : </label>
                          </div>
                          <div className='col-sm-2 mt-1'>
                            <input type="checkbox" checked={this.state.filterObj.JobView ? true : false} onChange={(e) => {
                              const obj = { ...this.state.filterObj };
                              obj.JobView = e.currentTarget.checked
                              localStorage.setItem("macStateDashboardFilter", JSON.stringify(obj));
                              this.setState({ filterObj: obj })
                            }


                            } />
                          </div>
                        </div>
                      </li> : ''}
                      {this.state.cardDashboardState || this.state.macStateDashboardState ? <li className="nav-item ml-2">
                        <div className='row'>
                          <div className='col-lg-4'>
                            <label className='small '>FilterBy:</label>
                          </div>
                          <div className='col-lg-8'>
                            <div className="btn-group ">
                              <button className="btn btn-secondary btn-sm dropdown-toggle text-white dropbtn-btn-filter-group" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Equipment Group
                              </button>
                              <div className="dropdown-menu">
                                {/* <div className="dropdown-item">
                                <input onChange={this.handleFilterBy} checked={this.state.filterBy['All']} name="All" type="checkbox" />
                                <span className="ml-2 p-0">All</span>
                              </div> */}
                                {this.state.macGroups.map(group => {
                                  const obj = { ...this.state.filterBy }
                                  return <div className="dropdown-item">
                                    <input name={group} onChange={this.handleFilterBy} type="checkbox" checked={obj[group]} />
                                    <span className="ml-2 p-0">{group}</span>
                                  </div>
                                })}

                              </div>
                            </div>
                            {/* <select name='FilterBy' value={this.state.filterObj.FilterBy} onChange={this.handleFilterAndSort} className='form-control form-control-sm'>
                            <option>All</option>
                            {this.state.macGroups.map(group => {
                              return <option>{group}</option>
                            })}
                          </select> */}
                          </div>
                        </div>



                      </li> : ''}



                      {this.state.cardDashboardState || this.state.macStateDashboardState ? <li className="nav-item ml-1 ">
                        <div className='row'>
                          <div className='col-sm-5'>
                            <label className='small '>Sort By : </label>
                          </div>
                          <div className='col'>
                            <select onChange={this.handleFilterAndSort} value={this.state.filterObj.SortBy} name='SortBy' className='form-control form-control-sm'>
                              <option>Ascending</option>
                              <option>Descending</option>
                              {this.state.cardDashboardState ? <> <option>Running On Top</option>
                                <option>Not Running On Top</option>
                                <option>OEE Ascending</option>
                                <option>OEE Descending</option>
                                <option>Availability Ascending</option>
                                <option>Availability Descending</option>
                                <option>Performance Ascending</option>
                                <option>Performance Descending</option>
                                <option>Quality Ascending</option>
                                <option>Quality Descending</option></> : ''}
                            </select>
                          </div>
                        </div>



                      </li>
                        : <li className="nav-item mr-5 ">
                          <div className='row'>
                            <div className='col-sm-5 d-flex ' style={{ maxWidth: '100%' }}>
                              <div className="row">
                                <div className="col-sm-5 p-0 pl-3">
                                  <label className='small'>View:</label>
                                </div>
                                <div className="col-sm-7">
                                  <div className='row'>
                                    <div id="list1" className="dropdown p-0 m-0 form-control form-control-sm " style={{ display: 'inherit' }} >
                                      <button style={{ backgroundColor: 'white', color: 'darkgray', border: 0, height: '100%', width: '100%' }} className="btn p-0 m-0 btn-secondary btn-sm dropdown-toggle text-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <p>Add Field</p>
                                      </button>
                                      <ul className="dropdown-menu position-absolute" style={{ minWidth: '100%' }} aria-labelledby="dropdownMenuButton">

                                        {this.state.andonDashboardData.length > 0 ? Object.keys(this.state.andOnDasboardObj).map((one) => {
                                          if (one !== 'Equipment' && one !== 'ConnStatus' && one !== 'Shift' && one !== 'State' && one !== 'DeviceConfig')
                                            return <li className="dropdown-item"> <input type="checkbox" id={one} checked={this.state.andOnDasboardObj[one]} onChange={(e) => this.handleOnChangeSelect(e)} name={one} />&nbsp;&nbsp;&nbsp;{one} </li>
                                        }) : ''}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='col-sm-7 d-flex'>
                              <div className="row">
                              <div className="col-sm-4">
                            <Button  className="btn-sm btn-primary" onClick={this.showModal}>
        Sort By :
      </Button>
      </div>
                                <div className="col-sm-4 p-0 pl-3">
                                  <label className='small '>Filter By :</label>
                                </div>
                               

                           
                                <div className="col-sm-4">
                                  <div className='row'>
                                    <div className="btn-group ">
                                      <button className="btn btn-secondary btn-sm dropdown-toggle text-white dropbtn-btn-filter-group" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Equipment Group
                                      </button>
                                      <div className="dropdown-menu">
                                        {/* <div className="dropdown-item">
                              <input onChange={this.handleFilterBy} checked={this.state.filterBy['All']} name="All" type="checkbox" />
                              <span className="ml-2 p-0">All</span>
                            </div> */}
                                        {this.state.macGroups.map(group => {
                                          const obj = { ...this.state.filterBy }
                                          return <div className="dropdown-item">
                                            <input name={group} onChange={this.handleFilterBy} type="checkbox" checked={obj[group]} />
                                            <span className="ml-2 p-0">{group}</span>
                                          </div>
                                        })}
                                      </div>

                                  </div>
                                </div>
                               
     
                            </div>
                              </div>


                              {/* <select name='FilterBy' value={this.state.filterObj.FilterBy} onChange={this.handleFilterAndSort} className='form-control form-control-sm'>
                          <option>All</option>
                          {this.state.macGroups.map(group => {
                            return <option>{group}</option>
                          })}
                        </select> */}
                            </div>
                          </div>
                        </li>}

                      {this.state.cardDashboardState || this.state.andonDashboardState || this.state.macStateDashboardState ? <li className="nav-item ml-2 " >
                        <a style={{ marginTop: -9, cursor: 'pointer' }} className="nav-link" onClick={() => {
                          let obj = {}
                          if (this.state.cardDashboardState) {
                            if (localStorage.getItem('card_state')) {
                              obj = JSON.parse(localStorage.getItem('card_state'))
                            }
                          }
                          if (this.state.macStateDashboardState) {
                            if (localStorage.getItem('mac_state')) {
                              obj = JSON.parse(localStorage.getItem('mac_state'))
                            }
                          }
                          if (this.state.andOnDasboardObj) {
                            if (localStorage.getItem('andon_state')) {
                              obj = JSON.parse(localStorage.getItem('andon_state'))
                            }
                          }
                          this.setState({ modalSettingState: true, autoScrollObj: obj })

                        }}>
                          <FontAwesomeIcon icon={faCog} />
                        </a>
                      </li> : <></>}

                      {
                        this.state.andonDashboardData ? '' : ''}
                    </ul>
                  </div></React.Fragment> : ''}
              </div>
            </nav>}
          <main>




            <Switch >
              <Route path='/' exact render={props => <MachineStateChart {...props} equipList={this.state.equipList} filterBy={this.state.filterBy} filterObj={this.state.filterObj} user={user} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/dashboard/cardview' exact render={props => <DashBoard {...props} equipList={this.state.equipList} user={user} filterBy={this.state.filterBy} filterObj={this.state.filterObj} />} />

              <Route path='/dashboard/cxoview' exact render={props => <OCXView {...props} user={user} />} />
                 
              <Route path='/dashboard/andon' exact render={props => <AndonDashboard {...props} handleCancel={this.handleCancel} open={this.state.open} equipList={this.state.equipList} user={user} filterBy={this.state.filterBy} filterObj={this.state.filterObj} configObj={this.state.andOnDasboardObj} autoScrollObj={this.state.autoScrollObj} array={this.state.andonDashboardData} getData={this.getAndonDashboardData} />} />


              <Route path='/dashboard/activity' exact render={props => <HourlyActivityDashboard {...props} filterObj={this.state.sortObj} activityDashboardState={this.state.activityDashboardState} equipList={this.state.equipList} user={user} array={this.state.andonDashboardData} getData={this.getAndonDashboardData} />} />

              <Route path='/setting/customer/add' render={props => <CustomerMaster {...props} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} userGroupData={this.state.customerData} actionStatus={this.state.actionStatus} />} />
              <Route path='/setting/customer/list' render={props => <CustomerTable {...props} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />
              {/* <Route path='/setting/report' render={props => <ReportSetting {...props} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} /> */}


              <Route path='/setting/usergroup/add' render={props => <UserGroup {...props} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='userGroups' userGroupData={this.state.userGroupData} actionStatus={this.state.actionStatus} />} />
              <Route path='/setting/usergroup/list' render={props => <UserGroupTable {...props} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='userGroups' handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/userrights/add' render={props => <UserRights {...props} equipList={this.state.equipList} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} user={this.props.user} userRightsData={this.state.userRightsData} actionStatus={this.state.actionStatus} userRightsList={this.state.userRightsList} />} />
              <Route path='/setting/userrights/list' render={props => <UserRightTable {...props} equipList={this.state.equipList} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} user={this.props.user} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/user/add' render={props => <User {...props} equipList={this.state.equipList} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} user={this.state.userData} actionStatus={this.state.actionStatus} userList={this.state.userList} />} />
              <Route path='/setting/user/list' render={props => <UserTable {...props} user={user} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />


              <Route path='/setting/equipmentgroup/add' render={props => <EquipmentGroup userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} dataRightsKey='equipmentGroups' equipmentGroupData={this.state.equipmentGroupData} actionStatus={this.state.actionStatus} equipmentGroupList={this.state.equipmentGroupList} />} />
              <Route path='/setting/equipmentgroup/list' render={props => <EquipmentGroupTable userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} dataRightsKey='equipmentGroups' handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/equipmenttype/add' render={props => <EquipmentType {...props} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} equipmentTypeData={this.state.equipmentTypeData} actionStatus={this.state.actionStatus} equipmentTypeList={this.state.equipmentTypeList} />} />
              <Route path='/setting/equipmenttype/list' render={props => <EquipmentTypeTable {...props} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/equipment/add' render={props => <Equipment userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='StaticDataRights' {...props} equipmentData={this.state.equipmentData} actionStatus={this.state.actionStatus} equipmentList={this.state.equipmentList} />} />
              <Route path='/setting/equipment/list' render={props => <EquipmentTable userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='StaticDataRights'  {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />


              <Route path='/setting/equipmenthourlyrate/add' render={props => <EquipmentHourlyRate userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='StaticDataRights' {...props} equipmentData={this.state.equipmentData} actionStatus={this.state.actionStatus} equipmentList={this.state.equipmentList} />} />
              <Route path='/setting/equipmenthourlyrate/list' render={props => <EquipmentHourlyRateTable userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='StaticDataRights'  {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/consumablegroup/add' render={props => <ConsumablesGroup {...props} consumableGroupData={this.state.consumableGroupData} consumableGroupList={this.state.consumableGroupList} actionStatus={this.state.actionStatus} />} />
              <Route path='/setting/consumablegroup/list' render={props => <ConsumablesGroupTable {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />


              <Route path='/setting/consumablemaster/add' render={props => <ConsumablesMaster userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} consumableMasterData={this.state.consumableMasterData} consumableMasterList={this.state.consumableMasterList} actionStatus={this.state.actionStatus} />} />
              <Route path='/setting/consumablemaster/list' render={props => <ConsumablesMasterTable userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/operatormaster/add' render={props => <OperatorMaster userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='StaticDataRights' {...props} operatorMasterData={this.state.operatorMasterData} operatorMasterList={this.state.operatorMasterList} actionStatus={this.state.actionStatus} />} />
              <Route path='/setting/operatormaster/list' render={props => <OperatorMasterTable userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='StaticDataRights' {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />


              <Route path='/setting/downtimereasongroup/add' render={props => <DownTimeReasonGroup userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='StaticDataRights' {...props} downTimeReasonGroupData={this.state.downTimeReasonGroupData} downTimeReasonGroupList={this.state.downTimeReasonGroupList} actionStatus={this.state.actionStatus} />} />
              <Route path='/setting/downtimereasongroup/list' render={props => <DownTimeReasonGroupTable userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='StaticDataRights' {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/downtimereason/add' render={props => <DownTime dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} downTimeReasonData={this.state.downTimeReasonData} downTimeReasonList={this.state.downTimeReasonList} actionStatus={this.state.actionStatus} />} />
              <Route path='/setting/downtimereason/list' render={props => <DownTimeTable dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/partspercyclereason/add' render={props => <PartsPerCycleReason dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} data={this.state.partsPerCycleReason} actionStatus={this.state.actionStatus} qualityReasonGroupList={this.state.qualityReasonGroupList} />} />
              <Route path='/setting/partspercyclereason/list' render={props => <PartsPerCycleReasonTable dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/rejectionstage/add' render={props => <RejectionStatge dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} stageData={this.state.rejectionStage} actionStatus={this.state.actionStatus} qualityReasonGroupList={this.state.qualityReasonGroupList} />} />
              <Route path='/setting/rejectionstage/list' render={props => <RejectionStageTable dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/qualityreasongroup/add' render={props => <QualityReasonGroup dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} qualityReasonGroupData={this.state.qualityReasonGroupData} actionStatus={this.state.actionStatus} qualityReasonGroupList={this.state.qualityReasonGroupList} />} />
              <Route path='/setting/qualityreasongroup/list' render={props => <QualityReasonGroupTable dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/qualityreason/add' render={props => <QualityReason dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} qualityReasonData={this.state.qualityReasonData} actionStatus={this.state.actionStatus} qualityReasonList={this.state.qualityReasonList} />} />
              <Route path='/setting/qualityreason/list' render={props => <QualityReasonTable dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/toolmaster/add' render={props => <ToolMaster dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} toolMasterData={this.state.toolMasterData} actionStatus={this.state.actionStatus} toolMasterList={this.state.toolMasterList} />} />
              <Route path='/setting/toolmaster/list' render={props => <ToolMasterTable dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/operation/add' render={props => <OperationMaster {...props} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='operationMaster' operationMasterData={this.state.operationMasterData} actionStatus={this.state.actionStatus} />} />
              <Route path='/setting/operation/list' render={props => <OperationTable {...props} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='operationMaster' handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/component/add' render={props => <ComponentTest dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} setNavSelectedState={this.setNavSelectedState} componentData={this.state.componentData} actionStatus={this.state.actionStatus} componentList={this.state.componentList} />} />
              <Route path='/setting/component/list' render={props => <ComponentTable dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              <Route path='/setting/shiftsetting/add' render={props => <ShiftSetting dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} shiftSettingList={this.state.shiftSettingList} shiftSettingData={this.state.shiftSettingData} actionStatus={this.state.actionStatus} />} />
              <Route path='/setting/shiftsetting/list' render={props => <ShiftSettingTable dataRightsKey='StaticDataRights' userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} {...props} handleOnAdd={this.handleOnAdd} onUpdate={this.handleOnUpdate} />} />

              {/* //////////////////////////Data Entry Routes//////////////////////////////////////////////////////////////////////////// */}

              <Route path='/dataentry/downtime' exact render={props => <Downtime userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} />} />
              <Route path='/dataentry/downtime/view' exact render={props => <DowntimeView company={this.state.company} userRights={this.state.userRights} equipGroups={this.state.equipGroups} equips={this.state.equipList} user={user} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} />} />
              <Route path='/dataentry/downtime/notification_click/:id/:DateTime' exact render={props => <NotificationClickDowntimeView company={this.state.company} userRights={this.state.userRights} equipGroups={this.state.equipGroups} equips={this.state.equipList} user={user} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} />} />

              <Route path='/dataentry/component' exact render={props => <ComponentDataEntry userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} actionStatus={this.state.actionStatus} dataEntryComponentData={this.state.dataEntryComponentData} />} />
              <Route path='/dataentry/component/view' render={props => <ComponentView company={this.state.company} compList={this.state.componentList} userRights={this.state.userRights} equips={this.state.equipList} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} handleOnUpdate={this.handleDataEntryUpdate} />} />

              <Route path='/dataentry/job/table' render={props => <JobEntryTabular compList={this.state.componentList} userRights={this.state.userRights} equips={this.state.equipList} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} handleOnUpdate={this.handleDataEntryUpdate} />} />
              {/* <Route path='/' render={props => <ComponentView userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} handleOnUpdate={this.handleDataEntryUpdate} />} /> */}

              <Route path='/dataentry/operator' exact render={props => <Operator userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} actionStatus={this.state.actionStatus} dataEntryOperatorData={this.state.dataEntryOperatorData} />} />
              <Route path='/dataentry/operator/view' render={props => <OperatorView userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} handleOnUpdate={this.handleDataEntryUpdate} />} />

              <Route path='/dataentry/quality' exact render={props => <Quality company={this.state.company} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} />} />
              <Route path='/dataentry/quality/view' render={props => <QualityView company={this.state.company} userRights={this.state.userRights} getRightsForAccess={handleUserRightsToVisibleOrNot} dataRightsKey='DynamicDataRights' {...props} />} />

              <Route path='/dataentry/consumable' exact render={props => <Consumable  {...props} actionStatus={this.state.actionStatus} dataEntryConsumableData={this.state.dataEntryConsumableData} />} />
              <Route path='/dataentry/consumable/view' exact render={props => <ConsumableView {...props} handleOnUpdate={this.handleDataEntryUpdate} />} />




              {/* /////////////////////////////////Reproting Routes////////////////////////////////////////////////////////// */}
              <Route path='/reporting/tool_production' exact render={props => <ToolProductionReport {...props} equipGroups={this.state.equipGroups} user={user} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/production_hourly3' exact render={props => <ProductionReportHourly3 {...props} equipGroups={this.state.equipGroups} user={user} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/cycle_duration' exact render={props => <CycleDuration {...props} equipGroups={this.state.equipGroups} user={user} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/tool_run' exact render={props => <ToolRun {...props} equipGroups={this.state.equipGroups} equips={this.state.equipList} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/benchmark' exact render={props => <BenchMarkTable {...props} handleUpdateEquips={this.handleUpdateEquips} equipGroups={this.state.equipGroups} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/detailed-production' exact render={props => <DetailedProduction {...props} qualityReason={this.state.qualityReasonList} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/detailed-production2' exact render={props => <DetailedProduction2 {...props} qualityReason={this.state.qualityReasonList} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />

              <Route path='/reporting/production' exact render={props => <ProductionReport {...props} equipGroups={this.state.equipGroups} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/production_hourly2' exact render={props => <ProductionReportHourly2 {...props} equipGroups={this.state.equipGroups} equips={this.state.equipList} user={user} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/production_hourly' exact render={props => <ProductionReportHourly {...props} equipGroups={this.state.equipGroups} equips={this.state.equipList} user={user} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />

              <Route path='/reporting/downtime_analysis' exact render={props => <DowntimeAnalysisCumulative {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/downtime_analysis_machine' exact render={props => <DowntimeAnalysisMachine {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              {/* <Route path='/reporting/operator_performance' render={props => <OperatorPerformance {...props} />} />
              <Route path='/reporting/operator_performance_detailed' render={props => <OperatorPerfDetailed {...props} />} /> */}
              <Route path='/reporting/jobs_run' exact render={props => <JobRunReport {...props} equipGroups={this.state.equipGroups} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              {/* <Route path='/reporting/quality_analysis' render={props => <QualityReport {...props} />} /> */}
              <Route path='/reporting/machine_oee' exact render={props => <Machine_OEE {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/availability' exact render={props => <Availability {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/machine_state' exact render={props => <HistoryMachineState {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/first_part' exact render={props => <FirstPartReport {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/machine_performance_overview' exact render={props => <Performance_Overview {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/machine_performance_detailed' exact render={props => <PerformanceDetailed {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/machine_activity' exact render={props => <MachineActvity {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/job_quality_factor' exact render={props => <QualityReport {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/operator_performance_overview' exact render={props => <OperatorPerformance {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/operator_performance_detailed' exact render={props => <OperatorPerfDetailed {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} />} storeDataInLocalStorage={this.storeDataInLocalStorage} />
              <Route path='/reporting/job_performance' exact render={props => <JobPerformance {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/job_operator_performance' exact render={props => <Job_Operator_Performance {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />

              <Route path='/reporting/availability_trend' exact render={props => <AvailabilityTrend {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/availability_hourly' exact render={props => <AvailabilityReportHourly {...props} user={user} />} />
              <Route path='/reporting/cycles_runtime' exact render={props => <CycleRunAndRuntime {...props} equipGroups={this.state.equipGroups} user={user} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />

              <Route path='/reporting/job_oee' exact render={props => <JobOEE {...props} user={user} componentList={this.state.componentList} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/downtime_machine_trend' exact render={props => <DowntimeMachineTrend {...props} user={user} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/downtime_pareto' exact render={props => <DowntimePareto {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/quality_pareto' exact render={props => <QualityPareto {...props} user={user} componentList={this.state.componentList} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />-
              <Route path='/reporting/machine_state_event' exact render={props => <MachineStateEventLog {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />


              <Route path='/reporting/down_time_detailed_analysis' exact render={props => <DowntimeDetailedAnalysis {...props} user={user} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/quality_analysis' exact render={props => <QualityAnalysis componentList={this.state.componentList} reasons={this.state.downtimeReasonList} {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/quality_satgerejection_report' exact render={props => <QualityRejectionStageReport componentList={this.state.componentList} reasons={this.state.downtimeReasonList} {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />

              <Route path='/reporting/utilization_report' exact render={props => <UtilizationReport componentList={this.state.componentList} reasons={this.state.downtimeReasonList} {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/manpower_wise_report' exact render={props => <TalbrosProductionReport componentList={this.state.componentList} reasons={this.state.downtimeReasonList} {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/production_report2' exact render={props => <Dailyproductionreport componentList={this.state.componentList} reasons={this.state.downtimeReasonList} {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/equipment_wise_report' exact render={props => <EquipmentWiseProductionData componentList={this.state.componentList} reasons={this.state.downtimeReasonList} {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/machine_wise_report' exact render={props => <McWiseData componentList={this.state.componentList} reasons={this.state.downtimeReasonList} {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/summary_report' exact render={props => <TalbrosProductionSummary componentList={this.state.componentList} reasons={this.state.downtimeReasonList} {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />



              <Route path='/reporting/qualityreport' render={props => <QualityReport {...props} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} />} />
              {/* <Route path='/reporting/oee' render={props => <OEEReport {...props} />} />
              <Route path='/reporting/jobrun' render={props => <JobRunReport {...props} />} />
              <Route path='/reporting/availability' render={props => <Availability {...props} />} />
              <Route path='/reporting/performance_bycomponent' render={props => <PerformanceAnalysisByComponent {...props} />} />
              <Route path='/reporting/performance_bymachine' render={props => <PerformanceAnalysisByMachine {...props} />} />
              <Route path='/reporting/operatorperformance' render={props => <OperatorPerformance {...props} />} />
              <Route path='/reporting/downtimeanalysis' render={props => <DowntimeAnalysis {...props} />} />
            */}


              {/* Talbros Routes Special Report */}
              <Route path='/reporting/overall_machine_oee' exact render={props => <OverallMachineOEE {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/overall_month_oee' exact render={props => <OverallMonthOEE {...props} user={user} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              {/* Talbros Routes Special Report */}




              <Route path='/reporting/live_summaryreport1' render={props => <LiveDashBoards {...props} equipList={this.state.equipList} equipGroups={this.state.equipGroups} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/production_summary1' render={props => <ProductionSummary {...props} equipList={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} pagetitle={this.pagetitle} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/availability_summary1' render={props => <AvilabilitySummary1 {...props} equipList={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} pagetitle={this.pagetitle} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/quality_summary1' render={props => <QualitySummary1 {...props} equipList={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} pagetitle={this.pagetitle} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/histogram_report' render={props => <HistogramReport {...props} equipList={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} pagetitle={this.pagetitle} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/production_summary2' render={props => <ComponentSummaryReport {...props} user={user} equipGroups={this.state.equipGroups} reasons={this.state.downtimeReasonList} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/incident_report1' exact render={props => <IncidentsReport1 {...props} user={user} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} pagetitle={this.pagetitle} />} />
              <Route path='/reporting/live_summaryreport2' render={props => <LiveDashBoards2 {...props} equipList={this.state.equipList} equipGroups={this.state.equipGroups} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/cavity_loss_reason' render={props => <CavityLossReasonReport {...props} user={user} equipGroups={this.state.equipGroups} reasons={this.state.downtimeReasonList} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/daily_production_summary' exact render={props => <DailyProductionSummary {...props} user={user} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} pagetitle={this.pagetitle} />} />
              {/* <Route path='/setting/faq' exact render={props => <FAQPage {...props} user={user} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList}  fromDateLimit={this.state.fromDateLimit  !== null ? this.state.fromDateLimit :''}  storeDataInLocalStorage={this.storeDataInLocalStorage} pagetitle={this.pagetitle} />} /> */}
              <Route path='/reporting/day_summary_one_report' exact render={props => <DaySummaryOneReport {...props} user={user} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/availability_rejection_oee_report' exact render={props => <AvailabilityRejectionOEEReport {...props} user={user} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} equips={this.state.equipList} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage} />} />
              <Route path='/reporting/CycleRunReportDynamic' exact render={props => <CycleRunReportDynamic {...props} equipGroups={this.state.equipGroups} user={user} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage}  />} />
              <Route path='/reporting/JobCycleTime_DowntimeChart' exact render={props => <JobCycleTime_DowntimeChart {...props} reasons={this.state.downtimeReasonList} equipGroups={this.state.equipGroups} user={user} equips={this.state.equipList} handleDownloadTableToExcel={this.handleDownloadTableToExcel} fromDateLimit={this.state.fromDateLimit !== null ? this.state.fromDateLimit : ''} storeDataInLocalStorage={this.storeDataInLocalStorage}  />} />
            
              
            </Switch>


          </main>
        </div>
      </div>
      {/* </div>
    )} */}
    </>
    );
  }
}

export default NavBarUser;
